import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Label
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDoctorFeeReportUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div
          style={{display: "flex", marginBottom: "0.75rem"}}>

          <Button
            color={props.activeTab === "description"? "blue":""}
            name="description"
            onClick={props.onChangeTab}
            size="small"
            style={{marginRight: "0.75rem"}}>
            รายละเอียดค่าตอบแทน
          </Button>
          <Button
            color={props.activeTab === "overview"? "blue":""}
            name="overview"
            onClick={props.onChangeTab}
            size="small"
            style={{marginRight: "0.75rem"}}>
            ภาพรวมค่าตอบแทนแพทย์
          </Button>
        </div>
        <div
          className="ui form">

          <div
            style={{fontSize: "1.15rem", fontWeight: "bold", padding: "2.5px 0"}}>
            {props.table?.title || "-"}
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: "0.5rem"}}>

        </div>
        <div
          className="ui form small">

          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                วันที่รักษา
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "10rem"}}
              width={1}>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  onChange={(value: string)=>props.onChangeFilter?.(null,{name:"startDate", value})}
                  value={props.filter?.startDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "10rem"}}
              width={1}>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  onChange={(value: string)=>props.onChangeFilter?.(null,{name:"endDate", value})}
                  value={props.filter?.endDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "max-content"}}>
                คลินิกที่ออกตรวจ
              </label>
              <Dropdown
                className="inline-label"
                fluid={true}
                name="division"
                onChange={props.onChangeFilter}
                options={props.divisionOptions|| []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.division || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <label>
                คาบ
              </label>
              <Dropdown
                className="inline-label"
                fluid={true}
                name="period"
                onChange={props.onChangeFilter}
                options={props.periodOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.period || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "max-content"}}>
                เวลาออกตรวจ
              </label>
              <Dropdown
                className="inline-label"
                fluid={true}
                name="workingType"
                onChange={props.onChangeFilter}
                options={props.workingTypeOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.workingType|| ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{display: props.activeTab === "description"? "": "none"}}
              width={4}>
              <label
                style={{minWidth: "max-content"}}>
                สถานะการชำระเงินของผู้ป่วย
              </label>
              <Dropdown
                className="inline-label"
                fluid={true}
                name="status"
                onChange={props.onChangeFilter}
                options={props.paymentStatusOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.status|| ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{display: props.activeTab === "overview"? "": "none"}}
              width={4}>
              <label
                style={{minWidth: "max-content"}}>
                สถาะส่งเบิก
              </label>
              <Dropdown
                className="inline-label"
                fluid={true}
                name="filter.status"
                onChange={props.onChangeFilter}
                options={props.sentClaimStatusOptions||[]}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.status|| ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div>

          <Table
            data={props.data}
            getTdProps={props.onGetTdProps}
            getTheadThProps={props.onGetTheadThProps}
            headers={props.table?.headers}
            keys={props.table?.keys}
            minRows={6}
            showPagination={false}
            style={{height: props.table?.height || ""}}
            widths={props.table?.widths}>
          </Table>
        </div>
        <div
          style={{margin:  "1rem 0 -1rem 0.5rem",display: props.activeTab === "description"? "": "none", fontSize: "0.85rem"}}>

          <div>
            หมายเหตุ ส่วนแบ่ง (%) ยังไม่ได้ถูกหักค่า LAB (ถ้ามี)
          </div>
          <div>
            C- ได้รับเป็นเงิน P-ได้รับเป็นแต้มสะสม
          </div>
        </div>
        <div
          className="ui form"
          style={{paddingLeft: "5rem", marginTop: "1rem"}}>

          <FormGroup
            inline={true}
            style={{marginBottom: "-0.25rem"}}>
            <FormField
              inline={true}
              width={9}>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <div
                className="ui form"
                style={{width: "100%"}}>

                <FormGroup
                  inline={true}
                  widths={16}>
                  <FormField
                    inline={true}
                    width={9}>
                    <label
                      style={{minWidth: "12rem", maxWidth: "12rem",fontSize: "0.85rem"}}>
                      รายรับทั้งหมด
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.total_revenue_price || "\u00a0"}
                    </Label>
                  </FormField>
                  <FormField
                    inline={true}
                    width={8}>
                  </FormField>
                </FormGroup>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "-0.25rem"}}>
            <FormField
              inline={true}
              width={9}>
              <div
                className="ui form"
                style={{width: "100%"}}>

                <FormGroup
                  inline={true}
                  widths={16}>
                  <FormField
                    inline={true}
                    style={{display: props.activeTab === "description"? "": "none"}}
                    width={12}>
                    <label
                      style={{minWidth: "max-content",fontSize: "0.85rem"}}>
                      ยอดรวมส่งเบิก
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.total_send_claim|| "\u00a0"}
                    </Label>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{display: props.activeTab === "overview"? "": "none"}}
                    width={12}>
                    <label
                      style={{minWidth: "max-content",fontSize: "0.85rem"}}>
                      จำนวนรวม(qty)
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.raw_total_quantity ?? "\u00a0"}
                    </Label>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{display: props.activeTab === "description"? "": "none"}}
                    width={12}>
                    <label
                      style={{minWidth: "max-content",fontSize: "0.85rem"}}>
                      ยอดรวมผู้ป่วยจ่าย
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.total_payable|| "\u00a0"}
                    </Label>
                  </FormField>
                  <FormField
                    inline={true}
                    width={12}>
                    <label
                      style={{minWidth: "7.5rem", maxWidth: "7.5rem",fontSize: "0.85rem"}}>
                      ค่าบริการผู้ป่วยนอก
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.total_revenue_outpatient|| "\u00a0"}
                    </Label>
                  </FormField>
                </FormGroup>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <div
                className="ui form"
                style={{width: "100%"}}>

                <FormGroup
                  inline={true}
                  widths={16}>
                  <FormField
                    inline={true}
                    width={9}>
                    <label
                      style={{minWidth: "12rem", maxWidth: "12rem",fontSize: "0.85rem"}}>
                      รายรับจากการรักษา (รับเป็นเงิน)
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.total_revenue_credit|| "\u00a0"}
                    </Label>
                  </FormField>
                  <FormField
                    inline={true}
                    width={8}>
                    <label
                      style={{minWidth: "9.5rem", maxWidth: "9.5rem",fontSize: "0.85rem"}}>
                      รายรับที่แบ่ง (รับเป็นเงิน)
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.final_revenue_credit|| "\u00a0"}
                    </Label>
                  </FormField>
                </FormGroup>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "-0.25rem"}}>
            <FormField
              inline={true}
              width={9}>
              <div
                className="ui form"
                style={{width: "100%"}}>

                <FormGroup
                  inline={true}
                  widths={16}>
                  <FormField
                    inline={true}
                    width={12}>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{display: props.activeTab === "description"? "": "none"}}
                    width={12}>
                  </FormField>
                  <FormField
                    inline={true}
                    width={12}>
                    <label
                      style={{minWidth: "7.5rem", maxWidth: "7.5rem",fontSize: "0.85rem"}}>
                      รายรับที่ไม่แบ่ง
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.total_compensation_price|| "\u00a0"}
                    </Label>
                  </FormField>
                </FormGroup>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <div
                className="ui form"
                style={{width: "100%"}}>

                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}
                    width={9}>
                    <label
                      style={{minWidth: "12rem", maxWidth: "12rem",fontSize: "0.85rem"}}>
                      รายรับจากโครงการ (แต้มสะสม)
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.total_revenue_point|| "\u00a0"}
                    </Label>
                  </FormField>
                  <FormField
                    inline={true}
                    width={8}>
                    <label
                      style={{minWidth: "9.5rem", maxWidth: "9.5rem",fontSize: "0.85rem"}}>
                      รายรับที่แบ่ง (แต้มสะสม)
                    </label>
                    <Label
                      size="large"
                      style={{width: "100%", textAlign: "right"}}>
                      {props.summary?.final_revenue_point|| "\u00a0"}
                    </Label>
                  </FormField>
                </FormGroup>
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{display: props.activeTab === "overview"? "": "none", marginTop: "1rem"}}>

          <FormGroup
            inline={true}
            style={{marginBottom: "0.25rem"}}>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content", fontSize: "0.85rem"}}>
                ยอดรวมต้นสังกัด / ผู้ป่วยชำระแล้ว
              </label>
              <Label
                size="large"
                style={{width: "100%", textAlign: "right"}}>
                {props.summary?.total_paid_price|| "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "8.5rem", maxWidth: "8.5rem",fontSize: "0.85rem"}}>
                ยอดรวมรอเบิกต้นสังกัดและ ผู้ป่วยค้างชำระ
              </label>
              <Label
                size="large"
                style={{width: "100%", textAlign: "right"}}>
                {props.summary?.total_waiting_claim_price|| "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "8.5rem", maxWidth: "8.5rem",fontSize: "0.85rem"}}>
                ยอดค่า Lab (หักแพทย์ (รับเป็นเงิน))
              </label>
              <Label
                size="large"
                style={{width: "100%", textAlign: "right"}}>
                {props.summary?.total_lab_df_deduct_credit|| "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content", fontSize: "0.85rem"}}>
                ยอดค่า Lab (ไม่หักแพทย์ (รับเป็นเงิน))
              </label>
              <Label
                size="large"
                style={{width: "100%", textAlign: "right"}}>
                {props.summary?.total_lab_price_credit|| "\u00a0"}
              </Label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "0.25rem"}}>
            <FormField
              inline={true}
              width={4}>
            </FormField>
            <FormField
              inline={true}
              width={4}>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "8.5rem", maxWidth: "8.5rem",fontSize: "0.85rem"}}>
                ยอดค่า Lab (หักแพทย์ (แต้มสะสม))
              </label>
              <Label
                size="large"
                style={{width: "100%", textAlign: "right"}}>
                {props.summary?.total_lab_df_deduct_point|| "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content", fontSize: "0.85rem"}}>
                ยอดค่า Lab (ไม่หักแพทย์ (แต้มสะสม))
              </label>
              <Label
                size="large"
                style={{width: "100%", textAlign: "right"}}>
                {props.summary?.total_lab_price_point|| "\u00a0"}
              </Label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "0.25rem"}}>
            <FormField
              inline={true}
              width={4}>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "8.5rem", maxWidth: "8.5rem",fontSize: "0.85rem"}}>
                ยอดรวมรอเบิก (สะสม)
              </label>
              <Label
                size="large"
                style={{width: "100%", textAlign: "right"}}>
                {props.summary?.total_waiting_claim_price_cumulative|| "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "8.5rem", maxWidth: "8.5rem",fontSize: "0.85rem"}}>
                ยอดรวมแต้ม (สะสม)
              </label>
              <Label
                size="large"
                style={{width: "100%", textAlign: "right"}}>
                {props.summary?.total_point|| "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
            </FormField>
          </FormGroup>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{marginTop: "1rem"}}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              style={{display: "flex"}}>
              <div>
                {props.buttonPrint}
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}

CardDoctorFeeReportUX.displayName = "CardDoctorFeeReportUX";
export default React.memo(CardDoctorFeeReportUX)

export const screenPropsDefault = {}

/* Date Time : Wed Sep 18 2024 09:56:53 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.5rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.table?.title || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดค่าตอบแทน"
        },
        "color": {
          "type": "code",
          "value": "props.activeTab === \"description\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "description"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ภาพรวมค่าตอบแทนแพทย์"
        },
        "color": {
          "type": "code",
          "value": "props.activeTab === \"overview\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "overview"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 24,
      "name": "Table",
      "parent": 23,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onGetTdProps"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.onGetTheadThProps"
        },
        "headers": {
          "type": "code",
          "value": "props.table?.headers"
        },
        "keys": {
          "type": "code",
          "value": "props.table?.keys"
        },
        "minRows": {
          "type": "code",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props.table?.height || \"\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.table?.widths"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormGroup",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 28,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 28,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormGroup",
      "parent": 56,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 57,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"description\"? \"\": \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่รักษา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิกที่ออกตรวจ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "คาบ"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลาออกตรวจ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการชำระเงินของผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 74,
      "name": "DateTextBox",
      "parent": 72,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.(null,{name:\"startDate\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.startDate || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 75,
      "name": "DateTextBox",
      "parent": 73,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.(null,{name:\"endDate\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.endDate || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Dropdown",
      "parent": 62,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.division || \"\""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Dropdown",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "period"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.periodOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.period || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Dropdown",
      "parent": 64,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "workingType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.workingTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.workingType|| \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Dropdown",
      "parent": 65,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.paymentStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.status|| \"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin:  \"1rem 0 -1rem 0.5rem\",display: props.activeTab === \"description\"? \"\": \"none\", fontSize: \"0.85rem\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ ส่วนแบ่ง (%) ยังไม่ได้ถูกหักค่า LAB (ถ้ามี)"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "C- ได้รับเป็นเงิน P-ได้รับเป็นแต้มสะสม"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"5rem\", marginTop: \"1rem\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormGroup",
      "parent": 85,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-0.25rem\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormGroup",
      "parent": 85,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-0.25rem\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormGroup",
      "parent": 85,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-0.25rem\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 86,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 86,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormField",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormField",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormGroup",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormGroup",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 99,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 99,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "รายรับจากโครงการ (แต้มสะสม)"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"12rem\", maxWidth: \"12rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "รายรับที่แบ่ง (แต้มสะสม)"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"9.5rem\", maxWidth: \"9.5rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "รายรับที่แบ่ง (รับเป็นเงิน)"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"9.5rem\", maxWidth: \"9.5rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "รายรับจากการรักษา (รับเป็นเงิน)"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"12rem\", maxWidth: \"12rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "รายรับทั้งหมด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"12rem\", maxWidth: \"12rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Label",
      "parent": 101,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_revenue_price || \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Label",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_revenue_credit|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Label",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.final_revenue_credit|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Label",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_revenue_point|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Label",
      "parent": 106,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.final_revenue_point|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormGroup",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormGroup",
      "parent": 118,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"description\"? \"\": \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"description\"? \"\": \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"description\"? \"\": \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวมส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวมผู้ป่วยจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "ค่าบริการผู้ป่วยนอก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"7.5rem\", maxWidth: \"7.5rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "รายรับที่ไม่แบ่ง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"7.5rem\", maxWidth: \"7.5rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Label",
      "parent": 123,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_compensation_price|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Label",
      "parent": 126,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_revenue_outpatient|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Label",
      "parent": 125,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_payable|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "Label",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_send_claim|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"overview\"? \"\": \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "label",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": "สถาะส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "Dropdown",
      "parent": 135,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "filter.status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.sentClaimStatusOptions||[]"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.status|| \"\""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"overview\"? \"\": \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนรวม(qty)"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Label",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.raw_total_quantity ?? \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"overview\"? \"\": \"none\", marginTop: \"1rem\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormGroup",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.25rem\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormGroup",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.25rem\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวมต้นสังกัด / ผู้ป่วยชำระแล้ว"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontSize: \"0.85rem\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวมรอเบิกต้นสังกัดและ ผู้ป่วยค้างชำระ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"8.5rem\", maxWidth: \"8.5rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "label",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดค่า Lab (หักแพทย์ (รับเป็นเงิน))"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"8.5rem\", maxWidth: \"8.5rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดค่า Lab (ไม่หักแพทย์ (รับเป็นเงิน))"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontSize: \"0.85rem\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดค่า Lab (หักแพทย์ (แต้มสะสม)) "
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"8.5rem\", maxWidth: \"8.5rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Label",
      "parent": 144,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_paid_price|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Label",
      "parent": 145,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_waiting_claim_price|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Label",
      "parent": 146,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_lab_df_deduct_credit|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Label",
      "parent": 147,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_lab_price_credit|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Label",
      "parent": 150,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_lab_df_deduct_point|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดค่า Lab (ไม่หักแพทย์ (แต้มสะสม)) "
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontSize: \"0.85rem\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Label",
      "parent": 151,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_lab_price_point|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormGroup",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.25rem\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 166,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 166,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "FormField",
      "parent": 166,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormField",
      "parent": 166,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "label",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวมรอเบิก (สะสม)"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"8.5rem\", maxWidth: \"8.5rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "label",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวมแต้ม (สะสม)"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"8.5rem\", maxWidth: \"8.5rem\",fontSize: \"0.85rem\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Label",
      "parent": 169,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_point|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Label",
      "parent": 168,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summary?.total_waiting_claim_price_cumulative|| \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"right\"}"
        }
      },
      "seq": 174,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": true,
  "memo": true,
  "name": "CardDoctorFeeReportUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 100
}

*********************************************************************************** */
