import WasmController from "react-lib/frameworks/WasmController";
import { beToAd } from "react-lib/utils/dateUtils";

//apis
import PatientAppointmentTelePharmacyList from "issara-sdk/apis/PatientAppointmentTelePharmacyList_apps_PRX"


export type State = {
  errorMessage?: any;
  successMessage?: any;
  AppointmentTelepharSequence?: {
    sequenceIndex?: string | null;
    selectedPatien?: any | null;
    selectedStatus?: any | null;
    selectedFromDate?: string | null;
    selectedToDate?: string | null;
  } | null;
  AppointmentTelepharList ?: any 
};

export const StateInitial: State = {
    AppointmentTelepharSequence: null,
    AppointmentTelepharList: []
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const start: Handler = async (controller, params) => {
  var state = controller.getState();
  if (!state.AppointmentTelepharSequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["divisionPharma", {}]],
    },
  });

   const [response, error, network] = await PatientAppointmentTelePharmacyList.list({

        extra: {
          division: controller.data.division,
          device: (controller.data as any).device,
        },
        apiToken: controller.apiToken,
      });



  controller.setState({
    AppointmentTelepharSequence: {
      ...state.AppointmentTelepharSequence,
      sequenceIndex: "SearchAppointmentTelephar",
    },
    AppointmentTelepharList: response || []
  });
};

export const SearchAppointmentTelephar: Handler = async (controller,params) => {
    const state = controller.getState(); 

    if (!state.AppointmentTelepharSequence) return;
    if (!params?.action ) return;

    if(params?.action === "search"){


    let fromDate = beToAd(state?.AppointmentTelepharSequence?.selectedFromDate)?._i
    let toDate = beToAd(state?.AppointmentTelepharSequence?.selectedToDate)?._i


    console.log("fromDate",fromDate);
      const [response, error, network] = await PatientAppointmentTelePharmacyList.list({
      
        params:{
          patient  : state?.AppointmentTelepharSequence?.selectedPatien || "",
          tele_pharmacy_status : state?.AppointmentTelepharSequence?.selectedStatus  || "",
          from_date :fromDate || "",
          to_date :toDate|| "",
        },
        extra: {
          division: controller.data.division,
          
        },
        apiToken: controller.apiToken,
      });

      

      controller.setState({
        AppointmentTelepharSequence: {
          ...state.AppointmentTelepharSequence,
        },
        AppointmentTelepharList: response

      })
      
    
    }
    


    
    

};
