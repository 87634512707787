import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Checkbox,
  Dropdown,
  Button,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDrugOrderHistoryUX = (props: any) => {
    return(
      <div
        style={{padding: "0px",minWidth:"max-content"}}>
        <div
          style={{ padding: "0px 0 15px",display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{fontWeight:"bold", fontSize: "1.35rem",}}>
            ประวัติการจ่ายยา
          </div>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.checkedPatientSearch}
                disabled={props.addToOrderMode || false}
                label="ชื่อหรือรหัสผู้ป่วย"
                onChange={props.handleChangeChecked("checkedPatientSearch", "selectedPatientSearch")}
                style={{width: "130px", display: props.isDrugOrderHistory ? "none": "", marginRight: 0}}>
              </Checkbox>
              <div
                style={{width: "max-content", display: props.isDrugOrderHistory ? "": "none"}}>
                {props.patientName}
              </div>
            </FormField>
            <FormField
              className={props.isDrugOrderHistory ? "hidden" : ""}
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.patientSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.checkedDoctorSearch}
                label="ชื่อหรือรหัสแพทย์"
                onChange={props.handleChangeChecked("checkedDoctorSearch", "selectedDoctorSearch")}
                style={{width: "135px"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.doctorSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.checkedDrugSearch}
                label="ชื่อหรือรหัสยา"
                onChange={props.handleChangeChecked("checkedDrugSearch", "selectedDrugSearch")}
                style={{width: "115px"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.drugSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.checkedFilterDate}
                label="วันที่สั่งยา"
                onChange={props.handleChangeCheckedDate("checkedFilterDate")}
                style={{width: "90px"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <DateTextBox
                disabled={!props.checkedFilterDate}
                onChange={props.handleChangeDate("selectedStartDate")}
                value={props.selectedStartDate}>
              </DateTextBox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <DateTextBox
                disabled={!props.checkedFilterDate}
                onChange={props.handleChangeDate("selectedEndDate")}
                value={props.selectedEndDate}>
              </DateTextBox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.checkedDrugOrderType}
                label="ประเภทยา"
                onChange={props.handleChangeChecked("checkedDrugOrderType", "selectedDrugOrderType")}
                style={{width: "90px"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                clearable={true}
                disabled={!props.checkedDrugOrderType}
                fluid={true}
                multiple={true}
                onChange={props.handleChangeValue("selectedDrugOrderType")}
                options={props.drugOrderTypeOptions}
                selection={true}
                style={{width: "100%",minWidth:"80px"}}
                value={props.selectedDrugOrderType || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.checkedDrugOrderStatus}
                label="สถานะยา"
                onChange={props.handleChangeChecked("checkedDrugOrderStatus", "selectedDrugOrderStatus")}
                style={{width: "85px"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                clearable={true}
                disabled={!props.checkedDrugOrderStatus}
                fluid={true}
                onChange={props.handleChangeValue("selectedDrugOrderStatus")}
                options={props.drugOrderStatusOptions}
                selection={true}
                style={{width: "100%",minWidth:"80px"}}
                value={props.selectedDrugOrderStatus}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.checkedDivisionPharma}
                label="ห้องยา"
                onChange={props.handleChangeChecked("checkedDivisionPharma", "selectedDivisionPharma")}
                style={{width: "70px"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                clearable={true}
                disabled={!props.checkedDivisionPharma}
                fluid={true}
                onChange={props.handleChangeValue("selectedDivisionPharma")}
                options={props.divisionPharmaOptions}
                selection={true}
                style={{width: "100%",minWidth:"80px"}}
                value={props.selectedDivisionPharma}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                onClick={props.handleSearchToday}
                size="tiny"
                style={{width: "70px"}}>
                วันนี้
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                onClick={props.handleSearchToday}
                size="tiny"
                style={{width: "70px"}}>
                วัคซีน
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                onClick={props.handleClearSearch}
                size="tiny"
                style={{width: "110px"}}>
                ล้างตัวกรอง
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="blue"
                onClick={props.handleOnSearch}
                size="tiny"
                style={{width: "70px"}}>
                ค้นหา
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display:"none", alignItems: "center", flexWrap: "wrap"}}>
          
          <div
            style={{display: "flex", padding:"0 20px 0 0", alignItems: "center"}}>
            
            <Checkbox
              checked={props.checkedPatientSearch}
              onChange={props.handleChangeChecked("checkedPatientSearch", "selectedPatientSearch")}
              style={props.checkedPatientStyle}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              {props?.patientName}
            </div>
            <div>
              {props.patientSearch}
            </div>
          </div>
          <div
            style={{display: "flex", padding:"0 20px 0 0", alignItems: "center"}}>
            
            <Checkbox
              checked={props.checkedDoctorSearch}
              onChange={props.handleChangeChecked("checkedDoctorSearch", "selectedDoctorSearch")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              ชื่อหรือรหัสแพทย์
            </div>
            <div>
              {props.doctorSearch}
            </div>
          </div>
          <div
            style={{display: "flex", padding:"0 20px 0 0", alignItems: "center"}}>
            
            <Checkbox
              checked={props.checkedDrugSearch}
              onChange={props.handleChangeChecked("checkedDrugSearch", "selectedDrugSearch")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              ชื่อหรือรหัสยา
            </div>
            <div>
              {props.drugSearch}
            </div>
          </div>
          <div
            style={{display: "flex", padding:"0 20px 0 0", alignItems: "center"}}>
            
            <Checkbox
              checked={props.checkedFilterDate}
              onChange={props.handleChangeCheckedDate("checkedFilterDate")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              วันที่สั่งยา
            </div>
            <DateTextBox
              disabled={!props.checkedFilterDate}
              onChange={props.handleChangeDate("selectedStartDate")}
              value={props.selectedStartDate}>
            </DateTextBox>
            <DateTextBox
              disabled={!props.checkedFilterDate}
              onChange={props.handleChangeDate("selectedEndDate")}
              value={props.selectedEndDate}>
            </DateTextBox>
          </div>
          <div
            style={{display: "flex", padding:"0 20px 0 0", alignItems: "center"}}>
            
            <Checkbox
              checked={props.checkedDrugOrderType}
              onChange={props.handleChangeChecked("checkedDrugOrderType", "selectedDrugOrderType")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              ประเภทยา
            </div>
            <Dropdown
              clearable={true}
              disabled={!props.checkedDrugOrderType}
              multiple={true}
              onChange={props.handleChangeValue("selectedDrugOrderType")}
              options={props.drugOrderTypeOptions}
              selection={true}
              value={props.selectedDrugOrderType}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", padding:"0 20px 0 0", alignItems: "center"}}>
            
            <Checkbox
              checked={props.checkedDrugOrderStatus}
              onChange={props.handleChangeChecked("checkedDrugOrderStatus", "selectedDrugOrderStatus")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              สถานะยา
            </div>
            <Dropdown
              clearable={true}
              disabled={!props.checkedDrugOrderStatus}
              onChange={props.handleChangeValue("selectedDrugOrderStatus")}
              options={props.drugOrderStatusOptions}
              selection={true}
              value={props.selectedDrugOrderStatus}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", padding:"5px 20px 0 0", alignItems: "center"}}>
            
            <Checkbox
              checked={props.checkedDivisionPharma}
              onChange={props.handleChangeChecked("checkedDivisionPharma", "selectedDivisionPharma")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              ห้องยา
            </div>
            <Dropdown
              clearable={true}
              disabled={!props.checkedDivisionPharma}
              onChange={props.handleChangeValue("selectedDivisionPharma")}
              options={props.divisionPharmaOptions}
              selection={true}
              value={props.selectedDivisionPharma}>
            </Dropdown>
          </div>
          <Button
            color="yellow"
            onClick={props.handleSearchToday}
            size="tiny">
            วันนี้
          </Button>
          <Button
            color="yellow"
            onClick={props.handleSearchVaccine}
            size="tiny">
            วัคซีน
          </Button>
          <Button
            color="yellow"
            onClick={props.handleClearSearch}
            size="tiny">
            ล้างตัวกรอง
          </Button>
          <Button
            color="blue"
            onClick={props.handleOnSearch}
            size="tiny">
            ค้นหา
          </Button>
        </div>
        <div
          style={{fontWeight:"bold", fontSize: "1.2rem", paddingTop: "10px"}}>
          ใบยา
          <Table
            data={props.drugHistoryOrderList}
            getTrProps={props.rowDrugHistoryOrderProps}
            headers={`เลขที่ใบสั่งยา,ประเภท,หน่วยงานสั่ง,ผู้สั่ง,ผู้ป่วย,สถานะ,วันที่สั่ง${props.addToOrderMode ? "":", ดูใบสั่งยา"}`}
            keys={`code, type,request_division,doctor_full_name, patient_full_name,status,order_time${props.addToOrderMode ? "":",prescription"}`}
            minRows="8"
            showPagination={false}
            style={{height: "300px", marginTop: "5px"}}>
          </Table>
        </div>
        <div
          style={{fontWeight:"bold", fontSize: "1.2rem", paddingTop: "10px"}}>
          รายการยา
          <Table
            data={props.drugHistoryList}
            getTrProps={props.getTrDrugHistoryProps}
            headers={props.drugOrderHeaders ? props.drugOrderHeaders : `บัญชียา,รหัสยา,ชื่อยาและความแรง,ราคาต่อหน่วย,จำนวนจ่าย,หมายเหตุ`}
            keys={props.drugOrderKeys ? props.drugOrderKeys : `list_or_ned,code,name,price_unit,quantity,note_reason`}
            minRows="8"
            showPagination={false}
            style={{height: "300px", marginTop: "5px"}}
            widths={props.addToOrderMode ? "60":""}>
          </Table>
        </div>
        <div
          style={{display: props.showAddToOrder ? "flex": "none", justifyContent: "flex-end", width: "100%", marginTop: "1rem"}}>
          
          <Button
            color="green"
            onClick={props.onAddToOrder}
            size="tiny">
            ADD TO ORDER
          </Button>
        </div>
      </div>
    )
}


export default CardDrugOrderHistoryUX

export const screenPropsDefault = {"doctorSearch":"[Doctor Search Box]","drugSearch":"[Drug Search Box]","patientSearch":"[Patient Search Box]"}

/* Date Time : Thu Jan 18 2024 07:42:11 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "from": null,
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"0px\",minWidth:\"max-content\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0 15px\",display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"none\", alignItems: \"center\", flexWrap: \"wrap\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", fontSize: \"1.2rem\", paddingTop: \"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", fontSize: \"1.2rem\", paddingTop: \"10px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugHistoryOrderList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowDrugHistoryOrderProps"
        },
        "headers": {
          "type": "code",
          "value": "`เลขที่ใบสั่งยา,ประเภท,หน่วยงานสั่ง,ผู้สั่ง,ผู้ป่วย,สถานะ,วันที่สั่ง${props.addToOrderMode ? \"\":\", ดูใบสั่งยา\"}`"
        },
        "keys": {
          "type": "code",
          "value": "`code, type,request_division,doctor_full_name, patient_full_name,status,order_time${props.addToOrderMode ? \"\":\",prescription\"}`"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", marginTop: \"5px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 6,
      "name": "Table",
      "parent": 4,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugHistoryList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrDrugHistoryProps"
        },
        "headers": {
          "type": "code",
          "value": "props.drugOrderHeaders ? props.drugOrderHeaders : `บัญชียา,รหัสยา,ชื่อยาและความแรง,ราคาต่อหน่วย,จำนวนจ่าย,หมายเหตุ`"
        },
        "keys": {
          "type": "code",
          "value": "props.drugOrderKeys ? props.drugOrderKeys : `list_or_ned,code,name,price_unit,quantity,note_reason`"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", marginTop: \"5px\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.addToOrderMode ? \"60\":\"\""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Checkbox",
      "parent": 10,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPatientSearch"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPatientSearch\", \"selectedPatientSearch\")"
        },
        "style": {
          "type": "code",
          "value": "props.checkedPatientStyle"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.patientName"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding:\"0 20px 0 0\", alignItems: \"center\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding:\"0 20px 0 0\", alignItems: \"center\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Checkbox",
      "parent": 11,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDoctorSearch"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDoctorSearch\", \"selectedDoctorSearch\")"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อหรือรหัสแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearch"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding:\"0 20px 0 0\", alignItems: \"center\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Checkbox",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDrugSearch"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDrugSearch\", \"selectedDrugSearch\")"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อหรือรหัสยา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugSearch"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding:\"0 20px 0 0\", alignItems: \"center\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Checkbox",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedFilterDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeCheckedDate(\"checkedFilterDate\")"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สั่งยา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 23,
      "name": "DateTextBox",
      "parent": 19,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.checkedFilterDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedStartDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStartDate"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 24,
      "name": "DateTextBox",
      "parent": 19,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.checkedFilterDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedEndDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedEndDate"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding:\"0 20px 0 0\", alignItems: \"center\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Checkbox",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDrugOrderType"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDrugOrderType\", \"selectedDrugOrderType\")"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทยา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 25,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.checkedDrugOrderType"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDrugOrderType\")"
        },
        "options": {
          "type": "code",
          "value": "props.drugOrderTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugOrderType"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding:\"0 20px 0 0\", alignItems: \"center\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 30,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDrugOrderStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDrugOrderStatus\", \"selectedDrugOrderStatus\")"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะยา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Dropdown",
      "parent": 30,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.checkedDrugOrderStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDrugOrderStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.drugOrderStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugOrderStatus"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding:\"5px 20px 0 0\", alignItems: \"center\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 34,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDivisionPharma"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDivisionPharma\", \"selectedDivisionPharma\")"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ห้องยา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 34,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.checkedDivisionPharma"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivisionPharma\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionPharmaOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivisionPharma"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "วันนี้"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleSearchToday"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "วัคซีน"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleSearchVaccine"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างตัวกรอง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClearSearch"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleOnSearch"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormGroup",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormGroup",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.isDrugOrderHistory ? \"hidden\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Checkbox",
      "parent": 48,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDoctorSearch"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสแพทย์"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDoctorSearch\", \"selectedDoctorSearch\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"135px\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Checkbox",
      "parent": 50,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDrugSearch"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสยา"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDrugSearch\", \"selectedDrugSearch\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"115px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Checkbox",
      "parent": 52,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedFilterDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่สั่งยา"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeCheckedDate(\"checkedFilterDate\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"90px\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 63,
      "name": "DateTextBox",
      "parent": 53,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.checkedFilterDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedStartDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStartDate"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 64,
      "name": "DateTextBox",
      "parent": 54,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.checkedFilterDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedEndDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedEndDate"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Checkbox",
      "parent": 46,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPatientSearch"
        },
        "disabled": {
          "type": "code",
          "value": "props.addToOrderMode || false"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสผู้ป่วย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPatientSearch\", \"selectedPatientSearch\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"130px\", display: props.isDrugOrderHistory ? \"none\": \"\", marginRight: 0}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDrugOrderType"
        },
        "label": {
          "type": "value",
          "value": "ประเภทยา"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDrugOrderType\", \"selectedDrugOrderType\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"90px\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Checkbox",
      "parent": 68,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDrugOrderStatus"
        },
        "label": {
          "type": "value",
          "value": "สถานะยา"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDrugOrderStatus\", \"selectedDrugOrderStatus\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"85px\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Checkbox",
      "parent": 70,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDivisionPharma"
        },
        "label": {
          "type": "value",
          "value": "ห้องยา"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDivisionPharma\", \"selectedDivisionPharma\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.checkedDrugOrderType"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDrugOrderType\")"
        },
        "options": {
          "type": "code",
          "value": "props.drugOrderTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",minWidth:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugOrderType || \"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Dropdown",
      "parent": 69,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.checkedDrugOrderStatus"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDrugOrderStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.drugOrderStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",minWidth:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugOrderStatus"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Dropdown",
      "parent": 71,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.checkedDivisionPharma"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivisionPharma\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionPharmaOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",minWidth:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivisionPharma"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Button",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "วันนี้"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleSearchToday"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Button",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "วัคซีน"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleSearchToday"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Button",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างตัวกรอง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClearSearch"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{width: \"110px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Button",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleOnSearch"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientName"
        },
        "style": {
          "type": "code",
          "value": "{width: \"max-content\", display: props.isDrugOrderHistory ? \"\": \"none\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showAddToOrder ? \"flex\": \"none\", justifyContent: \"flex-end\", width: \"100%\", marginTop: \"1rem\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Button",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD TO ORDER"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddToOrder"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการจ่ายยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", fontSize: \"1.35rem\",}"
        }
      },
      "seq": 89,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugOrderHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "doctorSearch": "[Doctor Search Box]",
    "drugSearch": "[Drug Search Box]",
    "patientSearch": "[Patient Search Box]"
  },
  "width": 70
}

*********************************************************************************** */
