import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardBillingPaymentArUX = (props: any) => {
    return(
      <div
        style={{ width: "100%", padding: "10px" }}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem", display: "flex", justifyContent: "space-between", }}>
          บันทึกรับชำระเงินจากหน่วยงานต้นสังกัด
        </div>
        <div>
          
          <hr>
          </hr>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{ display: "flex", alignItems: "center" }}>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ display: "flex", marginRight: "10px", width: "65%" }}>
                  
                  <div
                    style={{ fontWeight: "bold" }}>
                    เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด
                  </div>
                  <div
                    style={{ color: "red" }}>
                    *
                  </div>
                </div>
                <div
                  style={{ width: "100%", }}>
                  {props.arTransaction}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ fontWeight: "bold", marginRight: "10px", width: "20%" }}>
                  สิทธิ
                </div>
                <div
                  style={{ background: "#F1F1F1", padding: "6px 15px", borderRadius: 4, width: "100%", marginRight: "20px" }}>
                  {props.selectPaymentAr?.coverage_code_name ||  "-"}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ fontWeight: "bold", marginRight: "10px", width: "55%" }}>
                  หน่วยงานต้นสังกัดที่เรียกเก็บ
                </div>
                <div
                  style={{ background: "#F1F1F1", padding: "6px 15px", borderRadius: 4, width: "100%", marginRight: "20px" }}>
                  {props.selectPaymentAr?.payer_code_name ||  "-"}
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ display: "flex", alignItems: "center" }}>
            <FormField
              inline={true}
              width={6}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ fontWeight: "bold", marginRight: "10px", width: "55%" }}>
                  ออกใบแจ้งหนี้โดย
                </div>
                <div
                  style={{ background: "#F1F1F1", padding: "6px 15px", borderRadius: 4, width: "100%", marginRight: "20px" }}>
                  {props.selectPaymentAr?.created_user_name ||  "-"}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ fontWeight: "bold", marginRight: "10px", width: "55%" }}>
                  วันที่ออกใบแจ้งหนี้
                </div>
                <div
                  style={{ background: "#F1F1F1", padding: "6px 15px", borderRadius: 4, width: "100%", marginRight: "20px" }}>
                  {props.selectPaymentAr?.created_at ||  "-"}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ fontWeight: "bold", marginRight: "10px", width: "55%" }}>
                  สถานะใบแจ้งหนี้
                </div>
                <div
                  style={{ background: "#F1F1F1", padding: "6px 15px", borderRadius: 4, width: "100%", marginRight: "20px" }}>
                  {props.selectPaymentAr?.sent_claim_status_display ||  "-"}
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ display: "flex", alignItems: "center" }}>
            <FormField
              inline={true}
              width={6}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ fontWeight: "bold", marginRight: "10px", width: "55%" }}>
                  จำนวนข้อมูลที่เบิกได้
                </div>
                <div
                  style={{ background: "#F1F1F1", padding: "6px 15px", borderRadius: 4, width: "100%", marginRight: "20px" }}>
                  {props.invoiceArItem?.total_claim_count || "-"}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}>
                
                <div
                  style={{ fontWeight: "bold", marginRight: "10px", width: "55%" }}>
                  ยอดเบิก (บาท)
                </div>
                <div
                  style={{ background: "#F1F1F1", padding: "6px 15px", borderRadius: 4, width: "100%", marginRight: "20px" }}>
                  {props.totalSendClaimPrice || "-"}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={5}>
            </FormField>
          </FormGroup>
        </Form>
        <div>
          {props.paymentStatue}
        </div>
        <div
          style={{ padding: "10px 0px", ...(props.orderBy !== "Mode" && { display: "none" }) }}>
          
          <Table
            data={props.arModeData}
            headers="รหัส,หมวดค่าใช้จ่าย,ส่งเบิก,รับชำระ"
            keys="bill_mode_code,bill_mode_name,total_send_claim_price,total_pay_price"
            minRows={10}
            showPagination={false}
            style={{ height: "550px" }}
            widths="^30,^150,^60,^60">
          </Table>
        </div>
        <div
          style={{ padding: "10px 0px", ...(props.orderBy !== "Item" && { display: "none" }) }}>
          
          <Table
            data={props.arItemData}
            headers=",รหัส,ชื่อรายการ,หมวดค่าใช้จ่าย,จำนวน,ส่งเบิก,รับชำระ,ค้างชำระ"
            keys="chk,product_code,product_name,bill_mode_name,quantity,total_send_claim_price,total_pay_price,unpaidValue"
            minRows="10"
            showPagination={false}
            style={{ height: "550px" }}
            widths="^30,^40,^140,^140,^60,^60,^80,^60">
          </Table>
        </div>
        <div
          style={{ display: "flex" }}>
          
          <div
            style={{ display: "flex" }}>
            
            <Button
              className={props.orderBy === "Mode" && "active"}
              color="teal"
              id="btn-billMode"
              name="Mode"
              onClick={props.onChangeOrderBy}
              size="mini">
              BY MODE
            </Button>
            <Button
              className={props.orderBy === "Item" && "active"}
              color="teal"
              id="btn-billItem"
              name="Item"
              onClick={props.onChangeOrderBy}
              size="mini">
              BY ITEM
            </Button>
          </div>
          <div
            style={{ margin: "0px 50px" }}>
            
          </div>
          <div
            style={{ display: "none", ...(props.orderBy === "Item" && { display: "flex", justifyContent: "center", alignItems: "center" }) }}>
            
            <div
              style={{ marginRight: "10px" }}>
              Select:
            </div>
            <Button
              id="btn-billAll"
              onClick={props.onClickAll}
              size="mini">
              All
            </Button>
            <Button
              id="btn-billNone"
              onClick={props.onClickNone}
              size="mini"
              style={{ marginLeft: "10px" }}>
              None
            </Button>
          </div>
          <div
            style={{ flex: 1 }}>
            
          </div>
          <div
            style={{ display: "flex" }}>
            
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}>
              
              <div
                style={{ margin: "0px 10px", width: "55%" }}>
                ส่งเบิก
              </div>
              <Input
                className={props.classes.inputSummary}
                readOnly="true"
                size="mini"
                style={{ width: "62%" }}
                value={props.invoiceTotalSendClaimPrice}>
              </Input>
            </div>
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}>
              
              <div
                style={{ margin: "0px 10px", width: "55%" }}>
                รับชำระ
              </div>
              <Input
                className={props.classes.inputSummary}
                readOnly="true"
                size="mini"
                style={{ width: "62%" }}
                value={props.invoiceTotalRemainPrice}>
              </Input>
            </div>
            <div
              style={{ display: "none", ...(props.orderBy === "Item" && { display: "flex", width: "100%", alignItems: "center"  })}}>
              
              <div
                style={{ margin: "0px 10px", width: "55%" }}>
                ค้างชำระ
              </div>
              <Input
                className={Number(props.invoiceSummary?.total_paid_price) !== 0 ? props.classes.inputColorRed : props.classes.inputSummary}
                readOnly="true"
                size="mini"
                style={{ width: "62%",  }}
                value={props.invoiceTotalPaidPrice}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", padding: "15px 0px" }}>
          
          <div
            style={{ margin: "0px 10px" }}>
            {props.buttonPrint}
          </div>
          <Button
            color="green"
            disabled={props.disabledPayment}
            id="btn-billPayment"
            onClick={props.billPayment}>
            รับชำระเงิน
          </Button>
        </div>
      </div>
    )
}


export default CardBillingPaymentArUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Mon May 20 2024 09:30:21 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกรับชำระเงินจากหน่วยงานต้นสังกัด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\", display: \"flex\", justifyContent: \"space-between\", }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "hr",
      "parent": 128,
      "props": {
      },
      "seq": 129,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormGroup",
      "parent": 226,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "FormGroup",
      "parent": 226,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "FormGroup",
      "parent": 226,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormField",
      "parent": 228,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "FormField",
      "parent": 228,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormField",
      "parent": 228,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormField",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormField",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormField",
      "parent": 229,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": null,
      "id": 239,
      "name": "div",
      "parent": 232,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": null,
      "id": 240,
      "name": "div",
      "parent": 231,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 240,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\", width: \"20%\" }"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "div",
      "parent": 239,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานต้นสังกัดที่เรียกเก็บ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\", width: \"55%\" }"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "div",
      "parent": 241,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginRight: \"10px\", width: \"65%\" }"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "div",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "div",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "div",
      "parent": 241,
      "props": {
        "children": {
          "type": "code",
          "value": "props.arTransaction"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", }"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "div",
      "parent": 240,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectPaymentAr?.coverage_code_name ||  \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ background: \"#F1F1F1\", padding: \"6px 15px\", borderRadius: 4, width: \"100%\", marginRight: \"20px\" }"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 239,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectPaymentAr?.payer_code_name ||  \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ background: \"#F1F1F1\", padding: \"6px 15px\", borderRadius: 4, width: \"100%\", marginRight: \"20px\" }"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 257,
      "name": "div",
      "parent": 233,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": null,
      "id": 258,
      "name": "div",
      "parent": 234,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "div",
      "parent": 235,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 260,
      "name": "div",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "div",
      "parent": 237,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 257,
      "props": {
        "children": {
          "type": "value",
          "value": "ออกใบแจ้งหนี้โดย"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\", width: \"55%\" }"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": null,
      "id": 264,
      "name": "div",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ออกใบแจ้งหนี้"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\", width: \"55%\" }"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": null,
      "id": 265,
      "name": "div",
      "parent": 257,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectPaymentAr?.created_user_name ||  \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ background: \"#F1F1F1\", padding: \"6px 15px\", borderRadius: 4, width: \"100%\", marginRight: \"20px\" }"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "div",
      "parent": 258,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectPaymentAr?.created_at ||  \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ background: \"#F1F1F1\", padding: \"6px 15px\", borderRadius: 4, width: \"100%\", marginRight: \"20px\" }"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "div",
      "parent": 259,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะใบแจ้งหนี้"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\", width: \"55%\" }"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 268,
      "name": "div",
      "parent": 259,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectPaymentAr?.sent_claim_status_display ||  \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ background: \"#F1F1F1\", padding: \"6px 15px\", borderRadius: 4, width: \"100%\", marginRight: \"20px\" }"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": null,
      "id": 269,
      "name": "div",
      "parent": 260,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนข้อมูลที่เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\", width: \"55%\" }"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": null,
      "id": 270,
      "name": "div",
      "parent": 260,
      "props": {
        "children": {
          "type": "code",
          "value": "props.invoiceArItem?.total_claim_count || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ background: \"#F1F1F1\", padding: \"6px 15px\", borderRadius: 4, width: \"100%\", marginRight: \"20px\" }"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": null,
      "id": 271,
      "name": "div",
      "parent": 261,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดเบิก (บาท)"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\", width: \"55%\" }"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": null,
      "id": 272,
      "name": "div",
      "parent": 261,
      "props": {
        "children": {
          "type": "code",
          "value": "props.totalSendClaimPrice || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ background: \"#F1F1F1\", padding: \"6px 15px\", borderRadius: 4, width: \"100%\", marginRight: \"20px\" }"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px 0px\", ...(props.orderBy !== \"Mode\" && { display: \"none\" }) }"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 276,
      "name": "Table",
      "parent": 274,
      "props": {
        "data": {
          "type": "code",
          "value": "props.arModeData"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "รหัส,หมวดค่าใช้จ่าย,ส่งเบิก,รับชำระ"
        },
        "keys": {
          "type": "value",
          "value": "bill_mode_code,bill_mode_name,total_send_claim_price,total_pay_price"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"550px\" }"
        },
        "widths": {
          "type": "value",
          "value": "^30,^150,^60,^60"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\", ...(props.orderBy === \"Item\" && { display: \"flex\", justifyContent: \"center\", alignItems: \"center\" }) }"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Button",
      "parent": 277,
      "props": {
        "children": {
          "type": "value",
          "value": "BY MODE"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Mode\" && \"active\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billMode"
        },
        "name": {
          "type": "value",
          "value": "Mode"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "Button",
      "parent": 277,
      "props": {
        "children": {
          "type": "value",
          "value": "BY ITEM"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Item\" && \"active\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billItem"
        },
        "name": {
          "type": "value",
          "value": "Item"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": null,
      "id": 282,
      "name": "div",
      "parent": 278,
      "props": {
        "children": {
          "type": "value",
          "value": "Select:"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "Button",
      "parent": 278,
      "props": {
        "children": {
          "type": "value",
          "value": "All"
        },
        "id": {
          "type": "value",
          "value": "btn-billAll"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAll"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "Button",
      "parent": 278,
      "props": {
        "children": {
          "type": "value",
          "value": "None"
        },
        "id": {
          "type": "value",
          "value": "btn-billNone"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickNone"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": null,
      "id": 285,
      "name": "div",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "div",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": null,
      "id": 287,
      "name": "div",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\", ...(props.orderBy === \"Item\" && { display: \"flex\", width: \"100%\", alignItems: \"center\"  })}"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "div",
      "parent": 285,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\", width: \"55%\" }"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 289,
      "name": "div",
      "parent": 286,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\", width: \"55%\" }"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": null,
      "id": 290,
      "name": "div",
      "parent": 287,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้างชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\", width: \"55%\" }"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "Input",
      "parent": 285,
      "props": {
        "className": {
          "type": "code",
          "value": "props.classes.inputSummary"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"62%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceTotalSendClaimPrice"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "Input",
      "parent": 286,
      "props": {
        "className": {
          "type": "code",
          "value": "props.classes.inputSummary"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"62%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceTotalRemainPrice"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "Input",
      "parent": 287,
      "props": {
        "className": {
          "type": "code",
          "value": "Number(props.invoiceSummary?.total_paid_price) !== 0 ? props.classes.inputColorRed : props.classes.inputSummary"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"62%\",  }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceTotalPaidPrice"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": null,
      "id": 294,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1 }"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": null,
      "id": 295,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", padding: \"15px 0px\" }"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "Button",
      "parent": 295,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPayment"
        },
        "id": {
          "type": "value",
          "value": "btn-billPayment"
        },
        "onClick": {
          "type": "code",
          "value": "props.billPayment"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px 0px\", ...(props.orderBy !== \"Item\" && { display: \"none\" }) }"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 50px\" }"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 300,
      "name": "Table",
      "parent": 298,
      "props": {
        "data": {
          "type": "code",
          "value": "props.arItemData"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": ",รหัส,ชื่อรายการ,หมวดค่าใช้จ่าย,จำนวน,ส่งเบิก,รับชำระ,ค้างชำระ"
        },
        "keys": {
          "type": "value",
          "value": "chk,product_code,product_name,bill_mode_name,quantity,total_send_claim_price,total_pay_price,unpaidValue"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"550px\" }"
        },
        "widths": {
          "type": "value",
          "value": "^30,^40,^140,^140,^60,^60,^80,^60"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 301,
      "name": "div",
      "parent": 295,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 302,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paymentStatue"
        }
      },
      "seq": 274,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingPaymentArUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 55
}

*********************************************************************************** */
