import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Dropdown,
  Button,
  Checkbox,
  Input
} from 'semantic-ui-react'

const CardSettingAppointmentReminderUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px" }}>
        <div
          style={{padding: "10px 10px 15px", fontSize: "1.2rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          ตารางกำหนดแจ้งเตือนนัดหมายให้ผู้ป่วยของคลินิก
        </div>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          
          <div
            style={{ display: "flex", alignItems:  "center", width: "50%" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight: "20px" }}>
              แผนก
            </div>
            <Dropdown
              clearable={true}
              multiple={true}
              onChange={props.handleChangeValue("searchDivision")}
              options={props.masterOptions?.division}
              search={true}
              selection={true}
              style={{ marginRight: "20px", width: "100%" }}
              value={props.appointmentReminderOrder?.searchDivision}>
            </Dropdown>
            <Button
              color="teal"
              onClick={props.onSearchAppointmentReminder}
              size="small">
              ค้นหา
            </Button>
          </div>
          <div>
            
            <Button
              color="green"
              onClick={props.onOpenAppointmentReminder}
              size="small">
              เพิ่มการกำหนดคิว
            </Button>
          </div>
        </div>
        <Table
          data={props.appointmentReminderQueue}
          headers="แผนก,จำนวนวันแจ้งยืนยันนัดหมาย (ก่อนกี่วัน),จำนวนชั่วโมงแจ้งยืนยันนัดหมาย (ก่อนกี่ชั่วโมง),แก้ไข,ลบ"
          keys="division,confirmDay,incoming,edit,delete"
          minRows="5"
          showPagination={false}
          style={{height: "300px", marginTop: "0.5rem"}}
          widths="{ ....,...,...,100px,100px }">
        </Table>
        <div
          style={{ margin: "20px 0px", padding: "20px", border:  "1.5px solid rgba(26, 179, 203, 1)", ...(!props.openAppointmentReminder && {display: "none" }) }}>
          
          <div
            style={{width: "100%"}}>
            {props.errorRemind}
          </div>
          <div
            style={{display: "flex", alignItems: "center", width: "55%" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight: "20px" }}>
              แผนก
            </div>
            <Dropdown
              clearable={true}
              multiple={true}
              onChange={props.handleChangeValue("selectDivision")}
              options={props.masterOptions?.division}
              search={true}
              selection={true}
              style={{ marginRight: "20px", width: "70%" , display: props.appointmentReminderOrder?.selectAllDivision && "none"}}
              value={props.appointmentReminderOrder?.selectDivision }>
            </Dropdown>
            <Input
              readOnly="true"
              style={{ marginRight: "20px", width: "70%" , display: !props.appointmentReminderOrder?.selectAllDivision && "none"}}
              value="ืทั้งหมด">
            </Input>
            <Checkbox
              checked={props.appointmentReminderOrder?.selectAllDivision}
              label="แผนกทั้งหมด"
              name="selectAllDivision"
              onChange={props.handleChangeSelectAllDivision}>
            </Checkbox>
          </div>
          <div
            style={{display: "flex",  marginTop: "20px", alignItems: "flex-start" }}>
            
            <div
              style={{display: "flex", alignItems: "center", marginTop: "20px" }}>
              
              <label
                style={{ fontWeight: "bold" ,marginRight: "20px" }}>
                จำนวนวันแจ้งยืนยันนัดหมาย
              </label>
              <label>
                ก่อนถึงวันนัดหมาย
              </label>
              <label
                style={{color:"red",margin: " 0 20px 0 5px"}}>
                *
              </label>
              <Input
                min="0"
                onChange={props.handleChangeValueConfirmDay}
                style={{ marginRight: "20px" }}
                type="number"
                value={props.appointmentReminderOrder?.confirmDay || 0}>
              </Input>
              <label
                style={{ fontWeight: "bold" ,marginRight: "20px" }}>
                วัน
              </label>
              <label
                style={{ fontWeight: "bold" ,marginRight: "20px" }}>
                จำนวนชั่วโมงแจ้งยืนยันตามนัดหมาย
              </label>
              <label
                style={{ marginRight: "20px", whiteSpace: "pre-line", }}>
                {`ก่อนถึงวันนัดหมาย \n(หลังยืนยันแล้ว)`}
              </label>
            </div>
            <div
              style={{display: "flex", marginTop: "20px",  alignItems: "center", }}>
              
              <div>
                {props.incomingData}
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
            
            <Button
              color="green"
              onClick={props.onSaveAppointmentReminder}
              size="small"
              style={{ margin: "0px 10px" }}>
              บันทึก
            </Button>
            <Button
              color="red"
              onClick={props.onCloseAppointmentReminder}
              size="small">
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardSettingAppointmentReminderUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Mon Feb 05 2024 12:51:06 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ตารางกำหนดแจ้งเตือนนัดหมายให้ผู้ป่วยของคลินิก"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 15px\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 10,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.appointmentReminderQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "แผนก,จำนวนวันแจ้งยืนยันนัดหมาย (ก่อนกี่วัน),จำนวนชั่วโมงแจ้งยืนยันนัดหมาย (ก่อนกี่ชั่วโมง),แก้ไข,ลบ"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": "division,confirmDay,incoming,edit,delete"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", marginTop: \"0.5rem\"}"
        },
        "widths": {
          "type": "value",
          "value": "{ ....,...,...,100px,100px }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems:  \"center\", width: \"50%\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"searchDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.appointmentReminderOrder?.searchDivision"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Button",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchAppointmentReminder"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มการกำหนดคิว"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenAppointmentReminder"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px 0px\", padding: \"20px\", border:  \"1.5px solid rgba(26, 179, 203, 1)\", ...(!props.openAppointmentReminder && {display: \"none\" }) }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"55%\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"70%\" , display: props.appointmentReminderOrder?.selectAllDivision && \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.appointmentReminderOrder?.selectDivision "
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.appointmentReminderOrder?.selectAllDivision"
        },
        "label": {
          "type": "value",
          "value": "แผนกทั้งหมด"
        },
        "name": {
          "type": "value",
          "value": "selectAllDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeSelectAllDivision"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Input",
      "parent": 67,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"70%\" , display: !props.appointmentReminderOrder?.selectAllDivision && \"none\"}"
        },
        "value": {
          "type": "value",
          "value": "ืทั้งหมด"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  marginTop: \"20px\", alignItems: \"flex-start\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginTop: \"20px\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", marginTop: \"20px\",  alignItems: \"center\", }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนวันแจ้งยืนยันนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "ก่อนถึงวันนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Input",
      "parent": 93,
      "props": {
        "min": {
          "type": "value",
          "value": "0"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValueConfirmDay"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.appointmentReminderOrder?.confirmDay || 0"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginTop: \"20px\" }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveAppointmentReminder"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCloseAppointmentReminder"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนชั่วโมงแจ้งยืนยันตามนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "code",
          "value": "`ก่อนถึงวันนัดหมาย \\n(หลังยืนยันแล้ว)`"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", whiteSpace: \"pre-line\", }"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "code",
          "value": "props.incomingData"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\",margin: \" 0 20px 0 5px\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorRemind"
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 67,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingAppointmentReminderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
