import React, { useState, useRef, useEffect } from "react";
import { Form, TextArea } from "semantic-ui-react";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import { useIntl } from "react-intl";

// Types
type ModCancelAdmitOrderProps = {
  // data
  open: boolean;
  title?: string;
  loading: boolean;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  card: string;
  // config
  type?: "room_admit" | "cancel_reserve" | "cancel_admit";
  // callback
  onApprove?: (note: string) => any;
  onDeny?: () => any;
};

const ModCancelAdmitOrder: React.FC<ModCancelAdmitOrderProps> = (props) => {
  const intl = useIntl();
  const [note, setNote] = useState<string>("");

  const textareaRef = useRef<any>();
  const isTextareaChange = useRef<boolean>(false);

  useEffect(() => {
    if (isTextareaChange.current) {
      textareaRef.current?.ref?.current?.focus();
    }
  }, [note]);
  useEffect(() => {
    if (!!props.successMessage?.[props.card]) {
      handleDeny();
    }
  }, [props.successMessage]);

  const handleChangeNote = (e: any, v: any) => {
    isTextareaChange.current = true;
    setNote(v.value);
  };

  const handleApprove = () => {
    props.onApprove?.(note);
  };

  const handleDeny = () => {
    setNote("");
    props.onDeny?.();
  };

  return (
    <ModConfirm
      openModal={props.open}
      titleName={props.title}
      loading={props.loading}
      titleColor="blue"
      onApprove={handleApprove}
      onDeny={handleDeny}
      // @ts-ignore
      content={
        props.type === "room_admit" ? (
          <div style={{ margin: "-2rem 0" }}></div>
        ) : ["cancel_reserve", "cancel_admit"].includes(props.type || "") ? (
          <Form style={{ margin: "-1rem 0 -2rem" }}>
            <ErrorMessage error={props.errorMessage?.[props.card]} />
            <Form.Field required>
              <label>{intl.formatMessage({ id: "เหตุผลการในยกเลิก" })}</label>
              <TextArea
                ref={textareaRef}
                value={note}
                onChange={handleChangeNote}
              />
            </Form.Field>
          </Form>
        ) : null
      }
    />
  );
};

export default React.memo(ModCancelAdmitOrder);
