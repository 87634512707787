import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Dropdown,
  Input,
  Radio,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardPatientAssessmentUX = (props: any) => {
    return(
      <div>
        <div
          style={{ margin: "15px" }}>
          
          <div
            style={{ fontSize: "1.3rem", fontWeight: "bold",  }}>
            แบบประเมินแรกรับ
          </div>
          <div>
            {props.errorMessage}
          </div>
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{ marginRight: "10px", width: "20%" }}>
                  Date
                </div>
                <div>
                  {props.date}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px", width: "35%", textAlign: "right" }}>
                    Chief Complaint
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      fluid={true}
                      name="chief_complaint"
                      onChange={props.changeForm}
                      value={props.patientAssessment?.chief_complaint || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px", width: "20%" }}>
                    Arrived by
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      allowAdditions={true}
                      compact={true}
                      fluid={true}
                      name="arrive_status"
                      onAddItem={props.handleAddition}
                      onChange={props.changeForm}
                      options={props.arrivedByOptions}
                      selection={true}
                      value={props.patientAssessment?.arrive_status || ""}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px", width: "35%", textAlign: "right" }}>
                    ระดับการคัดกรอง
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      fluid={true}
                      name="triage_level"
                      onChange={props.changeForm}
                      options={props.triageLevelOptions}
                      selection={true}
                      value={props.patientAssessment?.triage_level || ""}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{ marginRight: "10px" }}>
                  ใช้ล่าม
                </div>
                <div>
                  
                  <Checkbox
                    checked={props.patientAssessment?.translator}
                    label={props.patientAssessment?.translator ? "ใช้" :  " ไม่ใช้"}
                    name="translator"
                    onChange={props.changeForm}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(!props.patientAssessment?.translator && { display: "none" }) }}
                width={5}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px", width: "35%", textAlign: "right" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      fluid={true}
                      name="translator_specific"
                      onChange={props.changeForm}
                      value={props.patientAssessment?.translator_specific || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={16}>
                <div
                  style={{ borderBottom: "solid 1px rgb(200, 200, 200, 0.5)", width: "100%" }}>
                  
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  ผู้บันทึก/แก้ไข
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div>
                  {props.user}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  Functional Assessment
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    name="functional"
                    onChange={props.changeForm}
                    options={props.masterOptions?.functional}
                    selection={true}
                    value={props.patientAssessment?.functional || ""}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      fluid={true}
                      name="functional_assm_other"
                      onChange={props?.changeForm}
                      size="mini"
                      value={props.patientAssessment?.functional_assm_other || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(props.version === "0.2" && { display: "none" }) }}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  Psychological Assessment
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Checkbox
                    checked={props.patientAssessment?.psychological}
                    label={props.patientAssessment?.psychological ? "แสดงออกทางอารมณ์และพฤติกรรมผิดปกติ" : "แสดงออกทางอารมณ์และพฤติกรรมปกติ"}
                    name="psychological"
                    onChange={props.changeForm}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(!props.patientAssessment?.psychological && { display: "none" }) }}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" ,...(!props.patientAssessment?.psychological && { display: "none" }) }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      fluid={true}
                      name="psychological_specific"
                      onChange={props?.changeForm}
                      value={props.patientAssessment?.psychological_specific || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    การให้การพยาบาล
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      fluid={true}
                      name="phyco_nurse_note"
                      onChange={props.changeForm}
                      options={props.phychoNurseNoteOptions}
                      selection={true}
                      value={props.patientAssessment?.phyco_nurse_note || ""}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(props.patientAssessment?.phyco_nurse_note === "NON" && { display: "none" } ) }}
                width={2}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <Input
                    fluid={true}
                    name="phyco_nurse_note_specific"
                    onChange={props.changeForm}
                    placeholder="ระบุ"
                    value={props.patientAssessment?.phyco_nurse_note_specific || ""}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ alignItems: "start", ...(props.version !== "0.2" && { display: "none" }) }}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  Psychological Assessment
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", alignItems: "top", }}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Checkbox
                    checked={props.patientAssessment?.psychologicalV2}
                    label={props.patientAssessment?.psychologicalV2 ? "แสดงออกทางอารมณ์และพฤติกรรมผิดปกติ" : "แสดงออกทางอารมณ์และพฤติกรรมปกติ"}
                    name="psychologicalV2"
                    onChange={props.changeForm}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(!props.patientAssessment?.psychologicalV2 && { display: "none" }) }}
                width={3}>
                <div
                  style={{ width: "100%"  }}>
                  
                  <Radio
                    checked={props.patientAssessment?.psychological_select === "1"}
                    label="ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ รู้สึกหดหู่ เศร้า หรือท้อแท้ สิ้นหวัง"
                    name="psychological_select"
                    onChange={props.changeForm}
                    value="1">
                  </Radio>
                  <Radio
                    checked={props.patientAssessment?.psychological_select === "2"}
                    label="ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ รู้สึกเบื่อ ทำอะไรก็ไม่เพลิดเพลิน"
                    name="psychological_select"
                    onChange={props.changeForm}
                    value="2">
                  </Radio>
                  <Radio
                    checked={props.patientAssessment?.psychological_select === "3"}
                    label="มีอาการหรือพฤติกรรมอื่นๆ ผิดปกติ"
                    name="psychological_select"
                    onChange={props.changeForm}
                    value="3">
                  </Radio>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(!props.patientAssessment?.psychologicalV2 && { display: "none" }) }}
                width="4">
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      fluid={true}
                      multiple={true}
                      name="psychological_detail"
                      onChange={props.changeForm}
                      options={props.psychologicalDetailOptions}
                      selection={true}
                      value={props.patientAssessment?.psychological_detail}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(!(props.patientAssessment?.psychologicalV2 && (props.patientAssessment?.psychological_detail || []).includes(6)) && { display: "none" }) }}
                width="2">
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      fluid={true}
                      name="psychological_text"
                      onChange={props.changeForm}
                      placeholder="ระบุ"
                      value={props.patientAssessment?.psychological_text}>
                    </Input>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(props.version !== "0.2" && { display: "none" }) }}>
              <FormField
                inline={true}
                width={3}>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px", width: "80%" }}>
                    การให้การพยาบาลผู้ป่วย
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      fluid={true}
                      name="phyco_nurse_noteV2"
                      onChange={props.changeForm}
                      options={props.phycoNurseNoteOptions}
                      selection={true}
                      value={props.patientAssessment?.phyco_nurse_noteV2}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(props.patientAssessment?.phyco_nurse_noteV2 !== "REP" && { display: "none" }) }}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      fluid={true}
                      name="phyco_nurse_note_specific_detail"
                      onChange={props.changeForm}
                      options={props.phycoNurseSpecificOptions}
                      selection={true}
                      value={props.patientAssessment?.phyco_nurse_note_specific_detail}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  Spiritual/Cultural Assessment
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Checkbox
                    checked={props.patientAssessment?.spiritual}
                    label={props.patientAssessment?.spiritual ? "YES" : "No"}
                    name="spiritual"
                    onChange={props.changeForm}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" ,...(!props.patientAssessment?.spiritual && { display: "none" }) }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      fluid={true}
                      name="spiritual_detail"
                      onChange={props?.changeForm}
                      options={props.masterOptions?.belief}
                      selection={true}
                      value={props.patientAssessment?.spiritual_detail}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  Suspected abuse or neglect
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    name="suspected"
                    onChange={props.changeForm}
                    options={props.suspectedOptions}
                    selection={true}
                    value={props.patientAssessment?.suspected}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      fluid={true}
                      name="suspected_specific"
                      onChange={props?.changeForm}
                      value={props.patientAssessment?.suspected_specific || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    การให้การพยาบาล
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      fluid={true}
                      name="sus_nurse_note"
                      onChange={props.changeForm}
                      options={props.susNurseNoteOptions}
                      selection={true}
                      value={props.patientAssessment?.sus_nurse_note}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(props.patientAssessment?.sus_nurse_note === "NON" && { display: "none" } ) }}
                width={2}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <Input
                    fluid={true}
                    name="sus_nurse_note_specific"
                    onChange={props.changeForm}
                    placeholder="ระบุ"
                    value={props.patientAssessment?.sus_nurse_note_specific}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  History of Febrile convulsion
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    name="convulsion"
                    onChange={props.changeForm}
                    options={props.convulsionOptions}
                    selection={true}
                    value={props.patientAssessment?.convulsion}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  เสี่ยงต่อการติดเชื้อ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  {}
                  <Dropdown
                    fluid={true}
                    name="mdr_triage"
                    onChange={props.changeForm}
                    options={props.mdrOptions}
                    selection={true}
                    value={props.patientAssessment?.mdr_triage}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" ,...(props.patientAssessment?.mdr_triage !== "YES" && { display: "none" }) }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    MDR
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Checkbox
                      checked={props.patientAssessment?.mdr}
                      label={props.patientAssessment?.mdr ? "YES" : "NO"}
                      name="mdr"
                      onChange={props.changeForm}
                      toggle={true}>
                    </Checkbox>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  Isolate Precaution
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    multiple={true}
                    name="precaution"
                    onChange={props.changeForm}
                    options={props.precautionOptions}
                    selection={true}
                    value={props.patientAssessment?.precaution}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  ประวัติการดื่มสุรา
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    name="alcohol"
                    onChange={props.changeForm}
                    options={props.alcoholOptions}
                    selection={true}
                    value={props.patientAssessment?.alcohol}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" ,...(!["YES", "Past"].includes(props.patientAssessment?.alcohol) && { display: "none" }) }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      fluid={true}
                      name="alcohol_specific"
                      onChange={props?.changeForm}
                      options={props.alcoholSpecificOptions}
                      selection={true}
                      value={props.patientAssessment?.alcohol_specific}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  ประวัติการสูบบุหรี่
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    name="tobacco"
                    onChange={props.changeForm}
                    options={props.tobaccoOptions}
                    selection={true}
                    value={props.patientAssessment?.tobacco}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" ,...(!["YES", "Past"].includes(props.patientAssessment?.tobacco)  && { display: "none" }) }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      fluid={true}
                      name="tobacco_specific"
                      onChange={props?.changeForm}
                      options={props.tobaccoSpecificOptions}
                      selection={true}
                      value={props.patientAssessment?.tobacco_specific}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  ประวัติการใช้สารเสพติด
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    name="narcotic"
                    onChange={props.changeForm}
                    options={props.narcoticOptions}
                    selection={true}
                    value={props.patientAssessment?.narcotic}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" ,...(props.patientAssessment?.narcotic !== "YES" && { display: "none" }) }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      fluid={true}
                      name="narcotic_specific"
                      onChange={props?.changeForm}
                      value={props.patientAssessment?.narcotic_specific}>
                    </Input>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "flex", alignItems: "center", ...(!props.isShowPregnancy && {display: "none"})}}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  ประวัติการตั้งครรภ์
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <Radio
                    checked={props.pregnancyAssessment?.pregnancy_status === 1}
                    label="ไม่ทราบ"
                    name="pregnancy_status"
                    onChange={props.changePregnancy}
                    style={{ marginRight: "20px" }}
                    value={1}>
                  </Radio>
                  <Radio
                    checked={props.pregnancyAssessment?.pregnancy_status === 2}
                    label="ไม่ตั้งครรภ์"
                    name="pregnancy_status"
                    onChange={props.changePregnancy}
                    style={{ marginRight: "20px" }}
                    value={2}>
                  </Radio>
                  <Radio
                    checked={props.pregnancyAssessment?.pregnancy_status === 3}
                    label="กำลังตั้งครรภ์"
                    name="pregnancy_status"
                    onChange={props.changePregnancy}
                    style={{ marginRight: "20px" }}
                    value={3}>
                  </Radio>
                  <div
                    style={{ marginRight: "20px", ...(props.encounterData?.pregnancy_status !== 3 && {display: "none"})}}>
                    ระบุไตรมาส
                  </div>
                  <Dropdown
                    name="pregnancy_period"
                    onChange={props.changePregnancy}
                    options={props.pregnancyPeriodOptions}
                    selection={true}
                    style={{ marginRight: "20px", ...(props.pregnancyAssessment?.pregnancy_status !== 3 && {display: "none"})}}
                    value={props.pregnancyAssessment?.pregnancy_period}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "flex", alignItems: "center", ...(!props.isMenstruation && {display: "none"})}}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ paddingRight: "10px", textAlign: "right", width: "100%" }}>
                  Menstruation
                </div>
              </FormField>
              <FormField
                inline={true}
                width="4">
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    name="menstruation"
                    onChange={props.changeForm}
                    options={props.menstruationOptions}
                    selection={true}
                    value={props.patientAssessment?.menstruation}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ width: "100%", alignItems: "center", display: "none", ...(props.patientAssessment?.menstruation === "LMP" && { display: "flex" }) }}>
                    
                    <DateTextBox
                      onChange={props.changeDate("lmp")}
                      value={props.patientAssessment?.lmp}>
                    </DateTextBox>
                  </div>
                  <div
                    style={{ width: "100%", alignItems: "center", display: "none", ...(props.patientAssessment?.menstruation === "other" && { display: "flex" }) }}>
                    
                    <div
                      style={{ marginRight: "20px" }}>
                      ระบุ
                    </div>
                    <Input
                      name="menstruationOther"
                      onChange={props.changeForm}
                      value={props.patientAssessment?.menstruationOther}>
                    </Input>
                  </div>
                </div>
              </FormField>
            </FormGroup>
          </Form>
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}>
            
            <Button
              color="blue"
              onClick={props.onClear}
              size="mini">
              Clear
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardPatientAssessmentUX

export const screenPropsDefault = {"alcohol":"YES","mdr":true,"mdr_triage":"YES","menstruation":"LMP","narcotic":"YES","psychological":true,"spiritual":true,"tobacco":"YES","translator":true}

/* Date Time : Wed Sep 25 2024 17:33:53 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "แบบประเมินแรกรับ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.3rem\", fontWeight: \"bold\",  }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Form",
      "parent": 108,
      "props": {
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 111,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 111,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "Date"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"20%\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "code",
          "value": "props.date"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 124,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "FormField",
      "parent": 124,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.patientAssessment?.translator && { display: \"none\" }) }"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "ใช้ล่าม"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "Checkbox",
      "parent": 137,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.patientAssessment?.translator"
        },
        "label": {
          "type": "code",
          "value": "props.patientAssessment?.translator ? \"ใช้\" :  \" ไม่ใช้\""
        },
        "name": {
          "type": "value",
          "value": "translator"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้บันทึก/แก้ไข"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.version === \"0.2\" && { display: \"none\" }) }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 147,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 148,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 149,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 152,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormField",
      "parent": 154,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormField",
      "parent": 155,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 156,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": "Functional Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ borderBottom: \"solid 1px rgb(200, 200, 200, 0.5)\", width: \"100%\" }"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "Spiritual/Cultural Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": "Suspected abuse or neglect"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": "History of Febrile convulsion"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "เสี่ยงต่อการติดเชื้อ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "Isolate Precaution"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการดื่มสุรา"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการสูบบุหรี่"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการใช้สารเสพติด"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 156,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormField",
      "parent": 155,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormField",
      "parent": 154,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "FormField",
      "parent": 152,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 149,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormField",
      "parent": 148,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 190,
      "props": {
        "children": {
          "type": "code",
          "value": "props.user"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 189,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "div",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "div",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "div",
      "parent": 183,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "div",
      "parent": 179,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "Dropdown",
      "parent": 192,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "functional"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.functional"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.functional || \"\""
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "Checkbox",
      "parent": 193,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.patientAssessment?.psychological"
        },
        "label": {
          "type": "code",
          "value": "props.patientAssessment?.psychological ? \"แสดงออกทางอารมณ์และพฤติกรรมผิดปกติ\" : \"แสดงออกทางอารมณ์และพฤติกรรมปกติ\""
        },
        "name": {
          "type": "value",
          "value": "psychological"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "Checkbox",
      "parent": 194,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.patientAssessment?.spiritual"
        },
        "label": {
          "type": "code",
          "value": "props.patientAssessment?.spiritual ? \"YES\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "spiritual"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "Dropdown",
      "parent": 195,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "suspected"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.suspectedOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.suspected"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "Dropdown",
      "parent": 196,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "convulsion"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.convulsionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.convulsion"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "Dropdown",
      "parent": 197,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mdr_triage"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.mdrOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.mdr_triage"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "Dropdown",
      "parent": 198,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "precaution"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.precautionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.precaution"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "Dropdown",
      "parent": 199,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "alcohol"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.alcoholOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.alcohol"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Dropdown",
      "parent": 200,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "tobacco"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.tobaccoOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.tobacco"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "Dropdown",
      "parent": 201,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "narcotic"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.narcoticOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.narcotic"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormField",
      "parent": 148,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.patientAssessment?.psychological && { display: \"none\" }) }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "FormField",
      "parent": 149,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "FormField",
      "parent": 152,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "FormField",
      "parent": 154,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormField",
      "parent": 155,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 156,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "div",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 220,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "div",
      "parent": 220,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "Input",
      "parent": 222,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "functional_assm_other"
        },
        "onChange": {
          "type": "code",
          "value": "props?.changeForm"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.functional_assm_other || \"\""
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "div",
      "parent": 213,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" ,...(!props.patientAssessment?.psychological && { display: \"none\" }) }"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "div",
      "parent": 224,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 224,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 214,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" ,...(!props.patientAssessment?.spiritual && { display: \"none\" }) }"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": null,
      "id": 228,
      "name": "div",
      "parent": 227,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 229,
      "name": "div",
      "parent": 227,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": null,
      "id": 230,
      "name": "div",
      "parent": 215,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": null,
      "id": 231,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": null,
      "id": 232,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "div",
      "parent": 216,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" ,...(props.patientAssessment?.mdr_triage !== \"YES\" && { display: \"none\" }) }"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "div",
      "parent": 233,
      "props": {
        "children": {
          "type": "value",
          "value": "MDR"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 235,
      "name": "div",
      "parent": 233,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": null,
      "id": 236,
      "name": "div",
      "parent": 217,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" ,...(![\"YES\", \"Past\"].includes(props.patientAssessment?.alcohol) && { display: \"none\" }) }"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": null,
      "id": 237,
      "name": "div",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "div",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": null,
      "id": 239,
      "name": "div",
      "parent": 218,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" ,...(![\"YES\", \"Past\"].includes(props.patientAssessment?.tobacco)  && { display: \"none\" }) }"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": null,
      "id": 240,
      "name": "div",
      "parent": 239,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "div",
      "parent": 239,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "div",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" ,...(props.patientAssessment?.narcotic !== \"YES\" && { display: \"none\" }) }"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "div",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "div",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "Input",
      "parent": 244,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "narcotic_specific"
        },
        "onChange": {
          "type": "code",
          "value": "props?.changeForm"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.narcotic_specific"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "Input",
      "parent": 232,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "suspected_specific"
        },
        "onChange": {
          "type": "code",
          "value": "props?.changeForm"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.suspected_specific || \"\""
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "Input",
      "parent": 226,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "psychological_specific"
        },
        "onChange": {
          "type": "code",
          "value": "props?.changeForm"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.psychological_specific || \"\""
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "Dropdown",
      "parent": 229,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "spiritual_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props?.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.belief"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.spiritual_detail"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "Dropdown",
      "parent": 241,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "tobacco_specific"
        },
        "onChange": {
          "type": "code",
          "value": "props?.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.tobaccoSpecificOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.tobacco_specific"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "Dropdown",
      "parent": 238,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "alcohol_specific"
        },
        "onChange": {
          "type": "code",
          "value": "props?.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.alcoholSpecificOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.alcohol_specific"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "Checkbox",
      "parent": 235,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.patientAssessment?.mdr"
        },
        "label": {
          "type": "code",
          "value": "props.patientAssessment?.mdr ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "mdr"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "FormField",
      "parent": 148,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "FormField",
      "parent": 148,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.patientAssessment?.phyco_nurse_note === \"NON\" && { display: \"none\" } ) }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.patientAssessment?.sus_nurse_note === \"NON\" && { display: \"none\" } ) }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 260,
      "name": "div",
      "parent": 256,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "div",
      "parent": 257,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": null,
      "id": 262,
      "name": "div",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 259,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": null,
      "id": 264,
      "name": "div",
      "parent": 262,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": null,
      "id": 265,
      "name": "div",
      "parent": 262,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "div",
      "parent": 260,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "div",
      "parent": 260,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "Input",
      "parent": 263,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "sus_nurse_note_specific"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุ"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.sus_nurse_note_specific"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "Input",
      "parent": 261,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "phyco_nurse_note_specific"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุ"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.phyco_nurse_note_specific || \"\""
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "Dropdown",
      "parent": 267,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "phyco_nurse_note"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.phychoNurseNoteOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.phyco_nurse_note || \"\""
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "Dropdown",
      "parent": 265,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "sus_nurse_note"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.susNurseNoteOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.sus_nurse_note"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": null,
      "id": 272,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "div",
      "parent": 272,
      "props": {
        "children": {
          "type": "value",
          "value": "Arrived by"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"20%\" }"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "div",
      "parent": 272,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 275,
      "name": "Dropdown",
      "parent": 274,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "arrive_status"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddition"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.arrivedByOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.arrive_status || \"\""
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "div",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "div",
      "parent": 276,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"35%\", textAlign: \"right\" }"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "div",
      "parent": 276,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Input",
      "parent": 278,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "translator_specific"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.translator_specific || \"\""
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 280,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับการคัดกรอง"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"35%\", textAlign: \"right\" }"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 282,
      "name": "div",
      "parent": 280,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": null,
      "id": 283,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": null,
      "id": 284,
      "name": "div",
      "parent": 283,
      "props": {
        "children": {
          "type": "value",
          "value": "Chief Complaint"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"35%\", textAlign: \"right\" }"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": null,
      "id": 285,
      "name": "div",
      "parent": 283,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 286,
      "name": "Input",
      "parent": 285,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "chief_complaint"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.chief_complaint || \"\""
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 287,
      "name": "Dropdown",
      "parent": 282,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "triage_level"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.triageLevelOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.triage_level || \"\""
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 354,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", ...(!props.isShowPregnancy && {display: \"none\"})}"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "FormField",
      "parent": 289,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "FormField",
      "parent": 289,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "div",
      "parent": 290,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการตั้งครรภ์"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": null,
      "id": 293,
      "name": "div",
      "parent": 291,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Radio",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyAssessment?.pregnancy_status === 1"
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "pregnancy_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "Radio",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyAssessment?.pregnancy_status === 2"
        },
        "label": {
          "type": "value",
          "value": "ไม่ตั้งครรภ์"
        },
        "name": {
          "type": "value",
          "value": "pregnancy_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "Radio",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyAssessment?.pregnancy_status === 3"
        },
        "label": {
          "type": "value",
          "value": "กำลังตั้งครรภ์"
        },
        "name": {
          "type": "value",
          "value": "pregnancy_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": null,
      "id": 297,
      "name": "div",
      "parent": 293,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุไตรมาส"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", ...(props.encounterData?.pregnancy_status !== 3 && {display: \"none\"})}"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 298,
      "name": "Dropdown",
      "parent": 293,
      "props": {
        "name": {
          "type": "value",
          "value": "pregnancy_period"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy"
        },
        "options": {
          "type": "code",
          "value": "props.pregnancyPeriodOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", ...(props.pregnancyAssessment?.pregnancy_status !== 3 && {display: \"none\"})}"
        },
        "value": {
          "type": "code",
          "value": "props.pregnancyAssessment?.pregnancy_period"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 299,
      "name": "Button",
      "parent": 288,
      "props": {
        "children": {
          "type": "value",
          "value": "Clear"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", ...(!props.isMenstruation && {display: \"none\"})}"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 301,
      "name": "FormField",
      "parent": 300,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "FormField",
      "parent": 300,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": null,
      "id": 303,
      "name": "div",
      "parent": 301,
      "props": {
        "children": {
          "type": "value",
          "value": "Menstruation"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": null,
      "id": 304,
      "name": "div",
      "parent": 302,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": null,
      "id": 305,
      "name": "div",
      "parent": 304,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", alignItems: \"center\", display: \"none\", ...(props.patientAssessment?.menstruation === \"LMP\" && { display: \"flex\" }) }"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": null,
      "id": 307,
      "name": "div",
      "parent": 304,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", alignItems: \"center\", display: \"none\", ...(props.patientAssessment?.menstruation === \"other\" && { display: \"flex\" }) }"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 308,
      "name": "FormField",
      "parent": 300,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": null,
      "id": 309,
      "name": "div",
      "parent": 308,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "Dropdown",
      "parent": 309,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "menstruation"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.menstruationOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.menstruation"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 311,
      "name": "DateTextBox",
      "parent": 305,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"lmp\")"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.lmp"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": null,
      "id": 312,
      "name": "div",
      "parent": 307,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "Input",
      "parent": 307,
      "props": {
        "name": {
          "type": "value",
          "value": "menstruationOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.menstruationOther"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 314,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"start\", ...(props.version !== \"0.2\" && { display: \"none\" }) }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 316,
      "name": "FormGroup",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.version !== \"0.2\" && { display: \"none\" }) }"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 317,
      "name": "FormField",
      "parent": 314,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "FormField",
      "parent": 316,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "FormField",
      "parent": 316,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 320,
      "name": "FormField",
      "parent": 314,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"top\", }"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 321,
      "name": "FormField",
      "parent": 314,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.patientAssessment?.psychologicalV2 && { display: \"none\" }) }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 322,
      "name": "FormField",
      "parent": 314,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.patientAssessment?.psychologicalV2 && { display: \"none\" }) }"
        },
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 323,
      "name": "FormField",
      "parent": 314,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!(props.patientAssessment?.psychologicalV2 && (props.patientAssessment?.psychological_detail || []).includes(6)) && { display: \"none\" }) }"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 324,
      "name": "FormField",
      "parent": 316,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.patientAssessment?.phyco_nurse_noteV2 !== \"REP\" && { display: \"none\" }) }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": null,
      "id": 325,
      "name": "div",
      "parent": 317,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", width: \"100%\" }"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": null,
      "id": 326,
      "name": "div",
      "parent": 320,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "div",
      "parent": 321,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"  }"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 332,
      "name": "Checkbox",
      "parent": 326,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.patientAssessment?.psychologicalV2"
        },
        "label": {
          "type": "code",
          "value": "props.patientAssessment?.psychologicalV2 ? \"แสดงออกทางอารมณ์และพฤติกรรมผิดปกติ\" : \"แสดงออกทางอารมณ์และพฤติกรรมปกติ\""
        },
        "name": {
          "type": "value",
          "value": "psychologicalV2"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 333,
      "name": "Radio",
      "parent": 327,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.patientAssessment?.psychological_select === \"1\""
        },
        "label": {
          "type": "value",
          "value": "ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ รู้สึกหดหู่ เศร้า หรือท้อแท้ สิ้นหวัง"
        },
        "name": {
          "type": "value",
          "value": "psychological_select"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "value": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 334,
      "name": "Radio",
      "parent": 327,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.patientAssessment?.psychological_select === \"2\""
        },
        "label": {
          "type": "value",
          "value": "ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ รู้สึกเบื่อ ทำอะไรก็ไม่เพลิดเพลิน"
        },
        "name": {
          "type": "value",
          "value": "psychological_select"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "value": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "Radio",
      "parent": 327,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.patientAssessment?.psychological_select === \"3\""
        },
        "label": {
          "type": "value",
          "value": "มีอาการหรือพฤติกรรมอื่นๆ ผิดปกติ"
        },
        "name": {
          "type": "value",
          "value": "psychological_select"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "value": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": null,
      "id": 337,
      "name": "div",
      "parent": 322,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 337,
      "void": false
    },
    {
      "from": null,
      "id": 339,
      "name": "div",
      "parent": 337,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 339,
      "void": false
    },
    {
      "from": null,
      "id": 340,
      "name": "div",
      "parent": 337,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": null,
      "id": 341,
      "name": "div",
      "parent": 323,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": null,
      "id": 342,
      "name": "div",
      "parent": 341,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 342,
      "void": false
    },
    {
      "from": null,
      "id": 343,
      "name": "div",
      "parent": 341,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 344,
      "name": "Dropdown",
      "parent": 340,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "psychological_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.psychologicalDetailOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.psychological_detail"
        }
      },
      "seq": 344,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 345,
      "name": "Input",
      "parent": 343,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "psychological_text"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุ"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.psychological_text"
        }
      },
      "seq": 345,
      "void": false
    },
    {
      "from": null,
      "id": 346,
      "name": "div",
      "parent": 324,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 346,
      "void": false
    },
    {
      "from": null,
      "id": 347,
      "name": "div",
      "parent": 319,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 347,
      "void": false
    },
    {
      "from": null,
      "id": 348,
      "name": "div",
      "parent": 347,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาลผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"80%\" }"
        }
      },
      "seq": 348,
      "void": false
    },
    {
      "from": null,
      "id": 349,
      "name": "div",
      "parent": 347,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 349,
      "void": false
    },
    {
      "from": null,
      "id": 350,
      "name": "div",
      "parent": 346,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 350,
      "void": false
    },
    {
      "from": null,
      "id": 351,
      "name": "div",
      "parent": 346,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 351,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 352,
      "name": "Dropdown",
      "parent": 349,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "phyco_nurse_noteV2"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.phycoNurseNoteOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.phyco_nurse_noteV2"
        }
      },
      "seq": 352,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 353,
      "name": "Dropdown",
      "parent": 351,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "phyco_nurse_note_specific_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm"
        },
        "options": {
          "type": "code",
          "value": "props.phycoNurseSpecificOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAssessment?.phyco_nurse_note_specific_detail"
        }
      },
      "seq": 353,
      "void": false
    },
    {
      "from": null,
      "id": 354,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        }
      },
      "seq": 110,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardPatientAssessmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "alcohol": "YES",
    "mdr": true,
    "mdr_triage": "YES",
    "menstruation": "LMP",
    "narcotic": "YES",
    "psychological": true,
    "spiritual": true,
    "tobacco": "YES",
    "translator": true
  },
  "width": 75
}

*********************************************************************************** */
