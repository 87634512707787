import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
// CONFIG
import config from "config/config";
import { Modal, Checkbox, Divider, Icon } from "semantic-ui-react"

import ModDrugOrderTemplateUX from "./ModDrugOrderTemplateUX";
import { useIntl } from "react-intl";

const API_HOST = `${config.API_HOST}`;

const ModDrugOrderTemplate = (props: any) => {
  const intl = useIntl();
	const [searchInput, setSearchInput] = useState<string>("")
	const [selectedOrder, setSelectedOrder] = useState<any>(null);
	const [selectedItem, setSelectedItem] = useState<any>(null);

	// useEffect(() => {

	// }, [])

	const headers = [
		<Checkbox
			checked={ 
				selectedOrder?.items?.length > 0 && selectedOrder?.items.every(
					(drug: any) => 
						props.DrugOrderTemplateSequence?.selectedDrugOrderItemId?.includes(drug.id)
					)
			}
			onChange={() => {
				props.runSequence({
					sequence: "DrugOrderTemplate",
					action: "selected_all",
					selectedDrugOrder: selectedOrder.id,
				})
			}} />, "ชื่อยา", "วิธีใช้", "จำนวน", "หน่วย"
	]

	const drugTemplate = useMemo(() => {
		return (props.DrugOrderTemplateSequence?.drugTemplateList || []).map(
			(item: any) => ({
				...item,
				_check: (
					<Checkbox
						checked={props.DrugOrderTemplateSequence?.selectedDrugOrderItemId?.includes(
							item.id
						)}
						onChange={(e: any, { v }) => {
							props.runSequence({
								sequence: "DrugOrderTemplate",
								action: "selected_drug_item",
								selectedDrugItemId: item.id,
								card: props.card
							});
						}}
					/>
				),
			})
		)
	}, [props.DrugOrderTemplateSequence])

	const drugOrderTemplateList = useMemo(() => {
		return (props.DrugOrderTemplateSequence?.drugOrderTemplateList || []).map(
			(item: any, index: number) => ({
				...item,
				_checkbox: (
					<Checkbox
						id={`ModDrugOrderTemplate-Checkbox-Selected-${index}`}
						checked={props.DrugOrderTemplateSequence?.selectedDrugOrderTemplates?.includes(
							item.id
						)}
						onChange={(e: any, { v }) => {
							props.runSequence({
								sequence: "DrugOrderTemplate",
								action: "select_drug_order",
								selectedDrugOrder: item.id,
								card: props.card
							});
						}}
					/>
				),
				name: (
					<div
						style={{ height: "100%", width: "100%" }}
						onClick={(e: any) => {
							props.runSequence({
								sequence: "DrugOrderTemplate",
								action: "show",
								selectedDrugOrder: item?.id || null,
							});
						}}
					>
						{item?.name}
					</div>
				),
				pinned: (<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<Icon
						size="big"
						name="pin"
						style={{ transform: "rotate(45deg)" }}
						color={item?.favourite_doctors?.includes(props.currentDoctor?.id) ? "red" : "grey"}
						onClick={() => {
							props.runSequence({
								sequence: "DrugOrderTemplate",
								action: "add_to_favourite",
								selectedDrugOrderData: item || null,
								card: props.card,
								searchInput
							});
						}}
					/>
				</div>)
			})
		);
	}, [props.DrugOrderTemplateSequence]);

	const handleOrderTrProps = (
		state: any,
		rowInfo: any,
		column: any,
		instance: any
	) => {
		return {
			style: {
				backgroundColor:
					rowInfo?.original?.id &&
						rowInfo?.original?.id === selectedOrder?.id
						? "#cccccc"
						: "white",
			},
			onClick: () => {
				setSelectedOrder(rowInfo?.original)
			}
		};
	};

	const handleItemTrProps = (
		state: any,
		rowInfo: any,
		column: any,
		instance: any
	) => {
		return {
			style: {
				backgroundColor:
					rowInfo?.original?.id &&
						rowInfo?.original?.id === selectedItem?.id
						? "#cccccc"
						: "white",
			},
			onClick: () => {
				setSelectedItem(rowInfo?.original)
			}
		};
	};

	const handleChangeTab = (tabName: string) => {
		setSearchInput("")
		props.runSequence({
			sequence: "DrugOrderTemplate",
			action: "change_tab",
			card: props.card,
			tabName
		})
		setSelectedOrder(null)
	}

	// console.log("ModDrugOrderTemplate props: ", props, selectedItem, selectedOrder)

	return (
		<Modal open={props.DrugOrderTemplateSequence?.open} style={{ width: "90%" }}>
			<ModDrugOrderTemplateUX
				// data
				selectTab={props.DrugOrderTemplateSequence?.selectedTab}
				dividerProps={<Divider />}
				drugOrderTemplateData={drugOrderTemplateList}
				drugTemplate={drugTemplate}
				drugItemHeader={headers}
				// function
				onChangeTab={handleChangeTab}
				onChangeSearchText={(e: SyntheticEvent, data: any) => { setSearchInput(data.value) }}
				onSearch={() => {
					props.runSequence({
						sequence: "DrugOrderTemplate",
						action: "search",
						searchText: searchInput,
						card: props.card
					})
				}}
				drugOrderTrProps={handleOrderTrProps}
				drugItemSelectRow={handleItemTrProps}
				onAddToOrder={(e: any) => {
					props.runSequence({
						sequence: "DrugOrderTemplate",
						action: "add_to_order",
						orderType: props.orderType,
						card: props.card
					});
				}}
				onCancel={(e: any) => {
					props.runSequence({
						sequence: "DrugOrderTemplate",
						action: "close",
						orderType: props.orderType,
						card: props.card
					});
				}}
				onDrugOrderTemplate={() => {
					window.open(`${API_HOST}/manage/TPD/drugordertemplate/`)?.focus();
				}}
				languageUX={props.languageUX}
			/>
		</Modal>
	)
}

export default ModDrugOrderTemplate;