import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Checkbox,
  Input,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardExportEclaimFile2UX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div
          className="ui form">
          
          <div
            style={{fontSize: "1.15rem", fontWeight: "bold", padding: "2.5px 0"}}>
            ส่งข้อมูลผู้ป่วยสำหรับ E-Claim
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form small">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.searchInvoiceGroup}
              </div>
            </FormField>
            <FormField>
              <Checkbox
                checked={props.filter?.isSpecifyDate || false}
                label="ระบุวันที่"
                name="isSpecifyDate"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.startDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.endDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex",}}>
                
                <div
                  style={{fontWeight: "bold", width: "70px"}}>
                  เลือกสิทธิ
                </div>
                <div
                  style={{color: "red", marginLeft: "-10px"}}>
                  *
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                className="inline-label"
                fluid={true}
                name="coveragePayer"
                onChange={props.onChangeValue}
                options={props.coveragePayerOptions || []}
                selection={true}
                style={{width: "100%", fontSize: "1em"}}
                value={props.filter?.coveragePayer || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                ประเภทการรักษา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                className="inline-label"
                fluid={true}
                name="treatmentType"
                onChange={props.onChangeValue}
                options={props.treatmentTypeOptions || []}
                selection={true}
                style={{width: "100%", fontSize: "1em"}}
                value={props.filter?.treatmentType || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.isHN || false}
                label="HN"
                name="isHN"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.patientSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.isEncounter || false}
                label="AN"
                name="isEncounter"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.searchEncounter}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.isStatus || false}
                label="สถานะการส่งเบิก"
                name="isStatus"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                className="inline-label"
                disabled={!props.isStatus}
                name="status"
                onChange={props.onChangeValue}
                options={props.sentClaimChoiceOptions || []}
                selection={true}
                style={{width: "100%", fontSize: "1em"}}
                value={props.filter?.status || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.notSpecifyRefNo || false}
                label="รายการนี้ยังไม่ระบุ REP NO."
                name="notSpecifyRefNo"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <div
              className="inline two wide field"
              style={{marginLeft: "auto"}}>
              
              <div
                style={{width: "100%"}}>
                {props.buttonSearch}
              </div>
            </div>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.billTransactionList || []}
            getTdProps={props.getTdProps}
            headers=",สถานะส่งเบิก,Date Transaction, Hospital Code, Encounter ID, HN, AN, Amount, Claim Amount, Paid, Personal ID, Name, REP NO."
            keys="description,status_display,dt_tran,hospital_code,encounter_id,hn,encounter_number,amount,claim_amount,paid,pid,name, ref_no"
            showPagination={false}
            style={{height: "calc(100vh - 27.5rem)"}}
            widths="110,^80,140,^85,^80,^70,^70,^70,^85,^70,^70,^100,^70">
          </Table>
        </div>
        <div
          className="ui form --override-disabled --grey"
          style={{marginTop: "1rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <div
                style={{display: "flex", alignItems: "center"}}>
                
                <div>
                  
                  <div
                    style={{width: "25px", height: "25px", backgroundColor: "rgb(250, 204, 103)", margin: "0 10px"}}>
                    
                  </div>
                </div>
                <div
                  style={{fontWeight: "bold"}}>
                  Validate ข้อมูลไม่ผ่าน
                </div>
              </div>
            </FormField>
            <div
              style={{display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "center"}}>
              
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "max-content"}}>
                  จำนวนข้อมูลที่ส่งเบิกได้
                </label>
              </FormField>
              <FormField
                inline={true}>
                <Input
                  disabled={true}
                  readOnly={true}
                  value={props.totalRows || ""}>
                </Input>
              </FormField>
              <FormField>
                <label
                  style={{minWidth: "max-content"}}>
                  ยอดเบิก
                </label>
              </FormField>
              <FormField>
                <Input
                  disabled={true}
                  readOnly={true}
                  value={props.totalSentClaimPrice || ""}>
                </Input>
              </FormField>
              <FormField>
                <label
                  style={{minWidth: "max-content"}}>
                  ยอดผู้ป่วยจ่าย
                </label>
              </FormField>
              <FormField>
                <Input
                  disabled={true}
                  readOnly={true}
                  value={props.totalPaidPrice || ""}>
                </Input>
              </FormField>
              <FormField>
                <label
                  style={{minWidth: "max-content"}}>
                  ยอดรวมจ่าย
                </label>
              </FormField>
              <FormField>
                <Input
                  disabled={true}
                  readOnly={true}
                  value={props.totalOtherPayPrice || ""}>
                </Input>
              </FormField>
            </div>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{marginTop: "2.25rem", paddingBottom: "1rem"}}>
          
          <div
            className="inline fields"
            style={{justifyContent: "flex-end"}}>
            
            <FormField
              inline={true}>
              <div>
                {props.buttonExportData}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="violet"
                onClick={props.onClickDownloadPreviousSendClaim}
                size="small">
                ดาวน์โหลด zip ไฟล์ย้อนหลัง
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="green"
                disabled={props.disabledSave || false}
                onClick={props.onSave}
                size="small">
                บันทึก REP NO. ส่งเบิก
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonPrintDocument}
              </div>
            </FormField>
          </div>
        </div>
      </div>
    )
}

CardExportEclaimFile2UX.displayName = "CardExportEclaimFile2UX";
export default React.memo(CardExportEclaimFile2UX)

export const screenPropsDefault = {}

/* Date Time : Thu Nov 14 2024 12:37:52 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งข้อมูลผู้ป่วยสำหรับ E-Claim"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการรักษา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "coveragePayer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.coveragePayerOptions || []"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.coveragePayer || \"\""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Dropdown",
      "parent": 16,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "treatmentType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.treatmentTypeOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.treatmentType || \"\""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกสิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"70px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", marginLeft: \"-10px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Checkbox",
      "parent": 32,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isHN || false"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "name": {
          "type": "value",
          "value": "isHN"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1em\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Checkbox",
      "parent": 34,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isEncounter || false"
        },
        "label": {
          "type": "value",
          "value": "AN"
        },
        "name": {
          "type": "value",
          "value": "isEncounter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1em\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Checkbox",
      "parent": 36,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isStatus || false"
        },
        "label": {
          "type": "value",
          "value": "สถานะการส่งเบิก"
        },
        "name": {
          "type": "value",
          "value": "isStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Dropdown",
      "parent": 37,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "disabled": {
          "type": "code",
          "value": "!props.isStatus"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.sentClaimChoiceOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.status || \"\""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Checkbox",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.notSpecifyRefNo || false"
        },
        "label": {
          "type": "value",
          "value": "รายการนี้ยังไม่ระบุ REP NO."
        },
        "name": {
          "type": "value",
          "value": "notSpecifyRefNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1em\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 50,
      "name": "Table",
      "parent": 49,
      "props": {
        "data": {
          "type": "code",
          "value": "props.billTransactionList || []"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "headers": {
          "type": "value",
          "value": ",สถานะส่งเบิก,Date Transaction, Hospital Code, Encounter ID, HN, AN, Amount, Claim Amount, Paid, Personal ID, Name, REP NO."
        },
        "keys": {
          "type": "value",
          "value": "description,status_display,dt_tran,hospital_code,encounter_id,hn,encounter_number,amount,claim_amount,paid,pid,name, ref_no"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 27.5rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "110,^80,140,^85,^80,^70,^70,^70,^85,^70,^70,^100,^70"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form --override-disabled --grey"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "Validate ข้อมูลไม่ผ่าน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width: \"100%\", justifyContent: \"flex-end\", alignItems: \"center\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนข้อมูลที่ส่งเบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Input",
      "parent": 60,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.totalRows || \"\""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดเบิก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดผู้ป่วยจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวมจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Input",
      "parent": 65,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.totalSentClaimPrice || \"\""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 67,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.totalPaidPrice || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Input",
      "parent": 75,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.totalOtherPayPrice || \"\""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2.25rem\", paddingBottom: \"1rem\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline fields"
        },
        "style": {
          "type": "code",
          "value": "{justifyContent: \"flex-end\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Button",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก REP NO. ส่งเบิก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledSave || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline two wide field"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"auto\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchInvoiceGroup"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormField",
      "parent": 6,
      "props": {
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Checkbox",
      "parent": 94,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isSpecifyDate || false"
        },
        "label": {
          "type": "value",
          "value": "ระบุวันที่"
        },
        "name": {
          "type": "value",
          "value": "isSpecifyDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchEncounter"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"25px\", height: \"25px\", backgroundColor: \"rgb(250, 204, 103)\", margin: \"0 10px\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Button",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "ดาวน์โหลด zip ไฟล์ย้อนหลัง"
        },
        "color": {
          "type": "value",
          "value": "violet"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDownloadPreviousSendClaim"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonExportData"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintDocument"
        }
      },
      "seq": 105,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": true,
  "memo": true,
  "name": "CardExportEclaimFile2UX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 100
}

*********************************************************************************** */
