import React, { useEffect } from "react";
// ui ORM
import CardElectricalXrayUltrasoundUX from "./CardElectricalXrayUltrasoundUX";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";

const CARD_KEY: string = "electricalData";
const FORM_CODE: string = "CardElectricalXray";
const FORM_NAME: string = "Electrical & X-ray & Ultrasound";
const FORM_VERSION: string = "1.0";

type CardElectricalXrayUltrasoundProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardElectricalXrayUltrasoundInitial: CardElectricalXrayUltrasoundProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PerioperativeNursingSequence: {},
};

const Tourniquet = {
  ronud: 0,
  tourniquetSide: "",
  pressure: "",
  timeOn: "",
  timeOff: "",
};

const Defibrillator = {
  external: false,
  internal: false,
  timeOn: "",
  power: "",
};

const CardElectricalXrayUltrasound: React.FC<
  CardElectricalXrayUltrasoundProps
> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
    });
  }, []);

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  return (
    <>
      <CardElectricalXrayUltrasoundUX
        masterOptions={props.masterOptions}
        handleChangeData={handleChangeData}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
        frequency={
          props.PerioperativeNursingSequence?.electricalData?.data?.frequency
        }
        groundPadSite={
          props.PerioperativeNursingSequence?.electricalData?.data
            ?.ground_pad_site
        }
        isSurgicalCautery={
          props.PerioperativeNursingSequence?.electricalData?.data
            ?.is_surgical_cautery
        }
        isUs={props.PerioperativeNursingSequence?.electricalData?.data?.is_us}
        isXray={
          props.PerioperativeNursingSequence?.electricalData?.data?.is_xray
        }
        surgicalCautery={
          props.PerioperativeNursingSequence?.electricalData?.data
            ?.surgical_cautery
        }
        xay={props.PerioperativeNursingSequence?.electricalData?.data?.xray}
        status={props.PerioperativeNursingSequence?.electricalData?.status}
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

CardElectricalXrayUltrasound.defaultProps = CardElectricalXrayUltrasoundInitial;

export default React.memo(CardElectricalXrayUltrasound);
