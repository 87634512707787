import LRMService from "../services/LRMService";
import { to } from "../../utils";
export default class LRMManager {
  apiToken?: string | null;

  constructor(apiToken?: string | null) {
    this.apiToken = apiToken
  }

  getSearchQueue = async ({ params } = {}) => {
    var searchQueue = [];
    var searchQueueError = null;
    var numberOfPage = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(lrmService.getSearchQueue({ params }));
    if (response) {
      numberOfPage = Math.ceil(response.total / params.limit);
      searchQueue = response.items;
    } else {
      searchQueueError = error;
    }
    return [searchQueue, searchQueueError, numberOfPage];
  };
  getANCRecordList = async ({ episodeId } = {}) => {
    let params = {};
    var ancRecord = [];
    var ancRecordError = null;
    const lrmService = new LRMService(this.apiToken);
    if (episodeId) {
      params.episode = episodeId;
    }
    const [error, response] = await to(lrmService.getANCRecordList(params));
    if (response) {
      ancRecord = response.items;
    } else {
      ancRecordError = error;
    }
    return [ancRecord, ancRecordError];
  };
  getPreviousPregnancy = async ({ ancRecordId } = {}) => {
    let params = {};
    var previousPregnancy = [];
    var previousPregnancyError = null;
    const lrmService = new LRMService(this.apiToken);
    if (ancRecordId) {
      params.anc_record = ancRecordId;
    }
    const [error, response] = await to(lrmService.getPreviousPregnancy(params));
    if (response) {
      previousPregnancy = response.items;
    } else {
      previousPregnancyError = error;
    }
    return [previousPregnancy, previousPregnancyError];
  };
  getANCSummary = async ({ ancSummaryId } = {}) => {
    let params = {};
    var ancSummary = [];
    var ancSummaryError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.getANCSummary({ ancSummaryId, params })
    );
    if (response) {
      ancSummary = response;
    } else {
      ancSummaryError = error;
    }
    return [ancSummary, ancSummaryError];
  };
  getANCItem = async ({ ancRecordId, limit, offset } = {}) => {
    let params = {};
    var ancItem = [];
    var ancItemError = null;
    const lrmService = new LRMService(this.apiToken);
    if (ancRecordId) {
      params.anc_record = ancRecordId;
    }
    if (limit) {
      params.limit = limit;
    }
    if (offset) {
      params.offset = offset;
    }
    const [error, response] = await to(lrmService.getANCItem({ params }));
    if (response) {
      ancItem = response;
    } else {
      ancItemError = error;
    }
    return [ancItem, ancItemError];
  };
  getANCLab = async ({ ancRecordId, ancLabId } = {}) => {
    if (!ancLabId) {
      // will not allow to fetch if there is no ancLabId
      // since ancLabId is sub-resources
      return;
    }
    let params = {};
    var ancLab = [];
    var ancLabError = null;
    const lrmService = new LRMService(this.apiToken);
    if (ancRecordId) {
      params.anc_record = ancRecordId;
    }
    const [error, response] = await to(
      lrmService.getANCLab({ ancLabId, params })
    );
    if (response) {
      ancLab = response.items;
    } else {
      ancLabError = error;
    }
    return [ancLab, ancLabError];
  };
  getFetalLie = async () => {
    let params = {};
    var fetalLie = [];
    var fetalLieError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(lrmService.getFetalLie({ params }));
    if (response) {
      fetalLie = response.items;
    } else {
      fetalLieError = error;
    }
    return [fetalLie, fetalLieError];
  };
  postLabInvestigation = async ({ episodeId } = {}) => {
    let data = {};
    var labInvestigation = [];
    var labInvestigationError = null;
    const lrmService = new LRMService(this.apiToken);
    if (episodeId) {
      data.episode_id = episodeId;
    }
    const [error, response] = await to(
      lrmService.postLabInvestigation({ data })
    );
    if (response) {
      labInvestigation = response;
    } else {
      labInvestigationError = error;
    }
    return [labInvestigation, labInvestigationError];
  };
  getLRLab = async () => {
    let params = {};
    var LRLab = [];
    var LRLabError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(lrmService.getLRLab({ params }));
    if (response) {
      LRLab = response.items;
    } else {
      LRLabError = error;
    }
    return [LRLab, LRLabError];
  };
  postSaveANCRecord = async ({ saveData } = {}) => {
    let params = {};
    var saveANCRecord = [];
    var saveANCRecordError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.postANCRecord({ data: saveData })
    );
    if (response) {
      saveANCRecord = response.items;
    } else {
      saveANCRecordError = error;
    }
    return [saveANCRecord, saveANCRecordError];
  };
  putLabInvestigation = async ({
    labInvestigationId,
    labInvestigation
  } = {}) => {
    let params = {};
    var plLabInvestigation = [];
    var labInvestigationError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.putLabInvestigation({
        labInvestigationId,
        data: labInvestigation
      })
    );
    if (response) {
      labInvestigation = response.items;
    } else {
      labInvestigationError = error;
    }
    return [labInvestigation, labInvestigationError];
  };
  getBirthCertificateApplication = async ({ episodeId, encounterId } = {}) => {
    let params = {};
    var birthCertificate = [];
    var birthCertificateError = null;
    const lrmService = new LRMService(this.apiToken);
    if (episodeId) {
      params.episode = episodeId;
    }
    if (encounterId) {
      params.encounter = encounterId;
    }
    const [error, response] = await to(
      lrmService.getBirthCertificateApplication({ params })
    );
    if (response) {
      birthCertificate = response.items;
    } else {
      birthCertificateError = error;
    }
    return [birthCertificate, birthCertificateError];
  };
  postBirthCertificateApplication = async ({ data } = {}) => {
    let params = {};
    var saveBirthCertificateApplication = [];
    var saveBirthCertificateApplicationError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.postBirthCertificateApplication({ data })
    );
    if (response) {
      saveBirthCertificateApplication = response.items;
    } else {
      saveBirthCertificateApplicationError = error;
    }
    return [
      saveBirthCertificateApplication,
      saveBirthCertificateApplicationError
    ];
  };
  putBirthCertificateApplication = async ({ data, birthCerAppId } = {}) => {
    let params = {};
    var editBirthCertificateApplication = [];
    var editBirthCertificateApplicationError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.putBirthCertificateApplication({ data, birthCerAppId })
    );
    if (response) {
      editBirthCertificateApplication = response.items;
    } else {
      editBirthCertificateApplicationError = error;
    }
    return [
      editBirthCertificateApplication,
      editBirthCertificateApplicationError
    ];
  };
  putPrintBirthCertificateApplication = async ({ birthCerAppId } = {}) => {
    let params = {};
    var printBirthCertificateApplication = [];
    var printBirthCertificateApplicationError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.putPrintBirthCertificateApplication({ birthCerAppId })
    );
    if (response) {
      printBirthCertificateApplication = response.items;
    } else {
      printBirthCertificateApplicationError = error;
    }
    return [
      printBirthCertificateApplication,
      printBirthCertificateApplicationError
    ];
  };
  getObstetricBasicInfo = async ({ episodeId, encounterId } = {}) => {
    let params = {};
    var obstetricBasicInfo = [];
    var obstetricBasicInfoError = null;
    const lrmService = new LRMService(this.apiToken);
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (episodeId) {
      params.episode = episodeId;
    }
    const [error, response] = await to(
      lrmService.getObstetricBasicInfo({ params })
    );
    if (response) {
      obstetricBasicInfo = response.items;
    } else {
      obstetricBasicInfoError = error;
    }
    return [obstetricBasicInfo, obstetricBasicInfoError];
  };
  postObstetricBasicInfo = async ({ saveData } = {}) => {
    let params = {};
    var obstetricBasicInfo = [];
    var obstetricBasicInfoError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.postObstetricBasicInfo({ data: saveData })
    );
    if (response) {
      obstetricBasicInfo = response;
    } else {
      obstetricBasicInfoError = error;
    }
    return [obstetricBasicInfo, obstetricBasicInfoError];
  };
  putObstetricBasicInfo = async ({ saveData, basicInfoId } = {}) => {
    let params = {};
    var obstetricBasicInfo = [];
    var obstetricBasicInfoError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.putObstetricBasicInfo({ data: saveData, basicInfoId })
    );
    if (response) {
      obstetricBasicInfo = response;
    } else {
      obstetricBasicInfoError = error;
    }
    return [obstetricBasicInfo, obstetricBasicInfoError];
  };
  putPrintObstetric = async ({ infantId, episodeId, encounterId } = {}) => {
    let params = {};
    var printObstetric = [];
    var printObstetricError = null;
    const lrmService = new LRMService(this.apiToken);
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (episodeId) {
      params.episode = episodeId;
    }
    if (infantId) {
      params.infant = infantId;
    }
    const [error, response] = await to(
      lrmService.putPrintObstetric({ params })
    );
    if (response) {
      printObstetric = response;
    } else {
      printObstetricError = error;
    }
    return [printObstetric, printObstetricError];
  };
  getObstetricLabour = async ({ episodeId, encounterId } = {}) => {
    let params = {};
    var obstetricLabour = [];
    var obstetricLabourError = null;
    const lrmService = new LRMService(this.apiToken);
    if (episodeId) {
      params.episode = episodeId;
    }
    if (encounterId) {
      params.encounter = encounterId;
    }
    const [error, response] = await to(
      lrmService.getObstetricLabor({ params })
    );
    if (response) {
      obstetricLabour = response.items;
    } else {
      obstetricLabourError = error;
    }
    return [obstetricLabour, obstetricLabourError];
  };
  postObstetricLabour = async ({ saveData } = {}) => {
    let params = {};
    var obstetricLabour = [];
    var obstetricLabourError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.postObstetricLabor({ data: saveData })
    );
    if (response) {
      obstetricLabour = response;
    } else {
      obstetricLabourError = error;
    }
    return [obstetricLabour, obstetricLabourError];
  };
  putObstetricLabour = async ({ saveData, labourId } = {}) => {
    let params = {};
    var obstetricLabour = [];
    var obstetricLabourError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.putObstetricLabor({ data: saveData, labourId })
    );
    if (response) {
      obstetricLabour = response;
    } else {
      obstetricLabourError = error;
    }
    return [obstetricLabour, obstetricLabourError];
  };
  getObstetricInfant = async ({ episodeId, encounterId } = {}) => {
    let params = {};
    var obstetricInfant = [];
    var obstetricInfantError = null;
    const lrmService = new LRMService(this.apiToken);
    if (episodeId) {
      params.episode = episodeId;
    }
    if (encounterId) {
      params.encounter = encounterId;
    }
    const [error, response] = await to(
      lrmService.getObstetricInfant({ params })
    );
    if (response) {
      obstetricInfant = response.items;
    } else {
      obstetricInfantError = error;
    }
    return [obstetricInfant, obstetricInfantError];
  };
  postObstetricInfant = async ({ saveData } = {}) => {
    let params = {};
    var obstetricInfant = [];
    var obstetricInfantError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.postObstetricInfant({ data: saveData })
    );
    if (response) {
      obstetricInfant = response;
    } else {
      obstetricInfantError = error;
    }
    return [obstetricInfant, obstetricInfantError];
  };
  putObstetricInfant = async ({ infantId, saveData } = {}) => {
    let params = {};
    var obstetricInfant = [];
    var obstetricInfantError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.putObstetricInfant({ infantId, data: saveData })
    );
    if (response) {
      obstetricInfant = response;
    } else {
      obstetricInfantError = error;
    }
    return [obstetricInfant, obstetricInfantError];
  };
  getObstetricPostLabor = async ({ episodeId, encounterId } = {}) => {
    let params = {};
    var obstetricPostLabor = [];
    var obstetricPostLaborError = null;
    const lrmService = new LRMService(this.apiToken);
    if (episodeId) {
      params.episode = episodeId;
    }
    if (encounterId) {
      params.encounter = encounterId;
    }
    const [error, response] = await to(
      lrmService.getObstetricPostLabor({ params })
    );
    if (response) {
      obstetricPostLabor = response.items;
    } else {
      obstetricPostLaborError = error;
    }
    return [obstetricPostLabor, obstetricPostLaborError];
  };
  postObstetricPostLabour = async ({ saveData } = {}) => {
    let params = {};
    var obstetricLabour = [];
    var obstetricLabourError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.postObstetricPostLabor({ data: saveData })
    );
    if (response) {
      obstetricLabour = response;
    } else {
      obstetricLabourError = error;
    }
    return [obstetricLabour, obstetricLabourError];
  };
  putObstetricPostLabour = async ({ postLabourId, saveData } = {}) => {
    let params = {};
    var obstetricPostLabour = [];
    var obstetricPostLabourError = null;
    const lrmService = new LRMService(this.apiToken);
    const [error, response] = await to(
      lrmService.putObstetricPostLabor({ postLabourId, data: saveData })
    );
    if (response) {
      obstetricPostLabour = response;
    } else {
      obstetricPostLabourError = error;
    }
    return [obstetricPostLabour, obstetricPostLabourError];
  };
  getEDC = async ({ date } = {}) => {
    let params = {};
    let data = {};
    var edc = [];
    var edcError = null;
    const lrmService = new LRMService(this.apiToken);
    if (date) {
      data.lmp_date = date;
    }
    const [error, response] = await to(lrmService.getEDC({ data }));
    if (response) {
      edc = response;
    } else {
      edcError = error;
    }
    return [edc, edcError];
  };
  postNewborn = async ({ encounterId, episodeId, items } = {}) => {
    let params = {};
    let data = {};
    var newBorn = [];
    var newBornError = null;
    const lrmService = new LRMService(this.apiToken);
    if (encounterId) {
      data.encounter = encounterId;
    }
    if (episodeId) {
      data.episode = episodeId;
    }
    if (items) {
      data.items = items;
    }
    const [error, response] = await to(lrmService.postNewborn({ data }));
    if (response) {
      newBorn = response;
    } else {
      newBornError = error;
    }
    return [newBorn, newBornError];
  };
  getCheckOutChoice = async ({ patientId } = {}) => {
    let params = {};
    var checkoutChoices = [];
    var checkoutChoicesError = null;
    const lrmService = new LRMService(this.apiToken);
    if (patientId) {
      params.patient = patientId;
    }
    const [error, response] = await to(
      lrmService.getCheckOutChoice({ params })
    );
    if (response) {
      checkoutChoices = response.items;
    } else {
      checkoutChoicesError = error;
    }
    return [checkoutChoices, checkoutChoicesError];
  };
  putLRCheckOut = async ({
    action,
    dischargeStatus,
    preDischargeCondition,
    encounterId,
    oldRoom,
    cleaning,
    appointment
  } = {}) => {
    let params = {};
    let data = {};
    var checkOut = [];
    var checkOutError = null;
    const lrmService = new LRMService(this.apiToken);
    if (action) {
      data.action = action;
    }
    if (dischargeStatus) {
      data.discharge_status = dischargeStatus;
    }
    if (preDischargeCondition) {
      data.predischarge_condition = preDischargeCondition;
    }
    if (oldRoom) {
      data.old_room = oldRoom;
    }
    if (cleaning) {
      data.cleaning = cleaning;
    }
    if (appointment) {
      data.appointment = appointment;
    }
    const [error, response] = await to(
      lrmService.putLRCheckOut({ params, data, encounterId })
    );
    if (response) {
      checkOut = response.items;
    } else {
      checkOutError = error;
    }
    return [checkOut, checkOutError];
  };
  printANCRecordByEncounter = async ({ encounterId, pdf }) => {
    let data = {};
    var print = [];
    var printError = null;
    const lrmService = new LRMService(this.apiToken);
    if (encounterId) {
      data.encounter = encounterId;
    }
    if (pdf) {
      data.pdf = pdf;
    }
    const [error, response] = await to(
      lrmService.printANCRecordByEncounter({ data, encounterId })
    );
    if (response) {
      print = response;
    } else {
      printError = error;
    }
    return [print, printError];
  };
}
