export default class CardMedicalNoteController {
  constructor(props) {
    this.mainPHVManager = props.mainPHVManager;
  }

  getUserList = async ({ nameUsername }={}) => {
    let params = {};
    if (nameUsername) {
      params.name_username = nameUsername;
    }
    const [response, error] = await this.mainPHVManager.getUserList(params);
    return [response, error];
  };
}