import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardCostCenterReceiptUX = (props: any) => {
    return(
      <div
        style={{position: "relative", padding: "1em"}}>
        <Form>
          <FormGroup
            className="ui form"
            style={{padding: "0em 0em 0em 0em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center"}}>
              เลขที่ใบเสร็จ
            </label>
            <Input
              onChange={(e) => props.onHandleInputReceipt( e)}
              style={{width: "15em"}}>
            </Input>
            <div>
              {props.searchButtonLoadCheck}
            </div>
          </FormGroup>
        </Form>
        <div>
          {props.searchNotification}
        </div>
        <div
          style={{ display: "flex", padding: "0em 0em 2em 0em" }}>
          
          <div
            style={{ flex: "70%" }}>
            
            <Form>
              <FormGroup
                className="ui form"
                style={{ padding: "1em 0em 0em 0em", gap: "1em" }}>
                <label
                  style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
                  ชื่อผู้ป่วย
                </label>
                <label
                  style={{ display: "flex", alignItems: "center" }}>
                  {props.patientName}
                </label>
                <label
                  style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
                  วันที่ออกใบเสร็จ
                </label>
                <label
                  style={{ display: "flex", alignItems: "center" }}>
                  {props.issuedDate}
                </label>
              </FormGroup>
              <FormGroup
                className="ui form"
                style={{ gap: "1em" }}>
                <label
                  style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
                  เจ้าหน้าที่การเงิน
                </label>
                <label
                  style={{ display: "flex", alignItems: "center" }}>
                  {props.financialOfficer}
                </label>
                <label
                  style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
                  สถานะใบเสร็จ
                </label>
                <label
                  style={{ display: "flex", alignItems: "center" }}>
                  {props.receiptStatus}
                </label>
              </FormGroup>
            </Form>
          </div>
          <div
            style={{ flex: "30%", background: "#fffaba" }}>
            
            <Form>
              <FormGroup
                className="ui form"
                style={{padding: "1em 0em 0em 1em",   gap: "1em", justifyContent: "space-between"}}>
                <label
                  style={{ fontWeight: "bold" }}>
                  ยอดรวม
                </label>
                <label>
                  {props.priceValue}
                </label>
              </FormGroup>
              <FormGroup
                className="ui form"
                style={{ padding: "0em 0em 0em 1em", gap: "1em", justifyContent: "space-between",}}>
                <label
                  style={{ fontWeight: "bold" }}>
                  เงินสด
                </label>
                <label>
                  {props.cashValue}
                </label>
              </FormGroup>
              <FormGroup
                className="ui form"
                style={{ padding: "0em 0em 0em 1em", gap: "1em", justifyContent: "space-between",}}>
                <label
                  style={{ fontWeight: "bold" }}>
                  เงินโอน/QR
                </label>
                <label>
                  {props.transferQrValue}
                </label>
              </FormGroup>
              <FormGroup
                className="ui form"
                style={{ padding: "0em 0em 0em 1em", gap: "1em", justifyContent: "space-between",}}>
                <label
                  style={{ fontWeight: "bold" }}>
                  บัตรเครดิต
                </label>
                <label>
                  {props.creditCardValue}
                </label>
              </FormGroup>
            </Form>
          </div>
        </div>
        <Table
          data={props.dataTable}
          headers={`วันที่,รหัสศูนย์ต้นทุน,ชื่อศูนย์ต้นทุน,รายได้รวม (บาท)`}
          keys={`paid_date,cost_center_code,cost_center_name,value_format`}
          minRows="10"
          showPagination={false}
          widths="auto, auto, auto, auto">
        </Table>
      </div>
    )
}


export default CardCostCenterReceiptUX

export const screenPropsDefault = {}

/* Date Time : Fri May 17 2024 01:38:03 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", padding: \"1em\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0em 0em 0em 0em\", gap: \"1em\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Input",
      "parent": 2,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleInputReceipt( e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"15em\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchNotification"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", padding: \"0em 0em 2em 0em\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: \"70%\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Form",
      "parent": 8,
      "props": {
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 9,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"1em 0em 0em 0em\", gap: \"1em\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 9,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ gap: \"1em\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientName"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ออกใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.issuedDate"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "เจ้าหน้าที่การเงิน"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.financialOfficer"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receiptStatus"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: \"30%\", background: \"#fffaba\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Form",
      "parent": 20,
      "props": {
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 0em 1em\",   gap: \"1em\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0em 0em 0em 1em\", gap: \"1em\", justifyContent: \"space-between\",}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormGroup",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0em 0em 0em 1em\", gap: \"1em\", justifyContent: \"space-between\",}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormGroup",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0em 0em 0em 1em\", gap: \"1em\", justifyContent: \"space-between\",}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวม"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.priceValue"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "เงินสด"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cashValue"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "เงินโอน/QR"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.transferQrValue"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "บัตรเครดิต"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props.creditCardValue"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 34,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.dataTable"
        },
        "headers": {
          "type": "code",
          "value": "`วันที่,รหัสศูนย์ต้นทุน,ชื่อศูนย์ต้นทุน,รายได้รวม (บาท)`"
        },
        "keys": {
          "type": "code",
          "value": "`paid_date,cost_center_code,cost_center_name,value_format`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "auto, auto, auto, auto"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchButtonLoadCheck"
        }
      },
      "seq": 35,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardCostCenterReceiptUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
