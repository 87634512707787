import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const CardAppointmentEncounterUX = (props: any) => {
    return(
      <div>
        <Table
          headers="วันที่,เวลา,ชื่อแพทย์"
          minRows={8}
          showPagination={false}
          style={{height: "300px"}}>
        </Table>
        <div
          style={{display: "flex", justifyContent: "flex-end", padding: "10px"}}>
          
          <Button
            color="green">
            เปิด Encounter
          </Button>
        </div>
      </div>
    )
}

export default CardAppointmentEncounterUX

export const screenPropsDefault = {}

/* Date Time : Sat Apr 16 2022 00:09:05 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "headers": {
          "type": "value",
          "value": "วันที่,เวลา,ชื่อแพทย์"
        },
        "minRows": {
          "type": "code",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", padding: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "เปิด Encounter"
        },
        "color": {
          "type": "value",
          "value": "green"
        }
      },
      "seq": 3,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardAppointmentEncounterUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
