import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardLabGroup = (props: any) => {
    return(
      <div>
        <div
          style={{padding: "15px 10px" }}>
          
          <label
            style={{fontWeight: "bold", fontSize: "1.4rem", padding: "1rem"}}>
            Lab ชุด
          </label>
          <div
            style={{display: "flex", alignItems: "center" }}>
            
            <div
              style={{marginLeft: "auto"}}>
              
            </div>
            <Input
              onChange={props.labGroupSearchChange}
              onKeyDown={(e: any) => { e.key === "Enter" && props.searchLabGroup(); }}
              style={{marginLeft: "15px"}}
              value={props.labGroupSearch}>
            </Input>
            <Button
              color="blue"
              onClick={props.labGroupSearchBtn}
              style={{marginLeft: "15px"}}>
              ค้นหา
            </Button>
            <Button
              color="orange"
              onClick={props.labGroupManageBtn}
              style={{marginLeft: "15px", marginRight: "10px"}}>
              จัดการชุด Lab 
            </Button>
          </div>
          <div>
            
            <Table
              data={props.labGroupItems}
              headers=" ,ชื่อชุด Lab,รายการ"
              keys="check,name,detail"
              minRows="5"
              showPagination={false}
              style={{height: "300px", margin: "10px",fontSize: "1.2rem",}}
              widths="90,300,auto">
            </Table>
          </div>
          <div>
            
            <Button
              color="blue"
              onClick={props.labGroupSelectedBtn}
              style={{width: "150px", marginLeft: "10px"}}>
              เลือก
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardLabGroup

export const screenPropsDefault = {}

/* Date Time : Wed Jul 20 2022 03:23:12 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px 10px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"auto\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.labGroupSearchBtn"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Input",
      "parent": 2,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.labGroupSearchChange"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.searchLabGroup(); }"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.labGroupSearch"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "จัดการชุด Lab "
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.labGroupManageBtn"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", marginRight: \"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 9,
      "name": "Table",
      "parent": 8,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labGroupItems"
        },
        "headers": {
          "type": "value",
          "value": " ,ชื่อชุด Lab,รายการ"
        },
        "keys": {
          "type": "value",
          "value": "check,name,detail"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", margin: \"10px\",fontSize: \"1.2rem\",}"
        },
        "widths": {
          "type": "value",
          "value": "90,300,auto"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.labGroupSelectedBtn"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", marginLeft: \"10px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab ชุด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.4rem\", padding: \"1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardLabGroup",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
