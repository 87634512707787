import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Calendar
} from 'react-big-calendar'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Dropdown,
  Checkbox,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardAppointmentSummaryUX = (props: any) => {
    return(
      <div
        style={{height: "90vh", display: "flex"}}>
        <div
          style={{flex: 1, overflow: "auto"}}>

          <div
            className="ui form"
            style={{padding: "10px"}}>

            <FormGroup
              inline={true}>
              <FormField
                style={{display: "block"}}
                width="8">
                <label>
                  ประเภทนัดหมาย
                </label>
                <div>

                  <Dropdown
                    clearable={true}
                    fluid={true}
                    onChange={props.onChange("appointmentType")}
                    options={props.appointmentTypeOptions}
                    selection={true}
                    value={props.appointmentType}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                style={{display: "block"}}
                width="8">
                <label>
                  สถานะนัดหมาย
                </label>
                <div>

                  <Dropdown
                    clearable={true}
                    fluid={true}
                    onChange={props.onChange("appointmentStatus")}
                    options={props.appointmentStatusOptions}
                    search={true}
                    selection={true}
                    value={props.appointmentStatus}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                style={{display: "block"}}
                width="8">
                <label>
                  HN ผู้ป่วย
                </label>
                <div>
                  {props.searchPatient}
                </div>
              </FormField>
              <FormField
                style={{display: "block"}}
                width="8">
                <div
                  style={{display: "flex", justifyContent: "space-between"}}>

                  <label
                    style={{fontWeight: "bold"}}>
                    แพทย์
                  </label>
                  <Checkbox
                    checked={props.isUnspecifiedDoctor}
                    disabled={!!props.providerDoctor}
                    label=" ไม่ระบุ"
                    onChange={props.onChange("isUnspecifiedDoctor")}>
                  </Checkbox>
                </div>
                <div>

                  <Dropdown
                    clearable={true}
                    disabled={props.isUnspecifiedDoctor}
                    fluid={true}
                    onChange={props.onChange("providerDoctor")}
                    options={props.providerDoctorOptions}
                    selection={true}
                    value={props.providerDoctor}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              style={{display: "flex", alignItems: "flex-end"}}
              widths={true}>
              <FormField
                style={{display: "block"}}
                width="7">
                <label>
                  วันที่นัดหมาย
                </label>
                <div>

                  <DateTextBox
                    onChange={props.onDateChange("startDate")}
                    value={props.startDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField>
                <label>
                  ถึง
                </label>
              </FormField>
              <FormField
                style={{display: "block"}}
                width="7">
                <label>

                </label>
                <div>

                  <DateTextBox
                    onChange={props.onDateChange("endDate")}
                    value={props.endDate}>
                  </DateTextBox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              style={{display: "flex", alignItems: "flex-end"}}>
              <FormField
                style={{display: "block"}}
                width="8">
                <label>
                  สถานะรับบริการ
                </label>
                <div>

                  <Dropdown
                    clearable={true}
                    fluid={true}
                    onChange={props.onChange("orderStatus")}
                    options={props.orderStatusOptions}
                    selection={true}
                    value={props.orderStatus}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                style={{display: "", paddingBottom: "5px"}}
                width="8">
                <Button
                  color="blue"
                  loading={props.loadingFilterAppList}
                  onClick={props.onSearch}
                  size="mini">
                  ค้นหา
                </Button>
                <Button
                  color="orange"
                  onClick={props.onPrintAppointmentList}
                  size="mini">
                  พิมพ์รายชื่อ
                </Button>
              </FormField>
            </FormGroup>
          </div>
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.appointmentList || []}
            defaultPageSize="10"
            getTrProps={props.onGetPatientRow}
            headers=",HN,ชื่อ - สกุล,แพทย์"
            keys="_icon,hn,patient_name,doctor_name"
            minRows="10"
            pageSize="10"
            showPagination={false}
            style={{height: "45vh"}}
            widths="60,">
          </Table>
          <div
            style={{display: "flex", justifyContent: "flex-end", padding: "5px"}}>
            {props.pagination}
          </div>
        </div>
        <div
          style={{flex: 3, display: props.tab ? "none": "",width:"70%"}}>

          <Calendar
            eventPropGetter={props.eventPropGetter}
            events={props.blockList}
            localizer={props.localizer}
            onRangeChange={props.onRangeChange}
            onSelectEvent={props.onSelectEvent}>
          </Calendar>
        </div>
        <div
          style={{flex: 3, ...(['generalAppDetailUX', 'generalAppDateTimeUX'].includes(props.tab)? {} : {display: "none"}),width:"70%"}}>

          <div
            style={{display: "flex", alignItems: "center", padding: "10px 10px 0px 10px", justifyContent: "space-between"}}>

            <div
              style={{display: "flex", alignItems: "center"}}>

              <Button
                color={props.tab === "generalAppDetailUX"? "blue": ""}
                disabled={props.disabledBtnAppointment||false}
                onClick={props.onChangeTab("generalAppDetailUX")}
                size="mini">
                รายละเอียดการนัดหมาย
              </Button>
              <Button
                color={props.tab === "generalAppDateTimeUX"? "blue": ""}
                disabled={props.disabledBtnSelectDatetime||false}
                onClick={props.onChangeTab("generalAppDateTimeUX")}
                size="mini">
                เลือกวันเวลานัดหมาย
              </Button>
            </div>
            <div>

              <Button
                color="blue"
                onClick={props.onHideDetail}
                size="mini">
                ซ่อนรายละเอียด
              </Button>
            </div>
          </div>
          <hr>
          </hr>
          <div
            style={{display: props.tab === "generalAppDetailUX"? "": "none", height: "85vh", overflow: "auto"}}>
            {props.generalAppDetailUX}
          </div>
          <div
            style={{display: props.tab === "generalAppDateTimeUX"? "": "none", height: "80vh"}}>
            {props.generalAppDateTimeUX}
          </div>
        </div>
        <div
          style={{flex: 3, ...(['consultDetailUX', 'consultDateTimeUX'].includes(props.tab)? {} : {display: "none"}),width:"70%"}}>

          <div
            style={{display: "flex", alignItems: "center", padding: "10px 10px 0px 10px", justifyContent: "space-between"}}>

            <div
              style={{display: "flex", alignItems: "center"}}>

              <Button
                color={props.tab === "consultDetailUX"? "blue": ""}
                onClick={props.onChangeTab("consultDetailUX")}
                size="mini">
                รายละเอียดการ Consult
              </Button>
              <Button
                color={props.tab === "consultDateTimeUX"? "blue": ""}
                disabled={props.disabledConsultDateTimeTab}
                onClick={props.onChangeTab("consultDateTimeUX")}
                size="mini">
                เลือกวันเวลานัดหมาย
              </Button>
            </div>
            <div>

              <Button
                color="blue"
                onClick={props.onHideDetail}
                size="mini">
                ซ่อนรายละเอียด
              </Button>
            </div>
          </div>
          <hr>
          </hr>
          <div
            style={{display: props.tab === "consultDetailUX"? "": "none", height: "90vh"}}>
            {props.consultDetailUX}
          </div>
          <div
            style={{display: props.tab === "consultDateTimeUX"? "": "none", height: "90vh"}}>
            {props.consultDateTimeUX}
          </div>
        </div>
      </div>
    )
}


export default CardAppointmentSummaryUX

export const screenPropsDefault = {}

/* Date Time : Thu Jun 13 2024 12:41:51 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"90vh\", display: \"flex\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-big-calendar",
      "id": 1,
      "name": "Calendar",
      "parent": 3,
      "props": {
        "eventPropGetter": {
          "type": "code",
          "value": "props.eventPropGetter"
        },
        "events": {
          "type": "code",
          "value": "props.blockList"
        },
        "localizer": {
          "type": "code",
          "value": "props.localizer"
        },
        "onRangeChange": {
          "type": "code",
          "value": "props.onRangeChange"
        },
        "onSelectEvent": {
          "type": "code",
          "value": "props.onSelectEvent"
        }
      },
      "seq": 1,
      "void": true
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, overflow: \"auto\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, display: props.tab ? \"none\": \"\",width:\"70%\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.appointmentList || []"
        },
        "defaultPageSize": {
          "type": "value",
          "value": "10"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetPatientRow"
        },
        "headers": {
          "type": "value",
          "value": ",HN,ชื่อ - สกุล,แพทย์"
        },
        "keys": {
          "type": "value",
          "value": "_icon,hn,patient_name,doctor_name"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"45vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "60,"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 45,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 45,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 48,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 48,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"flex-end\"}"
        },
        "widths": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 51,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "7"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 51,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "7"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"flex-end\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 54,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 54,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"\", paddingBottom: \"5px\"}"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทนัดหมาย"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "label",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะนัดหมาย"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "HN ผู้ป่วย"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดหมาย"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะรับบริการ"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchPatient"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Dropdown",
      "parent": 64,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"appointmentType\")"
        },
        "options": {
          "type": "code",
          "value": "props.appointmentTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.appointmentType"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 65,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"appointmentStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.appointmentStatusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.appointmentStatus"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.isUnspecifiedDoctor"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"providerDoctor\")"
        },
        "options": {
          "type": "code",
          "value": "props.providerDoctorOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.providerDoctor"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Dropdown",
      "parent": 70,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"orderStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.orderStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.orderStatus"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 76,
      "name": "DateTextBox",
      "parent": 68,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"startDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 77,
      "name": "DateTextBox",
      "parent": 69,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"endDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 51,
      "props": {
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Checkbox",
      "parent": 81,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isUnspecifiedDoctor"
        },
        "disabled": {
          "type": "code",
          "value": "!!props.providerDoctor"
        },
        "label": {
          "type": "value",
          "value": " ไม่ระบุ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"isUnspecifiedDoctor\")"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Button",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingFilterAppList"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Button",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายชื่อ"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintAppointmentList"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pagination"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", padding: \"5px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, ...(['consultDetailUX', 'consultDateTimeUX'].includes(props.tab)? {} : {display: \"none\"}),width:\"70%\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "code",
          "value": "props.consultDetailUX"
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"consultDetailUX\"? \"\": \"none\", height: \"90vh\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "code",
          "value": "props.consultDateTimeUX"
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"consultDateTimeUX\"? \"\": \"none\", height: \"90vh\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", padding: \"10px 10px 0px 10px\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Button",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการ Consult"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"consultDetailUX\"? \"blue\": \"\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab(\"consultDetailUX\")"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Button",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกวันเวลานัดหมาย"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"consultDateTimeUX\"? \"blue\": \"\""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledConsultDateTimeTab"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab(\"consultDateTimeUX\")"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Button",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "ซ่อนรายละเอียด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onHideDetail"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, ...(['generalAppDetailUX', 'generalAppDateTimeUX'].includes(props.tab)? {} : {display: \"none\"}),width:\"70%\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", padding: \"10px 10px 0px 10px\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการนัดหมาย"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"generalAppDetailUX\"? \"blue\": \"\""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledBtnAppointment||false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab(\"generalAppDetailUX\")"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกวันเวลานัดหมาย"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"generalAppDateTimeUX\"? \"blue\": \"\""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledBtnSelectDatetime||false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab(\"generalAppDateTimeUX\")"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Button",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "ซ่อนรายละเอียด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onHideDetail"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "code",
          "value": "props.generalAppDetailUX"
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"generalAppDetailUX\"? \"\": \"none\", height: \"85vh\", overflow: \"auto\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "code",
          "value": "props.generalAppDateTimeUX"
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"generalAppDateTimeUX\"? \"\": \"none\", height: \"80vh\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "hr",
      "parent": 99,
      "props": {
      },
      "seq": 106,
      "void": true
    },
    {
      "from": null,
      "id": 109,
      "name": "hr",
      "parent": 87,
      "props": {
      },
      "seq": 92,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAppointmentSummaryUX",
  "project": "cudent2",
  "screenPropsDefault": {
  },
  "width": 85
}

*********************************************************************************** */
