import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
// LAB
import CentralLabOrderEstimate from "issara-sdk/apis/CentralLabOrderEstimate_apps_LAB";
// CORE
import PackageTypeList from "issara-sdk/apis/PackageTypeList_core";
import PackageServiceTypeList from "issara-sdk/apis/PackageServiceTypeList_core";
import PackageList from "issara-sdk/apis/PackageList_core";
import ProductForPackageList from "issara-sdk/apis/ProductForPackageList_core";
import PackageDetail from "issara-sdk/apis/PackageDetail_core";
import PackageDuplicateView from "issara-sdk/apis/PackageDuplicateView_core";
import PackageCheckDeleteView from "issara-sdk/apis/PackageCheckDeleteView_core";

// TRT
import TreatmentOrderEstimate from "issara-sdk/apis/TreatmentOrderEstimate_apps_TRT";
// MSD
// TPD
// DFC

// Serializer
import PackageSerializer from "issara-sdk/types/PackageSerializer_core";
import ProductForPackageSerializerI from "issara-sdk/types/ProductForPackageSerializer_core";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import {
  SetErrorMessage,
  SetProperty,
  mapOptions,
} from "../../common/CommonInterface";

export type State = Partial<{
  // sequence
  SettingPackageSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    showDetail: boolean;
    packageDetail: Partial<PackageDetailType>;
    filterPackage: Partial<FilterPackageType>;
    searchedProductList: SearchedProductList;
    // เพื่อ update active, inactive แล้วคงข้อมูลเดิมไว้
    rawPackageDetail: Partial<PackageDetailType>;
    genderOptions: OptionType[];
    usageLimitOptions: OptionType[];
    packageTypeOptions: OptionType[];
    serviceTypeOptions: OptionType[];
    selectedProduct: Partial<{
      code: any;
      name: any;
    }>;
    selectedPackage: number | null;
    showRequiredField: RequiredFieldType;
    packageList: {
      items: PackageSerializer[];
      total: number;
      activePage: number;
    };
  }> | null;
}>;

type Picked = Partial<
  Pick<
    MainState,
    "buttonLoadCheck" | "errorMessage" | "django" | "searchedItemListWithKey"
  >
>;

export type SearchedProductList = {
  activePage?: number;
  items: ProductForPackageSerializer[];
  total: number;
};

export type ProductForPackageSerializer = { max_hours?: string } & ProductForPackageSerializerI;

export type FilterPackageType = {
  serviceType: number | "ALL";
  packageType: number | "ALL";
  status: "ACTIVE" | "INACTIVE" | "ALL";
};

type RequiredFieldType = Partial<{
  package_type: string[];
  package_service_type: string[];
  start_date: string[];
  end_date: string[];
  code: string[];
  name: string[];
  price_normal: string[];
  price_premium: string[];
  price_foreign: string[];
  age_range: boolean;
}>;

export type PackageDetailType = {
  price_normal: string;
  price_premium: string;
  price_foreign: string;
  activated?: boolean;
  can_delete?: boolean;
  price: string;
  items?: ProductDetailType[];
  isActionEdit?: boolean; // * Action Edit from table
} & Omit<PackageSerializer, "items">;

export type ProductDetailType = {
  id?: number;
  package?: number;
  product?: number;
  product_code: string;
  product_name: string;
  product_name_en: string;
  p_type: number;
  p_type_code: ProductTypeKey;
  package_group_code: ProductTypeKey;
  price: string;
  unit_price: string;
  unit_name: string;
  quantity: string;
  is_display: true;
  active: true;
  index: number;
  deleted?: boolean;
};

export type ProductTypeKey = keyof typeof PRODUCT_TYPES;

export type MasterOptionsType = Record<
  (typeof Masters)[number][0],
  OptionType[] | undefined
>;

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

type SearchBoxType = {
  action?: string;
  id?: "code" | "code_or_name" | "name";
  activePage?: number;
  limit?: number;
  searchText: string;
  type: ProductTypeKey;
};

export type UsageLimitType = keyof typeof USAGE_LIMIT_TYPES;

// Sequence
type SeqState = {
  sequence: "SettingPackage" | "SettingQueue";
  restart?: boolean;
  clear?: boolean;
  card?: string;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; searchText: string; card: string }
  | { action: "SEARCH_PRODUCT"; card: string; data: SearchBoxType }
  | { action: "LIST_PACKAGE"; activePage: number; card?: string }
  // Action
  | {
      action: "REFRESH_OPTION";
      card: string;
      type: "PACKAGE_TYPE" | "SERVICE_TYPE";
    }
  | {
      action: "SELECT_PACKAGE";
      packageId: number;
      isActionEdit?: boolean;
      index?: number;
      card?: string;
    }
  | { action: "CLEAR" }
  | { action: "NEW_PACKAGE" }
  // Method
  | {
      action: "SAVE" | "ACTIVE";
      card: string;
      data: Partial<PackageDetailType>;
      onSuccess?: Function;
    }
  | {
      action: "ADD_PRODUCT";
      type: ProductTypeKey;
      items: ProductForPackageSerializer[];
      onSuccess?: (success: boolean) => any;
    }
  | {
      action: "DELETE";
      card: string;
      errorKey: string;
      packageId: number;
      onSuccess?: Function;
    }
  | {
      action: "DUPLICATE";
      card: string;
      code: string;
      name: string;
      onSuccess?: Function;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

export type SetProp = SetProperty<State & Picked>;

type CustomExtract<T, U> = T extends T
  ? U extends Partial<T>
    ? T
    : never
  : never;

type Params<A extends ActionType["action"]> = CustomExtract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  SettingPackageSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

export const BUTTON_ACTIONS = {
  SEARCH: "SEARCH",
} as const;

export const PRODUCT_TYPES = {
  LAB: {
    name: "Lab",
    title: " Lab",
    tab: "LAB",
  },
  IMAGING: {
    name: "Imaging",
    title: " Imaging",
    tab: "IMAGING",
  },
  TREATMENT: {
    name: "Treatment",
    title: " Treatment",
    tab: "TREATMENT",
  },
  SUPPLY: {
    name: "Supply",
    title: "เวชภัณฑ์",
    tab: "SUPPLY",
  },
  DRUG: {
    name: "Drug",
    title: "ยา",
    tab: "DRUG",
  },
  MISCELLANEOUS: {
    name: "Miscellaneous",
    title: "เบ็ดเตล็ด",
    tab: "MISCELLANEOUS",
  },
  DOCTOR_FEE: {
    name: "Doctor Fee",
    title: "ค่าตอบแทน",
    tab: "DOCTOR FEE",
  },
  ROOM: {
    name: "Room Rate",
    title: "ค่าห้อง",
    tab: "ROOM RATE",
  },
  NURSE: {
    name: "Nurse Rate",
    title: "ค่าบริการทางการพยาบาล",
    tab: "NURSE RATE",
  },
  FOOD: {
    name: "Food Type",
    title: "ประเภทอาหาร",
    tab: "FOOD TYPE",
  },
};

export const USAGE_LIMIT_TYPES = {
  ONLY_ONCE_IN_ENCOUNTER: "ONLY_ONCE_IN_ENCOUNTER",
  MULTIPLE_ENCOUNTER: "MULTIPLE_ENCOUNTER",
};

export const PACKAGE_USAGE_STATUS = {
  active: "เปิดขาย",
  inactive: "ยังไม่เปิดขาย",
};

export const GENDER_OPTIONS = [
  { key: 1, text: "ชาย", value: "MALE" },
  { key: 2, text: "หญิง", value: "FEMALE" },
  { key: 3, text: "ไม่ระบุ", value: "NA" },
];

const USAGE_LIMIT_OPTIONS = [
  {
    key: 1,
    text: "Encounter เดียว",
    value: USAGE_LIMIT_TYPES.ONLY_ONCE_IN_ENCOUNTER,
  },
  {
    key: 2,
    text: "หลาย Encounter",
    value: USAGE_LIMIT_TYPES.MULTIPLE_ENCOUNTER,
  },
];

export const PACKAGE_STATUS_OPTIONS = [
  {
    key: 2,
    text: PACKAGE_USAGE_STATUS.active,
    value: "ACTIVE",
  },
  {
    key: 3,
    text: PACKAGE_USAGE_STATUS.inactive,
    value: "INACTIVE",
  },
];

export const filterPackageInit = {
  serviceType: "ALL",
  packageType: "ALL",
  status: "ALL",
} as const;

export const PACKAGE_SEARCH_ID = "Package_SSP";

export const PACKAGE_LIST_LIMIT = 20;

export const PRODUCT_PACKAGE_LIMIT = 40;

const Masters = [
  ["packageType", {}],
  ["packageServiceType", {}],
] as const;

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  const [packageType, serviceType] = await Promise.all([
    GetPackageTypeOptions(controller, {}),
    GetServiceTypeOptions(controller, {}),
  ]);

  controller.setState(
    {
      SettingPackageSequence: {
        ...state.SettingPackageSequence,
        sequenceIndex: "Action",
        showDetail: false,
        genderOptions: GENDER_OPTIONS,
        usageLimitOptions: USAGE_LIMIT_OPTIONS,
        packageTypeOptions: packageType,
        serviceTypeOptions: serviceType,
        packageDetail: {},
        filterPackage: filterPackageInit,
      },
    },
    () =>
      Action(controller, {
        action: "LIST_PACKAGE",
        activePage: 1,
        card: params.card,
      })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "SAVE") {
    HandleSave(controller, params);
  } else if (params.action === "ACTIVE") {
    HandleActive(controller, params);
  } else if (params.action === "DELETE") {
    HandleDelete(controller, params);
  } else if (params.action === "DUPLICATE") {
    HandleDuplicate(controller, params);
  } else if (params.action === "SEARCH_PRODUCT") {
    HandleSearchProduct(controller, params);
  } else if (params.action === "ADD_PRODUCT") {
    HandleAddProduct(controller, params);
  } else if (params.action === "REFRESH_OPTION") {
    HandleRefreshOption(controller, params);
  } else if (params.action === "SELECT_PACKAGE") {
    HandleSelectPackage(controller, params);
  } else if (params.action === "LIST_PACKAGE") {
    HandleListPackage(controller, params);
  } else if (params.action === "CLEAR") {
    HandleClear(controller, params);
  } else if (params.action === "NEW_PACKAGE") {
    HandleNewPackage(controller, params);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [result] = await PackageList.list({
    params: { keyword: params.searchText, limit: 20 },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  if (!result?.items?.length) {
    SetErrorMessage(controller, {
      ...params,
      errorKey: `${params.card}_${params.action}`,
      error: "NOT_FOUND",
    });
  } else {
    controller.setState({
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [PACKAGE_SEARCH_ID]: result.items || [],
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  }
};

const HandleSave: Handler<Params<"SAVE">> = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const data = params.data;

  data.items = data.items?.map((item) =>
    !Number(item.quantity) ? { ...item, deleted: true } : item
  );

  const isCreate = !data.id;
  const isActionEdit = data.isActionEdit;

  const api = isCreate ? PackageList.create : PackageDetail.update;

  const [result, error] = await api({
    apiToken: controller.apiToken,
    data,
    pk: data.id,
  });

  if (error) {
    controller.setState(
      {
        SettingPackageSequence: {
          ...state.SettingPackageSequence,
          showRequiredField: error,
        },
      },
      () => SetErrorMessage(controller, { ...params, error })
    );
  } else {
    const [checkDelete] = await PackageCheckDeleteView.get({
      apiToken: controller.apiToken,
      pk: result.id,
    });

    const data = {
      ...result,
      isActionEdit,
      can_delete: checkDelete?.can_delete || false,
      items: result.items.map((item: any, index: number) => ({
        ...item,
        index,
      })),
    };

    const activePage =
      state.SettingPackageSequence?.packageList?.activePage || 1;

    controller.setState(
      {
        SettingPackageSequence: {
          ...state.SettingPackageSequence,
          selectedPackage: data.id,
          packageDetail: { ...data },
          rawPackageDetail: { ...data },
          showRequiredField: {},
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
      },
      () => Action(controller, { action: "LIST_PACKAGE", activePage })
    );

    params.onSuccess?.();
  }
};

const HandleActive: Handler<Params<"ACTIVE">> = async (controller, params) => {
  const isActive = !!params.data.active;

  HandleSave(controller, {
    ...params,
    data: {
      ...params.data,
      ...(isActive ? { active: false } : { active: true }),
    },
  });
};

const HandleDelete: Handler<Params<"DELETE">> = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const [_, error] = await PackageDetail.delete({
    apiToken: controller.apiToken,
    pk: params.packageId,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    const activePage =
      state.SettingPackageSequence?.packageList?.activePage || 1;

    controller.setState(
      {
        SettingPackageSequence: {
          ...state.SettingPackageSequence,
          packageDetail: {},
          showDetail: false,
          selectedPackage: null,
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
        searchedItemListWithKey: {
          ...state.searchedItemListWithKey,
          [PACKAGE_SEARCH_ID]: [],
        },
      },
      () =>
        Action(controller, {
          action: "LIST_PACKAGE",
          activePage,
          card: params.card,
        })
    );

    params.onSuccess?.();
  }
};

const HandleDuplicate: Handler<Params<"DUPLICATE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const packageDetail = state.SettingPackageSequence?.packageDetail;

  const [result, error] = await PackageDuplicateView.post({
    pk: packageDetail?.id,
    apiToken: controller.apiToken,
    data: { code: params.code },
  });

  if (error) {
    SetErrorMessage(controller, {
      ...params,
      error: "DUPLICATE_PACKAGE",
      errorKey: `${params.card}_${params.action}`,
    });
  } else {
    // แก้ไขชื่อ package
    await PackageDetail.update({
      apiToken: controller.apiToken,
      data: { ...result, name: params.name },
      pk: result.id,
    });

    Action(controller, { action: "LIST_PACKAGE", activePage: 1 });

    controller.setState(
      {
        searchedItemListWithKey: {
          ...state.searchedItemListWithKey,
          [PACKAGE_SEARCH_ID]: [result],
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: null,
        },
      },
      () =>
        Action(controller, {
          action: "SELECT_PACKAGE",
          packageId: result.id,
          isActionEdit: packageDetail?.isActionEdit,
        })
    );

    params.onSuccess?.();
  }
};

const HandleSearchProduct: Handler<Params<"SEARCH_PRODUCT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
  });

  const [result] = await GetListProductForPackage(controller, {
    ...params.data,
    id: "code_or_name",
  });

  controller.setState({
    SettingPackageSequence: {
      ...state.SettingPackageSequence,
      searchedProductList: {
        activePage: params.data.activePage,
        items: result?.items || [],
        total: result?.total || 0,
      },
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "SUCCESS" },
  });
};

// #const HandleSearchProduct: Handler<Params<"SEARCH_PRODUCT">> = async (
//   controller,
//   params
// ) => {
//   let state = controller.getState();
//   const data = params.data;
//   const type = data.type as ProductTypeKey;
//   if (data.action === "clear") {
//     return controller.setState({
//       searchedItemListWithKey: {
//         ...state.searchedItemListWithKey,
//         [`${data.type}_${data.id}`]: [],
//       },
//     });
//   }
//   const result = await ProductForPackageList.list({
//     apiToken: controller.apiToken,
//     params: {
//       limit: data.limit,
//       [data.id]: data.searchText,
//       group_code: type,
//     },
//     extra: { division: controller.data.division },
//   });
//   state = controller.getState();
//   let items: any[] = (result?.[0]?.items || []).map((item: any) => ({
//     ...item,
//     name_code: `[${item.code}] ${item.name}`,
//   }));
//   controller.setState({
//     searchedItemListWithKey: {
//       ...state.searchedItemListWithKey,
//       [`${type}_${data.id}`]: [...items],
//     },
//   });
// };

const HandleAddProduct: Handler<Params<"ADD_PRODUCT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const packageDetail = state.SettingPackageSequence?.packageDetail || {};
  const items = params.items;
  const type = params.type;

  if (items.length) {
    const [newItems, oldItems] = items.reduce(
      (result, item) => {
        const index = packageDetail.items?.findIndex(
          (acc) => acc.product === item.id
        );

        result[index === -1 ? 0 : 1].push({ ...item, target: index });

        return result;
      },
      [[] as any[], [] as any[]]
    );

    const list = state.SettingPackageSequence?.packageDetail?.items || [];

    // * หากมี product อยู่แล้วให้บวก qty
    if (oldItems.length) {
      for (const data of oldItems) {
        list[data.target].quantity = (
          Number(list[data.target].quantity) + 1
        ).toString();
      }
    }

    for (const data of newItems) {
      data.product = data.id;

      delete data.id;

      list.push({
        ...data,
        quantity: "1",
        product_code: data.code,
        product_name: data.name,
        product_name_en: data.name_en,
        unit_price: data.unit_price,
        price: data.unit_price,
        // active: true,
        index: list.length,
        is_display: true,
      });
    }

    controller.setState({
      SettingPackageSequence: {
        ...state.SettingPackageSequence,
        packageDetail: {
          ...state.SettingPackageSequence?.packageDetail,
          items: [...list],
        },
        selectedProduct: {},
      },
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [`${type}_code`]: [],
        [`${type}_name`]: [],
      },
    });

    params.onSuccess?.(true);
  } else {
    params.onSuccess?.(false);
  }
};

const HandleRefreshOption: Handler<Params<"REFRESH_OPTION">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}_${params.type}`]: "LOADING",
    },
  });

  const option = {
    PACKAGE_TYPE: {
      key: "packageTypeOptions",
      api: GetPackageTypeOptions,
    },
    SERVICE_TYPE: {
      key: "serviceTypeOptions",
      api: GetServiceTypeOptions,
    },
  }[params.type];

  const options = await option.api(controller, {});

  state = controller.getState();

  controller.setState({
    SettingPackageSequence: {
      ...state.SettingPackageSequence,
      [option.key]: options,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}_${params.type}`]: "",
    },
  });
};

const HandleSelectPackage: Handler<Params<"SELECT_PACKAGE">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const btnKey = `${params.card}_${params.action}_${params.index}`;

  if (params.card) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });
  }

  const selected = state.searchedItemListWithKey?.[PACKAGE_SEARCH_ID]?.find(
    (item: any) => item.id === params.packageId
  );

  const isSelected = !!selected?.id || params.isActionEdit;
  const packageId = isSelected ? params.packageId : null;

  if (!isSelected) {
    await controller.setState({
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [PACKAGE_SEARCH_ID]: [],
      },
    });
  }

  const data = await GetPackageDetail(controller, { id: packageId });

  data.isActionEdit = params.isActionEdit;

  state = controller.getState();

  controller.setState({
    SettingPackageSequence: {
      ...state.SettingPackageSequence,
      selectedPackage: packageId || null,
      packageDetail: { ...data },
      rawPackageDetail: { ...data },
      showDetail: isSelected,
      showRequiredField: {},
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
  });
};

const HandleListPackage: Handler<Params<"LIST_PACKAGE">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const btnKey = `${params.card}_${params.action}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const result = await GetPackageList(controller, params);

  state = controller.getState();

  controller.setState({
    SettingPackageSequence: {
      ...state.SettingPackageSequence,
      packageList: {
        items: result?.items || [],
        total: result?.total || 0,
        activePage: params.activePage,
      },
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
  });
};

const HandleClear: Handler<Params<"CLEAR">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    SettingPackageSequence: {
      ...state.SettingPackageSequence,
      showDetail: false,
      selectedPackage: null,
      packageDetail: {},
      showRequiredField: {},
    },
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [PACKAGE_SEARCH_ID]: [],
    },
  });
};

const HandleNewPackage: Handler<Params<"NEW_PACKAGE">> = (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    SettingPackageSequence: {
      ...state.SettingPackageSequence,
      showDetail: true,
      showRequiredField: {},
      packageDetail: {
        items: [],
        can_delete: true,
        gender: "NA",
        isActionEdit: true,
      },
      selectedPackage: null,
    },
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetPackageTypeOptions: Handler = async (controller, params) => {
  const [result] = await PackageTypeList.list({
    apiToken: controller.apiToken,
  });

  return mapOptions(result?.items || []);
};

const GetServiceTypeOptions: Handler = async (controller, params) => {
  const [result] = await PackageServiceTypeList.list({
    apiToken: controller.apiToken,
  });

  return mapOptions(result?.items || []);
};

const GetPackageDetail: Handler<{ id?: number | null }> = async (
  controller,
  params
) => {
  if (params.id) {
    const [[packageDetail], [checkDelete]] = await Promise.all([
      PackageDetail.retrieve({
        apiToken: controller.apiToken,
        pk: params.id,
      }),
      PackageCheckDeleteView.get({
        apiToken: controller.apiToken,
        pk: params.id,
      }),
    ]);

    return {
      ...packageDetail,
      can_delete: checkDelete?.can_delete || false,
      items: packageDetail?.items?.map((item: any, index: number) => ({
        ...item,
        index,
      })),
    };
  } else {
    return {};
  }
};

const GetPackageList: Handler<
  { activePage: number },
  Promise<{ items: PackageSerializer[]; total: number }>
> = async (controller, params) => {
  const state = controller.getState();

  const packagePurchase = state.SettingPackageSequence || {};
  const filter = packagePurchase.filterPackage || {};
  const offset = (params.activePage - 1) * PACKAGE_LIST_LIMIT;

  const activeMap = {
    ACTIVE: true,
    INACTIVE: false,
  } as any;

  const formatParams = (key: keyof FilterPackageType) => {
    const value = filter?.[key];
    return value && value !== "ALL" ? value : undefined;
  };

  const [result] = await PackageList.list({
    apiToken: controller.apiToken,
    params: {
      package_type: formatParams("packageType"),
      package_service_type: formatParams("serviceType"),
      active: activeMap[formatParams("status") || ""],
      offset,
      limit: PACKAGE_LIST_LIMIT,
    },
  });

  let items: any[] = result?.items || [];

  return { items, total: result?.total || 0 };
};

const GetListProductForPackage: Handler<SearchBoxType> = async (
  controller,
  params
) => {
  const activePage = params.activePage || 1
  const offset = (activePage - 1) * PRODUCT_PACKAGE_LIMIT;

  return ProductForPackageList.list({
    apiToken: controller.apiToken,
    params: {
      group_code: params.type,
      limit: PRODUCT_PACKAGE_LIMIT,
      offset,
      [params.id]: params.searchText,
    },
    extra: { division: controller.data.division },
  }) as Promise<[unknown, unknown]>;
};

const GetLabOrderEstimate: Handler<{ data: any }> = async (
  controller,
  params
) => {
  const data = params.data;

  const orderItem = {
    _id: null,
    lab_code: data.lab_code,
    lab_speciality: data.lab_speciality,
    name: data.name,
    product: data.product_id,
    specimen: data.specimen,
    specimen_name: data.specimen_name,
    specimen_time: moment().format("HH:mm"),
    lab_type_label: data.lab_type_label,
    note: "",
    urgency: "ROUTINE", // To implement selecting urgency
    children: data.children.map((item: any) => ({
      ...item,
      id: null,
      note: "",
    })),
  };

  const estimate = await CentralLabOrderEstimate.post({
    apiToken: controller.apiToken,
    data: {
      order_id: null,
      encounter: 6991999,
      order_items: [orderItem],
      is_appointment: false,
    },
    extra: { division: controller.data.division },
  });

  return estimate[0];
};

const GetTreatmentOrderEstimate: Handler<{ data: any }> = async (
  controller,
  params
) => {
  const data = params.data;

  const orderItem = {
    id: null,
    product: data.id,
    quantity: "1",
  };

  const estimate = await TreatmentOrderEstimate.post({
    apiToken: controller.apiToken,
    data: {
      encounter: 6992056,
      have_compensation: false,
      order_items: [orderItem],
    },
    extra: { division: controller.data.division },
  });

  return estimate[0];
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

// #const PRODUCT_SEARCH = {
//   LAB: {
//     api: CentralLabTestList.list,
//     code: "lab_code",
//     name: "name",
//     params: { only_blood_bank: false },
//   },
//   IMAGING: {
//     api: MiscellaneousList.list,
//     code: "name",
//     name: "name",
//     params: { group_code: "XRAY" },
//   },
//   TREATMENT: {
//     api: TreatmentList.list,
//     code: "search",
//     name: "search",
//     params: {},
//   },
//   SUPPLY: {
//     api: SupplyList.list,
//     code: "keyword",
//     name: "keyword",
//     params: {},
//   },
//   DRUG: {
//     api: DrugList.list,
//     code: "keyword",
//     name: "keyword",
//     params: { exclude_outside_drug: true },
//   },
//   MISCELLANEOUS: {
//     api: MiscellaneousList.list,
//     code: "name",
//     name: "name",
//     params: { group_code: "MISC" },
//   },
//   DOCTOR_FEE: {
//     api: DoctorFeeRuleList.list,
//     code: "keyword",
//     name: "keyword",
//     params: {},
//   },
// };
