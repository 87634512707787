import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardRoomSearchUX = (props: any) => {
    return(
      <div>
        <div
          className="ui accordion blue  fluid raised segment"
          style={{boxShadow: "none"}}>
          
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <label
              style={{fontSize: "1.2rem", fontWeight: "bold"}}>
              ค้นหาห้อง
            </label>
            <div
              style={{cursor: "pointer"}}>
              {}
              <Icon
                className="red large"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
          <div
            className="ui divider">
            
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  เลขห้อง/เตียง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  onChange={props.onChangeRoomNo}
                  value={props.roomNo}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  ประเภทหอผู้ป่วย
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Dropdown
                  fluid={true}
                  onChange={props.onChangeWardType}
                  options={props.wardTypeOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.wardType}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Button
                  color={"blue"}
                  fluid={true}
                  loading={props.loading}
                  onClick={props.onSearch}>
                  ค้นหา
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  ประเภทห้อง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Dropdown
                  fluid={true}
                  onChange={props.onChangeRoomType}
                  options={props.roomTypeOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.roomType}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  สถานะห้อง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Dropdown
                  fluid={true}
                  onChange={props.onChangeRoomStatus}
                  options={props.roomStatusOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.roomStatus}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Button
                  color={"teal"}
                  fluid={true}
                  onClick={props.onClear}>
                  clear
                </Button>
              </FormField>
            </FormGroup>
            <div
              style={{marginBottom: "1rem"}}>
              
              <Table
                data={props.roomList}
                getTrProps={props.onGetTrProps}
                headers="เลขห้อง/เตียง,ประเภทหอผู้ป่วย,ประเภทห้อง,สถานะห้อง"
                keys="room_no,ward_name,room_name,room_status"
                showPagination={false}
                style={{width: "100%",  height: "400px"}}>
              </Table>
            </div>
            <FormGroup>
              <FormField
                inline={true}
                width={14}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Button
                  color={"green"}
                  fluid={true}
                  onClick={props.onSelect}>
                  เลือก
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}

export default CardRoomSearchUX

export const screenPropsDefault = {}

/* Date Time : Thu Jun 23 2022 07:24:38 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui accordion blue  fluid raised segment"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาห้อง"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Icon",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขห้อง/เตียง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Input",
      "parent": 11,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeRoomNo"
        },
        "value": {
          "type": "code",
          "value": "props.roomNo"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeWardType"
        },
        "options": {
          "type": "code",
          "value": "props.wardTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.wardType"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Button",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "code",
          "value": "\"blue\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทห้อง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Dropdown",
      "parent": 21,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeRoomType"
        },
        "options": {
          "type": "code",
          "value": "props.roomTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.roomType"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะห้อง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 23,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeRoomStatus"
        },
        "options": {
          "type": "code",
          "value": "props.roomStatusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.roomStatus"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Button",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "clear"
        },
        "color": {
          "type": "code",
          "value": "\"teal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1rem\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 33,
      "name": "Table",
      "parent": 32,
      "props": {
        "data": {
          "type": "code",
          "value": "props.roomList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "เลขห้อง/เตียง,ประเภทหอผู้ป่วย,ประเภทห้อง,สถานะห้อง"
        },
        "keys": {
          "type": "value",
          "value": "room_no,ward_name,room_name,room_status"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",  height: \"400px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormGroup",
      "parent": 7,
      "props": {
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Button",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "code",
          "value": "\"green\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelect"
        },
        "size": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 37,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "CardRoomSearchUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
