import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  TextArea,
  Button,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardPrintDrugLabelsManualUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          style={{display: "grid", gridTemplateColumns: "100% 0%"}}>
          
          <div>
            
            <div>
              
              <div
                style={{fontSize: "1.1rem", fontWeight: "bold", marginBottom: "0.5rem"}}>
                พิมพ์ฉลากยา Manual
              </div>
            </div>
            <div
              className="ui form"
              style={{backgroundColor: "#1678c2", borderRadius: "5px", padding: "5px 10px", marginBottom: "1em"}}>
              
              <FormGroup
                className="noMargin"
                inline={true}>
                <FormField
                  inline={true}>
                  <label
                    style={{minWidth: "max-content", color: "white"}}>
                    HN
                  </label>
                </FormField>
                <FormField
                  inline={true}
                  width={8}>
                  <div
                    style={{width: "100%"}}>
                    {props.PatientSearchBox}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <label
                    style={{color: "white", minWidth: "max-content"}}>
                    ชื่อผู้ป่วย
                  </label>
                </FormField>
                <FormField
                  inline={true}
                  width={8}>
                  <Input
                    readOnly={true}
                    value={props.patientName || ""}>
                  </Input>
                </FormField>
              </FormGroup>
            </div>
            <div
              className="ui form"
              style={{margin: "-5px 0 -10px"}}>
              
              <FormGroup
                inline={true}>
                <div
                  className="field"
                  style={{flex: 1}}>
                  
                </div>
                <FormField
                  inline={true}>
                  <Checkbox
                    checked={props.isBlankForm || false}
                    label="พิมพ์ฟอร์มเปล่า"
                    onChange={ props.onChangeCheckbox}
                    style={{fontWeight: "bold", minWidth: "max-content"}}>
                  </Checkbox>
                </FormField>
              </FormGroup>
            </div>
            <div
              className="ui form"
              style={{marginTop: "-5px"}}>
              
              <FormGroup>
                <FormField
                  width={8}>
                  <label>
                    ค้นหายา
                  </label>
                  <div
                    style={{width: "100%"}}>
                    {props.DrugSearch}
                  </div>
                </FormField>
                <FormField
                  width={3}>
                  <label>
                    QTY
                  </label>
                  <Input
                    name="qty"
                    onChange={props.onChangeValue}
                    type="number"
                    value={props.qty || ""}>
                  </Input>
                </FormField>
                <FormField>
                  <label>
                    Unit
                  </label>
                  <Dropdown
                    clearable={true}
                    name="unit"
                    onChange={props.onChangeValue}
                    options={props.unitOptions || []}
                    selection={true}
                    style={{minWidth: "11.5em"}}
                    value={props.unit || ""}>
                  </Dropdown>
                </FormField>
              </FormGroup>
              <FormGroup>
                <FormField
                  width={13}>
                  <label
                    style={{marginTop: "-7.5px"}}>
                    วิธีการใช้ยา
                  </label>
                  <TextArea
                    name="method"
                    onChange={props.onChangeValue}
                    rows={2}
                    value={props.method || ""}>
                  </TextArea>
                </FormField>
              </FormGroup>
              <FormGroup
                className="equal width">
                <FormField>
                  <label
                    style={{marginTop: "-7.5px"}}>
                    Indication:
                  </label>
                  <TextArea
                    name="indication"
                    onChange={props.onChangeValue}
                    rows={2}
                    value={props.indication || ""}>
                  </TextArea>
                </FormField>
                <FormField>
                  <label
                    style={{marginTop: "-7.5px"}}>
                    Helper labels:
                  </label>
                  <TextArea
                    name="helperLabels"
                    onChange={props.onChangeValue}
                    rows={2}
                    value={props.helperLabels || ""}>
                  </TextArea>
                </FormField>
              </FormGroup>
              <FormGroup>
                <FormField
                  width={16}>
                  <label
                    style={{marginTop: "-7.5px"}}>
                    ระบุข้อความเพิ่มเติม:
                  </label>
                  <TextArea
                    name="remark"
                    onChange={props.onChangeValue}
                    rows={2}
                    value={props.remark || ""}>
                  </TextArea>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <div
                  className="field"
                  style={{flex: 1}}>
                  
                </div>
                <FormField
                  inline={true}>
                  <Button
                    color="green"
                    disabled={props.disabledAdd || props.editMode || false}
                    onClick={props.onClickAdd}
                    size="small"
                    style={{marginTop: "-7.5px", display: props.editMode ? "none" : "", marginRight: "-1em"}}>
                    เพิ่มรายการ
                  </Button>
                  <Button
                    color="yellow"
                    disabled={props.disabledEdit || !props.editMode || false}
                    onClick={props.onClickEdit}
                    style={{marginTop: "-7.5px", display: props.editMode ? "" : "none",marginRight: "-1em"}}>
                    แก้ไขรายการ
                  </Button>
                </FormField>
              </FormGroup>
            </div>
            <div
              className="ui form"
              style={{marginTop: "-5px"}}>
              
              <Table
                data={props.items || []}
                defaultPageSize={props.items?.length > 10 ? props.items?.length : 10}
                headers={props.headers ? props.headers : ",รายการยา"}
                keys="checkbox, name, edit, remove"
                showPagination={false}
                style={{height: "calc(100vh - 45.5rem)"}}
                widths="60,,60,60">
              </Table>
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", marginTop: "5px"}}>
              
              <div>
                {props.Pagination}
              </div>
            </div>
          </div>
          <div>
            
          </div>
        </div>
      </div>
    )
}

export default React.memo(CardPrintDrugLabelsManualUX)

export const screenPropsDefault = {}

/* Date Time : Fri Feb 03 2023 17:25:33 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"100% 0%\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#1678c2\", borderRadius: \"5px\", padding: \"5px 10px\", marginBottom: \"1em\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ฉลากยา Manual"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormGroup",
      "parent": 11,
      "props": {
        "className": {
          "type": "value",
          "value": "noMargin"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", color: \"white\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{color: \"white\", minWidth: \"max-content\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 17,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientName || \"\""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-5px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 22,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormGroup",
      "parent": 22,
      "props": {
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 31,
      "props": {
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "วิธีการใช้ยา"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-7.5px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "TextArea",
      "parent": 32,
      "props": {
        "name": {
          "type": "value",
          "value": "method"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "value": {
          "type": "code",
          "value": "props.method || \"\""
        }
      },
      "seq": 34,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormGroup",
      "parent": 22,
      "props": {
        "className": {
          "type": "value",
          "value": "equal width"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "Indication:"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-7.5px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "Helper labels:"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-7.5px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "TextArea",
      "parent": 36,
      "props": {
        "name": {
          "type": "value",
          "value": "indication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "value": {
          "type": "code",
          "value": "props.indication || \"\""
        }
      },
      "seq": 40,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "TextArea",
      "parent": 37,
      "props": {
        "name": {
          "type": "value",
          "value": "helperLabels"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "value": {
          "type": "code",
          "value": "props.helperLabels || \"\""
        }
      },
      "seq": 41,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormGroup",
      "parent": 22,
      "props": {
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 42,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุข้อความเพิ่มเติม:"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-7.5px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "TextArea",
      "parent": 43,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "value": {
          "type": "code",
          "value": "props.remark || \"\""
        }
      },
      "seq": 45,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormGroup",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Button",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการ"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAdd || props.editMode || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAdd"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-7.5px\", display: props.editMode ? \"none\" : \"\", marginRight: \"-1em\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-5px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 51,
      "name": "Table",
      "parent": 50,
      "props": {
        "data": {
          "type": "code",
          "value": "props.items || []"
        },
        "defaultPageSize": {
          "type": "code",
          "value": "props.items?.length > 10 ? props.items?.length : 10"
        },
        "headers": {
          "type": "code",
          "value": "props.headers ? props.headers : \",รายการยา\""
        },
        "keys": {
          "type": "value",
          "value": "checkbox, name, edit, remove"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 45.5rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "60,,60,60"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"5px\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Pagination"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Button",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขรายการ"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledEdit || !props.editMode || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickEdit"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-7.5px\", display: props.editMode ? \"\" : \"none\",marginRight: \"-1em\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหายา"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "QTY"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 68,
      "props": {
        "name": {
          "type": "value",
          "value": "qty"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.qty || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Dropdown",
      "parent": 69,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.unitOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11.5em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.unit || \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"-5px 0 -10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormGroup",
      "parent": 79,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 80,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Checkbox",
      "parent": 82,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isBlankForm || false"
        },
        "label": {
          "type": "value",
          "value": "พิมพ์ฟอร์มเปล่า"
        },
        "onChange": {
          "type": "code",
          "value": " props.onChangeCheckbox"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 83,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardPrintDrugLabelsManualUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
