import { TDocumentDefinitions } from "pdfmake/interfaces";

import { getDrugTable } from "./FormMedicine";
import HeaderMedicineMore, {
  HeaderMedicineMoreProps,
} from "./HeaderMedicineMore";

type FormMedicineMoreProps = {
  items: Record<string, any>[];
} & HeaderMedicineMoreProps;

const FormMedicineMore = (props: FormMedicineMoreProps): TDocumentDefinitions => {
  const headerMedicineMore = HeaderMedicineMore(props);

  return {
    ...headerMedicineMore,
    styles: {
      tableData: { fontSize: 13 },
      tableHeader: {
        bold: true,
        color: "black",
        fontSize: 13,
      },
    },
    content: [getDrugTable(props.items)],
  };
};

export default FormMedicineMore;
