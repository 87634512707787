import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Icon,
  TextArea,
  Button
} from 'semantic-ui-react'

const ModMakeAppointmentPackageUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "18px 10px 3px 25px", }}>
          
          <div
            style={{ color: "#0072BC", fontSize: "1.2rem", fontWeight: "bold", }}>
            จองวันและเวลาทำนัดหมาย
          </div>
        </div>
        <div
          className="ui divider"
          style={{margin: "1rem"}}>
          
        </div>
        <div
          style={{padding: "0.2rem 2rem 0.25rem", lineHeight: 1.25}}>
          
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{fontSize: "1.1rem", fontWeight: "bold"}}>
                  {props.patientInfo && `${props.patientInfo?.full_name} [${props.patientInfo?.hn}]`}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginLeft: "5px"}}>
              <FormField
                inline={true}>
                <div
                  style={{transform:"scale(1.3)", margin: "0 -0.5rem 0 -0.5rem"}}>
                  
                  <Icon
                    className="blue"
                    name="man">
                  </Icon>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  {props.patientInfo?.birthdate || ""}
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{transform:"rotate(90deg)", margin: "0 -0.5rem 0 0rem"}}>
                  
                  <Icon
                    className="blue"
                    name="phone">
                  </Icon>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  {props.patientInfo?.phone_num || "-"}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginTop: "1.5rem"}}>
              <FormField
                inline={true}>
                <label
                  style={{fontSize: "1.1rem", color: "#0072BC"}}>
                  Package ที่ต้องการทำนัดหมาย
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <div
                  style={ {lineHeight: 2.25, marginTop: "-5px"}}>
                  {props.packageNameList}
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{fontSize: "1.1rem", color: "#0072BC"}}>
                  วันที่ต้องการทำนัดหมาย
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{fontWeight: "normal", marginRight: "5rem"}}>
                  {props.slotDate || ""}
                </label>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{fontWeight: "normal"}}>
                  {`เวลา ${props.time || ""}`}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{fontWeight: "normal"}}>
                  {`สถานที่ : ${props.divisionName || ""}`}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginTop: "2rem"}}>
              <FormField
                inline={true}>
                <label>
                  คำแนะนำในการปฏิบัติตัวเพิ่มเติม
                </label>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  color="blue"
                  onClick={props.onAddNote}
                  size="mini">
                  เพิ่มคำแนะนำ
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{padding: 0}}
                width={16}>
                <TextArea
                  name="doctor_note"
                  onChange={props.onChangeValue}
                  rows={5}
                  value={props.remark || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginTop: "2rem"}}>
              <FormField
                inline={true}
                style={{flex:1}}>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.ButtonMakeAppointment}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{padding: 0}}>
                <Button
                  color="red"
                  onClick={props.onCancel}>
                  ยกเลิก
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default ModMakeAppointmentPackageUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Mon Jul 03 2023 03:15:54 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\", padding: \"18px 10px 3px 25px\", }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "จองวันและเวลาทำนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"#0072BC\", fontSize: \"1.2rem\", fontWeight: \"bold\", }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.2rem 2rem 0.25rem\", lineHeight: 1.25}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInfo && `${props.patientInfo?.full_name} [${props.patientInfo?.hn}]`"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInfo?.birthdate || \"\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInfo?.phone_num || \"-\""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{transform:\"scale(1.3)\", margin: \"0 -0.5rem 0 -0.5rem\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{transform:\"rotate(90deg)\", margin: \"0 -0.5rem 0 0rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Icon",
      "parent": 19,
      "props": {
        "className": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "phone"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Icon",
      "parent": 18,
      "props": {
        "className": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "man"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Package ที่ต้องการทำนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", color: \"#0072BC\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormGroup",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ต้องการทำนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", color: \"#0072BC\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormGroup",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormGroup",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 35,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.slotDate || \"\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", marginRight: \"5rem\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "`เวลา ${props.time || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": "`สถานที่ : ${props.divisionName || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.packageNameList"
        },
        "style": {
          "type": "code",
          "value": " {lineHeight: 2.25, marginTop: \"-5px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormGroup",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormGroup",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormGroup",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "TextArea",
      "parent": 51,
      "props": {
        "name": {
          "type": "value",
          "value": "doctor_note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.remark || \"\""
        }
      },
      "seq": 52,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "คำแนะนำในการปฏิบัติตัวเพิ่มเติม"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Button",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มคำแนะนำ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNote"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Button",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonMakeAppointment"
        }
      },
      "seq": 63,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "ModMakeAppointmentPackageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
