import WasmController from "react-lib/frameworks/WasmController";
import { HandleGetEmployeeTokenization } from "../../TPD/TPDInterface";
import SupplyOrderList from "issara-sdk/apis/SupplyOrderList_apps_MSD";
import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";

export type State = {
  SupplyOrderHistorySequence?: {
    sequenceIndex?: any;
    selectedPatient?: string | null;
    selectedDoctor?: string | null;
    selectedType?: string | null;
    selectedStatus?: string | null;
    selectedOrderDivision?: string | null;
    selectedPerformDivision?: string | null;
    selectedOrderStartDate?: string | null;
    selectedOrderEndDate?: string | null;
    selectedPerformStartDate?: string | null;
    selectedPerformEndDate?: string | null;
    selectedUser?: string | null;
    checkedPatient?: boolean | null;
    checkedDoctor?: boolean | null;
    checkedType?: boolean | null;
    checkedStatus?: boolean | null;
    checkedOrderDivision?: boolean | null;
    checkedPerformDivision?: boolean | null;
    checkedOrderDate?: boolean | null;
    checkedPerformDate?: boolean | null;
    checkedUser?: boolean | null;
    loading?: boolean | null;
    error?: any | null;
    selectedUserToken?: string | null;
    supplyOrderHistoryList?: any | null;
  } | null;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
  DispensingOrderSupplySequence?: any;
};

export const StateInitial: State = {
  SupplyOrderHistorySequence: {},
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};


export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const SearchAndSelect: Handler = async (controller, params) => {
  var state = controller.getState();

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["divisionSupply", {}],
        ["supplyStatus", {}],
        ["supplyType", {}],
      ],
    },
  });

  state = controller.getState();

  controller.setState({
    SupplyOrderHistorySequence: {
      ...state.SupplyOrderHistorySequence,
    },
  });

  if (params.action === "searchToken") {
    controller.setState({
      SupplyOrderHistorySequence: {
        ...state.SupplyOrderHistorySequence,
        loading: true,
      },
    });

    const [response, error, network] = await HandleGetEmployeeTokenization(
      controller as any,
      {
        code: params.code,
      }
    );

    if (error) {
      controller.setState({
        SupplyOrderHistorySequence: {
          ...state.SupplyOrderHistorySequence,
          error: error,
          loading: false,
        },
      });
      return;
    }
    controller.setState({
      SupplyOrderHistorySequence: {
        ...state.SupplyOrderHistorySequence,
        selectedUserToken: response?.token || "",
        selectedUser:
          decodeURIComponent(atob(response?.token.split(".")[1])) || "",
        loading: false,
        checkedUser: true,
      },
    });
  } else if (params.action === "search") {
    const data = state.SupplyOrderHistorySequence;

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [r, e, n] = await SupplyOrderList.list({
      params: {
        patient: data?.checkedPatient ? data?.selectedPatient : "",
        doctor: data?.checkedDoctor ? data?.selectedDoctor : "",
        type: data?.checkedType ? data?.selectedType : "",
        status: data?.checkedStatus ? data?.selectedStatus : "",
        order_division: data?.checkedOrderDivision
          ? data?.selectedOrderDivision
          : "",
        perform_division: data?.checkedPerformDivision
          ? data?.selectedPerformDivision
          : "",
        user: data?.checkedUser ? data?.selectedUserToken : "",
        order_from_date: data?.checkedOrderDate
          ? data?.selectedOrderStartDate
          : "",
        order_to_date: data?.checkedOrderDate ? data?.selectedOrderEndDate : "",
        perform_from_date: data?.checkedPerformDate
          ? data?.selectedPerformStartDate
          : "",
        perform_to_date: data?.checkedPerformDate
          ? data?.selectedPerformEndDate
          : "",
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: e },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: r },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        SupplyOrderHistorySequence: {
          ...state.SupplyOrderHistorySequence,
          supplyOrderHistoryList: r?.items,
        },
      });
    }
  } else if (params.action === "clear") {
    controller.setState({
      SupplyOrderHistorySequence: {
        ...state.SupplyOrderHistorySequence,
        selectedDoctor: "",
        selectedOrderDivision: "",
        selectedOrderEndDate: "",
        selectedOrderStartDate: "",
        selectedPatient: "",
        selectedPerformDivision: "",
        selectedPerformEndDate: "",
        selectedPerformStartDate: "",
        selectedStatus: "",
        selectedType: "",
        selectedUser: "",
        selectedUserToken: "",
        checkedDoctor: false,
        checkedOrderDate: false,
        checkedOrderDivision: false,
        checkedPatient: false,
        checkedPerformDate: false,
        checkedPerformDivision: false,
        checkedStatus: false,
        checkedType: false,
        checkedUser: false,
        loading: false,
        error: null,
      },
    });
  } else if (params.action === "select") {
    const supplyOrder = await SupplyOrderDetail.retrieve({
      pk: params.orderId,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    controller.setState({
      DispensingOrderSupplySequence: {
        ...state.DispensingOrderSupplySequence,
        selectedOrderId: params?.orderId,
      },
    });
    const [r, e, n] = await EncounterDetail.retrieve({
      pk: supplyOrder?.[0]?.encounter,
      apiToken: controller.apiToken,
    });

    if (e) {
      console.log("error ");
      return;
    }

    await controller.handleEvent({
      message: "SelectEncounter" as any,
      params: { encounter: r || null, selectedRecordViewIndex: 0 },
    });

    state = controller.getState();
  }
};
