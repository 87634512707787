// NPM: MAIN
import React, { useRef, useState, useEffect, CSSProperties, MutableRefObject } from 'react'

type SubUserTokenStateType = {
  token?: string;
  controller: any
  error?: boolean
  onChangeToken?: (token: string, error?: any) => any
}

type InputNurseType = {
  text: boolean;
  value: string;
  token: string
}

const SubUserTokenStateTypeInitial: SubUserTokenStateType = {
  token: "",
  controller: null,
  error: false,
  onChangeToken: () => null
}

const initialInputNurse: InputNurseType = {
  text: false,
  value: "",
  token: ""
}

const SubUserToken: React.FC<SubUserTokenStateType> = (props) => {
  const [inputNurse, setInputNurse] = useState<InputNurseType>(initialInputNurse)
  const [inputStyle, setInputStyle] = useState<CSSProperties>({})
  const nurseRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (props.token) {
      onSetToken(props.token)
    } else {
      setInputNurse(initialInputNurse)
    }
  }, [props.token])

  useEffect(() => {
    setInputStyle(props.error ? {
      backgroundColor: "#fff6f6",
      borderColor: "#e0b4b4",
      color: "#9f3a38",
      boxShadow: "none"
    } : {})
    if (nurseRef.current && props.error) {
      nurseRef.current.focus()
    }
  }, [props.error])
  // ---------------------------- FETCH API, method: GET ---------------------
  const getNurseToken = async (value: string): Promise<string> => {
    if (!value) {
      return ""
    }
    let nurseToken = ""
    const [res, err] = await props.controller.userManager.getUserTokenize(
      { code: value }
    )

    if (!err) {
      nurseToken = res.token
    }

    return nurseToken
  }
  // ---------------------------- END FETCH API ----------------------------

  /**
   * Handle close input full name
   */
  const handleOnClick = () => {
    setInputNurse(initialInputNurse)
    props.onChangeToken && props.onChangeToken("", null)
    setTimeout(() => {
      nurseRef.current && nurseRef.current.focus()
    });
  }

  /**
   * Handle keypress input code
   */
  const handleOnKeyPress = async (ev: any) => {
    if (ev.key === "Enter") {
      ev.preventDefault()
      const value = ev.target.value
      const nurseToken = await getNurseToken(value)

      if (nurseToken) {
        onSetToken(nurseToken)
        setInputStyle({})
      } else {
        props.onChangeToken && props.onChangeToken("", {
          detail: "รหัสไม่ถูกต้อง"
        })
      }
    }
  }

  const onSetToken = (token: string) => {
    const encodedName = token.split('.')[1]
    const fullName = decodeURIComponent(atob(encodedName))

    setInputNurse({
      text: true,
      value: fullName,
      token,
    })
    props.onChangeToken && props.onChangeToken(token, null)
  }

  return (
    <>
      {
        inputNurse.text ?
          <div className="ui icon input">
            <input type="text"
              placeholder="Code"
              value={inputNurse.value}
              style={inputStyle}
            />
            <i aria-hidden="true"
              className="remove link icon"
              onClick={handleOnClick}
            ></i>
          </div>
          :
          <input
            type="password"
            placeholder="Code"
            ref={nurseRef}
            style={inputStyle}
            onKeyPress={handleOnKeyPress}
          />
      }
    </>
  )
}

SubUserToken.defaultProps = SubUserTokenStateTypeInitial
export default SubUserToken
