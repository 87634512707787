import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, Segment } from "semantic-ui-react";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import TimeTextBox from "react-lib/apps/common/TimeTextBox";
import { useIntl } from "react-intl";

// Types
type ORCheckoutComponentProps = {
  onEvent: (e: any) => any;
  // data
  open: boolean;
  selectedOrOrder?: Record<string, any>;
  // options
  masterOptions?: Record<string, any[]>;
  // callback
  onClose: () => any;
};

const ORCheckoutComponent = (props: ORCheckoutComponentProps) => {
  const intl = useIntl();
  const [payload, setPayload] = useState<any>({
    dead: false,
    checkout_cause: "",
    death_date: null,
    death_time: null,
  });

  useEffect(() => {
    props.onEvent({ message: "GetCheckoutMaster" });
  }, [props.selectedOrOrder]);

  const handleChangeORCheckout = (_event: any, data: any) => {
    if (data.value === "DEAD") {
      setPayload({ ...payload, dead: true, [data.name]: data.value });
    } else {
      setPayload({ ...payload, [data.name]: data.value });
    }
  };

  const handleChangeORDeathDate = (date: string) => {
    setPayload({ ...payload, death_date: date });
  };

  const handleChangeORDeathTime = (_event: any, time: string) => {
    setPayload({ ...payload, death_time: time });
  };

  const closeModORCheckout = () => {
    setPayload({
      dead: false,
      checkout_cause: "",
      death_date: null,
      death_time: null,
    });

    props.onClose();
  };

  const handleORCheckout = () => {
    props.onEvent({
      message: "ORCheckout",
      params: {
        order: props.selectedOrOrder,
        checkout_cause: payload.checkout_cause,
        death_datetime: payload.dead
          ? payload.death_date + "-" + payload.death_time
          : null,
      },
    });

    closeModORCheckout();
  };

  return (
    <>
      <Modal
        size="mini"
        closeIcon
        open={props.open}
        onClose={closeModORCheckout}
      >
        {/* @ts-ignore */}
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          Check out
        </Segment>
        <Modal.Content>
          {/* @ts-ignore */}
          <Form>
            <Form.Field width={16} required>
              <label>{intl.formatMessage({ id: "สาเหตุการสิ้นสุดการรักษา" })}</label>
            </Form.Field>
            <Form.Field width={16}>
              <Dropdown
                className="fluidDropdown"
                selection
                name="checkout_cause"
                value={payload.checkout_cause || ""}
                options={props.masterOptions?.planDischargeType || []}
                onChange={handleChangeORCheckout}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleORCheckout}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModORCheckout}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="mini"
        closeIcon
        open={props.open && payload.dead}
        onClose={closeModORCheckout}
      >
        {/* @ts-ignore */}
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          Check out DEAD
        </Segment>
        <Modal.Content>
          {/* @ts-ignore */}
          <Form>
            <Form.Field width={16}>
              <DateTextBox
                value={payload.death_date}
                onChange={handleChangeORDeathDate}
              />
            </Form.Field>
            <Form.Field width={16}>
              <TimeTextBox
                value={payload.death_time}
                useDefaultTime={true}
                onTimeChange={handleChangeORDeathTime}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleORCheckout}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModORCheckout}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default React.memo(ORCheckoutComponent);
