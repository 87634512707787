import WasmController from "react-lib/frameworks/WasmController";

// APIS
// BILL
import ReceiptList from "issara-sdk/apis/ReceiptList_apps_BIL";
import InvoiceList from "issara-sdk/apis/InvoiceList_apps_BIL";
import CreditNoteList from "issara-sdk/apis/CreditNoteList_apps_BIL";
import InvoiceItemByOrderList from "issara-sdk/apis/InvoiceItemByOrderList_apps_BIL";
import InvoiceItemByModeList from "issara-sdk/apis/InvoiceItemByModeList_apps_BIL";
import InvoiceItemList from "issara-sdk/apis/InvoiceItemList_apps_BIL";
import NewReportMedicalFeeDetailView from "issara-sdk/apis/NewReportMedicalFeeDetailView_apps_BIL";
import EDCTransactionList from "issara-sdk/apis/EDCTransactionList_apps_BIL";
import ReceiptDetail from "issara-sdk/apis/ReceiptDetail_apps_BILM";
import ReportStationSummaryView from "issara-sdk/apis/ReportStationSummaryView_apps_BIL";
import ReportDrawerCoverageView from "issara-sdk/apis/ReportDrawerCoverageView_apps_BIL";
import ReportDrawerCashView from "issara-sdk/apis/ReportDrawerCashView_apps_BIL";
import StationLogList from "issara-sdk/apis/StationLogList_apps_BIL";
import ReportPrintTrackingResult from "issara-sdk/apis/ReportPrintTrackingResult_core";
import ReportDailyCashView from "issara-sdk/apis/ReportDailyCashView_apps_BIL";
import ReportDailyCoverageView from "issara-sdk/apis/ReportDailyCoverageView_apps_BIL";
import ReportDailyUnpaidView from "issara-sdk/apis/ReportDailyUnpaidView_apps_BIL";
import ReportDailyApprovedUnperformedView from "issara-sdk/apis/ReportDailyApprovedUnperformedView_apps_BIL";
import ReportDailyApprovedView from "issara-sdk/apis/ReportDailyApprovedView_apps_BIL";
import ReportApprovalCodeView from "issara-sdk/apis/ReportApprovalCodeView_apps_BIL";
import ReportInstallmentView from "issara-sdk/apis/ReportInstallmentView_apps_BIL";
import ReportIpdDischargeView from "issara-sdk/apis/ReportIpdDischargeView_apps_BIL";
import InvoiceDetail from "issara-sdk/apis/InvoiceDetail_apps_BIL";
import EDCTransactionDetail from "issara-sdk/apis/EDCTransactionDetail_apps_BIL";
import EDCTransactionLogDetail from "issara-sdk/apis/EDCTransactionLogDetail_apps_BILM";
import ReportDailyRevenueView from "issara-sdk/apis/ReportDailyRevenueView_apps_BIL";
import ReportCashierWorkingView from "issara-sdk/apis/ReportCashierWorkingView_apps_BIL";
import BillingUserList from "issara-sdk/apis/BillingUserList_apps_BIL";
import CreditNoteDetail from "issara-sdk/apis/CreditNoteDetail_apps_BILM";
import ReportPrintingCopyReceiptView from "issara-sdk/apis/ReportPrintingCopyReceiptView_apps_BIL";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import DepositRecordReportToExcel from "issara-sdk/apis/DepositRecordReportToExcel_apps_BIL";
// USERS
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
// REG
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
// CORE
import EncounterList from "issara-sdk/apis/EncounterList_core";
import DoctorOrderPendingList from "issara-sdk/apis/DoctorOrderPendingList_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";

// Interface
import { HandlePrintReceipt } from "./BillPayment";
import { GetBillEncounterStatus } from "../BILInterface";

// Form
// #import FormCashierWork from "../FormCashierWork";
import FormCopyReceipt from "react-lib/apps/HISV3/BIL/FormCopyReceipt";

import moment from "moment";

// Utils
import { printURL, printBase64, b64toBlob } from "react-lib/utils/printURL";
import * as CAgent from "react-lib/apps/common/CAgent";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { base64toBlob, combineBase64Pdf } from "react-lib/apps/HISV3/common/CommonInterface";
import { formatDatetime } from "react-lib/utils/dateUtils";
import { downloadFile } from "react-lib/utils/utils";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  invoiceHistory?: any;
  buttonLoadCheck?: any;
  selectedEncounter?: any;
  selectedDivision?: any;
  selectedPatient?: any;
  masterOptions?: any;
  billReportPrint?: Record<string, any>;
  django?: any;
  bilReceiptCodeDetail?: {
    code: string;
    BIL_RECEIPT_SYSTEM_PREFIX: string;
    BIL_RECEIPT_PREFIX_SIZE: number;
    BIL_RECEIPT_RUNNING_SIZE: number;
  };
  // Sequence
  AssessmentSequence?: any;
  BillingHistorySequence?: Partial<{
    sequenceIndex?: string | null;
    receiptItems: Record<string, any>[];
    // check price for invoice
    receiptListData: Record<string, any>[];
    receiptDetail: {
      Mode: Record<string, any>[];
      Order: Record<string, any>[];
      Item: Record<string, any>[];
    };
    receiptData: Record<string, any>;
    receiptId: number;
    patient: any;
    permission: Record<string, any>;
    edcTransaction: any[];
    encounterList: any[];
    doctorOrder: any;
    reportSummary: any;
    bilStationLog: any[];
    trackingStatus: any;
    openCardPaying: any;
    cancelReceipt: any;
    cancelReceiptMessage: any;
    actionLogList: any[];
    creditNoteData: any;
    doctorName: any;
  }> | null;
};

type ReportTypesKeys = keyof typeof REPORT_TYPES;

type RTV = (typeof REPORT_TYPES)[ReportTypesKeys];

export const StateInitial: State = {
  BillingHistorySequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  divisionDict?: any;
  device?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

const INVOICE_ITEM_API = {
  Order: InvoiceItemByOrderList.get,
  Item: InvoiceItemList.list,
  Mode: InvoiceItemByModeList.get,
};

// ReferenceError: Cannot access 'StateInitial' before initialization
const REPORT_TYPES = {
  DRAWER_CASH: "รายงาน Drawer Cash",
  // DAILY_REVENUE: "รายงานรายได้ประจำวันรวม",
  DAILY_UNPAID: "รายงานค่ารักษาพยาบาลค้างชำระ",
  APPROVAL_CODE: "รายการรอปรับปรุง Approval Code",
  DRAWER_COVERAGE: "รายงาน Drawer Coverage",
  DAILY_CASH: "รายงานรายได้ประจำวันประเภทเงินสด",
  DAILY_APPROVED_UNPERFORMED: "รายงานผ่านสิทธิไม่รับบริการ",
  IPD_DISCHARGE: "รายงานผู้ป่วย Discharge",
  REMITTANCE: "รายงานใบนำส่งเงิน",
  DAILY_COVERAGE: "รายงานรายได้ประจำวันประเภทเงินเชื่อ",
  DAILY_APPROVED: "รายงานการผ่านสิทธิผ่านเครื่อง EDC",
  INSTALLMENT: "รายงานผ่อนชำระ",
  INCOME: "รายงานรายได้ที่บันทึกรับชำระเงินแล้ว",
  MEDICAL_FEE: "รายงานค่ารักษาพยาบาลแยกสิทธิ",
  CASHIER_WORK: "รายงานการปฏิบัติงานของเจ้าหน้าที่",
  COPY_RECEIPT: "รายงานการพิมพ์สำเนาใบเสร็จ",
} as const;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillingHistorySequence) return;

  // Master data
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["bilStation", {}],
        ["bilStationLog", {}],
        ["coverage", {}],
        ["cancel_receipt", {}],
        ["doctor", {}],
        ["prenameTh", {}],
        ["prenameEn", {}],
      ],
    },
  });

  controller.setState(
    {
      BillingHistorySequence: {
        sequenceIndex: "Action",
        doctorName: state.selectedEncounter?.doctor || "",
      },
    },
    () => {
      Action(controller, params);
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillingHistorySequence) return;

  if (params.action === "get_data") {
    HandleGetItemList(controller, params);
  } else if (params.action === "get_data_invoice") {
    HandleGetInvoice(controller, params);
  } else if (params.action === "get_receipt") {
    HandleGetReceipt(controller, params);
  } else if (params.action === "get_credit_note") {
    HandleGetCreditNote(controller, params);
  } else if (params.action === "print_receipt") {
    const report = await NewReportMedicalFeeDetailView.get({
      params: {
        receipt: params.receipt,
        patient_name_lang: "TH",
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: controller.data.device,
      },
    });

    controller.setState({
      billReportPrint: { open: true, task_id: report[0]?.task_id || "" },
    });
  } else if (params.action === "print_invoice") {
    const report = await NewReportMedicalFeeDetailView.get({
      params: {
        invoice: params.receipt,
        patient_name_lang: "TH",
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: controller.data.device,
      },
    });

    controller.setState({
      billReportPrint: { open: true, task_id: report[0]?.task_id || "" },
    });
  } else if (params.action === "confirm_receipt") {
    HandleGetEDCTransaction(controller, params);
  } else if (params.action === "cancel_receipt") {
    HandleCancelReceipt(controller, params);
  } else if (params.action === "cancel_credit_note") {
    HandleCancelCreditNote(controller, params);
  } else if (params.action === "cancel_invoice") {
    const cancelInvoice = await InvoiceDetail.delete({
      pk: params.receipt,
      apiToken: controller.apiToken,
    });

    Action(controller, {
      action: "get_data",
      from_date: params.from_date,
      to_date: params.to_date,
      division: params.division,
      status: params.status,
      type: params.type,
    });
  } else if (params.action === "get_encounters") {
    HandleGetEncounter(controller, params);
  } else if (params.action === "get_summary") {
    HandleGetSummary(controller, params);
  } else if (params.action === "print_summary") {
    HandlePrintSummary(controller, params);
  } else if (params.action === "get_bilStationLog") {
    const bilStationLog = await StationLogList.list({
      apiToken: controller.apiToken,
      params: {
        station: params.station,
        start_date: params.start_date,
        end_date: params.end_date,
      },
    });

    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        bilStationLog: bilStationLog[0]?.items || [],
      },
    });
  } else if (params.action === "get_bilUser") {
    const billingUserList = await BillingUserList.get({
      apiToken: controller.apiToken,
      params: {
        station: params.station,
        start_date: params.start_date,
        end_date: params.end_date,
      },
    });

    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        bilStationLog: billingUserList[0]?.items || [],
      },
    });
  } else if (params.action === "set_pdf_print") {
    if (params.pdf) {
      if (params.reportView.pdf_b64data) {
        const pdfWindow = window.open(
          "_blank",
          `data:application/pdf;base64,${params.reportView.pdf_b64data}`
        );
        pdfWindow?.document?.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
            params.reportView.pdf_b64data +
            "'></iframe>"
        );
      } else {
        // issue 67842
        const base64DataList = params.reportView.report
          .filter((item: any) => Object.keys(item).length > 0)
          .map((item: any) => item.pdf_b64data);
        const mergedBase64Data = await combineBase64Pdf(base64DataList);
        const pdfWindow = window.open("_blank", `data:application/pdf;base64,${mergedBase64Data}`);
        pdfWindow?.document?.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
            mergedBase64Data +
            "'></iframe>"
        );
      }
    } else {
      if (params.reportView.pdf_b64data) {
        printBase64(params.reportView.pdf_b64data);
      } else {
        // issue 67842
        const base64DataList = params.reportView.report
          ?.filter((item: any) => item.pdf_b64data)
          ?.map((item: any) => item.pdf_b64data);
        const mergedBase64Data = await combineBase64Pdf(base64DataList);
        printBase64(mergedBase64Data);
      }
    }
  } else if (params.action === "set_form_print") {
    const forms = {
      [REPORT_TYPES.COPY_RECEIPT]: {
        data: { ...params.reportView?.params, items: params.reportView?.fields },
        form: FormCopyReceipt,
      },
    };

    if (forms[params.reportType]) {
      const { data, form } = forms[params.reportType];

      const docDef = await form?.({ ...data });

      const pdfMake = await getPdfMake(true);

      const pdf = pdfMake.createPdf(docDef);

      if (params.pdf) {
        pdf.open();

        return;
      }

      const blob = await new Promise<Blob>((resolve) => {
        pdf.getBlob((blob) => {
          resolve(blob);
        });
      });

      printURL(URL.createObjectURL(blob));
    }
  } else if (params.action === "set_tracking_print") {
    HandleSetTrackingPrint(controller, params);
  } else if (params.action === "print_report") {
    HandlePrintReport(controller, params);
  } else if (params.action === "print_receipt_copy") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
      errorMessage: {
        ...state.errorMessage,
        [`${params.card}_${params.action}`]: null,
      },
    });

    const [doctorDetailRes, doctorDetailErr, doctorDetailNet] = await DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state?.BillingHistorySequence?.doctorName,
    });

    if (params.note === "") {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [`${params.card}_${params.action}`]: "กรุณาระบุเหตุผลการพิมพ์สำเนาใบเสร็จ",
        },
      });
    } else {
      const prenameTh =
        (state.masterOptions?.prenameTh || []).find(
          (item: any) => item.value === doctorDetailRes?.pre_name
        )?.text || null;

      const prenameEn =
        (state.masterOptions?.prenameEn || []).find(
          (item: any) => item.value === doctorDetailRes?.pre_name_en
        )?.text || null;

      let doctorName =
        doctorDetailRes?.pre_name_en &&
        doctorDetailRes?.first_name_en &&
        doctorDetailRes?.last_name_en
          ? `${doctorDetailRes?.full_name}  (${prenameEn} ${doctorDetailRes?.first_name_en} ${doctorDetailRes?.last_name_en})`
          : doctorDetailRes?.full_name;

      await HandlePrintReceipt(controller as any, {
        id: params.receipt,
        note: params.note,
        isWatermark: true,
        doctorName: doctorName,
      });

      params.callback?.(false, "");

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
    }
  } else if (params.action === "confirm_void") {
    HandleConfirmVoid(controller, params);
  } else if (params.action === "confirm_reprint") {
    HandleConfirmReprint(controller, params);
  } else if (params.action === "get_action_log") {
    HandleGetActionLog(controller, params);
  } else if (params.action === "clearTrackingStatus") {
    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        trackingStatus: null,
      },
    });
  } else if (params.action === "dowload_xlsx") {
    HandleDownLoadXLSX(controller, params);
  }
};

/* ------------------------------------------------------ */

/*                         ACTION                         */

/* ------------------------------------------------------ */
const HandleGetItemList: Handler = async (controller, params) => {
  const state = controller.getState();
  const btnKey = `${params.card}_search`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const permission = await UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_BIL_CAN_PRINT_RECEIPT_COPY: false,
    },
  });

  const api = {
    receipt: ReceiptList,
    invoice: InvoiceList,
    credit_note: CreditNoteList,
  }[params.type as "receipt" | "invoice" | "credit_note"];

  const data = await api?.list({
    params: {
      from_date: params.from_date,
      to_date: params.to_date,
      division: params.division,
      status: params.status,
      patient: state.selectedPatient?.id,
      exclude_pkg: true,
    },
    apiToken: controller.apiToken,
  });

  let receiptListInvoice: any = [];
  if (params.type === "invoice") {
    const data = await ReceiptList?.list({
      params: {
        from_date: params.from_date,
        to_date: params.to_date,
        division: params.division,
        status: params.status,
        patient: state.selectedPatient?.id,
      },
      apiToken: controller.apiToken,
    });

    receiptListInvoice = data?.[0]?.items;
  }

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      receiptItems: data?.[0],
      receiptListData: params.type === "invoice" ? receiptListInvoice : data?.[0]?.items,
      openCardPaying: false,
      permission: permission?.[0],
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
  });
};

const HandleGetInvoice: Handler = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_search`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const data = await InvoiceList.list({
    params: {
      from_date: params.from_date,
      to_date: params.to_date,
      division: params.division,
      status: params.status,
      patient: state.selectedPatient?.id,
    },
    apiToken: controller.apiToken,
  });

  const [patient, ...receipt] = await Promise.all([
    PatientDetailView.retrieve({
      pk: data[0]?.items[0]?.patient_id,
      apiToken: controller.apiToken,
    }),
    ...Object.entries(INVOICE_ITEM_API).map(([key, api]) =>
      api({
        params: {
          invoice: data[0]?.items[0]?.id,
        },
        apiToken: controller.apiToken,
      })
    ),
  ]);

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      receiptItems: data[0],
      receiptDetail: {
        Order: receipt[0][0]?.items || [],
        Item: receipt[1][0]?.items || [],
        Mode: receipt[2][0]?.items || [],
      },
      patient: patient[0],
      openCardPaying: true,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
  });
};

const HandleGetReceipt: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      receiptId: params.receipt,
    },
  });

  // const data = await ReCreateClaimCodeNHSOCheckView.get({
  //   params: {
  //     receipt: params.receipt,
  //   },
  //   apiToken: controller.apiToken,
  // });
  const billParams =
    params.showBillType === "receipt"
      ? {
          receipt: params.receipt,
        }
      : params.showBillType === "invoice"
      ? {
          invoice: params.receipt,
        }
      : {};

  const [patient, detail, ...receipt] = await Promise.all([
    PatientDetailView.retrieve({
      pk: params.patient_id,
      apiToken: controller.apiToken,
    }),
    ReceiptDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.receipt,
    }),
    ...Object.entries(INVOICE_ITEM_API).map(([key, api]) =>
      api({
        params: billParams,
        apiToken: controller.apiToken,
      })
    ),
  ]);

  let receiptData: any = [];
  let receiptEncounterData: any = {};
  if (params.showBillType === "invoice") {
    let receipt = (state.BillingHistorySequence?.receiptListData || []).find(
      (item: any) => item?.invoice === params?.receipt
    );

    receiptData = receipt;

    receiptEncounterData = {};
    if (params.selectedReceipt?.type_name === "FULL_PAYMENT") {
      const [encounterRes, encounterErr, encounterNet] = await EncounterDetail.retrieve({
        pk: params.selectedReceipt?.encounter,
        apiToken: controller.apiToken,
      });

      if (encounterRes) {
        receiptEncounterData = {
          ...encounterRes,
          checkIn: formatDatetime(encounterRes?.encounter_checkin_time, true) || "-",
          checkOut: formatDatetime(encounterRes?.encounter_checkout_time, true) || "-",
        };
      }
    }
  }

  // #const byItems: Record<string, any>[] = receipt[1][0]?.items || [];
  // const encounterDoctor = byItems[0].encounter_doctor;
  // const episodeDoctor = byItems.find((item) => item.episode_doctor)?.episode_doctor;
  // const doctorID = episodeDoctor || encounterDoctor || "";

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      receiptDetail: {
        Order: receipt[0][0]?.items || [],
        Item: receipt[1][0]?.items || [],
        Mode: receipt[2][0]?.items || [],
      },
      receiptData: params.showBillType === "invoice" ? receiptData : detail[0],
      patient: patient[0],
      ...(receiptEncounterData && {
        receiptEncounterData: receiptEncounterData,
      }),
      doctorName: params.showBillType === "receipt" ? detail[0]?.doctor : null,
    },
  });
};

const HandleGetCreditNote: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      receiptId: params.receipt,
    },
  });

  const [creditNoteRes, creditNoteErr, creditNoteNet] = await CreditNoteDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.receipt,
  });

  if (creditNoteRes) {
    let editDate = moment(creditNoteRes?.edited).format("DD/MM/YYYY");
    let editTime = moment(creditNoteRes?.edited).format("HH:mm");

    let changeDate = editDate.split("/");

    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        creditNoteData: {
          ...creditNoteRes,
          editedText: `${changeDate?.[0]}/${changeDate?.[1]}/${
            Number(changeDate?.[2]) + 543
          } ${editTime}`,
        },
      },
    });
  }
};

const HandleCancelReceipt: Handler = async (controller, params) => {
  const state = controller.getState();

  const cancelReceipt = await ReceiptDetail.delete({
    pk: params?.receipt,
    data: {
      username: params?.username,
      password: params?.password,
      note: params?.note,
    },
    apiToken: controller.apiToken,
  });

  if (cancelReceipt?.[1]) {
    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        cancelReceipt: "error",
        cancelReceiptMessage: cancelReceipt?.[1],
      },
    });
  } else {
    const [encounterLocker, patientHasDebt] = await GetBillEncounterStatus(controller as any);

    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        cancelReceipt: "success",
      },
      AssessmentSequence: {
        ...state.AssessmentSequence,
        encounterLocker: encounterLocker[1],
        patientHasDebtView: patientHasDebt[0],
      },
    });

    Action(controller, {
      action: "get_data",
      from_date: params.from_date,
      to_date: params.to_date,
      division: params.division,
      status: params.status,
      type: params.type,
    });

    params.onSuccess?.();
  }
};

const HandleCancelCreditNote: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      receiptId: params.receipt,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.card]: "LOADING",
    },
  });

  const [creditNoteRes, creditNoteErr, creditNoteNet] = await CreditNoteDetail.delete({
    apiToken: controller.apiToken,
    pk: params.receipt,
    data: {
      code: state.BillingHistorySequence?.creditNoteData?.code,
      patient: state.BillingHistorySequence?.creditNoteData?.patient,
      status_name: state.BillingHistorySequence?.creditNoteData?.status_name,
      status_label: state.BillingHistorySequence?.creditNoteData?.status_label,
      credit_note_items: state.BillingHistorySequence?.creditNoteData?.credit_note_items,
      price: state.BillingHistorySequence?.creditNoteData?.price,
      created: state.BillingHistorySequence?.creditNoteData?.created,
      note: params?.note,
      receipt_code: state.bilReceiptCodeDetail?.code || "",
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (creditNoteErr) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: creditNoteErr,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card]: "ERROR",
      },
    });
  } else {
    controller.setState({
      successMessage: {
        ...state.successMessage,
        [params.card]: creditNoteRes,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card]: "SUCCESS",
      },
    });

    Action(controller, {
      action: "get_data",
      from_date: params.from_date,
      to_date: params.to_date,
      division: params.division,
      status: params.status,
      type: params.type,
    });
  }
};

const HandleGetEncounter: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      encounterList: [],
      doctorOrder: [],
    },
  });

  const encounterList = await EncounterList.list({
    params: {
      patient: state.selectedPatient?.id,
      unexpired_only: true,
    },
    apiToken: controller.apiToken,
  });

  const doctorOrder = await DoctorOrderPendingList.list({
    params: {
      patient: state.selectedPatient?.id,
      exclude_no_payment_order: true,
      offset: 0,
      limit: 40,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      encounterList: encounterList[0],
      doctorOrder: doctorOrder[0],
    },
  });
};

const HandleGetSummary: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const summaryList = await ReportStationSummaryView.retrieve({
    apiToken: controller.apiToken,
    // pk: state.selectedDivision.id,
    pk: controller.data.device,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (summaryList[0]) {
    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        reportSummary: summaryList[0],
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
    });
  }
};

const HandlePrintSummary: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const summaryList = await ReportStationSummaryView.update({
    apiToken: controller.apiToken,
    // pk: state.selectedDivision.id,
    pk: controller.data.device,
    data: {
      receipts: state.BillingHistorySequence?.reportSummary?.receipts,
      will_close: params.will_close,
      will_print: params.will_print,
      pdf: true,
    } as any,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (summaryList[0]) {
    let remainError = null;

    if (state.errorMessage) {
      let { [params.buttonLoadKey]: e, ...restError } = state.errorMessage;
      remainError = restError;
      controller.setState({ errorMessage: restError });
    }

    controller.setState(
      {
        // BillingHistorySequence: {
        //   ...state.BillingHistorySequence,
        //   reportSummary: summaryList[0],
        // },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        successMessage: {
          ...state.successMessage,
          [params.buttonLoadKey]: summaryList[0],
        },
        ...(remainError && { errorMessage: { ...remainError } }),
      },
      async () => {
        await Action(controller, { action: "get_summary" });
      }
    );
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.buttonLoadKey]: summaryList[1],
      },
    });
  }
};

const HandleSetTrackingPrint: Handler = async (controller, params) => {
  const state = controller.getState();

  const reportView = await ReportPrintTrackingResult.retrieve({
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
    params: {
      task_id: params.task_id,
      action: params.status,
    },
  });

  if (reportView[0]?.pdf_b64data) {
    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        trackingStatus: { ...reportView[0], pdf: params.pdf },
      },
    });

    Action(controller, {
      action: "set_pdf_print",
      pdf: params.pdf,
      reportView: reportView[0],
    });
  } else if (reportView[2]?.stack) {
    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        trackingStatus: { ...reportView[2], status: "ERROR", pdf: params.pdf },
      },
    });
  } else {
    controller.setState({
      BillingHistorySequence: {
        ...state.BillingHistorySequence,
        trackingStatus: { ...reportView[0], pdf: params.pdf },
      },
    });
  }
};

const HandlePrintReport: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
    errorMessage: {
      ...state.errorMessage,
      [`${params.card}_${params.action}`]: null,
    },
  });

  const type = params.type as RTV;

  const api = {
    [REPORT_TYPES.DRAWER_COVERAGE]: ReportDrawerCoverageView.list,
    [REPORT_TYPES.DRAWER_CASH]: ReportDrawerCashView.list,
    [REPORT_TYPES.REMITTANCE]: ReportDrawerCashView.list,
    // [REPORT_TYPES.DAILY_REVENUE]: ReportDailyRevenueView.get,
    [REPORT_TYPES.DAILY_CASH]: ReportDailyCashView.get,
    [REPORT_TYPES.DAILY_COVERAGE]: ReportDailyCoverageView.get,
    [REPORT_TYPES.DAILY_UNPAID]: ReportDailyUnpaidView.get,
    [REPORT_TYPES.DAILY_APPROVED_UNPERFORMED]: ReportDailyApprovedUnperformedView.get,
    [REPORT_TYPES.DAILY_APPROVED]: ReportDailyApprovedView.get,
    [REPORT_TYPES.APPROVAL_CODE]: ReportApprovalCodeView.get,
    [REPORT_TYPES.IPD_DISCHARGE]: ReportIpdDischargeView.retrieve,
    [REPORT_TYPES.INSTALLMENT]: ReportInstallmentView.get,
    [REPORT_TYPES.INCOME]: ReportDailyRevenueView.get,
    [REPORT_TYPES.MEDICAL_FEE]: ReportDrawerCoverageView.list,
    [REPORT_TYPES.CASHIER_WORK]: ReportCashierWorkingView.list,
    [REPORT_TYPES.COPY_RECEIPT]: ReportPrintingCopyReceiptView.get,
  }[type];

  const reportView = await api?.({
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
    params: {
      ...((
        [
          REPORT_TYPES.DRAWER_COVERAGE,
          REPORT_TYPES.DRAWER_CASH,
          REPORT_TYPES.MEDICAL_FEE,
          REPORT_TYPES.REMITTANCE,
          REPORT_TYPES.CASHIER_WORK,
        ] as RTV[]
      ).includes(type)
        ? { receipt_date: params.start_date }
        : type === REPORT_TYPES.IPD_DISCHARGE
        ? { date: params.start_date }
        : { start_date: params.start_date }),
      report_version: ([REPORT_TYPES.DRAWER_COVERAGE, REPORT_TYPES.DRAWER_CASH] as RTV[]).includes(
        type
      )
        ? "V1"
        : ([REPORT_TYPES.MEDICAL_FEE, REPORT_TYPES.REMITTANCE] as RTV[]).includes(type)
        ? "V2"
        : undefined,
      export_type: ([REPORT_TYPES.INCOME] as RTV[]).includes(type) ? params.typeReport : undefined,
      station: params.station,
      station_log: params.station_log,
      pdf: true,
      // ---
      end_date: params.end_date,
      coverage: params.coverage,
      payer: params.payer?.id,
      discharge_only: params.discharge_only,
      patient_type: params.patient_type,
      // ---
      receipt_status: params.receipt_status,
      user: params.user,
      close_shift_report: ([REPORT_TYPES.DRAWER_CASH, REPORT_TYPES.REMITTANCE] as RTV[]).includes(
        type
      )
        ? true
        : false, // issue 67842
    },
  });

  if (reportView?.[0]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    });

    if (type === REPORT_TYPES.IPD_DISCHARGE && !reportView?.[0].pdf_b64data) {
      console.warn(" REPORT_TYPES.IPD_DISCHARGE or no pdf_b64data");
      return controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [`${params.card}_${params.action}`]: "ไม่พบข้อมูลในระบบ",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    }

    // print PDF
    Action(controller, {
      action: (
        [
          // REPORT_TYPES.DAILY_REVENUE,
          REPORT_TYPES.DAILY_CASH,
          REPORT_TYPES.DAILY_COVERAGE,
          REPORT_TYPES.INCOME,
        ] as RTV[]
      ).includes(type)
        ? "set_tracking_print"
        : [REPORT_TYPES.COPY_RECEIPT].includes(type)
        ? "set_form_print"
        : "set_pdf_print",
      pdf: params.pdf,
      reportView: reportView[0] || {},
      reportType: REPORT_TYPES.COPY_RECEIPT,
      task_id: reportView[0]?.task_id,
    });
  } else {
    alert(`Error: ` + reportView?.[1]); // issue 67756
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
    });
  }
};

// #const HandlePrintCashierWork: Handler = async (controller, params) => {
//   const state = controller.getState();
//   let docDef: any = { content: [] };
//   const data = {
//     date: params.start_date,
//     user: "bill",
//     staff: state.django.user.full_name,
//     items: [
//       {
//         receipt_code: "100-667",
//         station_name: "001:BIL",
//         coverage: "เงินสด",
//         price_total: "1,580.00",
//         credit: "",
//         cash: "",
//         transfer: "1,580.00",
//         credit_card: "",
//         cheque: "",
//         installment: "",
//         cancel_payment: "",
//       },
//       {
//         receipt_code: "100-668",
//         station_name: "001:BIL",
//         coverage: "เงินสด",
//         price_total: "950.00",
//         credit: "",
//         cash: "",
//         transfer: "",
//         credit_card: "950.00",
//         cheque: "",
//         installment: "",
//         cancel_payment: "",
//       },
//       {
//         receipt_code: "100-669 (ยกเลิก)",
//         station_name: "001:BIL",
//         coverage: "เงินสด",
//         price_total: "3,250.00",
//         credit: "",
//         cash: "1,000.00",
//         transfer: "",
//         credit_card: "2,250.0",
//         cheque: "",
//         installment: "",
//         cancel_payment: "3,250.00",
//       },
//       {
//         receipt_code: "COV0010000001",
//         station_name: "02:BIL_TEST",
//         coverage: "ข้าราชการ",
//         price_total: "1,250.00",
//         credit: "1,070.00",
//         cash: "",
//         transfer: "180.00",
//         credit_card: "",
//         cheque: "",
//         installment: "",
//         cancel_payment: "",
//       },
//       {
//         receipt_code: "COV0010000002",
//         station_name: "02:BIL_TEST",
//         coverage: "ข้าราชการ",
//         price_total: "150.00",
//         credit: "1,070.00",
//         cash: "",
//         transfer: "",
//         credit_card: "",
//         cheque: "",
//         installment: "",
//         cancel_payment: "",
//       },
//     ],
//   };
//   docDef = await FormCashierWork({ ...data });
//   (await getPdfMake()).createPdf(docDef).open();
// };

const HandleConfirmVoid: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  if (params.type === "VOID") {
    if (!params.useEDC) {
      UpdateEDCTransactionDetail(controller, {
        ...params,
        approval_code: params.data.approval_code_edited,
        pk: params.data.id,
        action: params.type,
      });
    } else {
      HandleKTBEDCVoid(controller, params);
    }
  } else if (params.type === "UNVOID") {
    UpdateEDCTransactionDetail(controller, {
      ...params,
      approval_code: params.data.approval_code_edited,
      pk: params.data.id,
      action: params.type,
    });
  } else if (params.type === "SAVE") {
    if (!params.useEDC) {
      CreateEDCTransactionDetail(controller, {
        ...params,
        data: {
          receipt: params.receipt,
          total_price: params.data.total_price,
          invoice_items: params.data.invoice_items_pending,
          effective_date: params.data.effective_date,
          reference_code: params.data.reference_code_edited,
          approval_code: params.data.approval_code_edited,
        },
      });
    } else {
      HandleKBEDCApprove(controller, params);
    }
  }
};

const HandleConfirmReprint: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  CAgent.ktbedcReprint(params.data.reference_code)
    .then(function (result) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
    })
    .catch(function (result) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.errorKey]: result,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    });
};

const HandleGetEDCTransaction: Handler = async (controller, params) => {
  const state = controller.getState();

  const confirmReceipt = await EDCTransactionList.list({
    params: {
      receipt: params.receipt,
    },
    apiToken: controller.apiToken,
  });

  const items = (confirmReceipt[0]?.items || []).map((item: any) => ({
    ...item,
    reference_code_edited: item.reference_code,
    approval_code_edited: item.approval_code,
  }));

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      edcTransaction: items,
    },
  });
};

const HandleKTBEDCVoid: Handler = (controller, params) => {
  const state = controller.getState();

  CAgent.ktbedcVoid(params.data.reference_code)
    .then(async (result: any) => {
      UpdateEDCTransactionDetail(controller, {
        ...params,
        approval_code: result.approvalCode,
        pk: params.data.id,
      });
    })
    .catch(function (result) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.errorKey]: result,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.btnAction}`]: "ERROR",
        },
      });
    });
};

const HandleKBEDCApprove: Handler = (controller, params) => {
  const state = controller.getState();
  const patient = state.selectedPatient || {};
  var personal_id = (patient.citizen_no || patient.reimburse_foreign_code).replace(/\D/g, "");
  CAgent.ktbedcApprove(personal_id, params.data.total_price, "SELF")
    .then(function (result) {
      CreateEDCTransactionDetail(controller, {
        ...params,
        data: {
          receipt: params.receipt,
          total_price: params.data.total_price,
          invoice_items: params.data.invoice_items_pending,
          reference_code: result.traceId || "",
          approval_code: result.approvalCode || "",
          terminal_id: result.terminalId || "",
          merchant_id: result.merchantId || "",
          effective_date: moment(result.date + result.time, "YYMMDDHHmmss"),
        },
      });
    })
    .catch(function (result) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.errorKey]: result,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.btnAction}`]: "ERROR",
        },
      });
    });
};

const HandleGetActionLog: Handler = async (controller, params) => {
  const state = controller.getState();

  const result = await EDCTransactionLogDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.pk,
    params: {
      limit: 40,
    },
  });

  controller.setState({
    BillingHistorySequence: {
      ...state.BillingHistorySequence,
      actionLogList: result[0]?.items || [],
    },
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const UpdateEDCTransactionDetail: Handler = async (controller, params) => {
  const state = controller.getState();

  const edc = await EDCTransactionDetail.update({
    apiToken: controller.apiToken,
    pk: params.pk,
    data: {
      action: params.action,
      approval_code: params.approval_code || "",
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (edc[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: edc[1],
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "SUCCESS",
      },
    });

    HandleGetEDCTransaction(controller, params);

    params.onSuccess?.();
  }
};

const CreateEDCTransactionDetail: Handler = async (controller, params) => {
  const state = controller.getState();

  const edc = await EDCTransactionList.create({
    apiToken: controller.apiToken,
    data: {
      action: "CREATE",
      ...params.data,
    } as any,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (edc[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: edc[1],
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "SUCCESS",
      },
    });

    HandleGetEDCTransaction(controller, params);

    params.onSuccess?.();
  }
};

const HandleDownLoadXLSX: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.buttonLoadKey}`]: "LOADING",
    },
  });

  const [depositRecordReportRes, depositRecordReportErr, depositRecordReportNet] =
    await DepositRecordReportToExcel.get({
      apiToken: controller.apiToken,
      params: {
        start_date: params.start_date,
        end_date: params.end_date,
        hn: params.hn,
        station: params.station,
        create_user: params.user,
      },
      extra: { responseType: "arraybuffer" },
    });

  if (depositRecordReportErr) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.buttonLoadKey}`]: "ERROR",
      },
    });
  } else {
    downloadFile(depositRecordReportNet);

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.buttonLoadKey}`]: "SUCCESS",
      },
    });
  }
};
