import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  KeyboardEvent,
  CSSProperties,
  ReactElement,
  useImperativeHandle,
  useCallback,
  MutableRefObject,
} from "react";
import {
  Button,
  Form,
  Icon,
  Image,
  Input,
  InputProps,
  Modal,
  Radio,
  RadioProps,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm"
import ModInfo from "react-lib/apps/common/ModInfo"

import ChatBox from "./ChatBox";
import { useIntl } from "react-intl";

type StaffChatProps = {
  // data
  userId: string;
  userName: string;
  userFirstName: string;
  userLastName: string;
  apiToken: string;
  divisionId: string;
  chatRoomList?: any[];
  searchUserList?: any[];
  userChatSubscriptionList?: any[];
  searchMessageList?: {
    current_offset: number;
    current_limit: number;
    count: number;
    next: any;
    previous: any;
    items: any[];
  };
  modOpenLinkEncounter?: string;
  // callback
  chatController: any;
  onEvent: any;
  onReadAllMessages?: any;
  onCloseModLinkEncounter?: () => any;
  onDidMount?: () => any;
  onOpenVideoCall?: (url:string, chat_channel_id:string) => any;
};

const COLORS = {
  chat_light: "var(--primary-theme-chat-light)",
  grey: "rgb(204, 204, 204)",
  grey_half: "#CECDCD",
  dark_grey: "#746A6A",
  light_grey: "#D9D9D9",
  divider: "rgba(228, 218, 218, 0.5)",
};

const IMAGES = {
  pencil_edit: "/static/images/pencil-edit-icon.png",
  pencil_cog: "/static/images/pencil-cog-icon.png",
  leave: "/static/images/leave-icon.png",
};

const styles = {
  no_chat: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: "rgb(158 157 157)",
  } as CSSProperties,
  item: {
    padding: "15px 10px",
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  } as CSSProperties,
  message_search: {
    position: "absolute",
    width: "100%",
    background: "white",
    zIndex: 1,
    left: 0,
    overflow: "auto",
    // top: "200px"
  } as CSSProperties,
};

/* ------------------------------------------------------ */
/// StaffChat
/* ------------------------------------------------------ */

const StaffChat = React.forwardRef((props: StaffChatProps, ref) => {
  // mod create class room
  const [openModal, setOpenModal] = useState<{
    id?: number;
    open?: true;
    chat_channel_id?: number;
    name?: string;
    chat_channel_name_autogen?: string;
    members?: any[];
  } | null>(null);
  const [openModLeave, setOpenModLeave] = useState<boolean>(false);

  const [toggleSearch, setToggleSearch] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState<any>(null);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [chatRoomList, setChatRoomList] = useState<any[]>([]);
  const [searchIndex, setSearchIndex] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");

  const chatBoxRef = useRef<any>();
  const isFinish = useRef<boolean>(false);
  const isSearch = useRef<boolean>(false);

  useImperativeHandle(ref, () => ({
    chatBoxRef: chatBoxRef.current,

    getChatChannelList: (params: any) => {
      // console.log(" getChatChannelList !!! ")
      isFinish.current = true;
      handleGetChatChannelList(params);
    },
  }));

  useEffect(() => {
    props.onDidMount?.()
  }, [])

  useEffect(() => {
    handleGetChatChannelList();
  }, []);

  useEffect(() => {
    let list: any[] = [...(props.chatRoomList || [])];
    const index = list.findIndex((item) => item.id === selectedChat?.id);

    if (index !== -1) {
      list[index].unread_message_count = 0;
    }

    list = list.filter((item) => item.division == null)

    setChatRoomList(list);
  }, [props.chatRoomList]);

  useEffect(() => {
    const checkReadMessageCount = () => {
      const sum = chatRoomList.reduce(
        (result, item) => (result += item.unread_message_count),
        0
      );

      if (!sum && !!chatRoomList.length) {
        props.onReadAllMessages?.();
      }
    };

    checkReadMessageCount();
  }, [chatRoomList]);

  useEffect(() => {
    if (!chatRoomList?.find((item) => item.id === selectedChat?.id)) {
      setSelectedChat(null);
      handleClose();
    } else if (isFinish.current && !!selectedChat) {
      const list: any[] = props.chatRoomList || [];
      const chat = list.find((item) => item.id === selectedChat?.id);
      const modal = list.find((item) => item.id === openModal?.id);

      isFinish.current = false;

      if (!!modal) {
        handleOpenModal(modal);
      }
      setSelectedChat(chat || null);
    }
  }, [props.chatRoomList]);

  const searchMessage = useMemo(() => {
    const item = props.searchMessageList?.items?.[searchIndex];
    return item && { id: item.id, offset: item.offset, index: searchIndex };
  }, [searchIndex, props.searchMessageList?.items]);

  /* ------------------------- API ------------------------ */
  const handleGetChatChannelList = (params = {}) => {
    // console.log(" handleGetChatChannelList params: ", params)
    props.onEvent({
      message: "GetChatChannelList",
      params: {
        isPatient: false,
        user: props.userId,
        channelId: "all",
        ...(isSearch.current && { search: inputSearch }),
        ...params,
        chatRoomId: selectedChat?.id,
      },
    });
  };

  const handleGetSearchUserList = useCallback(
    (search: string, selected: any[]) => {
      props.onEvent({
        message: "GetSearchUserList",
        params: { search, is_set_state: true, selected: selected },
      });
    },
    []
  );

  const handleGetUserChatSubscription = useCallback((params: any) => {
    props.onEvent({
      message: "GetUserChatSubscriptionList",
      params,
    });
  }, []);

  const handleCreateUpdateChatRoom = (data: any) => {
    return props.onEvent({
      message: "CreateUpdateChatRoom",
      params: data,
    });
  };

  const handleGetSearchMessageList = (params: any) => {
    props.onEvent({
      message: "GetSearchMessageList",
      params: params,
    });
  };

  /* ------------------------- API ------------------------ */

  // Delete
  const handleDeleteMember = useCallback(
    (params: { ids: number[]; name?: string; isChangeName?: boolean }) => {
      return handleCreateUpdateChatRoom({
        chatChannelId: selectedChat?.chat_channel_id,
        type: "delete",
        userIds: params.ids,
        groupName: params.name,
        isChangeName: params.isChangeName,
      });
    },
    [selectedChat]
  );

  const handleFinish = useCallback(
    async (data: any) => {
      const userIds = [...data.memberIds, Number(props.userId)].sort(
        (a: any, b: any) => a - b
      );
      const findChat = chatRoomList.find((item) => {
        const memberIds: any[] = item.members
          .map((acc: any) => acc.user_obj.id)
          .sort((a: any, b: any) => a - b);
        return userIds.toString() === memberIds.toString();
      });

      let result: any[] = [null, null, null];

      if (!!findChat && !data.chatChannelId) {
        setSelectedChat(findChat);

        // await handleCreateUpdateChatRoom({
        //   ...data,
        //   type: "change_name",
        // });
      } else {
        result = await handleCreateUpdateChatRoom({
          ...data,
          type: "create",
          notChangeName:
            data.groupName === openModal?.name && data.chatChannelId,
        });

        if (!!selectedChat) {
          chatBoxRef.current?.receivedMessage?.({
            chatChannelId: selectedChat?.chat_channel_id,
          });
          isFinish.current = true;
        }

        await handleGetChatChannelList({
          search: inputSearch,
          channelId: openModal?.chat_channel_id || result[0]?.channel,
        });
      }

      handleClose();
    },
    [props.userId, inputSearch, openModal, selectedChat, chatRoomList]
  );

  // Open Modal
  const handleOpenModal = (params: any = {}) => {
    setOpenModal({ open: true, ...params });
  };

  const handleClose = () => {
    setOpenModal(null);
  };
  // Toggle Search
  const handleToggleSearch = () => {
    setToggleSearch(!toggleSearch);
    handleGetSearchMessageList({ clear: true });
    handleClearSearch();
  };

  const handleCloseSearch = () => {
    setToggleSearch(false);
    handleClearSearch();
    handleGetSearchMessageList({ clear: true });
  };

  const handleClearSearch = () => {
    setSearchText("");
    setSearchIndex(0);
  };

  const handleSearch = (value: string) => {
    handleGetSearchMessageList({
      search: value,
      chatChannelId: selectedChat?.chat_channel_id,
      clear: !value,
      last: true,
    });

    setSearchText(value);
    setSearchIndex(0);
  };

  const handleNextSearch = (value: string) => {
    handleGetSearchMessageList({
      search: value,
      chatChannelId: selectedChat?.chat_channel_id,
      previous: props.searchMessageList?.previous,
      concat: true,
    });
  };

  // Chat
  const handleSelectedChat = (item: any) => (e: any) => {
    setSelectedChat(null);
    handleCloseSearch();
    handleClearSearch();

    if (item.unread_message_count) {
      const message = {
        event: "message_read",
        data_message_type: "MESSAGE_READ",
        message_id: item.latest_message,
        channel_id: item.chat_channel_id,
      };

      // ส่งข้อความกลับไปยังแท็บเดิม
      window.opener?.postMessage(message, window.location.origin);
    }

    setTimeout(() => setSelectedChat(item));
  };

  // Leave
  const handleOpenModLeave = () => {
    setOpenModLeave(true);
  };

  const handleCloseModLeave = () => {
    setOpenModLeave(false);
  };

  const handleConfirmLeave = async () => {
    // let members: any[] = [...(selectedChat?.members || [])].map((item) => ({
    //   ...item,
    //   ...item.user_obj,
    // }));
    // const name: string = selectedChat?.name || "";
    // let params = { ...props };

    // const groupName = getChangeName(
    //   { ...params, members: members },
    //   members,
    //   name,
    //   props.userId
    // );

    await handleCreateUpdateChatRoom({
      userChatSubscriptionId: selectedChat?.user_chat_subscription_id,
      groupName: "",
      chatChannelId: selectedChat?.chat_channel_id,
      type: "leave",
      isChangeName:
        !!selectedChat.name &&
        selectedChat?.member_count - 1 === 1,
    });

    handleCloseModLeave();
    setSelectedChat(null);

    await handleGetChatChannelList();
  };

  // Search
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleGetChatChannelList({ search: (e.target as any).value });
      isSearch.current = true;
      isFinish.current = true;
    }
  };

  const handleChangeInput = (e: any, v: InputProps) => {
    isSearch.current = false;
    setInputSearch(v.value);
  };

  // Badge
  const handleUnreadMessage = (action: "add" | "reset", chatId: number) => {
    const update: any[] = chatRoomList.map((item) =>
      item.chat_channel_id === chatId
        ? {
            ...item,
            unread_message_count:
              action === "add" ? item.unread_message_count + 1 : 0,
          }
        : item
    );
    setChatRoomList(update);
  };

  const handleReadMessage = () => {
    handleUnreadMessage("reset", selectedChat?.chat_channel_id);
  };

  // Message list
  const handleSelectMessage = (item: any, index: number) => {
    setSearchIndex(index);
  };

  const handlePostMessage = () => {
    if (searchText) {
      handleCloseSearch();
    }
  };

  const handleClickLink = (e: MouseEvent) => {
    const a = e.target as HTMLAnchorElement;
    const href = a.href || "";
    const params = new URLSearchParams(href.match(/(?!=\/)\?.*$/g)?.[0] || "");
    const encounterId = params.get("encounterId");
    const app = params.get("app");

    if (encounterId && app === "MainDPO") {
      if (e.type === "mousedown") {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      // insert loader
      // const loader = a.querySelector(".ui.mini.loader")
      // a.insertAdjacentHTML("afterbegin", `<span class="ui mini loader" style="left: 0.8rem;"></span>`);

      props.onEvent({
        message: "HandleOpenLinkEncounter",
        params: { type: e.type, event: e, encounterId, href },
      });
    } else {
      const clickLeft = e?.button === 0;

      if (clickLeft && href) {
        window.open(href, "_blank", "noopener noreferrer")?.focus();
      }
    }
  };

  console.log("StaffChat", props);

  return (
    <div className="staff-chat" style={{ height: "95vh" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "22.5% 1fr",
          height: "100%",
        }}
      >
        {/* ChatList */}
        <div style={{ borderRight: `1px solid ${COLORS.grey}` }}>
          {/* Filter */}
          <Form style={{ padding: "10px 0px 5px 10px" }}>
            <Form.Group inline>
              <Form.Field width={10}>
                <Input
                  value={inputSearch}
                  action={{
                    icon: "search",
                    onClick: () =>
                      handleKeyPress({
                        key: "Enter",
                        target: { value: inputSearch },
                      }),
                    // handleGetChatChannelList({ search: inputSearch }),
                  }}
                  placeholder="Search..."
                  onKeyPress={handleKeyPress}
                  onChange={handleChangeInput}
                />
              </Form.Field>

              <Form.Field width={6}>
                <Button
                  style={{
                    backgroundColor: COLORS.chat_light,
                    padding: ".78rem 0.5rem .78rem",
                  }}
                  fluid
                  onClick={() => handleOpenModal()}
                >
                  <Icon name="facebook messenger" />
                  New Chat
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>

          {/* Chat */}
          <div style={{ height: "88vh", overflow: "auto" }}>
            {chatRoomList.map((item, index) => (
              <div
                key={`chat-list-${index}`}
                style={{
                  ...styles.item,
                  backgroundColor:
                    selectedChat?.id === item.id && selectedChat?.id
                      ? "rgb(214, 236, 243)"
                      : "",
                }}
                onClick={handleSelectedChat(item)}
              >
                <div style={{ width: "85%" }}>
                  <DisplayGroupName
                    // members={item.members}
                    name={item.name}
                    nameAutoGen={item.chat_channel_name_autogen}
                    memberCount={item.member_count}
                    // userId={props.userId}
                    // userName={props.userName}
                    // userLastName={props.userLastName}
                    // splitter={true}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  />
                </div>

                {!!item.unread_message_count && (
                  <div
                    style={{
                      backgroundColor: "#D71E1E",
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* ChatBox */}
        <div>
          {selectedChat ? (
            <>
              <div style={{ borderBottom: `1px solid ${COLORS.grey}` }}>
                <div
                  style={{
                    padding: "15px 10px 20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DisplayGroupName
                      // members={selectedChat?.members || []}
                      name={selectedChat.name}
                      nameAutoGen={selectedChat.chat_channel_name_autogen}
                      memberCount={selectedChat.member_count}
                      // userId={props.userId}
                      // userName={props.userName}
                      // userLastName={props.userLastName}
                      splitter={true}
                    />
                    <Image
                      src={IMAGES.pencil_cog}
                      style={{
                        width: "20px",
                        marginLeft: "4rem",
                        cursor: "pointer",
                      }}
                      alt="pencil-cog.icon"
                      onClick={() => handleOpenModal(selectedChat)}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <Icon
                      name="search"
                      style={{
                        margin: "0px 0.5rem",
                        cursor: "pointer",
                        color: COLORS.dark_grey,
                        fontSize: "1.25rem",
                      }}
                      onClick={handleToggleSearch}
                    />
                    <Image
                      src={IMAGES.leave}
                      style={{
                        cursor: "pointer",
                        width: "18px",
                        margin: "0px 1rem",
                      }}
                      alt="leave.icon"
                      onClick={handleOpenModLeave}
                    />
                  </div>
                </div>

                {toggleSearch && (
                  <SearchBox
                    total={props.searchMessageList?.count || 0}
                    activeIndex={searchIndex}
                    searchList={props.searchMessageList?.items}
                    laxyLoad={chatBoxRef.current?.laxyLoad}
                    // callback
                    onClose={handleCloseSearch}
                    onSearch={handleSearch}
                    onNextSearch={handleNextSearch}
                    onChangeIndex={setSearchIndex}
                    onSelectMessage={handleSelectMessage}
                  />
                )}
              </div>

              <div>
                <ChatBox
                  ref={(ref: any) => (chatBoxRef.current = ref)}
                  // @ts-ignore
                  controller={props.chatController}
                  userId={props.userId}
                  apiToken={props.apiToken}
                  isPatient={false}
                  nullId={false}
                  fullname={"fullname"}
                  username={"username"}
                  patientData={{}}
                  enHasNoDiv={() => {}}
                  enHasDiv={() => {}}
                  match={{
                    params: {
                      chatChannelId: selectedChat?.chat_channel_id,
                    },
                  }}
                  division_id={Number(props.divisionId)}
                  readOnly={false}
                  messageViewStyle={{
                    height: toggleSearch ? "75vh" : "80vh",
                  }}
                  searchText={searchText}
                  searchMessage={searchMessage}
                  // config
                  hideButtonMenu={true}
                  isStaff={true}
                  // callback
                  onNavigationMessage={({ content }: any) => content}
                  onSetChannelName={() => {}}
                  onCloseVideoCall={() => {}}
                  onOpenVideoCall={(url:string) =>
                    props?.onOpenVideoCall?.(url, selectedChat?.chat_channel_id)
                  }
                  onDidMount={handleReadMessage}
                  onPostMessage={handlePostMessage}
                  onClickLink={handleClickLink}
                />
              </div>
            </>
          ) : (
            <div style={styles.no_chat}>
              <Icon
                name="comment alternate outline"
                style={{
                  transform: "scale(5.5)",
                  marginBottom: "100px",
                  color: "#E0E0E0",
                }}
              />
              <p style={{ fontSize: "1.25rem", marginTop: "-2rem" }}>
                No Conversation
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal
        open={!!openModal}
        size="small"
        onClose={handleClose}
        style={{ width: "50%" }}
      >
        <CardCreateChatRoom
          // data
          userName={props.userName}
          userLastName={props.userLastName}
          userFirstName={props.userFirstName}
          userId={props.userId}
          groupName={openModal?.name || ""}
          nameAutoGen={openModal?.chat_channel_name_autogen}
          // nameAutoGen={openModal.chat_channel_name_autogen}
          userList={props.searchUserList}
          chatChannelId={openModal?.chat_channel_id}
          // memberList={props.userChatSubscriptionList}
          memberList={openModal?.members}
          // callback
          onGetSearchUserList={handleGetSearchUserList}
          onGetUserChatSubscription={handleGetUserChatSubscription}
          onEvent={props.onEvent}
          onClose={handleClose}
          onDelete={handleDeleteMember}
          onFinish={handleFinish}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModConfirmCustom
        open={openModLeave}
        onDeny={handleCloseModLeave}
        onApprove={handleConfirmLeave}
        approveText="Leave"
        denyText="Cancel"
        content={
          <div style={{ margin: "0 0 -1.25rem" }}>
            <label>
              If you leave this group, you will no longer be able to view it’s
              chat history
            </label>
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              Do you want to leave this group?
            </div>
          </div>
        }
      />

      <ModInfo
        open={!!props.modOpenLinkEncounter}
        titleName="แจ้งเตือน!!!"
        titleColor="red"
        alertText={
          <div style={{ margin: "0 0 1.2rem", whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: props.modOpenLinkEncounter || "" }} />
        }
        onClose={props.onCloseModLinkEncounter}
        onApprove={props.onCloseModLinkEncounter}
      />
    </div>
  );
});

/* ------------------------------------------------------ */

/* ------------------ ModConfirmCustom; ----------------- */

/* ------------------------------------------------------ */

type ModConfirmCustomProps = {
  // data
  open: boolean;
  approveText?: string;
  denyText?: string;
  content?: ReactElement;

  // callback
  onApprove: () => void;
  onDeny: () => void;
};

const ModConfirmCustom = React.memo<ModConfirmCustomProps>((props) => {
  return (
    <ModConfirm
      openModal={props.open}
      basic={false}
      titleColor="red"
      approveButtonStyle={{
        backgroundColor: COLORS.chat_light,
        color: "#121010",
        fontWeight: "normal",
      }}
      approveButtonText={props.approveText}
      denyButtonStyle={{
        backgroundColor: "#CECDCD",
        color: "#121010",
        fontWeight: "normal",
      }}
      denyButtonText={props.denyText}
      textContent={props.content}
      onCloseWithDimmerClick={props.onDeny}
      onDeny={props.onDeny}
      onApprove={props.onApprove}
    />
  );
});

/* ------------------------------------------------------ */

/* ------------------ DisplayChatName ------------------ */

/* ------------------------------------------------------ */

type DisplayChatNameProps = {
  // members: any[];
  // userId: string;
  // userName: string;
  // userLastName: string;
  name?: string;
  nameAutoGen?: string;
  memberCount?: number;
  splitter?: boolean;
  style?: CSSProperties;
};

const getGroupName = ({
  members,
  userId,
  userName,
  userLastName,
  name,
  splitter = false,
}: {
  members: any[];
  userId: string;
  userName: string;
  userLastName: string;
  name?: string;
  splitter?: boolean;
}) => {
  const list = [...(members || [])].map((item) => ({
    ...item,
    ...item.user_obj,
  }));
  // .filter((item) => item.id !== Number(userId));

  const getFirstName = (item: any) => {
    const cleanName = (item.last_name || "").replace(
      /(\.|\+|\*|\?|\^|\$|\(|\)|\[|\]|\{|\}|\||\\)/g,
      "\\$1"
    );
    const reg = new RegExp(cleanName, "g");
    return item?.full_name?.replace(reg, "")?.trim() || "";
  };

  const concatName = list.slice(0, 4).map(getFirstName).join(",");
  const number = `(${list.length})`;
  // const number = `(${list.length + 1})`;
  const firstName = getFirstName({
    full_name: userName,
    last_name: userLastName,
  });

  const friend = list.find((item) => item.id !== Number(userId));

  let title: any = { name: "", full_name: "", number: "" };

  switch (list.length - 1) {
    case 0:
      title = { name: "ห้องว่าง" };
      break;
    case 1:
      title = {
        // name: list[0]?.full_name,
        name: friend?.full_name,
        full_name: `${userName}-${friend?.full_name}`,
      };
      break;
    case 2:
    case 3:
      title = {
        // name: name ? name : `${concatName},${firstName}`,
        name: name ? name : `${concatName}`,
        number,
      };
      break;
    default:
      title = {
        // name: name ? name : `${concatName},${firstName},...`,
        name: name ? name : `${concatName},...`,
        number,
      };
  }

  if (name && splitter) {
    const reg = / \((\d+)\)$/g;
    const splitNumber = Number(reg.exec(name)?.[1]);
    splitNumber === list.length + 1 &&
      (title.name = title.name.replace(reg, ""));
  }

  return title;
};

const DisplayGroupName = React.forwardRef(
  (props: DisplayChatNameProps, ref) => {
    const title = useMemo(() => {
      const count = props.memberCount || 0;
      const number = count <= 2 ? "" : `(${count})`;
      const name = props.nameAutoGen || "";
      // const comma = name.split(",") || [];
      // const length = /^\.\.\. /g.test(comma.slice(-1)[0]) ? count : comma.length;
      // const reg = new RegExp(` \\(${length}\\)`, "g");
      // const isGen = reg.test(name);

      if (!props.name) {
        return { name: name.replace(/ \(\d+\)/g, ""), number };
      } else {
        return { name: props.name, number };
      }
    }, [props.name, props.nameAutoGen]);

    return (
      <div style={props.style}>
        <>
          <label
            style={{
              fontStyle: props.memberCount === 0 ? "italic" : "",
            }}
          >
            {title.name || props.name}
          </label>
          <label> {title.number || ""}</label>
        </>
      </div>
    );
  }
);

/* ------------------------------------------------------ */

/*                    CardMessageList                    */

/* ------------------------------------------------------ */

type CardMessageListProps = {
  // data
  search: string;
  searchList?: any[];
  isEndOfList?: boolean;
  style: CSSProperties;

  // callback
  onScrollToBottom?: Function;
  onSelected?: (select: any, index: number) => void;
};

const CardMessageList = React.memo<CardMessageListProps>((props) => {
  const intl = useIntl();
  const [isBottomLoading, setIsBottomLoading] = useState<boolean>(false);

  const isLaxyLoad = useRef<boolean>(false);
  const messageListRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const bodyRef = messageListRef.current;

    if (!bodyRef) {
      return;
    }

    bodyRef.onscroll = async () => {
      const isBottom =
        bodyRef.scrollTop + bodyRef.offsetHeight + 15 >= bodyRef.scrollHeight;
      if (!isLaxyLoad.current && isBottom && !props.isEndOfList) {
        isLaxyLoad.current = true;
        setIsBottomLoading(true);

        props.onScrollToBottom?.();
      }
    };

    return () => {
      bodyRef.onscroll = null;
    };
  }, [props.searchList, props.isEndOfList]);

  useEffect(() => {
    isLaxyLoad.current = false;
    setIsBottomLoading(false);
  }, [props.searchList]);

  const handleClick = (item: any, index: number) => (e: any) => {
    props.onSelected?.(item, index);
  };

  return (
    <>
      <div
        ref={messageListRef}
        style={{ ...styles.message_search, ...props.style }}
      >
        {(props.searchList || []).map((item, index) => {
          const cleanText = (props.search || "").replace(
            /(\.|\+|\*|\?|\^|\$|\(|\)|\[|\]|\{|\}|\||\\)/g,
            "\\$1"
          );

          const reg = new RegExp(`(${cleanText})`, "g");
          const content = item.content.replace(
            reg,
            `<span style='background-color:#5DBCD2; color:white; padding:1px 0 3px;'>$1</span>`
          );

          return (
            <div
              key={"message-list-" + index}
              style={{
                borderBottom: `1px solid ${COLORS.divider}`,
                padding: "7.5px 15px",
                cursor: "pointer",
              }}
              onClick={handleClick(item, index)}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontWeight: "bold" }}>
                  {item.author?.full_name || ""}
                </div>
                <div style={{ color: COLORS.dark_grey }}>{item.send_at}</div>
              </div>
              <div
                style={{ padding: "3px 0" }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          );
        })}

        {isBottomLoading && (
          <Dimmer
            active={true}
            inverted
            style={{
              background: "transparent",
              position: "relative",
              zIndex: "auto",
              paddingTop: "60px",
              height: "30px",
            }}
          >
            <Loader inverted></Loader>
          </Dimmer>
        )}

        {props.isEndOfList && (
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              padding: "10px 0",
              color: COLORS.dark_grey,
            }}
          >{intl.formatMessage({ id: "สิ้นสุดรายการ" })}</div>
        )}
      </div>
    </>
  );
});

/* ------------------------------------------------------ */

/* ------------------ SearchBox; ----------------- */

/* ------------------------------------------------------ */

type SearchBoxProps = {
  // data
  total?: number;
  activeIndex?: number;
  searchList?: any[];
  laxyLoad?: () => "next" | "prev";
  // callback
  onChangeIndex?: (index: number) => void;
  onClose?: () => void;
  onSearch?: (value: string) => void;
  onNextSearch?: (value: string) => void;
  onSelectMessage?: (item: any, index: number) => void;
};

const SearchBox = React.memo<SearchBoxProps>((props) => {
  const [input, setInput] = useState<string>("");
  const [toggleMessageList, setToggleMessageList] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const searchBoxRef = useRef() as MutableRefObject<HTMLDivElement>;
  const inputRef = useRef<any>();
  const isSearch = useRef<boolean>(false);

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.inputRef.current.focus();
      }, 100);
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (!props.searchList?.length && isSearch.current && input) {
      setIsNotFound(true);
    } else {
      setIsNotFound(false);
    }
  }, [props.searchList]);

  const messageSearchStyle = useMemo(() => {
    if (searchBoxRef.current) {
      const body = document.querySelector("body") as HTMLBodyElement;
      const { bottom, height } = searchBoxRef.current.getBoundingClientRect();
      const { bottom: bottomBody } = body.getBoundingClientRect();
      return { top: `${height}px`, height: `${bottomBody - bottom}px` };
    }
    return {};
  }, [window.innerHeight, searchBoxRef.current]);

  // Search
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const value = (e.target as any).value;
    if (e.key === "Enter") {
      isSearch.current = true;
      props.onSearch?.((e.target as any).value);
      setInput(value);
    }
  };

  const handleChange = (v: InputProps) => {
    isSearch.current = false;
    setInput(v.value);
  };

  const handleNextSearch = () => {
    props.onNextSearch?.(input);
  };

  const handleShowMessageList = () => {
    setToggleMessageList(!toggleMessageList);
  };

  const handleChangeStep = (action: string) => (e: any) => {
    console.log(props.laxyLoad?.());

    if (props.laxyLoad?.()) {
      return;
    }

    let index = props.activeIndex || 0;
    const endIndex = (props.total || 0) - 1;
    if (action === "next") {
      index -= 1;
    } else if (action === "previous") {
      index += 1;
    }

    if (index >= (props.searchList?.length || 0)) {
      handleNextSearch();
    }

    props.onChangeIndex?.(index < 0 ? 0 : index > endIndex ? endIndex : index);
  };

  // Message list
  const handleSelected = (item: any, index: number) => {
    props.onSelectMessage?.(item, index);
    setToggleMessageList(false);
  };

  return (
    <div
      ref={searchBoxRef}
      style={{
        display: "flex",
        alignItems: "baseline",
        width: "100%",
        padding: "0 10px 10px",
        position: "relative",
      }}
    >
      <Input
        ref={inputRef}
        icon={
          <div
            style={{
              display: "flex",
              margin: "0.55rem 0 0 -153px",
            }}
          >
            {!!props.total && (
              <label style={{ width: "90px", textAlign: "right" }}>
                {!!props.total &&
                  `${(props.activeIndex || 0) + 1}/${props.total || ""}`}
              </label>
            )}
            {isNotFound && (
              <label
                style={{
                  width: "140px",
                  textAlign: "right",
                  color: COLORS.dark_grey,
                }}
              >
                No results
              </label>
            )}

            {!!props.total && (
              <>
                <Icon
                  name="caret up"
                  link
                  style={{
                    fontSize: "2rem",
                    color: COLORS.light_grey,
                    marginRight: "-7.5px",
                  }}
                  onClick={handleChangeStep("previous")}
                />
                <Icon
                  name="caret down"
                  link
                  style={{ fontSize: "2rem", color: COLORS.light_grey }}
                  onClick={handleChangeStep("next")}
                />
              </>
            )}
          </div>
        }
        value={input}
        placeholder="ค้นหาข้อความ"
        fluid
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        style={{ width: "95%", marginRight: "0.75rem" }}
      />
      {!!props.total && (
        <Icon
          name="comments"
          circular={true}
          inverted={toggleMessageList}
          style={{
            fontSize: "1.2rem",
            margin: "0px 1rem 0px 0.05rem",
            color: COLORS.dark_grey,
            cursor: "pointer",
          }}
          onClick={handleShowMessageList}
        />
      )}
      <Icon
        name="close"
        color="red"
        onClick={props.onClose}
        style={{ cursor: "pointer" }}
      />

      {/* Display message search */}
      {toggleMessageList && (
        <CardMessageList
          // data
          searchList={props.searchList}
          search={input}
          isEndOfList={
            !!props.total && props.total === props.searchList?.length
          }
          style={messageSearchStyle}
          // callback
          onScrollToBottom={handleNextSearch}
          onSelected={handleSelected}
          languageUX={props.languageUX}
        />
      )}
    </div>
  );
});

/* ------------------------------------------------------ */

/* ----------------- CardCreateChatRoom; ----------------- */

/* ------------------------------------------------------ */

type CardCreateChatRoomProps = {
  // data
  userName: string;
  userLastName: string;
  userFirstName: string;
  userId: string;
  chatChannelId?: number;
  groupName?: string;
  nameAutoGen?: string;
  userList?: any[];
  memberList?: any[];
  // callback
  onEvent: any;
  onGetSearchUserList?: (search: string, selected: number[]) => void;
  onGetUserChatSubscription?: (params: any) => void;
  onClose?: () => void;
  onFinish?: (params: {
    userIds: number[];
    groupName: string;
    action: string;
    chatChannelId?: number;
    memberIds: number[];
  }) => void;
  // onSelected?: () => void;
  onDelete?: (params: {
    ids: number[];
    name?: string;
    isChangeName?: boolean;
  }) => Promise<any>;
};

const getChangeName = (
  params: {
    members: any[];
    userId: string;
    userName: string;
    userLastName: string;
    userFirstName: string;
    name?: string | undefined;
    splitter?: boolean | undefined;
  },
  memberList: any[],
  groupName?: string,
  removeUser?: string
) => {
  let members = [...params.members].map((item) => ({
    ...item,
    ...item.user_obj,
  }));
  let filterMember = [...memberList];
  let newParams = { ...params };

  // const splitUser = /\(\d+\)$/g.test(groupName || "")
  //   ? groupName?.split(",").filter((value) => !value.includes("...")) || []
  //   : [];
  // // owner
  // const lastUser =
  //   splitUser.slice(-1)[0]?.replace(/ \(\d+\)$/g, "") || ("" as string);
  // // user อยู่ใน group name
  // const findIndex = splitUser.findIndex((value) =>
  //   value.includes(params.userFirstName)
  // );

  // // ใน members ไม่มี user ที่อยู่ใน group name
  // if (
  //   findIndex !== -1 &&
  //   !members.find((item) => item.id === Number(params.userId))
  // ) {
  //   const user = {
  //     id: Number(params.userId),
  //     full_name: params.userName,
  //     last_name: params.userLastName,
  //     first_name: params.userFirstName,
  //   };

  //   members.splice(findIndex, 0, user);
  //   filterMember.splice(findIndex, 0, user);
  // }

  // const formatParams = (params: any, user: any) => ({
  //   ...params,
  //   userId: user.id,
  //   userName: user.full_name,
  //   userLastName: user.last_name,
  // });

  // // มี owner
  // if (lastUser) {
  //   let user = members.find((item) =>
  //     new RegExp(`.*${item.first_name}$`).test(lastUser)
  //   );

  //   if (!!user) {
  //     user = { ...user, full_name: `${lastUser} ${user.last_name}` };

  //     members = members.map((item) => (item.id === user.id ? user : item));

  //     if (removeUser) {
  //       filterMember = members.filter(
  //         (item) => item.id !== user.id && item.id !== Number(removeUser)
  //       );
  //     }

  //     params = formatParams(params, user);
  //     newParams = { ...params };

  //     if (user.id === Number(removeUser)) {
  //       newParams = formatParams(
  //         params,
  //         members.filter((item) => item.id !== Number(removeUser)).slice(-1)[0]
  //       );
  //     }
  //   }
  // }

  const groupNameString = (params: any) => {
    const title = getGroupName(params);
    return `${title.name} ${title.number || ""}`.trim();
  };

  // const oldName = groupNameString({ ...params, members });

  // // เปลี่ยน group name auto
  // if (!groupName || oldName === groupName || members.length === 1) {
  //   const name = groupNameString({ ...newParams, members: filterMember });

  //   return name;
  // }
  // // เปลี่ยน group name auto
  if (!groupName || members.length === 1) {
    const name = groupNameString({ ...newParams, members: filterMember });

    return name;
  }
};

const CardCreateChatRoom = React.memo<CardCreateChatRoomProps>((props) => {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [openModDelete, setOpenModDelete] = useState<any>(null);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [memberList, setMemberList] = useState<any[]>([]);
  const [allMemberList, setAllMemberList] = useState<any[]>([]);
  const [groupName, setGroupName] = useState("");
  const [allUserList, setAllUserList] = useState<any[]>([]);
  const [nameAutoGen, setNameAutoGen] = useState("");

  // const groupNameRef = useRef<any>();
  const isMounted = useRef<boolean>(false);
  const membersRef = useRef<any[]>([]);
  const isConfirmRef = useRef<boolean>(false);
  const isDeleteRef = useRef<boolean>(false);

  useEffect(() => {
    getSearchUserList();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // const getSelectMembers = (list: any[]) => list.filter((item) => !item.is_member);

    if (isMounted.current) {
      return;
    }

    const members = (props.memberList || []).map((item) => ({
      ...item,
      id: item.user_obj.id,
      full_name: item.user_obj.full_name,
      is_member: true,
    }));

    const allMembers = !!members.length
      ? members
      : [
          {
            id: Number(props.userId),
            full_name: props.userName,
            is_member: true,
          },
        ];
    const userList = members.filter((item) => item.id !== Number(props.userId));
    // const concatAllMembers = [...allMembers];
    // const concatMemberList = [...userList];
    membersRef.current = props.memberList || [];

    setMemberList(userList);
    setAllMemberList(allMembers);
    setGroupName(props.groupName || "");
    setNameAutoGen(props.nameAutoGen || "");

    isMounted.current = true;
  }, [props.memberList, props.groupName, props.nameAutoGen]);

  useEffect(() => {
    const array = [...allUserList, ...(props.userList || [])];
    const key = "id";
    const arrayUniqueByKey = Array.from(
      new Map(array.map((item) => [item[key], item])).values()
    );

    setAllUserList(arrayUniqueByKey);
  }, [props.userList]);

  useEffect(() => {
    if (isConfirmRef.current && !groupName) {
      handleConfirm();
      isConfirmRef.current = false;
    }
  }, [groupName]);

  useEffect(() => {
    if (isDeleteRef.current && !groupName) {
      handleConfirmDelete();
      isDeleteRef.current = false;
    }
  }, [groupName]);

  // useEffect(() => {
  //   // const title = getGroupName({
  //   //   ...props,
  //   //   name: props.groupName,
  //   //   members: props.memberList || [],
  //   // });

  //   setGroupName(props.groupName || "");
  // }, [props.groupName]);

  // useEffect(() => {
  //   let name = props.nameAutoGen || "";

  //   setNameAutoGen(name);
  // }, [props.nameAutoGen, props.memberList]);

  const userList = useMemo(() => {
    const memberIds = [{ id: Number(props.userId) }, ...memberList].map(
      (item) => item.id
    );
    return (props.userList || []).filter(
      (item) => !memberIds.includes(item.id)
    );
  }, [props.userList, memberList]);

  const getSearchUserList = (search: string = "") => {
    // setCheckedIds([]);

    props.onGetSearchUserList?.(search, checkedIds);
  };

  const handleChecked = (e: any, v: RadioProps) => {
    if (v.checked) {
      setCheckedIds([...checkedIds, Number(v.name)]);
    } else {
      setCheckedIds([...checkedIds.filter((id) => id !== Number(v.name))]);
    }
  };

  const handleStartChat = () => {
    if (!memberList.length && !props.chatChannelId) {
      return;
    }

    const oldIds = membersRef.current.map((item) => item.user_obj.id);

    // const title = getGroupName({ ...props, members: memberList });
    // const fullTitle = `${title.name} ${title.number || ""}`.trim();
    // const isEqual = fullTitle === groupName;
    // const name = title.full_name
    //   ? title.full_name
    //   : isEqual || !groupName
    //   ? fullTitle
    //   : groupName;

    // console.log(name, title, groupName);

    props.onFinish?.({
      userIds: memberList
        .map((item) => item.id)
        .filter((id) => !oldIds.includes(id)),
      groupName: groupName,
      action: "REQUEST",
      chatChannelId: props.chatChannelId,
      memberIds: memberList.map((item) => item.id),
    });
  };

  const handleConfirm = () => {
    const users = allUserList.filter((item) => checkedIds.includes(item.id));

    const members = [...allMemberList, ...users];

    // if(members.length === 2 && !isConfirmRef.current && groupName){
    //   isConfirmRef.current = true;
    //   return setGroupName("")
    // }

    setMemberList([...memberList, ...users]);
    setAllMemberList(members);
    // handleSetGroupName([...memberList, ...users]);
    handleSetGroupName(members);
    setCheckedIds([]);
  };

  const handleSetGroupName = (members: any[], removeUser = undefined) => {
    // setMemberList(members);

    const name = getChangeName(
      { ...props, members: allMemberList },
      members,
      groupName,
      removeUser
    );

    if (typeof name === "string") {
      setNameAutoGen(name);

      return name;
    }
  };

  // Mod Delete
  const handleCloseModDelete = () => {
    setOpenModDelete(null);
  };

  const handleDelete = (item: any) => (e: any) => {
    setOpenModDelete(item);
  };

  const handleConfirmDelete = async () => {
    const members = allMemberList.filter(
      (item) => item.id !== openModDelete?.id
    );

    if (members.length === 1 && !isDeleteRef.current && groupName) {
      isDeleteRef.current = true;
      return setGroupName("");
    }

    setMemberList(memberList.filter((item) => item.id !== openModDelete?.id));
    setAllMemberList(members);

    const name = handleSetGroupName(members, openModDelete?.id);

    if (openModDelete?.is_member) {
      await props.onDelete?.({
        ids: [openModDelete?.id],
        name: groupName,
        isChangeName:
          !!props.groupName &&
          nameAutoGen === "ห้องว่าง" &&
          !groupName &&
          members.length === 1,
      });
    }

    handleCloseModDelete();
  };

  // Search
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getSearchUserList((e.target as any).value);
    }
  };

  const handleChangeInput = (e: any, v: InputProps) => {
    setInputSearch(v.value);
  };

  // Group name
  const handleChangeGroupName = (e: any, v: InputProps) => {
    const value = v.value;
    if (value === nameAutoGen) {
      setGroupName("");
    } else {
      setGroupName(value);
    }

    if (value === "") {
      setNameAutoGen("");
    }
  };

  // console.log(props.chatChannelId , allMemberList.length , isMounted.current)
  return (
    <div className="CardCreate-chatRoom">
      {/* Header */}
      <div
        style={{
          backgroundColor: "#94C4F1",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem 0.5rem 1rem 1rem",
        }}
      >
        <label>{"Create & Edit chat room"}</label>
        <Icon
          style={{ cursor: "pointer" }}
          name="close"
          onClick={props.onClose}
        />
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "47.5% 1fr" }}>
        {/* Choose member */}
        <div
          style={{
            padding: "20px 20px 25px 20px",
            borderRight: `1px solid ${COLORS.grey}`,
          }}
        >
          <label>Choose member for invite</label>
          <Input
            value={inputSearch}
            action={{
              icon: "search",
              onClick: () => getSearchUserList(inputSearch),
            }}
            placeholder="Search..."
            fluid
            style={{ width: "90%", marginTop: "1rem" }}
            onKeyPress={handleKeyPress}
            onChange={handleChangeInput}
          />
          <div
            style={{ color: COLORS.dark_grey, margin: "1.5rem 0px 0.75rem 0" }}
          >
            Selected ({checkedIds.length})
          </div>

          <div style={{ height: "53vh", overflow: "auto" }}>
            {userList.map((item) => (
              <div
                key={`user-list-${item?.id}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "13px 7.5% 13px 0",
                }}
              >
                <label>{item.full_name}</label>
                <Radio
                  name={item.id}
                  checked={checkedIds.includes(item.id)}
                  onClick={handleChecked}
                />
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <Button
              style={{
                backgroundColor:(!!props.chatChannelId && allMemberList.length === 1) || !isMounted.current ?
                  "#767676": COLORS.chat_light,
                width: "fit-content",
              }}
              fluid
              disabled={(!!props.chatChannelId && allMemberList.length === 1) || !isMounted.current}
              onClick={handleConfirm}
            >
              Confirm Selected
            </Button>
          </div>
        </div>

        {/* Member */}
        <div>
          <div
            style={{
              height: "70px",
              backgroundColor: "rgba(198, 235, 243, 0.5)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "25px 15px 0px",
              }}
            >
              <Input
                value={groupName || nameAutoGen}
                ref={(instance: any) => {
                  instance?.inputRef?.current &&
                    (instance.inputRef.current.style.cssText = `background: transparent; border-color: transparent; font-size: 1.1rem; color: rgb(129 129 129); padding: 0;`);
                }}
                placeholder="Create group name"
                readOnly={memberList.length <= 1}
                style={{ width: "100%", marginRight: "0.75rem" }}
                onChange={handleChangeGroupName}
              />
              <Image
                src={IMAGES.pencil_edit}
                alt="pencil-edit.icon"
                style={{ width: "20px" }}
              />
            </div>
          </div>

          <div style={{ padding: "15px 20px 25px 20px" }}>
            <div
              style={{ color: COLORS.dark_grey, margin: "0.75rem 0 0.5rem 0" }}
            >
              Member ({memberList.length + 1})
            </div>

            <div style={{ height: "55vh", overflow: "auto" }}>
              {[{ id: null, full_name: props.userName }, ...memberList].map(
                (item, index) => (
                  <div
                    key={`user-list-${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "13px 7.5% 13px 0",
                    }}
                  >
                    <label>{item.full_name}</label>
                    {index === 0 ? (
                      <div style={{ fontStyle: "italic", color: COLORS.grey }}>
                        {"(you)"}
                      </div>
                    ) : (
                      <Icon
                        name="close"
                        color="red"
                        style={{
                          fontSize: "0.75rem",
                          marginRight: "8px",
                          cursor: "pointer",
                        }}
                        onClick={handleDelete(item)}
                      />
                    )}
                  </div>
                )
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1.5rem",
              }}
            >
              <Button
                style={{
                  width: "fit-content",
                }}
                color={
                  !!memberList.length || props.chatChannelId ? "green" : "grey"
                }
                fluid
                onClick={handleStartChat}
              >
                Start chat
              </Button>
            </div>
          </div>
        </div>
      </div>

      <ModConfirmCustom
        open={!!openModDelete}
        onDeny={handleCloseModDelete}
        onApprove={handleConfirmDelete}
        approveText="Remove"
        denyText="Cancel"
        content={
          <div style={{ textAlign: "center", margin: "0 0 -1.25rem" }}>
            Remove {openModDelete?.full_name || ""} from this group?
          </div>
        }
      />
    </div>
  );
});

(StaffChat.defaultProps as StaffChatProps) = {
  chatController: {},
  onEvent: () => null,
  userId: "",
  apiToken: "",
  divisionId: "",
  userName: "",
  userLastName: "",
  userFirstName: "",
};

export default React.memo(StaffChat);
