import * as UnderlyingDiseaseI from "./UnderlyingDisease";
import * as DrugDiseaseInteractionI from "./DrugDiseaseInteraction";
import * as HealthMemberI from "./HealthMember";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  UnderlyingDisease: {
    default: {
      START: UnderlyingDiseaseI.GetMaster,
      Action: UnderlyingDiseaseI.Action,
    },
  },
  DrugDiseaseInteraction: {
    default: {
      START: DrugDiseaseInteractionI.GetMaster,
      Action: DrugDiseaseInteractionI.Action,
    },
  },
  HealthMember: {
    default: {
      START: HealthMemberI.GetMaster,
      Action: HealthMemberI.Action,
    },
  },
};

export default SequencePattern;
