import React, {useState} from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import { useIntl } from "react-intl";

interface Props { 
  open: boolean
  message: string
  onClose: () => void
}

const ModalRequireField:React.FC<Props> = (props) => {
  const intl = useIntl();
  return (
    <Modal
      closeIcon
      open={props.open}
      onClose={() => {  props.onClose() }}
    >
      <Header icon='warning' content={intl.formatMessage({ id: "ไม่สามารถ ดำเนินการต่อได้ จำเป็นต้องกรอกข้อมูลให้ครบ" })} />
      <Modal.Content>
        <p>
          {props.message}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color='green' onClick={() => props.onClose()}>
          <Icon name='check' /> OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalRequireField;
