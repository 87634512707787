import React from 'react'
import {
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardOTPhysicalExaminationCerebralPalsy2.module.css'

export interface CardOTPhysicalExaminationCerebralPalsy2ViewDataType {
    CtGrooming_oral: string,
    CtGrooming_oralDisabled: boolean,
    CtGrooming_oralOptions: any,
    OtPhysicalCerebral25: string,
    OtPhysicalCerebral25Disabled: boolean,
    CtFeedingEating: string,
    CtFeedingEatingDisabled: boolean,
    CtFeedingEatingOptions: any,
    OtPhysicalCerebral211: string,
    OtPhysicalCerebral211Disabled: boolean,
    CtDressingUpper: string,
    CtDressingUpperDisabled: boolean,
    CtDressingUpperOptions: any,
    OtPhysicalCerebral216: string,
    OtPhysicalCerebral216Disabled: boolean,
    CtDressingLower: string,
    CtDressingLowerDisabled: boolean,
    CtDressingLowerOptions: any,
    OtPhysicalCerebral221: string,
    OtPhysicalCerebral221Disabled: boolean,
    CtTransferBed: string,
    CtTransferBedDisabled: boolean,
    CtTransferBedOptions: any,
    OtPhysicalCerebral227: string,
    OtPhysicalCerebral227Disabled: boolean,
    CtTransferToilet: string,
    CtTransferToiletDisabled: boolean,
    CtTransferToiletOptions: any,
    OtPhysicalCerebral232: string,
    OtPhysicalCerebral232Disabled: boolean,
    CtBedMobility: string,
    CtBedMobilityDisabled: boolean,
    CtBedMobilityOptions: any,
    OtPhysicalCerebral238: string,
    OtPhysicalCerebral238Disabled: boolean,
    CtBathing: string,
    CtBathingDisabled: boolean,
    CtBathingOptions: any,
    OtPhysicalCerebral243: string,
    OtPhysicalCerebral243Disabled: boolean,
    CtToileting: string,
    CtToiletingDisabled: boolean,
    CtToiletingOptions: any,
    OtPhysicalCerebral248: string,
    OtPhysicalCerebral248Disabled: boolean,
    CtOther: string,
    CtOtherDisabled: boolean,
    CtOtherOptions: any,
    OtPhysicalCerebral253: string,
    OtPhysicalCerebral253Disabled: boolean,
}

export const CardOTPhysicalExaminationCerebralPalsy2InitialViewData: CardOTPhysicalExaminationCerebralPalsy2ViewDataType = {
    CtGrooming_oral: "",
    CtGrooming_oralDisabled: false,
    CtGrooming_oralOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral25: "",
    OtPhysicalCerebral25Disabled: false,
    CtFeedingEating: "",
    CtFeedingEatingDisabled: false,
    CtFeedingEatingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral211: "",
    OtPhysicalCerebral211Disabled: false,
    CtDressingUpper: "",
    CtDressingUpperDisabled: false,
    CtDressingUpperOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral216: "",
    OtPhysicalCerebral216Disabled: false,
    CtDressingLower: "",
    CtDressingLowerDisabled: false,
    CtDressingLowerOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral221: "",
    OtPhysicalCerebral221Disabled: false,
    CtTransferBed: "",
    CtTransferBedDisabled: false,
    CtTransferBedOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral227: "",
    OtPhysicalCerebral227Disabled: false,
    CtTransferToilet: "",
    CtTransferToiletDisabled: false,
    CtTransferToiletOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral232: "",
    OtPhysicalCerebral232Disabled: false,
    CtBedMobility: "",
    CtBedMobilityDisabled: false,
    CtBedMobilityOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral238: "",
    OtPhysicalCerebral238Disabled: false,
    CtBathing: "",
    CtBathingDisabled: false,
    CtBathingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral243: "",
    OtPhysicalCerebral243Disabled: false,
    CtToileting: "",
    CtToiletingDisabled: false,
    CtToiletingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral248: "",
    OtPhysicalCerebral248Disabled: false,
    CtOther: "",
    CtOtherDisabled: false,
    CtOtherOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral253: "",
    OtPhysicalCerebral253Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtPhysicalCerebral260}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Activities of daily living</strong></p>
                </div>
            </div>
            <div className={styles.CtGrooming_oral}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGrooming_oral}
                    disabled={ props.viewData.CtGrooming_oralDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtGrooming_oral",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGrooming_oralOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral25}>
                <Input
                    value={props.viewData.OtPhysicalCerebral25}
                    disabled={ props.viewData.OtPhysicalCerebral25Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral25',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral26}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Grooming/Oral hygiene</p>
                </div>
            </div>
            <div className={styles.CtFeedingEating}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtFeedingEating}
                    disabled={ props.viewData.CtFeedingEatingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtFeedingEating",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtFeedingEatingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral211}>
                <Input
                    value={props.viewData.OtPhysicalCerebral211}
                    disabled={ props.viewData.OtPhysicalCerebral211Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral211',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral212}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Feeding/eating</p>
                </div>
            </div>
            <div className={styles.CtDressingUpper}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtDressingUpper}
                    disabled={ props.viewData.CtDressingUpperDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtDressingUpper",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtDressingUpperOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral216}>
                <Input
                    value={props.viewData.OtPhysicalCerebral216}
                    disabled={ props.viewData.OtPhysicalCerebral216Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral216',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral217}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Dressing</p>
                </div>
            </div>
            <div className={styles.CtDressingLower}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtDressingLower}
                    disabled={ props.viewData.CtDressingLowerDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtDressingLower",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtDressingLowerOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral221}>
                <Input
                    value={props.viewData.OtPhysicalCerebral221}
                    disabled={ props.viewData.OtPhysicalCerebral221Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral221',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral222}>
                <div
                    style={{color: "#2F80ED", height: "100%", width: "100%", textAlign: "Right"}}
                >
                    <p><strong>Upper</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral223}>
                <div
                    style={{color: "#2F80ED", height: "100%", width: "100%", textAlign: "Right"}}
                >
                    <p><strong>Lower</strong></p>
                </div>
            </div>
            <div className={styles.CtTransferBed}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTransferBed}
                    disabled={ props.viewData.CtTransferBedDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtTransferBed",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTransferBedOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral227}>
                <Input
                    value={props.viewData.OtPhysicalCerebral227}
                    disabled={ props.viewData.OtPhysicalCerebral227Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral227',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral228}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Transfer</p>
                </div>
            </div>
            <div className={styles.CtTransferToilet}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTransferToilet}
                    disabled={ props.viewData.CtTransferToiletDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtTransferToilet",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTransferToiletOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral232}>
                <Input
                    value={props.viewData.OtPhysicalCerebral232}
                    disabled={ props.viewData.OtPhysicalCerebral232Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral232',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral233}>
                <div
                    style={{color: "#2F80ED", height: "100%", width: "100%", textAlign: "Right"}}
                >
                    <p><strong>W/C - bed</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral234}>
                <div
                    style={{color: "#2F80ED", height: "100%", width: "100%", textAlign: "Right"}}
                >
                    <p><strong>W/C - toilet</strong></p>
                </div>
            </div>
            <div className={styles.CtBedMobility}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtBedMobility}
                    disabled={ props.viewData.CtBedMobilityDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtBedMobility",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtBedMobilityOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral238}>
                <Input
                    value={props.viewData.OtPhysicalCerebral238}
                    disabled={ props.viewData.OtPhysicalCerebral238Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral238',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral239}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Functional test</p>
                </div>
            </div>
            <div className={styles.CtBathing}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtBathing}
                    disabled={ props.viewData.CtBathingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtBathing",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtBathingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral243}>
                <Input
                    value={props.viewData.OtPhysicalCerebral243}
                    disabled={ props.viewData.OtPhysicalCerebral243Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral243',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral244}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Bathing / showering</p>
                </div>
            </div>
            <div className={styles.CtToileting}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtToileting}
                    disabled={ props.viewData.CtToiletingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtToileting",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtToiletingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral248}>
                <Input
                    value={props.viewData.OtPhysicalCerebral248}
                    disabled={ props.viewData.OtPhysicalCerebral248Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral248',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral249}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Toileting</p>
                </div>
            </div>
            <div className={styles.CtOther}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtOther}
                    disabled={ props.viewData.CtOtherDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationCerebralPalsy2",
                                name: "CtOther",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtOtherOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral253}>
                <Input
                    value={props.viewData.OtPhysicalCerebral253}
                    disabled={ props.viewData.OtPhysicalCerebral253Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationCerebralPalsy2',
                            name: 'OtPhysicalCerebral253',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral254}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
