import React, { useEffect, useMemo, useState } from "react";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBox from "react-lib/appcon/common/SearchBox";

// UI ADM
import CardAdmitChangeUX from "react-lib/apps/HISV3/ADM/CardAdmitChangeUX";
import CardRoomSearch from "react-lib/apps/HISV3/ADM/CardRoomSearch";

// UI TPD
import CardPatientSearchBox from "react-lib/apps/HISV3/TPD/CardPatientSearchBox";
import { useIntl } from "react-intl";

const CardAdmitChange = (props: any) => {
  const intl = useIntl();
  const [roomDetail, setRoomDetail] = useState<Record<string, any>>({});

  useEffect(() => {
    props.runSequence({ sequence: "AdmitChange", action: "CLEAR" });
  }, []);

  useEffect(() => {
    const updateRoomDetail = async () => {
      if (props.action === "RESERVE_TRANSFER") {
        handleChangeNewRoom(null, {
          ...roomDetail,
          value: roomDetail.id,
          notFilter: true,
        });
      }
    };

    updateRoomDetail();
  }, [roomDetail]);

  const disabledBtnSave = useMemo(() => {
    const old_room = false;
    if (props.AdmitChangeSequence?.oldRoom?.trim().replace("-", "") === "") {
      return true;
    }

    const new_room = false;
    if (
      ["", undefined].includes(
        props.AdmitChangeSequence?.room?.toString().trim().replace("-", "")
      )
    ) {
      return true;
    }

    let cleaning = false;
    if (props.action === "CHANGE_BED") {
      if (
        props.AdmitChangeSequence?.cleaning?.toString() === "" ||
        props.AdmitChangeSequence?.cleaning === undefined
      ) {
        cleaning = true;
      }
    }

    return (
      !props.AdmitChangeSequence?.patientId || old_room || new_room || cleaning
    );
  }, [
    props.AdmitChangeSequence?.patientId,
    props.AdmitChangeSequence?.oldRoom,
    props.AdmitChangeSequence?.cleaning,
    props.AdmitChangeSequence?.room,
  ]);

  const handleChangePatient = (
    id: any,
    hn: string,
    fullName: string,
    original: any,
  ) => {
    console.log(id, hn, original);
    props.runSequence({
      sequence: "AdmitChange",
      action: "SET_PATIENT_DATA",
      data: {
        patientId: id,
        hn: hn,
        fullName,
        birthdate: original?.birthdate,
        action: props.action,
      },
    });
  };

  const handleChangeCleaning = (_event: any, data: any) => {
    props.setProp("AdmitChangeSequence.cleaning", data.value);
  };

  const handleChangeNewRoom = (_event: any, data: any) => {
    props.runSequence({
      sequence: "AdmitChange",
      action: "SET_NEW_ROOM",
      selectedRoom: data,
    });
  };

  const handleChangeNewRoomSearchBox = (item: any) => {
    props.runSequence({
      sequence: "AdmitChange",
      action: "SET_NEW_ROOM_SEARCH_BOX",
      selectedRoom: item,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "AdmitChange",
      action: "SAVE",
      btnKey: "CardAdmitChange",
      data: { action: props.action },
    });
  };

  const handleChangeRoom = (room: any) => {
    setRoomDetail(room || {});
  };

  return (
    <>
      <CardAdmitChangeUX
        // seq
        AdmitChangeSequence={props.AdmitChangeSequence}
        // function
        onChangeCleaning={handleChangeCleaning}
        onChangeNewRoom={handleChangeNewRoom}
        // data
        title={
          props.action === "RESERVE_TRANSFER"
            ? "ย้าย Ward"
            : props.action === "CHANGE_BED"
            ? "ย้ายเตียง"
            : ""
        }
        action={props.action}
        // component
        patientSearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            defaultValue={props.selectedHN}
            fluid={true}
            onEnterPatientSearch={handleChangePatient}
            languageUX={props.languageUX}
          />
        }
        roomSearchBox={
          <CardRoomSearch
            // callback
            getRoomList={props.getRoomList}
            onEnter={handleChangeRoom}
            onSelect={handleChangeRoom}
            // options
            wardTypeOptions={props.masterOptions.wardType}
            roomTypeOptions={props.masterOptions.roomType}
            roomStatusOptions={props.masterOptions.roomStatus}
            defaultValue={props.roomNo}
            fluid={true}
            languageUX={props.languageUX}
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey="CardAdmitChange"
            buttonLoadCheck={props.buttonLoadCheck?.CardAdmitChange}
            // config
            color="green"
            disabled={disabledBtnSave}
            fluid={true}
            title={intl.formatMessage({ id: "บันทึกรายการ" })}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

export default React.memo(CardAdmitChange);
