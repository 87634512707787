import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';


const CardSelectDateTimePackageUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{fontSize: "1.2rem", fontWeight: "bold", marginBottom: "1rem"}}>
          {props.selectedDivision}
        </div>
        <div>
          {props.Calendar}
        </div>
      </div>
    )
}


export default CardSelectDateTimePackageUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Wed Jul 24 2024 16:35:13 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedDivision"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", marginBottom: \"1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Calendar"
        }
      },
      "seq": 2,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardSelectDateTimePackageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
