import React from 'react';
import {IntlProvider, FormattedMessage} from 'react-intl';

import {
  Input,
  Button
} from 'semantic-ui-react'

const ModCheckDIUX = (props: any) => {

    const isMounted = React.useRef(true);

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{ backgroundColor: "red", color: "white", fontSize: "1.2rem", padding: "5px"}}>
          พบรายการยาที่มีปฏิกิริยาต่อกัน
        </div>
        <div>
          {props.diDetail}
        </div>
        <div
          style={{padding: "10px", display: props.needNote ? "none" : "flex", justifyContent: "center", alignItems: "center" , width :"100%"}}>
          
          <Button
            color="red"
            onClick={props.acknowledge}>
            รับทราบ
          </Button>
        </div>
        <div
          style={{ display: props.needNote ? "block": "none", padding: "2px" }}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(15, 1fr)","gridTemplateColumns":"repeat(20, 1fr)"}}>
            <div
              style={{ gridRow: "6/10", gridColumn: "2/20",  }}>
              <Input
                fluid={true}
                onChange={props.changeNote}>
              </Input>
            </div>
            <div
              style={{ fontSize: "1.2rem", display: "flex", alignItems: "center", justifyContent: "center", gridRow: "3/5", gridColumn: "2/20",  }}>
              กรุณาระบุหมายเหตุการใช้ยา
            </div>
            <div
              style={{ gridRow: "11/14", gridColumn: "2/8",  }}>
              <Button
                color="red"
                onClick={props.confirmOrder}
                style={{width: "100%", height: "100%" }}>
                ยืนยันสั่ง
              </Button>
            </div>
            <div
              style={{ gridRow: "11/14", gridColumn: "14/20",  }}>
              <Button
                onClick={props.cancelOrder}
                style={{width: "100%", height: "100%" }}>
                ไม่สั่ง
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ModCheckDIUX

export const screenPropsDefault = {"diDetail":"ทดสอบ","needNote":true}

/* Date Time : Fri Mar 04 2022 12:38:47 GMT+0700 (Indochina Time)
/* Original screen Data **************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 13,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(15, 1fr)\",\"gridTemplateColumns\":\"repeat(20, 1fr)\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/10\", gridColumn: \"2/20\",  }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาระบุหมายเหตุการใช้ยา"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", display: \"flex\", alignItems: \"center\", justifyContent: \"center\", gridRow: \"3/5\", gridColumn: \"2/20\",  }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/14\", gridColumn: \"2/8\",  }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/14\", gridColumn: \"14/20\",  }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Input",
      "parent": 2,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNote"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันสั่ง"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.confirmOrder"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่สั่ง"
        },
        "onClick": {
          "type": "code",
          "value": "props.cancelOrder"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "พบรายการยาที่มีปฏิกิริยาต่อกัน"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"red\", color: \"white\", fontSize: \"1.2rem\", padding: \"5px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.diDetail"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: props.needNote ? \"none\" : \"flex\", justifyContent: \"center\", alignItems: \"center\" , width :\"100%\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Button",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "รับทราบ"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.acknowledge"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.needNote ? \"block\": \"none\", padding: \"2px\" }"
        }
      },
      "seq": 13,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 40,
  "isMounted": true,
  "memo": false,
  "name": "ModCheckDIUX",
  "project": "cnmi",
  "screenPropsDefault": {
    "diDetail": "ทดสอบ",
    "needNote": true
  },
  "width": 55
}

************************************************************************************* */
