class PatientData {

  patient_id: number | null;
  ENCOUNTER: { 
    encounter_id: number | null, 
    doctor_id: number | null, 
    division_id: number | null
  }
  EMR: {
    emr_id: number | null, 
    progression_cycle_id: number | null, 
    has_doctor_fee: boolean
  }
  EMRList: [{
    doctor_id: number |null,
    emr_id: number | null, 
    progression_cycle_id: number | null, 
    has_doctor_fee: boolean
  }] | []
  OR: {
    operatingId: number | null,
    operatingDetailId: number | null,
  }
  changePatientData: () => {} | null

  hn: string | null
  // @ts-ignore
  full_name_th: string | null
  // @ts-ignore
  gender: string | null
  // @ts-ignore
  birthdate: string | null
  // @ts-ignore
  age: string | null
  // @ts-ignore
  encounter_string: string | null
  // @ts-ignore
  encounter_type:  string | null

  constructor(callback?: any) {
    this.patient_id = null;
    this.ENCOUNTER = {
      encounter_id: null,
      doctor_id: null,
      division_id: null
    };
    this.EMR = {
      emr_id: null,
      progression_cycle_id: null,
      has_doctor_fee: false
    };
    this.EMRList = [];
    this.OR = {
      operatingId: null,
      operatingDetailId: null
    };
    this.hn = null
    this.changePatientData = callback ? callback : () => {} ;
  }

  // Get
  printPatientData() {
    console.log("PatientData this: ", this);
    console.log("PatientData, PatientID: " + this.patient_id);
    console.log("PatientData, ENCOUNTER: " + this.ENCOUNTER);
    console.log("PatientData, EMR: " + this.EMR);
    console.log("PatientData, OR: " + this.OR);
  }

  // Set
  setPatientId(id: any) {
    this.patient_id = Number(id);
  }

  setEncounterId(id: any) {
    this.ENCOUNTER.encounter_id = Number(id);
  }

  setDoctorId(id: any) {
    this.ENCOUNTER.doctor_id = Number(id);
  }

  setDivisionId(id: any) {
    this.ENCOUNTER.division_id = Number(id);
  }

  setEmrId(id: any) {
    this.EMR.emr_id = Number(id);
  }

  setProgressionCycleId(id: any) {
    this.EMR.progression_cycle_id = Number(id);
  }

  setHasDoctorFee(isHave: any) {
    this.EMR.has_doctor_fee = Boolean(isHave);
  }

  setOperationId(id: any) {
    this.OR.operatingId = Number(id);
  }

  setOperationDetailId(id: any) {
    this.OR.operatingDetailId = Number(id);
  }

  // setPatientIDString(idStr){
  //   this.patientID = idStr
  // }
  
  // setEncounterIDString(idStr){
  //   this.encounterID = idStr
  // }

  sethn(hn: any) {
    this.hn = hn
  }

  setFullNameTh(name: any) {
    this.full_name_th = name
  }

  setGender(gender: any) {
    this.gender = gender
  }
  setBirthDate(bd: any) {
    this.birthdate = bd
  }

  setAge(age: any) {
    this.age = age
  }

  setEncounterString(encounterString: any) {
    this.encounter_string = encounterString
  }
  setEncounterType(type: any) {
    this.encounter_type = type
  }


  changePatientId(newId: any) {
    this.patient_id = newId;
    this.changePatientData();
  }

  changeEncounterId(newId: any) {
    this.ENCOUNTER.encounter_id = newId;
    this.changePatientData();
  }

  changeEncounterDoctor(newId: any) {
    this.ENCOUNTER.doctor_id = newId;
    this.changePatientData();
  }

  changeEncounterDivision(newId: any) {
    this.ENCOUNTER.division_id = newId;
    this.changePatientData();
  }

  changeEmrId(newId: any) {
    this.EMR.emr_id = newId;
    this.changePatientData();
  }

  changeProgressionCycleId(newId: any) {
    
    this.EMR.progression_cycle_id = newId;
    console.log('changeProgressionCycleId',newId )
    this.changePatientData();
  }

  changeHasDoctorFee(value: any) {
    this.EMR.has_doctor_fee = value;
    this.changePatientData();
  }

  changeOperatingId(newId: any) {
    this.OR.operatingId = newId;
    this.changePatientData();
  }

  changeOperatingDetailId(newId: any) {
    this.OR.operatingDetailId = newId;
    this.changePatientData();
  }

  changeData({
    patientId,
    encounterId,
    doctorId,
    divisionId,
    emrId,
    progressionCycleId,
    hasDoctorFee,
    operatingId,
    operatingDetailId
  }: any) {
    
    if (patientId !== undefined) {
      this.patient_id = patientId;
    }

    if (encounterId !== undefined) {
      this.ENCOUNTER.encounter_id = encounterId;
    }
    if (doctorId !== undefined) {
      this.ENCOUNTER.doctor_id = doctorId;
    }
    if (divisionId !== undefined) {
      this.ENCOUNTER.division_id = divisionId;
    }

    if (emrId !== undefined) {
      this.EMR.emr_id = emrId;
    }
    if (progressionCycleId !== undefined) {
      this.EMR.progression_cycle_id = progressionCycleId;
    }
    if (hasDoctorFee !== undefined) {
      this.EMR.has_doctor_fee = hasDoctorFee;
    }

    if (operatingId !== undefined) {
      this.OR.operatingId = operatingId;
    }
    if (operatingDetailId !== undefined) {
      this.OR.operatingDetailId = operatingId;
    }

    this.changePatientData();
  }
}

export default PatientData
