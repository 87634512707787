import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button, ButtonProps, FormField, Label } from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";

// Common
import ModInfo from "react-lib/appcon/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck, { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";

// UX
import CardDoctorFeeSummaryUX from "./CardDoctorFeeSummaryUX";
import ModEditDoctorFee from "./ModEditDoctorFee";
import CardDFPayrollGroupSearchBox, { SearchParams } from "./CardDFPayrollGroupSearchBox";
import { useIntl } from "react-intl";

// Interface
import {
  ACTIONS,
  AllFilterType,
  BTN_ACTS,
  CARD_DF_SUMMARY,
  DFPayrollDraftType,
  DFPayrollListType,
  DFPayrollSerializer,
  FilterAllKeys,
  FilterAllValues,
  MasterOptionsType,
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/DoctorFeeSummary";

// Types
type CardDoctorFeeSummaryProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  DoctorFeeSummarySequence: State["DoctorFeeSummarySequence"];
  masterData: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
} & PickedProps;

type TotalKeys = keyof (typeof CREATE_TOTALS & typeof FEE_TOTALS);

type Styles = Record<"button" | "label", CSSProperties>;

// Styles
const styles: Styles = {
  button: { padding: "0.45rem" },
  label: { textAlign: "right", width: "100%" },
};

// Const
const PAYROLL_HEADERS =
  "ลำดับ,ชื่อ-สกุลแพทย์,รายรับ,ค่าแลป,รายได้ หลังหักค่าแลป,หักค่าบริหาร 20 %,คงเหลือ,ส่วนแบ่ง (%),รายได้หลัง หักส่วนแบ่ง,ปรับเพิ่ม,ปรับลด,รวมสุทธิ,บัญชี,เลขที่บัญชี";

const PAYROLL_WIDTHS = "60,^100,^65,^65,^70,^70,^65,^70,^70,^70,^70,^65,^115,^80";

const CREATE_TOTALS = {
  doctor_count: "จำนวนแพทย์",
  total_final_revenue_price: "รวมรายได้หลังหักส่วนแบ่ง",
  total_lab_price: "รวมค่าแลป",
  total_leftover_revenue: "รวมคงเหลือ",
  total_management_fee: "รวมหักค่าบริหาร 20%",
  total_net_price: "รวมสุทธิ",
  total_revenue_after_lab: "รวมรายได้หลังหักค่าแลป",
  total_revenue_price: "รวมรายรับ",
};

const FEE_TOTALS = {
  total: "จำนวนแพทย์",
  total_final_revenue_price: "รวมรายได้หลังหักส่วนแบ่ง",
  total_lab_price: "รวมค่าแลป",
  total_leftover_revenue_price: "รวมคงเหลือ",
  total_management_fee_price: "รวมหักค่าบริหาร 20%",
  total_net_price: "รวมสุทธิ",
  total_revenue_after_lab_price: "รวมรายได้หลังหักค่าแลป",
  total_revenue_price: "รวมรายรับ",
};

const CREATE_PRICE_FIELDS =
  "revenue_price,lab_price,revenue_after_lab,management_fee,leftover_revenue,compensation_percent,final_revenue_price,adjust_up_price,adjust_down_price,net_price";

const SUMMARY_PRICE_FIELDS =
  "net_price,overdue_price,sum_income_price,income_tax_percent,fee_price,net_income_price";

const FEE_PRICE_FIELDS =
  "revenue_price,lab_price,revenue_after_lab_price,management_fee_price,leftover_revenue_price,compensation_percent,final_revenue_price,adjust_up_price,adjust_down_price,net_price";

const DF_SUMMARY = {
  create: {
    buttons: ["create"],
    data: "dfPayrollDraft",
    headers: PAYROLL_HEADERS,
    height: "calc(-25.5rem + 100dvh)",
    keys: `seq,doctor_name,${CREATE_PRICE_FIELDS},bank_branch_name,bank_account_no`,
    title: "สร้างเลขงวดรอบจ่ายค่าตอบแทนแพทย์",
    totals: CREATE_TOTALS,
    widths: PAYROLL_WIDTHS,
  },
  fee: {
    buttons: ["df_payroll_report", "print_summary"],
    data: "dfPayrollFee",
    headers: `${PAYROLL_HEADERS},`,
    height: "calc(-25.5rem + 100dvh)",
    keys: `seq,doctor_name,${FEE_PRICE_FIELDS},bank_branch_name,bank_account_no,action`,
    title: "รายละเอียดค่าตอบแทนแพทย์",
    totals: FEE_TOTALS,
    widths: `${PAYROLL_WIDTHS},65`,
  },
  summary: {
    buttons: ["df_payroll_income_report", "print_payment_slip"],
    data: "dfPayrollSummary",
    headers:
      "ลำดับ,ชื่อ-สกุลแพทย์,ค่าตอบแทน,ค้างจ่าย,รวมรายได้,หักภาษีร้อยละ,ค่าธรรมเนียม,รับสุทธิ,บัญชี,บัญชีเลขที่",
    height: "calc(-30.95rem + 100dvh)",
    keys: `seq,doctor_name,${SUMMARY_PRICE_FIELDS},bank_branch_name,bank_account_no`,
    title: "สรุปรายได้ค่าตอบแทนแพทย์",
    totals: {
      hidden1: "",
      hidden2: "",
      total: "จำนวนแพทย์",
      total_fee_price: "ยอดรวมค่าธรรมเนียม",
      total_net_income_price: "ยอดรวมรับสุทธิ",
      total_net_price: "รวมค่าตอบแทน",
      total_overdue_price: "รวมค้างจ่าย",
      total_sum_income_price: "รวมรายได้",
    },
    widths: "70",
  },
} as const;

type TabMenuKeys = keyof typeof DF_SUMMARY;

const CardDoctorFeeSummary = (props: CardDoctorFeeSummaryProps) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState<TabMenuKeys>("create");

  const doctorFeeSummaryRef = useRef<State["DoctorFeeSummarySequence"]>(null);

  // Effect
  useEffect(() => {
    setActiveTab("create");

    props.runSequence({
      sequence: "DoctorFeeSummary",
      nextIndex: "Create",
    });

    return () => {
      props.runSequence({ sequence: "DoctorFeeSummary", clear: true });

      handleCloseModCreate();
    };
  }, []);

  useEffect(() => {
    doctorFeeSummaryRef.current = props.DoctorFeeSummarySequence;
  }, [props.DoctorFeeSummarySequence]);

  // Memo Callback
  const filterCreate = useMemo(
    () => props.DoctorFeeSummarySequence?.filterCreate,
    [props.DoctorFeeSummarySequence?.filterCreate]
  );

  const filterFee = useMemo(
    () => props.DoctorFeeSummarySequence?.filterFee,
    [props.DoctorFeeSummarySequence?.filterFee]
  );

  const filterSummary = useMemo(
    () => props.DoctorFeeSummarySequence?.filterSummary,
    [props.DoctorFeeSummarySequence?.filterSummary]
  );

  const errorMessage = useMemo(() => {
    const error = props.errorMessage?.[CARD_DF_SUMMARY] || null;

    const displayError = "show" in (error || {}) ? error.show && error.error : error;

    return { ...error, error: displayError };
  }, [props.errorMessage]);

  // Callback
  const allowSearch = useMemo(() => {
    switch (activeTab) {
      case "create": {
        return !!filterCreate?.startDate && !!filterCreate.endDate;
      }
      case "fee": {
        return !!filterFee?.dfPayrollGroup?.id;
      }
      case "summary": {
        return !!filterSummary?.dfPayrollGroup?.id;
      }
      // No default
    }

    return false;
  }, [activeTab, filterCreate, filterFee, filterSummary]);

  const handleGetTdProps = useCallback(
    (state: any, rowInfo: RowInfo, column: Column) => ({
      style: ["bank_account_no", "bank_branch_name", "doctor_name", "seq"].includes(column.id || "")
        ? {}
        : { textAlign: "right" },
    }),
    []
  );

  const formatPrice = useCallback((value: number | string) => {
    if (typeof value !== "number") {
      return value;
    }

    return Number(value).toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "decimal",
    });
  }, []);

  const formatFieldsPrice = useCallback((item: Record<string, any>, fields: string) => {
    const keys = fields.split(",");

    return Object.fromEntries(keys.map((key) => [key, formatPrice(Number.parseFloat(item[key]))]));
  }, []);

  const handleEdit = useCallback(
    (data: DFPayrollSerializer) => () => {
      props.setProp("DoctorFeeSummarySequence.dfPayrollDetail", data);
    },
    []
  );

  const handleSearch: BLClickHandler<typeof ACTIONS.SEARCH> = useCallback((e, data) => {
    props.runSequence({ sequence: "DoctorFeeSummary", action: data.name });
  }, []);

  const handleChangeTab = useCallback(
    (e: SyntheticEvent, data: { name: TabMenuKeys } & ButtonProps) => {
      setActiveTab(data.name);

      props.setProp(`buttonLoadCheck.${BTN_ACTS.SEARCH}`, null);
      props.setProp(`errorMessage.${CARD_DF_SUMMARY}`, null);

      props.runSequence({
        sequence: "DoctorFeeSummary",
        nextIndex: `${data.name[0].toUpperCase()}${data.name.slice(1)}` as any,
      });
    },
    []
  );

  const handleChangeFilter = useCallback(
    (e: SyntheticEvent | null, data: { name: FilterAllKeys; value: FilterAllValues }) => {
      const { name, value } = data;
      const [first] = name.split(".") as [keyof AllFilterType];

      const setValue = (key: FilterAllKeys, value: FilterAllValues) => {
        const keys = key.split(".");

        let current = doctorFeeSummaryRef.current || {};

        for (const [index, k] of keys.entries()) {
          current = current[k] = index === keys.length - 1 ? value : current[k] || {};
        }
      };

      setValue(name, value);

      props.setProp(`DoctorFeeSummarySequence.${first}`, {
        ...doctorFeeSummaryRef.current?.[first],
      });
    },
    [props.DoctorFeeSummarySequence]
  );

  const handleSelectedLotNo = useCallback(
    (key: "filterFee" | "filterSummary") => (data: Record<string, any>) => {
      handleChangeFilter(null, { name: `${key}.dfPayrollGroup`, value: data });
    },
    [handleChangeFilter]
  );

  const handleSearchDFPayrollGroup = useCallback((data: SearchParams) => {
    props.runSequence({
      sequence: "DoctorFeeSummary",
      ...data,
      action: ACTIONS.SEARCH_DF_PAYROLL_GROUP,
    });
  }, []);

  const handleSaveDFPayroll = useCallback(
    (data: { btnAction: string; data: DFPayrollSerializer }) => {
      props.runSequence({
        sequence: "DoctorFeeSummary",
        ...data,
        action: ACTIONS.SAVE_DF_PAYROLL,
      });
    },
    []
  );

  const handleDownloadDF: BLClickHandler<typeof ACTIONS.DF_PAYROLL_REPORT> = useCallback(
    (e, data) => {
      props.runSequence({
        sequence: "DoctorFeeSummary",
        action: data.name,
        card: CARD_DF_SUMMARY,
      });
    },
    []
  );

  const handleDownloadDFIncome: BLClickHandler<typeof ACTIONS.DF_PAYROLL_INCOME_REPORT> =
    useCallback((e, data) => {
      props.runSequence({
        sequence: "DoctorFeeSummary",
        action: data.name,
        card: CARD_DF_SUMMARY,
      });
    }, []);

  const handlePrintDF: BLClickHandler<typeof ACTIONS.DF_PAYROLL_REPORT_PDF> = useCallback(
    (e, data) => {
      props.runSequence({
        sequence: "DoctorFeeSummary",
        action: data.name,
        card: CARD_DF_SUMMARY,
      });
    },
    []
  );

  const handleCloseModEditDF = useCallback(() => {
    props.setProp("DoctorFeeSummarySequence.dfPayrollDetail", null);
  }, []);

  const handleSaveDFPayrollGroup = useCallback(() => {
    props.runSequence({
      sequence: "DoctorFeeSummary",
      action: ACTIONS.SAVE_DF_PAYROLL_GROUP,
      card: CARD_DF_SUMMARY,
    });
  }, []);

  const handleCreate: BLClickHandler<typeof ACTIONS.CREATE> = useCallback((e, data) => {
    props.runSequence({ sequence: "DoctorFeeSummary", action: data.name, card: CARD_DF_SUMMARY });
  }, []);

  const handleCloseModCreate = useCallback(() => {
    props.setProp(`successMessage.${BTN_ACTS.CREATE}`, null);
  }, []);

  const handleCloseErrMsg = useCallback(() => {
    const error = "show" in errorMessage ? { ...errorMessage, show: false } : null;

    props.setProp(`errorMessage.${CARD_DF_SUMMARY}`, error);
  }, [errorMessage]);

  const handlePrintPaymentSlip: BLClickHandler<typeof ACTIONS.PRINT_PAYMENT_SLIP> = useCallback(
    (e, data) => {
      props.runSequence({
        sequence: "DoctorFeeSummary",
        action: data.name,
        card: CARD_DF_SUMMARY,
      });
    },
    []
  );

  // Memo
  const totalItems = useMemo(() => {
    const dfSummary = DF_SUMMARY[activeTab];
    const seq = props.DoctorFeeSummarySequence;

    const entries = Object.entries(dfSummary.totals) as [TotalKeys, string][];

    return entries.map(([key, value]) => {
      const data = seq?.[dfSummary.data] as (DFPayrollDraftType & DFPayrollListType) | undefined;

      return {
        key,
        label: value,
        value: data?.summary[key] ?? "",
      };
    });
  }, [
    activeTab,
    props.DoctorFeeSummarySequence?.dfPayrollDraft,
    props.DoctorFeeSummarySequence?.dfPayrollFee,
    props.DoctorFeeSummarySequence?.dfPayrollSummary,
  ]);

  const doctorOptions = useMemo(
    () => [{ key: 1, text: "แพทย์ทั้งหมด", value: "ALL" }, ...(props.masterOptions?.doctor || [])],
    [props.masterOptions]
  );

  const tableDetail = useMemo(() => {
    const items = props.DoctorFeeSummarySequence?.[DF_SUMMARY[activeTab].data]?.items || [];

    return {
      data: DF_SUMMARY[activeTab],
      items: items.map((item, index) => {
        const bankBranchName: string =
          "bank_branch_name" in item ? item.bank_branch_name : `${item.bank} ${item.bank_branch}`;

        return {
          ...item,
          ...formatFieldsPrice(
            item,
            `${CREATE_PRICE_FIELDS},${SUMMARY_PRICE_FIELDS},${FEE_PRICE_FIELDS}`
          ),
          action: (
            <div style={{ display: "grid", placeContent: "center" }}>
              <Button
                color="yellow"
                icon="edit"
                size="small"
                style={styles.button}
                onClick={handleEdit(item)}
              />
            </div>
          ),
          bank_branch_name: bankBranchName.replaceAll(" ", "\n"),
          seq: item.seq ?? index + 1,
        };
      }),
    };
  }, [
    activeTab,
    props.DoctorFeeSummarySequence?.dfPayrollDraft,
    props.DoctorFeeSummarySequence?.dfPayrollFee,
    props.DoctorFeeSummarySequence?.dfPayrollSummary,
  ]);

  const renderSummary = useMemo(
    () =>
      totalItems.map((item) => {
        const { key, label } = item;
        const isPriceFormat = Object.keys(CREATE_TOTALS).includes(key) && key !== "doctor_count";

        let { value } = item;

        if (isPriceFormat) {
          value = formatPrice(value);
        }

        return (
          <FormField key={`total${key}`} width={2}>
            <label style={{ fontSize: "0.85rem", margin: 0 }}>{label || "\u00A0"}</label>
            {!key.includes("hidden") && (
              <Label size="large" style={styles.label}>
                {value || "\u00A0"}
              </Label>
            )}
          </FormField>
        );
      }),
    [totalItems]
  );

  const buttonCreate = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"green"}
        disabled={!(filterCreate?.startDate && filterCreate.endDate)}
        name={ACTIONS.CREATE}
        paramKey={BTN_ACTS.CREATE}
        title={intl.formatMessage({ id: "สร้างเลขงวด" })}
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.CREATE]}
        // callback
        onClick={handleCreate}
      />
    ),
    [filterCreate, handleCreate, props.buttonLoadCheck]
  );

  const buttonDownloadDF = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"orange"}
        disabled={tableDetail.items.length === 0}
        name={ACTIONS.DF_PAYROLL_REPORT}
        paramKey={BTN_ACTS.DF_PAYROLL_REPORT}
        title={intl.formatMessage({ id: "ดาวน์โหลด xlsx." })}
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DF_PAYROLL_REPORT]}
        // callback
        onClick={handleDownloadDF}
      />
    ),
    [handleDownloadDF, props.buttonLoadCheck, tableDetail.items]
  );

  const buttonDownloadDFIncome = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"orange"}
        disabled={tableDetail.items.length === 0}
        name={ACTIONS.DF_PAYROLL_INCOME_REPORT}
        paramKey={BTN_ACTS.DF_PAYROLL_INCOME_REPORT}
        title={intl.formatMessage({ id: "ดาวน์โหลด xlsx." })}
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DF_PAYROLL_INCOME_REPORT]}
        // callback
        onClick={handleDownloadDFIncome}
      />
    ),
    [handleDownloadDFIncome, props.buttonLoadCheck, tableDetail.items]
  );

  const buttonPrintPaymentSlip = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"blue"}
        name={ACTIONS.PRINT_PAYMENT_SLIP}
        paramKey={BTN_ACTS.PRINT_PAYMENT_SLIP}
        title={intl.formatMessage({ id: "พิมพ์ใบจ่ายเงิน" })}
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.PRINT_PAYMENT_SLIP]}
        // callback
        onClick={handlePrintPaymentSlip}
      />
    ),
    [handlePrintPaymentSlip, props.buttonLoadCheck]
  );

  const buttonPrintSummary = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"blue"}
        disabled={tableDetail.items.length === 0}
        name={ACTIONS.DF_PAYROLL_REPORT_PDF}
        paramKey={BTN_ACTS.DF_PAYROLL_REPORT_PDF}
        title={intl.formatMessage({ id: "พิมพ์รายงาน" })}
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DF_PAYROLL_REPORT_PDF]}
        // callback
        onClick={handlePrintDF}
      />
    ),
    [handlePrintDF, props.buttonLoadCheck, tableDetail.items]
  );

  const buttonSave = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={filterSummary?.dfPayrollGroup?.pay_to_bank_branch ? "yellow" : "green"}
        disabled={!filterSummary?.dfPayrollGroup?.id}
        name={ACTIONS.SAVE_DF_PAYROLL_GROUP}
        paramKey={BTN_ACTS.SAVE_DF_PAYROLL_GROUP}
        title={filterSummary?.dfPayrollGroup?.pay_to_bank_branch ? "บันทึกและแก้ไข" : "บันทึก"}
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SAVE_DF_PAYROLL_GROUP]}
        // callback
        onClick={handleSaveDFPayrollGroup}
      />
    ),
    [filterSummary?.dfPayrollGroup, handleSaveDFPayrollGroup, props.buttonLoadCheck]
  );

  const buttonSearch = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"blue"}
        disabled={!allowSearch}
        name={ACTIONS.SEARCH}
        paramKey={BTN_ACTS.SEARCH}
        size="small"
        title={intl.formatMessage({ id: "ค้นหา" })}
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEARCH]}
        // callback
        onClick={handleSearch}
      />
    ),
    [allowSearch, handleSearch, props.buttonLoadCheck]
  );

  const dfPayrollGroupFeeSearch = useMemo(
    () => (
      <CardDFPayrollGroupSearchBox
        setProp={props.setProp}
        defaultId={filterFee?.dfPayrollGroup?.id}
        // data
        items={props.DoctorFeeSummarySequence?.dfPayrollGroupList}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        // callback
        onSearch={handleSearchDFPayrollGroup}
        onSelected={handleSelectedLotNo("filterFee")}
        languageUX={props.languageUX}
      />
    ),
    [
      filterFee?.dfPayrollGroup,
      handleSearchDFPayrollGroup,
      handleSelectedLotNo,
      props.buttonLoadCheck,
      props.DoctorFeeSummarySequence?.dfPayrollGroupList,
    ]
  );

  const dfPayrollGroupSummarySearch = useMemo(
    () => (
      <CardDFPayrollGroupSearchBox
        setProp={props.setProp}
        defaultId={filterSummary?.dfPayrollGroup?.id}
        // data
        items={props.DoctorFeeSummarySequence?.dfPayrollGroupList}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        // callback
        onSearch={handleSearchDFPayrollGroup}
        onSelected={handleSelectedLotNo("filterSummary")}
        languageUX={props.languageUX}
      />
    ),
    [
      filterSummary?.dfPayrollGroup,
      handleSearchDFPayrollGroup,
      handleSelectedLotNo,
      props.buttonLoadCheck,
      props.DoctorFeeSummarySequence?.dfPayrollGroupList,
    ]
  );

  const examinationOptions = useMemo(
    () => {
      return [{ key: 1, text: "ทุกประเภท", value: "ALL" }, ...(props.masterOptions?.examinationTypeName || [])]
    },
    [props.masterOptions]
  );


  // #console.log("CardDoctorFeeSummary", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={errorMessage?.error}
        success={null}
        onClose={handleCloseErrMsg}
        languageUX={props.languageUX}
      />

      <CardDoctorFeeSummaryUX
        // data
        activeTab={activeTab}
        // Element
        buttonCreate={buttonCreate}
        buttonDownloadDF={buttonDownloadDF}
        buttonDownloadDFIncome={buttonDownloadDFIncome}
        buttonPrintPaymentSlip={buttonPrintPaymentSlip}
        buttonPrintSummary={buttonPrintSummary}
        buttonSave={buttonSave}
        buttonSearch={buttonSearch}
        data={tableDetail.items}
        dfPayrollGroupFeeSearch={dfPayrollGroupFeeSearch}
        dfPayrollGroupSummarySearch={dfPayrollGroupSummarySearch}
        doctorLoading={props.masterData.doctorLoading}
        examinationTypeLoading={props.masterData.examinationTypeNameLoading}
        filterCreate={filterCreate}
        filterFee={filterFee}
        filterSummary={filterSummary}
        showRequiredField={errorMessage.showRequiredField}
        summaryElement={renderSummary}
        // table
        table={tableDetail.data}
        // options
        bankBranchOptions={props.masterOptions?.bankBranch}
        doctorOptions={doctorOptions}
        examinationTypeOptions={examinationOptions}
        // callback
        onChangeFilter={handleChangeFilter}
        onChangeTab={handleChangeTab}
        onGetTdProps={handleGetTdProps}
        languageUX={props.languageUX}
      />

      <ModEditDoctorFee
        onEvent={props.onEvent}
        setProp={props.setProp}
        data={props.DoctorFeeSummarySequence?.dfPayrollDetail}
        open={!!props.DoctorFeeSummarySequence?.dfPayrollDetail}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
        // callback
        onClose={handleCloseModEditDF}
        onSave={handleSaveDFPayroll}
      />

      <ModInfo
        open={props.successMessage?.[BTN_ACTS.CREATE]}
        titleColor={"green"}
        titleName={intl.formatMessage({ id: "สร้างเลขงวดสำเร็จ" })}
        onApprove={handleCloseModCreate}
        onClose={handleCloseModCreate}
      >
        <div style={{ fontWeight: "bold", padding: "1rem 0" }}>
          <div>เลขงวด : {props.successMessage?.[BTN_ACTS.CREATE]?.group?.lot_no || "-"}</div>
        </div>
      </ModInfo>
    </div>
  );
};

CardDoctorFeeSummary.displayName = "CardDoctorFeeSummary";

export default React.memo(CardDoctorFeeSummary);
