import React from 'react';
import PropTypes from 'prop-types';
import CardLayout from '../common/CardLayout';
import {
  Modal,
  Button,
  Form
} from 'semantic-ui-react';
import PureReactTable from '../common/PureReactTable';
import SummaryPrice from './SummaryPrice';

class ModDownloadPreviousSendClaimFile extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array,
    isLoading: PropTypes.bool,
    header: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDownloadSendCalimFile: PropTypes.func,
    onSummary: PropTypes.func,
    modalStyle: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.gridColumns = [
      {
        Header: 'งวดส่ง',
        accessor: 'sent_no',
        width: 250
      },
      {
        Header: 'วันเวลาส่ง',
        accessor: 'sent_at_datetime',
        width: 250
      },
      {
        Header: 'ผู้ส่ง',
        accessor: 'sent_by_name',
        width: 250
      },
      {
        Header: 'Zip File Name',
        accessor: 'zip_file_name',
      },
      {
        Header: 'สถานะการส่งเบิก',
        accessor: 'status_name',
        width: 200
      },
      {
        Header: '',
        accessor: '',
        width: 150,
        Cell: row => (
          <div style={{ textAlign: 'center' }} >
            <Button
              style={{ display: 'inline-block' }}
              compact
              size='mini'
              color='violet'
              onClick={() => {
                this.props.onSummary(row.original.id, row.original.sent_no)
              }}
            >
              ดูยอดรวม
                </Button>
          </div>
        )
      },
      {
        Header: '',
        accessor: '',
        width: 150,
        Cell: (row) => (
          <Button
            compact
            size='mini'
            color='green'
            onClick={() => {
              this.props.onDownloadSendCalimFile(row.original.id);
            }}
          >
            Download
          </Button>
        )
      },
    ];
  }

  render() {
    const {
      open,
      header,
      onClose,
      items,
      isLoading,
      summaryOpen,
      summaryHeader,
      totalRows,
      totalPrice,
      totalSentClaim,
      totalPaid,
      totalOtherPay,
      totalPriceBillItemMode_2,
      totalSentClaimPriceBillItemMode_2,
      totalPriceBillItemMode_3_5,
      totalSentClaimPriceBillItemMode_3_5,
      totalPriceBillItemModeRemain,
      totalSentClaimPriceBillItemModeRemain,
      totalPriceDispensing,
      totalSentClaimPriceDispensing,
      totalPriceOperationService,
      onSummaryClose,
      isCalulating,
      modalStyle,
    } = this.props;

    return (
      <Modal
        open={open}
        size="fullscreen"
        style={modalStyle}
        onClose={onClose}
        // style={{ marginTop: 0 }} // dont push
      >
        <CardLayout
          titleText={header}
          headerColor="yellow"
          onClose={onClose}
          loading={isLoading}
        >
          <PureReactTable
            manual={false}
            data={items}
            columns={this.gridColumns}
          />
          <Modal
            open={summaryOpen}
            size="fullscreen"
            style={modalStyle}
            onClose={onSummaryClose}
            // style={{ marginTop: 0 }} // dont push
          >
            <CardLayout
              titleText={summaryHeader}
              onClose={onSummaryClose}
              headerColor="purple"
              toggleable={false}
            >
              <Form>
                <SummaryPrice
                  totalRows={totalRows}
                  totalPrice={totalPrice}
                  totalSentClaim={totalSentClaim}
                  totalPaid={totalPaid}
                  totalOtherPay={totalOtherPay}
                  totalPriceBillItemMode_2={totalPriceBillItemMode_2}
                  totalSentClaimPriceBillItemMode_2={
                    totalSentClaimPriceBillItemMode_2
                  }
                  totalPriceBillItemMode_3_5={totalPriceBillItemMode_3_5}
                  totalSentClaimPriceBillItemMode_3_5={
                    totalSentClaimPriceBillItemMode_3_5
                  }
                  totalPriceBillItemModeRemain={totalPriceBillItemModeRemain}
                  totalSentClaimPriceBillItemModeRemain={
                    totalSentClaimPriceBillItemModeRemain
                  }
                  totalPriceDispensing={totalPriceDispensing}
                  totalSentClaimPriceDispensing={totalSentClaimPriceDispensing}
                  totalPriceOperationService={totalPriceOperationService}
                  isCalulating={isCalulating}
                />
              </Form>
            </CardLayout>
          </Modal>
        </CardLayout>
      </Modal>
    );
  }
}

export default ModDownloadPreviousSendClaimFile;
