import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { formatDate } from "react-lib/utils";
import CardBillingEncountersUX from "./CardBillingEncountersUX";
import { useIntl } from "react-intl";

const CardBillingEncounters = (props: any) => {
  const intl = useIntl();
  useEffect(() => {
    props.runSequence({
      sequence: "BillingHistory",
      action: "get_encounters",
    });
  }, []);

  const checkNote = (expired: any, payment: any, order: any) => {
    if (expired === true && payment === "READY" && order === "PERFORMED") {
      return <div style={{ color: "red" }}>{intl.formatMessage({ id: "ค้างชำระ" })}</div>;
    } else if (payment === "READY" && order === "PENDING") {
      return <div>{intl.formatMessage({ id: "รอชำระเงิน" })}</div>;
    } else if (payment === "PENDING" && order === "PERFORMED") {
      return <div>{intl.formatMessage({ id: "รอสรุปค่าใช้จ่าย" })}</div>;
    } else if (payment === "PENDING" && order === "PENDING") {
      return <div>{intl.formatMessage({ id: "รอหน่วยบริการ" })}</div>;
    } else if (payment === "PAID" && order === "PENDING") {
      return <div style={{ color: "orange" }}>{intl.formatMessage({ id: "รอรับบริการ" })}</div>;
    } else {
      return <div></div>;
    }
  };

  const encounterListData = useMemo(() => {
    return (props?.BillingHistorySequence?.encounterList?.items || []).map(
      (item: any, index: number) => ({
        ...item,
      })
    );
  }, [props?.BillingHistorySequence?.encounterList]);

  const doctorOrderData = useMemo(() => {
    return (props?.BillingHistorySequence?.doctorOrder?.items || []).map((item: any) => ({
      ...item,
      order_time: (
        <div>
          {formatDate(moment(item?.order_time))} [{moment(item?.order_time).format("HH:mm")}]
        </div>
      ),
      note: (
        <div>{checkNote(item?.is_expired, item?.payment_status_name, item?.order_status_name)}</div>
      ),
    }));
  }, [props?.BillingHistorySequence?.doctorOrder]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CardBillingEncountersUX
        // style={{ zoom: "90%" }}
        doctorOrderData={doctorOrderData}
        encounterListData={encounterListData}
        onRefresh={() => {
          props.runSequence({
            sequence: "BillingHistory",
            action: "get_encounters",
          });
        }}
        languageUX={props.languageUX}
      />
    </div>
  );
};

export default CardBillingEncounters;
