import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Dropdown,
  Label,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDrugResuscitationReturnUX = (props: any) => {
    return(
      <div
        style={{margin: "10px", backgroundColor: "#F3F3F3", height:"90%"}}>
        <div
          style={{display: "flex", alignItems: "center", padding: "14px"}}>
          
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            เลขที่ใบจัดกล่อง
          </label>
          <Input
            icon="search"
            name="filterBox.code"
            onChange={props.handleChangeData}
            style={{width:"140px"}}
            value={props.DrugResuscitationSequence?.filterBox?.code || ""}>
          </Input>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            วันที่แจ้ง
          </label>
          <DateTextBox
            onChange={props.handleChangeStartDate}
            value={props.DrugResuscitationSequence?.filterBox?.returned_start || ""}>
          </DateTextBox>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            ถึง
          </label>
          <DateTextBox
            onChange={props.handleChangeEndDate}
            value={props.DrugResuscitationSequence?.filterBox?.returned_end || ""}>
          </DateTextBox>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            หน่วยงานที่คืน
          </label>
          <Dropdown
            clearable={true}
            onChange={props.handleChangeValue}
            options={props?.divisionOptions}
            search={true}
            selection={true}
            value={props.DrugResuscitationSequence?.destinationDivision}>
          </Dropdown>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            สถานะ
          </label>
          <Dropdown
            clearable={true}
            name="filterBox.status"
            onChange={props.handleChangeData}
            options={props.statusTypeItems || []}
            search={true}
            selection={true}
            style={{width:"140px", margin: "0px 10px 0px 0px"}}
            value={props.DrugResuscitationSequence?.filterBox?.status || ""}>
          </Dropdown>
          <div>
            {props.buttonSearchBox}
          </div>
          <div
            style={{flex:1}}>
            
          </div>
        </div>
        <div
          style={{ padding: "15px" }}>
          
          <Table
            data={props?.boxItems}
            getTrProps={props.boxTableRowProps}
            headers="วันที่แจ้งขอคืน,เลขที่ใบจัดกล่อง,รายการยาในกล่อง,แผนกที่ขอคืน,อนุมัติ,สถานะ"
            keys="returned_datetime_div,code,drugs_str,destination_division_name,approved_datetime,status_name"
            showPagination={false}
            style={{fontSize: "medium", height: "20vh"}}>
          </Table>
        </div>
        <div
          style={{ padding: "15px" }}>
          
          <div
            style={{border:"1px solid #ccc", borderRadius: "8px", padding: "0px 0px 4px 0px"}}>
            
            <div
              style={{display: "flex", alignItems: "center", margin: "14px 0px"}}>
              
              <div
                className="header"
                style={{margin: "0px 10px 0px 14px", fontWeight: "bold", fontSize: "16px"}}>
                คืนกล่องยาฉุกเฉินกลับเข้าห้องยา
              </div>
              <Label
                style={{  ...(!props.DrugResuscitationSequence?.selectedBox && {display: "none"}) }}>
                {props.drugResuscitationLabel}
              </Label>
            </div>
            <div>
              
              <hr>
              </hr>
            </div>
            <div
              style={{display: "flex", alignItems: "center", padding: "14px"}}>
              
              <label
                style={{width:"auto",fontWeight: "bold", fontSize: "14px", marginRight: "10px"}}>
                ผู้ขอคืน
              </label>
              <Input
                readOnly="true"
                style={{width:"400px"}}
                value={props.DrugResuscitationSequence?.selectedBox?.destination_division_name || ""}>
              </Input>
              <label
                style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
                วันที่และเวลา
              </label>
              <Input
                readOnly="true"
                style={{width:"400px"}}
                value={props.DrugResuscitationSequence?.selectedBox?.returned_datetime_str || ""}>
              </Input>
            </div>
            <div
              style={{ padding: "10px" }}>
              
              <Table
                data={props.drugItems}
                headers="ชื่อยา,จำนวนยาทั้งหมดในกล่อง,Used Quantity,จำนวนยาที่เหลือในกล่อง,จำนวนรับคืน,Stock Unit,วันหมดอายุ"
                keys="generic_name,total_quantity,used,quantity,return_quantity,default_dosage_unit_name,expired"
                showPagination={false}
                style={{fontSize: "medium", height: "25vh"}}>
              </Table>
            </div>
            <div
              style={{display: "flex", alignItems: "center", margin: "14px 0px"}}>
              
              <div
                style={{flex:1}}>
                
              </div>
              <div>
                {props.buttonEdit}
              </div>
              <div>
                {props.buttonApprove}
              </div>
              <div>
                {props.buttonReplace}
              </div>
              <Button
                color="red"
                onClick={props.rejectClick}
                size="small"
                style={{display: props.showRejectBox ? "flex" : "none", margin: "0px 4px 0px 0px"}}>
                Reject
              </Button>
              <Button
                color="red"
                size="small">
                ยกเลิก
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardDrugResuscitationReturnUX

export const screenPropsDefault = {}

/* Date Time : Tue Sep 19 2023 11:52:28 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", backgroundColor: \"#F3F3F3\", height:\"90%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", padding: \"14px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Input",
      "parent": 8,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "filterBox.code"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"140px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.code || \"\""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"15px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 14,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.boxItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.boxTableRowProps"
        },
        "headers": {
          "type": "value",
          "value": "วันที่แจ้งขอคืน,เลขที่ใบจัดกล่อง,รายการยาในกล่อง,แผนกที่ขอคืน,อนุมัติ,สถานะ"
        },
        "keys": {
          "type": "value",
          "value": "returned_datetime_div,code,drugs_str,destination_division_name,approved_datetime,status_name"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"medium\", height: \"20vh\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"15px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบจัดกล่อง"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่แจ้ง"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "filterBox.status"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.statusTypeItems || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"140px\", margin: \"0px 10px 0px 0px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.status || \"\""
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border:\"1px solid #ccc\", borderRadius: \"8px\", padding: \"0px 0px 4px 0px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนกล่องยาฉุกเฉินกลับเข้าห้องยา"
        },
        "className": {
          "type": "value",
          "value": "header"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 14px\", fontWeight: \"bold\", fontSize: \"16px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Label",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugResuscitationLabel"
        },
        "style": {
          "type": "code",
          "value": "{  ...(!props.DrugResuscitationSequence?.selectedBox && {display: \"none\"}) }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "hr",
      "parent": 32,
      "props": {
      },
      "seq": 33,
      "void": true
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 35,
      "name": "Table",
      "parent": 34,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugItems"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อยา,จำนวนยาทั้งหมดในกล่อง,Used Quantity,จำนวนยาที่เหลือในกล่อง,จำนวนรับคืน,Stock Unit,วันหมดอายุ"
        },
        "keys": {
          "type": "value",
          "value": "generic_name,total_quantity,used,quantity,return_quantity,default_dosage_unit_name,expired"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"medium\", height: \"25vh\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", padding: \"14px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ขอคืน"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", marginRight: \"10px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Input",
      "parent": 36,
      "props": {
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"400px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.destination_division_name || \"\""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"14px 0px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่และเวลา"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Input",
      "parent": 36,
      "props": {
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"400px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.returned_datetime_str || \"\""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"14px 0px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Button",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Reject"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.rejectClick"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showRejectBox ? \"flex\" : \"none\", margin: \"0px 4px 0px 0px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Button",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานที่คืน"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props?.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.destinationDivision"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonApprove"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReplace"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 53,
      "name": "DateTextBox",
      "parent": 8,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.returned_start || \"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 54,
      "name": "DateTextBox",
      "parent": 8,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.returned_end || \"\""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearchBox"
        }
      },
      "seq": 54,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugResuscitationReturnUx",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
