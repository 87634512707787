import BaseService from "./BaseService";
import { TEC } from "../../configs/apis";
export default class TECService extends BaseService {
  putArriveTRTOrder(treatmentOrderId, data) {
    const url = TEC.ARRIVE_TRT_ORDER({
      treatment_order_id: treatmentOrderId
    });
    return this.client
      .put(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getTECSession(encounterId) {
    const url = TEC.GET_SESSION_FROM_ENCOUNTER({
      encounterId: encounterId
    });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
