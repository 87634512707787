import React, { useCallback } from "react";

import { toast } from "react-toastify";

import NotificationContainer, { NotificationItem } from "../common/NotificationContainer";

import SubBillLockNotiMessage, { BillLockNotiMessageItem } from "./SubBillLockNotiMessage";

// Types
type CardBillLockNotiMessageProps = {
  onEvent: (e: any) => void;
  setProp: (key: string, value: any, callback?: () => void) => any;
  notificationMessageList?: NotificationItem[];
  tabPatientRef?: { changeTabKey: (tab: string, callback: () => void) => void };
};

const CardBillLockNotiMessage = (props: CardBillLockNotiMessageProps) => {
  const handleOpenPendingInvoice = useCallback(
    (data: BillLockNotiMessageItem) => () => {
      props.tabPatientRef?.changeTabKey("Encounter", () => {
        props.setProp("initialLayout", 1);

        props.onEvent({
          message: "BillingQueueAction",
          params: {
            action: "select_encounter",
            encounterId: data.encounter,
          },
        });

        toast.dismiss(data.time);

        handleSetNotifications(
          (props.notificationMessageList || []).filter((n) => n.id !== data.id)
        );
      });
    },
    [props.notificationMessageList, props.tabPatientRef]
  );

  const handleSetNotifications = useCallback((notifications: BillLockNotiMessageItem[]) => {
    props.setProp("billLockNotiMessageList", notifications);
  }, []);

  const renderCard = useCallback(
    (cardProps: BillLockNotiMessageItem) => (
      <SubBillLockNotiMessage
        {...cardProps}
        onOpenPendingInvoice={handleOpenPendingInvoice(cardProps)}
      />
    ),
    [handleOpenPendingInvoice]
  );

  return (
    <NotificationContainer
      notifications={props.notificationMessageList}
      position={toast.POSITION.BOTTOM_RIGHT}
      renderCard={renderCard}
      noWidth
      setNotifications={handleSetNotifications}
    />
  );
};

export default React.memo(CardBillLockNotiMessage);
