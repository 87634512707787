import React from "react";

// UX
import CardSkinPreparationUX from "./CardSkinPreparationUX";
import {
  ACTION,
  checkHideButton,
} from "./CardOtherForm";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";

export const SKIN_PREPARATION = "skinPreparation";
const CARD_SKIN_PREPARATION = "CardSkinPreparation";

const CardSkinPreparation = (props: any) => {
  const intl = useIntl();
  const handleSave = (action: string) => (e: any, v: any) => {
    props.onSave(action)(e, {
      ...v,
      formCode: CARD_SKIN_PREPARATION,
      formName: "Skin Preparation",
      key: SKIN_PREPARATION,
    });
  };

  const handleAddVaginaOptions = (e: any, { value }: any) => {
    props.setProp("masterOptions.orVagina", [
      { text: value, value: value, key: value },
      ...props.masterOptions?.orVagina,
    ]);
  };

  return (
    <>
      <CardSkinPreparationUX
        // data
        status={props.data?.status}
        isSurgicalSkinScrub={props.data?.data?.is_surgical_skin_scrub}
        isSurgicalSite={props.data?.data?.is_surgical_site}
        isVagina={props.data?.data?.is_vagina}
        vagina={props.data?.data?.vagina}
        handleAddVaginaOptions={handleAddVaginaOptions}
        woundClassification={props.data?.data?.wound_classification}
        nurseDiagnosis={props.data?.data?.nursing_diagnosis}
        goal={props.data?.data?.goal}
        planAndImplementation={props.data?.data?.plan_and_implementation}
        evaluation={props.data?.data?.evaluation}
        evaluationCheck={props.data?.data?.evaluation_check}
        evaluationDetail={props.data?.data?.evaluation_detail}
        // options
        orVaginaOptions={props.masterOptions?.orVagina}
        // callback
        onChangeData={props.onChangeData}
        // config
        config={{
          disabledVagina: !props.data?.data?.is_vagina,
          hideStatus: checkHideButton(props.data, "STATUS"),
        }}
        // Element
        ButtonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [SKIN_PREPARATION]: props.data }}
            type="save"
            cardKey={SKIN_PREPARATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.SAVE)}
          />
        }
        ButtonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [SKIN_PREPARATION]: props.data }}
            type="confirm"
            cardKey={SKIN_PREPARATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.CONFIRM)}
          />
        }
        ButtonUnConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [SKIN_PREPARATION]: props.data }}
            type="unconfirm"
            cardKey={SKIN_PREPARATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.UNCONFIRM)}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

export default React.memo(CardSkinPreparation);
