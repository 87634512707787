import React from "react";
import PropTypes from "prop-types";
import { Modal, Header } from "semantic-ui-react";
import ReactTable from 'react-table-6';
import { useIntl } from "react-intl";

const ModalADRInfo = ({ data, open, onClose, useShortNote }: any) => {
  const intl = useIntl();

  const handleOnClose = (e: any, value: any) => {
    onClose();
  };

  var columns = []
  let shortNote = [{
    Header: 'Note',
    accessor: 'adr_short_info', // String-based value accessors!
    style: { 'whiteSpace': 'unset' },
    minWidth: 100,
  }, {
    Header: 'ประเภท',
    accessor: 'type_name',
    style: { 'whiteSpace': 'unset' },
    minWidth: 50,

  }, {
    id: 'ชื่อสารที่แพ้', // Required because our accessor is not a string
    Header: 'ชื่อสารที่แพ้',
    style: { 'whiteSpace': 'unset' },
    accessor: 'name',
    Cell: (props: any) => <span style={{ color: "red" }}>{props.value}</span>,
    minWidth: 100,
  }, {

    Header: 'ความรุนแรง',
    accessor: 'severity_name',
    style: { 'whiteSpace': 'unset' },
    minWidth: 50
  }]

  let normalNote = [
    {
      Header: 'ความรุนแรง',
      accessor: 'severity_name',
      style: { 'whiteSpace': 'unset' },
      minWidth: 50
    },
    {
      Header: 'ประเภท',
      accessor: 'type_name',
      style: { 'whiteSpace': 'unset' },
      minWidth: 50,

    }, {
      id: 'ชื่อสารที่แพ้', // Required because our accessor is not a string
      Header: 'ชื่อสารที่แพ้',
      style: { 'whiteSpace': 'unset' },
      accessor: 'name',
      Cell: (props: any) => <span style={{ color: "red" }}>{props.value}</span>,
      minWidth: 120,
    },
    {
      Header: 'Note',
      accessor: 'note', // String-based value accessors!
      style: { 'whiteSpace': 'unset' },
      minWidth: 370,
    }]

  if (useShortNote) {
    columns = shortNote
  } else {
    columns = normalNote
  }

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      size={useShortNote ? "small" : "large"}
      closeIcon
    >

      <Header content='ADR Information' color='pink' />
      <Modal.Content>
        <ReactTable
          data={data.items ? data.items : data}
          columns={columns}
          minRows={3}
          getNoDataProps={() => {
            return { style: { display: "none" } };
          }}
          showPagination={false}
        />
      </Modal.Content>
    </Modal>
  )
};

ModalADRInfo.defaultProps = {
  data: [],
  open: false,
  onClose: (() => { }),
  useShortNote: true,
}

ModalADRInfo.propsTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  useShortNote: PropTypes.bool,
};

export default ModalADRInfo;
