import React from 'react'

const DotColor = (props: any) => {
  return (
    <> <span style={{  height: "25px",
      width: "25px",
      backgroundColor: props.color || "#bbb",
      borderRadius: "50%",
      display: "inline-block"}}> </span></>
  )
}

export default DotColor;
