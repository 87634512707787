import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";
import ReactTable from "react-table-6";
import CardCSDivisionSearch from "./CardCSDivisionSearch";
import CardCSDoctorSearch from "./CardCSDoctorSearch";
import { Pagination, PaginationProps } from "semantic-ui-react";
import { useIntl } from "react-intl";

interface CardCSProcedureDetailProps {
  allDivisionList: any;
  allDoctorList: any;
  csProcedureDetailList: any;
  patientDataAPI: any;
  handleGetCSProcedureDetail: any;
}

const CardCSProcedureDetail = forwardRef(
  (props: CardCSProcedureDetailProps, ref) => {
    const isMounted = useRef(true);
    const [doctorSearch, setDoctorSearch] = useState("");
    const [divisionSearch, setDivisionSearch] = useState("");
    const [isCSProcedureLoading, setIsProcedureLoading] = useState(false);
    const [isCSDivisionSearchLoading, setIsCSDivisionSearchLoading] = useState(false);
    const [isCSDoctorSearchLoading, setIsCSDoctorSearchLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);

    const limit = 6;

    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      const loadProcedure = async () => {
        setIsProcedureLoading(true);
        await props.handleGetCSProcedureDetail();
        setIsProcedureLoading(false);
      };

      loadProcedure();
    }, [doctorSearch, divisionSearch, props.patientDataAPI, activePage]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          doctor: doctorSearch,
          division: divisionSearch,
          limit: limit,
          offset: activePage - 1,
        };
      },
    }));
    const columns = [
      {
        Header: "Procedure Details",
        accessor: "icd9_detail", // String-based value accessors!
        resizable: false,
      },
      {
        // Header: "Doctor/Division",
        accessor: "chief_surgeon",
        resizable: false,
        width: 140,
        Cell: props => (<><div className="division-label">{props.original.division}</div>{props.original.chief_surgeon}</>),
      },
      {
        accessor: "start_date",
        resizable: false,
        width: 90,
        Cell: props => <div style={{ textAlign: "center" }}>{props.value}</div>
      },
    ];

    const totalPages = Math.ceil(props.csProcedureDetailList?.total / limit);
    return (
      <div
        className="card-cs card-procedure-detail"
      >
        <div className="card-cs-header">
          {/* <div style={{ width: "200px" }}></div> */}
          {/* <div className="card-cs-header-title">Division</div> */}
          <CardCSDivisionSearch
            disabled={isCSProcedureLoading}
            allDivisionList={props.allDivisionList}
            setDivisionSearch={(search) => {
              setDivisionSearch(search)
              setActivePage(1)
            }}
            isLoading={isCSDivisionSearchLoading}
            languageUX={props.languageUX}
          />
          {/* <div className="card-cs-header-title">Doctor</div> */}
          <CardCSDoctorSearch
            disabled={isCSProcedureLoading}
            allDoctorList={props.allDoctorList}
            setDoctorSearch={(search) => {
              setDoctorSearch(search)
              setActivePage(1)
            }}
            isLoading={isCSDoctorSearchLoading}
            languageUX={props.languageUX}
          />
        </div>
        <div className="card-cs-header-title">Procedure Details</div>
        <div className="card-wrap-cs-table">
          <ReactTable
            loading={isCSProcedureLoading}
            className="card-cs-table-none-border"
            columns={columns}
            data={props.csProcedureDetailList?.items?.length > 0 ? props.csProcedureDetailList?.items : []}
            showPagination={false}
            minRows={5}
            style={{
              height: "100%",
            }}
            NoDataComponent={() => null}
            getTheadThProps={(state, rowInfo, column, instance) => {
              // console.log(" getTdProps", state, rowInfo, column, instance)
              return {
                style: {
                  fontSize: "12px",
                  border: "none",
                  boxShadow: "none",
                  textAlign: "left",
                },
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              // console.log(" getTdProps", state, rowInfo, column, instance)

              let style = {
                fontSize: "12px",
                border: "none",
                boxShadow: "none",
                fontWeight: "bolder",
                color: "#4F4F4F",
              };
              if (
                column.Header === "Order Type" ||
                column.Header === "Division"
              ) {
                style = { ...style, ...{ fontWeight: "normal" } };
              }
              return {
                style: style,
              };
            }}
          />
        </div>
        {totalPages > 1 ? (
          <div style={{ textAlign: "center" }}>
            <Pagination
              disabled={!(props.csDiagnosisList?.items?.length > 0)}
              onPageChange={(
                e: React.MouseEvent<HTMLAnchorElement>,
                data: PaginationProps
              ) => {
                setActivePage(data.activePage as number);
              }}
              activePage={activePage}
              firstItem={null}
              lastItem={null}
              ellipsisItem={null}
              pointing
              secondary
              siblingRange={1}
              totalPages={totalPages}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

export default React.memo(CardCSProcedureDetail);
