import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { Button, Form, Icon, Modal, TextArea } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// UI
import CardPatientSearchBox from "../HISV3/TPD/CardPatientSearchBox";
import CardSegmentPatientUX from "./CardSegmentPatientUX";

import { CARD_KEY } from "./CardSegment";
import { useIntl } from "react-intl";

type CardSegmentPatientProps = {
  // function
  onEvent?: any;
  setProp?: any;

  // seq
  runSequence?: any;
  SegmentSequence?: any;

  // controller
  drugOrderQueueController?: any;
};

const CardSegmentPatient = (props: CardSegmentPatientProps) => {
  const intl = useIntl();
  const [findHN, setFindHN] = useState<string>("");
  const [xlsxFile, setXlsxFile] = useState<any>(null);
  const [modRemark, setModRemark] = useState<any>({ open: false });
  const dropzoneRef = useRef() as MutableRefObject<any>;

  // filter HN
  const callSearchPatient = (hn: string) => {
    if (props.SegmentSequence?.selectedSegment?.id && hn) {
      props.runSequence({
        sequence: "Segment",
        action: "SEARCH_PATIENT",
        cardKey: CARD_KEY,
        findHN: hn,
      });
    }
  };

  const handleEnterCallback = (hn: string) => {
    if (hn) {
      callSearchPatient(hn);
    } else {
      handleClearSearch();
    }
  };

  const handleFindHN = (id: any, _hn: any) => {
    if (id) {
      callSearchPatient(findHN);
    }
  };

  const handlePatientSearchboxChange = (hn: string) => {
    setFindHN(hn);
  };

  const handleClearSearch = () => {
    setFindHN("");
    if (props.SegmentSequence?.selectedSegment?.id) {
      props.runSequence({
        sequence: "Segment",
        action: "SEARCH_PATIENT",
        cardKey: CARD_KEY,
        findHN: "",
      });
    }
  };

  // add HN
  const handleSelectedHN = (id: any, hn: any) => {
    props.setProp("SegmentSequence.addHNList", [hn]);
  };

  const handleAddHN = () => {
    props.runSequence({
      sequence: "Segment",
      action: "SAVE_PATIENT",
      cardKey: CARD_KEY,
    });
  };

  const handleBrowseFile = () => {
    if (dropzoneRef?.current) {
      dropzoneRef?.current.open();
    }
  };

  const handleSelectedFile = (accepted, rejected) => {
    const acceptedFile = _.get(accepted, "[0]");
    setXlsxFile(acceptedFile);

    let fileReader = new FileReader();
    fileReader.readAsBinaryString(acceptedFile);
    fileReader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const rowObject = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets[workbook.SheetNames[0]]
      );

      let hn_list: any[] = rowObject.map((item: any) => {
        return item.HN;
      });

      if (hn_list.length === 0) {
        props.setProp(`errorMessage.${CARD_KEY}`, "Patient not found.");
        return;
      }
      props.setProp("SegmentSequence.addHNList", hn_list);
    };
  };

  const s2ab = (s: any) => {
    let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    let view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  };

  const handleDownloadTemplate = () => {
    import("xlsx").then((XLSX) => {
      let workbook = XLSX.utils.book_new();
      workbook.SheetNames.push("Segment");
      const sheet_data = [["HN"]];
      const sheet = XLSX.utils.aoa_to_sheet(sheet_data);
      workbook.Sheets["Segment"] = sheet;

      const output = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      saveAs(
        new Blob([s2ab(output)], { type: "application/octet-stream" }),
        "segment.xlsx"
      );
    });
  };

  const handleImportFile = () => {
    if (!props.SegmentSequence?.selectedSegment?.id) {
      props.setProp(`errorMessage.${CARD_KEY}`, "Segment not found.");
      return;
    }
    handleAddHN();
    setXlsxFile(null);
  };

  // Modal Remark
  const handleCloseModRemark = () => {
    setModRemark({ open: false });
  };

  const handleChangeRemark = (_e: any, data: any) => {
    setModRemark({ ...modRemark, remark: data.value });
  };

  const handleSelectedPatient = (item: any) => {
    setModRemark({ open: true, ...item });
  };

  const handleSaveRemark = () => {
    props.runSequence({
      sequence: "Segment",
      action: "SAVE_REMARK",
      cardKey: CARD_KEY,
      data: modRemark,
    });
    handleCloseModRemark();
  };

  // Patient
  const handleDeletePatient = (item: any) => {
    props.runSequence({
      sequence: "Segment",
      action: "DELETE_PATIENT",
      cardKey: CARD_KEY,
      data: item,
    });
  };

  return (
    <div style={{ width: "100%", padding: "20px 0px" }}>
      <CardSegmentPatientUX
        // function
        onBrowseFile={handleBrowseFile}
        onDownloadTemplate={handleDownloadTemplate}
        onClearSearch={handleClearSearch}
        // data
        file={xlsxFile}
        tableData={
          props.SegmentSequence?.selectedSegment?.id
            ? props.SegmentSequence?.patientList?.items?.map((item: any) => ({
                ...item,
                remark_lbl: (
                  <>
                    {item.remark.trim() === "" ? (
                      <Icon
                        name="comment alternate outline"
                        color="blue"
                        onClick={() => handleSelectedPatient(item)}
                      />
                    ) : (
                      <>
                        <Icon
                          name="comment alternate"
                          color="blue"
                          onClick={() => handleSelectedPatient(item)}
                        />
                        {item.remark}
                      </>
                    )}
                  </>
                ),
                action: (
                  <div style={{ display: "grid", placeContent: "center" }}>
                    <Button
                      icon="minus"
                      color="red"
                      size="mini"
                      onClick={() => handleDeletePatient(item)}
                    />
                  </div>
                ),
              }))
            : []
        }
        limit={props.SegmentSequence?.limit}
        currentPage={props.SegmentSequence?.currentPage}
        totalPage={props.SegmentSequence?.totalPage}
        loading={props.SegmentSequence?.loadingSegmentPatient}
        handlePageChange={(page) => {
          props.runSequence({
            sequence: "Segment",
            action: "SEARCH_PATIENT",
            cardKey: CARD_KEY,
            currentPage: page,
          });
        }}
        handlePageSizeChange={(limit: any) => {
          props.runSequence({
            sequence: "Segment",
            action: "SEARCH_PATIENT",
            cardKey: CARD_KEY,
            limit: limit,
          });
        }}
        // component
        addPatientSearchbox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            modalStyle={{ width: "auto" }}
            onEnterPatientSearch={handleSelectedHN}
            clearHNImput={props.SegmentSequence?.addHNList?.length === 0 ? true : false}
            fluid={true}
            inputStyle={{ width: "100%" }}
          />
        }
        patientSearchbox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            modalStyle={{ width: "auto" }}
            onEnterCallback={handleEnterCallback}
            onEnterPatientSearch={handleFindHN}
            onInputChange={handlePatientSearchboxChange}
            clearHNImput={findHN === "" ? true : false}
          />
        }
        buttonAdd={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleAddHN}
            // data
            paramKey={`${CARD_KEY}_SAVE_PATIENT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE_PATIENT`]}
            // config
            color="blue"
            size="medium"
            title="ADD"
          />
        }
        buttonImport={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleImportFile}
            // data
            paramKey={`${CARD_KEY}_IMPORT_PATIENT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}__IMPORT_PATIENT`]}
            // config
            color="blue"
            size="medium"
            title="Import"
          />
        }
      />

      <Dropzone
        ref={dropzoneRef}
        multiple={false}
        style={{ display: "none" }}
        accept=".xlsx"
        onDrop={handleSelectedFile}
      />

      <Modal size="tiny" open={modRemark?.open} onClose={handleCloseModRemark}>
        <Modal.Header>Remark</Modal.Header>
        <Modal.Content>
          <Form>
            <TextArea value={modRemark?.remark || ""} row={3} onChange={handleChangeRemark} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Save" color="green" onClick={handleSaveRemark} />
          <Button content="Close" color="red" onClick={handleCloseModRemark} />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default React.memo(CardSegmentPatient);
