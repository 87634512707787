import WasmController from 'react-lib/frameworks/WasmController';
import PatientList from "issara-sdk/apis/PatientList_apps_REG";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import PatientDetailViewByHn from "issara-sdk/apis/PatientDetailViewByHn_apps_REG"
import BloodTypeList from "issara-sdk/apis/BloodTypeList_apps_REG"

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "apiToken", params: any }
  |  { message: "getPatientInfo", params: any }
  |  { message: "getSearchAllPatient", params: any }
  |  { message: "getPatientOldName", params: any }
  |  { message: "getPatientNameWithID", params: any }
  |  { message: "getPatientByHN", params: any }
  |  { message: "getBloodType", params: any }
  |  { message: "regService", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const apiToken: Handler = async (controller, {}) => {
//undefined
//
//----> Name mismatch between manager and service
}

export const getPatientInfo: Handler = async (controller, {
  patientId,
  apiToken
}) => {
  var patientInfoData = [];
  var patientInfoError = null;
  const result = await PatientDetailView.retrieve({
    pk: patientId,
    apiToken: controller.apiToken
  });
  if (result[0]) {
    patientInfoData = result[0];
  } else {
    patientInfoError = result[1];
  }
  return [patientInfoData, patientInfoError];
}

export const getSearchAllPatient: Handler = async (controller, 
  { hn, fullName, citizenId, nationality, startAge, endAge, noEncounter, mergeFile, includeCancel, 
    isEmergency, is_old_name_search, limit, offset } = {}
) => {
  let params: any = {};
  if (hn) {
    params.hn = hn;
  }
  if (fullName) {
    params.name_search = fullName;
  }
  if (citizenId) {
    params.citizen_passport = citizenId;
  }
  if (nationality) {
    params.nationality = nationality;
  }
  if (startAge) {
    params.start_age = startAge;
  }
  if (endAge) {
    params.end_age = endAge;
  }
  if (noEncounter) {
    params.no_encounter = noEncounter;
  }
  if (mergeFile) {
    params.merge_file = mergeFile;
  }
  if (includeCancel) {
    params.include_cancel = includeCancel;
  }
  if (isEmergency) {
    params.is_emergency = isEmergency;
  }
  if (is_old_name_search) {
    params.is_old_name_search = is_old_name_search;
  }
  if (limit) {
    params.limit = limit;
  }
  if (offset) {
    params.offset = offset;
  }
  // if (cancelToken) {
  //   params.cancelToken = cancelToken;
  // }
  // Do not support cancelToken -- need to change apigen
  return await PatientList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getPatientOldName: Handler = async (controller, {}) => {
//function (patientId) { return __awaiter(_this, void 0, void 0, function () {
//            var _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.regService.getPatientName(patientId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        return [2 /*return*/, [response, error]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getPatientNameWithID: Handler = async (controller, {}) => {
//function (patientId) { return __awaiter(_this, void 0, void 0, function () {
//            var nameData, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        nameData = [];
//                        return [4 /*yield*/, utils_1.to(this.regService.getPatientName(patientId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            nameData = response.items;
//                        }
//                        return [2 /*return*/, [nameData, error]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getPatientByHN: Handler = async (controller, {hn}) => {
  let params: any = {}
  if(hn){
    params.hn = hn
  }
  return await PatientDetailViewByHn.get({
    params: params,
    apiToken: controller.apiToken,
    hn
  })
}

export const getBloodType: Handler = async (controller, params) => {
  const [r, e, n] = await BloodTypeList.list({
    params,
    apiToken: controller.apiToken
  });

  return [r?.items || [], e, n]
//function (_a) {
//            var apiToken = _a.apiToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, err, params, _b, error, response;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            data = [];
//                            err = null;
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(this.regService.getBloodType({ params: params, apiToken: apiToken }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1];
//                            if (response) {
//                                data = response.items;
//                            }
//                            else {
//                                err = error;
//                            }
//                            return [2 /*return*/, [data, err]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, apiToken = _a.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client.get(apis_1.REG.BLOOD_TYPE, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/REG/bloodtype/
}

export const regService: Handler = async (controller, {}) => {
//[object Object]
//
//----> Name mismatch between manager and service
}
