import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Radio,
  Button
} from 'semantic-ui-react'

const CardSettingTimeOperatingRoomUX = (props: any) => {
    return(
      <div
        style={{width:"100%"}}>
        <div
          style={{padding: "1.3rem 0px 1rem 5rem", color: "#746A6A", fontWeight: "bold", fontSize: "1.2rem", backgroundColor: "#B4E4EF"}}>
          ตั้งค่าเวลาปิด ห้องผ่าตัด
        </div>
        <div
          style={{padding: "2rem"}}>
          
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label>
                  วันที่
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{width: "100px"}}>
                  
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{width: "100%"}}>
                  {props.DateTextBoxStart}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{width: "100%", textAlign: "center",fontWeight: "normal"}}>
                  ถึง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{width: "100%"}}>
                  {props.DateTextBoxEnd}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{margin: 0}}>
                  เวลาปิด
                </label>
                <label
                  style={{color: "red", marginRight: "0.25rem"}}>
                  *
                </label>
                <label>
                  :
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{ fontWeight: "normal"}}>
                  จาก
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{width: "100%"}}>
                  {props.TimeTextBoxStart}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{width: "100%", textAlign: "center",fontWeight: "normal"}}>
                  ถึง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{width: "100%"}}>
                  {props.TimeTextBoxEnd}
                </div>
              </FormField>
              <FormField>
                <Checkbox
                  checked={props.allHour || false}
                  label="ทั้งวัน"
                  name="all_hour"
                  onChange={props.onChangeValue}
                  style={{fontWeight: "normal"}}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.repeat || false}
                  label="ต้องการซ้ำ"
                  name="repeat"
                  onChange={props.onChangeValue}
                  style={{marginTop: "1rem", fontWeight: "bold", marginBottom: "0.5rem"}}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <Radio
                  checked={props.everyday === true}
                  disabled={!props.config?.enabledRepeat}
                  label="ทุกวัน"
                  name="everyday"
                  onChange={(e: any, {checked})=> props.onChangeValue(e, {name:"everyday", checked: true})}
                  style={{marginLeft: "2rem"}}>
                </Radio>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <Radio
                  checked={props.everyday === false}
                  disabled={!props.config?.enabledRepeat}
                  label="ทุกสัปดาห์"
                  onChange={(e: any, {checked})=> props.onChangeValue(e, {name:"everyday", checked: false})}
                  style={{marginLeft: "2rem"}}>
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  className="circular"
                  disabled={props.config?.disabledMonday || false}
                  onClick={(e)=>props.onChangeValue(e,{value: 0, name: "select_day"})}
                  style={{backgroundColor:  props.selectDay?.includes( 0) ? "#5DBCD2" : "transparent", border: "1px solid #746A6A", height: "2.5rem", width: "2.5rem", padding: "0.5rem 0.5rem 0.75rem"}}>
                  จ.
                </Button>
              </FormField>
              <FormField>
                <Button
                  className="circular"
                  disabled={props.config?.disabledTuesday || false}
                  onClick={(e)=>props.onChangeValue(e,{value: 1, name: "select_day"})}
                  style={{backgroundColor: props.selectDay?.includes( 1) ? "#5DBCD2" : "transparent", border: "1px solid #746A6A", height: "2.5rem", width: "2.5rem", padding: "0.5rem 0.5rem 0.75rem"}}>
                  อ.
                </Button>
              </FormField>
              <FormField>
                <Button
                  className="circular"
                  disabled={props.config?.disabledWednesday || false}
                  onClick={(e)=>props.onChangeValue(e,{value: 2, name: "select_day"})}
                  style={{backgroundColor: props.selectDay?.includes( 2)  ? "#5DBCD2" : "transparent", border: "1px solid #746A6A", height: "2.5rem", width: "2.5rem", padding: "0.5rem 0.5rem 0.75rem"}}>
                  พ.
                </Button>
              </FormField>
              <FormField>
                <Button
                  className="circular"
                  disabled={props.config?.disabledThursday || false}
                  onClick={(e)=>props.onChangeValue(e,{value: 3, name: "select_day"})}
                  style={{backgroundColor: props.selectDay?.includes( 3) ? "#5DBCD2" : "transparent", border: "1px solid #746A6A", height: "2.5rem", width: "2.5rem", padding: "0.5rem 0.25rem 0.75rem"}}>
                  พฤ.
                </Button>
              </FormField>
              <FormField>
                <Button
                  className="circular"
                  disabled={props.config?.disabledFriday || false}
                  onClick={(e)=>props.onChangeValue(e,{value: 4, name: "select_day"})}
                  style={{backgroundColor: props.selectDay?.includes( 4)  ? "#5DBCD2" : "transparent", border: "1px solid #746A6A", height: "2.5rem", width: "2.5rem", padding: "0.5rem 0.5rem 0.75rem"}}>
                  ศ.
                </Button>
              </FormField>
              <FormField>
                <Button
                  className="circular"
                  disabled={props.config?.disabledSaturday || false}
                  onClick={(e)=>props.onChangeValue(e,{value: 5, name: "select_day"})}
                  style={{backgroundColor: props.selectDay?.includes( 5)  ? "#5DBCD2" : "transparent", border: "1px solid #746A6A", height: "2.5rem", width: "2.5rem", padding: "0.5rem 0.5rem 0.75rem"}}>
                  ส.
                </Button>
              </FormField>
              <FormField>
                <Button
                  className="circular"
                  disabled={props.config?.disabledSunday || false}
                  onClick={(e)=>props.onChangeValue(e,{value: 6, name: "select_day"})}
                  style={{backgroundColor: props.selectDay?.includes( 6)  ? "#5DBCD2" : "transparent",border: "1px solid #746A6A", height: "2.5rem", width: "2.5rem", padding: "0.5rem 0.5rem 0.75rem"}}>
                  อา.
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={12}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Button
                  color="green"
                  disabled={props.config?.disabledSave}
                  fluid={true}
                  onClick={props.onSave}>
                  ตกลง
                </Button>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Button
                  color="red"
                  fluid={true}
                  onClick={props.onCancel}>
                  ยกเลิก
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}

export default CardSettingTimeOperatingRoomUX

export const screenPropsDefault = {}

/* Date Time : Wed Sep 14 2022 07:18:26 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ตั้งค่าเวลาปิด ห้องผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1.3rem 0px 1rem 5rem\", color: \"#746A6A\", fontWeight: \"bold\", fontSize: \"1.2rem\", backgroundColor: \"#B4E4EF\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"2rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxStart"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxEnd"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"center\",fontWeight: \"normal\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลาปิด"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "จาก"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TimeTextBoxStart"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TimeTextBoxEnd"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"center\",fontWeight: \"normal\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 5,
      "props": {
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Checkbox",
      "parent": 29,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.allHour || false"
        },
        "label": {
          "type": "value",
          "value": "ทั้งวัน"
        },
        "name": {
          "type": "value",
          "value": "all_hour"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Radio",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.everyday === true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledRepeat"
        },
        "label": {
          "type": "value",
          "value": "ทุกวัน"
        },
        "name": {
          "type": "value",
          "value": "everyday"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked})=> props.onChangeValue(e, {name:\"everyday\", checked: true})"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"2rem\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 35,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Radio",
      "parent": 36,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.everyday === false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledRepeat"
        },
        "label": {
          "type": "value",
          "value": "ทุกสัปดาห์"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked})=> props.onChangeValue(e, {name:\"everyday\", checked: false})"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"2rem\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 35,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "พ."
        },
        "className": {
          "type": "value",
          "value": "circular"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledWednesday || false"
        },
        "onClick": {
          "type": "code",
          "value": "(e)=>props.onChangeValue(e,{value: 2, name: \"select_day\"})"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: props.selectDay?.includes( 2)  ? \"#5DBCD2\" : \"transparent\", border: \"1px solid #746A6A\", height: \"2.5rem\", width: \"2.5rem\", padding: \"0.5rem 0.5rem 0.75rem\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Button",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "อา."
        },
        "className": {
          "type": "value",
          "value": "circular"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledSunday || false"
        },
        "onClick": {
          "type": "code",
          "value": "(e)=>props.onChangeValue(e,{value: 6, name: \"select_day\"})"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: props.selectDay?.includes( 6)  ? \"#5DBCD2\" : \"transparent\",border: \"1px solid #746A6A\", height: \"2.5rem\", width: \"2.5rem\", padding: \"0.5rem 0.5rem 0.75rem\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Button",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ส."
        },
        "className": {
          "type": "value",
          "value": "circular"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledSaturday || false"
        },
        "onClick": {
          "type": "code",
          "value": "(e)=>props.onChangeValue(e,{value: 5, name: \"select_day\"})"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: props.selectDay?.includes( 5)  ? \"#5DBCD2\" : \"transparent\", border: \"1px solid #746A6A\", height: \"2.5rem\", width: \"2.5rem\", padding: \"0.5rem 0.5rem 0.75rem\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Button",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "ศ."
        },
        "className": {
          "type": "value",
          "value": "circular"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledFriday || false"
        },
        "onClick": {
          "type": "code",
          "value": "(e)=>props.onChangeValue(e,{value: 4, name: \"select_day\"})"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: props.selectDay?.includes( 4)  ? \"#5DBCD2\" : \"transparent\", border: \"1px solid #746A6A\", height: \"2.5rem\", width: \"2.5rem\", padding: \"0.5rem 0.5rem 0.75rem\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "พฤ."
        },
        "className": {
          "type": "value",
          "value": "circular"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledThursday || false"
        },
        "onClick": {
          "type": "code",
          "value": "(e)=>props.onChangeValue(e,{value: 3, name: \"select_day\"})"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: props.selectDay?.includes( 3) ? \"#5DBCD2\" : \"transparent\", border: \"1px solid #746A6A\", height: \"2.5rem\", width: \"2.5rem\", padding: \"0.5rem 0.25rem 0.75rem\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Button",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "อ."
        },
        "className": {
          "type": "value",
          "value": "circular"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledTuesday || false"
        },
        "onClick": {
          "type": "code",
          "value": "(e)=>props.onChangeValue(e,{value: 1, name: \"select_day\"})"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: props.selectDay?.includes( 1) ? \"#5DBCD2\" : \"transparent\", border: \"1px solid #746A6A\", height: \"2.5rem\", width: \"2.5rem\", padding: \"0.5rem 0.5rem 0.75rem\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Button",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "จ."
        },
        "className": {
          "type": "value",
          "value": "circular"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledMonday || false"
        },
        "onClick": {
          "type": "code",
          "value": "(e)=>props.onChangeValue(e,{value: 0, name: \"select_day\"})"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:  props.selectDay?.includes( 0) ? \"#5DBCD2\" : \"transparent\", border: \"1px solid #746A6A\", height: \"2.5rem\", width: \"2.5rem\", padding: \"0.5rem 0.5rem 0.75rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "ตกลง"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledSave"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Button",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.repeat || false"
        },
        "label": {
          "type": "value",
          "value": "ต้องการซ้ำ"
        },
        "name": {
          "type": "value",
          "value": "repeat"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\", fontWeight: \"bold\", marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", marginRight: \"0.25rem\"}"
        }
      },
      "seq": 65,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingTimeOperatingRoomUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
