import { TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "../../BIL/pdfFormBil/HeaderSummaryReportForm";

const FormDFPayrollReport = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props FormDFPayrollReport: ", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.result?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน และการรักษา",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        preserveLeadingSpaces: true,
        text: `ประเภทการรักษา : ${props.result?.params?.examination_type.length > 0 ? props.result?.params?.examination_type : "ทุกประเภท"}`, // issue 67770
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `งวดวันที่ ${props.result?.params?.start_date} ถึง ${props.result?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const generateTableBody = (data: any, columns: any) => {
    let body = [];

    body.push([
      { text: "ลำดับ", bold: true, alignment: "center" },
      { text: "แพทย์", bold: true, alignment: "center" },
      { text: "รายรับ", bold: true, alignment: "center" },
      { text: "ค่า Lab", bold: true, alignment: "center" },
      { text: "รายได้\nหลังหักค่า Lab", bold: true, alignment: "center" },
      { text: "หักค่าบริการ\n20%", bold: true, alignment: "center" },
      { text: "คงเหลือ", bold: true, alignment: "center" },
      { text: "ส่วนแบ่ง\n(%)", bold: true, alignment: "center" },
      { text: "รายได้\nหลังหักส่วนแบ่ง", bold: true, alignment: "center" },
      { text: "ปรับเพิ่ม", bold: true, alignment: "center" },
      { text: "ปรับลด", bold: true, alignment: "center" },
      { text: "รวมสุทธิ", bold: true, alignment: "center" },
      { text: "ชื่อบัญชี", bold: true, alignment: "center" },
      { text: "เลขที่บัญชี", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            switch (column) {
              case "revenue_price":
              case "lab_price":
              case "revenue_after_lab":
              case "management_fee":
              case "leftover_revenue":
              case "compensation_percent":
              case "final_revenue_price":
              case "adjust_up_price":
              case "adjust_down_price":
              case "net_price":
                dataRow.push({
                  text: row[column].toLocaleString("th-TH", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  alignment: "right",
                });
                break;
              case "bank":
                dataRow.push({ text: row[column] + "\n" + row["bank_branch"] });
                break;
              default:
                dataRow.push({ text: row[column].toString() });
                break;
            }
          } else {
            dataRow.push({ text: "-" });
          }
        });

        body.push(dataRow);
      });
    }

    if (props?.result?.params) {
      body.push([
        { text: "รวม", colSpan: 2, bold: true, alignment: "center" },
        {},
        {
          text: props.result?.params?.total_revenue_price.toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          bold: true,
          alignment: "right",
        },
        {
          text: props.result?.params?.total_lab_price.toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          bold: true,
          alignment: "right",
        },
        {
          text: props.result?.params?.total_revenue_after_lab.toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          bold: true,
          alignment: "right",
        },
        {
          text: props.result?.params?.total_management_fee.toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          bold: true,
          alignment: "right",
        },
        {
          text: props.result?.params?.total_leftover_revenue.toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          bold: true,
          alignment: "right",
        },
        {},
        {
          text: props.result?.params?.total_final_revenue_price.toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          bold: true,
          alignment: "right",
        },
        {
          text: props.result?.params?.total_adjust_up_price.toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          bold: true,
          alignment: "right",
        },
        {
          text: props.result?.params?.total_adjust_down_price.toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          bold: true,
          alignment: "right",
        },
        {
          text: props.result?.params?.total_net_price.toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          bold: true,
          alignment: "right",
        },
        {},
        {},
      ]);
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: [
          "auto",
          "*",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "*",
          "auto",
        ],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };
  return {
    ...headerForm,
    content: [
      tableReport(props.result?.fields || [], [
        "seq",
        "doctor_name",
        "revenue_price",
        "lab_price",
        "revenue_after_lab",
        "management_fee",
        "leftover_revenue",
        "compensation_percent",
        "final_revenue_price",
        "adjust_up_price",
        "adjust_down_price",
        "net_price",
        "bank",
        "bank_account_no",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
  };
};

export default FormDFPayrollReport;
