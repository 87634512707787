import WasmController from 'react-lib/frameworks/WasmController';
import PatientList from "issara-sdk/apis/PatientList_apps_REG"
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import DivisionServiceBlockView from "issara-sdk/apis/DivisionServiceBlockView_apps_QUE";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import PatientData from "react-lib/apps/common/PatientData";
import ProxyPatientHasDivisionChatDetail from "issara-sdk/apis/ProxyPatientHasDivisionChatDetail_apps_PRX";

// Interface
import { HandleGetProfileImage } from 'react-lib/apps/HISV3/REG/sequence/SetPatient';
import { HandleGetProfileInfo } from 'react-lib/apps/HISV3/REG/sequence/SetPatient';

// Config
import CONFIG from "../../config/config"

export type State = 
  {
    // division?: number,
    // provider?: number,
    // pageName?: string,
    matchCompat?: any,
    matchQAChat?: {
      params: {
        triageLevel?: any,
        diagFormId?: any,
        diag_rule?: any,
        vid_call?: any,
      }
    }

    patientSearchText?: string,
    patientList?: any[],
    selectedPatientId?: number | null,
    selectedPatient?: any,
    patientData?: PatientData | null,
    appointmentList?: any[]
    selectedAppointmentId?: number | null,
    blockList?: any[],
    selectedBlockId?: number | null,
  }

export const StateInitial: State = 
  {
    // pageName: "",
    matchCompat: {
      params:{
        id: null, 
        weekStartSerial: null,
      }
    },
    matchQAChat: {
      params: {
        triageLevel: null,
        diagFormId: null,
      }
    },

    patientSearchText: "",
    patientList: [],
    selectedPatientId: null,
    appointmentList: [],
    selectedAppointmentId: null,
    blockList: [],
    selectedBlockId: null,
  }

export type Event = 
  //   { message: "DidMount", params: {} } 
  // | { message: "DidUpdate", params: {} }
  // | { message: "RouteToPage", 
  //     params: {
  //       pageName: string,
  //       pageParams: any
  //     } }
  { message: "SearchPatient", params: {} }
  | { message: "SelectPatientForAppointment", params: { id: number } }
  | { message: "CreatePatientAppointment", params: {}}
  | { message: "SelectAppointment", params: {}}
  | { message: "AssignAppointmentToBlock", params: {}}

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

// export const DidMount: Handler = async (controller, params) => {
//   document.documentElement.setAttribute("data-theme", "penta");
//   controller.cookies = Cookies;
//   controller.apiToken = Cookies.get("apiToken") || "";
//   let division: number | null = null;
//   division = parseInt(Cookies.get("division_id") || "0");
//   const [r, e, n] = await GetProviderInfo.get({
//     user_id: Cookies.get("user_id"),
//     apiToken: Cookies.get("apiToken")
//   });
//   console.log(r);
//   let pageName = "ManageSchedule";
//   for (const key of Object.keys(PAGEROUTE)) {
//     if (controller.history.location.pathname.includes(key)) {
//       pageName = key
//     }
//   }
//   controller.setState({
//     division: division,
//     provider: r.id,
//     pageName: pageName
//   })
// }

// export const DidUpdate: Handler = async (controller, params) => {
// }

// const PAGEROUTE: { [name: string]: any } = {
//   ManageSchedule: "/",
//   ReviewSchedule: "/ReviewSchedule/",
//   PatientAppointment: "/PatientAppointment/"
// }

// export const appLabel = "?app=Scheduling";

// export const RouteToPage: Handler = async (controller, params) => {
//   controller.setState({ pageName: params.pageName });
//   controller.history.push(PAGEROUTE[params.pageName] + appLabel);
// }

export const SearchPatient: Handler = async (controller, params) => {
  const [r, e, n] = await PatientList.list({
    params: { 
      name_search: controller.getState().patientSearchText,
      is_old_name_search: true,
      offset: 0,
      limit: 40
    },
    apiToken: controller.apiToken
  });
  if (e) return console.log("Error", e.toString());
  controller.setState({
    patientList: r.items
  })
}

export const SelectPatientForAppointment: Handler = async (controller, params) => {

  console.log('SelectPatientForAppointment: ', params);
  // get patient data
  let patientData = new PatientData(() => {});

  patientData.setPatientId(params.id);
  patientData.setEncounterId(null);
  patientData.setEmrId(null);
  patientData.setProgressionCycleId(null);

  const patientDetail = await PatientDetailView.retrieve({ 
    pk: params.id,
    apiToken: controller.apiToken });

  if (CONFIG.PATIENT_IMAGE_URL && patientDetail[0]) {
    patientDetail[0].profile_image = null;

    HandleGetProfileImage(controller as any, { hn: patientDetail[0]?.hn })
  }

  controller.setState({
    selectedPatientId: params.id,
    selectedPatient: patientDetail[0] ? patientDetail[0] : null,
    patientData: patientData,
  });

  if (CONFIG.SHOW_PATIENT_INFO_FROM_BDMS) {
    HandleGetProfileInfo(controller as any, {hn: patientDetail[0]?.hn, patient_id: patientDetail[0]?.patient_id })
  }

  // get appointment
  const [r, e, n] = await PatientAppointmentView.list({
    params: { patient_id: params.id },
    apiToken: controller.apiToken
  });

  if (e) return console.log("Error", e.toString());
  const items = r.items.map((item: any) => ({
    ...item,
    start_datetime: item.display_info?.start_datetime || "",
  }))

  controller.setState({
    appointmentList: items
  });

  const [chatDetail, error] = await ProxyPatientHasDivisionChatDetail.retrieve({
    pk: params?.id,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
    params: {
      force_create: true,
    },
  })

  controller.setState({ chatDetail: chatDetail });
}

export const CreatePatientAppointment: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log(state);
  if (Number.isInteger(state?.selectedPatientId)) {
    const [r, e, n] = await PatientAppointmentView.create({
      data: {
        patient: state.selectedPatientId,
        division: controller.data.division,
      },
      apiToken: controller.apiToken
    });
    console.log(r);
  }
}

export const SelectAppointment: Handler = async (controller, params) => {
  controller.setState({
    selectedAppointmentId: params.id
  });
  const [r, e, n] = await DivisionServiceBlockView.list({
    params: { 
      divisions: [controller.data.division]
    },
    apiToken: controller.apiToken
  });
  if (e) return console.log("Error", e.toString());
  controller.setState({
    blockList: r.items
  })
  console.log(r);
}

export const AssignAppointmentToBlock: Handler = async (controller, params) => {
  const selectedAppointmentId = controller.getState().selectedAppointmentId;
  controller.setState({
    selectedBlockId: params.id
  });
  const [r, e, n] = await PatientAppointmentUpdate.patch({
    pk: selectedAppointmentId,
    data: {
      division_service_block: params.id
    },
    apiToken: controller.apiToken
  });
  console.log(r);
}