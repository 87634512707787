import React, { useState, useRef, useEffect } from "react";
import {
  Segment,
  Grid,
  GridRow,
  GridColumn,
  Icon,
  Image,
  Form,
  Input,
  Label,
  Dimmer,
  Loader
} from "semantic-ui-react";
import PropTypes from "prop-types";
import RightLabel from "./RightLabel";
import defaultImage from "../common/person.png";
import ModalADRInfo from "../PHV/ModalADRInfo";
import { formatToBEDatetime } from "../../utils";
import { useIntl } from "react-intl";

const replaceEmptyData = data => {
  if (!data) {
    return "-";
  }
  return data;
};

const PatientPanel2 = props => {
  const intl = useIntl();
  const style = {
    fluid: { width: "100%" },
    inline: { margin: "0 0 0.5em 0" },
    blueFont: { color: "#3180ed" },
    grayColor: "#f3f3f3"
  };

  const isMounted = useRef(true);
  const [openModADR, setOpenModADR] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [episodeInfo, setEpisodeInfo] = useState({});
  const [drugInfo, setDrugInfo] = useState({});
  const [weightInfo, setWeightInfo] = useState({});
  const [heightInfo, setHeightInfo] = useState({});
  const [encounterInfo, setEncounterInfo] = useState({});
  const [adrInfo, setADRInfo] = useState({});

  useEffect(() => {
    isMounted.current = true;
    refresh();
    return () => {
      isMounted.current = false;
    };
  }, [props.patientId]);

  const refresh = async () => {
    setIsLoading(true);
    if (props.controller && props.patientId) {
      const response = await props.controller.getPatientInfo(
        props.encounterId,
        props.patientId
      );
      if (isMounted.current) {
        setPatientInfo(response.patientInfo)
        setEpisodeInfo(response.episodeInfo)
        setDrugInfo(response.drugInfo?.data)
        setWeightInfo(response.weightInfo)
        setHeightInfo(response.heightInfo)
        setEncounterInfo(response.encounterInfo)

      }

      // GET ADR DATA
      // excludeUnused: true,
      // severeFirst: true,
      // approveOnly: true,
      // showNotKnownByDoctor: true
      const [adrData, error, network] = await props.controller.getAdverseReactionList(
        {
          patientId: props.patientId,
          excludeUnused: true,
          severeFirst: true,
          approveOnly: true,
          showNotKnownByDoctor: true
        }
      );
      if (isMounted.current) {
        setADRInfo(adrData?.items)
      }
    }
    setIsLoading(false);
  };

  const handleOnClickADR = () => {
    setOpenModADR(!openModADR);
  };

  const {
    hn,
    profile_image,
    full_name_th,
    birthdate,
    full_age,
    religion,
    gender_name,
    coverage,
    underlying_disease,
    is_new_patient
  } = patientInfo ? patientInfo : props; // To prevent bugs when there is no props.patientInfo

  const { number, payer_name } = encounterInfo
    ? encounterInfo
    : props;

  const { tobacco, alcohol, triage_level, precaution } = drugInfo
    ? drugInfo
    : props;

  const { weight, weight_unit } = weightInfo ? weightInfo : props;

  const { height, height_unit } = heightInfo ? heightInfo : props;

  const {
    checkin_time,
    checkout_time,
    episode_start,
    episode_name
  } = episodeInfo ? episodeInfo : props;

  let image = profile_image ? profile_image.image : defaultImage;

  let patientLabel = adrInfo
    ? adrInfo.length > 0
      ? {
        as: "a",
        color: "red",
        content: "ADR",
        ribbon: "right",
        size: "tiny",
        onClick: () => handleOnClickADR()
      }
      : {
        as: "a",
        content: "NKA",
        ribbon: "right",
        size: "tiny"
      }
    : null;

  let checkInTime =
    encounterInfo && encounterInfo.encounter_checkin_time
      ? formatToBEDatetime({ date: encounterInfo.encounter_checkin_time })
      : "";
  let checkOutTime =
    encounterInfo && encounterInfo.encounter_checkout_time
      ? formatToBEDatetime({ date: encounterInfo.encounter_checkout_time })
      : "";

  if (props.episodeInfo && props.episodeInfo.checkin_time) {
    checkInTime = props.episodeInfo.checkin_time;
  }
  if (props.episodeInfo && props.episodeInfo.checkout_time) {
    checkOutTime = props.episodeInfo.checkout_time;
  }

  return (
    <Segment raised style={{ background: style.grayColor }} loading={isLoading}>
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      <ModalADRInfo
        open={openModADR}
        onClose={handleOnClickADR}
        data={adrInfo}
      />

      <Grid>
        <GridRow>
          <GridColumn style={{ width: "12%" }}>
            <Image
              fluid
              src={image}
              onError={i => (i.target.src = defaultImage)}
              label={patientLabel}
            />
            {is_new_patient && (
              <Label style={style.patientLabel} className="fluid" color="teal">
                <Icon name="star" />
                New Patient
              </Label>
            )}
          </GridColumn>

          <GridColumn style={{ width: "64%" }}>
            <Form>
              <Form.Group inline style={style.inline}>
                <Form.Field width={1}>
                  <RightLabel style={style.blueFont}>HN</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "18%" }}>
                  <Input
                    style={{ ...style.blueFont, ...style.fluid }}
                    value={replaceEmptyData(hn)}
                  />
                </Form.Field>
                <Form.Field width={1}>
                  <RightLabel style={style.blueFont}>{intl.formatMessage({ id: "ชื่อ" })}</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "18%" }}>
                  <Label style={{ ...style.blueFont, ...style.fluid }}>
                    {replaceEmptyData(full_name_th)}
                  </Label>
                </Form.Field>
                <Form.Field width={4}>
                  <RightLabel>{intl.formatMessage({ id: "สูบบุหรี่" })}</RightLabel>
                  <Label>{replaceEmptyData(tobacco)}</Label>
                  <RightLabel>{intl.formatMessage({ id: "ดื่มสุรา" })}</RightLabel>
                  <Label>{replaceEmptyData(alcohol)}</Label>
                </Form.Field>
                <Form.Field width={3}>
                  <RightLabel>Triage Level</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "10%" }}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(triage_level)}
                  </Label>
                </Form.Field>
              </Form.Group>

              <Form.Group inline style={style.inline}>
                <Form.Field width={1}>
                  <RightLabel style={style.blueFont}>{intl.formatMessage({ id: "วันเกิด" })}</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "18%" }}>
                  <Label style={{ ...style.fluid, ...style.blueFont }}>
                    {replaceEmptyData(birthdate)}
                  </Label>
                </Form.Field>
                <Form.Field width={1}>
                  <RightLabel>{intl.formatMessage({ id: "อายุ" })}</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "18%" }}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(full_age)}
                  </Label>
                </Form.Field>
                <Form.Field style={{ width: "9%", textAlign: "right" }}>
                  <RightLabel>{intl.formatMessage({ id: "ศาสนา/ความเชื่อ" })}</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "16%" }}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(religion)}
                  </Label>
                </Form.Field>
                <Form.Field width={3}>
                  <RightLabel>Weight</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "10%" }}>
                  <Label style={style.fluid}>{`${replaceEmptyData(
                    weight
                  )} ${replaceEmptyData(weight_unit)}`}</Label>
                </Form.Field>
              </Form.Group>

              <Form.Group inline style={style.inline}>
                <Form.Field width={1}>
                  <RightLabel>{intl.formatMessage({ id: "เพศ" })}</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "18%" }}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(gender_name)}
                  </Label>
                </Form.Field>
                <Form.Field style={{ width: "50%" }}></Form.Field>
                <Form.Field width={3}>
                  <RightLabel>Height</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "10%" }}>
                  <Label style={style.fluid}>{`${replaceEmptyData(
                    height
                  )} ${replaceEmptyData(height_unit)}`}</Label>
                </Form.Field>
              </Form.Group>

              <Form.Group inline style={style.inline}>
                <Form.Field width={1}>
                  <RightLabel>{intl.formatMessage({ id: "สิทธิ" })}</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "18%" }}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(coverage)}
                  </Label>
                </Form.Field>
                <Form.Field width={1}>
                  <RightLabel>Payer</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "18%" }}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(payer_name)}
                  </Label>
                </Form.Field>
                <Form.Field style={{ width: "9%", textAlign: "right" }}>
                  <RightLabel>Precaution</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "16%" }}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(precaution)}
                  </Label>
                </Form.Field>
                <Form.Field width={3}>
                  <RightLabel>Underlying Disease</RightLabel>
                </Form.Field>
                <Form.Field style={{ width: "10%" }}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(underlying_disease)}
                  </Label>
                </Form.Field>
              </Form.Group>
            </Form>
          </GridColumn>

          <GridColumn style={{ width: "24%" }}>
            <Form>
              <Form.Group
                inline
                style={{ ...style.inline, marginTop: "2%", marginBottom: "3%" }}
              >
                <Form.Field width={9}>
                  <RightLabel style={style.blueFont}>Encounter</RightLabel>
                </Form.Field>
                <Form.Field width={11}>
                  <Label style={{ ...style.fluid, ...style.blueFont }}>
                    {replaceEmptyData(number)}
                  </Label>
                </Form.Field>
              </Form.Group>
              {!props.disableEpisode && (
                <Form.Group inline style={style.inline}>
                  <Form.Field width={9}>
                    <RightLabel style={style.blueFont}>
                      Episode of care (Start)
                    </RightLabel>
                  </Form.Field>
                  <Form.Field width={11}>
                    <Label style={{ ...style.fluid, ...style.blueFont }}>
                      {replaceEmptyData(episode_start)}
                    </Label>
                  </Form.Field>
                </Form.Group>
              )}
              {!props.disableEpisode && (
                <Form.Group inline style={style.inline}>
                  <Form.Field width={9}>
                    <RightLabel style={style.blueFont}>
                      Episode of care
                    </RightLabel>
                  </Form.Field>
                  <Form.Field width={11}>
                    <Label style={{ ...style.fluid, ...style.blueFont }}>
                      {replaceEmptyData(episode_name)}
                    </Label>
                  </Form.Field>
                </Form.Group>
              )}
              <Form.Group inline style={style.inline}>
                <Form.Field width={9}>
                  <RightLabel>Check-in Time</RightLabel>
                </Form.Field>
                <Form.Field width={11}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(checkInTime)}
                  </Label>
                </Form.Field>
              </Form.Group>
              <Form.Group inline style={style.inline}>
                <Form.Field width={9}>
                  <RightLabel>Checkout Time</RightLabel>
                </Form.Field>
                <Form.Field width={11}>
                  <Label style={style.fluid}>
                    {replaceEmptyData(checkOutTime)}
                  </Label>
                </Form.Field>
              </Form.Group>
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );
};

PatientPanel2.defaultProps = {
  controller: null,
  encounterId: null,
  patientId: null,
  image: '../../../static/images/person.png',

  // Disable UI
  disableEpisode: false
};

PatientPanel2.propTypes = {
  controller: PropTypes.object,
  encounterId: PropTypes.number,
  patientId: PropTypes.number,

  // image: PropTypes.string,
  // hn: PropTypes.string,
  // full_name_th: PropTypes.string,
  // tobacco: PropTypes.string,
  // alcohol: PropTypes.string,
  // triage_level: PropTypes.string,
  // encounter_no: PropTypes.string,
  // birthdate: PropTypes.string,
  // full_age: PropTypes.string,
  // religion: PropTypes.string,
  // gender_name: PropTypes.string,
  // height: PropTypes.string,
  // height_unit: PropTypes.string,
  // weight: PropTypes.string,
  // weight_unit: PropTypes.string,
  // encounter_coverage: PropTypes.string,
  // encounter_payer_name: PropTypes.string,
  // precaution: PropTypes.string,
  // underlying_disease: PropTypes.string,
  // checkin_time: PropTypes.string,
  // checkout_time: PropTypes.string,
  // episode_start: PropTypes.string,
  // episode_name: PropTypes.string,
  // patientInfo: PropTypes.object,
  // encounterInfo: PropTypes.object,
  // weightInfo: PropTypes.object,
  // drugInfo: PropTypes.object,
  // heightInfo: PropTypes.object,
  // episodeInfo: PropTypes.object,

  // Disable UI
  disableEpisode: PropTypes.bool
};

export default React.memo(PatientPanel2);
