import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { SketchPicker } from "react-color";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// UI
import CardSegmentDetailUX from "./CardSegmentDetailUX";

import { CARD_KEY } from "./CardSegment";
import { StateInitial } from "./sequence/Segment";
import { useIntl } from "react-intl";

type CardSegmentDetailProps = {
  // function
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // seq
  runSequence?: any;
  SegmentSequence?: any;
};

const CardSegmentDetail = (props: CardSegmentDetailProps) => {
  const intl = useIntl();
  const [saveData, setSaveData] = useState<any>({});
  const [dataDisplay, setDataDisplay] = useState<any>({})
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const dropzoneRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    if (props.SegmentSequence?.selectedSegment?.id) {
      setDataDisplay({
        ...props.SegmentSequence?.selectedSegment,
        icon: { name: props.SegmentSequence?.selectedSegment?.icon_name },
      });
      setSaveData({
        id: props.SegmentSequence?.selectedSegment?.id,
        name: props.SegmentSequence?.selectedSegment?.name,
      });
    } else {
      setDataDisplay(StateInitial?.SegmentSequence?.selectedSegment);
    }
  }, [props.SegmentSequence?.selectedSegment]);

  const handleChangeCreateValue = (_event: any, data: any) => {
    setDataDisplay({ ...dataDisplay, [data.name]: data.value });
    setSaveData({ ...saveData, [data.name]: data.value });
  };

  const handleBrowseFile = () => {
    if (dropzoneRef?.current) {
      dropzoneRef?.current.open();
    }
  };

  const handleSelectedFile = (accepted, rejected) => {
    const acceptedFile = _.get(accepted, "[0]");
    setDataDisplay({ ...dataDisplay, icon: acceptedFile });
    setSaveData({ ...saveData, icon: acceptedFile });
  };

  const handleChangeColor = (color) => {
    setDataDisplay({ ...dataDisplay, color_code: color.hex });
    setSaveData({ ...saveData, color_code: color.hex });
    setShowColorPicker(false);
  };

  const handleSaveSegment = () => {
    props.runSequence({
      sequence: "Segment",
      action: "SAVE_SEGMENT",
      cardKey: CARD_KEY,
      data: saveData,
    });
  };

  const handleDeleteSegment = () => {
    props.runSequence({
      sequence: "Segment",
      action: "DELETE_SEGMENT",
      cardKey: CARD_KEY,
      data: saveData,
    });
  };

  return (
    <>
      <CardSegmentDetailUX
        // function
        onChangeCreateValue={handleChangeCreateValue}
        onBrowseFile={handleBrowseFile}
        // seq
        data={dataDisplay}
        // component
        iconColor={
          <>
            <div
              style={{
                border: "1px solid grey",
                width: "80px",
                height: "35px",
              }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              <div
                style={{
                  background: dataDisplay?.color_code,
                  width: "30px",
                  height: "23px",
                  margin: "5px 25px",
                }}
              ></div>
            </div>
            <div
              style={{ display: showColorPicker ? "" : "none", position: "absolute", zIndex: 1 }}
            >
              <SketchPicker color={dataDisplay?.color_code} onChangeComplete={handleChangeColor} />
            </div>
          </>
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSaveSegment}
            // data
            paramKey={`${CARD_KEY}_SAVE_SEGMENT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE_SEGMENT`]}
            // config
            color={props.SegmentSequence?.selectedSegment?.id ? "yellow" : "green"}
            size="medium"
            title={props.SegmentSequence?.selectedSegment?.id ? "Edit" : "Save"}
          />
        }
        buttonDel={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleDeleteSegment}
            // data
            paramKey={`${CARD_KEY}_DELETE_SEGMENT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_DELETE_SEGMENT`]}
            // config
            color="red"
            size="medium"
            style={{
              display: props.SegmentSequence?.selectedSegment?.id ? "" : "none",
            }}
            title="Delete Segment"
          />
        }
      />

      <Dropzone
        ref={dropzoneRef}
        multiple={false}
        style={{ display: "none" }}
        accept=".png, .jpg, .jpeg"
        onDrop={handleSelectedFile}
      />
    </>
  );
};

export default React.memo(CardSegmentDetail);
