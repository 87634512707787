import { CHAT, PRX } from "./url";
import BaseService from "react-lib/apis/services/BaseService";

class ChatService extends BaseService {
    getChatChannel({headers, params } = {}) {
        return this.client.get(PRX.CHAT_CHANNEL, { headers, params } )
          .then(this.handleResponse)
          .catch(this.throwErrorMessage);
      }

    getLasterChatChannel({headers, params } = {}) {
        return this.client.get(CHAT.LATEST_CHAT_CHANNEL, { headers, params } )
          .then(this.handleResponse)
          .catch(this.throwErrorMessage);
      }
}

export default ChatService;