
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['APIView'] */
/* params:  */
/* data:  */
const DoGenerateSentClaimDataIPDFromGroupAPIView : 
{
  get: ({coverage_payer_sent_claim_group_id,start_date,end_date, params, apiToken, extra}:
             {  coverage_payer_sent_claim_group_id: any,start_date: any,end_date: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  post:   ({params, data, apiToken, extra}:
            { coverage_payer_sent_claim_group_id: any,start_date: any,end_date: any, 
            params?: any,
            data?: any,
            apiToken?: any,
            extra?: any
        }) => any,
  put:   ({coverage_payer_sent_claim_group_id,start_date,end_date, params, data, apiToken, extra}:
             { coverage_payer_sent_claim_group_id: any,start_date: any,end_date: any, 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({coverage_payer_sent_claim_group_id,start_date,end_date, params, data, apiToken, extra}:
             { coverage_payer_sent_claim_group_id: any,start_date: any,end_date: any, 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  get: async ({coverage_payer_sent_claim_group_id,start_date,end_date, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/INF/do-generate-sent-claim-data-ipd-from-group/${coverage_payer_sent_claim_group_id}/${start_date}/${end_date}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  post: async ({coverage_payer_sent_claim_group_id,start_date,end_date, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.post(`${HOST}/apis/INF/do-generate-sent-claim-data-ipd-from-group/${coverage_payer_sent_claim_group_id}/${start_date}/${end_date}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  put: async ({coverage_payer_sent_claim_group_id,start_date,end_date, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.put(`${HOST}/apis/INF/do-generate-sent-claim-data-ipd-from-group/${coverage_payer_sent_claim_group_id}/${start_date}/${end_date}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({coverage_payer_sent_claim_group_id,start_date,end_date, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.patch(`${HOST}/apis/INF/do-generate-sent-claim-data-ipd-from-group/${coverage_payer_sent_claim_group_id}/${start_date}/${end_date}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default DoGenerateSentClaimDataIPDFromGroupAPIView

