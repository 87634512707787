import WasmController from "react-lib/frameworks/WasmController";
import DrugTransferRequestList from "issara-sdk/apis/DrugTransferRequestList_apps_TPD";
import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";
import DrugTransferRequestActionLogList from "issara-sdk/apis/DrugTransferRequestActionLogList_apps_TPD";
import StockList from "issara-sdk/apis/StockList_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import DrugTransferRequestDetail from "issara-sdk/apis/DrugTransferRequestDetail_apps_TPD";

export type State = {
  errorMessage?: any;
  successMessage?: any;
  drugTransferRequestDetail?: any;
  drugTransferRequestlist?:
  | {
    drug?: number | null;
    code?: any;
    name?: any;
    request_quantity?: number | null;
    storekey?: number | null;
    onhand_requester?: number | null;
    onhand_provider?: number | null;
    stock_unit_name?: number | null;
    request?: number;
  }[]
  | null;
  DrugTransferRequestSequence?: {
    sequenceIndex?: string | null;
    divisionRequest: any;
    selectedDivisionPharma?: any;
    selectedDrugSearch?: any;
    selectedDrugDose?: any;
  } | null;
  providerDivision?: any;
  drugTransferLog?: any;
};

export const StateInitial: State = {
  DrugTransferRequestSequence: null,
  drugTransferRequestlist: null,
  providerDivision: null,
  drugTransferLog: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DrugTransferRequestSequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["divisionPharma", {}]],
    },
  });

  controller.setState({
    DrugTransferRequestSequence: {
      ...state.DrugTransferRequestSequence,
      sequenceIndex: "SaveDrugTransferRequest",
    },
  });
};

export const SaveDrugTransferRequest: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DrugTransferRequestSequence) return;

  if (params?.action === "add") {
    HandleAdd(controller, params);
  } else if (params?.action === "request") {
    HandleRequest(controller, params);
  } else if (params?.action === "update") {
    HandleUpdate(controller, params);
  } else if (params?.action === "cancel") {
    HandleCancel(controller, params);
  } else if (params?.action === "update_stock") {
    HandleUpdateStock(controller, params,);
  } else if (params?.action === "edit") {
    const data = params.data;

    let items = state.drugTransferRequestlist || [];

    if (params.data.type === "request_quantity") {
      if (Number(data.value) < 0) {
        data.value = "";
      }

      items[data.index].request_quantity = data.value;
    }

    controller.setState({
      drugTransferRequestlist: [...items],
    });
  } else if (params?.action === "remove") {
    const selectedRowIndex = params.selectedRow?.index;
    let itemsDrug = state.drugTransferRequestlist || [];

    const items = itemsDrug.filter(
      (item: any, index: number) => index !== selectedRowIndex
    );

    controller.setState({
      drugTransferRequestlist: items,
      drugTransferLog: null,
      drugTransferRequestDetail: null,
    });
  } else if (params?.action === "log") {
    const transferLog = state.drugTransferRequestlist?.[0]?.request;

    const drugTransferRequestLog = await DrugTransferRequestActionLogList.list({
      pk: transferLog,
      extra: {
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({ drugTransferLog: drugTransferRequestLog[0] });
  } else if (params?.action === "new") {
    controller.setState({
      // {
      //   drug: null,
      //   code: null,
      //   name: null,
      //   request_quantity: null,
      //   storekey: null,
      //   onhand_requester: null,
      //   onhand_provider: null,
      //   stock_unit_name: null,
      // },
      drugTransferRequestlist: [],
      drugTransferLog: null,
      drugTransferRequestDetail: null,
    });
  }
};

/* ------------------------------------------------------ */

/*                         ACTION                         */

/* ------------------------------------------------------ */
const HandleAdd: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DrugTransferRequestSequence) return;

  const drug = state.DrugTransferRequestSequence?.selectedDrugSearch?.id

  const [drugDetail] = await DrugDetail.retrieve({
    pk: drug,
    apiToken: controller.apiToken,
  });

  controller.setState({
    drugTransferRequestlist: [
      ...(state.drugTransferRequestlist || []),
      {
        drug: drugDetail?.id,
        code: drugDetail?.code,
        name: drugDetail?.full_name,
        request_quantity: state.DrugTransferRequestSequence?.selectedDrugDose,
        storekey: null,
        stock_unit_name: drugDetail?.stock_unit_name,
      },
    ],
    DrugTransferRequestSequence: {
      ...state.DrugTransferRequestSequence,
      // selectedDivisionPharma: "",
      selectedDrugSearch: "",
      selectedDrugDose: "",
    },
  }, () => {
    const state = controller.getState();

    HandleUpdateStock(controller, {
      ...params,
      drug: drug,
      division: state.DrugTransferRequestSequence?.selectedDivisionPharma,
      index: (state.drugTransferRequestlist?.length || 0) - 1
    })
  });
};

const HandleRequest: Handler = async (controller, params) => {
  const state = controller.getState();

  const drugTransferRequest = await DrugTransferRequestList.create({
    data: {
      code: "NEW",
      action: "REQUEST",
      requester_name: "",
      provider_name: "",
      status_name: "NEW",
      items: state?.drugTransferRequestlist,
      provider: state.providerDivision,
      is_requester: false,
      is_provider: false,
    },
    extra: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  if (drugTransferRequest[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: drugTransferRequest[1],
      },
    });
  } else {
    controller.setState({
      successMessage: { ...state.successMessage, [params.card]: true },
    });

    controller.setState({
      drugTransferRequestlist: drugTransferRequest[0].items.map(
        (item: any, index: number) => ({
          ...(state as any).drugTransferRequestlist[index],
          ...item,
        })
      ),
      drugTransferRequestDetail: drugTransferRequest[0],
    });
  }
};

const HandleUpdate: Handler = async (controller, params) => {
  const state = controller.getState();

  const transferList = state.drugTransferRequestlist || [];

  const drugTransferRequestUpdate = await DrugTransferRequestDetail.update({
    pk: transferList[0].request,
    data: {
      action: "EDIT",
      status_name: "REQUESTED",
      items: transferList,
    },
    extra: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    drugTransferRequestlist: drugTransferRequestUpdate[0].items.map(
      (item: any, index: number) => ({
        ...transferList[index],
        ...item,
      })
    ),
    drugTransferRequestDetail: drugTransferRequestUpdate[0],
  });

  const transferLog = state.drugTransferRequestlist?.[0]?.request;

  const drugTransferRequestLog = await DrugTransferRequestActionLogList.list({
    pk: transferLog,
    extra: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({ drugTransferLog: drugTransferRequestLog[0] });
};

const HandleCancel: Handler = async (controller, params) => {
  const state = controller.getState();

  const transferList = state.drugTransferRequestlist || [];

  const drugTransferRequestCancel = await DrugTransferRequestDetail.update({
    pk: transferList[0].request,
    data: {
      action: "CANCEL",
      status_name: "REQUESTED",
      items: state?.drugTransferRequestlist,
    },
    extra: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    drugTransferRequestlist: drugTransferRequestCancel[0].items.map(
      (item: any, index: number) => ({
        ...transferList[index],
        ...item,
      })
    ),
    drugTransferRequestDetail: drugTransferRequestCancel[0],
  });

  const transferLog = state.drugTransferRequestlist?.[0]?.request;

  const drugTransferRequestLog = await DrugTransferRequestActionLogList.list({
    pk: transferLog,
    extra: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({ drugTransferLog: drugTransferRequestLog[0] });
};

const HandleUpdateStock: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DrugTransferRequestSequence) return;

  const ids = (state.drugTransferRequestlist || []).map(
    (item: any) => item.id
  );

  const stockParams = {
    divisions: [controller.data.division, params.division].join(","),
    products: [...ids, params.drug].join(","),
  };

  const [drugStock] = await StockList.list({
    params: stockParams,
    apiToken: controller.apiToken,
  });

  const stockRequester = drugStock?.items.find((item: any) =>
    item.divisions.includes(controller.data.division)
  );
  const stockProvider = drugStock?.items.find((item: any) =>
    item.divisions.includes(
      state.DrugTransferRequestSequence?.selectedDivisionPharma || ""
    )
  );

  const drugs = [...(state.drugTransferRequestlist || [])].map((item, index) =>
    index === params.index
      ? {
        ...item,
        onhand_requester: stockRequester?.quantity,
        onhand_provider: stockProvider?.quantity,
      }
      : item
  );

  controller.setState({
    drugTransferRequestlist: drugs,
    DrugTransferRequestSequence: {
      ...state.DrugTransferRequestSequence,
      // selectedDivisionPharma: "",
    },
    providerDivision: params.division,
  });
}