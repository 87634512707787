
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import WalletTransactionGroupSerializer from '../types/WalletTransactionGroupSerializer_apps_BIL'


const HOST = `${config.API_HOST}`
/* ['generics.DestroyAPIView'] */
/* params:  */
/* data: 'note' */
const WalletTransactionGroupCancel : 
{
  delete: ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  delete: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.delete(`${HOST}/apis/BIL/wallet-tx-group-cancel/${pk}/`,
    { params: params ? { ...params } : {},
      data: data? {...data} : {},
       ...extra
    }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default WalletTransactionGroupCancel

