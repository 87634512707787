import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './PTObjectiveCardiopulmonaryOPD.module.css'

export interface PTObjectiveCardiopulmonaryOPDViewDataType {
    PtObjectiveCardioOpd2: string,
    PtObjectiveCardioOpd2Disabled: boolean,
    CtObservation: string,
    CtObservationDisabled: boolean,
    CtObservationOptions: any,
    PtObjectiveCardioOpd7: string,
    PtObjectiveCardioOpd7Disabled: boolean,
    Ct7Consciousness: string,
    Ct7ConsciousnessDisabled: boolean,
    Ct7ConsciousnessOptions: any,
    Ct10Communication: string,
    Ct10CommunicationDisabled: boolean,
    Ct10CommunicationOptions: any,
    Ct10CoOperation: string,
    Ct10CoOperationDisabled: boolean,
    Ct10CoOperationOptions: any,
    CtCough: string,
    CtCoughDisabled: boolean,
    CtCoughOptions: any,
    CtBreathingPattern: string,
    CtBreathingPatternDisabled: boolean,
    CtBreathingPatternOptions: any,
    PtObjectiveCardioOpd28: string,
    PtObjectiveCardioOpd28Disabled: boolean,
    CtChestMovement: string,
    CtChestMovementDisabled: boolean,
    CtChestMovementOptions: any,
    PtObjectiveCardioOpd34: string,
    PtObjectiveCardioOpd34Disabled: boolean,
    CtChestExpansion: string,
    CtChestExpansionDisabled: boolean,
    CtChestExpansionOptions: any,
    PtObjectiveCardioOpd42: string,
    PtObjectiveCardioOpd42Disabled: boolean,
    PtObjectiveCardioOpd45: string,
    PtObjectiveCardioOpd45Disabled: boolean,
    CtPercussionNote: string,
    CtPercussionNoteDisabled: boolean,
    CtPercussionNoteOptions: any,
    PtObjectiveCardioOpd51: string,
    PtObjectiveCardioOpd51Disabled: boolean,
    CtAdventitiousSound: string,
    CtAdventitiousSoundDisabled: boolean,
    CtAdventitiousSoundOptions: any,
    PtObjectiveCardioOpd56: string,
    PtObjectiveCardioOpd56Disabled: boolean,
    PtObjectiveCardioOpd58: string,
    PtObjectiveCardioOpd58Disabled: boolean,
    PtObjectiveCardioOpd59: boolean,
    PtObjectiveCardioOpd59Disabled: boolean,
    PtObjectiveCardioOpd60: string,
    PtObjectiveCardioOpd60Disabled: boolean,
    PtObjectiveCardioOpd63: boolean,
    PtObjectiveCardioOpd63Disabled: boolean,
    PtObjectiveCardioOpd64: string,
    PtObjectiveCardioOpd64Disabled: boolean,
    PtObjectiveCardioOpd66: boolean,
    PtObjectiveCardioOpd66Disabled: boolean,
    PtObjectiveCardioOpd67: string,
    PtObjectiveCardioOpd67Disabled: boolean,
    PtObjectiveCardioOpd70: boolean,
    PtObjectiveCardioOpd70Disabled: boolean,
    PtObjectiveCardioOpd73: boolean,
    PtObjectiveCardioOpd73Disabled: boolean,
    PtObjectiveCardioOpd76: string,
    PtObjectiveCardioOpd76Disabled: boolean,
    PtObjectiveCardioOpd77: boolean,
    PtObjectiveCardioOpd77Disabled: boolean,
    PtObjectiveCardioOpd78: string,
    PtObjectiveCardioOpd78Disabled: boolean,
    PtObjectiveCardioOpd80: boolean,
    PtObjectiveCardioOpd80Disabled: boolean,
    PtObjectiveCardioOpd81: string,
    PtObjectiveCardioOpd81Disabled: boolean,
    PtObjectiveCardioOpd84: boolean,
    PtObjectiveCardioOpd84Disabled: boolean,
    PtObjectiveCardioOpd86: boolean,
    PtObjectiveCardioOpd86Disabled: boolean,
    PtObjectiveCardioOpd89: boolean,
    PtObjectiveCardioOpd89Disabled: boolean,
    PtObjectiveCardioOpd92: boolean,
    PtObjectiveCardioOpd92Disabled: boolean,
    PtObjectiveCardioOpd97: string,
    PtObjectiveCardioOpd97Disabled: boolean,
    PtObjectiveCardioOpd98: string,
    PtObjectiveCardioOpd98Disabled: boolean,
    PtObjectiveCardioOpd100: string,
    PtObjectiveCardioOpd100Disabled: boolean,
    PtObjectiveCardioOpd101: string,
    PtObjectiveCardioOpd101Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const PTObjectiveCardiopulmonaryOPDInitialViewData: PTObjectiveCardiopulmonaryOPDViewDataType = {
    PtObjectiveCardioOpd2: "",
    PtObjectiveCardioOpd2Disabled: false,
    CtObservation: "",
    CtObservationDisabled: false,
    CtObservationOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveCardioOpd7: "",
    PtObjectiveCardioOpd7Disabled: false,
    Ct7Consciousness: "",
    Ct7ConsciousnessDisabled: false,
    Ct7ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct10Communication: "",
    Ct10CommunicationDisabled: false,
    Ct10CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct10CoOperation: "",
    Ct10CoOperationDisabled: false,
    Ct10CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    CtCough: "",
    CtCoughDisabled: false,
    CtCoughOptions: [{key: 0, value: "A", text: "A"}],
    CtBreathingPattern: "",
    CtBreathingPatternDisabled: false,
    CtBreathingPatternOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveCardioOpd28: "",
    PtObjectiveCardioOpd28Disabled: false,
    CtChestMovement: "",
    CtChestMovementDisabled: false,
    CtChestMovementOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveCardioOpd34: "",
    PtObjectiveCardioOpd34Disabled: false,
    CtChestExpansion: "",
    CtChestExpansionDisabled: false,
    CtChestExpansionOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveCardioOpd42: "",
    PtObjectiveCardioOpd42Disabled: false,
    PtObjectiveCardioOpd45: "",
    PtObjectiveCardioOpd45Disabled: false,
    CtPercussionNote: "",
    CtPercussionNoteDisabled: false,
    CtPercussionNoteOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveCardioOpd51: "",
    PtObjectiveCardioOpd51Disabled: false,
    CtAdventitiousSound: "",
    CtAdventitiousSoundDisabled: false,
    CtAdventitiousSoundOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveCardioOpd56: "",
    PtObjectiveCardioOpd56Disabled: false,
    PtObjectiveCardioOpd58: "",
    PtObjectiveCardioOpd58Disabled: false,
    PtObjectiveCardioOpd59: false,
    PtObjectiveCardioOpd59Disabled: false,
    PtObjectiveCardioOpd60: "",
    PtObjectiveCardioOpd60Disabled: false,
    PtObjectiveCardioOpd63: false,
    PtObjectiveCardioOpd63Disabled: false,
    PtObjectiveCardioOpd64: "",
    PtObjectiveCardioOpd64Disabled: false,
    PtObjectiveCardioOpd66: false,
    PtObjectiveCardioOpd66Disabled: false,
    PtObjectiveCardioOpd67: "",
    PtObjectiveCardioOpd67Disabled: false,
    PtObjectiveCardioOpd70: false,
    PtObjectiveCardioOpd70Disabled: false,
    PtObjectiveCardioOpd73: false,
    PtObjectiveCardioOpd73Disabled: false,
    PtObjectiveCardioOpd76: "",
    PtObjectiveCardioOpd76Disabled: false,
    PtObjectiveCardioOpd77: false,
    PtObjectiveCardioOpd77Disabled: false,
    PtObjectiveCardioOpd78: "",
    PtObjectiveCardioOpd78Disabled: false,
    PtObjectiveCardioOpd80: false,
    PtObjectiveCardioOpd80Disabled: false,
    PtObjectiveCardioOpd81: "",
    PtObjectiveCardioOpd81Disabled: false,
    PtObjectiveCardioOpd84: false,
    PtObjectiveCardioOpd84Disabled: false,
    PtObjectiveCardioOpd86: false,
    PtObjectiveCardioOpd86Disabled: false,
    PtObjectiveCardioOpd89: false,
    PtObjectiveCardioOpd89Disabled: false,
    PtObjectiveCardioOpd92: false,
    PtObjectiveCardioOpd92Disabled: false,
    PtObjectiveCardioOpd97: "",
    PtObjectiveCardioOpd97Disabled: false,
    PtObjectiveCardioOpd98: "",
    PtObjectiveCardioOpd98Disabled: false,
    PtObjectiveCardioOpd100: "",
    PtObjectiveCardioOpd100Disabled: false,
    PtObjectiveCardioOpd101: "",
    PtObjectiveCardioOpd101Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtObjectiveCardioOpd1}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd2}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd2}
                    disabled={ props.viewData.PtObjectiveCardioOpd2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd2',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd3}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Objective</strong></p>
                </div>
            </div>
            <div className={styles.CtObservation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtObservation}
                    disabled={ props.viewData.CtObservationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "CtObservation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtObservationOptions}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd7}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd7}
                    disabled={ props.viewData.PtObjectiveCardioOpd7Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd7',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd8}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.Ct7Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct7Consciousness}
                    disabled={ props.viewData.Ct7ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "Ct7Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct7ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct10Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct10Communication}
                    disabled={ props.viewData.Ct10CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "Ct10Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct10CommunicationOptions}
                />
            </div>
            <div className={styles.Ct10CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct10CoOperation}
                    disabled={ props.viewData.Ct10CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "Ct10CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct10CoOperationOptions}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.CtCough}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCough}
                    disabled={ props.viewData.CtCoughDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "CtCough",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCoughOptions}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd24}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Cough</strong></p>
                </div>
            </div>
            <div className={styles.CtBreathingPattern}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtBreathingPattern}
                    disabled={ props.viewData.CtBreathingPatternDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "CtBreathingPattern",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtBreathingPatternOptions}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd28}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd28}
                    disabled={ props.viewData.PtObjectiveCardioOpd28Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd28',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd29}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Breathing pattern</strong></p>
                </div>
            </div>
            <div className={styles.CtChestMovement}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtChestMovement}
                    disabled={ props.viewData.CtChestMovementDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "CtChestMovement",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtChestMovementOptions}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd34}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd34}
                    disabled={ props.viewData.PtObjectiveCardioOpd34Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd34',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd35}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Chest movement</strong></p>
                </div>
            </div>
            <div className={styles.CtChestExpansion}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtChestExpansion}
                    disabled={ props.viewData.CtChestExpansionDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "CtChestExpansion",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtChestExpansionOptions}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd42}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd42}
                    disabled={ props.viewData.PtObjectiveCardioOpd42Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd42',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd43}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Chest expansion</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd45}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd45}
                    disabled={ props.viewData.PtObjectiveCardioOpd45Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd45',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtPercussionNote}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtPercussionNote}
                    disabled={ props.viewData.CtPercussionNoteDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "CtPercussionNote",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtPercussionNoteOptions}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd49}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Percussion Note</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd51}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd51}
                    disabled={ props.viewData.PtObjectiveCardioOpd51Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd51',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtAdventitiousSound}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtAdventitiousSound}
                    disabled={ props.viewData.CtAdventitiousSoundDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "CtAdventitiousSound",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtAdventitiousSoundOptions}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd55}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Adventitions sound</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd56}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd56}
                    disabled={ props.viewData.PtObjectiveCardioOpd56Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd56',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd58}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd58}
                    disabled={ props.viewData.PtObjectiveCardioOpd58Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd58',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd59}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd59}
                    disabled={ props.viewData.PtObjectiveCardioOpd59Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd59"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd60}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd60}
                    disabled={ props.viewData.PtObjectiveCardioOpd60Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd60',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd61}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle length</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd63}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd63}
                    disabled={ props.viewData.PtObjectiveCardioOpd63Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd63"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd64}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd64}
                    disabled={ props.viewData.PtObjectiveCardioOpd64Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd64',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd65}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd66}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd66}
                    disabled={ props.viewData.PtObjectiveCardioOpd66Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd66"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd67}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd67}
                    disabled={ props.viewData.PtObjectiveCardioOpd67Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd67',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd69}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd70}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd70}
                    disabled={ props.viewData.PtObjectiveCardioOpd70Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd70"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd71}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd73}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd73}
                    disabled={ props.viewData.PtObjectiveCardioOpd73Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd73"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd74}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd75}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd76}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd76}
                    disabled={ props.viewData.PtObjectiveCardioOpd76Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd76',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd77}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd77}
                    disabled={ props.viewData.PtObjectiveCardioOpd77Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd77"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd78}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd78}
                    disabled={ props.viewData.PtObjectiveCardioOpd78Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd78',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd79}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle tone</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd80}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd80}
                    disabled={ props.viewData.PtObjectiveCardioOpd80Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd80"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd81}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd81}
                    disabled={ props.viewData.PtObjectiveCardioOpd81Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd81',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd83}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd84}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd84}
                    disabled={ props.viewData.PtObjectiveCardioOpd84Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd84"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd85}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spasticity</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd86}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd86}
                    disabled={ props.viewData.PtObjectiveCardioOpd86Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd86"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd87}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Flaccid</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd88}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd89}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd89}
                    disabled={ props.viewData.PtObjectiveCardioOpd89Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd89"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd90}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Motor Power:</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd92}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveCardioOpd92}
                    disabled={ props.viewData.PtObjectiveCardioOpd92Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveCardiopulmonaryOPD",
                                name: "PtObjectiveCardioOpd92"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveCardioOpd93}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Right</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd97}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd97}
                    disabled={ props.viewData.PtObjectiveCardioOpd97Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd97',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd98}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd98}
                    disabled={ props.viewData.PtObjectiveCardioOpd98Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd98',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "120%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd100}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd100}
                    disabled={ props.viewData.PtObjectiveCardioOpd100Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd100',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd101}>
                <Input
                    value={props.viewData.PtObjectiveCardioOpd101}
                    disabled={ props.viewData.PtObjectiveCardioOpd101Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'PtObjectiveCardioOpd101',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "120%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveCardioOpd104}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Left</p>
                </div>
            </div>
            <div className={styles.PtObjectiveCardioOpd108}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PTObjectiveCardiopulmonaryOPD',
                            name: 'SaveRequest'
                        }
                    })}}
                    style={{color: "#FFFFFF",backgroundColor: "#27AE60", height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
