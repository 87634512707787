import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Button,
  Dropdown,
  Icon
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardUDSegmentMemberUX = (props: any) => {
    return(
      <div
        style={{padding: "15px"}}>
        <div
          style={{ padding: "1rem 0"}}>
          {}
          <label
            style={{color: "#2185d0", fontSize: "1.2rem", fontWeight: "bold"}}>
            เพิ่มสมาชิกกลุ่ม
          </label>
        </div>
        <div
          className="ui form"
          style={{marginTop: "1rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                เพิ่มรายบุคคล : 
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{width: "100%"}}>
                {props.PatientSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label>
                เลือกกลุ่มย่อย: 
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Dropdown
                fluid={true}
                onChange={props.onChangeSubSegment}
                options={props.subSegmentOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.subSegment || ""}>
              </Dropdown>
            </FormField>
            <FormField>
              <div>
                {props.ButtonAdd}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                เพิ่มเป็นกลุ่ม: 
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                action={{content: "Browse", color: "teal", onClick: props.onBrowse}}
                readOnly={true}
                value={props.fileName || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.ButtonImport}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                basic={true}
                color="blue"
                disabled={props.disabledDownloadTemplate || false}
                onClick={props.onDownloadTemplate}>
                Download templete
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{marginTop: "2.5rem", padding: "0 10px"}}>
          
          <FormGroup>
            <FormField>
              <label
                style={{margin: 0}}>
                ค้นหาสมาชิกกลุ่ม
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{textWeight: "normal"}}>
                HN: 
              </label>
            </FormField>
            <FormField>
              <Input
                name="hn"
                onChange={props.onChangeValue}
                value={props.hn || ""}>
              </Input>
            </FormField>
            <FormField>
              <label
                style={{textWeight: "normal", minWidth: "max-content"}}>
                ชื่อ-นามสกุล
              </label>
            </FormField>
            <FormField>
              <Input
                name="fullName"
                onChange={props.onChangeValue}
                value={props.fullName || ""}>
              </Input>
            </FormField>
            <FormField>
              <label
                style={{textWeight: "normal",minWidth: "max-content"}}>
                กลุ่มย่อย: 
              </label>
            </FormField>
            <FormField>
              <Dropdown
                clearable={true}
                name="subSegmentId"
                onChange={props.onChangeValue}
                options={props.subSegmentIdOptions|| []}
                selection={true}
                value={props.subSegmentId|| ""}>
              </Dropdown>
            </FormField>
            <FormField>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <div
              className="field"
              style={{flex: 1}}>
              
            </div>
            <FormField>
              <Button
                color="yellow"
                onClick={props.onEdit}
                size="tiny">
                
                <Icon
                  name="edit">
                </Icon>
                <label>
                  แก้ไข
                </label>
              </Button>
            </FormField>
            <FormField>
              <Button
                color="red"
                onClick={props.onRemove}
                size="tiny">
                
                <Icon
                  name="trash alternate">
                </Icon>
                <label>
                  ลบ
                </label>
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{padding: "0 10px"}}>
          
          <Table
            data={props.patientSegmentList || []}
            headers={props.headers ? props.headers : "HN,ชื่อ-นามสกุล, วัน เดือน ปีเกิด [อายุ],กลุ่มย่อย"}
            keys="chk,hn,full_name, birthdate,result"
            minRows="3"
            showPagination={false}
            style={{maxHeight: "49vh"}}
            widths="80">
          </Table>
        </div>
      </div>
    )
}

export default React.memo(CardUDSegmentMemberUX)

export const screenPropsDefault = {}

/* Date Time : Thu Mar 30 2023 08:02:52 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"1rem 0\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มสมาชิกกลุ่ม"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#2185d0\", fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormGroup",
      "parent": 15,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 15,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายบุคคล : "
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกกลุ่มย่อย: "
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มเป็นกลุ่ม: "
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 27,
      "props": {
        "action": {
          "type": "code",
          "value": "{content: \"Browse\", color: \"teal\", onClick: props.onBrowse}"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.fileName || \"\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 29,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "Download templete"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDownloadTemplate || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDownloadTemplate"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 21,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSubSegment"
        },
        "options": {
          "type": "code",
          "value": "props.subSegmentOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.subSegment || \"\""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 16,
      "props": {
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2.5rem\", padding: \"0 10px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormGroup",
      "parent": 38,
      "props": {
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormGroup",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 39,
      "props": {
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาสมาชิกกลุ่ม"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 40,
      "props": {
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 40,
      "props": {
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 40,
      "props": {
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 40,
      "props": {
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 40,
      "props": {
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 40,
      "props": {
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "HN: "
        },
        "style": {
          "type": "code",
          "value": "{textWeight: \"normal\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{textWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "กลุ่มย่อย: "
        },
        "style": {
          "type": "code",
          "value": "{textWeight: \"normal\",minWidth: \"max-content\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Input",
      "parent": 44,
      "props": {
        "name": {
          "type": "value",
          "value": "hn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.hn || \"\""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Input",
      "parent": 46,
      "props": {
        "name": {
          "type": "value",
          "value": "fullName"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.fullName || \"\""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 48,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "subSegmentId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.subSegmentIdOptions|| []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.subSegmentId|| \"\""
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormGroup",
      "parent": 38,
      "props": {
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 57,
      "props": {
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 57,
      "props": {
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Button",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onEdit"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Button",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRemove"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Icon",
      "parent": 61,
      "props": {
        "name": {
          "type": "value",
          "value": "edit"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไข"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Icon",
      "parent": 63,
      "props": {
        "name": {
          "type": "value",
          "value": "trash alternate"
        },
        "onClick": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "ลบ"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 69,
      "name": "Table",
      "parent": 68,
      "props": {
        "data": {
          "type": "code",
          "value": "props.patientSegmentList || []"
        },
        "headers": {
          "type": "code",
          "value": "props.headers ? props.headers : \"HN,ชื่อ-นามสกุล, วัน เดือน ปีเกิด [อายุ],กลุ่มย่อย\""
        },
        "keys": {
          "type": "value",
          "value": "chk,hn,full_name, birthdate,result"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"49vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "80"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonAdd"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonImport"
        }
      },
      "seq": 72,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": true,
  "name": "CardUDSegmentMemberUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
