import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import { HeaderLogoWithAddress } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

const FORM_NAME = "FormInpatientDiagnosis";

const FormInpatientDiagnosis = async (props: any) => {
  const headerForm = await HeaderLogoWithAddress({
    pageMargins: [15, 140, 0, 50],
    font: "THSarabunNew",
    form: FORM_NAME,
    logoHeight: 30,
    styles: {
      title: {
        bold: true,
        fontSize: 18,
        alignment: "center",
      },
      header: {
        fontSize: 18,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 16,
        alignment: "center",
      },
      tableDetail: {
        fontSize: 14,
        alignment: "center",
      },
      addressRightStyle: {
        fontSize: 12,
        alignment: "right",
      },
      addressLeftStyle: {
        fontSize: 12,
        alignment: "left",
      },
    },
    titleContent: [
      {
        text: "รายงานสถิติวันนอนเฉลี่ยใน แต่ละ Diagnosis (สำหรับผู้ป่วยใน)",
        style: "title",
      },
      {
        text: `วันที่ ${props?.start_date} - ${props?.end_date}`,
        style: "header",
      },
    ],
  });
  const { font, images, styles } = headerForm;

  return {
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 12,
    },
    pageSize: "A4",
    ...styles,
    ...headerForm,
    content: [
      {
        margin: [20, 0, 10, 10],
        table: {
          widths: ["8%", "25%", "*", "15%"],
          body: [
            [
              { text: "ลำดับ", style: "tableHeader" },
              { text: "รหัสโรค / ICD-10 Code", style: "tableHeader" },
              { text: "Diagnosis / ICD-10 Term", style: "tableHeader" },
              { text: "วันนอน (เฉลี่ย)", style: "tableHeader" },
            ],
            ...(props?.data || []).map((item: any, index: number) => [
              { text: item.no, style: "tableDetail" },
              { text: item.icd10_code, style: "tableDetail" },
              { text: item.icd10_term, style: "tableDetail" },
              { text: item.avg_admit_date, style: "tableDetail" },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      const printDateMM = moment();
      return {
        stack: [
          {
            margin: [40, 0, 20, 0],
            columns: [
              {
                width: "50%",
                stack: [
                  {
                    text: `วันเวลาที่พิมพ์ : ${formatDate(printDateMM)} [${printDateMM.format(
                      "HH:mm"
                    )}]`,
                    style: "addressLeftStyle",
                  },
                  {
                    text: `ผู้ออกรายงาน : ${props?.print_user || ""}`,
                    style: "addressLeftStyle",
                  },
                ],
              },
              {
                width: "50%",
                stack: [
                  {
                    text: `หน้าที่ ${currentPage} / ${pageCount}`,
                    style: "addressRightStyle",
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    images: {
      ...images,
    },
  };
};

export default FormInpatientDiagnosis;
