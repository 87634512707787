import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

// PDF
const PDF = "/static/pdf/taxDoctor/280360_WHT3_kor.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf";

const FormPND3AReport = async (data: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Load your custom font
    const fontUrl = Font; // Replace with your font URL
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const getDecimal = (value: number) => {
      let rounded = Math.floor(value);
      
      return (value - rounded || 0).toFixed(2)?.toLocaleString().slice(2,4);
    };

    const fontSize = 12;
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const textCoordinates = [
      { text: data?.officerId, x: 172, y: 721.5 },

      { text: data?.branch, x: 301, y: 692.5 },

      { text: data?.payerName, x: 60, y: 676 },
      { text: data?.buildingName, x: 105, y: 660 },
      { text: data?.roomNo, x: 193, y: 661 },
      { text: data?.floor, x: 231, y: 661 },
      { text: data?.village, x: 269, y: 661 },
      { text: data?.No, x: 75, y: 645 },
      { text: data?.villageNo, x: 162, y: 645 },
      { text: data?.alley, x: 215, y: 645 },
      { text: data?.Junction, x: 300, y: 644 },
      { text: data?.street, x: 73, y: 630 },
      { text: data?.subDistrict, x: 217, y: 630 },
      { text: data?.district, x: 100, y: 615 },
      { text: data?.province, x: 219, y: 615 },
      { text: data?.zipCode, x: 108, y: 602 },

      { text: data?.year, x: 515, y: 700 },

      { text: data?.specialSubmitNo?.toString(), x: 530, y: 638 },
      { text: data?.doctor_count?.toString(), x: 505, y: 497 },
      { text: data?.total_page?.toString(), x: 505, y: 479 },

      {
        text: data?.recordingInComputerQuantity?.toString(),
        x: 505,
        y: 463,
      },
      { text: data?.recordingInComputerList?.toString(), x: 505, y: 445 },
      { text: data?.registerNo?.toString(), x: 505, y: 433 },
      { text: data?.registerRefNo?.toString(), x: 505, y: 420 },
      {
        text: Number(data?.total_net_income_price?.toFixed(0))?.toLocaleString(),
        x:
          524 -
          customFont.widthOfTextAtSize(
            (data?.total_net_income_price || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 361,
      },
      {
        text:
          data?.total_net_income_price === 0
            ? "00"
            : getDecimal(Number(data?.total_net_income_price)),
        x: 532,
        y: 361,
      },
      {
        text: Number(data?.total_tax?.toFixed(0))?.toLocaleString(),
        x: 524 - customFont.widthOfTextAtSize((data?.total_tax || 0)?.toFixed(0)?.toLocaleString(), fontSize),
        y: 341,
      },
      {
        text: data?.total_tax === 0 ? "00" : getDecimal(Number(data?.total_tax)),
        x: 532,
        y: 341,
      },
      { text: data?.payerFullName, x: 280, y: 260 },

      { text: data?.position, x: 280, y: 244 },
      { text: data?.submitDate, x: 245, y: 229 },
      { text: data?.submitMonth, x: 296, y: 229 },
      { text: data?.submitYear, x: 367, y: 229 },
      // Add other text fields using data? here...
    ];

    for (const { text, x, y } of textCoordinates) {
      let adjustedX = x; // Initialize adjustedX to the original x coordinate

      if (text === data?.officerId) {
        // Loop through each character in data?.taxId
        for (let j = 0; j < data?.officerId?.length; j++) {
          const charSpacing = 6.05;
          if (j === 1 || j === 5 || j === 10 || j === 12) {
            adjustedX += charSpacing;
          } // Apply spacing adjustment if necessary

          // Get the current character from taxId
          const char = data?.officerId[j];

          // Draw the character at the adjustedX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Increment adjustedX by the width of the character plus the spacing adjustment
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else if (text === data?.branch) {
        const charSpacing = 3.3;

        for (let j = 0; j < data?.branch?.length; j++) {
          const char = data?.branch[j];

          // Adjust spacing for specific letters
          if (j === 1 || j === 2 || j === 3) {
            adjustedX += charSpacing; // Increase the X coordinate by charSpacing
          }

          // Draw the character at the currentX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Update currentX with character width and spacing
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else if (text === data?.zipCode) {
        const charSpacing = 3;

        for (let j = 0; j < data?.zipCode?.length; j++) {
          const char = data?.zipCode[j];

          // Adjust spacing for specific letters
          if (j === 1 || j === 2 || j === 3 || j === 4 || j === 5) {
            adjustedX += charSpacing; // Increase the X coordinate by charSpacing
          }

          // Draw the character at the currentX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Update currentX with character width and spacing
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else {
        // Draw the text as it is without any adjustments
        firstPage.drawText(text, {
          x,
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      }
    }
    const checkSymbol = "\u2713"; // ✓ Check symbol
    const falseText = ""; // Empty text when isMoneyType is false

    // Adjust coordinates for each check symbol
    const isCheckTextCoordinates = [
      {
        text: checkSymbol,
        x: 350,
        y: 635,
      },
      {
        text: data?.isSpecialSubmit ? checkSymbol : falseText,
        x: 435,
        y: 635,
      },
      {
        text: checkSymbol,
        x: 262,
        y: 495,
      },
      {
        text: data?.isRecordingInComputer ? checkSymbol : falseText,
        x: 262,
        y: 460,
      },
    ];

    for (const { text, x, y } of isCheckTextCoordinates) {
      firstPage.drawText(text, {
        x,
        y,
        size: 12,
        font: zapFont,
        color: rgb(0, 0, 0),
      });
    }
    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfUrl = URL.createObjectURL(
      new Blob([modifiedPdfBytes], { type: "application/pdf" })
    );

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl };
  } catch (error) {
    console.error("Error modifying PDF:", error);

    return {};
  }
};

export default FormPND3AReport;
