import React, { useState, useRef , useEffect} from "react";
import { Loader } from "semantic-ui-react";
import { useIntl } from "react-intl";
interface CardCSMedicationProps {
  csMedicationList: any;
  patientDataAPI: any;
  handleGetCSMedication:any;
}



const CardCSMedication = (props: CardCSMedicationProps) => {

  const isMounted = useRef(true)
  const [isMedicationLoading, setIsMedicationLoading] = useState(false)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {

    const loadMedication = async () => {
      setIsMedicationLoading(true)
      await props.handleGetCSMedication()
      setIsMedicationLoading(false)
    }
    loadMedication()

  }, [props.patientDataAPI])

  console.log("CardCSMedication  props.csMedicationList,", props.csMedicationList)
  const createRowMedications = () => {
    if(props.csMedicationList?.items?.length >0){
      return props.csMedicationList?.items?.map((item, index) => {
        return <div key={index} className="row">
          <div className="row-left"><span style={{fontWeight:"bold"}}>{item.name}</span> <br/><span>{item.label}</span></div>
          <div className="row-right">{item.order_time.replace('[','').replace(']','')}</div>
          
          </div>
      })
    }else{
      return <div className="row-no-data">No data</div>
    }
  };

  return (
    <div className="card-cs card-cs-medication" style={{
      ...( isMedicationLoading && { 
        opacity:" 0.5",
        top:0,
        left:0,
        width:"100%",
        height:"400px",
      })}
    }>
      <div className="patient-card-header">
        Current Medications
      </div>
      <div className="sheet">
        { isMedicationLoading ? <div style={{textAlign: "center"}}> Loading... </div>: createRowMedications()}
      </div>
    </div>
  )
}

export default CardCSMedication
