import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import config from '../../../../../config/config'
import { Response, Error } from '../../common'
const HOST = `${config.API_HOST}`

export interface BillTransactionIPDItemSerializer {
    id?: any,
    valid?: any,
    error_message?: any,
    created_by?: any,
    created_at?: any,
    active?: any,
    bill_transaction_ipd_ref?: any,
    invoice_item_ref?: any,
    sequence?: any,
    service_date?: any,
    bill_group?: any,
    lc_code?: any,
    description?: any,
    qty?: any,
    unit_price?: any,
    charge_amount?: any,
    discount?: any,
    procedure_sequence?: any,
    diagnosis_sequence?: any,
    claim_system?: any,
    bill_group_cs?: any,
    cs_code?: any,
    code_sys?: any,
    std_code?: any,
    claim_cat?: any,
    revision_date?: any,
    claim_unit_price?: any,
    claim_amount?: any,
}


/* ['generics.ListAPIView'] */
/* params: 'bill_transaction_ipd', 'bill_transaction_ipd' */
/* data:  */
export const BillTransactionIPDItemList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/INF/bill-transaction-ipd-item/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveUpdateAPIView'] */
/* params:  */
/* data:  */
export const BillTransactionIPDItemDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: BillTransactionIPDItemSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: BillTransactionIPDItemSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/INF/bill-transaction-ipd-item-detail/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/INF/bill-transaction-ipd-item-detail/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/INF/bill-transaction-ipd-item-detail/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

