import JsBarcode from "jsbarcode";

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

const FormStickerResuscitationBox = (props: any) => {
  console.log("FormStickerResuscitationBox props: ", props);

  return {
    pageSize: {
      width: 300,
      height: 150,
    },
    pageOrientation: "landscape",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 0.8,
      fontSize: 15,
    },
    pageMargins: [0, 0, 0, 0],
    styles: {
      fieldKey: {
        bold: true,
        fontSize: 15,
      },
      fieldValue: {
        bold: true,
        fontSize: 15,
      },
      miniFieldValue: {
        fontSize: 14,
      },
    },
    content: [
      {
        stack: [
          {
            margin: [5, -2.5, 0, 0],
            text: props.patientName || " ",
            style: "fieldKey",
            fontSize: 18,
            preserveLeadingSpaces: true,
          },
          {
            table: {
              widths: [12, "*"],
              body: [
                [
                  {
                    layout: "noBorders",
                    fillColor: "#000000",
                    stack: [
                      {
                        svg: `<svg width="12" height="75.590">
                          <rect width="12" height="75.590" fill="black" />
                          <text x="-297.5%" y="9" dominant-baseline="middle" text-anchor="middle"
                            fill="white" transform="rotate(270 0,0)"
                            font-size="18" font-family="THSarabunNew" font-weight="bold">${props.data.code}</text>
                        </svg>`,
                      },
                    ],
                  },
                  {
                    layout: "noBorders",
                    margin: [2.5, 0, 2.5, 0],
                    stack: [
                      {
                        columns: [
                          {
                            width: "8%",
                            text: "ชื่อ: ",
                            style: "fieldKey",
                          },
                          {
                            width: "*",
                            text: props.data.container_name,
                            style: "fieldValue",
                            alignment: "left",
                            margin: [0, 0, 2.5, 0],
                          },
                        ],
                      },
                      {
                        columns: [
                          {
                            image: textToBase64BarcodeBlob(
                              `${props.data.code || ""}`
                            ),
                            width: 250,
                            height: 50,
                            margin: [-3, 1, 0, 0],
                          },
                        ],
                      },
                      {
                        margin: [0, -3, 0, 0],
                        columns: [
                          {
                            text: "เลขที่ตัวล็อคกล่อง:",
                            width: "30%",
                            style: "fieldKey",
                          },
                          {
                            width: "*",
                            margin: [0, 0, 2.5, 0],
                            text: props.data.break_code,
                            alignment: "left",
                            style: "fieldValue",
                          },
                        ],
                      },
                      {
                        margin: [0, -3, 0, 0],
                        columns: [
                          {
                            text: "แผนก:",
                            width: "15%",
                            style: "fieldKey",
                          },
                          {
                            width: "*",
                            margin: [0, 0, 2.5, 0],
                            text: props.data.destination_division_name,
                            alignment: "left",
                            style: "fieldValue",
                          },
                        ],
                      },
                      {
                        margin: [0, -3, 0, 0],
                        columns: [
                          {
                            text: "ผู้จัด:",
                            width: "15%",
                            style: "fieldKey",
                          },
                          {
                            width: "*",
                            margin: [0, 0, 2.5, 0],
                            text: props.data.edit_user_fullname,
                            alignment: "left",
                            style: "fieldValue",
                          },
                        ],
                      },
                      {
                        margin: [0, -3, 0, 0],
                        columns: [
                          {
                            text: "วันที่:",
                            width: "15%",
                            style: "fieldKey",
                          },
                          {
                            width: "*",
                            margin: [0, 0, 2.5, 0],
                            text: props.data.edited,
                            alignment: "left",
                            style: "fieldValue",
                          },
                        ],
                      },
                    ],
                  },
                ],
              ],
            },
          },
        ],
      },
    ],
  };
};

export default FormStickerResuscitationBox;
