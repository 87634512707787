import React, { useState, useEffect, CSSProperties, useMemo } from "react";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import CardQueueDoctorUX from "./CardQueueDoctorUX";
import moment from "moment";
import config from "config/config";
import "react-lib/apps/IsHealth/css/QueueForPatient.scss";
import { useIntl } from "react-intl";

const hospital = "โรงพยาบาล มอร์เฮลท์ แคร์";
const IMAGE = {
  logo: "images/IHimage.png",
};

const dateDefault = dateToStringWithoutTimeBE(new Date());

const CardQueueDoctor = (props: any) => {
  const intl = useIntl();
  const [time, setTime] = useState<any>();
  const [viewIndex, setViewIndex] = useState<number>(0);

  useEffect(() => {
    props.onEvent({
      message: "QueueForPatient",
      params: { action: "queueDoctor" },
    });
  }, []);

  useEffect(() => {
    let list = setInterval(
      () =>
        props.onEvent({
          message: "QueueForPatient",
          params: { action: "queueDoctor" },
        }),
      1000 * 30
    );
    return () => {
      clearInterval(list);
    };
  }, []);
  useEffect(() => {
    let timeId = setInterval(
      () => setTime(moment(new Date())?.format("HH:mm:ss")),
      1000
    );
    return () => {
      clearInterval(timeId);
    };
  }, []);

  useEffect(() => {
    let timeId = setInterval(
      () => setViewIndex((index) => index + 1),
      1000 * config.QUEUE_DOCTOR_REFRESH_MINUTE
    );
    return () => {
      clearInterval(timeId);
    };
  }, []);

  const lab = useMemo(() => {
    const labList: any = [];
    for (let index = 0; index < 6; index++) {
      labList.push(props?.queueDoctor?.labList?.items?.[index]);
    }
    return labList;
  }, [props?.queueDoctor?.labList?.items]);

  const queue = useMemo(() => {
    const listQueue: any = [];
    const items = props.queueDoctor?.doctorList?.items || [];
    const index = viewIndex % items.length;
    const limit = 3;
    const array = items.slice(index, index + limit);
    const list =
      items.length <= limit
        ? items
        : [...array, ...items.slice(0, limit - array.length)];

    for (let index = 0; index < 3; index++) {
      listQueue.push(list?.[index]);
    }

    return listQueue;
  }, [props.queueDoctor?.doctorList?.items, viewIndex]);

  return (
    <>
      <CardQueueDoctorUX
        hospital={hospital}
        division={
          props.masterOptions?.division?.find(
            (options: any) => options.value === props.queueDoctor?.division
          )?.text
        }
        date={dateDefault}
        time={time}
        logo={IMAGE.logo}
        openFullScreen={props.openFullScreen}
        onClose={() => {
          props.setOpenFullScreen(false);
        }}
        countLab={props?.queueDoctor?.labList?.count_encounter?.length || 0}
        listLab={
          <div style={{ flexWrap: "wrap" }}>
            {(lab || [])?.map((item: any, index: any) => {
              return <ItemLab item={item} index={index} />;
            })}
          </div>
        }
        listCancel={
          <div
            className="QueueForPatient"
            style={{
              padding:
                props.queueDoctor?.cancelQueueList?.items?.lengt > 5
                  ? "0 5rem"
                  : "0px",
              width: "100%",
            }}
          >
            <div
              className={
                props.queueDoctor?.cancelQueueList?.items?.length > 5
                  ? "marquee"
                  : ""
              }
              style={{
                display:
                  props.queueDoctor?.cancelQueueList?.items?.length > 5
                    ? ""
                    : "flex",
              }}
            >
              {(props.queueDoctor?.cancelQueueList?.items || [])?.map(
                (item: any, index: number) => {
                  return (
                    <ItemCancel
                      item={
                        props.queueDoctor?.cancelQueueList?.items?.length > 5
                          ? `${props?.queueDoctor?.cancelQueueList?.items}`
                          : item
                      }
                      index={index}
                      count={props.queueDoctor?.cancelQueueList?.items?.length}
                    />
                  );
                }
              )}
            </div>
          </div>
        }
        listDoctor={
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(queue || [])?.map((item: any, index: number) => {
              return <ItemDoctor item={item} index={index} />;
            })}
          </div>
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

const ItemDoctor = (props: any) => {
  const intl = useIntl();
  const list: any = [];
  for (let index = 0; index < 6; index++) {
    list.push(props?.item?.encounter_numbers?.[index]);
  }

  const listItem = list?.map((item: any, index: number) => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            margin: "15px",
            padding: "10px",
            width: "92%",
            height: "50px",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {item}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div
      style={{
        backgroundColor: "#C6EBF3",
        borderRadius: "10px",
        marginRight: "10px",
        width: "32%",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
          margin: "10px",
          padding: "10px",
          height: "50px",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "22px",
            justifyContent: "center",
            display: "flex",
            color: "#026EBC",
          }}
        >
          {props?.item?.doctor_fullname}
        </div>
      </div>

      <div>{listItem}</div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "10px",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            alignItems: "center",
            display: "flex",
          }}
        >{intl.formatMessage({ id: "จำนวนที่รอตรวจ" })}</div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            margin: "10px",
            padding: "10px",
            width: "15%",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {props?.item?.encounter_numbers?.length || 0}
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemLab = (props: any) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        margin: "15px",
        padding: "10px",
        width: "30%%",
        height: "50px",
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: "22px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {props?.item}
      </div>
    </div>
  );
};

const ItemCancel = (props: any) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
          {props?.item},
        </div>
      </div>
    </>
  );
};

export default CardQueueDoctor;
