import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getAdverseReaction", params: any }
  |  { message: "getAdverseReactionList", params: any }
  |  { message: "postAdverseReaction", params: any }
  |  { message: "putAdverseReaction", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getAdverseReaction: Handler = async (controller, {}) => {
//function (patientId) { return __awaiter(_this, void 0, void 0, function () {
//            var adrService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        adrService = new ADRService_1.default();
//                        return [4 /*yield*/, utils_1.to(adrService.getAdverseReaction(patientId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (id) {
//        var url = apis_1.ADR.ADVERSE_REACTION({ patient: id });
//        return this.client
//            .get(url)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ADR/adverse-reaction/?patient=' +
//((__t = (patient)) == null ? '' : __t) +
//'&approve_only=true&exclude_unused=true&severe_first=true';
//
//}
//return __p
//}
}

export const getAdverseReactionList: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, patientId = _b.patientId, today = _b.today, excludeCancel = _b.excludeCancel, byUser = _b.byUser, excludeUnused = _b.excludeUnused, severeFirst = _b.severeFirst, approveOnly = _b.approveOnly, showNotKnownByDoctor = _b.showNotKnownByDoctor, notApproveOnly = _b.notApproveOnly;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, adrService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (patientId) {
//                                params.patient = patientId;
//                            }
//                            if (today) {
//                                params.today = today;
//                            }
//                            if (excludeCancel) {
//                                params.exclude_cancel = excludeCancel;
//                            }
//                            if (byUser) {
//                                params.by_user = byUser;
//                            }
//                            if (excludeUnused) {
//                                params.exclude_unused = excludeUnused;
//                            }
//                            if (severeFirst) {
//                                params.severe_first = severeFirst;
//                            }
//                            if (approveOnly) {
//                                params.approve_only = approveOnly;
//                            }
//                            if (showNotKnownByDoctor) {
//                                params.show_not_known_by_doctor = showNotKnownByDoctor;
//                            }
//                            if (notApproveOnly) {
//                                params.not_approve_only = notApproveOnly;
//                            }
//                            adrService = new ADRService_1.default();
//                            return [4 /*yield*/, utils_1.to(adrService.getAdverseReactionList({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.ADR.ADVERSE_REACTION_LIST, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ADR/adverse-reaction/
}

export const postAdverseReaction: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, patientId = _b.patientId, action = _b.action, note = _b.note;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, params, adrService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = {};
//                            params = {};
//                            if (patientId) {
//                                data.patient = patientId;
//                            }
//                            if (action) {
//                                data.action = action;
//                            }
//                            data.note = note;
//                            adrService = new ADRService_1.default();
//                            return [4 /*yield*/, utils_1.to(adrService.postAdverseReaction({ params: params, data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .post(apis_1.ADR.ADVERSE_REACTION_LIST, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ADR/adverse-reaction/
}

export const putAdverseReaction: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, patientId = _b.patientId, action = _b.action, note = _b.note, adrId = _b.adrId, changeNote = _b.changeNote;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, adrService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            if (patientId) {
//                                data.patient = patientId;
//                            }
//                            if (action) {
//                                data.action = action;
//                            }
//                            data.note = note;
//                            if (changeNote) {
//                                params.change_note = changeNote;
//                            }
//                            adrService = new ADRService_1.default();
//                            return [4 /*yield*/, utils_1.to(adrService.putAdverseReaction({ params: params, data: data, adrId: adrId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, adrId = _b.adrId;
//        var url = apis_1.ADR.ADVERSE_REACTION_DETAIL({ adr_id: adrId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ADR/adverse-reaction/' +
//((__t = (adr_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}
