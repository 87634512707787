import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import ModInfo from "./ModInfo";
import { useIntl } from "react-intl";

const ModOrderDetail = (props: any) => {
  const intl = useIntl();
  const isMounted = useRef(true);

  const [detail, setDetail] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const refresh = async () => {
      setIsLoading(true)
      const [
        response,
        error,
        network,
      ] = await props.controller.getDoctorOrderDetail(props.orderId);
      setIsLoading(false)
      if (isMounted.current) {
        if (error) {
          toast.error(error);
        } else {
          setDetail(
            <div
              dangerouslySetInnerHTML={{
                __html: response.order_detail,
              }}
            /> as any
          );
        }
      }
    };

    if (props.orderId) {
      refresh();
    }
  }, [props.orderId]);

  useEffect(() => {
    if(!props.open){
      setDetail("")
    }
  }, [props.open])

  return (
    <ModInfo
      btnLoading={isLoading}
      open={!!props.open}
      titleColor="darkblue"
      buttonColor="blue"
      titleName={intl.formatMessage({ id: "รายละเอียด" })}
      alertText={detail}
      btnText={intl.formatMessage({ id: "ปิด" })}
      onApprove={props.hideCallback}
      onClose={props.hideCallback}
    />
  );
};

ModOrderDetail.defaultProps = {
  controller: null,
  hideCallback: () => {},
  open: false,
  orderId: null,
};

ModOrderDetail.propTypes = {
  controller: PropTypes.object,
  hideCallback: PropTypes.func,
  open: PropTypes.bool,
  orderId: PropTypes.number,
};

export default React.memo(ModOrderDetail);
