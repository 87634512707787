import React, { useEffect, useMemo, useState } from "react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI TPD
import CardReportDrugUseRecordUX from "./CardReportDrugUseRecordUX";
import { useIntl } from "react-intl";

const CARD_KEY: string = "CardReportDrugUseRecord";

type CardReportDrugUseRecordProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  ReportDrugUseRecordSequence?: any;
};

const CardReportDrugUseRecord = (props: CardReportDrugUseRecordProps) => {
  const intl = useIntl();

  const [divisionOptions, setDivisionOptions] = useState([]);

  useEffect(() => {
    props.runSequence({ sequence: "ReportDrugUseRecord", restart: true });
  }, []);

  const handelSearch = () => {
    props.runSequence({
      sequence: "ReportDrugUseRecord",
      action: "FETCH",
      cardKey: CARD_KEY,
    });
  };

  const handelPrint = () => {
    props.runSequence({
      sequence: "ReportDrugUseRecord",
      action: "PRINT",
      cardKey: CARD_KEY,
    });
  };

  const createOptions = (data: any[] = []) => {
    return data.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.label || item.name,
      id: item.id,
    }));
  };

  const filterDivisionType = useMemo(() => {
    return props.masterData?.divisionTypeWithId?.filter(
      (d: any) => ["WARD", "WARD_PREMIUM", "CLINIC", "PREMIUM_CLINIC", "OPERATING_ROOM"].includes(d.value)
    );
  }, [props.masterData?.divisionTypeWithId]);

  const divisionTypeOptions = createOptions(filterDivisionType)

  const handleDivisionTypeChange = async (e: any, data: any) => {
    setDivisionOptions([])
    await props.setProp("ReportDrugUseRecordSequence.filterDrugList.division_type", data.value)

    let selectedType = props.masterData?.divisionTypeWithId.find((dt: any) => dt.id === data.value);
    console.log("selectedType", selectedType)
    if (selectedType) {
      let divOptions = props.masterData?.division?.filter(
        (d: any) => d.type === selectedType.id
      );
      // console.log(createOptions(divOptions));
      props.setProp("ReportDrugUseRecordSequence.filterDrugList.division", "");
      setDivisionOptions(createOptions(divOptions))
    }
  }

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <CardReportDrugUseRecordUX
        // function
        setProp={props.setProp}
        // options
        masterOptions={props.masterOptions}
        divisionTypeOptions={divisionTypeOptions}
        divisionOptions={divisionOptions}
        handleDivisionTypeChange={handleDivisionTypeChange}
        // seq
        ReportDrugUseRecordSequence={props.ReportDrugUseRecordSequence}
        // component
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handelSearch}
            // data
            paramKey={`${CARD_KEY}_FETCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_FETCH`]}
            // config
            color="blue"
            size="small"
            title={intl.formatMessage({ id: "แสดง" })}
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handelPrint}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="green"
            size="small"
            title={intl.formatMessage({ id: "พิมพ์รายงาน" })}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

export default React.memo(CardReportDrugUseRecord);
