const FormBedSummary = (props: any) => {
  console.log("FormBedSummary props: ", props);

  ///

  let division =
    props.masterOptions?.divisionIpd?.find(
      (item: any) => item.value === props.selectedDivision
    )?.text || "แสดงทุกหน่วยตรวจ"; // original "แสดงทุกประเภทห้อง"
  console.log("division: ", division);
  let roomStatus =
    props.masterOptions?.roomStatus?.find(
      (item: any) => item.value === props.selectedRoomStatus
    )?.text || "แสดงทุกประเภทห้อง"; // original "แสดงทุกประเภทหอผู้ป่วย"
  console.log("roomStatus: ", roomStatus);
  let roomType =
    props.masterOptions?.roomType?.find(
      (item: any) => item.value === props.selectedRoomType
    )?.text || "แสดงทุกประเภทห้อง"; // original "แสดงทุกหน่วยตรวจ"
  console.log("roomType: ", roomType);
  let wardType =
    props.masterOptions?.wardType?.find(
      (item: any) => item.value === props.selectedWardType
    )?.text || "แสดงทุกประเภทหอผู้ป่วย"; // original "แสดงทุกสถานะห้อง"
  console.log("wardType: ", wardType);
  // selectedDivision : 59 // props.masterOptions?.divisionIpd
  // selectedRoomStatus : "ROOM_RESERVED" // props.masterOptions?.roomStatus
  // selectedRoomType : 11  // props.masterOptions?.roomType
  // selectedWardType : 9 // props.masterOptions?.wardType

  let queryText = `ประเภทห้อง: ${roomType}  ประเภทหอผู้ป่วย: ${wardType}\nหน่วยตรวจ: ${division}  สถานะห้อง: ${roomStatus}`;
  console.log("queryText: ", queryText);

  return {
    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 11,
    },
    pageMargins: [15, 95, 15, 30],
    // A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {
      table: {
        margin: [0, 5, 0, 15],
      },
      header: {
        fontSize: 22,
        bold: true,
        alignment: "center",
      },
      subHeader: {
        fontSize: 15,
        bold: true,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
        fillColor: "lightgray",
      },
      tableData: {},
    },
    header: {
      margin: [0, 20, 0, 0],
      stack: [
        {
          layout: "noBorders",
          // fillColor: "#1b9d2c",
          // fillOpacity: 0.4,
          table: {
            // widths: ["10%", "90%"],
            widths: ["100%"],
            heights: [55],
            body: [
              [
                // {
                //   // margin: [5, 2, 0, 0],
                //   // layout: "noBorders",
                //   // fillColor: "#1b9d2c",
                //   // fillOpacity: 0.4,
                //   // stack: [
                //   //   {
                //   //     image: "logo",
                //   //     fillColor: "#1b9d2c",
                //   //     fillOpacity: 0.4,
                //   //     width: 90,
                //   //   },
                //   // ],
                //   text: ""
                // },
                {
                  margin: [0, 0, 0, 0],
                  stack: [
                    { text: "รายงานเตียง", style: "header" },
                    {
                      text: `${queryText}`,
                      style: "subHeader",
                    },
                  ],
                  alignment: "center",
                  // style: {
                  //   fontSize: 20,
                  //   bold: true,
                  // },
                },
              ],
            ],
          },
        },
      ],
    },
    content: [
      {
        style: "table",
        table: {
          headerRows: 1,
          widths: [40, "*", "*", "*", 30, 100, "*", 80],
          body: [
            [
              {
                text: "เลขห้อง/เตียง",
                style: "tableHeader",
                alignment: "center",
              },
              { text: "Ward", style: "tableHeader", alignment: "center" },
              { text: "ประเภทห้อง", style: "tableHeader", alignment: "center" },
              { text: "HN", style: "tableHeader", alignment: "center" },
              { text: "AN", style: "tableHeader", alignment: "center" },
              {
                text: "ชื่อ - สกุลผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              { text: "เพศ", style: "tableHeader", alignment: "center" },
              { text: "สถานะ", style: "tableHeader", alignment: "center" },
            ],
            ...(props?.data || []).map((item: any) => [
              { text: item.room_no, style: "tableData", alignment: "center" },
              { text: item.ward_name, style: "tableData", alignment: "center" },
              { text: item.room_name, style: "tableData", alignment: "center" },
              { text: item.hn, style: "tableData", alignment: "center" },
              { text: item.an, style: "tableData", alignment: "center" },
              {
                text: item.patient_name,
                style: "tableData",
                alignment: "left",
              },
              {
                text: item.patient_gender,
                style: "tableData",
                alignment: "center",
              },
              { text: item.room_status, style: "tableData", alignment: "left" },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      let pageText = currentPage.toString() + " of " + pageCount;
      return { text: pageText, alignment: "center" };
    },
    // footer: function (currentPage: number, pageCount: number) {
    //   return {
    //     margin: [20, 0, 20, 0],
    //     stack: [
    //       {
    //         text: `Approved by: ${props.doctorDetail.full_name || ""} (ว.${
    //           props.doctorDetail?.certificate_no || ""
    //         })`,
    //         style: {
    //           bold: true,
    //           alignment: "right",
    //         },
    //       },
    //       {
    //         table: {
    //           widths: ["50%", "40%", "10%"],
    //           body: [
    //             [
    //               {
    //                 borderColor: ["#646464", "#646464", "#646464", "#646464"],
    //                 border: [false, true, false, false],
    //                 text: `HN: ${props.patient?.hn} ${props.patient?.full_name_th} DOB: ${props.patient?.birthdate}`,
    //                 italics: true,
    //                 style: {
    //                   fontSize: 12,
    //                   color: "#5A5A5A",
    //                 },
    //               },
    //               {
    //                 border: [false, true, false, false],
    //                 text: `Printed date: ${props.date} [${props.time}]`,
    //                 italics: true,
    //                 style: {
    //                   fontSize: 12,
    //                   color: "#5A5A5A",
    //                 },
    //               },
    //               {
    //                 border: [false, true, false, false],
    //                 text: `${currentPage.toString()} / ${pageCount}`,
    //                 italics: true,
    //                 style: {
    //                   fontSize: 12,
    //                   color: "#5A5A5A",
    //                   alignment: "right",
    //                 },
    //               },
    //             ],
    //           ],
    //         },
    //       },
    //     ],
    //   };
    // },
    // images: {
    //   logo:
    //     origin + "/static/images/laboratory_examination_summary/logoMor.png",
    //   queue: origin + "/static/images/menus/queue.png",
    // },
  };
};

export default FormBedSummary;
