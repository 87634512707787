import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  Dropdown,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const CardReAssessmentRiskFallUX = (props: any) => {
    return(
      <div
        style={{position: "relative", marginTop: "1rem"}}>
        <div
          style={{ marginLeft: "1rem" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "4rem", whiteSpace: "normal"}}>
              Score:
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.riskFallQuestion?.riskFallScore === "Assessment"}
                label="Assessment"
                name="riskFallQuestion.riskFallScore"
                onChange={props.onChangeData}
                value="Assessment">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.riskFallQuestion?.riskFallScore === "Re-assessment"}
                label="Re-assessment"
                name="riskFallQuestion.riskFallScore"
                onChange={props.onChangeData}
                value="Re-assessment">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "4rem", whiteSpace: "normal"}}>
              Age:
            </label>
            <Dropdown
              name="riskFallQuestion.riskFallAge"
              onChange={props.onChangeAge}
              options={props.reAssessmentOptions?.riskFallAgeRange}
              selection={true}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallAge}>
            </Dropdown>
            <Input
              onChange={props.onChangeData}
              readOnly={true}
              style={{width: "6rem", marginLeft: "2rem"}}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallAgeScore || 0}>
            </Input>
            <label
              style={{width: "8rem", whiteSpace: "normal", marginLeft: "2rem"}}>
              History of fall:
            </label>
            <Dropdown
              name="riskFallQuestion.riskFallHistory"
              onChange={props.onChangeHistory}
              options={props.reAssessmentOptions?.riskFallHistory}
              selection={true}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallHistory}>
            </Dropdown>
            <Input
              onChange={props.onChangeData}
              readOnly={true}
              style={{width: "6rem", marginLeft: "2rem"}}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallHistoryScore || 0}>
            </Input>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "22rem", whiteSpace: "normal"}}>
              Mental status Oriented to Time, Place, Person:
            </label>
            <Dropdown
              name="riskFallQuestion.riskFallMental"
              onChange={props.onChangeMental}
              options={props.reAssessmentOptions?.riskFallMental}
              selection={true}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallMental}>
            </Dropdown>
            <Input
              onChange={props.onChangeData}
              readOnly={true}
              style={{width: "6rem", marginLeft: "2rem"}}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallMentalScore || 0}>
            </Input>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "16rem", whiteSpace: "normal"}}>
              Sensory/Communication deficit:
            </label>
            <Dropdown
              clearable={true}
              multiple={true}
              name="riskFallQuestion.riskFallSensory"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.riskFallSensory}
              selection={true}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallSensory}>
            </Dropdown>
            <div
              style={{width: "4rem", marginLeft: "0.5rem"}}>
              {props.onSensoryPopup}
            </div>
            <Dropdown
              name="riskFallQuestion.riskFallSensoryDeficit"
              onChange={props.onChangeSensory}
              options={props.reAssessmentOptions?.riskFallDeficit}
              selection={true}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallSensoryDeficit}>
            </Dropdown>
            <Input
              onChange={props.onChangeData}
              readOnly={true}
              style={{width: "6rem", marginLeft: "2rem"}}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallSensoryScore || 0}>
            </Input>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "7rem", whiteSpace: "normal"}}>
              Elimination:
            </label>
            <Dropdown
              clearable={true}
              multiple={true}
              name="riskFallQuestion.riskFallElimination"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.riskFallElimination}
              selection={true}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallElimination}>
            </Dropdown>
            <div
              style={{width: "4rem", marginLeft: "0.5rem"}}>
              {props.onEliminationPopup}
            </div>
            <Dropdown
              name="riskFallQuestion.riskFallEliminationProblem"
              onChange={props.onChangeElimination}
              options={props.reAssessmentOptions?.riskFallProblem}
              selection={true}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallEliminationProblem}>
            </Dropdown>
            <Input
              onChange={props.onChangeData}
              readOnly={true}
              style={{width: "6rem", marginLeft: "2rem"}}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallEliminationScore || 0}>
            </Input>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "7rem", whiteSpace: "normal"}}>
              Medication:
            </label>
            <Dropdown
              clearable={true}
              name="riskFallQuestion.riskFallMedication"
              onChange={props.onChangeMedication}
              options={props.reAssessmentOptions?.riskFallMedication}
              selection={true}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallMedication}>
            </Dropdown>
            <Input
              onChange={props.onChangeData}
              readOnly={true}
              style={{width: "6rem", marginLeft: "2rem"}}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallMedicationScore || 0}>
            </Input>
            <label
              style={{width: "18rem", whiteSpace: "normal", marginLeft: "2rem"}}>
              Mobily (Including desire autonomy):
            </label>
            <Dropdown
              name="riskFallQuestion.riskFallMobily"
              onChange={props.onChangeMobily}
              options={props.reAssessmentOptions?.riskFallMobily}
              selection={true}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallMobily}>
            </Dropdown>
            <Input
              onChange={props.onChangeData}
              readOnly={true}
              style={{width: "6rem", marginLeft: "2rem"}}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallMobilyScore || 0}>
            </Input>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label>
              Total score:
            </label>
            <Input
              onChange={props.onChangeData}
              readOnly={true}
              style={{width: "6rem", marginLeft: "2rem"}}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallTotalScore || 0}>
            </Input>
            <Dropdown
              disabled={true}
              name="riskFallQuestion.riskFallTotalPrecaution"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.riskFallResult}
              selection={true}
              style={{marginLeft: "2rem"}}
              value={props.ReAssessmentSequence?.riskFallQuestion?.riskFallTotalPrecaution}>
            </Dropdown>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "right", alignItems: "center" , margin: "1rem"}}>
          
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            Shift
          </label>
          <Radio
            checked={props.ReAssessmentSequence?.riskFallData?.saveShift === "Day"}
            label="Day"
            name="riskFallData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center"  }}
            value="Day">
          </Radio>
          <Radio
            checked={props.ReAssessmentSequence?.riskFallData?.saveShift === "Night"}
            label="Night"
            name="riskFallData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center", margin: "0rem 1rem"  }}
            value="Night">
          </Radio>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            ประเมินวันที่
          </label>
          <DateTextBox
            onChange={props.onChangeDateRiskFall}
            value={props.ReAssessmentSequence?.riskFallData?.saveData}>
          </DateTextBox>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            เวลา
          </label>
          <TimeFreeTextBox24
            autoFocus={false}
            onChange={props.onChangeTimeRiskFall}
            value={props.ReAssessmentSequence?.riskFallData?.saveTime}>
          </TimeFreeTextBox24>
          <div
            style={{ margin: "0rem 0rem 0rem 2rem" }}>
            {props?.buttonSaveRiskFall}
          </div>
        </div>
      </div>
    )
}


export default CardReAssessmentRiskFallUX

export const screenPropsDefault = {}

/* Date Time : Mon Oct 07 2024 16:08:19 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", marginTop: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"1rem\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Score:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Radio",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallScore === \"Assessment\""
        },
        "label": {
          "type": "value",
          "value": "Assessment"
        },
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallScore"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Assessment"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Radio",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallScore === \"Re-assessment\""
        },
        "label": {
          "type": "value",
          "value": "Re-assessment"
        },
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallScore"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Re-assessment"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"right\", alignItems: \"center\" , margin: \"1rem\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Age:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Dropdown",
      "parent": 7,
      "props": {
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallAge"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAge"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallAgeRange"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallAge"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Input",
      "parent": 7,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", marginLeft: \"2rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallAgeScore || 0"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "History of fall:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\", whiteSpace: \"normal\", marginLeft: \"2rem\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 7,
      "props": {
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallHistory"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeHistory"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallHistory"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallHistory"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", marginLeft: \"2rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallHistoryScore || 0"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Mental status Oriented to Time, Place, Person:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallMental"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeMental"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallMental"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallMental"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Input",
      "parent": 8,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", marginLeft: \"2rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallMentalScore || 0"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "Sensory/Communication deficit:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"16rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Dropdown",
      "parent": 9,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallSensory"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallSensory"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallSensory"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onSensoryPopup"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4rem\", marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Dropdown",
      "parent": 9,
      "props": {
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallSensoryDeficit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSensory"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallDeficit"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallSensoryDeficit"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Input",
      "parent": 9,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", marginLeft: \"2rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallSensoryScore || 0"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Elimination:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"7rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallElimination"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallElimination"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallElimination"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onEliminationPopup"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4rem\", marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallEliminationProblem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeElimination"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallProblem"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallEliminationProblem"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 10,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", marginLeft: \"2rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallEliminationScore || 0"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"7rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Dropdown",
      "parent": 11,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallMedication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeMedication"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallMedication"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallMedication"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Input",
      "parent": 11,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", marginLeft: \"2rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallMedicationScore || 0"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Mobily (Including desire autonomy):"
        },
        "style": {
          "type": "code",
          "value": "{width: \"18rem\", whiteSpace: \"normal\", marginLeft: \"2rem\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 11,
      "props": {
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallMobily"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeMobily"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallMobily"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallMobily"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Input",
      "parent": 11,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", marginLeft: \"2rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallMobilyScore || 0"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Total score:"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Input",
      "parent": 12,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", marginLeft: \"2rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallTotalScore || 0"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "riskFallQuestion.riskFallTotalPrecaution"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.riskFallResult"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"2rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallQuestion?.riskFallTotalPrecaution"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Shift"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Radio",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallData?.saveShift === \"Day\""
        },
        "label": {
          "type": "value",
          "value": "Day"
        },
        "name": {
          "type": "value",
          "value": "riskFallData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "Day"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Radio",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallData?.saveShift === \"Night\""
        },
        "label": {
          "type": "value",
          "value": "Night"
        },
        "name": {
          "type": "value",
          "value": "riskFallData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 1rem\"  }"
        },
        "value": {
          "type": "value",
          "value": "Night"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 46,
      "name": "DateTextBox",
      "parent": 13,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateRiskFall"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallData?.saveData"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 48,
      "name": "TimeFreeTextBox24",
      "parent": 13,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTimeRiskFall"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.riskFallData?.saveTime"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSaveRiskFall"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0rem 0rem 0rem 2rem\" }"
        }
      },
      "seq": 49,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardReAssessmentRiskFallUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
