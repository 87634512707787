import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ANES_ChargeItem_SetupTemplate_Supply = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{height:"300px",width:"100%",display:"flex",flexDirection: "column", padding: "0 1rem 0"}}>

        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(19, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
          <div
            style={{"gridRow":"2/5","gridColumn":"1/51","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <div
              style={{width:"100%", flexGrow:1}}>

              <label
                style={{fontSize: "22px", fontWeight:"bold"}}>
                {props.languageUX === "en" ? "Edit template : " : "ชุดรายการค่าใช้จ่ายที่กำลังแก้ไข : "}
              </label>
              <label
                style={{fontSize: "16px", fontWeight:"normal"}}>
                {props.template?.name}
              </label>
            </div>
          </div>
          <div
            style={{"gridRow":"6/8","gridColumn":"2/10","border":"solid 0px rgb(200, 200, 200, 0.5)", display: "flex", alignItems: "center"}}>
            <div
              style={{width:"100%",flexGrow:9, flexShrink:4}}>

              <label
                style={{fontWeight:"bold"}}>
                รายการเวชภัณฑ์ ที่เลือก :
              </label>
            </div>
          </div>
          <div
            style={{ gridRow: "6/8", gridColumn: "10/33", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
            <Input
              fluid={true}
              onChange={props.onSupplySearchTextChange}
              value={props.data?.supplySelected?.name || props.data?.supplySearch || ""}>
            </Input>
            <div
              style={{ display: props.showSearchResult ? "block" : "none",  position: "absolute", zIndex: 100, width: "80%", height: "400px" }}>

              <Table
                data={props.supplySearchResult}
                getTrProps={props.supplyRowProps}
                headers="Code,Mode,Product Name,ขนาดบรรจุ,Eligibility Type"
                keys="code,mode,name,package_size,eligibility_type"
                minRows={8}
                showPagination={false}
                style={{ height: "250px" }}>
              </Table>
            </div>
          </div>
          <div
            style={{"gridRow":"6/8","gridColumn":"33/41", marginLeft: "20px"}}>
            <Button
              color={props.data?.supplySelected?.name ? "red" : "blue"}
              onClick={props.onClickSupplySearch}
              style={{width:"100%", flexShrink: 9 }}>
              {props.data?.supplySelected?.name ? "ยกเลิกการเลือก" : "ค้นหาเวชภัณฑ์"}
            </Button>
          </div>
          <div
            style={{ gridRow: "16/18", gridColumn: "2/8", border: "solid 0px rgb(200, 200, 200, 0.5)", display: "flex", alignItems: "center" }}>
            <label
              style={{fontWeight:"bold"}}>
              Remark
            </label>
          </div>
          <div
            style={{"gridRow":"16/19","gridColumn":"8/24","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <Input
              name="remark"
              onChange={props.onChangeSupplyANS}
              style={{height:"100%",width:"100%"}}
              value={props.data?.remark || ""}>
            </Input>
          </div>
          <div
            style={{"gridRow":"16/18","gridColumn":"35/42","border":"solid 1px rgb(200, 200, 200, 0.5)"}}>
            <div>
              {props.buttonCreateSupply}
            </div>
          </div>
          <div
            style={{"gridRow":"16/18","gridColumn":"43/50","border":"solid 1px rgb(200, 200, 200, 0.5)"}}>
            <Button
              color="red"
              onClick={props.onCancelEdit}
              style={{width:"100%", flexShrink: 9 }}>
              ยกเลิกการแก้ไข
            </Button>
          </div>
          <div
            style={{"gridRow":"12/14","gridColumn":"2/8","border":"solid 0px rgb(200, 200, 200, 0.5)", display: "flex", alignItems: "center"}}>
            <label
              style={{fontWeight:"bold"}}>
              Eligibility type
            </label>
          </div>
          <div
            style={{"gridRow":"12/15","gridColumn":"8/24","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <Dropdown
              name="eligibilityType"
              onChange={props.onChangeSupplyANS}
              options={props.masterOptions?.claimImaging || []}
              selection={true}
              style={{width:"100%",height:"100%"}}
              value={props.data?.eligibilityType}>
            </Dropdown>
          </div>
        </div>
      </div>
    )
}


export default ANES_ChargeItem_SetupTemplate_Supply

export const screenPropsDefault = {}

/* Date Time : Mon Aug 26 2024 12:50:06 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"300px\",width:\"100%\",display:\"flex\",flexDirection: \"column\", padding: \"0 1rem 0\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexGrow:1}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ชุดรายการค่าใช้จ่ายที่กำลังแก้ไข : ",
          "valueEN": "Edit template : "
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"22px\", fontWeight:\"bold\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data?.supplySelected?.name ? \"ยกเลิกการเลือก\" : \"ค้นหาเวชภัณฑ์\""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": "props.data?.supplySelected?.name ? \"red\" : \"blue\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickSupplySearch"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexShrink: 9 }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",flexGrow:9, flexShrink:4}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.template?.name"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight:\"normal\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการเวชภัณฑ์ ที่เลือก : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(19, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/5\",\"gridColumn\":\"1/51\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"6/8\",\"gridColumn\":\"33/41\", marginLeft: \"20px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"6/8\",\"gridColumn\":\"2/10\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"16/18\", gridColumn: \"2/8\", border: \"solid 0px rgb(200, 200, 200, 0.5)\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/19\",\"gridColumn\":\"8/24\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Input",
      "parent": 64,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSupplyANS"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.remark || \"\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/18\",\"gridColumn\":\"35/42\",\"border\":\"solid 1px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/18\",\"gridColumn\":\"43/50\",\"border\":\"solid 1px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการแก้ไข"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelEdit"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexShrink: 9 }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"12/14\",\"gridColumn\":\"2/8\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "Eligibility type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"12/15\",\"gridColumn\":\"8/24\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "name": {
          "type": "value",
          "value": "eligibilityType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSupplyANS"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.claimImaging || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.eligibilityType"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/8\", gridColumn: \"10/33\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Input",
      "parent": 77,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSupplySearchTextChange"
        },
        "value": {
          "type": "code",
          "value": "props.data?.supplySelected?.name || props.data?.supplySearch || \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showSearchResult ? \"block\" : \"none\",  position: \"absolute\", zIndex: 100, width: \"80%\", height: \"400px\" }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 80,
      "name": "Table",
      "parent": 79,
      "props": {
        "data": {
          "type": "code",
          "value": "props.supplySearchResult"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.supplyRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Code,Mode,Product Name,ขนาดบรรจุ,Eligibility Type"
        },
        "keys": {
          "type": "value",
          "value": "code,mode,name,package_size,eligibility_type"
        },
        "minRows": {
          "type": "code",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"250px\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCreateSupply"
        }
      },
      "seq": 81,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ChargeItem_SetupTemplate_Supply",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
