import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
// PDF
import FormPatientIPDList from "../FormPatientIPDList";
import FormStatIPDList from "../FormStatIPDList";
// APIs
import AdmitOrderReportList from "issara-sdk/apis/AdmitOrderReportList_apps_ADM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // sequence
  ReportPatientIPDListSequence?: {
    sequenceIndex?: string | null;

    filterPatientList?: {
      ward?: number | null;
      start_ward?: string;
      end_ward?: string;
    };
    patientIPDList?: any;
    statIPDList?: any[];
  } | null;
};

export const StateInitial: State = {
  ReportPatientIPDListSequence: {
    sequenceIndex: null,

    filterPatientList: {
      ward: null,
      start_ward: "",
      end_ward: "",
    },
    patientIPDList: {},
    statIPDList: [],
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller, params) => {
  await controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [["divisionWard", {}]],
    },
  });

  controller.setState({
    ReportPatientIPDListSequence: {
      sequenceIndex: "Action",
      patientIPDList: {},
    },
  });
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const [response, error, network] = await AdmitOrderReportList.list({
      apiToken: controller.apiToken,
      params: state.ReportPatientIPDListSequence?.filterPatientList,
    });
    if (error) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: error,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    } else {
      const patientList = response?.items?.map((item: any) => ({
        ...item,
        start_admited_date: formatDate(moment(item?.start_admited)),
        hn_an: `${item?.hn} (${item?.en_number})`,
      }));

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        ReportPatientIPDListSequence: {
          ...state.ReportPatientIPDListSequence,
          patientIPDList: { ...response, items: patientList },
        },
      });
    }
  } else if (params?.action === "FETCH_STAT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const [response, error, network] = await AdmitOrderReportList.list({
      apiToken: controller.apiToken,
      params: state.ReportPatientIPDListSequence?.filterPatientList,
    });
    if (error) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: error,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    } else {
      console.log("response.items", response.items);

      let DOCTOR_FULLNAME = "doctor_fullname";
      let PATIENT_CASE_NAME = "patient_case_name";
      let PROVISIONAL_DIAGNOSIS = "provisional_diagnosis";

      const arrayString = (response.items || [])?.map(
        (item: any) =>
          `${DOCTOR_FULLNAME}${item.doctor_fullname}${PATIENT_CASE_NAME}${item.patient_case_name}${PROVISIONAL_DIAGNOSIS}${item.provisional_diagnosis}`
      );
      const arrayMap = arrayString.reduce(
        (a: any, k: any) => ((a[k] = (a[k] || 0) + 1), a),
        {}
      );
      console.log("Object.keys(arrayMap): ", Object.keys(arrayMap));

      const uniqueArrays = Array.from(Object.keys(arrayMap), (x) => {
        let patientCaseNameIdx = x.indexOf("patient_case_name");
        let provisionalDiagnosisIdx = x.indexOf("provisional_diagnosis");
        let doctorFullName = x.substring(DOCTOR_FULLNAME.length, patientCaseNameIdx);
        let patientCaseName = x.substring(
          patientCaseNameIdx + PATIENT_CASE_NAME.length,
          provisionalDiagnosisIdx
        );
        let provisionalDiagnosis = x.substring(
          provisionalDiagnosisIdx + PROVISIONAL_DIAGNOSIS.length
        );
        return {
          doctor_fullname: doctorFullName,
          patient_case_name: patientCaseName,
          provisional_diagnosis: provisionalDiagnosis,
        };
      });
      const theCount = Object.values(arrayMap);
      console.log('arrayMap: ', arrayMap);

      console.log("uniqueArrays: ", uniqueArrays);
      console.log("theCount: ", theCount);
      const joinArray = uniqueArrays.map((item: any, index: number ) => ({...item, count: theCount[index]}))

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        ReportPatientIPDListSequence: {
          ...state.ReportPatientIPDListSequence,
          statIPDList: joinArray
        },
      });
    }
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const data = Object.assign({
      start_date:
        state.ReportPatientIPDListSequence?.filterPatientList?.start_ward ||
        " - ",
      end_date:
        state.ReportPatientIPDListSequence?.filterPatientList?.end_ward ||
        " - ",
      data: state.ReportPatientIPDListSequence?.patientIPDList?.items || [],
    });

    let docDef: any = { content: [] };

    docDef = FormPatientIPDList(data);

    const pdfMake = (await getPdfMake()).createPdf(docDef);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "SUCCESS",
      },
    });
    return;
  } else if (params?.action === "PRINT_STAT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const data = Object.assign({
      start_date:
        state.ReportPatientIPDListSequence?.filterPatientList?.start_ward ||
        " - ",
      end_date:
        state.ReportPatientIPDListSequence?.filterPatientList?.end_ward ||
        " - ",
      data: state.ReportPatientIPDListSequence?.statIPDList || [],
    });

    let docDef: any = { content: [] };

    docDef = FormStatIPDList(data);

    const pdfMake = (await getPdfMake()).createPdf(docDef);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "SUCCESS",
      },
    });
    return;
  }
};
