import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea
} from 'semantic-ui-react'



import styles from './CardOTDiagnosisPediatricsLOW.module.css'

export interface CardOTDiagnosisPediatricsLOWViewDataType {
    OtDiagnosisPediatricsLow2: string,
    OtDiagnosisPediatricsLow2Disabled: boolean,
    OtDiagnosisPediatricsLow3: string,
    OtDiagnosisPediatricsLow3Disabled: boolean,
    OtDiagnosisPediatricsLow7: boolean,
    OtDiagnosisPediatricsLow7Disabled: boolean,
    OtDiagnosisPediatricsLow8: boolean,
    OtDiagnosisPediatricsLow8Disabled: boolean,
    OtDiagnosisPediatricsLow11: boolean,
    OtDiagnosisPediatricsLow11Disabled: boolean,
    OtDiagnosisPediatricsLow14: boolean,
    OtDiagnosisPediatricsLow14Disabled: boolean,
    OtDiagnosisPediatricsLow18: boolean,
    OtDiagnosisPediatricsLow18Disabled: boolean,
    OtDiagnosisPediatricsLow20: boolean,
    OtDiagnosisPediatricsLow20Disabled: boolean,
    OtDiagnosisPediatricsLow24: boolean,
    OtDiagnosisPediatricsLow24Disabled: boolean,
    OtDiagnosisPediatricsLow25: boolean,
    OtDiagnosisPediatricsLow25Disabled: boolean,
    OtDiagnosisPediatricsLow26: string,
    OtDiagnosisPediatricsLow26Disabled: boolean,
    OtDiagnosisPediatricsLow27: boolean,
    OtDiagnosisPediatricsLow27Disabled: boolean,
    OtDiagnosisPediatricsLow28: boolean,
    OtDiagnosisPediatricsLow28Disabled: boolean,
    OtDiagnosisPediatricsLow35: boolean,
    OtDiagnosisPediatricsLow35Disabled: boolean,
    OtDiagnosisPediatricsLow36: boolean,
    OtDiagnosisPediatricsLow36Disabled: boolean,
    OtDiagnosisPediatricsLow37: boolean,
    OtDiagnosisPediatricsLow37Disabled: boolean,
    OtDiagnosisPediatricsLow40: boolean,
    OtDiagnosisPediatricsLow40Disabled: boolean,
    OtDiagnosisPediatricsLow42: boolean,
    OtDiagnosisPediatricsLow42Disabled: boolean,
    OtDiagnosisPediatricsLow45: boolean,
    OtDiagnosisPediatricsLow45Disabled: boolean,
    OtDiagnosisPediatricsLow47: boolean,
    OtDiagnosisPediatricsLow47Disabled: boolean,
    OtDiagnosisPediatricsLow52: boolean,
    OtDiagnosisPediatricsLow52Disabled: boolean,
    OtDiagnosisPediatricsLow53: boolean,
    OtDiagnosisPediatricsLow53Disabled: boolean,
    OtDiagnosisPediatricsLow54: string,
    OtDiagnosisPediatricsLow54Disabled: boolean,
    OtDiagnosisPediatricsLow55: boolean,
    OtDiagnosisPediatricsLow55Disabled: boolean,
    OtDiagnosisPediatricsLow57: boolean,
    OtDiagnosisPediatricsLow57Disabled: boolean,
    OtDiagnosisPediatricsLow58: boolean,
    OtDiagnosisPediatricsLow58Disabled: boolean,
    OtDiagnosisPediatricsLow60: boolean,
    OtDiagnosisPediatricsLow60Disabled: boolean,
    OtDiagnosisPediatricsLow66: string,
    OtDiagnosisPediatricsLow66Disabled: boolean,
    OtDiagnosisPediatricsLow67: boolean,
    OtDiagnosisPediatricsLow67Disabled: boolean,
    Ct2Premorbid: string,
    Ct2PremorbidDisabled: boolean,
    Ct2PremorbidOptions: any,
    OtDiagnosisPediatricsLow72: string,
    OtDiagnosisPediatricsLow72Disabled: boolean,
    OtDiagnosisPediatricsLow74: boolean,
    OtDiagnosisPediatricsLow74Disabled: boolean,
    OtDiagnosisPediatricsLow76: boolean,
    OtDiagnosisPediatricsLow76Disabled: boolean,
    OtDiagnosisPediatricsLow82: string,
    OtDiagnosisPediatricsLow82Disabled: boolean,
    OtDiagnosisPediatricsLow86: string,
    OtDiagnosisPediatricsLow86Disabled: boolean,
    OtDiagnosisPediatricsLow87: string,
    OtDiagnosisPediatricsLow87Disabled: boolean,
    OtDiagnosisPediatricsLow89: string,
    OtDiagnosisPediatricsLow89Disabled: boolean,
    OtDiagnosisPediatricsLow92: boolean,
    OtDiagnosisPediatricsLow92Disabled: boolean,
    OtDiagnosisPediatricsLow94: boolean,
    OtDiagnosisPediatricsLow94Disabled: boolean,
    OtDiagnosisPediatricsLow95: boolean,
    OtDiagnosisPediatricsLow95Disabled: boolean,
    OtDiagnosisPediatricsLow96: string,
    OtDiagnosisPediatricsLow96Disabled: boolean,
    OtDiagnosisPediatricsLow98: boolean,
    OtDiagnosisPediatricsLow98Disabled: boolean,
    OtDiagnosisPediatricsLow100: boolean,
    OtDiagnosisPediatricsLow100Disabled: boolean,
    OtDiagnosisPediatricsLow102: boolean,
    OtDiagnosisPediatricsLow102Disabled: boolean,
    OtDiagnosisPediatricsLow108: string,
    OtDiagnosisPediatricsLow108Disabled: boolean,
    OtDiagnosisPediatricsLow109: string,
    OtDiagnosisPediatricsLow109Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const CardOTDiagnosisPediatricsLOWInitialViewData: CardOTDiagnosisPediatricsLOWViewDataType = {
    OtDiagnosisPediatricsLow2: "",
    OtDiagnosisPediatricsLow2Disabled: false,
    OtDiagnosisPediatricsLow3: "",
    OtDiagnosisPediatricsLow3Disabled: false,
    OtDiagnosisPediatricsLow7: false,
    OtDiagnosisPediatricsLow7Disabled: false,
    OtDiagnosisPediatricsLow8: false,
    OtDiagnosisPediatricsLow8Disabled: false,
    OtDiagnosisPediatricsLow11: false,
    OtDiagnosisPediatricsLow11Disabled: false,
    OtDiagnosisPediatricsLow14: false,
    OtDiagnosisPediatricsLow14Disabled: false,
    OtDiagnosisPediatricsLow18: false,
    OtDiagnosisPediatricsLow18Disabled: false,
    OtDiagnosisPediatricsLow20: false,
    OtDiagnosisPediatricsLow20Disabled: false,
    OtDiagnosisPediatricsLow24: false,
    OtDiagnosisPediatricsLow24Disabled: false,
    OtDiagnosisPediatricsLow25: false,
    OtDiagnosisPediatricsLow25Disabled: false,
    OtDiagnosisPediatricsLow26: "",
    OtDiagnosisPediatricsLow26Disabled: false,
    OtDiagnosisPediatricsLow27: false,
    OtDiagnosisPediatricsLow27Disabled: false,
    OtDiagnosisPediatricsLow28: false,
    OtDiagnosisPediatricsLow28Disabled: false,
    OtDiagnosisPediatricsLow35: false,
    OtDiagnosisPediatricsLow35Disabled: false,
    OtDiagnosisPediatricsLow36: false,
    OtDiagnosisPediatricsLow36Disabled: false,
    OtDiagnosisPediatricsLow37: false,
    OtDiagnosisPediatricsLow37Disabled: false,
    OtDiagnosisPediatricsLow40: false,
    OtDiagnosisPediatricsLow40Disabled: false,
    OtDiagnosisPediatricsLow42: false,
    OtDiagnosisPediatricsLow42Disabled: false,
    OtDiagnosisPediatricsLow45: false,
    OtDiagnosisPediatricsLow45Disabled: false,
    OtDiagnosisPediatricsLow47: false,
    OtDiagnosisPediatricsLow47Disabled: false,
    OtDiagnosisPediatricsLow52: false,
    OtDiagnosisPediatricsLow52Disabled: false,
    OtDiagnosisPediatricsLow53: false,
    OtDiagnosisPediatricsLow53Disabled: false,
    OtDiagnosisPediatricsLow54: "",
    OtDiagnosisPediatricsLow54Disabled: true,
    OtDiagnosisPediatricsLow55: false,
    OtDiagnosisPediatricsLow55Disabled: false,
    OtDiagnosisPediatricsLow57: false,
    OtDiagnosisPediatricsLow57Disabled: false,
    OtDiagnosisPediatricsLow58: false,
    OtDiagnosisPediatricsLow58Disabled: false,
    OtDiagnosisPediatricsLow60: false,
    OtDiagnosisPediatricsLow60Disabled: false,
    OtDiagnosisPediatricsLow66: "",
    OtDiagnosisPediatricsLow66Disabled: true,
    OtDiagnosisPediatricsLow67: false,
    OtDiagnosisPediatricsLow67Disabled: false,
    Ct2Premorbid: "",
    Ct2PremorbidDisabled: false,
    Ct2PremorbidOptions: [{key: 0, value: "A", text: "A"}],
    OtDiagnosisPediatricsLow72: "",
    OtDiagnosisPediatricsLow72Disabled: false,
    OtDiagnosisPediatricsLow74: false,
    OtDiagnosisPediatricsLow74Disabled: false,
    OtDiagnosisPediatricsLow76: false,
    OtDiagnosisPediatricsLow76Disabled: false,
    OtDiagnosisPediatricsLow82: "",
    OtDiagnosisPediatricsLow82Disabled: false,
    OtDiagnosisPediatricsLow86: "",
    OtDiagnosisPediatricsLow86Disabled: false,
    OtDiagnosisPediatricsLow87: "",
    OtDiagnosisPediatricsLow87Disabled: false,
    OtDiagnosisPediatricsLow89: "",
    OtDiagnosisPediatricsLow89Disabled: false,
    OtDiagnosisPediatricsLow92: false,
    OtDiagnosisPediatricsLow92Disabled: false,
    OtDiagnosisPediatricsLow94: false,
    OtDiagnosisPediatricsLow94Disabled: false,
    OtDiagnosisPediatricsLow95: false,
    OtDiagnosisPediatricsLow95Disabled: false,
    OtDiagnosisPediatricsLow96: "",
    OtDiagnosisPediatricsLow96Disabled: false,
    OtDiagnosisPediatricsLow98: false,
    OtDiagnosisPediatricsLow98Disabled: false,
    OtDiagnosisPediatricsLow100: false,
    OtDiagnosisPediatricsLow100Disabled: false,
    OtDiagnosisPediatricsLow102: false,
    OtDiagnosisPediatricsLow102Disabled: false,
    OtDiagnosisPediatricsLow108: "",
    OtDiagnosisPediatricsLow108Disabled: false,
    OtDiagnosisPediatricsLow109: "",
    OtDiagnosisPediatricsLow109Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtDiagnosisPediatricsLow1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow2}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow2}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow2',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow3}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow3}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow3Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow3',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow4}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Chief complaint*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Underlying disease</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow7}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow8}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow8}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow8Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow8"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consult for*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow11}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow11}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow11Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow11"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow12}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Developmental Stimulation</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow14}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow14}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow14Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow14"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Kinetic activites</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>ADL evaluation &amp; training</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow18}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow18}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cognitive/Perceptual evaluation &amp; training</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow20}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow20}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow20Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow20"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Adaptive device</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Multi-sensory room</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow24}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow25}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow26}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow26}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow26Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow26',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow27}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow27}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow27"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow28}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow28}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow28Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow28"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Swallowing evaluation &amp; training</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hand orthosis/splint</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Home program</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow34}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow35}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow35}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow35Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow35"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow36}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow36}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow36Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow36"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow37}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow37}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow37Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow37"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow38}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Precaution*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow40}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow40}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow40Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow40"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Infectious precaution</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow42}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow42}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow42Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow42"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow44}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Malignancy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow45}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow45}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow45Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow45"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow46}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Vascular disorder</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow47}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow47}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow47Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow47"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow48}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Internel Instrument</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow50}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Osteoporosis</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow51}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pregnancy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow52}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow52}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow52Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow52"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow53}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow53}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow53Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow53"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow54}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow54}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow54Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow54',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow55}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow55}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow55Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow55"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow56}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hypertension</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow57}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow57}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow57Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow57"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow58}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow58}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow58Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow58"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow59}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Falling</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow60}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow60}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow60Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow60"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow61}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Diabetes melitus</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow63}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Fracture</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow64}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Aspiration</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow65}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Seizure</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow66}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow66}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow66Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow66',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow67}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow67}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow67Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow67"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow68}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.Ct2Premorbid}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Premorbid}
                    disabled={ props.viewData.Ct2PremorbidDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "Ct2Premorbid",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2PremorbidOptions}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow72}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow72}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow72Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow72',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow73}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Premorbid</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow74}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow74}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow74Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow74"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow75}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Family Status</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow76}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow76}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow76Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow76"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow80}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Caregiver</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow81}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Non-Caregiver</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow82}>
                <TextArea
                    value={props.viewData.OtDiagnosisPediatricsLow82}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow82Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow82',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow83}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Present and Past History</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow86}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow86}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow86Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow86',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow87}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow87}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow87Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow87',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow88}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Nickname</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow89}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow89}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow89Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow89',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow90}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>School</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow91}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Grade</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow92}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow92}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow92Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow92"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow93}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Other special treatment</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow94}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow94}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow94Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow94"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow95}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow95}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow95Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow95"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow96}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow96}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow96Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow96',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow98}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow98}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow98Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow98"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow99}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow100}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow100}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow100Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow100"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow101}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Speech therapy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow102}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPediatricsLow102}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow102Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPediatricsLOW",
                                name: "OtDiagnosisPediatricsLow102"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPediatricsLow103}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Physical therapy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow104}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Psychotherapy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow105}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Special education</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow107}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow108}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow108}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow108Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow108',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow109}>
                <Input
                    value={props.viewData.OtDiagnosisPediatricsLow109}
                    disabled={ props.viewData.OtDiagnosisPediatricsLow109Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'OtDiagnosisPediatricsLow109',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow110}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Parent's name</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPediatricsLow112}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Related</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardOTDiagnosisPediatricsLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
