import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Segment,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ModEditDiscountUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%" ,cursor: "pointer",overflow: "visible",}}>
        <Segment>
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              แก้ไขรายละเอียดส่วนลด
            </div>
            <div
              style={{cursor: "pointer"}}>
              
            </div>
          </div>
          <hr>
          </hr>
          <div
            style={{margin:"10px 0 10px 0"}}>
            {props.errorMessageAdd}
          </div>
          <div
            style={{display: props.hideSelectCoverage ? "none" : "flex",justifyContent: "space-between",marginTop:"15px"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1rem"}}>
              เลือกสิทธิส่วนลด
            </div>
            <div
              style={{width:"75%",marginRight:"5px"}}>
              
              <Dropdown
                clearable={true}
                fluid={true}
                id="ModEditDiscount-Dropdown-Coverage"
                onChange={props.handleChangeValue("coverage")}
                options={props.coverageOptions}
                search={true}
                selection={true}
                value={props.coverage}>
              </Dropdown>
            </div>
            <div>
              {props.buttonAddCoverage}
            </div>
          </div>
          <hr>
          </hr>
          <div
            style={{display: props.showEdit ?"flex" :"none", justifyContent: "space-between",margin:"15px 0 15px 0"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1rem"}}>
              แก้ไขส่วนลดตามสิทธิ
            </div>
            <div
              style={{width:"85%"}}>
              
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.handleChangeValue("discountByCoverageCode")}
                options={props.discountOptions}
                selection={true}
                value={props.discountByCoverageCode}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{display:props.showEdit ?"" :"none"}}>
            
            <Table
              data={props.orderList}
              getTdProps={props.getTdProps}
              headers="หมวดหรือสินค้า,ประเภท,ราคาลด,ร้อยละ,วิธีการคำนวณ,"
              keys="name,encounter_type,amount,percent,calculation,delete"
              minRows="7"
              showPagination={false}
              style={{height:"300px"}}>
            </Table>
          </div>
          <div
            style={{margin:"10px 0 10px 0"}}>
            {props.errorMessageSave}
          </div>
          <div
            style={{display: props.showEdit ?"flex" :"none", margin:"15px"}}>
            
            <div>
              {props.buttonBillProduct}
            </div>
            <div>
              {props.buttonBillMode}
            </div>
            <Button
              color="blue"
              onClick={props.onAddProduct}>
              ADD PRODUCT
            </Button>
            <Button
              color="blue"
              onClick={props.onAddBillMode}>
              ADD BILL MODE
            </Button>
            <div
              style={{flex:"1"}}>
              
            </div>
            <div>
              {props.buttonSave}
            </div>
          </div>
        </Segment>
      </div>
    )
}


export default ModEditDiscountUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Wed Aug 07 2024 11:46:39 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" ,cursor: \"pointer\",overflow: \"visible\",}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขรายละเอียดส่วนลด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 6,
      "void": true
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.showEdit ?\"\" :\"none\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showEdit ?\"flex\" :\"none\", margin:\"15px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonBillProduct"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideSelectCoverage ? \"none\" : \"flex\",justifyContent: \"space-between\",marginTop:\"15px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกสิทธิส่วนลด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1rem\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"75%\",marginRight:\"5px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAddCoverage"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 23,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModEditDiscount-Dropdown-Coverage"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"coverage\")"
        },
        "options": {
          "type": "code",
          "value": "props.coverageOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.coverage"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 21,
      "void": true
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showEdit ?\"flex\" :\"none\", justifyContent: \"space-between\",margin:\"15px 0 15px 0\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขส่วนลดตามสิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1rem\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"85%\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"discountByCoverageCode\")"
        },
        "options": {
          "type": "code",
          "value": "props.discountOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.discountByCoverageCode"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonBillMode"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD PRODUCT"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddProduct"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD BILL MODE"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddBillMode"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessageAdd"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px 0 10px 0\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessageSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px 0 10px 0\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 38,
      "name": "Table",
      "parent": 15,
      "props": {
        "data": {
          "type": "code",
          "value": "props.orderList"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "headers": {
          "type": "value",
          "value": "หมวดหรือสินค้า,ประเภท,ราคาลด,ร้อยละ,วิธีการคำนวณ,"
        },
        "keys": {
          "type": "value",
          "value": "name,encounter_type,amount,percent,calculation,delete"
        },
        "minRows": {
          "type": "value",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"300px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 35,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModEditDiscountUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 75
}

*********************************************************************************** */
