import * as SettingMediaDocumentI from "./SettingMediaDocument";
import * as SettingTRTOptionsI from "./SettingTRTOptions";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  SettingMediaDocument: {
    default: {
      START: SettingMediaDocumentI.GetMaster,
      Action: SettingMediaDocumentI.Action,
    },
  },
  SettingTRTOptions: {
    default: {
      START: SettingTRTOptionsI.GetMaster,
      Action: SettingTRTOptionsI.Action,
    },
  },
};

export default SequencePattern;
