import React from 'react'
import {
   Button,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './OTPhysicalExaminationCerebralPalsy3.module.css'

export interface OTPhysicalExaminationCerebralPalsy3ViewDataType {
    CtHeadControlSupine: string,
    CtHeadControlSupineDisabled: boolean,
    CtHeadControlSupineOptions: any,
    OtPhysicalCerebral36: string,
    OtPhysicalCerebral36Disabled: boolean,
    CtHeadControlProne: string,
    CtHeadControlProneDisabled: boolean,
    CtHeadControlProneOptions: any,
    OtPhysicalCerebral310: string,
    OtPhysicalCerebral310Disabled: boolean,
    CtPullToSit: string,
    CtPullToSitDisabled: boolean,
    CtPullToSitOptions: any,
    OtPhysicalCerebral316: string,
    OtPhysicalCerebral316Disabled: boolean,
    CtSupineToProne: string,
    CtSupineToProneDisabled: boolean,
    CtSupineToProneOptions: any,
    OtPhysicalCerebral324: string,
    OtPhysicalCerebral324Disabled: boolean,
    CtProneToSupine: string,
    CtProneToSupineDisabled: boolean,
    CtProneToSupineOptions: any,
    OtPhysicalCerebral328: string,
    OtPhysicalCerebral328Disabled: boolean,
    CtSideLyingToSit: string,
    CtSideLyingToSitDisabled: boolean,
    CtSideLyingToSitOptions: any,
    OtPhysicalCerebral333: string,
    OtPhysicalCerebral333Disabled: boolean,
    CtSitting: string,
    CtSittingDisabled: boolean,
    CtSittingOptions: any,
    OtPhysicalCerebral339: string,
    OtPhysicalCerebral339Disabled: boolean,
    Ct2CrawlingCreeping: string,
    Ct2CrawlingCreepingDisabled: boolean,
    Ct2CrawlingCreepingOptions: any,
    OtPhysicalCerebral343: string,
    OtPhysicalCerebral343Disabled: boolean,
    CtPullToStand: string,
    CtPullToStandDisabled: boolean,
    CtPullToStandOptions: any,
    OtPhysicalCerebral348: string,
    OtPhysicalCerebral348Disabled: boolean,
    CtStanding: string,
    CtStandingDisabled: boolean,
    CtStandingOptions: any,
    OtPhysicalCerebral355: string,
    OtPhysicalCerebral355Disabled: boolean,
    CtSideWalking: string,
    CtSideWalkingDisabled: boolean,
    CtSideWalkingOptions: any,
    OtPhysicalCerebral359: string,
    OtPhysicalCerebral359Disabled: boolean,
    CtForwardWalking: string,
    CtForwardWalkingDisabled: boolean,
    CtForwardWalkingOptions: any,
    OtPhysicalCerebral364: string,
    OtPhysicalCerebral364Disabled: boolean,
    CtBackwardWalking: string,
    CtBackwardWalkingDisabled: boolean,
    CtBackwardWalkingOptions: any,
    OtPhysicalCerebral370: string,
    OtPhysicalCerebral370Disabled: boolean,
    CtRunning: string,
    CtRunningDisabled: boolean,
    CtRunningOptions: any,
    OtPhysicalCerebral374: string,
    OtPhysicalCerebral374Disabled: boolean,
    CtJumping: string,
    CtJumpingDisabled: boolean,
    CtJumpingOptions: any,
    OtPhysicalCerebral379: string,
    OtPhysicalCerebral379Disabled: boolean,
    CtHopping: string,
    CtHoppingDisabled: boolean,
    CtHoppingOptions: any,
    OtPhysicalCerebral385: string,
    OtPhysicalCerebral385Disabled: boolean,
    CtGraspReflex: string,
    CtGraspReflexDisabled: boolean,
    CtGraspReflexOptions: any,
    OtPhysicalCerebral391: string,
    OtPhysicalCerebral391Disabled: boolean,
    CtOpenHand: string,
    CtOpenHandDisabled: boolean,
    CtOpenHandOptions: any,
    OtPhysicalCerebral396: string,
    OtPhysicalCerebral396Disabled: boolean,
    CtReachOut: string,
    CtReachOutDisabled: boolean,
    CtReachOutOptions: any,
    OtPhysicalCerebral3101: string,
    OtPhysicalCerebral3101Disabled: boolean,
    CtHandGrasp: string,
    CtHandGraspDisabled: boolean,
    CtHandGraspOptions: any,
    OtPhysicalCerebral3106: string,
    OtPhysicalCerebral3106Disabled: boolean,
    CtWholeHandGrasp: string,
    CtWholeHandGraspDisabled: boolean,
    CtWholeHandGraspOptions: any,
    OtPhysicalCerebral3111: string,
    OtPhysicalCerebral3111Disabled: boolean,
    CtPinchGrasp: string,
    CtPinchGraspDisabled: boolean,
    CtPinchGraspOptions: any,
    OtPhysicalCerebral3116: string,
    OtPhysicalCerebral3116Disabled: boolean,
    CtLaterality: string,
    CtLateralityDisabled: boolean,
    CtLateralityOptions: any,
    OtPhysicalCerebral3121: string,
    OtPhysicalCerebral3121Disabled: boolean,
    CtBilateralHandUse: string,
    CtBilateralHandUseDisabled: boolean,
    CtBilateralHandUseOptions: any,
    OtPhysicalCerebral3126: string,
    OtPhysicalCerebral3126Disabled: boolean,
    CtDependent: string,
    CtDependentDisabled: boolean,
    CtDependentOptions: any,
    OtPhysicalCerebral3133: string,
    OtPhysicalCerebral3133Disabled: boolean,
    CtWalkWithWalkingAid: string,
    CtWalkWithWalkingAidDisabled: boolean,
    CtWalkWithWalkingAidOptions: any,
    OtPhysicalCerebral3138: string,
    OtPhysicalCerebral3138Disabled: boolean,
    CtRolling: string,
    CtRollingDisabled: boolean,
    CtRollingOptions: any,
    OtPhysicalCerebral3143: string,
    OtPhysicalCerebral3143Disabled: boolean,
    CtWheelchair: string,
    CtWheelchairDisabled: boolean,
    CtWheelchairOptions: any,
    OtPhysicalCerebral3148: string,
    OtPhysicalCerebral3148Disabled: boolean,
    CtCrawlingCreeping: string,
    CtCrawlingCreepingDisabled: boolean,
    CtCrawlingCreepingOptions: any,
    OtPhysicalCerebral3153: string,
    OtPhysicalCerebral3153Disabled: boolean,
    CtWalking: string,
    CtWalkingDisabled: boolean,
    CtWalkingOptions: any,
    OtPhysicalCerebral3158: string,
    OtPhysicalCerebral3158Disabled: boolean,
    CtTransferInSitting: string,
    CtTransferInSittingDisabled: boolean,
    CtTransferInSittingOptions: any,
    OtPhysicalCerebral3164: string,
    OtPhysicalCerebral3164Disabled: boolean,
    CtOroMotor: string,
    CtOroMotorDisabled: boolean,
    CtOroMotorOptions: any,
    OtPhysicalCerebral3169: string,
    OtPhysicalCerebral3169Disabled: boolean,
    OtPhysicalCerebral3171: string,
    OtPhysicalCerebral3171Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const OTPhysicalExaminationCerebralPalsy3InitialViewData: OTPhysicalExaminationCerebralPalsy3ViewDataType = {
    CtHeadControlSupine: "",
    CtHeadControlSupineDisabled: false,
    CtHeadControlSupineOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral36: "",
    OtPhysicalCerebral36Disabled: false,
    CtHeadControlProne: "",
    CtHeadControlProneDisabled: false,
    CtHeadControlProneOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral310: "",
    OtPhysicalCerebral310Disabled: false,
    CtPullToSit: "",
    CtPullToSitDisabled: false,
    CtPullToSitOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral316: "",
    OtPhysicalCerebral316Disabled: false,
    CtSupineToProne: "",
    CtSupineToProneDisabled: false,
    CtSupineToProneOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral324: "",
    OtPhysicalCerebral324Disabled: false,
    CtProneToSupine: "",
    CtProneToSupineDisabled: false,
    CtProneToSupineOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral328: "",
    OtPhysicalCerebral328Disabled: false,
    CtSideLyingToSit: "",
    CtSideLyingToSitDisabled: false,
    CtSideLyingToSitOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral333: "",
    OtPhysicalCerebral333Disabled: false,
    CtSitting: "",
    CtSittingDisabled: false,
    CtSittingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral339: "",
    OtPhysicalCerebral339Disabled: false,
    Ct2CrawlingCreeping: "",
    Ct2CrawlingCreepingDisabled: false,
    Ct2CrawlingCreepingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral343: "",
    OtPhysicalCerebral343Disabled: false,
    CtPullToStand: "",
    CtPullToStandDisabled: false,
    CtPullToStandOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral348: "",
    OtPhysicalCerebral348Disabled: false,
    CtStanding: "",
    CtStandingDisabled: false,
    CtStandingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral355: "",
    OtPhysicalCerebral355Disabled: false,
    CtSideWalking: "",
    CtSideWalkingDisabled: false,
    CtSideWalkingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral359: "",
    OtPhysicalCerebral359Disabled: false,
    CtForwardWalking: "",
    CtForwardWalkingDisabled: false,
    CtForwardWalkingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral364: "",
    OtPhysicalCerebral364Disabled: false,
    CtBackwardWalking: "",
    CtBackwardWalkingDisabled: false,
    CtBackwardWalkingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral370: "",
    OtPhysicalCerebral370Disabled: false,
    CtRunning: "",
    CtRunningDisabled: false,
    CtRunningOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral374: "",
    OtPhysicalCerebral374Disabled: false,
    CtJumping: "",
    CtJumpingDisabled: false,
    CtJumpingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral379: "",
    OtPhysicalCerebral379Disabled: false,
    CtHopping: "",
    CtHoppingDisabled: false,
    CtHoppingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral385: "",
    OtPhysicalCerebral385Disabled: false,
    CtGraspReflex: "",
    CtGraspReflexDisabled: false,
    CtGraspReflexOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral391: "",
    OtPhysicalCerebral391Disabled: false,
    CtOpenHand: "",
    CtOpenHandDisabled: false,
    CtOpenHandOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral396: "",
    OtPhysicalCerebral396Disabled: false,
    CtReachOut: "",
    CtReachOutDisabled: false,
    CtReachOutOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3101: "",
    OtPhysicalCerebral3101Disabled: false,
    CtHandGrasp: "",
    CtHandGraspDisabled: false,
    CtHandGraspOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3106: "",
    OtPhysicalCerebral3106Disabled: false,
    CtWholeHandGrasp: "",
    CtWholeHandGraspDisabled: false,
    CtWholeHandGraspOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3111: "",
    OtPhysicalCerebral3111Disabled: false,
    CtPinchGrasp: "",
    CtPinchGraspDisabled: false,
    CtPinchGraspOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3116: "",
    OtPhysicalCerebral3116Disabled: false,
    CtLaterality: "",
    CtLateralityDisabled: false,
    CtLateralityOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3121: "",
    OtPhysicalCerebral3121Disabled: false,
    CtBilateralHandUse: "",
    CtBilateralHandUseDisabled: false,
    CtBilateralHandUseOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3126: "",
    OtPhysicalCerebral3126Disabled: false,
    CtDependent: "",
    CtDependentDisabled: false,
    CtDependentOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3133: "",
    OtPhysicalCerebral3133Disabled: false,
    CtWalkWithWalkingAid: "",
    CtWalkWithWalkingAidDisabled: false,
    CtWalkWithWalkingAidOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3138: "",
    OtPhysicalCerebral3138Disabled: false,
    CtRolling: "",
    CtRollingDisabled: false,
    CtRollingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3143: "",
    OtPhysicalCerebral3143Disabled: false,
    CtWheelchair: "",
    CtWheelchairDisabled: false,
    CtWheelchairOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3148: "",
    OtPhysicalCerebral3148Disabled: false,
    CtCrawlingCreeping: "",
    CtCrawlingCreepingDisabled: false,
    CtCrawlingCreepingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3153: "",
    OtPhysicalCerebral3153Disabled: false,
    CtWalking: "",
    CtWalkingDisabled: false,
    CtWalkingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3158: "",
    OtPhysicalCerebral3158Disabled: false,
    CtTransferInSitting: "",
    CtTransferInSittingDisabled: false,
    CtTransferInSittingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3164: "",
    OtPhysicalCerebral3164Disabled: false,
    CtOroMotor: "",
    CtOroMotorDisabled: false,
    CtOroMotorOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalCerebral3169: "",
    OtPhysicalCerebral3169Disabled: false,
    OtPhysicalCerebral3171: "",
    OtPhysicalCerebral3171Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtPhysicalCerebral31}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Motor development</strong></p>
                </div>
            </div>
            <div className={styles.CtHeadControlSupine}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtHeadControlSupine}
                    disabled={ props.viewData.CtHeadControlSupineDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtHeadControlSupine",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtHeadControlSupineOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral36}>
                <Input
                    value={props.viewData.OtPhysicalCerebral36}
                    disabled={ props.viewData.OtPhysicalCerebral36Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral36',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtHeadControlProne}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtHeadControlProne}
                    disabled={ props.viewData.CtHeadControlProneDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtHeadControlProne",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtHeadControlProneOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral310}>
                <Input
                    value={props.viewData.OtPhysicalCerebral310}
                    disabled={ props.viewData.OtPhysicalCerebral310Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral310',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral311}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Head control  in supine</p>
                </div>
            </div>
            <div className={styles.CtPullToSit}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtPullToSit}
                    disabled={ props.viewData.CtPullToSitDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtPullToSit",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtPullToSitOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral316}>
                <Input
                    value={props.viewData.OtPhysicalCerebral316}
                    disabled={ props.viewData.OtPhysicalCerebral316Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral316',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral317}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Head control  in prone</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral319}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Pull to sit no head lack</p>
                </div>
            </div>
            <div className={styles.CtSupineToProne}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSupineToProne}
                    disabled={ props.viewData.CtSupineToProneDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtSupineToProne",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSupineToProneOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral324}>
                <Input
                    value={props.viewData.OtPhysicalCerebral324}
                    disabled={ props.viewData.OtPhysicalCerebral324Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral324',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtProneToSupine}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtProneToSupine}
                    disabled={ props.viewData.CtProneToSupineDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtProneToSupine",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtProneToSupineOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral328}>
                <Input
                    value={props.viewData.OtPhysicalCerebral328}
                    disabled={ props.viewData.OtPhysicalCerebral328Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral328',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtSideLyingToSit}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSideLyingToSit}
                    disabled={ props.viewData.CtSideLyingToSitDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtSideLyingToSit",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSideLyingToSitOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral332}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Supine to prone</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral333}>
                <Input
                    value={props.viewData.OtPhysicalCerebral333}
                    disabled={ props.viewData.OtPhysicalCerebral333Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral333',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral334}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Prone to supine</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral335}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Side lying to sit</p>
                </div>
            </div>
            <div className={styles.CtSitting}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSitting}
                    disabled={ props.viewData.CtSittingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtSitting",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSittingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral339}>
                <Input
                    value={props.viewData.OtPhysicalCerebral339}
                    disabled={ props.viewData.OtPhysicalCerebral339Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral339',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct2CrawlingCreeping}>
                <Dropdown selection
                    style={{ height: "100%", width: "100%"}}
                    value={props.viewData.Ct2CrawlingCreeping}
                    disabled={ props.viewData.Ct2CrawlingCreepingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "Ct2CrawlingCreeping",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2CrawlingCreepingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral343}>
                <Input
                    value={props.viewData.OtPhysicalCerebral343}
                    disabled={ props.viewData.OtPhysicalCerebral343Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral343',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral344}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Sitting</p>
                </div>
            </div>
            <div className={styles.CtPullToStand}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtPullToStand}
                    disabled={ props.viewData.CtPullToStandDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtPullToStand",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtPullToStandOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral348}>
                <Input
                    value={props.viewData.OtPhysicalCerebral348}
                    disabled={ props.viewData.OtPhysicalCerebral348Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral348',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtCreepingCrawling}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Creeping / Crawling</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral350}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Pull to stand</p>
                </div>
            </div>
            <div className={styles.CtStanding}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtStanding}
                    disabled={ props.viewData.CtStandingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtStanding",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtStandingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral355}>
                <Input
                    value={props.viewData.OtPhysicalCerebral355}
                    disabled={ props.viewData.OtPhysicalCerebral355Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral355',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtSideWalking}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSideWalking}
                    disabled={ props.viewData.CtSideWalkingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtSideWalking",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSideWalkingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral359}>
                <Input
                    value={props.viewData.OtPhysicalCerebral359}
                    disabled={ props.viewData.OtPhysicalCerebral359Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral359',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtForwardWalking}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtForwardWalking}
                    disabled={ props.viewData.CtForwardWalkingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtForwardWalking",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtForwardWalkingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral363}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Standing</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral364}>
                <Input
                    value={props.viewData.OtPhysicalCerebral364}
                    disabled={ props.viewData.OtPhysicalCerebral364Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral364',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral365}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Side walking</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral366}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Forward walking</p>
                </div>
            </div>
            <div className={styles.CtBackwardWalking}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtBackwardWalking}
                    disabled={ props.viewData.CtBackwardWalkingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtBackwardWalking",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtBackwardWalkingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral370}>
                <Input
                    value={props.viewData.OtPhysicalCerebral370}
                    disabled={ props.viewData.OtPhysicalCerebral370Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral370',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtRunning}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtRunning}
                    disabled={ props.viewData.CtRunningDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtRunning",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtRunningOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral374}>
                <Input
                    value={props.viewData.OtPhysicalCerebral374}
                    disabled={ props.viewData.OtPhysicalCerebral374Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral374',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral375}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Backward walking</p>
                </div>
            </div>
            <div className={styles.CtJumping}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtJumping}
                    disabled={ props.viewData.CtJumpingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtJumping",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtJumpingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral379}>
                <Input
                    value={props.viewData.OtPhysicalCerebral379}
                    disabled={ props.viewData.OtPhysicalCerebral379Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral379',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral380}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Running</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral381}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Jumping</p>
                </div>
            </div>
            <div className={styles.CtHopping}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtHopping}
                    disabled={ props.viewData.CtHoppingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtHopping",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtHoppingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral385}>
                <Input
                    value={props.viewData.OtPhysicalCerebral385}
                    disabled={ props.viewData.OtPhysicalCerebral385Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral385',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral386}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Hopping</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral387}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Hand use</strong></p>
                </div>
            </div>
            <div className={styles.CtGraspReflex}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGraspReflex}
                    disabled={ props.viewData.CtGraspReflexDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtGraspReflex",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGraspReflexOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral391}>
                <Input
                    value={props.viewData.OtPhysicalCerebral391}
                    disabled={ props.viewData.OtPhysicalCerebral391Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral391',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral392}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Grasp Reflex</p>
                </div>
            </div>
            <div className={styles.CtOpenHand}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtOpenHand}
                    disabled={ props.viewData.CtOpenHandDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtOpenHand",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtOpenHandOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral396}>
                <Input
                    value={props.viewData.OtPhysicalCerebral396}
                    disabled={ props.viewData.OtPhysicalCerebral396Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral396',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral397}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Open hand</p>
                </div>
            </div>
            <div className={styles.CtReachOut}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtReachOut}
                    disabled={ props.viewData.CtReachOutDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtReachOut",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtReachOutOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3101}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3101}
                    disabled={ props.viewData.OtPhysicalCerebral3101Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3101',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3102}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Reach out</p>
                </div>
            </div>
            <div className={styles.CtHandGrasp}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtHandGrasp}
                    disabled={ props.viewData.CtHandGraspDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtHandGrasp",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtHandGraspOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3106}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3106}
                    disabled={ props.viewData.OtPhysicalCerebral3106Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3106',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3107}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hand grasp</p>
                </div>
            </div>
            <div className={styles.CtWholeHandGrasp}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtWholeHandGrasp}
                    disabled={ props.viewData.CtWholeHandGraspDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtWholeHandGrasp",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtWholeHandGraspOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3111}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3111}
                    disabled={ props.viewData.OtPhysicalCerebral3111Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3111',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3112}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Whole hand grasp</p>
                </div>
            </div>
            <div className={styles.CtPinchGrasp}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtPinchGrasp}
                    disabled={ props.viewData.CtPinchGraspDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtPinchGrasp",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtPinchGraspOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3116}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3116}
                    disabled={ props.viewData.OtPhysicalCerebral3116Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3116',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3117}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pinch grasp</p>
                </div>
            </div>
            <div className={styles.CtLaterality}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtLaterality}
                    disabled={ props.viewData.CtLateralityDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtLaterality",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtLateralityOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3121}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3121}
                    disabled={ props.viewData.OtPhysicalCerebral3121Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3121',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3122}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Laterality</p>
                </div>
            </div>
            <div className={styles.CtBilateralHandUse}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtBilateralHandUse}
                    disabled={ props.viewData.CtBilateralHandUseDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtBilateralHandUse",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtBilateralHandUseOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3126}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3126}
                    disabled={ props.viewData.OtPhysicalCerebral3126Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3126',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3127}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Bilateral hand use</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral3129}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Ambulation</strong></p>
                </div>
            </div>
            <div className={styles.CtDependent}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtDependent}
                    disabled={ props.viewData.CtDependentDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtDependent",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtDependentOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3133}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3133}
                    disabled={ props.viewData.OtPhysicalCerebral3133Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3133',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3134}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dependent</p>
                </div>
            </div>
            <div className={styles.CtWalkWithWalkingAid}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtWalkWithWalkingAid}
                    disabled={ props.viewData.CtWalkWithWalkingAidDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtWalkWithWalkingAid",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtWalkWithWalkingAidOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3138}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3138}
                    disabled={ props.viewData.OtPhysicalCerebral3138Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3138',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3139}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Walk with walking aid</p>
                </div>
            </div>
            <div className={styles.CtRolling}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtRolling}
                    disabled={ props.viewData.CtRollingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtRolling",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtRollingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3143}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3143}
                    disabled={ props.viewData.OtPhysicalCerebral3143Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3143',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3144}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Rolling</p>
                </div>
            </div>
            <div className={styles.CtWheelchair}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtWheelchair}
                    disabled={ props.viewData.CtWheelchairDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtWheelchair",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtWheelchairOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3148}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3148}
                    disabled={ props.viewData.OtPhysicalCerebral3148Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3148',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3149}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Wheelchair</p>
                </div>
            </div>
            <div className={styles.CtCrawlingCreeping}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCrawlingCreeping}
                    disabled={ props.viewData.CtCrawlingCreepingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtCrawlingCreeping",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCrawlingCreepingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3153}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3153}
                    disabled={ props.viewData.OtPhysicalCerebral3153Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3153',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3154}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Crawling / Creeping</p>
                </div>
            </div>
            <div className={styles.CtWalking}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtWalking}
                    disabled={ props.viewData.CtWalkingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtWalking",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtWalkingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3158}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3158}
                    disabled={ props.viewData.OtPhysicalCerebral3158Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3158',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3159}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Walking</p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral3160}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Transfer in sitting  position</p>
                </div>
            </div>
            <div className={styles.CtTransferInSitting}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTransferInSitting}
                    disabled={ props.viewData.CtTransferInSittingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtTransferInSitting",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTransferInSittingOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3164}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3164}
                    disabled={ props.viewData.OtPhysicalCerebral3164Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3164',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtOroMotor}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtOroMotor}
                    disabled={ props.viewData.CtOroMotorDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationCerebralPalsy3",
                                name: "CtOroMotor",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtOroMotorOptions}
                />
            </div>
            <div className={styles.OtPhysicalCerebral3169}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3169}
                    disabled={ props.viewData.OtPhysicalCerebral3169Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3169',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3170}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Oro-motor function</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalCerebral3171}>
                <Input
                    value={props.viewData.OtPhysicalCerebral3171}
                    disabled={ props.viewData.OtPhysicalCerebral3171Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'OtPhysicalCerebral3171',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalCerebral3172}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Other</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'OTPhysicalExaminationCerebralPalsy3',
                            name: 'SaveRequest'
                        }
                    })}}
                    style={{color: "#FFFFFF",backgroundColor: "#27AE60", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
