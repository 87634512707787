// DrugResuscitation
import WasmController from "react-lib/frameworks/WasmController";
import DrugResuscitationBoxList from "issara-sdk/apis/DrugResuscitationBoxList_apps_TPD";
import DrugResuscitationBoxDetail from "issara-sdk/apis/DrugResuscitationBoxDetail_apps_TPD";
import DrugResuscitationBoxItemList from "issara-sdk/apis/DrugResuscitationBoxItemList_apps_TPD";
import DrugResuscitationBoxItemActionLogList from "issara-sdk/apis/DrugResuscitationBoxItemActionLogList_apps_TPD";
import DrugResuscitationBoxActionLogList from "issara-sdk/apis/DrugResuscitationBoxActionLogList_apps_TPD";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";
import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";
import { beToAd } from "react-lib/utils/dateUtils";
import moment from "moment";
import { adToBe } from "react-lib/utils";

export type State = {
  // CommonInterface
  django?: any;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}
  loadingStatus?: any;
  // seq
  DrugResuscitationSequence?: {
    sequenceIndex?: string | null;
    break_code?: string | null;
    container_code?: string | null;
    container_name?: string | null;
    boxItems?: any[];
    listItems?: any[];
    selectedBox?: any;
    drugList?: any[];
    drugUnit?: any;
    drugItems?: any[];
    destinationDivision?: number | null;
    boxLogs?: any;
    drugLogs?: any;
    selectedCode?: string | null;
    encounter?: number | null;
    filterBox?: {
      code: string;
      created_start: string;
      created_end: string;
      returned_start: string;
      returned_end: string;
      status: string;
    };
    returned_datetime?: string;
  } | null;
};

export const StateInitial: State = {
  DrugResuscitationSequence: {
    break_code: "",
    container_code: "",
    container_name: "",
    boxItems: [],
    listItems: [],
    selectedBox: {},
    drugList: [],
    drugUnit: {},
    drugItems: [],
    boxLogs: {},
    drugLogs: [],
    filterBox: {
      code: "",
      created_start: "",
      created_end: "",
      returned_start: "",
      returned_end: "",
      status: "",
    },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const start: Handler = async (controller, params) => {
  var state = controller.getState();
  if (!state.DrugResuscitationSequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["divisionPharma", {}]],
    },
  });

  controller.setState(
    {
      DrugResuscitationSequence: { sequenceIndex: "SearchDrugResuscitation" },
    },
    () => {
      controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: "search" },
      });
    }
  );
};

export const SearchDrugResuscitation: Handler = async (controller, params) => {
  let state = controller.getState();

  if (params.action === "search" || params.action === "refresh") {
    state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    let filterParams = { 
      ...state.DrugResuscitationSequence?.filterBox,
      // division: state.DrugResuscitationSequence?.destinationDivision,
      division: params.division
    };
    if (filterParams?.created_start) {
      filterParams.created_start = moment(
        beToAd(filterParams.created_start)
      ).format("YYYY-MM-DD"); //'%Y-%m-%d'
    }
    if (filterParams?.created_end) {
      filterParams.created_end = moment(
        beToAd(filterParams.created_end)
      ).format("YYYY-MM-DD"); //'%Y-%m-%d'
    }
    if (filterParams?.returned_start) {
      filterParams.returned_start = moment(
        beToAd(filterParams.returned_start)
      ).format("YYYY-MM-DD"); //'%Y-%m-%d'
    }
    if (filterParams?.returned_end) {
      filterParams.returned_end = moment(
        beToAd(filterParams.returned_end)
      ).format("YYYY-MM-DD"); //'%Y-%m-%d'
    }
    const [response, error, network] = await DrugResuscitationBoxList.list({
      params: !params?.modeName && filterParams,
      apiToken: controller.apiToken,
    });

    let tempSel = {};
    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      state = controller.getState();

      let drugBoxList: any[] = [];
      if (state.django?.user?.role_types.includes("REGISTERED_NURSE") && params?.isNurseScreen) {
        drugBoxList = response.items.filter(
          (item: any) =>
            item?.status_name === "OPENED" ||
            item?.status_name === "ACTIVE" ||
            item?.status_name === "RETURNED"
        );
      } else {
        drugBoxList = response.items;
      }

      const _boxItems = drugBoxList.map((box: any) => {
        return {
          ...box,
          items: box.items.map((item: any) => {
            return {
              ...item,
              expired_be: adToBe(moment(item.expired, "YYYY-MM-DD")),
            };
          }),
        };
      });

      tempSel = state.DrugResuscitationSequence?.selectedBox;
      if (tempSel) {
        tempSel = _boxItems?.filter((t: any) => t.id === tempSel.id)[0];
      }

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
        DrugResuscitationSequence: {
          ...state.DrugResuscitationSequence,
          boxItems: _boxItems || [],
        },
      });
    }

    SearchDrugResuscitation(controller, {
      ...params,
      action: "SET_SELECTED",
      selectedBox: tempSel,
    });
  } else if (params.action === "drugSearch") {
    console.log("DrugSearch");
    const [response, error, network] = await DrugResuscitationBoxList.list({
      params: {
        code: state.DrugResuscitationSequence?.selectedCode,
        // division: controller.data.division,
        // encounter: params.encounter.id
      },
      apiToken: controller.apiToken,
    });
    console.log("search  drug response", response);
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: response },
      loadingStatus: { ...state.loadingStatus, [params?.sequence]: false },
      DrugResuscitationSequence: {
        ...state.DrugResuscitationSequence,
        listItems: response.items || [],
      },
    });
  } else if (params.action === "SET_SELECTED") {
    controller.setState({
      DrugResuscitationSequence: {
        ...state.DrugResuscitationSequence,
        selectedBox: params.selectedBox,
        returned_datetime: params.selectedBox?.returned_datetime,
        // destinationDivision: params.selectedBox?.destination_division,
        break_code: params.selectedBox?.break_code || "",
        container_code: params.selectedBox?.container_code || "",
        container_name: params.selectedBox?.container_name || "",
        drugItems: params.selectedBox?.items || [],
      },
    });
  } else if (params?.action === "CREATE") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params?.card]: true },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_CREATE_EDIT`]: "LOADING",
      },
    });

    const [response, error] = await DrugResuscitationBoxList.create({
      data: {
        action: "CREATE",
        break_code: state.DrugResuscitationSequence?.break_code || "",
        container_code: state.DrugResuscitationSequence?.container_code || "",
        container_name: state.DrugResuscitationSequence?.container_name || "",
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (response) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.card]: response,
        },
        loadingStatus: { ...state.loadingStatus, [params?.card]: false },
        DrugResuscitationSequence: {
          ...state.DrugResuscitationSequence,
          selectedBox: response,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_CREATE_EDIT`]: "SUCCESS",
        },
      });

      params.onSuccess?.();
      SearchDrugResuscitation(controller, { ...params, action: "refresh" });
    }
    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: error },
        loadingStatus: { ...state.loadingStatus, [params?.card]: false },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_CREATE_EDIT`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "EDIT") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params?.card]: true },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_CREATE_EDIT`]: "LOADING",
      },
    });

    const [response, error] = await DrugResuscitationBoxDetail.update({
      pk: state.DrugResuscitationSequence?.selectedBox?.id,
      data: {
        action: "EDIT",
        break_code: state.DrugResuscitationSequence?.break_code || "",
        container_code: state.DrugResuscitationSequence?.container_code || "",
        container_name: state.DrugResuscitationSequence?.container_name || "",
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (response) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.card]: response,
        },
        loadingStatus: { ...state.loadingStatus, [params?.card]: false },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_CREATE_EDIT`]: "SUCCESS",
        },
      });

      params.onSuccess?.();
      SearchDrugResuscitation(controller, { ...params, action: "refresh" });
    }
    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: error },
        loadingStatus: { ...state.loadingStatus, [params?.card]: false },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_CREATE_EDIT`]: "ERROR",
        },
      });
    }
  } else if (
    params?.action === "ACTIVATE" ||
    params?.action === "RETURN" ||
    params?.action === "RETURN_TO_ACTIVE" ||
    params?.action === "RETURN_TO_OPEN" ||
    params?.action === "APPROVE" ||
    params?.action === "CANCEL_APPROVE" ||
    params?.action === "REPLACE" ||
    params?.action === "REJECT"
  ) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params?.card]: true },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}`]: "LOADING",
      },
    });

    if (params?.action === "REPLACE") {
      // REPLACE to new drug resuscitation
      const [drugBoxDetailRes, drugBoxDetailErr, drugBoxDetailNet] =
        await DrugResuscitationBoxDetail.update({
          pk: state.DrugResuscitationSequence?.selectedBox?.id,
          data: {
            action: params?.action,
            break_code: state.DrugResuscitationSequence?.break_code || "",
            destination_division:
              state.DrugResuscitationSequence?.destinationDivision,
          },
          extra: { division: controller.data.division },
          apiToken: controller.apiToken,
        });

      if (drugBoxDetailRes) {
        let drugItems = state.DrugResuscitationSequence?.drugItems;

        let addDrugItem = drugItems?.map((item: any) => {
          return {
            _id: null,
            box: drugBoxDetailRes?.id,
            drug: item.drug,
            generic_name: item.generic_name,
            total_quantity: item.total_quantity,
            quantity: item.quantity,
            return_quantity: item.return_quantity,
            default_dosage_unit_name: item.default_dosage_unit_name,
            expired: item.expired,
            expired_be: item.expired_be,
          };
        });

        // update items to new drug resuscitation
        const [createDrugRes, createDrugErr, createDrugNet] =
          await DrugResuscitationBoxItemList.create({
            data: {
              box: drugBoxDetailRes?.id,
              items: addDrugItem,
            },
            extra: { division: controller.data.division },
            apiToken: controller.apiToken,
          });

        const [drugBoxListRes, drugBoxListErr, drugBoxListNet] =
          await DrugResuscitationBoxList.list({
            params: {
              code: drugBoxDetailRes?.code,
            },
            apiToken: controller.apiToken,
          });

        controller.setState({
          DrugResuscitationSequence: {
            ...state.DrugResuscitationSequence,
            selectedBox: drugBoxListRes?.items?.[0],
          },
          successMessage: {
            ...state.successMessage,
            [params?.card]: drugBoxDetailRes,
          },
          loadingStatus: { ...state.loadingStatus, [params?.card]: false },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}`]: "SUCCESS",
          },
        });

        params.onSuccess?.();
        SearchDrugResuscitation(controller, { ...params, action: "refresh" });
      } else {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params?.card]: drugBoxDetailErr,
          },
          loadingStatus: { ...state.loadingStatus, [params?.card]: false },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}`]: "ERROR",
          },
        });
      }
    } else {
      // update drug items before PUT action
      const [createDrugRes, createDrugErr, createDrugNet] =
        await DrugResuscitationBoxItemList.create({
          data: {
            box: state.DrugResuscitationSequence?.selectedBox?.id,
            items: state.DrugResuscitationSequence?.drugItems,
          },
          extra: { division: controller.data.division },
          apiToken: controller.apiToken,
        });

      // update drug items before PUT action
      const [drugBoxDetailRes, drugBoxDetailErr, drugBoxDetailNet] =
        await DrugResuscitationBoxDetail.update({
          pk: state.DrugResuscitationSequence?.selectedBox?.id,
          data: {
            action: params?.action,
            break_code: params?.action === "APPROVE" ? "" : state.DrugResuscitationSequence?.break_code || "",
            destination_division:
              state.DrugResuscitationSequence?.destinationDivision,
          },
          extra: { division: controller.data.division },
          apiToken: controller.apiToken,
        });

      if (drugBoxDetailRes) {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params?.card]: drugBoxDetailRes,
          },
          loadingStatus: { ...state.loadingStatus, [params?.card]: false },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}`]: "SUCCESS",
          },
        });

        params.onSuccess?.();
        SearchDrugResuscitation(controller, { ...params, action: "refresh" });
      } else {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params?.card]: drugBoxDetailErr,
          },
          loadingStatus: { ...state.loadingStatus, [params?.card]: false },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}`]: "ERROR",
          },
        });
      }
    }
  } else if (params?.action === "_delete_box") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params?.card]: true },
    });

    const [response, error] = await DrugResuscitationBoxDetail.delete({
      pk: state.DrugResuscitationSequence?.selectedBox?.id,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: error },
        loadingStatus: { ...state.loadingStatus, [params?.card]: false },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.card]: response,
        },
        loadingStatus: { ...state.loadingStatus, [params?.card]: false },
        DrugResuscitationSequence: {
          ...state.DrugResuscitationSequence,
          selectedBox: {},
        },
      });

      params.onSuccess?.();
      SearchDrugResuscitation(controller, { ...params, action: "refresh" });
    }
  } else if (params?.action === "SEARCH_DRUG") {
    const state = controller.getState();

    if (params.keyword.length < 3) {
      controller.setState({
        DrugResuscitationSequence: {
          ...state.DrugResuscitationSequence,
          drugList: [],
        },
      });
      return;
    }
    const [drugResp, drugErr, drugNet] = await DrugList.list({
      apiToken: controller.apiToken,
      params: { keyword: params.keyword },
      extra: { division: controller.data.division },
    });

    controller.setState({
      DrugResuscitationSequence: {
        ...state.DrugResuscitationSequence,
        drugList: drugResp.items,
      },
    });
  } else if (params?.action === "GET_DERUG_UNIT") {
    const [drugResp, drugErr, drugNet] = await DrugDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.id,
    });

    const state = controller.getState();

    controller.setState({
      DrugResuscitationSequence: {
        ...state.DrugResuscitationSequence,
        drugUnit: {
          unit: drugResp?.default_dosage_unit,
          route: drugResp?.default_route,
          site: drugResp?.default_site,
          frequency: drugResp?.default_frequency,
          method: drugResp?.default_method,
        },
      },
    });
  } else if (params?.action === "_add_drug") {
    const state = controller.getState();

    let items = state.DrugResuscitationSequence?.drugItems || [];
    const tmp = {
      _id: null,
      box: state.DrugResuscitationSequence?.selectedBox?.id,
      drug: params.data.drugSelected.id,
      // generic_name: params.data.drugSelected.generic_name,
      generic_name: params.data.drugSelected.full_name,
      total_quantity: params.data.qty,
      quantity: params.data.qty,
      return_quantity: 0,
      default_dosage_unit_name: params.data.drugSelected.stock_unit_name,
      expired: moment(beToAd(params.data.expire)).format("YYYY-MM-DD"), //'%Y-%m-%d'
      expired_be: params.data.expire,
    };

    items.push(tmp);

    controller.setState({
      DrugResuscitationSequence: {
        ...state.DrugResuscitationSequence,
        drugItems: items,
      },
    });
  } else if (params?.action === "_delete_drug") {
    const state = controller.getState();

    let items = state.DrugResuscitationSequence?.drugItems || [];
    items.splice(params?.idx, 1);

    console.log(items);

    controller.setState({
      DrugResuscitationSequence: {
        ...state.DrugResuscitationSequence,
        drugItems: items,
      },
    });
  } else if (params?.action === "CREATE_ITEMS") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const [response, error] = await DrugResuscitationBoxItemList.create({
      data: {
        box: state.DrugResuscitationSequence?.selectedBox?.id,
        items: state.DrugResuscitationSequence?.drugItems,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: error },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.card]: response,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });

      params.onSuccess?.();
      SearchDrugResuscitation(controller, { ...params, action: "refresh" });
    }
  } else if (params?.action === "getBoxActionLog") {
    const [logResp, logErr, logNet] =
      await DrugResuscitationBoxActionLogList.list({
        apiToken: controller.apiToken,
        pk: params.boxId,
      });

    const state = controller.getState();

    controller.setState({
      DrugResuscitationSequence: {
        ...state.DrugResuscitationSequence,
        boxLogs: logResp,
      },
    });
  } else if (params?.action === "getDrugActionLog") {
    const state = controller.getState();
    const [logResp, logErr, logNet] =
      await DrugResuscitationBoxItemActionLogList.get({
        apiToken: controller.apiToken,
        params: { box: state.DrugResuscitationSequence?.selectedBox?.id },
      });

    let _items = logResp?.items;
    console.log("division", controller.data.division);
    const divName = params?.masterDivision.find(
      (options: any) => options.value === controller.data.division
    )?.text;
    console.log("divName", divName);
    if (params?.isNurseScreen) {
      console.log("isNurseScreen", params.isNurseScreen);
      _items = _items?.filter((t: any) => t.division_name === divName);
    }
    controller.setState({
      DrugResuscitationSequence: {
        ...state.DrugResuscitationSequence,
        drugLogs: _items,
      },
    });
  }
};

export const refresh: Handler = async (controller, params) => {
  const state = controller.getState();
};
