import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input
} from 'semantic-ui-react'

const ANES_PACU_Intra = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPACURecordIntraoperative"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"40%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>

          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(10, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "3/4", gridColumn: "3/8", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Crystalloid :
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "3/8", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Colloid
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "3/8", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Blood
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "8/15", paddingBottom:"10px"}}>
              <Input
                name="Crystalloid"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.Crystalloid || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "8/15",  paddingBottom:"10px"}}>
              <Input
                name="Colloid"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.Colloid || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "8/15", paddingBottom:"10px"}}>
              <Input
                name="Blood"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.Blood || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "16/18", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ml
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "16/18" , paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ml
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "16/18", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ml
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "23/27", paddingBottom:"10px" }}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Blood loss :
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "23/27",paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Urine :
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "23/27", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Drain :
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "28/35", paddingBottom:"10px"}}>
              <Input
                name="BloodLoss"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.BloodLoss || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "28/35", paddingBottom:"10px" }}>
              <Input
                name="Urine"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.Urine || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "28/35",  paddingBottom:"10px" }}>
              <Input
                name="Drain"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.Drain || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "36/38", paddingBottom:"10px" }}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ml
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "36/38", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ml
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "36/38", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ml
              </label>
            </div>
            <div
              style={{ gridRow: "1/11", gridColumn: "20/20", display: "flex", alignItems: "center", justifyContent: "center"}}>

              <div
                style={{borderLeft: "0.5px solid #5DBCD2", height: "calc(100% - -22px) "}}>

              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "2/18", paddingBottom:"10px", fontWeight: "bold", fontSize: "1.5rem"}}>
              Intraoperative intake
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "22/40", paddingBottom:"10px", fontWeight: "bold", fontSize: "1.5rem"}}>
              Intraoperative output
            </div>
          </div>
        </div>
      </div>
    )
}


export default ANES_PACU_Intra

export const screenPropsDefault = {}

/* Date Time : Mon Sep 23 2024 11:36:52 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPACURecordIntraoperative"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"40%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(10, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"3/8\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Crystalloid :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"3/8\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"3/8\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Colloid"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"8/15\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"8/15\",  paddingBottom:\"10px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"8/15\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"16/18\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"16/18\" , paddingBottom:\"10px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"16/18\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"23/27\", paddingBottom:\"10px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"23/27\",paddingBottom:\"10px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"23/27\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"28/35\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"28/35\", paddingBottom:\"10px\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"28/35\",  paddingBottom:\"10px\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"36/38\", paddingBottom:\"10px\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"36/38\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"36/38\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood loss :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "Urine :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Drain :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Input",
      "parent": 9,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "Crystalloid"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.Crystalloid || \"\""
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Input",
      "parent": 10,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "Colloid"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.Colloid || \"\""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Input",
      "parent": 11,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "Blood"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.Blood || \"\""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Input",
      "parent": 18,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "BloodLoss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.BloodLoss || \"\""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Input",
      "parent": 19,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "Urine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.Urine || \"\""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Input",
      "parent": 20,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "Drain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.Drain || \"\""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/11\", gridColumn: \"20/20\", display: \"flex\", alignItems: \"center\", justifyContent: \"center\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{borderLeft: \"0.5px solid #5DBCD2\", height: \"calc(100% - -22px) \"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Intraoperative intake"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/18\", paddingBottom:\"10px\", fontWeight: \"bold\", fontSize: \"1.5rem\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Intraoperative output"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"22/40\", paddingBottom:\"10px\", fontWeight: \"bold\", fontSize: \"1.5rem\"}"
        }
      },
      "seq": 42,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PACU_Intra",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
