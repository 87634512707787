import React, { useState } from "react";
import { Dimmer, Loader, Icon } from "semantic-ui-react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useSize } from "react-lib/utils/hooksUtils";
import LineLoginButton from "react-lib/apps/IsHealth/Common/Line/LoginButton";
import FBLoginButton from "react-lib/apps/IsHealth/Common/FacebookLogin/FBLoginButton";
import AppleIDLoginButton from "react-lib/apps/IsHealth/Common/AppleIDLogin/LoginButton";
import settings from "../config/config";
import {facebookAppId, versionFacebook} from "./MainPatient";

const Login = props => {
  const history = useHistory();
  const lineButtonRef = React.useRef();
  const { height } = useSize(lineButtonRef);
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const show_apple = params.get('apple');
  const [deviceId, setDeviceId] = useState(null);

  const loadFbLoginApi = (_deviceId) => {
    console.log(" loadFbLoginApi called fbAsyncInit")
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: versionFacebook
      });
      window.FB.AppEvents.logPageView();
      // window.FB.Event.subscribe("auth.statusChange", response => {
      //   if (response.authResponse) {
      //     checkLoginState(_deviceId);
      //   } else {
      //     checkLoginState(_deviceId);
      //   }
      // });
      checkLoginState(_deviceId)
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/th_TH/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  const responseFacebook = async (fbResponse, _deviceId) => {
    if (
      fbResponse.status === "not_authorized" ||
      fbResponse.status === "unknown"
    ) {
      return;
    }
    setIsLoading(true);
    if (fbResponse.authResponse && fbResponse.authResponse.accessToken) {
      if (fbResponse.authResponse) {
        props.onLoginFacebookSiteSuccess(fbResponse.authResponse);
        await window.FB.api(
          "/me",
          "get",
          { fields: "email,name,picture" },
          async res => {
            if (!("email" in res)) {
              alert("User ไม่ได้อนุญาติ ให้เข้าถึง email-address");
              setIsLoading(false);
              props.openSetupEmail();
              return;
            }
            let [
              response,
              error,
              network
            ] = await props.controller.getRegisterByTokenFacebook({
              accessToken: fbResponse.authResponse.accessToken,
              app: settings.APP_NAME ? settings.APP_NAME : "ishealth",
              device_id: _deviceId
            });

            if (response) {
              props.onLoginSuccess(response);
            } else {
              setIsLoading(false);
            }
          }
        );
      }
    }
  };

  React.useEffect(() => {
    setDeviceId(props.device_id)
    if (props.device_id !== null) {
      loadFbLoginApi(props.device_id);
    }
    
  }, [props.device_id])

  React.useEffect(() => {

    console.log({ cookies: Cookies.get("apiToken"), props: props.apiToken , oaChannelId: Cookies.get("oaChannelId")})
    if ((Cookies.get("apiToken") || props.apiToken) && !props.alreadyRedirect) {
      console.log(" useEffect props.onHaveApiToken() ")
      props.onHaveApiToken();
    }

  }, [props.apiToken, Cookies.get("apiToken")])

  React.useEffect(() => {
    console.log(" uesEffect", Cookies.get("apiToken"), "props.IsAnonymousLogin", props.IsAnonymousLogin);
    // loadFbLoginApi();
    // if (Cookies.get("apiToken") || props.apiToken) {
    //   props.onHaveApiToken();
    // }
    if (props.IsAnonymousLogin && Cookies.get("apiToken") === "anonymous") {
      props.onHaveAnonymousLogin();
    }
    
  }, []);

  const gotoLineLogin = () => {
    props.loginLine();
    // if (props.lineLoginManager) {
    //   props.lineLoginManager.openLogin();
    // }
  };

  const checkLoginState = (_deviceId) => {
    window.FB.getLoginStatus(response => {
      console.log("checkLoginState  response", response )
      responseFacebook(response, _deviceId);
    });
  };

  const handleFBClick = () => {
    // window.FB.login((res) => {}, {scope: 'public_profile,email'});

    window.FB.login(function (response) {
      if (response.authResponse) {

        console.log("Login success ", response)
        checkLoginState(props.device_id)
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {scope: 'public_profile,email'});

  };

  return (
    <Dimmer.Dimmable dimmed={isLoading || props.loading}>
      <div className="Login">
        <div className="fbLogin">
          {props.detail}
          <LineLoginButton
            ref={lineButtonRef}
            onClick={gotoLineLogin}
            style={{ width: "90%", lineHeight: "32px" }} // 32px is size of Line Logo Image
            logoStyle={{ float: "left" }}
          />
          <br />
          <br />
          <FBLoginButton
            onClick={handleFBClick}
            style={{ height, width: "90%", lineHeight: "32px" }}
            logoStyle={{ height: height, padding: "5px", float: "left" }} // 32px is size of Line Logo Image
          />
          <br />
          <br />
          <div
            className="email-login-button"
            onClick={() => {
              history.push("/EmailLogin/");
            }}
          >
          <Icon style={{ padding: "5px", float: "left" }} name="mail" size="large"/>
            <span >ลงชื่อเข้าใช้งานด้วยอีเมล</span>
          </div>
          <br/>
          <br/>
          {show_apple?<AppleIDLoginButton/>:<></>}
        </div>
      </div>
      <Dimmer active={isLoading} inverted>
        <Loader size="massive">{"Loading"}</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

Login.defaultProps = {
  onLoginSuccess: () => {},
  IsAnonymousLogin: false,
  OnAnonymousLoginSuccess: () => {},
  onLoginFacebookSiteSuccess: () => {},
  onHaveApiToken: () => {},
  onHaveAnonymousLogin: () => {},
  openSetupEmail: () => {},
  detail: null,
  redirectUri: null,
  device_id: null,
  subscription: null,
  loginLine: () => {},
};

Login.propTypes = {
  onLoginSuccess: PropTypes.func,
  IsAnonymousLogin: PropTypes.bool,
  OnAnonymousLoginSuccess: PropTypes.func,
  onLoginFacebookSiteSuccess: PropTypes.func,
  onHaveApiToken: PropTypes.func,
  onHaveAnonymousLogin: PropTypes.func,
  openSetupEmail: PropTypes.func,
  redirectUri: PropTypes.string,
  device_id: PropTypes.string,
  subscription: PropTypes.object,
  loginLine: PropTypes.func,
};

export default Login;
