import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button,
  Input
} from 'semantic-ui-react'

const RefillDivisionPrintUX = (props: any) => {
    return(
      <div>
        <div
          style={{fontWeight: "bold", fontSize: "1.4rem", padding: "1rem"}}>
          พิมพ์รายการจัดเวชภัณฑ์สำหรับหน่วยบริการ (REFILL-DIVISION-PRINT)
        </div>
        <div>

          <div
            style={{padding: "10px", display: "flex", alignItems: "center", backgroundColor: "#F3F3F3" }}>
            {props.container}
          </div>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{marginLeft: "1rem", marginTop: "1rem"}}>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                onClick={props.selectAll}
                style={{width: "150px", }}>
                เลือกทั้งหมด
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                onClick={props.unSelectAll}
                style={{width: "150px", }}>
                ไม่เลือก
              </Button>
            </FormField>
            <FormField
              inline={true}
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}>
              <Input
                label={{ basic: false, content: 'มูลค่ารวม' , style: {margin: 0, display: "flex", alignItems: "center"}}}
                readOnly={true}
                style={{width: "400px"}}
                value={props.total}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSave}
              </div>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{width:"100%"}}>
          {props.ErrorMessage}
        </div>
      </div>
    )
}


export default RefillDivisionPrintUX

export const screenPropsDefault = {}

/* Date Time : Wed Jul 31 2024 16:29:16 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายการจัดเวชภัณฑ์สำหรับหน่วยบริการ (REFILL-DIVISION-PRINT)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.4rem\", padding: \"1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.container"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", alignItems: \"center\", backgroundColor: \"#F3F3F3\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\", marginTop: \"1rem\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกทั้งหมด"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.selectAll"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่เลือก"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.unSelectAll"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Input",
      "parent": 18,
      "props": {
        "label": {
          "type": "code",
          "value": "{ basic: false, content: 'มูลค่ารวม' , style: {margin: 0, display: \"flex\", alignItems: \"center\"}}"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"400px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.total"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 24,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "RefillDivisionPrintUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
