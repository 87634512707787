import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Input,
  Icon,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'

const CardSpecimenCollectorUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            inline={true}>
            <FormField>
              <div
                style={{fontSize: "1.2rem", fontWeight: "bold"}}>
                Specimen
              </div>
            </FormField>
            <FormField>
              <Checkbox
                checked={props.filterData?.isLabDivision}
                label="Lab Division"
                name="isLabDivision"
                onChange={props.onFilterChange}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              width={5}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="labDivision"
                onChange={props.onFilterChange}
                options={props.specimenCollectorMasterData?.labDivisionOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterData?.labDivision}>
              </Dropdown>
            </FormField>
            <FormField>
              <Checkbox
                checked={props.filterData?.isStatus}
                label="สถานะ"
                name="isStatus"
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              width={5}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="status"
                onChange={props.onFilterChange}
                options={props.specimenCollectorMasterData?.labItemStatusOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterData?.status}>
              </Dropdown>
            </FormField>
            <FormField>
              <Button
                color="blue"
                onClick={props.onSearch}
                size="small">
                ค้นหา
              </Button>
            </FormField>
            <FormField
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.filterData?.isFinished}
                label="เก็บ Specimen เสร็จสิ้น"
                name="isFinished"
                onChange={props.onFilterChange}>
              </Checkbox>
            </FormField>
          </FormGroup>
        </Form>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          columns={props.columns_specimenItems}
          data={props.containerList}
          getTrProps={props.rowProps}
          minRows={props.containerList.length > 10 ? props.containerList.length : 10}
          resizable={false}
          showPagination={false}
          style={{ height: "55vh" }}>
        </Table>
        <div
          style={{display: "flex" , marginTop: "1rem"}}>
          
          <Button
            color="green"
            disabled={props.disablePrint}
            loading={props.loadingPrintSticker}
            onClick={props.onPrintSticker}
            size="mini">
            {props.print}
          </Button>
          <Button
            color="orange"
            disabled={props.disablePrint}
            loading={props.loadingPrintRobo}
            onClick={props.onPrintRobo}
            size="mini"
            style={{marginLeft: "0.5rem"}}>
            {props.robo}
          </Button>
          <Button
            color="blue"
            disabled={props.disabledPrintLabSummary || false}
            onClick={props.onPrintLabSummary}
            size="mini"
            style={{ display: "flex", paddingRight: "0.5rem", marginLeft: "0.5rem", alignItems: "center"}}>
            
            <label
              style={{marginRight: "0.75rem"}}>
              พิมพ์สรุปรายการแลป
            </label>
            <Icon
              className="large"
              name="print">
            </Icon>
          </Button>
          <div
            style={{flex: 1}}>
            
          </div>
          <Input
            disabled={props.disableInputUsername || false}
            onChange={props.changeUsername}
            placeholder="username"
            size="mini"
            style={{display: "none"}}
            value={props.username}>
          </Input>
          <div
            style={{marginRight: "0.5rem", display: "flex", alignItems: "center"}}>
            Password
          </div>
          <div>
            {props.passwordComponent}
          </div>
          <Button
            color="blue"
            disabled={props.disablePrint}
            loading={props.loadingCollect}
            onClick={props.handleCollect}
            size="mini"
            style={{marginLeft: "0.5rem"}}>
            {props.collect}
          </Button>
          <div
            style={{marginLeft: "0.5rem"}}>
            {props.buttonReceive}
          </div>
          <div
            style={{marginLeft: "0.5rem"}}>
            {props.buttonReject}
          </div>
        </div>
      </div>
    )
}


export default CardSpecimenCollectorUX

export const screenPropsDefault = {}

/* Date Time : Wed Jul 17 2024 11:35:06 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "columns": {
          "type": "code",
          "value": "props.columns_specimenItems"
        },
        "data": {
          "type": "code",
          "value": "props.containerList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "code",
          "value": "props.containerList.length > 10 ? props.containerList.length : 10"
        },
        "resizable": {
          "type": "code",
          "value": "false"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55vh\" }"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.print"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disablePrint"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrintSticker"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintSticker"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.collect"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disablePrint"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingCollect"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleCollect"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 13,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableInputUsername || false"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUsername"
        },
        "placeholder": {
          "type": "value",
          "value": "username"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" , marginTop: \"1rem\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReceive"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReject"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrintLabSummary || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintLabSummary"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingRight: \"0.5rem\", marginLeft: \"0.5rem\", alignItems: \"center\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Icon",
      "parent": 25,
      "props": {
        "className": {
          "type": "value",
          "value": "large"
        },
        "name": {
          "type": "value",
          "value": "print"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์สรุปรายการแลป"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.robo"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.disablePrint"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrintRobo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintRobo"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormGroup",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 30,
      "props": {
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 30,
      "props": {
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 30,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 30,
      "props": {
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 30,
      "props": {
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 30,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "Specimen"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Checkbox",
      "parent": 32,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterData?.isLabDivision"
        },
        "label": {
          "type": "value",
          "value": "Lab Division"
        },
        "name": {
          "type": "value",
          "value": "isLabDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.onFilterChange"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Checkbox",
      "parent": 34,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterData?.isStatus"
        },
        "label": {
          "type": "value",
          "value": "สถานะ"
        },
        "name": {
          "type": "value",
          "value": "isStatus"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterData?.isFinished"
        },
        "label": {
          "type": "value",
          "value": "เก็บ Specimen เสร็จสิ้น"
        },
        "name": {
          "type": "value",
          "value": "isFinished"
        },
        "onChange": {
          "type": "code",
          "value": "props.onFilterChange"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "labDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.onFilterChange"
        },
        "options": {
          "type": "code",
          "value": "props.specimenCollectorMasterData?.labDivisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterData?.labDivision"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Dropdown",
      "parent": 35,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onFilterChange"
        },
        "options": {
          "type": "code",
          "value": "props.specimenCollectorMasterData?.labItemStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterData?.status"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Password"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.passwordComponent"
        }
      },
      "seq": 24,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 55,
  "isMounted": false,
  "memo": false,
  "name": "CardSpecimenCollectorUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 55
}

*********************************************************************************** */
