import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Modal } from "semantic-ui-react";

import moment from "moment";

// Common
import ModLotNoExpUX from "./ModLotNoExpUX";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import EditorColumn from "react-lib/appcon/common/EditorColumn";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { useIntl } from "react-intl";

// Types
type ModLotNoExpProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // data
  data?: Record<string, any> | null;
  open: boolean;
  lotNoExpList?: any[];
  orderPerformDiv?: string;
  // config
  readOnly?: boolean;
  // callback
  onClose: Function;
};

// Const
const MOD_LOT_NO_EXP = "ModLotNoExp";

const ModLotNoExp = (props: ModLotNoExpProps) => {
  const intl = useIntl();
  const [lotList, setLotList] = useState<any[]>([]);

  useEffect(() => {
    if (props.open && props.data?.product) {
      props.onEvent({
        message: "GetLotNoExp",
        params: {
          productId: props.data.product,
          orderPerformDiv: props.orderPerformDiv,
          orderItem: props.data.id,
        },
      });
    }
  }, [props.data, props.open]);

  useEffect(() => {
    setLotList(props.lotNoExpList || []);
  }, [props.lotNoExpList]);

  const handleChangeEdit = useCallback(
    (data: { item: any; value: string; index: number; key: string }) => {
      const list = [...lotList];
      let value = data.value as string;

      if (Number(value) < 0) {
        value = "";
      } else if (Number(value) > Number(data.item.onh_qty)) {
        value = data.item.onh_qty;
      }

      list[data.index][data.key] = !Number(value)
        ? ""
        : Math.ceil(Number(value));
      list[data.index]["is_change"] = true;

      setLotList(list);
    },
    [lotList]
  );

  const alignCenter = useCallback((item: any, keys: string) => {
    return keys.split(",").reduce(
      (result, key) => ({
        ...result,
        [key]: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {(item as any)[key]}
          </div>
        ),
      }),
      {}
    );
  }, []);

  const lotItems = useMemo(() => {
    return lotList.map((item, index) => ({
      ...item,
      disp_qty: (
        <EditorColumn
          value={item.disp_qty}
          valueOnFocus={true}
          selectOnFocus={true}
          disabled={props.readOnly}
          // style
          backgroundColor="rgb(255, 255, 204)"
          onDataChanged={(value: string) =>
            handleChangeEdit({ value, index, key: "disp_qty", item })
          }
        />
      ),
      ...alignCenter(
        {
          lot_no: item.lot?.mfc_no || "",
          exp: item.lot?.exp_datetime
            ? formatDate(moment(item.lot.exp_datetime))
            : "",
          onh_qty: item.quantity,
          unit: item.product?.unit_name || "",
        },
        "lot_no,exp,onh_qty,unit"
      ),
    }));
  }, [lotList, props.readOnly]);

  const allowEdit = useMemo(() => {
    const sum = lotList.reduce(
      (result, item) => result + Number(item.disp_qty),
      0
    );

    return props.data?.quantity && props.data?.quantity === sum;
  }, [props.data?.quantity, lotList]);

  const handleSave = (e: any, data: any) => {
    props.onEvent({
      message: "UpdateLotNoExp",
      params: {
        card: MOD_LOT_NO_EXP,
        data: lotList.filter((item) =>
          !item.plan_id && !Number(item.disp_qty) ? false : item.is_change
        ),
        orderItem: props.data?.id,
        action: data.name,
        onSuccess: handleClose,
      },
    });
  };

  const handleClose = () => {
    props.setProp("lotNoExpList", []);

    props.onClose();
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${MOD_LOT_NO_EXP}`, null);
        }}
        error={props.errorMessage?.[MOD_LOT_NO_EXP]}
        success={null}
        languageUX={props.languageUX}
      />

      <Modal
        open={props.open}
        size="small"
        style={{ width: "40%" }}
        closeOnDimmerClick={true}
        onClose={handleClose}
      >
        <ModLotNoExpUX
          // data
          lotList={lotItems}
          name={`[${props.data?.code || ""}] ${props.data?.name || ""} Qty ${
            props.data?.quantity || ""
          } ${props.data?.stock_unit_name || ""}`}
          // callback
          onClose={handleClose}
          // Element
          ButtonSave={
            !props.readOnly && (
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleSave}
                // data
                paramKey={`${MOD_LOT_NO_EXP}_SAVE`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`${MOD_LOT_NO_EXP}_SAVE`]
                }
                // config
                disabled={!allowEdit}
                color={"green"}
                name="SAVE"
                size="small"
                title={intl.formatMessage({ id: "ยืนยัน" })}
              />
            )
          }
          languageUX={props.languageUX}
        />
      </Modal>
    </>
  );
};
// lot_no: "1A/003",
//     exp: "31/12/2565",
//     onh_qty: "65",
//     disp_qty: "3",
//     unit: "Tab",

export default React.memo(ModLotNoExp);
