import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Dropdown
} from 'semantic-ui-react'

const ModConsultDoctorListUX = (props: any) => {
    return(
      <div
        style={{padding: "20px"}}>
        <div
          style={{display: "flex", paddingBottom: "5px"}}>
          
          <div
            style={{fontWeight: "bold", paddingRight: "10px"}}>
            วันที่เลือก:
          </div>
          <div>
            {props.dsbDetail}
          </div>
        </div>
        <Table
          data={props.consultDoctorList}
          defaultPageSize="5"
          getTrProps={props.onSelectDSBRow}
          headers="แพทย์,วันที่,เริ่ม,สิ้นสุด,เก้าอี้,ZONE,จำนวนนัดหมาย"
          keys="doctor_name,dateText,start_time,end_time,chair,zone,appointmentCount"
          minRows="5"
          pageSize="5"
          showPagination={false}
          style={{height: "250px"}}
          widths=",100,80,80,,,120">
        </Table>
        <div
          style={{padding: "10px", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          
          <div
            style={{display: "flex", fontWeight: "bold", paddingRight: "5px"}}>
            
            <div>
              ระบุเวลานัดหมาย
            </div>
            <div
              style={{color: "red"}}>
              *
            </div>
          </div>
          <Dropdown
            onChange={props.onTimeChange}
            options={props.timeOptions}
            selection={true}
            style={{marginRight: "10px"}}
            value={props.time}>
          </Dropdown>
          <Button
            color="green"
            disabled={!props.time}
            onClick={props.onSelectDSB}>
            เลือก
          </Button>
          <Button
            color="red"
            onClick={props.onCancel}>
            ยกเลิก
          </Button>
        </div>
      </div>
    )
}


export default ModConsultDoctorListUX

export const screenPropsDefault = {"drugSearch":"[Drug Search Box]"}

/* Date Time : Wed Oct 11 2023 15:16:19 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"20px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", paddingBottom: \"5px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เลือก:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", paddingRight: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dsbDetail"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 6,
      "name": "Table",
      "parent": 0,
      "props": {
        "columns": {
          "type": "value",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.consultDoctorList"
        },
        "defaultPageSize": {
          "type": "value",
          "value": "5"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectDSBRow"
        },
        "headers": {
          "type": "value",
          "value": "แพทย์,วันที่,เริ่ม,สิ้นสุด,เก้าอี้,ZONE,จำนวนนัดหมาย"
        },
        "keys": {
          "type": "value",
          "value": "doctor_name,dateText,start_time,end_time,chair,zone,appointmentCount"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "pageSize": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        },
        "widths": {
          "type": "value",
          "value": ",100,80,80,,,120"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "!props.time"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectDSB"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", paddingRight: \"5px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Dropdown",
      "parent": 5,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onTimeChange"
        },
        "options": {
          "type": "code",
          "value": "props.timeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.time"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุเวลานัดหมาย"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 12,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModConsultDoctorListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "drugSearch": "[Drug Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
