import React, { useEffect, useMemo, useState } from "react";
import { Icon, Input, Popup } from "semantic-ui-react";

// UI
import CardReAssessmentPainUX from "./CardReAssessmentPainUX";

// Common
import TimeFreeTextBox24 from "react-lib/apps/common/TimeFreeTextBox24";

// Utils
import moment from "moment";

// Interface
import { characteristicOptions } from "./sequence/ReAssessment";
import { useIntl } from "react-intl";

type CardReAssessmentPainProps = {
  // function
  onEvent?: any;
  setProp?: any;
  onChangeData?: any;
  onChangeDatePain: any;
  onChangeTimePain: any;
  onChangeMedicationGiveTime: any;
  onChangeScoreAfterInterventionTime: any;
  // seq
  runSequence: any;
  ReAssessmentSequence?: any;
  // options
  masterOptions?: any;
  reAssessmentOptions?: any;
  // common
  buttonLoadCheck?: any;
  buttonSavePain?: any;
};

// Const
const PAIN_SCORE_AFTER_INTERVENTION = ["IV min", "IM 30 min", "Oral / non med 60 min"];

const CardReAssessmentPain = (props: CardReAssessmentPainProps) => {
  const intl = useIntl();
  // console.log("Yeti Pain Assessment Props: ", props);

  const timeToDay = moment().format("HH:mm");

  // combine Characteristic
  const combinedCharactOptions = [
    ...(props.masterOptions?.characteristic || []),
    ...characteristicOptions,
  ];

  // useEffect
  useEffect(() => {
    if (props.ReAssessmentSequence?.painQuestion?.painInformedPhysician === "No") {
      props.setProp(`ReAssessmentSequence.painQuestion.painInformedPhysicianNote`, null);
    }
  }, [props.ReAssessmentSequence?.painQuestion?.painInformedPhysician]);

  useEffect(() => {
    if (props.ReAssessmentSequence?.painQuestion?.painMedicationGive === "No") {
      props.setProp(`ReAssessmentSequence.painQuestion.painMedicationGiveNote`, null);
      props.setProp(`ReAssessmentSequence.painQuestion.painMedicationGiveTime`, null);
    } else if (props.ReAssessmentSequence?.painQuestion?.painMedicationGive === "Yes") {
      props.setProp(`ReAssessmentSequence.painQuestion.painMedicationGiveTime`, timeToDay);
    }
  }, [props.ReAssessmentSequence?.painQuestion?.painMedicationGive]);

  useEffect(() => {
    if (PAIN_SCORE_AFTER_INTERVENTION.includes(props.ReAssessmentSequence?.painQuestion?.painScoreAfterIntervention)) {
      props.setProp(`ReAssessmentSequence.painQuestion.painScoreAfterInterventionTime`, timeToDay);
    }
  }, [props.ReAssessmentSequence?.painQuestion?.painScoreAfterIntervention])

  useEffect(() => {
    if (props.ReAssessmentSequence?.painQuestion?.painCharacteristic !== "Other") {
      props.setProp(`ReAssessmentSequence.painQuestion.painCharacteristicNote`, null);
    }
  }, [props.ReAssessmentSequence?.painQuestion?.painCharacteristic]);

  useEffect(() => {
    if (props.ReAssessmentSequence?.painQuestion?.painSideEffect !== "Other") {
      props.setProp(`ReAssessmentSequence.painQuestion.painSideEffectNote`, null);
    }
  }, [props.ReAssessmentSequence?.painQuestion?.painSideEffect]);

  // useMemo
  const informedCheck = useMemo(() => {
    return props.ReAssessmentSequence?.painQuestion?.painInformedPhysician === "Yes" ? "Yes" : "No";
  }, [props.ReAssessmentSequence]);

  const medicationCheck = useMemo(() => {
    return props.ReAssessmentSequence?.painQuestion?.painMedicationGive === "Yes" ? "Yes" : "No";
  }, [props.ReAssessmentSequence]);

  const painScoreAfterInterventionCheck = useMemo(() => {
    return PAIN_SCORE_AFTER_INTERVENTION.includes(props.ReAssessmentSequence?.painQuestion?.painScoreAfterIntervention);
  }, [props.ReAssessmentSequence])

  const otherSideEffectCheck = useMemo(() => {
    return props.ReAssessmentSequence?.painQuestion?.painSideEffect === "Other";
  }, [props.ReAssessmentSequence]);

  const otherCharacteristicCheck = useMemo(() => {
    return props.ReAssessmentSequence?.painQuestion?.painCharacteristic === "Other";
  }, [props.ReAssessmentSequence]);

  // Component
  const isInformedOptions = (opt: "Yes" | "No" | null) => {
    if (opt === "Yes") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ color: "red", width: "1rem" }}>*</label>
          <Input
            value={props.ReAssessmentSequence?.painQuestion?.painInformedPhysicianNote}
            onChange={props.onChangeData}
            name="painQuestion.painInformedPhysicianNote"
          />
        </div>
      );
    }

    return <></>;
  };

  const isMedicationGive = (opt: "Yes" | "No" | null) => {
    if (opt === "Yes") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            value={props.ReAssessmentSequence?.painQuestion?.painMedicationGiveNote}
            onChange={props.onChangeData}
            name="painQuestion.painMedicationGiveNote"
            placeholder="Name of Medication,Dose &Dose & Route"
            style={{ width: "22rem" }}
          />
          <label style={{ width: "3rem", whiteSpace: "normal", marginLeft: "3rem" }}>Time:</label>
          <TimeFreeTextBox24
            value={props.ReAssessmentSequence?.painQuestion?.painMedicationGiveTime}
            onChange={props.onChangeMedicationGiveTime}
            autoFocus={false}
          />
        </div>
      );
    }

    return <></>;
  };

  const sedatScoreTooltip = () => {
    return (
      <Popup
        trigger={<Icon name="info circle" size="large" color="blue" />}
        content={
          <div>
            {"Sedation score :"}
            <br />
            {"*record before and after if use Opioids only"}
            <br />
            {"O = Awake"}
            <br />
            {"1 = Call to Awake"}
            <br />
            {"2 = Touch to awake"}
            <br />
            {"3 = No response"}
            <br />
          </div>
        }
        size="small"
        position="bottom left"
      />
    );
  };

  const isPainScoreAfterIntervention = (value: any) => {
    if (value) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ color: "red", width: "1rem" }}>*</label>
          <Input
            value={props.ReAssessmentSequence?.painQuestion?.painScoreAfterInterventionScore}
            onChange={props.onChangeData}
            name="painQuestion.painScoreAfterInterventionScore"
            style={{width: "6rem"}}
          />
          <label style={{ width: "3rem", whiteSpace: "normal", marginLeft: "3rem" }}>Time:</label>
          <TimeFreeTextBox24
            value={props.ReAssessmentSequence?.painQuestion?.painScoreAfterInterventionTime}
            onChange={props.onChangeScoreAfterInterventionTime}
            autoFocus={false}
          />
        </div>
      )
    }

    return <></>;
  };

  const isOtherCharacteristic = (isOther: boolean) => {
    if (isOther) {
      return (
        <Input
          value={props.ReAssessmentSequence?.painQuestion?.painCharacteristicNote}
          onChange={props.onChangeData}
          name="painQuestion.painCharacteristicNote"
          style={{ width: "22rem", marginLeft: "2rem" }}
        />
      )
    }
  };

  const isOtherSideEffect = (isOther: boolean) => {
    if (isOther) {
      return (
        <Input
          value={props.ReAssessmentSequence?.painQuestion?.painSideEffectNote}
          onChange={props.onChangeData}
          name="painQuestion.painSideEffectNote"
          style={{ width: "22rem", marginLeft: "2rem" }}
        />
      )
    }

    return <></>;
  }

  return (
    <CardReAssessmentPainUX
      // seq
      runSequence={props.runSequence}
      ReAssessmentSequence={props.ReAssessmentSequence}
      // options
      masterOptions={props.masterOptions}
      reAssessmentOptions={props.reAssessmentOptions}
      // onChange
      onChangeData={props.onChangeData}
      onChangeDatePain={props.onChangeDatePain}
      onChangeTimePain={props.onChangeTimePain}
      // Addition
      isInformedOptions={isInformedOptions(informedCheck)}
      isMedicationGive={isMedicationGive(medicationCheck)}
      isPainScoreAfterIntervention={isPainScoreAfterIntervention(painScoreAfterInterventionCheck)}
      isOtherCharacteristic={isOtherCharacteristic(otherCharacteristicCheck)}
      isOtherSideEffect={isOtherSideEffect(otherSideEffectCheck)}
      onSedatScoreTooltip={sedatScoreTooltip()}
      characteristicOptions={combinedCharactOptions}
      // button
      buttonSavePain={props.buttonSavePain}
      languageUX={props.languageUX}
    />
  );
};

export default CardReAssessmentPain;
