import React from "react";
import PropTypes from "prop-types";
import CardLayout from "react-lib/apps/common/CardLayout";
import PureReactTable from "../common/PureReactTable";
import { useIntl } from "react-intl";

//* This card do not have controller
const SubADR = React.forwardRef((props: any, ref) => {
  const [allergy, setAllergy] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    getAdverseReaction();
  }, [props.patientData.patient_id]);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getAdverseReaction = async () => {
    let params: any = {
      patientId: props.patientData.patient_id,
      excludeUnused: true,
      severeFirst: true
    };
    if (props.approvedOnly) {
      params.approveOnly = true;
      params.shouldNotKnown = true;
    } else {
      params.notApproveOnly = true;
    }
    setIsLoading(true);
    const [data, error] = await props.controller.getAdverseReactionList({
      ...params
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        if (data.items.length === 0) {
          props.warningLevelResolved(0);
        } else if (data.items.length === 1 && data.items[0].id === null) {
          props.warningLevelResolved(1);
        } else {
          props.warningLevelResolved(2);
        }
        setAllergy(data.items);
      }
    }
  };

  return (
    <CardLayout
      titleText="ADR information"
      toggleable={props.toggleable}
      closeable={props.closeable}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={isLoading}
    >
      <PureReactTable
        data={allergy}
        style={styles.adrTable}
        showPagination={false}
        minRows={0}
        columns={[
          {
            Header: "ความรุนแรง",
            accessor: "severity_name",
            minWidth: 50
          },
          {
            Header: "type",
            accessor: "type_name",
            minWidth: 50
          },
          {
            Header: "ชื่อ",
            accessor: "name",
            minWidth: 50,
            Cell: (row: any) => {
              let text = row.original.note;
              if (row.original.name) {
                text = row.original.name;
              }
              return <div style={styles.adrName}>{text}</div>;
            }
          },
          {
            Header: "note",
            accessor: "note",
            minWidth: 50
          }
        ]}
      />
    </CardLayout>
  );
});

const styles = {
  adrTable: {
    height: "200px"
  },
  adrName: {
    color: "red"
  }
};

SubADR.defaultProps = {
  patientData: {},
  controller: {},
  approvedOnly: true,
  toggleable: false,
  closeable: false,
  hideHeaderIcon: true,
  warningLevelResolved: () => {}
};

SubADR.propTypes = {
  controller: PropTypes.object,
  patientData: PropTypes.object,
  approvedOnly: PropTypes.bool,
  toggleable: PropTypes.bool,
  closeable: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  warningLevelResolved: PropTypes.func
};

export default React.memo(SubADR);
