import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Dropdown, Form, Input } from "semantic-ui-react";
import { useIntl } from "react-intl";

export interface CardSensoryViewDataType {
  dropDownOptions: {
    sensoryOptions: any[];
  };
  data: {
    choice: number;
    remark: string;
  };
  readonly: boolean;
}

const CardSensory: FC<CardSensoryViewDataType> = forwardRef((props, ref) => {
  // Sensory
  const [sensory, setSensory] = useState<any>(sensoryDefaultData);
  
  useEffect(() => {
    setSensory(props.data);
  }, [props.data]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return sensory;
    },
  }));

  const handleChangeSensory = (event, data) => {
    if (!props.readonly) {
      setSensory({ ...sensory, [data.name]: data.value });
    }
  };

  return (
    <Form>
      <Form.Group inline>
        <Form.Field width={4}>
          <Dropdown
            className="fluidDropdown"
            selection
            name="choice"
            value={sensory.choice}
            options={props.dropDownOptions.sensoryOptions || []}
            onChange={handleChangeSensory}
          />
        </Form.Field>
        <Form.Field width={10}>
          <Input
            value={sensory.remark}
            name="remark"
            onChange={handleChangeSensory}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
});

const sensoryDefaultData = {
  choice: null,
  remark: "",
};

export const CardSensoryInitialViewData: CardSensoryViewDataType = {
  dropDownOptions: { sensoryOptions: [] },
  data: sensoryDefaultData,
  readonly: false,
};

CardSensory.defaultProps = CardSensoryInitialViewData;

export default CardSensory;
