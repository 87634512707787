import React, {
  CSSProperties,
  ChangeEvent,
  MutableRefObject,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  SyntheticEvent,
} from "react";
import { Menu } from "semantic-ui-react";

import moment from "moment";

// UX
import CardImportLabValuesCalcDoctorFeeUX from "./CardImportLabValuesCalcDoctorFeeUX";
import TableUploadExcel from "./TableUploadExcel";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ModInfo from "react-lib/apps/common/ModInfo";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { useIntl } from "react-intl";

// Interface
import {
  ACTIONS,
  BTN_ACTS,
  CARD_IMPORT_LAB_VALUES_CALC_DF,
  DetailType,
  TABLE_HEADERS,
  PickedProps,
  RunSequence,
  SetProp,
  State,
  TAB_KEYS,
  TAB_MENUS,
  TabMenuKeys,
} from "./sequence/ImportLabValuesCalcDF";

// Types
type CardImportLabValuesCalcDoctorFeeProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  ImportLabValuesCalcDFSequence: State["ImportLabValuesCalcDFSequence"];
} & PickedProps;

const styles = {
  badgeCount: {
    height: "100%",
    padding: "0.4rem",
    margin: "0 -1.05rem 0 1rem",
    fontSize: "1.55rem",
    display: "flex",
    alignItems: "center",
    color: "white",
    fontWeight: "bold",
  } as CSSProperties,
  downloadBtn: {
    position: "absolute",
    right: "1rem",
    top: 0,
  } as CSSProperties,
};

const CURRENT_YEAR = Number(moment().format("YYYY")) + 543;

const CardImportLabValuesCalcDoctorFee = (props: CardImportLabValuesCalcDoctorFeeProps) => {
  const intl = useIntl();
  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "ImportLabValuesCalcDF", restart: true });
  }, []);

  // Callback
  // Use Callback
  const handleSelectedItem = useCallback(
    (value: any, key: any) => {
      handleChangeValue(null, { name: "doctorId", value: value || null });
    },
    [props.searchedItemListWithKey]
  );

  // Use Memo
  const yearOptions = useMemo(() => {
    const years = Array(111)
      .fill("")
      .map((_, index) => CURRENT_YEAR - 100 + index);

    return years.map((year) => ({ key: year, value: year, text: year }));
  }, []);

  const monthOptions = useMemo(() => {
    const months = Array.from({ length: 12 }, (_, i) => {
      return moment(`1997-${i + 1}-1`)
        .locale("th")
        .format("MMMM");
    });

    return months.map((month, index) => ({ key: month, value: index + 1, text: month }));
  }, []);

  const selectedLotNo = useMemo(() => {
    const id = props.ImportLabValuesCalcDFSequence?.dfPayrollGroupId;
    const options = props.ImportLabValuesCalcDFSequence?.dfPayrollGroupOptions || [];

    return options.find((option) => option.value === id)?.original || null;
  }, [
    props.ImportLabValuesCalcDFSequence?.dfPayrollGroupOptions,
    props.ImportLabValuesCalcDFSequence?.dfPayrollGroupId,
  ]);

  const fiscalYearId = useMemo(() => {
    return selectedLotNo ? +moment(selectedLotNo.started_at).format("YYYY") + 543 : "";
  }, [selectedLotNo]);

  const monthId = useMemo(() => {
    return selectedLotNo ? +moment(selectedLotNo.started_at).format("M") : "";
  }, [selectedLotNo]);

  const activeTab = useMemo(() => {
    return props.ImportLabValuesCalcDFSequence?.activeTab;
  }, [props.ImportLabValuesCalcDFSequence?.activeTab]);

  // Handler
  const handleChangeTab = (tab: TabMenuKeys) => () => {
    props.setProp("ImportLabValuesCalcDFSequence.activeTab", tab);
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0] || null;

    if (!file) {
      return console.warn("errorMessage", "Please select a file.");
    }

    props.runSequence({ sequence: "ImportLabValuesCalcDF", action: ACTIONS.CHANGE_FILE, file });

    fileRef.current.value = "";
  };

  const handleChooseFile = () => {
    fileRef.current.click();
  };

  const handleClearFile = () => {
    props.runSequence({ sequence: "ImportLabValuesCalcDF", action: ACTIONS.CLEAR_FILE });
  };

  const handleChangeValue = (
    e: SyntheticEvent | null,
    data: { value: any; name: keyof DetailType }
  ) => {
    const name = data.name;

    const detail = { ...props.ImportLabValuesCalcDFSequence };

    detail[name] = data.value;

    props.setProp(`ImportLabValuesCalcDFSequence`, { ...detail });
  };

  const handleUpload = () => {
    props.runSequence({
      sequence: "ImportLabValuesCalcDF",
      action: ACTIONS.UPLOAD,
      card: CARD_IMPORT_LAB_VALUES_CALC_DF,
    });
  };

  const handleDownload = () => {
    props.runSequence({
      sequence: "ImportLabValuesCalcDF",
      action: ACTIONS.DOWNLOAD_TEMPLATE,
      card: CARD_IMPORT_LAB_VALUES_CALC_DF,
    });
  };

  console.log("CardImportLabValuesCalcDoctorFee", props);

  return (
    <div style={{ width: "calc(100vw - 150px)" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_IMPORT_LAB_VALUES_CALC_DF}`, null);
        }}
        error={props.errorMessage?.[CARD_IMPORT_LAB_VALUES_CALC_DF]}
        success={null}
        languageUX={props.languageUX}
      />

      <CardImportLabValuesCalcDoctorFeeUX
        // data
        activeItem={activeTab}
        fileName={props.ImportLabValuesCalcDFSequence?.excelFile?.name}
        fiscalYearId={fiscalYearId}
        monthId={monthId}
        dfPayrollGroupId={props.ImportLabValuesCalcDFSequence?.dfPayrollGroupId}
        selectedLotNo={selectedLotNo}
        // options
        yearOptions={yearOptions}
        monthOptions={monthOptions}
        dfPayrollGroupOptions={props.ImportLabValuesCalcDFSequence?.dfPayrollGroupOptions}
        // callback
        onChooseFile={handleChooseFile}
        onClearFile={handleClearFile}
        onChangeValue={handleChangeValue}
        onUpload={handleUpload}
        // Element
        buttonDownload={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleDownload}
            // data
            paramKey={BTN_ACTS.DOWNLOAD_TEMPLATE}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DOWNLOAD_TEMPLATE]}
            // config
            color={"blue"}
            name={ACTIONS.DOWNLOAD_TEMPLATE}
            size="medium"
            iconRight="download"
            title="Download Template"
            style={styles.downloadBtn}
          />
        }
        doctorSearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Doctor"
            id="ILVCDF"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            inline={true}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.ImportLabValuesCalcDFSequence?.doctorId || null}
            setSelectedItem={handleSelectedItem}
          />
        }
        tabPans={
          <Menu tabular style={{ height: "40px", marginBottom: "1rem" }}>
            {Object.entries(TAB_MENUS).map(([key, menu], index) => (
              <Menu.Item
                key={"menu-" + menu}
                active={activeTab === key}
                style={{
                  ...(activeTab === key && {
                    color: "white",
                    backgroundColor: "#2185D0",
                  }),
                  padding: "0 1.5em",
                }}
                onClick={handleChangeTab(key as TabMenuKeys)}
              >
                {menu.title}{" "}
                {menu.bg && !!props.ImportLabValuesCalcDFSequence?.[menu.key]?.length && (
                  <div
                    style={{
                      backgroundColor: menu.bg,
                      ...styles.badgeCount,
                    }}
                  >
                    {props.ImportLabValuesCalcDFSequence?.[menu.key]?.length}
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu>
        }
        languageUX={props.languageUX}
      />

      <input ref={fileRef} type="file" accept=".xlsx" onChange={handleChangeFile} hidden />

      <div style={{ margin: "-1rem 1rem 0" }}>
        <TableUploadExcel
          data={
            activeTab ? props.ImportLabValuesCalcDFSequence?.[TAB_MENUS[activeTab].key] || [] : []
          }
          headers={TABLE_HEADERS as any}
          displayError={activeTab === TAB_KEYS.FAIL}
          style={{
            height:
              activeTab === TAB_KEYS.UPLOAD ? "calc(100dvh - 20rem)" : "calc(100dvh - 14.5rem)",
          }}
        />
      </div>

      <ModInfo
        open={props.buttonLoadCheck?.[BTN_ACTS.UPLOAD]}
        titleColor={"red"}
        style={{ minWidth: "37rem" }}
        buttonStyle={{ display: "none" }}
      >
        <div style={{ padding: "2rem 0", fontWeight: "bold", lineHeight: 2 }}>
          <div>{intl.formatMessage({ id: "กรุณารอสักครู่ระบบกำลังอัพโหลดข้อมูล" })}</div>
          <div>{intl.formatMessage({ id: "ห้ามปิดหน้าต่างจนกว่าระบบจะประมวลผลเสร็จสิ้น" })}</div>
        </div>
      </ModInfo>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      TableCustom;                      */

/* ------------------------------------------------------ */
// Types

CardImportLabValuesCalcDoctorFee.displayName = "CardImportLabValuesCalcDoctorFee";

export default React.memo(CardImportLabValuesCalcDoctorFee);
