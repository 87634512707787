import React, { useEffect, useState, useCallback } from "react";
import { Checkbox, Popup, Image, Icon } from "semantic-ui-react";

import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-lib/css/ReactTableFixedColumns.css";
import ReactTable from "react-table-6";

// UX
import RefillDivisionPrintUX from "./RefillDivisionPrintUX";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const ExpandingTable = (props: any) => {
  // #const [scroll, setScroll] = useState<boolean>(
  //   props.data && props.data.length && props.data.length > 20 ? true : false
  // );

  // #useEffect(() => {
  //   if (props.data && props.data.length && props.data.length > 20) {
  //     setScroll(true);
  //   } else {
  //     setScroll(false);
  //   }
  // }, [props.data]);

  return (
    <ReactTableFixedColumns
      // @ts-ignore
      data={props.data}
      columns={props.columns}
      expanded={props.selectItemExpand}
      expanderDefaults={{
        sortable: false,
        resizable: true,
        filterable: false,
      }}
      onExpandedChange={(expanded: any, index: number, event: any) => {
        console.log("onExpandedChange expanded", expanded);
        console.log("onExpandedChange index", index);
        console.log("onExpandedChange event", event);
        // Find Expand
        // let parentCount = props.data && props.data.length ? props.data.length : 0;
        // let childCount = 0;
        // // Adjust PageSize and setHeight
        // Object.keys(expanded).forEach(function (key) {
        //   if (expanded[key]) {
        //     childCount += props.data[key].children.length;
        //   }
        // });
        // if (parentCount + childCount > 20) {
        //   setScroll(true);
        // } else {
        //   setScroll(false);
        // }

        props.setSelectItemExpand(expanded);
      }}
      // pageSize={props.data && props.data.length ? props.data.length : 20}
      // style={scroll ? { height: "500px" } : null}
      style={{ maxHeight: "calc(100dvh - 19rem)" }}
      subRowsKey={"children"}
      showPagination={false}
      getNoDataProps={() => {
        return { style: { display: "none" } };
      }}
      minRows={1}
    />
  );
};

type ModInfoType = {
  open: boolean;
  error?: any;
  title?: string;
  color?: string;
  btnText?: string;
};

const modInfoInitial: ModInfoType = {
  open: false,
  error: null,
  title: "",
  color: undefined,
  btnText: "",
};
const CARD_REFILL_DIVISION_PRINT = "CardRefillDivisionPrint";

const CardRefillDivisionPrint = (props: any) => {
  const intl = useIntl();
  const [selectItemExpand, setSelectItemExpand] = useState<any>({});
  const [divisionPrintItems, setDivisionPrintItems] = useState<any>([]);
  const [total, setTotal] = useState<string>("0.0");
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial);

  useEffect(() => {
    console.log("RefillDivisionPrint restart");
    props.runSequence({ sequence: "RefillDivisionPrint", restart: true });
  }, []);

  const checkedItemByRef = (item: any) => {
    item.chk = true;
    if ("parent_idx" in item) {
      item["price_total"] = (item["quantity_issue"] * item["price_unit"]).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    item.children?.forEach((it: any) => {
      it["chk"] = true;
      it["price_total"] = (it["quantity_issue"] * it["price_unit"]).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    });
  };

  const unCheckedItemByRef = (item: any) => {
    item.chk = false;
    item["price_total"] = "";
    if ("parent_idx" in item) {
      divisionPrintItems[item["parent_idx"]].chk = false;
    }
    item.children?.forEach((it: any) => {
      it["chk"] = false;
      it["price_total"] = "";
    });
  };

  const checkboxSelected = useCallback(
    (item: any) => (data: any, props: any) => {
      console.log("item", item);
      console.log("data", data);
      if (props.checked) {
        checkedItemByRef(item);

        let tmp = [...divisionPrintItems];
        setDivisionPrintItems(tmp);
      } else {
        unCheckedItemByRef(item);

        let tmp = [...divisionPrintItems];
        setDivisionPrintItems(tmp);
      }
    },
    [divisionPrintItems]
  );

  const tableColumns = [
    {
      id: "checkbox",
      accessor: "",
      Cell: ({ original }: any) => {
        console.log("original ", original);
        return <Checkbox checked={original.chk} onClick={checkboxSelected(original)} />;
      },
      Header: "",
      sortable: false,
      width: 30,

      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        margin: 0,
      },
      resizable: false,
    },
    {
      Header: "ชื่อหน่วยบริการ ชื่อเวชภัณฑ์",
      // width: 300,
      resizable: false,
      expander: true,
      style: { whiteSpace: "unset" }, // WordWrap

      Expander: ({ isExpanded, ...rest }: any) => {
        // test your condition for Sub-Component here
        // I am using the presence of no comments
        if (!rest.original.hasOwnProperty("children")) {
          return (
            <div>
              &nbsp;&nbsp;&nbsp;{rest.original.name}
              <div style={{ display: "inline-block", float: "right" }}>
                {rest.original.images?.length > 0 ? (
                  <Popup
                    trigger={<Icon name="picture" color={"black"} style={{ margin: 0 }} />}
                    content={
                      <Image
                        src={rest.original.images?.length > 0 ? rest.original?.images[0] : ""}
                        size="small"
                      />
                    }
                    inverted
                  />
                ) : (
                  <Icon name="picture" disabled />
                )}
              </div>
            </div>
          );
        } else if (
          rest.original.hasOwnProperty("children") &&
          rest.original.children.length === 0
        ) {
          return <div>{rest.original.name}</div>;
        } else {
          return (
            <div>
              {isExpanded ? (
                <>
                  <span
                    style={{
                      display: "inline-block",
                      transform: "rotate(90deg)",
                    }}
                  >
                    &#10148;
                  </span>
                  <span> {rest.original.name} </span>
                </>
              ) : (
                <span>&#10148; {rest.original.name} </span>
              )}
            </div>
          );
        }
      },
      getProps: (state: any, rowInfo: any, column: any) => {
        if (rowInfo) {
          // same test as above

          if (
            !rowInfo.original.children ||
            (rowInfo.original.children && rowInfo.original.children.length === 0)
          ) {
            // hijack the onClick so it doesn't open

            return { onClick: () => {} };
          }
        }

        return { className: "show-pointer" };
      },
    },
    {
      Header: "จำนวนสั่ง",
      accessor: "quantity_request",
      width: 100,
    },
    {
      Header: "จำนวนจ่าย",
      accessor: "quantity_issue",
      width: 100,
    },
    {
      Header: "มูลค่าต่อหน่วย",
      accessor: "price_unit",
      width: 100,
    },
    {
      Header: "มูลค่ารวม",
      accessor: "price_total",
      width: 100,
    },
  ];

  // const divisionPrintItems = useMemo(
  //   () =>
  //     props.RefillDivisionSequence.divisionPrintItems?.map(
  //       (item: any, idx: any) => {
  //         return {
  //           ...item,
  //           ...item.children.map((child: any, idx: any) => {
  //             return { ...child, chk: true };
  //           }),
  //           chk: true,
  //         };
  //       }
  //     ),
  //   [props.RefillDivisionSequence.divisionPrintItems]
  // );

  useEffect(() => {
    let temp: any[] = [];
    props.RefillDivisionSequence?.divisionPrintItems?.forEach((it: any, parent_idx: any) => {
      it["chk"] = false;
      it["children"] = it.children.map((child: any, idx: any) => {
        return {
          ...child,
          chk: false,
          parent_idx: parent_idx,
          price_unit: child.price_unit?.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      });
      temp.push(it);
    });

    setDivisionPrintItems(temp);
  }, [props.RefillDivisionSequence, props.RefillDivisionSequence?.divisionPrintItems]);

  useEffect(() => {
    let tmpTotal = 0.0;
    divisionPrintItems.forEach((it: any) => {
      it.children.forEach((child: any, idx: any) => {
        if (typeof child["price_total"] === "string") {
          tmpTotal += Number(child["price_total"].replace(/,/g, ""));
        } else if (typeof child["price_total"] === "number") {
          tmpTotal += child["price_total"];
        }
      });
    });
    setTotal(
      tmpTotal.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }, [divisionPrintItems]);

  useEffect(() => {
    if (
      props.successMessage?.[CARD_REFILL_DIVISION_PRINT] &&
      props.RefillDivisionSequence?.openModInfo
    ) {
      setOpenModInfo({
        open: true,
        title: props.RefillDivisionSequence?.titlePopup,
        color: "green",
        btnText: "ปิด",
      });
    }
  }, [props.successMessage?.[CARD_REFILL_DIVISION_PRINT]]);

  const handleSave = () => {
    const result = divisionPrintItems.map((item: any) => {
      if (item.chk) {
        return item;
      } else {
        const chkChild = item.children.filter((child: any) => child.chk);
        return { ...item, children: chkChild };
      }
    });

    props.runSequence({
      sequence: "RefillDivisionPrint",
      action: "save",
      card: CARD_REFILL_DIVISION_PRINT,
      items: divisionPrintItems,
    });
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial);
    props.setProp("RefillDivisionSequence", {
      ...props.RefillDivisionSequence,
      openModInfo: false,
      titlePopup: "",
    });
  };

  const handleSelectAll = () => {
    let tmp = [...divisionPrintItems].map((item) => {
      checkedItemByRef(item);
      return item;
    });
    setDivisionPrintItems(tmp);
  };

  const handleUnSelectAll = () => {
    let tmp = [...divisionPrintItems].map((item) => {
      unCheckedItemByRef(item);
      return item;
    });
    setDivisionPrintItems(tmp);
  };

  return (
    <div>
      <RefillDivisionPrintUX
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey="RefillDivisionPrint_SAVE"
            buttonLoadCheck={props.buttonLoadCheck?.RefillDivisionPrint_SAVE}
            // config
            color="green"
            size="medium"
            title={intl.formatMessage({ id: "พิมพ์รายการที่เลือก" })}
          />
        }
        total={total}
        selectAll={handleSelectAll}
        unSelectAll={handleUnSelectAll}
        container={
          <div style={{ width: "100%" }}>
            <ExpandingTable
              data={divisionPrintItems}
              columns={tableColumns}
              selectItemExpand={selectItemExpand}
              setSelectItemExpand={setSelectItemExpand}
            />
          </div>
        }
        ErrorMessage={<ErrorMessage error={props?.errorMessage?.["RefillDivisionPrint"]} />}
      />

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color}
        titleName={openModInfo.title}
        btnText={openModInfo.btnText}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      ></ModInfo>
    </div>
  );
};

export default React.memo(CardRefillDivisionPrint);
