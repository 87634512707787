import { formatDropdown } from "react-lib/utils";
export default class DoctorSearchBoxController {
  constructor(props) {
    this.coreManager = props.coreManager;
  }

  getSpeciality = async () => {
    const [
      specialityData,
      specialityError,
      network,
    ] = await this.coreManager.getSpeciality();
    return [specialityData, specialityError, network];
  };

  getDoctorList = async (params) => {
    const [
      doctorListData,
      doctorListError,
      numberOfPage,
    ] = await this.coreManager.getDoctorList(params);
    return [doctorListData, doctorListError, numberOfPage];
  };

  choicesSpecialty = async () => {
    var choices = [];
    const [data, error] = await this.coreManager.getSpecialty();
    if (error) {
      return choices;
    }
    let list = formatDropdown(data, "name", "id");
    choices = list.map((item) => {
      delete item.is_active;
      delete item.display_seq;
      return item;
    });
    return choices;
  };

  searchDoctor = async ({ nameCode, specialty, doctorCurrentPage }) => {
    let limit = 40;
    let params = {
      nameCode,
      specialty,
      offset: (doctorCurrentPage - 1) * limit,
      limit,
    };
    const [data, error, numberOfPage] = await this.coreManager.getDoctorList(
      params
    );
    return [data, error, numberOfPage];
  };
}
