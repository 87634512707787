import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ModLotNoExpUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          style={{backgroundColor: "var(--primary-theme-color,#5DBCD2)",margin: "-0.75rem -0.75rem -1rem", padding: "1rem", display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0", color: "white"}}>
            Lot No./Exp.
          </div>
          <div
            style={{cursor: "pointer", color: "white", marginTop: "2.5px"}}>
            
            <Icon
              className="white"
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{marginLeft: "0.85rem",color: "rgba(0,0,0,0.87)"}}>
                {props.name || "-"}
              </label>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            NoDataComponent={()=> null}
            data={props.lotList|| []}
            getTrProps={props.getTrProps}
            headers="Lot No.,Exp.,Onh. Qty, Disp. Qty,Unit"
            keys="lot_no,exp,onh_qty,disp_qty,unit"
            minRows={1}
            showPagination={false}
            style={{maxHeight: "300px", marginBottom: "1rem"}}>
          </Table>
        </div>
        <div
          style={{margin: "1rem 0 0.5rem", display: "flex", justifyContent: "flex-end"}}>
          
          <div>
            {props.ButtonSave}
          </div>
        </div>
      </div>
    )
}

export default React.memo(ModLotNoExpUX)

export const screenPropsDefault = {}

/* Date Time : Mon Feb 20 2023 04:44:19 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"var(--primary-theme-color,#5DBCD2)\",margin: \"-0.75rem -0.75rem -1rem\", padding: \"1rem\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Lot No./Exp."
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\", color: \"white\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", color: \"white\", marginTop: \"2.5px\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Icon",
      "parent": 122,
      "props": {
        "className": {
          "type": "value",
          "value": "white"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "code",
          "value": "props.name || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.85rem\",color: \"rgba(0,0,0,0.87)\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 137,
      "name": "Table",
      "parent": 136,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "()=> null"
        },
        "data": {
          "type": "code",
          "value": "props.lotList|| []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "Lot No.,Exp.,Onh. Qty, Disp. Qty,Unit"
        },
        "keys": {
          "type": "value",
          "value": "lot_no,exp,onh_qty,disp_qty,unit"
        },
        "minRows": {
          "type": "code",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"300px\", marginBottom: \"1rem\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"1rem 0 0.5rem\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 139,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "ModLotNoExpUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
