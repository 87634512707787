import '../../css/SummaryPrice.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

class SummaryFormGroup extends React.PureComponent {
  static propTypes = {
    totalRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalSentClaim: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPaid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalOtherPay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPriceBillItemMode_2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalSentClaimPriceBillItemMode_2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPriceBillItemMode_3_5: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalSentClaimPriceBillItemMode_3_5: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPriceBillItemModeRemain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalSentClaimPriceBillItemModeRemain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPriceDispensing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalSentClaimPriceDispensing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPriceOperationService: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  render() {
    const {
      totalRows,
      totalPrice,
      totalSentClaim,
      totalPaid,
      totalOtherPay,
      totalPriceBillItemMode_2,
      totalSentClaimPriceBillItemMode_2,
      totalPriceBillItemMode_3_5,
      totalSentClaimPriceBillItemMode_3_5,
      totalPriceBillItemModeRemain,
      totalSentClaimPriceBillItemModeRemain,
      totalPriceDispensing,
      totalSentClaimPriceDispensing,
      totalPriceOperationService,
      isCalulating,
    } = this.props;

    return (
      <div>
        <Form.Group inline className='summary'>
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='ยอดรวม'
            value={isCalulating ? 'Calculating...':totalPrice}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='ยอดหมวด 2'
            value={isCalulating ? 'Calculating...':totalPriceBillItemMode_2}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='ยอดหมวด 3-5'
            value={isCalulating ? 'Calculating...':totalPriceBillItemMode_3_5}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='ยอดหมวดที่เหลือ'
            value={isCalulating ? 'Calculating...':totalPriceBillItemModeRemain}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='ยอด Dispensing'
            value={isCalulating ? 'Calculating...':totalPriceDispensing}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='ยอด OPService'
            value={isCalulating ? 'Calculating...':totalPriceOperationService}
          />
        </Form.Group>
        <Form.Group inline className='summary'>
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='เบิกรวม'
            value={isCalulating ? 'Calculating...':totalSentClaim}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='เบิกหมวด 2'
            value={isCalulating ? 'Calculating...':totalSentClaimPriceBillItemMode_2}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='เบิกหมวด 3-5'
            value={isCalulating ? 'Calculating...':totalSentClaimPriceBillItemMode_3_5}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='เบิกหมวดที่เหลือ'
            value={isCalulating ? 'Calculating...':totalSentClaimPriceBillItemModeRemain}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='เบิก Dispensing'
            value={isCalulating ? 'Calculating...':totalSentClaimPriceDispensing}
          />
          <Form.Input
            className='summary__total-price-default'
            width={3}
            label='เบิก OPService'
            value={isCalulating ? 'Calculating...':totalSentClaimPriceBillItemModeRemain}
          />
        </Form.Group>
        <Form.Group inline className='summary'>
          <Form.Input
            className='summary__total-rows'
            width={3}
            label='จำนวนข้อมูล'
            value={isCalulating ? 'Calculating...':totalRows}
          />
  
          <Form.Input
            className='summary__total-paid'
            width={3}
            label='ยอดผู้ป่วยจ่าย'
            value={isCalulating ? 'Calculating...':totalPaid}
          />
          <Form.Input
            className='summary__total-other-pay'
            width={3}
            label='ยอดร่วมจ่าย'
            value={isCalulating ? 'Calculating...':totalOtherPay}
          />
        </Form.Group>
      </div>
    );
  }
}

export default SummaryFormGroup;
