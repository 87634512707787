export default class CardImagingExamResultController {
  
  constructor(imeManager) {
    this.imeManager = imeManager 
  }

  getImagingExamResult = async (params) => {
    let [data, error, network] = await this.imeManager.getImagingExamResult(params)
    return [data, error, network]
  }

}
