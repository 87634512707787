import React from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Input,
  Segment,
  TextArea,
} from "semantic-ui-react";

import RightLabel from "react-lib/apps/common/RightLabel";
import { useIntl } from "react-intl";

export interface OTProgressionNoteOPDDysfunctionViewDataType {
  OtProgressnoteOpdDysfunction4: string;
  OtProgressnoteOpdDysfunction4Disabled: boolean;
  OtProgressnoteOpdDysfunction6: string;
  OtProgressnoteOpdDysfunction6Disabled: boolean;
  OtProgressnoteOpdDysfunction9: string;
  OtProgressnoteOpdDysfunction9Disabled: boolean;
  OtProgressnoteOpdDysfunction11: string;
  OtProgressnoteOpdDysfunction11Disabled: boolean;
  OtProgressnoteOpdDysfunction12: boolean;
  OtProgressnoteOpdDysfunction12Disabled: boolean;
  OtProgressnoteOpdDysfunction14: boolean;
  OtProgressnoteOpdDysfunction14Disabled: boolean;
  OtProgressnoteOpdDysfunction19: string;
  OtProgressnoteOpdDysfunction19Disabled: boolean;
  CtDischargeToday: string;
  CtDischargeTodayDisabled: boolean;
  CtDischargeTodayOptions: any;
  OtProgressnoteOpdDysfunction26: string;
  OtProgressnoteOpdDysfunction26Disabled: boolean;
  OtProgressnoteOpdDysfunction28: boolean;
  OtProgressnoteOpdDysfunction28Disabled: boolean;
  OtProgressnoteOpdDysfunction36: boolean;
  OtProgressnoteOpdDysfunction36Disabled: boolean;
  OtProgressnoteOpdDysfunction40: string;
  OtProgressnoteOpdDysfunction40Disabled: boolean;
  OtProgressnoteOpdDysfunction42: string;
  OtProgressnoteOpdDysfunction42Disabled: boolean;
  OtProgressnoteOpdDysfunction44: string;
  OtProgressnoteOpdDysfunction44Disabled: boolean;
  OtProgressnoteOpdDysfunction51: string;
  OtProgressnoteOpdDysfunction51Disabled: boolean;
  OtProgressnoteOpdDysfunction52: string;
  OtProgressnoteOpdDysfunction52Disabled: boolean;
  SaveRequestDisabled: boolean;
}

export const OTProgressionNoteOPDDysfunctionInitialViewData: OTProgressionNoteOPDDysfunctionViewDataType = {
  OtProgressnoteOpdDysfunction4: "",
  OtProgressnoteOpdDysfunction4Disabled: false,
  OtProgressnoteOpdDysfunction6: "",
  OtProgressnoteOpdDysfunction6Disabled: false,
  OtProgressnoteOpdDysfunction9: "",
  OtProgressnoteOpdDysfunction9Disabled: false,
  OtProgressnoteOpdDysfunction11: "",
  OtProgressnoteOpdDysfunction11Disabled: false,
  OtProgressnoteOpdDysfunction12: false,
  OtProgressnoteOpdDysfunction12Disabled: false,
  OtProgressnoteOpdDysfunction14: false,
  OtProgressnoteOpdDysfunction14Disabled: false,
  OtProgressnoteOpdDysfunction19: "",
  OtProgressnoteOpdDysfunction19Disabled: false,
  CtDischargeToday: "",
  CtDischargeTodayDisabled: false,
  CtDischargeTodayOptions: [{ key: 0, value: "A", text: "A" }],
  OtProgressnoteOpdDysfunction26: "",
  OtProgressnoteOpdDysfunction26Disabled: false,
  OtProgressnoteOpdDysfunction28: false,
  OtProgressnoteOpdDysfunction28Disabled: false,
  OtProgressnoteOpdDysfunction36: false,
  OtProgressnoteOpdDysfunction36Disabled: false,
  OtProgressnoteOpdDysfunction40: "",
  OtProgressnoteOpdDysfunction40Disabled: false,
  OtProgressnoteOpdDysfunction42: "",
  OtProgressnoteOpdDysfunction42Disabled: false,
  OtProgressnoteOpdDysfunction44: "",
  OtProgressnoteOpdDysfunction44Disabled: false,
  OtProgressnoteOpdDysfunction51: "",
  OtProgressnoteOpdDysfunction51Disabled: false,
  OtProgressnoteOpdDysfunction52: "",
  OtProgressnoteOpdDysfunction52Disabled: false,
  SaveRequestDisabled: false,
};

const styles = {
  topBackground: {
    backgroundColor: "#F3F3F3",
  },
  bottomBackground: {
    backgroundColor: "#E0E0E0",
  },
  blueFont: {
    color: "#2F80ED",
  },
  maxWidth: {
    width: "100%",
  },
  buttonPadding: {
    padding: "20px 0px 0px",
  },
};

const Sub = (props: any) => {
  return (
    <>
      <Segment style={styles.topBackground}>
        <Form>
          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Problem/ Occupational Therapy diagnosis*</RightLabel>
            </Form.Field>
            <Form.Field width={13}>
              <Input
                value={props.viewData.OtProgressnoteOpdDysfunction4}
                disabled={props.viewData.OtProgressnoteOpdDysfunction4Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction4",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Goal of Treatment*</RightLabel>
            </Form.Field>
            <Form.Field width={13}>
              <Input
                value={props.viewData.OtProgressnoteOpdDysfunction6}
                disabled={props.viewData.OtProgressnoteOpdDysfunction6Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction6",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Treatment*</RightLabel>
            </Form.Field>
            <Form.Field width={13}>
              <TextArea
                value={props.viewData.OtProgressnoteOpdDysfunction9}
                disabled={props.viewData.OtProgressnoteOpdDysfunction9Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction9",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Plan</RightLabel>
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox
                label="continue program"
                checked={props.viewData.OtProgressnoteOpdDysfunction12}
                disabled={props.viewData.OtProgressnoteOpdDysfunction12Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction12",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox
                label="Other"
                checked={props.viewData.OtProgressnoteOpdDysfunction14}
                disabled={props.viewData.OtProgressnoteOpdDysfunction14Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction14",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={10}>
              <Input
                value={props.viewData.OtProgressnoteOpdDysfunction11}
                disabled={props.viewData.OtProgressnoteOpdDysfunction11Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction11",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Note</RightLabel>
            </Form.Field>
            <Form.Field width={13}>
              <TextArea
                rows={3}
                value={props.viewData.OtProgressnoteOpdDysfunction19}
                disabled={props.viewData.OtProgressnoteOpdDysfunction19Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction19",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Discharge status today</RightLabel>
            </Form.Field>
            <Form.Field width={13}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.CtDischargeToday}
                disabled={props.viewData.CtDischargeTodayDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "CtDischargeToday",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.CtDischargeTodayOptions}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Instruction*</RightLabel>
            </Form.Field>
            <Form.Field width={13}>
              <Input
                value={props.viewData.OtProgressnoteOpdDysfunction26}
                disabled={props.viewData.OtProgressnoteOpdDysfunction26Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction26",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel></RightLabel>
            </Form.Field>
            <Form.Field width={13}>
              <Checkbox
                label="Patient and/or family was/were informed about instruction and plan of treatment"
                checked={props.viewData.OtProgressnoteOpdDysfunction28}
                disabled={props.viewData.OtProgressnoteOpdDysfunction28Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction28",
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel></RightLabel>
            </Form.Field>
            <Form.Field width={13}>
              <Checkbox
                label="Need to be reviewed"
                checked={props.viewData.OtProgressnoteOpdDysfunction36}
                disabled={props.viewData.OtProgressnoteOpdDysfunction36Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "OtProgressnoteOpdDysfunction36",
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <Form>
          <Form.Group inline>
            <Form.Field width={14}></Form.Field>
            <Form.Field width={2} style={styles.buttonPadding}>
              <Button
                fluid
                type="button"
                color="green"
                disabled={props.viewData.SaveRequestDisabled}
                onClick={(e) => {
                  props.onEvent({
                    message: "clickButton",
                    params: {
                      view: "OTProgressionNoteOPDDysfunction",
                      name: "SaveRequest",
                    },
                  });
                }}
              >
                Save
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    </>
  );
};

export default Sub;
