import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
// ADR
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import AdverseReactionDetail from "issara-sdk/apis/AdverseReactionDetail_apps_ADR";
import MultiAdverseReactionList from "issara-sdk/apis/MultiAdverseReactionList_apps_ADR";
import AdverseReactionActionLogList from "issara-sdk/apis/AdverseReactionActionLogList_apps_ADR";
// TPD
import IngredientList from "issara-sdk/apis/IngredientList_apps_TPD";
import DrugGroupList from "issara-sdk/apis/DrugGroupList_apps_TPD";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";

import { CreateApiRequests } from "../../common/CardPatientListInterface";

// Utils
import { beToAd, formatDate } from "react-lib/utils/dateUtils";

const ALLERGY_LIST_LIMIT = 400;

export type State = {
  django?: any;
  selectedEmr?: any;
  selectedPatient?: any;
  selectedEncounter?: any;
  masterOptions?: any;
  searchedItemList?: any[];
  errorMessage?: any;
  successMessage?: any;
  patientADR?: any;
  patientId?: number | null;
  AllergySequence?: {
    selectedAdversaryName?: string | null;
    selectedAdversaryId?: number | null;
    sequenceIndex?: string | null;
    actionButtonRadio?: string;
    adrList?: any[];
    adrTotalPage?: number;
    adrActivePage?: number;
    loadingADRTable?: boolean;
    adrSelected?: any;
    activeADRMenu?:
      | "product"
      | "ingredient"
      | "mims"
      | "atc"
      | "freetext"
      | "food"
      | "other"
      | "group";
    modConfirmInactive?: {
      open?: boolean;
      confirmNote?: string;
    };
    ingredientList?: any;
    drugGroupList?: any;
    ingredientData?: any;
    drugGroupData?: any;
    actionNote?: string | null;
    actionLog?: any | null;
    showInactive?: boolean;
  } | null;
};

export const StateInitial: State = {
  AllergySequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} }
  | { message: "ItemSearch"; params: {} };

export type Data = {
  division?: number;
  divisionDetail?: any;
};

export const DataInitial = {};

export const ADR_TYPES = {
  DRUG: "DRUG",
  FOOD: "FOOD",
  OTHER: "OTHER",
};

export const SORT_ADR_ORDER = {
  [ADR_TYPES.DRUG]: 1,
  [ADR_TYPES.FOOD]: 2,
  [ADR_TYPES.OTHER]: 3,
};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

let initial: any = {
  filterRadio: "all",
  adrTotalPage: 0,
  adrActivePage: 0,
  loadingADRTable: true,
  adrSelected: null,
  selectedAdversaryName: null,
  selectedAdversaryId: null,
  // newADR: {
  //   activeADRMenu: "product",
  //   type: "DRUG",
  //   adversary_type: "PRODUCT",
  //   other_adversary: "",
  //   selected_adversary: "",
  //   symptoms: [], // อาการ
  //   severity: "", // ความรุนแรง
  //   probability: "", // ความเป็นไปได้
  //   note: "", // ข้อความแจ้งเตือน
  //   verifyChecked: false,
  // },
};

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["patientCondition", {}],
        ["patientDischarge", {}],
        ["cleaningChoice", {}],
        ["ADRSymptoms", {}],
        ["ADRSeverity", {}],
        ["ADRProbability", {}],
      ],
    },
  });

  if (!state.AllergySequence || (!state.selectedEmr && !params.noEmr)) return;

  const allergyApis = {
    adverseReactionList: {
      api: AdverseReactionList.list,
      payload: {
        apiToken: controller.apiToken,
        params: {
          // exclude_unused: true,
          exclude_cancel: true,
          // severe_first: true,
          limit: ALLERGY_LIST_LIMIT,

          // patient: state.selectedEncounter?.patient_id || state.patientId || state.selectedPatient?.id,
          patient: params.patient || state.selectedPatient?.id,
          // show_inactive: true,
        },
        extra: { division: controller.data.division },
      },
    },
    drugGroupList: {
      api: DrugGroupList.list,
      payload: {
        apiToken: controller.apiToken,
        params: { show_on_adr: true },
        extra: { division: controller.data.division },
      },
    },
    ingredientList: {
      api: IngredientList.list,
      payload: {
        apiToken: controller.apiToken,
        params: { show_on_adr: true },
        extra: { division: controller.data.division },
      },
    },
  };

  const promises = CreateApiRequests(controller as any, {
    apis: allergyApis,
    key: "allergyApis",
  });

  const responses = await Promise.all(promises);

  const [adverseReactionList, drugGroupList, ingredientList] = responses;

  // * มีการ Abort request
  const isAbort = responses.some((res) => res[1] === "canceled");

  if (isAbort) {
    return;
  }

  //  !Today
  // Refer to P'Mahn gave API query params
  // console.log("!Today  Fetch 3", r3)

  state = controller.getState();

  console.log(" !!! adverseReactionList  ", adverseReactionList);

  if (adverseReactionList[1]) {
    controller.setState({
      AllergySequence: {
        ...initial,
        sequenceIndex: "Edit",
        loadingADRTable: false,
        adrList: [],
        ingredientData: [],
        drugGroupData: [],
      },
    });
  } else {
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        ...initial,
        sequenceIndex: "Edit",
        loadingADRTable: false,
        adrList: adverseReactionList[0]?.items || [],
        adrTotalPage: adverseReactionList[0]?.total
          ? Math.ceil(adverseReactionList[0]?.total / ALLERGY_LIST_LIMIT)
          : 0, // r2?.total,
        adrActivePage: 1,
        ingredientList: ingredientList[0]?.items,
        drugGroupList: drugGroupList[0]?.items,
        showInactive: false,
        ingredientData: [],
        drugGroupData: [],
      },
    });
  }
};

export const Edit: Handler = async (controller, params) => {
  let state = controller.getState();

  let result: any[] = [];

  if (params.action === "getActionLog") {
    const [r, e] = await AdverseReactionActionLogList.list({
      pk: params?.id,
      params: {
        offset: 0,
        limit: 40,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        actionLog: !e ? r : null,
      },
    });
  } else if (params.action === "inactive") {
    const [r] = await AdverseReactionList.list({
      params: {
        // exclude_unused: true,
        // severe_first: true,
        limit: ALLERGY_LIST_LIMIT,
        patient: state.selectedPatient?.id || state.selectedEncounter?.patient_id,
        exclude_cancel: true,
        show_inactive: params?.checked,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    state = controller.getState();

    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        loadingADRTable: false,
        adrList: r?.items || [],
      },
    });
  } else if (params.action === "clearNote") {
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        actionNote: "",
      },
      successMessage: {
        ...state.successMessage,
        [params?.card]: null,
      },
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: null,
      },
    });
  } else if (params.action === "actionButton") {
    if (params?.toggleLock) {
      result = await AdverseReactionDetail.update({
        pk: params?.item?.id,
        data: {
          action: params?.item?.status === "LOCKED" ? "PAUSE" : "LOCK",
          note: state.AllergySequence?.actionNote,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });
    } else if (params?.cancel) {
      result = await AdverseReactionDetail.update({
        pk: params?.item?.id,
        data: {
          action: "CANCEL",
          note: state.AllergySequence?.actionNote,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });
    }

    state = controller.getState();

    if (result?.[1]) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.card]: result?.[1],
        },
        AllergySequence: {
          ...state.AllergySequence,
          actionNote: "",
        },
      });
    } else if (!result?.[1]) {
      params.onDataUpdated?.();

      console.log(" Lock Success");
      controller.setState(
        {
          successMessage: {
            ...state.successMessage,
            [params?.card]: "Success",
          },
          AllergySequence: {
            ...state.AllergySequence,
            sequenceIndex: "Fetch",
            actionNote: "",
          },
        },
        () => {
          controller.handleEvent({
            message: "RunSequence",
            params: { sequence: "Allergy" },
          });
        }
      );
    }
  } else if (params.action === "save") {
    let prepareData: any = {};
    const adrSelected = state.AllergySequence?.adrSelected;

    if (adrSelected?.is_inactive) {
      if (!adrSelected?.inactive_start_date) {
        return controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: "กรุณาระบุ Inactive Date",
          },
        });
      }
      if (!state.AllergySequence?.modConfirmInactive?.confirmNote) {
        return controller.setState({
          AllergySequence: {
            ...state.AllergySequence,
            modConfirmInactive: {
              open: true,
              confirmNote: "",
            },
          },
        });
      } else {
        prepareData.note = state.AllergySequence?.modConfirmInactive?.confirmNote;
      }
    }

    // DRUG = 1, 'ยา'
    // FOOD = 2, 'อาหาร'
    // SUPPLIES = 3, 'เวชภัณฑ์'
    // OTHER = 4, 'อื่นๆ'

    const adversaryId = state.AllergySequence?.selectedAdversaryId;
    const adversaryName = state.AllergySequence?.selectedAdversaryName;
    const otherAdversary = adrSelected?.name;
    const activeADRMenu = state.AllergySequence?.activeADRMenu || "";
    const targetAction = adrSelected.targetAction || "";

    const paramsByTypes = {
      food: {
        adversary_type: "FOOD",
        selected_adversary: adversaryId,
        adversary_name: adversaryName,
        other_adversary: otherAdversary,
        type: ADR_TYPES.FOOD,
        typeCreate: 2,
      },
      other: {
        adversary_type: "OTHER",
        selected_adversary: adversaryId,
        adversary_name: adversaryName,
        other_adversary: otherAdversary,
        type: ADR_TYPES.OTHER,
        typeCreate: 4,
      },
      freetext: {
        adversary_type: "OTHER",
        selected_adversary: null,
        adversary_name: "",
        other_adversary: "",
        type: ADR_TYPES.DRUG,
        typeCreate: 1,
      },
      drug: {
        selected_adversary: adversaryId,
        adversary_name: adversaryName,
        other_adversary: "",
        type: ADR_TYPES.DRUG,
        typeCreate: 1,
      },
    } as any;

    let paramsByType = paramsByTypes[activeADRMenu];

    if (["atc", "mims", "ingredient", "product"].includes(activeADRMenu)) {
      paramsByType = {
        ...paramsByTypes.drug,
        adversary_type: activeADRMenu.toUpperCase(),
      };
    }

    const actions = {
      NOTED: { action: "NOTE", is_acknowledged: false },
      VERIFIED: { action: "NOTE", is_acknowledged: true },
      LOCKED: { action: "LOCK", is_acknowledged: true },
    } as any;

    let actionData = actions[targetAction];

    // * ย้อนกลับสถานะ LOCKED
    if (adrSelected.status === "LOCKED" && ["NOTED", "VERIFIED"].includes(targetAction)) {
      actionData.action = "UNLOCK";
    }

    prepareData = {
      ...prepareData,
      ...paramsByType,
      ...actionData,
    };

    // New or Edit
    // #let symptoms = (state.AllergySequence?.adrSelected?.symptoms || [])?.map(
    //   (item: any) => {
    //     const data = state.masterOptions?.ADRSymptoms?.find(
    //       (opt: any) => opt.value === item
    //     )

    //     return {
    //       id: data.value,
    //       name: data.text,
    //     };
    //   }
    // );

    // * Naranjo score
    let score = state.AllergySequence?.adrSelected?.naranjo_data.score;
    let probability = null;

    if (score) {
      if (score > 8) {
        probability = 4;
      } else if (score <= 8 && score >= 5) {
        probability = 3;
      } else if (score <= 4 && score >= 1) {
        probability = 2;
      } else if (score < 1) {
        probability = 1;
      }
    }

    let adrSelectedSymptoms = (state?.AllergySequence?.adrSelected?.symptoms || [])?.filter(
      (item: any) => item !== "Other"
    );
    let symptoms = adrSelectedSymptoms?.map((item: any) => {
      return {
        name: state.masterOptions?.ADRSymptoms?.find((opt: any) => opt.value === item)?.text,
      };
    });

    let data = {
      ...prepareData,
      patient: adrSelected?.patient,
      adversary_symptom_type: adrSelected?.adversary_symptom_type || "ALLERGY",
      probability: probability === null ? adrSelected?.probability || null : probability || null,
      severity: adrSelected?.severity || null,
      type: adrSelected?.id ? prepareData.type : prepareData.typeCreate,
      symptoms: adrSelected?.id ? symptoms : adrSelectedSymptoms,
      note: prepareData?.note || adrSelected?.note || "",
      is_inactive: adrSelected?.is_inactive,
      inactive_start_date: beToAd(adrSelected?.inactive_start_date)?.format("YYYY-MM-DD") || null,
      naranjo: JSON.stringify(adrSelected?.naranjo_data),
      other_symptom: adrSelected?.other_symptom,
      // moment for adrSelected.type other or food
      ...(adrSelected?.issue_date && {
        issue_date: beToAd(adrSelected?.issue_date)?.format("YYYY-MM-DD"),
      }),
    };

    // prepareData.action === LOCK use action "UNLOCK" before update
    if (
      (prepareData.action === "LOCK" && prepareData.is_acknowledged === true) ||
      prepareData.action === "UNLOCK"
    ) {
      const allergyUnlock = await AdverseReactionDetail.update({
        pk: adrSelected?.id,
        data: {
          ...data,
          action: "UNLOCK",
          symptoms: adrSelectedSymptoms,
          type: prepareData.typeCreate,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });
    }

    const api = adrSelected?.id ? AdverseReactionDetail.update : AdverseReactionList.create;

    const [adrRes] = await api({
      pk: adrSelected?.id,
      data: {
        ...data,
        action: ["LOCK", "UNLOCK"].includes(prepareData.action) ? "NOTE" : prepareData.action,
        symptoms: data.symptoms,
        type: data.type,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    // create and use update to update data
    if (adrRes) {
      const [adrUpdateRes] = await AdverseReactionDetail.update({
        pk: adrRes?.id,
        data: {
          ...data,
          action: prepareData.action === "LOCK" ? "NOTE" : prepareData.action,
          symptoms: symptoms,
          type: prepareData.type,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

      // ถ้า action === "LOCK" ให้ทำการ update ก่อน เพื่อบันทึกข้อมูล
      if (adrUpdateRes && prepareData.action === "LOCK") {
        const [adrUpdateRes] = await AdverseReactionDetail.update({
          pk: adrRes?.id,
          data: {
            ...data,
            symptoms: adrSelectedSymptoms,
            type: prepareData.typeCreate,
          },
          apiToken: controller.apiToken,
          extra: { division: controller.data.division },
        });
      }
    }

    state = controller.getState();

    if (adrRes) {
      params.onDataUpdated?.();

      controller.setState(
        {
          AllergySequence: {
            ...state.AllergySequence,
            adrSelected: null,
            sequenceIndex: "Fetch",
            modConfirmInactive: {
              open: false,
              confirmNote: "",
            },
          },
        },
        () => {
          controller.handleEvent({
            message: "RunSequence",
            params: { sequence: "Allergy" },
          });
        }
      );
    }
  } else if (params.action === "saveIngredientData") {
    let newData: any = [];
    let ingredient = state.AllergySequence?.ingredientData.filter(
      (item: any) => item.checked === true && item?.onDone !== true
    );
    let drugGroup = state.AllergySequence?.drugGroupData.filter(
      (item: any) => item.checked === true && item?.onDone !== true
    );

    ingredient.forEach((item: any) => {
      let setData = {
        action: "NOTE",
        adversary_name: item.name,
        adversary_type: "INGREDIENT",
        inactive_start_date: null,
        is_acknowledged: false,
        is_inactive: false,
        naranjo: "{}",
        note: item.note || "note",
        other_adversary: "",
        probability: null,
        selected_adversary: item.id,
        severity: null,
        symptoms: [],
        type: "DRUG",
      };

      newData.push(setData);
    });

    drugGroup.forEach((item: any) => {
      let setData = {
        action: "NOTE",
        adversary_name: item.name,
        adversary_type: "MIMS",
        inactive_start_date: null,
        is_acknowledged: false,
        is_inactive: false,
        naranjo: "{}",
        note: item.note || "note",
        other_adversary: "",
        probability: null,
        selected_adversary: item.id,
        severity: null,
        symptoms: [],
        type: "DRUG",
      };

      newData.push(setData);
    });
    console.log("state.selectedEncounter?.patient_id: ", state.selectedEncounter?.patient_id);
    console.log("state.patientId: ", state.patientId);

    const [r] = await MultiAdverseReactionList.create({
      data: {
        action: "NOTE",
        patient: state.selectedEncounter?.patient_id || state.patientId || state.selectedPatient.id,
        items: newData,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (r) {
      params.onDataUpdated?.();
      // Success
      state = controller.getState();
      controller.setState(
        {
          AllergySequence: {
            ...state.AllergySequence,
            adrSelected: null,
            sequenceIndex: "Fetch",
            modConfirmInactive: {
              open: false,
              confirmNote: "",
            },
            drugGroupData: [],
            ingredientData: [],
          },
        },
        () => {
          controller.handleEvent({
            message: "RunSequence",
            params: { sequence: "Allergy" },
          });
        }
      );
    }
  } else if (params.action === "newNote") {
    state = controller.getState();

    if (params.card === "CardPatientPanel") {
      const [r] = await AdverseReactionList.list({
        params: {
          limit: ALLERGY_LIST_LIMIT,
          patient:
            state.selectedEncounter?.patient_id || state.patientId || state.selectedPatient.id,
          exclude_cancel: true,
          // exclude_unused: true,
          // severe_first: true,
          show_inactive: state.AllergySequence?.showInactive,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          adrSelected: {},
          activeADRMenu: "group",
          selectedAdversaryName: null,
        },
        patientADR: r,
      });
    } else {
      const patientId =
        state.selectedEncounter?.patient_id || state.patientId || state.selectedPatient.id;

      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          activeADRMenu: "group",
          selectedAdversaryName: null,
          adrSelected: {
            id: null,
            patient: patientId,
            note: "note",
            status: "NOTED",
            naranjo: "{}",
            naranjo_data: {},
            targetAction: "NOTED",
          },
        },
      });
    }
  } else if (params.action === "selectedADR") {
    const [r, e] = await AdverseReactionDetail.retrieve({
      pk: params.original?.id,
      params: { show_note: true },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (e) {
      return;
    }

    let targetAction = "NOTED";

    if (r.status === "LOCKED" && r.is_acknowledged) {
      targetAction = "LOCKED";
    } else if (r.is_acknowledged) {
      targetAction = "VERIFIED";
    }

    let ADType: any = r.adversary_type?.toLowerCase() || "product";

    let allergySeq = {
      ...state.AllergySequence,
      adrSelected: {
        ...params.original,
        activeADRMenu: ADType,
        targetAction,
        naranjoChecked: r?.naranjo_data.score !== undefined,
        ...(params.original?.other_symptom !== "" && {
          symptoms: [...params.original?.symptoms, "Other"],
        }),
      },
    };

    if (r.adversary_type) {
      // * Default menu
      if (ADType === "other") {
        if (r.type === "FOOD") {
          ADType = "food";
        } else if (r.type === "OTHER") {
          ADType = "other";
        } else if (r.type === "DRUG") {
          ADType = "freetext";
        }
      }

      allergySeq = {
        ...allergySeq,
        selectedAdversaryId: r.selected_adversary,
        selectedAdversaryName: r.adversary_name,
        activeADRMenu: ADType,
      };

      allergySeq.adrSelected.issue_date = formatDate(moment(r.issue_date, "DD-MM-YYYY"));

      if (allergySeq.adrSelected?.inactive_start_date) {
        allergySeq.adrSelected.inactive_start_date = formatDate(
          moment(r?.inactive_start_date, "DD-MM-YYYY")
        );
      }

      const drugApis = {
        product: DrugList.list({
          params: { name_code: r.adversary_name },
          apiToken: controller.apiToken,
        }),
        mims: DrugGroupList.list({
          params: {
            type: "MIMS",
            name_desc: r.adversary_name,
          },
          apiToken: controller.apiToken,
        }),
        atc: DrugGroupList.list({
          params: {
            type: "ATC",
            name_desc: r.adversary_name,
          },
          apiToken: controller.apiToken,
        }),
      } as any;

      if (drugApis[ADType]) {
        const [result] = await drugApis[ADType];

        allergySeq.selectedAdversaryName = result.items[0]?.name_code;
      }

      controller.handleEvent({
        message: "ItemSearch",
        params: { type: "ADR" + ADType, searchText: r?.adversary_name },
      });
    }

    controller.setState({ AllergySequence: { ...allergySeq } });
  } else if (params.action === "NO_REACTION") {
    const types = {
      drug: ["DRUG", 1],
      food: ["FOOD", 2],
      other: ["OTHER", 4],
    } as any;

    const type = types[params.type];

    const [r, e, n] = await AdverseReactionList.create({
      data: {
        action: "NO_REACTION",
        items: [], // ลอกจาก QML
        note: "",
        type: type[1],
        type_name_name: type[0],
        patient: state.selectedEncounter?.patient_id || state.patientId || state.selectedPatient.id,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    console.log("Call API to Save No ADR");

    state = controller.getState();

    controller.setState(
      {
        AllergySequence: {
          ...state.AllergySequence,
          sequenceIndex: "Fetch",
        },
      },
      () => {
        controller.handleEvent({
          message: "RunSequence",
          params: { sequence: "Allergy" },
        });
      }
    );
  }
  // else if (params?.action === "noADR") {
  //   const [r, e, n] = await AdverseReactionList.create({
  //     data: {
  //       action: "NO_REACTION",
  //       items: [], // ลอกจาก QML
  //       note: "",
  //       type: 1,
  //       type_name_name: "DRUG",
  //       patient: state.selectedEncounter?.patient_id || state.patientId,
  //     },
  //     apiToken: controller.apiToken,
  //     extra: { division: controller.data.division },
  //   });
  //   console.log("Call API to Save No ADR");
  //   state = controller.getState();
  //   controller.setState(
  //     {
  //       AllergySequence: {
  //         ...state.AllergySequence,
  //         sequenceIndex: "Fetch",
  //       },
  //     },
  //     () => {
  //       controller.handleEvent({
  //         message: "RunSequence",
  //         params: { sequence: "Allergy" },
  //       });
  //     }
  //   );
  // } else if (params?.action === "noFood") {
  //   const [r, e, n] = await AdverseReactionList.create({
  //     data: {
  //       action: "NO_REACTION",
  //       items: [], // ลอกจาก QML
  //       note: "",
  //       type: 2,
  //       type_name_name: "FOOD",
  //       patient: state.selectedEncounter?.patient_id || state.patientId,
  //     },
  //     apiToken: controller.apiToken,
  //     extra: { division: controller.data.division },
  //   });
  //   console.log("Call API to Save No Food");

  //   state = controller.getState();
  //   controller.setState(
  //     {
  //       AllergySequence: {
  //         ...state.AllergySequence,
  //         sequenceIndex: "Fetch",
  //       },
  //     },
  //     () => {
  //       controller.handleEvent({
  //         message: "RunSequence",
  //         params: { sequence: "Allergy" },
  //       });
  //     }
  //   );
  // } else if (params?.action === "noOther") {
  //   const [r, e, n] = await AdverseReactionList.create({
  //     data: {
  //       action: "NO_REACTION",
  //       items: [], // ลอกจาก QML
  //       note: "",
  //       type: 4,
  //       type_name_name: "OTHER",
  //       patient: state.selectedEncounter?.patient_id || state.patientId,
  //     },
  //     apiToken: controller.apiToken,
  //     extra: { division: controller.data.division },
  //   });
  //   console.log("Call API to Save No Other");
  //   state = controller.getState();
  //   controller.setState(
  //     {
  //       AllergySequence: {
  //         ...state.AllergySequence,
  //         sequenceIndex: "Fetch",
  //       },
  //     },
  //     () => {
  //       controller.handleEvent({
  //         message: "RunSequence",
  //         params: { sequence: "Allergy" },
  //       });
  //     }
  //   );
  // }
  else if (params.action === "selectedADRType" && params.activeADRMenu) {
    let adrSelected = state.AllergySequence?.adrSelected;
    let ADType: any = adrSelected.adversary_type?.toLowerCase() || "product";

    // * Default menu
    if (ADType === "other") {
      if (adrSelected.type === "FOOD") {
        ADType = "food";
      } else if (adrSelected.type === "OTHER") {
        ADType = "other";
      } else if (adrSelected.type === "DRUG") {
        ADType = "freetext";
      }
    }

    if (ADType !== params.activeADRMenu) {
      // clear adrSelected
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          adrSelected: {
            ...state.AllergySequence?.adrSelected,
            symptoms: [],
            adr_short_info: "",
            adversary_symptom_type: "",
            issue_date: "",
            severity: "",
            naranjo: {},
            naranjoChecked: false,
            naranjo_data: {},
            note: "",
            probability: "",
            name: ""
          },
          selectedAdversaryName: "",
          activeADRMenu: params.activeADRMenu,
        },
      });
    } else {
      // filter adverse-reaction
      const adrSelected = state.AllergySequence?.adrSelected;
      let symptoms = (adrSelected.symptoms || [])?.map((item: any, number: any) => {
        return item.id;
      });

      let selectedAdversaryName;

      const drugApis = {
        product: DrugList.list({
          params: { name_code: adrSelected.adversary_name },
          apiToken: controller.apiToken,
        }),
        mims: DrugGroupList.list({
          params: {
            type: "MIMS",
            name_desc: adrSelected.adversary_name,
          },
          apiToken: controller.apiToken,
        }),
        atc: DrugGroupList.list({
          params: {
            type: "ATC",
            name_desc: adrSelected.adversary_name,
          },
          apiToken: controller.apiToken,
        }),
      } as any;

      if (drugApis[ADType]) {
        const [result] = await drugApis[ADType];

        selectedAdversaryName = result.items[0]?.name_code;
      }

      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          adrSelected: { ...adrSelected, symptoms: symptoms },
          selectedAdversaryName: selectedAdversaryName,
          activeADRMenu: params.activeADRMenu,
        },
      });
    }
  } else if (params.action === "editADR" && params.field && params.value !== undefined) {
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        adrSelected: {
          ...state.AllergySequence?.adrSelected,
          [params?.field]: params?.value,
        },
      },
    });
  } else if (params.action === "runningAllergy") {
    console.log("runningAllergy");
    console.log("params?.toActivePage", params?.toActivePage);
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        loadingADRTable: false,
      },
    });

    const [r, e] = await AdverseReactionList.list({
      params: {
        limit: ALLERGY_LIST_LIMIT,
        patient: state.selectedEncounter?.patient_id || state.patientId || state.selectedPatient.id,
        exclude_cancel: true,
        // offset: (params?.toActivePage - 1) * ALLERGY_LIST_LIMIT,
        // exclude_unused: true,
        // severe_first: true,
        show_inactive: state.AllergySequence?.showInactive,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    state = controller.getState();
    if (!e) {
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          loadingADRTable: false,
          adrList: r?.items ? r?.items : [],
          adrTotalPage: r?.total ? Math.ceil(r?.total / ALLERGY_LIST_LIMIT) : 0, // r2?.total,
          adrActivePage: params?.toActivePage,
        },
      });
    }
  } else if (params.action === "selectedPage") {
    console.log("params?.toActivePage", params?.toActivePage);

    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        loadingADRTable: true,
        adrSelected: null,
      },
    });

    const [r, e] = await AdverseReactionList.list({
      params: {
        limit: ALLERGY_LIST_LIMIT,
        offset: (params?.toActivePage - 1) * ALLERGY_LIST_LIMIT,
        patient: state.selectedEncounter?.patient_id || state.patientId || state.selectedPatient.id,
        exclude_cancel: true,
        // exclude_unused: true,
        // severe_first: true,
        show_inactive: state.AllergySequence?.showInactive,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    state = controller.getState();

    if (!e) {
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          loadingADRTable: false,
          adrList: r?.items?.length > 0 ? r?.items : [],
          adrTotalPage: r?.total ? Math.ceil(r?.total / ALLERGY_LIST_LIMIT) : 0, // r2?.total,
          adrActivePage: params?.toActivePage,
        },
      });
    }
  }
};

export const Fetch: Handler = async (controller, params) => {
  let state = controller.getState();

  controller?.setState({
    AllergySequence: {
      ...state.AllergySequence,
      loadingADRTable: true,
    },
  });

  const [r, e] = await AdverseReactionList.list({
    params: {
      limit: ALLERGY_LIST_LIMIT,
      patient: state.selectedEncounter?.patient_id || state.patientId || state.selectedPatient.id,
      exclude_cancel: true,
      // exclude_unused: true,
      // severe_first: true,
      show_inactive: state.AllergySequence?.showInactive,
    },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  state = controller.getState();

  if (e) {
    console.log("r", r);
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        ...initial,
        sequenceIndex: "Edit",
        loadingADRTable: false,
        adrList: [],
      },
    });
  } else {
    console.log("r", r);

    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        ...initial,
        sequenceIndex: "Edit",
        loadingADRTable: false,
        adrList: r?.items ? r?.items : [],
        adrTotalPage: r?.total ? Math.ceil(r?.total / ALLERGY_LIST_LIMIT) : 0, // r2?.total,
        adrActivePage: 1,
      },
      patientADR: r,
    });
  }
};

// //  Today
// #const [r2, e2, n2] = await AdverseReactionList.list({
//   params: {
//     patient: state.selectedEncounter?.patient_id,
//     today: true,
//     exclude_cancel: true,
//   },
//   apiToken: controller.apiToken,
//   extra: { division: controller.data.division },
// });
// console.log("Today Fetch 2", r2);
// //  !Today
// const [r3, e3, n3] = await AdverseReactionList.list({
//   params: {
//     patient: state.selectedEncounter?.patient_id,
//     exclude_cancel: true,
//   },
//   apiToken: controller.apiToken,
//   extra: { division: controller.data.division },
// });
// console.log("!Today  Fetch 3", r3);
// // เภสัช ดูด้วย
// const [r4, e4, n4] = await AdverseReactionList.list({
//   params: {
//     patient: state.selectedEncounter?.patient_id,
//     exclude_unused: true,
//   },
//   apiToken: controller.apiToken,
//   extra: { division: controller.data.division },
// });
// console.log("เภสัช ดูด้วย  Fetch ", r4);
// state = controller.getState();
// controller.setState({
//   AllergySequence: {
//     ...state.AllergySequence,
//     sequenceIndex: "Edit",
//   },
// });
