import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Icon
} from 'semantic-ui-react'

const CardRiskOfDVTUX = (props: any) => {
    return(
      <div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              width={7}>
              <label
                style={{ fontSize: "17px" }}>
                (Padua Prediction Score)
              </label>
            </FormField>
            <div
              style={{ position: "absolute", right: "0", display: "flex" }}>
              
              <label
                style={{ padding: "8px 5px 0px" }}>
                {`${props.data?.edit_by || ""}  ${props.data?.edited || ""}`}
              </label>
              <div>
                {props.ButtonSave}
              </div>
            </div>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{ border: "2px solid #0CABCF", padding: "20px" }}>
          
          <FormGroup
            inline={true}>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.elderly_age}
                disabled={props.data?.none_risk}
                label="Elderly"
                name="pre_op_medical_assessment.data.risk_dvt.elderly_age"
                onChange={props.onChangeValue}
                style={{ fontWeight: "bold" }}>
              </Checkbox>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                {"Age > 70 years"}
              </label>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+1)
              </label>
            </FormField>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.trauma}
                disabled={props.data?.none_risk}
                label="Recent ( 1 month) Trauma/Surgery"
                name="pre_op_medical_assessment.data.risk_dvt.trauma"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+2)
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.obesity_bmi}
                disabled={props.data?.none_risk}
                label="Obesity"
                name="pre_op_medical_assessment.data.risk_dvt.obesity_bmi"
                onChange={props.onChangeValue}
                style={{ fontWeight: "bold" }}>
              </Checkbox>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px", ...( props.data?.none_risk && { opacity: "0.5" } )  }}>
                {"BMI > 30 kg/m2"}
              </label>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+1)
              </label>
            </FormField>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.cancer}
                disabled={props.data?.none_risk}
                label="Active Cancer"
                name="pre_op_medical_assessment.data.risk_dvt.cancer"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+3)
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.heart}
                disabled={props.data?.none_risk}
                label="Heart and/or Respiratory failure"
                name="pre_op_medical_assessment.data.risk_dvt.heart"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+1)
              </label>
            </FormField>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.mobillity}
                disabled={props.data?.none_risk}
                label="Reduce Mobillity "
                name="pre_op_medical_assessment.data.risk_dvt.mobillity"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+3)
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.mi}
                disabled={props.data?.none_risk}
                label="Acute Myocardial infection (MI) and /or Ischemic stroke "
                name="pre_op_medical_assessment.data.risk_dvt.mi"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+1)
              </label>
            </FormField>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.thrombophilia}
                disabled={props.data?.none_risk}
                label="Thrombophilia Condition"
                name="pre_op_medical_assessment.data.risk_dvt.thrombophilia"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+3)
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.infection}
                disabled={props.data?.none_risk}
                label=" Acute Infection and/or Rheumatologic disorder"
                name="pre_op_medical_assessment.data.risk_dvt.infection"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+1)
              </label>
            </FormField>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.vte}
                disabled={props.data?.none_risk}
                label="Previous"
                name="pre_op_medical_assessment.data.risk_dvt.vte"
                onChange={props.onChangeValue}>
              </Checkbox>
              <label
                style={{ fontWeight: "bold", marginLeft: "-8px", ...( props.data?.none_risk && { opacity: "0.5" } )  }}>
                VTE
              </label>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+3)
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={7}>
            </FormField>
            <FormField
              width={1}>
            </FormField>
            <FormField
              width={7}>
              <label
                style={{ paddingLeft: "25px", fontWeight: "normal", marginTop: "-15px", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (Excluding superficial vein thrombosis)
              </label>
            </FormField>
            <FormField
              width={1}>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.hormonal}
                disabled={props.data?.none_risk}
                label="Ongoing Hormonal Treatment"
                name="pre_op_medical_assessment.data.risk_dvt.hormonal"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#0147A3", ...( props.data?.none_risk && { opacity: "0.5" } ) }}>
                (+1)
              </label>
            </FormField>
            <FormField
              width={7}>
              <Checkbox
                checked={props.data?.none_risk}
                disabled={props.data?.disabled_none_risk}
                name="pre_op_medical_assessment.data.risk_dvt.none_risk"
                onChange={props.onChangeValue}>
              </Checkbox>
              <label
                style={{ color: "#1B9D2C", fontWeight: "bold", ...( props.data?.disabled_none_risk && { opacity: "0.5" } ) }}>
                None of the above risk factor
              </label>
            </FormField>
            <FormField
              width={1}>
              <label
                style={{color: "#1B9D2C",  ...( props.data?.disabled_none_risk && { opacity: "0.5" } ) }}>
                (0)
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ fontSize: "17px" }}>
            <FormField
              width={4}>
              <label>
                Total Risk Factor Score
              </label>
            </FormField>
            <FormField
              style={{ background: "#C0F3FF9E",border: "2px solid #41C7E5", padding: "15px" }}
              width={1}>
              <label>
                {props.data?.score || ""}
              </label>
            </FormField>
            <FormField
              width={4}>
              <label
                style={{ paddingLeft: "20px", fontSize: "25px", marginRight: "10px" }}>
                {props.data?.summary ? `${props.data?.summary} risk` : ""}
              </label>
              <label>
                {props.data?.summary ? "of DVT" : ""}
              </label>
            </FormField>
            <FormField
              width={7}>
              <label
                style={{ color: "#0147A3" }}>
                {props.data?.summary === "Low" ? "Pharmacologic prophylaxis is NOT indicated. Consider using mechanical prophylaxis." : props.data?.summary === "High" ? "Pharmacologic Prophylaxis IS indicated.  If high risk of bleeding, use mechanical prophylaxis." : ""}
              </label>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}
            style={{ fontSize: "17px" }}>
            <FormField>
              <Icon
                name="info circle blue">
              </Icon>
            </FormField>
            <FormField>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px", textDecoration: "underline" }}>
                Active cancer
              </label>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px"  }}>
                 is defined as local or distant metastases and with chemotherapy or radiation in the previous 6 months
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ fontSize: "17px", paddingLeft: "35px" }}>
            <FormField>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px", textDecoration: "underline" }}>
                Reduced mobility
              </label>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px"  }}>
                is defined as anticipated bed rest with bathroom privileges for at least 3 days
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ fontSize: "17px", paddingLeft: "35px" }}>
            <FormField>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px", textDecoration: "underline" }}>
                Established Thrombophilic
              </label>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px"  }}>
                is defined as factor V Leiden, G20210A prothrombin mutation, Pharmacological prophylaxis Not needed
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ fontSize: "17px", paddingLeft: "35px", marginTop: "-15px" }}>
            <FormField>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px"}}>
                antiphospholipid syndrome, tupus anticoagulant, elevated serum homocysteine, If VTE prophylaxis not administered as recommended, reason
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ fontSize: "17px", paddingLeft: "35px", marginTop: "-15px" }}>
            <FormField>
              <label
                style={{ fontWeight: "normal", marginLeft: "-8px"  }}>
                heparin-induced thrombocytopenia and defects of antithrombin, protein C or S.
              </label>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{ background: "#C0F3FF9E", border: "2px solid #41C7E5", padding: "20px" }}>
          
          <FormGroup
            inline={true}>
            <FormField
              style={{ fontSize: "17px" }}
              width={2}>
              <label>
                Padua Score
              </label>
            </FormField>
            <FormField
              width={2}>
              <label>
                {"< 4: Low risk of VTE."}
              </label>
            </FormField>
            <FormField
              width={12}>
              <label
                style={{ fontWeight: "normal" }}>
                Thromboprophylaxis should be considered on a case-by-case basis.
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={2}>
            </FormField>
            <FormField
              width={2}>
              <label>
                 ≥ 4: High risk of VTE.
              </label>
            </FormField>
            <FormField
              width={12}>
              <label
                style={{ fontWeight: "normal" }}>
                {"Thromboprophylaxis (i.e. heparin / enoxaparin) is recommended for non-pregnant patients without contraindications (major bleeding, low platelets, creatinine clearance <30 mL/min) who are >18 years."}
              </label>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardRiskOfDVTUX

export const screenPropsDefault = {}

/* Date Time : Thu Jul 25 2024 11:29:07 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ border: \"2px solid #0CABCF\", padding: \"20px\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 4,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 4,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.elderly_age"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Elderly"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.elderly_age"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "\"Age > 70 years\""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "(+1)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 4,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Checkbox",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.trauma"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Recent ( 1 month) Trauma/Surgery"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.trauma"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 4,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "(+2)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 21,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Checkbox",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.obesity_bmi"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Obesity"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.obesity_bmi"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "\"BMI > 30 kg/m2\""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\", ...( props.data?.none_risk && { opacity: \"0.5\" } )  }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 21,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "(+1)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 21,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.cancer"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Active Cancer"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.cancer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "toggle": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 21,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "(+3)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 31,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Checkbox",
      "parent": 32,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.heart"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Heart and/or Respiratory failure"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.heart"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 31,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "(+1)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 31,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.mobillity"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Reduce Mobillity "
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.mobillity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 31,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "(+3)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 41,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Checkbox",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.mi"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Acute Myocardial infection (MI) and /or Ischemic stroke "
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.mi"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 41,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "(+1)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 41,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Checkbox",
      "parent": 46,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.thrombophilia"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Thrombophilia Condition"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.thrombophilia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 41,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "(+3)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Checkbox",
      "parent": 51,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.infection"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": " Acute Infection and/or Rheumatologic disorder"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.infection"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "(+1)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Checkbox",
      "parent": 55,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.vte"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Previous"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.vte"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "(+3)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "VTE"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginLeft: \"-8px\", ...( props.data?.none_risk && { opacity: \"0.5\" } )  }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 61,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 61,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 61,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "(Excluding superficial vein thrombosis)"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"25px\", fontWeight: \"normal\", marginTop: \"-15px\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 61,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 71,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Checkbox",
      "parent": 72,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.hormonal"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "label": {
          "type": "value",
          "value": "Ongoing Hormonal Treatment"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.hormonal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 71,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "(+1)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0147A3\", ...( props.data?.none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 71,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Checkbox",
      "parent": 76,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.disabled_none_risk"
        },
        "label": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_dvt.none_risk"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 71,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "(0)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#1B9D2C\",  ...( props.data?.disabled_none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 80,
      "props": {
        "style": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "Total Risk Factor Score"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 80,
      "props": {
        "style": {
          "type": "code",
          "value": "{ background: \"#C0F3FF9E\",border: \"2px solid #41C7E5\", padding: \"15px\" }"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data?.score || \"\""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 89,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "(Padua Prediction Score)"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\" }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormGroup",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 97,
      "props": {
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 97,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Icon",
      "parent": 98,
      "props": {
        "name": {
          "type": "value",
          "value": "info circle blue"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Active cancer"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\", textDecoration: \"underline\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": " is defined as local or distant metastases and with chemotherapy or radiation in the previous 6 months"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\"  }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormGroup",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", paddingLeft: \"35px\" }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 106,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": "Reduced mobility"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\", textDecoration: \"underline\" }"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": "is defined as anticipated bed rest with bathroom privileges for at least 3 days"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\"  }"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormGroup",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", paddingLeft: \"35px\" }"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 112,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "Established Thrombophilic"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\", textDecoration: \"underline\" }"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "is defined as factor V Leiden, G20210A prothrombin mutation, Pharmacological prophylaxis Not needed"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\"  }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormGroup",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", paddingLeft: \"35px\", marginTop: \"-15px\" }"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 116,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "antiphospholipid syndrome, tupus anticoagulant, elevated serum homocysteine, If VTE prophylaxis not administered as recommended, reason"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormGroup",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", paddingLeft: \"35px\", marginTop: \"-15px\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 120,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "label",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "heparin-induced thrombocytopenia and defects of antithrombin, protein C or S."
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", marginLeft: \"-8px\"  }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ background: \"#C0F3FF9E\", border: \"2px solid #41C7E5\", padding: \"20px\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormGroup",
      "parent": 124,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "FormField",
      "parent": 125,
      "props": {
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "Padua Score"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "FormField",
      "parent": 125,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 130,
      "props": {
        "children": {
          "type": "code",
          "value": "\"< 4: Low risk of VTE.\""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormField",
      "parent": 125,
      "props": {
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "label",
      "parent": 132,
      "props": {
        "children": {
          "type": "value",
          "value": "Thromboprophylaxis should be considered on a case-by-case basis."
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormGroup",
      "parent": 124,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 134,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 134,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": " ≥ 4: High risk of VTE."
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 134,
      "props": {
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 139,
      "props": {
        "children": {
          "type": "code",
          "value": "\"Thromboprophylaxis (i.e. heparin / enoxaparin) is recommended for non-pregnant patients without contraindications (major bleeding, low platelets, creatinine clearance <30 mL/min) who are >18 years.\""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 144,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ position: \"absolute\", right: \"0\", display: \"flex\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.data?.edit_by || \"\"}  ${props.data?.edited || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"8px 5px 0px\" }"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "None of the above risk factor"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"#1B9D2C\", fontWeight: \"bold\", ...( props.data?.disabled_none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 80,
      "props": {
        "style": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "label",
      "parent": 149,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data?.summary ? `${props.data?.summary} risk` : \"\""
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"20px\", fontSize: \"25px\", marginRight: \"10px\" }"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "label",
      "parent": 149,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data?.summary ? \"of DVT\" : \"\""
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 80,
      "props": {
        "style": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 154,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data?.summary === \"Low\" ? \"Pharmacologic prophylaxis is NOT indicated. Consider using mechanical prophylaxis.\" : props.data?.summary === \"High\" ? \"Pharmacologic Prophylaxis IS indicated.  If high risk of bleeding, use mechanical prophylaxis.\" : \"\""
        },
        "style": {
          "type": "code",
          "value": "{ color: \"#0147A3\" }"
        }
      },
      "seq": 157,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardRiskOfDVTUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
