import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const MSDMenuUX = (props: any) => {
    return(
      <div
        style={{display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap", flex: "0 1 auto"}}>
        <div
          style={{display: "flex", justifyContent: "flex-start", alignItems: "center",padding: "20px 0px 0px 10px"}}>
          
          <Button
            color={props.queueOrderClicked ? "blue" : undefined}
            onClick={props.queueOrder}
            style={{marginRight: "10px"}}>
            Queue Order
          </Button>
          <Button
            color={props.supplyOrderHistoryClicked ? "blue" : undefined}
            onClick={props.supplyOrderHistory}>
            Supply Order History
          </Button>
          <Button
            color={props.deliveryHistoryClicked ? "blue" : undefined}
            onClick={props.deliveryHistory}
            style={{...(props.hideDeliveryHistoryBtn && {display: "none"})}}>
            Delivery History
          </Button>
          <Button
            color={props.stockManagementClicked? "blue" : undefined}
            onClick={props.stockManagement}>
            Stock Management
          </Button>
          <Button
            color={props.returnHistoryClicked ? "blue" : undefined}
            onClick={props.returnHistory}
            style={{...(props.hideReturnBtn && {display: "none"})}}>
            Return History 
          </Button>
        </div>
        <div
          style={{border:"1px solid #ccc", borderRadius: "8px", padding: "0px 8px 4px 10px", ...(props.hideSending && {display: "none"}), margin: "5px 0px 0 8px"}}>
          
          <div>
            
            <label
              style={{color: "#000000de"}}>
              กลุ่มจัดส่งเวชภัณฑ์
            </label>
          </div>
          <Button
            color={props.deliveryPrintClicked ? "blue" : undefined}
            onClick={props.deliveryPrint}>
            พิมพ์จัดส่ง
          </Button>
          <Button
            color={props.deliveryQueueClicked ? "blue" : undefined}
            onClick={props.deliveryQueue}>
            คิวจัดส่ง
          </Button>
          <Button
            color={props.deliveryClicked ? "blue" : undefined}>
             จัดเวชภัณฑ์เพื่อจัดส่ง
          </Button>
        </div>
      </div>
    )
}


export default MSDMenuUX

export const screenPropsDefault = {}

/* Date Time : Thu Dec 28 2023 10:17:34 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-start\", alignItems: \"center\", flexWrap: \"wrap\", flex: \"0 1 auto\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Queue Order"
        },
        "color": {
          "type": "code",
          "value": "props.queueOrderClicked ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "props.queueOrder"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "คิวจัดส่ง"
        },
        "color": {
          "type": "code",
          "value": "props.deliveryQueueClicked ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "props.deliveryQueue"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": " จัดเวชภัณฑ์เพื่อจัดส่ง"
        },
        "color": {
          "type": "code",
          "value": "props.deliveryClicked ? \"blue\" : undefined"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์จัดส่ง"
        },
        "color": {
          "type": "code",
          "value": "props.deliveryPrintClicked ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "props.deliveryPrint"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border:\"1px solid #ccc\", borderRadius: \"8px\", padding: \"0px 8px 4px 10px\", ...(props.hideSending && {display: \"none\"}), margin: \"5px 0px 0 8px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-start\", alignItems: \"center\",padding: \"20px 0px 0px 10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "กลุ่มจัดส่งเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#000000de\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Supply Order History"
        },
        "color": {
          "type": "code",
          "value": "props.supplyOrderHistoryClicked ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "props.supplyOrderHistory"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Delivery History"
        },
        "color": {
          "type": "code",
          "value": "props.deliveryHistoryClicked ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "props.deliveryHistory"
        },
        "style": {
          "type": "code",
          "value": "{...(props.hideDeliveryHistoryBtn && {display: \"none\"})}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Return History "
        },
        "color": {
          "type": "code",
          "value": "props.returnHistoryClicked ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "props.returnHistory"
        },
        "style": {
          "type": "code",
          "value": "{...(props.hideReturnBtn && {display: \"none\"})}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Stock Management"
        },
        "color": {
          "type": "code",
          "value": "props.stockManagementClicked? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "props.stockManagement"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "MSDMenuUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
