// vitalSignService.ts
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import axios from "axios";
import moment from "moment";

import { firebaseApp } from "react-lib/frameworks/Firebase";

const storage = getStorage(firebaseApp);

console.log("firebaseApp", firebaseApp);

interface VitalSignType {
  name: string;
  unit: string;
}

interface SourceValue {
  value: string;
  valueName: string;
  timeStampISO: string;
}

export interface ConvertValue {
  value: string;
  valueName: string;
  timeStampISO: moment.Moment;
  timeStamp: string;
  unitType?: string;
}

interface DeviceData {
  name: string;
  unitType: string;
  values: SourceValue[];
  timeStamp: string;
}

export interface SourceVitalSignData {
  Android: DeviceData;
  Ios: DeviceData;
}

export interface MergedVitalSignData {
  name: string;
  unitType?: string;
  values: ConvertValue[];
}

export interface ConsolidatedData {
  [key: string]: MergedVitalSignData;
}

export interface FileDetails {
  name: string;
  url: string;
  date: moment.Moment;
}

const getVitalSignFiles = async (
  patientId: string,
  selectedRange: string
): Promise<{
  filesList: { [key: string]: FileDetails[] };
  vitalSignData: { [key: string]: SourceVitalSignData[] };
}> => {
  const storageRef = ref(storage, patientId);
  const vitalSignDirectories = await listAll(storageRef);
  const filesList: { [key: string]: FileDetails[] } = {};

  const endDate = moment();
  const startDate = endDate.clone().subtract(parseInt(selectedRange), "days");

  for (const dirRef of vitalSignDirectories.prefixes) {
    const dirName = dirRef.name;
    const dirContents = await listAll(ref(storage, `${patientId}/${dirName}`));

    const fileDetails: FileDetails[] = await Promise.all(
      dirContents.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        const name = itemRef.name;
        const dateStr = name.split("_").slice(-3).join("_");
        const fileDate = moment(dateStr, "YYYY_MM_DD");
        return {
          name,
          url,
          date: fileDate,
        };
      })
    );

    // Exclude files named "LastUpdate"
    let filteredFiles = fileDetails.filter((file) => !file.name.includes("LastUpdate"));

    // filter by date range
    filteredFiles = filteredFiles
      .filter((file) => file.date.isBetween(startDate, endDate, "days", "[]"))
      .sort((a, b) => b.date.diff(a.date));

    filesList[dirName] = filteredFiles;
  }

  const vitalSignData: { [key: string]: SourceVitalSignData[] } = {};

  for (const [type, files] of Object.entries(filesList)) {
    const fileContents: SourceVitalSignData[] = await Promise.all(
      files.map(async (file) => {
        const response = await axios.get(file.url);
        return response.data;
      })
    );
    vitalSignData[type] = fileContents;
  }

  return { filesList, vitalSignData };
};

// Function to merge Android and iOS data into one array
const mergeDeviceData = (data: SourceVitalSignData): MergedVitalSignData => {
  try {
    const mergedName = data.Android.name || data.Ios.name || "-";
    const mergedUnitType = data.Android.unitType || data.Ios.unitType;

    const mergeValuesWithTimeStamp = (values: any[], timeStamp: string) => {
      return values.map((value) => ({
        ...value,
        timeStampISO: moment(value.timeStampISO),
        timeStamp,
      }));
    };

    const mergedValues = [
      ...mergeValuesWithTimeStamp(data.Android.values, data.Android.timeStamp),
      ...mergeValuesWithTimeStamp(data.Ios.values, data.Ios.timeStamp),
    ];

    return {
      name: mergedName,
      unitType: mergedUnitType,
      values: mergedValues,
    };
  } catch (error) {
    return {
      name: "-",
      unitType: "-",
      values: [],
    };
  }
  
};

// Function to consolidate all vital sign data by type
const consolidateVitalSigns = (
  dataArray: SourceVitalSignData[]
): { [key: string]: MergedVitalSignData } => {
  const consolidatedData: ConsolidatedData = {};

  console.log("dataArray", dataArray)

  dataArray.forEach((data) => {
    const mergedData = mergeDeviceData(data);

    // Check if unitType is undefined and assign it from the first item's unitType
    if (!mergedData.unitType && mergedData.values.length > 0) {
      mergedData.unitType = mergedData.values[0]?.unitType;
    }

    if (!consolidatedData[mergedData.name]) {
      consolidatedData[mergedData.name] = {
        name: mergedData.name,
        unitType: mergedData.unitType,
        values: [],
      };
    }

    consolidatedData[mergedData.name].values.push(...mergedData.values);
  });

  return consolidatedData;
};

// Function to group data by date and type, and calculate the average value for each day and type
const groupAndAverageValues = (
  dataArray: MergedVitalSignData[]
): { [key: string]: { [key: string]: ConvertValue } } => {
  const groupedData: {
    [key: string]: {
      [key: string]: { sum: number; count: number; timeStampISO: moment.Moment; _values: any[] };
    };
  } = {};

  // console.log("dataArray", dataArray)

  dataArray.forEach((data) => {
    // console.log("data", data);
    data.values.forEach((value) => {
      const dateKey = value.timeStampISO.format("YYYY-MM-DD");
      if (!groupedData[dateKey]) {
        groupedData[dateKey] = {};
      }
      if (!groupedData[dateKey][data.name]) {
        groupedData[dateKey][data.name] = {
          sum: 0,
          count: 0,
          timeStampISO: value.timeStampISO,
          _values: [],
        };
      }
      let _values = groupedData[dateKey][data.name]._values;
      _values.push(value);
      groupedData[dateKey][data.name].sum += parseFloat(value.value);
      groupedData[dateKey][data.name].count += 1;
      groupedData[dateKey][data.name]._values = _values;
    });
  });

  const averagedData: { [key: string]: { [key: string]: any } } = {};
  Object.keys(groupedData).forEach((dateKey) => {
    averagedData[dateKey] = {};
    Object.keys(groupedData[dateKey]).forEach((typeKey) => {
      const { sum, count, timeStampISO, _values } = groupedData[dateKey][typeKey];

      if (typeKey === "sleep") {
        // Use the latest value for the "sleep" type
        const latestValue = _values.reduce((latest, current) => {
          return moment(current.timeStampISO).isAfter(latest.timeStampISO) ? current : latest;
        });

        averagedData[dateKey][typeKey] = {
          value: latestValue.value,
          valueName: typeKey,
          timeStampISO: latestValue.timeStampISO,
          timeStamp: latestValue.timeStampISO.format("YYYY-MM-DD"),
          original: _values,
        };
      } else {
        // Average the values for other types
        averagedData[dateKey][typeKey] = {
          value: (sum / count).toFixed(2), // Calculating the average and formatting to 2 decimal places
          valueName: typeKey,
          timeStampISO,
          timeStamp: timeStampISO.format("YYYY-MM-DD"),
          original: _values,
        };
      }
    });
  });

  return averagedData;
};

export { getVitalSignFiles, consolidateVitalSigns, groupAndAverageValues };
