import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import config from '../../../../../config/config'
import { Response, Error } from '../../common'
const HOST = `${config.API_HOST}`

export interface TECSessionSerializer {
    id?: any,
    request?: any,
    appointment_binder?: any,
    treatment_order?: any,
    treatment_date?: any,
    technician_assign?: any,
    technician_actual?: any,
    first?: any,
    assessment?: any,
    progression_note?: any,
    user?: any,
    date?: any,
    start_time?: any,
    patient_id?: any,
    hn?: any,
    patient_full_name?: any,
    patient_full_age?: any,
    patient_birthdate?: any,
    tec_type_label?: any,
    tec_type_name?: any,
    frequency?: any,
    raw_appointment?: any,
    latest_perform_order?: any,
    created?: any,
    appointment?: any,
    note_to_nurse?: any,
    note_to_patient?: any,
    appointment_status_label?: any,
    doctor_note?: any,
    app_encounter?: any,
    doctor?: any,
    encounter?: any,
    encounter_number?: any,
    encounter_type?: any,
    encounter_status_label?: any,
    coverage?: any,
    technician_assign_fullname?: any,
    division?: any,
    real_treatment_order?: any,
    app_treatment_order?: any,
    document_info?: any,
    doctor_orders?: any,
    status_name?: any,
    status_label?: any,
}


/* ['generics.ListCreateAPIView'] */
/* params: 'request_id', 'patient', 'encounter_type', 'created_date', 'gte_created_date', 'order_by', 'technician_assign', 'status', 'exclude_app_status', 'tec_type' */
/* data:  */
export const TECSessions : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: TECSessionSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/TEC/tec-sessions/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/TEC/tec-sessions/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveUpdateAPIView'] */
/* params:  */
/* data:  */
export const TECSessionDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: TECSessionSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: TECSessionSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/TEC/tec-session/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/TEC/tec-session/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/TEC/tec-session/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

