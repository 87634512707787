import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ModApplyPackageUX = (props: any) => {
    return(
      <div
        style={{overflow: "hidden"}}>
        <div
          style={{backgroundColor: "#2185d0", padding: "1rem", display: props.hideHeader ? "none" : "" }}>

          <label
            style={{color:"white", fontSize: "1.2rem"}}>
            เพิ่มรายการ/แก้ไขรายการ Package
          </label>
        </div>
        <div
          className="ui form"
          style={{padding:"0.5rem 1rem 0.25rem"}}>

          <FormGroup>
            <FormField
              width={16}>
              <div
                style={{width: "100%"}}>
                {props.errorMessage}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={13}>
              <label
                style={{minWidth: "max-content"}}>
                รายการแพ็กเกจ
              </label>
              <Dropdown
                disabled={props.disabledPackage || false}
                fluid={true}
                onChange={props.onChangePackage}
                options={props.packageOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.packageId||""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={16}>
              <Table
                data={props.encounterList||[]}
                headers=",Encounter No.,วันเวลาที่รับบริการ,ประเภท,คลินิก/Ward,แพทย์เจ้าของไข้"
                keys="checkbox,number,performed_date,type,division_name,doctor_name"
                minRows={5}
                showPagination={false}
                style={{height: "200px"}}
                widths="60,^80,150,^60,^100,^100">
              </Table>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{marginBottom: 0}}>
            <FormField>
              <Button
                color="grey"
                onClick={props.onSelectAll}
                size="mini"
                style={{backgroundColor: "#515151"}}>
                SELECT ALL
              </Button>
            </FormField>
            <FormField>
              <Button
                color={"grey"}
                onClick={props.onDeselect}
                size="mini">
                DESELECT
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField>
              <div>
                {props.buttonSave}
              </div>
            </FormField>
            <FormField>
              <Button
                basic={true}
                color={"grey"}
                onClick={props.onClose}>
                ปิดหน้าต่าง
              </Button>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

ModApplyPackageUX.displayName = "ModApplyPackageUX";
export default React.memo(ModApplyPackageUX)

export const screenPropsDefault = {}

/* Date Time : Wed Jul 31 2024 15:03:06 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{overflow: \"hidden\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#2185d0\", padding: \"1rem\", display: props.hideHeader ? \"none\" : \"\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการ/แก้ไขรายการ Package"
        },
        "style": {
          "type": "code",
          "value": "{color:\"white\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0.5rem 1rem 0.25rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการแพ็กเกจ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledPackage || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePackage"
        },
        "options": {
          "type": "code",
          "value": "props.packageOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.packageId||\"\""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormGroup",
      "parent": 3,
      "props": {
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 39,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginBottom: 0}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 42,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 45,
      "name": "Table",
      "parent": 44,
      "props": {
        "data": {
          "type": "code",
          "value": "props.encounterList||[]"
        },
        "headers": {
          "type": "value",
          "value": ",Encounter No.,วันเวลาที่รับบริการ,ประเภท,คลินิก/Ward,แพทย์เจ้าของไข้"
        },
        "keys": {
          "type": "value",
          "value": "checkbox,id,performed_date,type,division_name,doctor_name"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        },
        "widths": {
          "type": "value",
          "value": "60,^80,150,^60,^100,^100"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormGroup",
      "parent": 3,
      "props": {
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 46,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 46,
      "props": {
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 43,
      "props": {
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 43,
      "props": {
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 46,
      "props": {
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 51,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "ปิดหน้าต่าง"
        },
        "color": {
          "type": "code",
          "value": "\"grey\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Button",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "SELECT ALL"
        },
        "color": {
          "type": "value",
          "value": "grey"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectAll"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#515151\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Button",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "DESELECT"
        },
        "color": {
          "type": "code",
          "value": "\"grey\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onDeselect"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 55,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": true,
  "name": "ModApplyPackageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
