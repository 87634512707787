import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Checkbox,
  Dropdown,
  Button,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSupplyOrderHistoryUX = (props: any) => {
    return(
      <div
        style={{padding:"5px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",margin:"10px"}}>
          ประวัติการสั่งเวชภัณฑ์
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedPatient}
                label="ชื่อหรือรหัสผู้ป่วย"
                onChange={props.handleChangeChecked("checkedPatient")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{width:"65%"}}>
                {props.patientSearch}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedDoctor}
                label="ชื่อหรือรหัสแพทย์"
                onChange={props.handleChangeChecked("checkedDoctor")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{ width: "65%" }}>
                {props.doctorSearch}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedType}
                label="ประเภท"
                onChange={props.handleChangeChecked("checkedType")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                fluid={true}
                onChange={props.handleChangeValue("selectedType","checkedType")}
                options={props.masterOptions?.supplyType}
                selection={true}
                style={{width:"65%"}}
                value={props.selectedType}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedStatus}
                label="สถานะ"
                onChange={props.handleChangeChecked("checkedStatus")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                fluid={true}
                onChange={props.handleChangeValue("selectedStatus","checkedStatus")}
                options={props.masterOptions?.supplyStatus}
                selection={true}
                style={{width:"65%"}}
                value={props.selectedStatus}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedOrderDivision}
                label="หน่ยงานสั่ง"
                onChange={props.handleChangeChecked("checkedOrderDivision")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                fluid={true}
                onChange={props.handleChangeValue("selectedOrderDivision","checkedOrderDivision")}
                options={props.masterOptions?.division}
                search={true}
                selection={true}
                style={{width:"65%"}}
                value={props.selectedOrderDivision}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedPerformDivision}
                label="หน่วยงานจ่าย"
                onChange={props.handleChangeChecked("checkedPerformDivision")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                fluid={true}
                onChange={props.handleChangeValue("selectedPerformDivision","checkedPerformDivision")}
                options={props.masterOptions?.divisionSupply}
                search={true}
                selection={true}
                style={{width:"65%"}}
                value={props.selectedPerformDivision}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedOrderDate}
                label="วันที่สั่ง"
                onChange={props.handleChangeChecked("checkedOrderDate")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{display:"flex",width:"100%"}}>
                
                <DateTextBox
                  onChange={props.handleChangeDate("selectedOrderStartDate")}
                  style={{width:"50%"}}
                  value={props.selectedOrderStartDate}>
                </DateTextBox>
                <DateTextBox
                  onChange={props.handleChangeDate("selectedOrderEndDate")}
                  style={{marginLeft:"26px",width:"50%"}}
                  value={props.selectedOrderEndDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedUser}
                label="ผู้บันทึก"
                onChange={props.handleChangeChecked("checkedUser")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{ width: "65%" }}>
                {props.employeeCode}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedPerformDate}
                label="วันที่จ่าย"
                onChange={props.handleChangeChecked("checkedPerformDate")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{display:"flex",width:"100%"}}>
                
                <DateTextBox
                  onChange={props.handleChangeDate("selectedPerformStartDate")}
                  style={{width:"50%"}}
                  value={props.selectedPerformStartDate}>
                </DateTextBox>
                <DateTextBox
                  onChange={props.handleChangeDate("selectedPerformEndDate")}
                  style={{marginLeft:"26px",width:"50%"}}
                  value={props.selectedPerformEndDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{flex:"1"}}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Button
                color="yellow"
                onClick={props.onClear}
                size="small">
                ล้างตัวกรอง
              </Button>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{marginTop:"20px"}}>
          
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.supplyOrderHistoryList}
            getTrProps={props.handleGetTrProps}
            headers="รหัส,ประเภท,สถานะ,วันที่สั่ง,หน่วยงานที่สั่ง,ผู้ป่วย,แพทย์,วันที่จ่าย,หน่วยงานที่จ่าย,ผู้บันทึกล่าสุด"
            keys="code,type,statusMod,orderTime,order_div_name,patient,doctor,orderPerformTime,order_perform_div_name, edit_user"
            minRows="10"
            showPagination={false}
            style={{height: "400px",width:"100%"}}
            widths="^60,^150,^150,^150^150,^150,^150,^150,^150,^150">
          </Table>
        </div>
      </div>
    )
}


export default CardSupplyOrderHistoryUX

export const screenPropsDefault = {"patientSearch":"[Patient Search Box]"}

/* Date Time : Mon Nov 04 2024 09:31:12 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:\"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการสั่งเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",margin:\"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"20px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.supplyOrderHistoryList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.handleGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "รหัส,ประเภท,สถานะ,วันที่สั่ง,หน่วยงานที่สั่ง,ผู้ป่วย,แพทย์,วันที่จ่าย,หน่วยงานที่จ่าย,ผู้บันทึกล่าสุด"
        },
        "keys": {
          "type": "value",
          "value": "code,type,statusMod,orderTime,order_div_name,patient,doctor,orderPerformTime,order_perform_div_name, edit_user"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\",width:\"100%\"}"
        },
        "widths": {
          "type": "value",
          "value": "^60,^150,^150,^150^150,^150,^150,^150,^150,^150"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Checkbox",
      "parent": 62,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPatient"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสผู้ป่วย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPatient\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Checkbox",
      "parent": 64,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDoctor"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสแพทย์"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDoctor\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearch"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedType"
        },
        "label": {
          "type": "value",
          "value": "ประเภท"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedType\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Checkbox",
      "parent": 68,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedStatus"
        },
        "label": {
          "type": "value",
          "value": "สถานะ"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedStatus\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 69,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedStatus\",\"checkedStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.supplyStatus"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"65%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStatus"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Checkbox",
      "parent": 70,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOrderDivision"
        },
        "label": {
          "type": "value",
          "value": "หน่ยงานสั่ง"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedOrderDivision\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Checkbox",
      "parent": 72,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPerformDivision"
        },
        "label": {
          "type": "value",
          "value": "หน่วยงานจ่าย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPerformDivision\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedPerformDivision\",\"checkedPerformDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.divisionSupply"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"65%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerformDivision"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "code",
          "value": "props.employeeCode"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Checkbox",
      "parent": 74,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOrderDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่สั่ง"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedOrderDate\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 76,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedUser"
        },
        "label": {
          "type": "value",
          "value": "ผู้บันทึก"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedUser\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 39,
      "name": "DateTextBox",
      "parent": 81,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedOrderStartDate\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"50%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderStartDate"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Checkbox",
      "parent": 78,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPerformDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่จ่าย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPerformDate\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 46,
      "name": "DateTextBox",
      "parent": 82,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedPerformStartDate\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"50%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerformStartDate"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 47,
      "name": "DateTextBox",
      "parent": 82,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedPerformEndDate\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"26px\",width:\"50%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerformEndDate"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Button",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างตัวกรอง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 50,
      "name": "DateTextBox",
      "parent": 81,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedOrderEndDate\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"26px\",width:\"50%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderEndDate"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedType\",\"checkedType\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.supplyType"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"65%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedType"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        },
        "style": {
          "type": "code",
          "value": "{width:\"65%\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 71,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedOrderDivision\",\"checkedOrderDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"65%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderDivision"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",width:\"100%\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",width:\"100%\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 80,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyOrderHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "patientSearch": "[Patient Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
