import HRMService from "../services/HRMService";
import { to } from "../../utils";
// import _ from "../../compat/lodashplus";
export default class HRMManager {
  getProviderInfo = async ({ apiToken, userId } = {}) => {
    let params = {};
    const hrmService = new HRMService();
    const [error, response, network] = await to(
      hrmService.getProviderInfo({
        apiToken,
        userId,
        params
      })
    );
    return [response, error, network];
  };
}
