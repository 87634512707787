import React, { useState, useEffect } from "react";

// UX
import ModSelectQuarterUX from "./ModSelectQuarterUX";
import { useIntl } from "react-intl";

// Types
type ModSelectQuarterProps = {
  // data
  detail?: DetailType;
  // callback
  onSave?: (data: DetailType) => any;
  onClose?: Function;
};

type DetailType = {
  quarter: string;
  year: string;
  startDate: string;
  endDate: string;
};

// Const
const quarterDetailInitial: DetailType = {
  quarter: "",
  year: "",
  startDate: "",
  endDate: "",
};

const ModSelectQuarter = (props: ModSelectQuarterProps) => {
  const intl = useIntl();
  const [quarterDetail, setQuarterDetail] =
    useState<DetailType>(quarterDetailInitial);

  // Effect
  useEffect(() => {
    if (!!props.detail) {
      setQuarterDetail(props.detail);
    }
  }, []);

  // handler
  const handleSave = () => {
    props.onSave?.(quarterDetail);
  };

  const handleChange = (e: any, data: any) => {
    setQuarterDetail({
      ...quarterDetail,
      [data.name]: data.value,
    });
  };

  return (
    <ModSelectQuarterUX
      // data
      quarterDetail={quarterDetail}
      // callback
      onChange={handleChange}
      onSave={handleSave}
      onClose={props.onClose}
      languageUX={props.languageUX}
    />
  );
};

export default React.memo(ModSelectQuarter);
