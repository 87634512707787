import React, { useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";
import CardLayout from "../common/CardLayout";
import { useIntl } from "react-intl";

const CardImagingExamResult = props => {
  const intl = useIntl();
  const {
    getImagingExamResult,
    imagingExameResult,
    closeable,
    toggleable,
    hideHeaderIcon,
    isLoading
  } = props;

  const results = imagingExameResult;
  const columns = [
    {
      Header: "วันเวลาที่สั่ง",
      accessor: "order_time",
      minWidth: 20
    },
    {
      Header: "แพทย์",
      accessor: "doctor_name",
      minWidth: 25
    },
    {
      Header: "Order",
      accessor: "summary",
      minWidth: 30
    },
    {
      Header: "วันเวลาได้รับผล",
      accessor: "reported_datetime",
      minWidth: 20
    },
    {
      id: "result",
      Header: "ผล",
      resizable: false,
      accessor: d => {

        if (props.hideDetail) {
          let index = d.result.search("<button ");
          let button = d.result.slice(index)
          return <center> <div dangerouslySetInnerHTML={{ __html: button }} /> </center>
        } else {
          return <center> <div dangerouslySetInnerHTML={{ __html: d.result }} /> </center>
        }
      },
      minWidth: 50
    }
  ];

  useEffect(() => {
    getImagingExamResult();
  }, []);

  const handleOpenPACS = () => {
    props.onOpenPACS()
  };

  const TableImagingExam = params => {
    return (
      <>
        {/* <div style={{ textAlign: "right" }}>
          <Button
            size="tiny"
            color="green"
            content={intl.formatMessage({ id: "เปิดหน้า PACS" })}
            onClick={handleOpenPACS}
          />
        </div> */}

        <ReactTable
          data={results}
          columns={columns}
          minRows={0}
          showPagination={false}
          sortable={false}
        />
      </>
    );
  };

  const content = (
    <CardLayout
      titleText="Imaing Examination Results"
      closeable={closeable}
      toggleable={toggleable}
      hideHeaderIcon={hideHeaderIcon}
      onClose={props.hideCallback}
    >
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      {results && results.length && results.length > 0 ? (
        <TableImagingExam />
      ) : (
        <div>No Imaing Examination result.</div>
      )}
    </CardLayout>
  );

  return content;
};

CardImagingExamResult.propTypes = {
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  imagingExameResult: PropTypes.array,
  getImagingExamResult: PropTypes.func,
  hideCallback: PropTypes.func,
  hideDetail: PropTypes.bool,
};

CardImagingExamResult.defaultProps = {
  closeable: false,
  toggleable: false,
  hideHeaderIcon: true,
  isLoading: false,
  getImagingExamResult: () => { },
  imagingExameResult: [],
  onOpenPACS: () => { },
  hideCallback: () => { },
  hideDetail: true, // true For PHV, false for DPO,TEC
};
export default CardImagingExamResult;
