import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Input,
  Checkbox,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDrivingCertificateUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1em 0em 0em 1em",  gap: "6.65em"}}>
            <label
              style={{display: "flex",  alignItems: "center", fontWeight: "bold"}}>
              วันที่ตรวจ
            </label>
            <DateTextBox
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-DateTextBox-ExamDate"
              minDate={props.onMinDate}
              onChange={(date: string) => props.onHandleChangeDate("examDate", date)}
              value={props.onExamDate}>
            </DateTextBox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em",  gap: "3.2em"}}>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              หนังสือยืนยันตัวตน
            </label>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-IdentNo"
              readOnly="true"
              style={{width: "22em"}}
              value={props.onIdentNo}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em",  gap: "1.5em"}}>
            <label
              style={{display: "flex",  alignItems: "center", padding: "0em 4.7em 0em 0em"}}>
              น้ำหนัก (kg.)
            </label>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-Weight"
              readOnly="true"
              style={{width: "6em"}}
              value={props.onWeight}>
            </Input>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              ส่วนสูง (cm.)
            </label>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-Height"
              readOnly="true"
              style={{width: "6em"}}
              value={props.onHeight}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em",  gap: "1.5em"}}>
            <label
              style={{display: "flex",  alignItems: "center", padding: "0em 0em 0em 0em"}}>
              ความดันโลหิต (mmHg)
            </label>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-BloodPressure"
              readOnly="true"
              style={{width: "6em"}}
              value={props.onBloodPressure}>
            </Input>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              ชีพจร (bpm)
            </label>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-Pulse"
              readOnly="true"
              style={{width: "6em"}}
              value={props.onPulse}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ีui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
              ความเห็นของแพทย์
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center", padding: "0em 8.5em 0em 0em"}}>
              1. โรคประจำตัว
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em",  gap: "2.5em"}}>
            <Checkbox
              checked={props.onCheckboxOption("isFirstOption", "no")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-No-1"
              label="ไม่มี"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isFirstOption", "no")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Checkbox
              checked={props.onCheckboxOption("isFirstOption", "yes")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-Yes-1"
              label="มี (โปรดระบุ*)"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isFirstOption", "yes")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Input
              disabled={props.onHandleInputDisable("isFirstOption") || props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-Explain-1"
              onChange={(e) => props.onHandleChangeText("explainFirst", e)}
              value={props.onExplainFirst}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center",  padding: "0em 4.5em 0em 0em"}}>
              2. อุบัติเหตุ และการผ่าตัด
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em",  gap: "2.5em"}}>
            <Checkbox
              checked={props.onCheckboxOption("isSecondOption", "no")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-No-2"
              label="ไม่มี"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isSecondOption", "no")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Checkbox
              checked={props.onCheckboxOption("isSecondOption", "yes")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-Yes-2"
              label="มี (โปรดระบุ*)"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isSecondOption", "yes")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Input
              disabled={props.onHandleInputDisable("isSecondOption") || props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-Explain-2"
              onChange={(e) => props.onHandleChangeText("explainSecond", e)}
              value={props.onExplainSecond}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              3. เคยเข้ารับการรักษาในโรงพยาบาล
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em",  gap: "2.5em"}}>
            <Checkbox
              checked={props.onCheckboxOption("isThirdOption", "no")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-No-3"
              label="ไม่มี"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isThirdOption", "no")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Checkbox
              checked={props.onCheckboxOption("isThirdOption", "yes")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-Yes-3"
              label="มี (โปรดระบุ*)"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isThirdOption", "yes")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Input
              disabled={props.onHandleInputDisable("isThirdOption") || props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-Explain-3"
              onChange={(e) => props.onHandleChangeText("explainThird", e)}
              value={props.onExplainThird}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center",  padding: "0em 9.8em 0em 0em"}}>
              4. โรคลมชัก
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em",  gap: "2.5em"}}>
            <Checkbox
              checked={props.onCheckboxOption("isFourthOption", "no")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-No-4"
              label="ไม่มี"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isFourthOption", "no")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Checkbox
              checked={props.onCheckboxOption("isFourthOption", "yes")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-Yes-4"
              label="มี (โปรดระบุ*)"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isFourthOption", "yes")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Input
              disabled={props.onHandleInputDisable("isFourthOption") || props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-Explain-4"
              onChange={(e) => props.onHandleChangeText("explainFourth", e)}
              value={props.onExplainFourth}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center",  padding: "0em 6.7em 0em 0em"}}>
              5. ประวัติอื่นที่สำคัญ
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em",  gap: "2.5em"}}>
            <Checkbox
              checked={props.onCheckboxOption("isFifthOption", "no")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-No-5"
              label="ไม่มี"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isFifthOption", "no")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Checkbox
              checked={props.onCheckboxOption("isFifthOption", "yes")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-Yes-5"
              label="มี (โปรดระบุ*)"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "isFifthOption", "yes")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Input
              disabled={props.onHandleInputDisable("isFifthOption") || props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-Explain-5"
              onChange={(e) => props.onHandleChangeText("explainFifth", e)}
              value={props.onExplainFifth}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em",  gap: "4em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
              สภาพร่างกายทั่วไปอยู่ในเกณฑ์
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em",  gap: "2.5em"}}>
            <Checkbox
              checked={props.onCheckboxOption("summaryCheck", "normal")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-Normal"
              label="ปกติ"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "summaryCheck", "normal")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Checkbox
              checked={props.onCheckboxOption("summaryCheck", "abnormal")}
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-Checkbox-Abnormal"
              label="ผิดปกติ"
              onChange={(e) => props.onHandleSelectedCheckbox(e, "summaryCheck", "abnormal")}
              style={{display: "flex",  alignItems: "center"}}>
            </Checkbox>
            <Input
              disabled={props.onHandleInputDisable("summaryCheck") || props.onHandleDisableForm}
              id="CardDrivingCertificate-Input-Explain-Summary"
              onChange={(e) => props.onHandleChangeText("explainSummary", e)}
              style={{width: "18em"}}
              value={props.onExplainSummary}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex", alignItems: "center",  fontSize: "1em"}}>
              สรุปความเห็นและข้อแนะนำของแพทย์
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 3em"}}>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardDrivingCertificate-TextArea-Comment"
              onChange={(e) => props.onHandleChangeText("comment", e)}
              rows="15"
              value={props.onComment}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", justifyContent: "flex-end"}}>
            <label>
              {props.onCloseSelected}
            </label>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardDrivingCertificateUX

export const screenPropsDefault = {"language":"TH"}

/* Date Time : Wed Oct 09 2024 12:08:06 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1em 0em 0em 1em\",  gap: \"6.65em\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "label",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ตรวจ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 196,
      "name": "DateTextBox",
      "parent": 194,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-DateTextBox-ExamDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"examDate\", date)"
        },
        "value": {
          "type": "code",
          "value": "props.onExamDate"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\",  gap: \"3.2em\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "label",
      "parent": 197,
      "props": {
        "children": {
          "type": "value",
          "value": "หนังสือยืนยันตัวตน"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Input",
      "parent": 197,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-IdentNo"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onIdentNo"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\",  gap: \"1.5em\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "label",
      "parent": 200,
      "props": {
        "children": {
          "type": "value",
          "value": "น้ำหนัก (kg.)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", padding: \"0em 4.7em 0em 0em\"}"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "Input",
      "parent": 200,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-Weight"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onWeight"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "label",
      "parent": 200,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนสูง (cm.)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "Input",
      "parent": 200,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-Height"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onHeight"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\",  gap: \"1.5em\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "label",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": "ความดันโลหิต (mmHg)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", padding: \"0em 0em 0em 0em\"}"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "Input",
      "parent": 205,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-BloodPressure"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onBloodPressure"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "label",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": "ชีพจร (bpm)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "Input",
      "parent": 205,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-Pulse"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onPulse"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ีui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "label",
      "parent": 210,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเห็นของแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "label",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": "1. โรคประจำตัว"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", padding: \"0em 8.5em 0em 0em\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "Checkbox",
      "parent": 246,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isFirstOption\", \"no\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-No-1"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isFirstOption\", \"no\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "Checkbox",
      "parent": 246,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isFirstOption\", \"yes\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-Yes-1"
        },
        "label": {
          "type": "value",
          "value": "มี (โปรดระบุ*)"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isFirstOption\", \"yes\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Input",
      "parent": 246,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleInputDisable(\"isFirstOption\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-Explain-1"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"explainFirst\", e)"
        },
        "value": {
          "type": "code",
          "value": "props.onExplainFirst"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "label",
      "parent": 217,
      "props": {
        "children": {
          "type": "value",
          "value": "2. อุบัติเหตุ และการผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\",  padding: \"0em 4.5em 0em 0em\"}"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "Checkbox",
      "parent": 247,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isSecondOption\", \"no\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-No-2"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isSecondOption\", \"no\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 220,
      "name": "Checkbox",
      "parent": 247,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isSecondOption\", \"yes\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-Yes-2"
        },
        "label": {
          "type": "value",
          "value": "มี (โปรดระบุ*)"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isSecondOption\", \"yes\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "Input",
      "parent": 247,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleInputDisable(\"isSecondOption\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-Explain-2"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"explainSecond\", e)"
        },
        "value": {
          "type": "code",
          "value": "props.onExplainSecond"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "label",
      "parent": 222,
      "props": {
        "children": {
          "type": "value",
          "value": "3. เคยเข้ารับการรักษาในโรงพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "Checkbox",
      "parent": 248,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isThirdOption\", \"no\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-No-3"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isThirdOption\", \"no\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "Checkbox",
      "parent": 248,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isThirdOption\", \"yes\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-Yes-3"
        },
        "label": {
          "type": "value",
          "value": "มี (โปรดระบุ*)"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isThirdOption\", \"yes\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "Input",
      "parent": 248,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleInputDisable(\"isThirdOption\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-Explain-3"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"explainThird\", e)"
        },
        "value": {
          "type": "code",
          "value": "props.onExplainThird"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 229,
      "name": "label",
      "parent": 227,
      "props": {
        "children": {
          "type": "value",
          "value": "4. โรคลมชัก"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\",  padding: \"0em 9.8em 0em 0em\"}"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": null,
      "id": 230,
      "name": "label",
      "parent": 228,
      "props": {
        "children": {
          "type": "value",
          "value": "5. ประวัติอื่นที่สำคัญ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\",  padding: \"0em 6.7em 0em 0em\"}"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "Checkbox",
      "parent": 249,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isFourthOption\", \"no\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-No-4"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isFourthOption\", \"no\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "Checkbox",
      "parent": 249,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isFourthOption\", \"yes\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-Yes-4"
        },
        "label": {
          "type": "value",
          "value": "มี (โปรดระบุ*)"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isFourthOption\", \"yes\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "Checkbox",
      "parent": 250,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isFifthOption\", \"no\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-No-5"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isFifthOption\", \"no\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "Checkbox",
      "parent": 250,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"isFifthOption\", \"yes\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-Yes-5"
        },
        "label": {
          "type": "value",
          "value": "มี (โปรดระบุ*)"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"isFifthOption\", \"yes\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "Input",
      "parent": 249,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleInputDisable(\"isFourthOption\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-Explain-4"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"explainFourth\", e)"
        },
        "value": {
          "type": "code",
          "value": "props.onExplainFourth"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "Input",
      "parent": 250,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleInputDisable(\"isFifthOption\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-Explain-5"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"explainFifth\", e)"
        },
        "value": {
          "type": "code",
          "value": "props.onExplainFifth"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\",  gap: \"4em\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\"}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 240,
      "name": "label",
      "parent": 237,
      "props": {
        "children": {
          "type": "value",
          "value": "สภาพร่างกายทั่วไปอยู่ในเกณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "Checkbox",
      "parent": 251,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"summaryCheck\", \"normal\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-Normal"
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"summaryCheck\", \"normal\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "Checkbox",
      "parent": 251,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckboxOption(\"summaryCheck\", \"abnormal\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Checkbox-Abnormal"
        },
        "label": {
          "type": "value",
          "value": "ผิดปกติ"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleSelectedCheckbox(e, \"summaryCheck\", \"abnormal\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "Input",
      "parent": 251,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleInputDisable(\"summaryCheck\") || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-Input-Explain-Summary"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"explainSummary\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"18em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onExplainSummary"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "label",
      "parent": 238,
      "props": {
        "children": {
          "type": "value",
          "value": "สรุปความเห็นและข้อแนะนำของแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",  fontSize: \"1em\"}"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "TextArea",
      "parent": 239,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDrivingCertificate-TextArea-Comment"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"comment\", e)"
        },
        "rows": {
          "type": "value",
          "value": "15"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.onComment"
        }
      },
      "seq": 245,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\",  gap: \"2.5em\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\",  gap: \"2.5em\"}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\",  gap: \"2.5em\"}"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\",  gap: \"2.5em\"}"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\",  gap: \"2.5em\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 3em\",  gap: \"2.5em\"}"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "FormGroup",
      "parent": 193,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "label",
      "parent": 252,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onCloseSelected}"
        }
      },
      "seq": 253,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardDrivingCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "language": "TH"
  },
  "width": 55
}

*********************************************************************************** */
