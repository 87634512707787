import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Button,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDrugCounsellingUX = (props: any) => {
    return(
      <div>
        <div
          style={{padding: "20px", display: "flex", flexDirection: "column"}}>
          
          <div
            style={{padding:"20px 20px", fontSize: "20px", fontWeight: "bold", color: "#1b75bb"}}>
            ประวัติการให้คำปรึกษาการใช้ยา
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div>
                  วันที่ให้คำปรึกษา
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  
                  <DateTextBox
                    onChange={(date: any) => {props.onChange("filterStartDate", date)}}
                    style={{width: "100%"}}
                    value={props.filterStartDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField>
                <div>
                  ถึง
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  
                  <DateTextBox
                    onChange={(date: any) => {props.onChange("filterEndDate", date)}}
                    style={{width: "100%"}}
                    value={props.filterEndDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <div>
                  หัวข้อ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  onChange={(e:any, data: any) => {props.onChange("filterTitle", data.value)}}
                  value={props.filterTitle}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div>
                  ผู้ให้คำปรึกษา
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{width: "100%"}}>
                  {props.advisorSearch}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                inline={true}
                width={13}>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{display: "flex", justifyContent: "space-evenly"}}>
                  
                  <Button
                    color="blue"
                    onClick={props.onSearch}>
                    ค้นหา
                  </Button>
                  <Button
                    color="green"
                    onClick={props.onAddItem}>
                    เพิ่มรายการ
                  </Button>
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div
            style={{height: "275px"}}>
            
            <Table
              data={props.drugCounsellingList}
              defaultPageSize={5}
              headers="วันที่ให้คำปรึกษา,หัวข้อ,รายละเอียดการให้คำปรึกษา,ผู้ให้คำปรึกษา,แก้ไข/ลบ"
              keys="date,title,description,advisor_name,_action"
              minRows={5}
              pageSize={5}
              showPagination={false}
              style={{height: "270px"}}
              widths="200,300,,200,150">
            </Table>
          </div>
          <div>
            {props.pagination}
          </div>
          <div
            style={{padding: "10px", border: "1px solid #1b75bb", borderRadius: "10px", display: props.showDetail? "": "none"}}>
            
            <div>
              {props.closeIcon}
            </div>
            <div
              style={{display: "flex", alignItems: "center"}}>
              
              <div
                style={{padding: "5px"}}>
                วันที่ให้คำปรึกษา
              </div>
              <div>
                
                <DateTextBox
                  onChange={(date: string) => {props.onChange("adviseDate", date)}}
                  style={{width: "100%"}}
                  value={props.adviseDate}>
                </DateTextBox>
              </div>
              <div
                style={{padding: "5px"}}>
                หัวข้อ
              </div>
              <div
                style={{width:"60%"}}>
                
                <Input
                  onChange={(e:any, data: any) => {props.onChange("title", data.value)}}
                  style={{width: "100%"}}
                  value={props.title}>
                </Input>
              </div>
            </div>
            <div
              style={{padding: "10px 5px"}}>
              รายละเอียดการให้คำปรึกษา
            </div>
            <div
              style={{paddingRight: "10px"}}>
              
              <TextArea
                onChange={(e:any, data: any) => {props.onChange("description", data.value)}}
                rows="5"
                style={{resize: "none", width: "100%"}}
                value={props.description}>
              </TextArea>
            </div>
            <div
              style={{padding: "10px 5px", display: "flex",  justifyContent: "flex-end", alignItems: "center"}}>
              
              <div
                style={{padding: "5px"}}>
                ผู้ให้คำปรึกษา
              </div>
              <div>
                {props.advisorCode}
              </div>
              <div>
                {props.saveButton}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardDrugCounsellingUX

export const screenPropsDefault = {}

/* Date Time : Wed Feb 22 2023 15:33:01 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px\", display: \"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการให้คำปรึกษาการใช้ยา"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"20px 20px\", fontSize: \"20px\", fontWeight: \"bold\", color: \"#1b75bb\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"275px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pagination"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", border: \"1px solid #1b75bb\", borderRadius: \"10px\", display: props.showDetail? \"\": \"none\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 3,
      "props": {
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 8,
      "props": {
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ให้คำปรึกษา"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "หัวข้อ"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ให้คำปรึกษา"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Input",
      "parent": 15,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e:any, data: any) => {props.onChange(\"filterTitle\", data.value)}"
        },
        "value": {
          "type": "code",
          "value": "props.filterTitle"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.advisorSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 28,
      "name": "DateTextBox",
      "parent": 26,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: any) => {props.onChange(\"filterStartDate\", date)}"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterStartDate"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 30,
      "name": "DateTextBox",
      "parent": 29,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: any) => {props.onChange(\"filterEndDate\", date)}"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterEndDate"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-evenly\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Button",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการ"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddItem"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 36,
      "name": "Table",
      "parent": 4,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugCounsellingList"
        },
        "defaultPageSize": {
          "type": "code",
          "value": "5"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ให้คำปรึกษา,หัวข้อ,รายละเอียดการให้คำปรึกษา,ผู้ให้คำปรึกษา,แก้ไข/ลบ"
        },
        "keys": {
          "type": "value",
          "value": "date,title,description,advisor_name,_action"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "pageSize": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"270px\"}"
        },
        "widths": {
          "type": "value",
          "value": "200,300,,200,150"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการให้คำปรึกษา"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 5px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 5px\", display: \"flex\",  justifyContent: \"flex-end\", alignItems: \"center\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ให้คำปรึกษา"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "หัวข้อ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"60%\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 45,
      "name": "DateTextBox",
      "parent": 42,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string) => {props.onChange(\"adviseDate\", date)}"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.adviseDate"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Input",
      "parent": 44,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e:any, data: any) => {props.onChange(\"title\", data.value)}"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.title"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "TextArea",
      "parent": 39,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e:any, data: any) => {props.onChange(\"description\", data.value)}"
        },
        "rows": {
          "type": "value",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": "{resize: \"none\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.description"
        }
      },
      "seq": 47,
      "void": true
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ให้คำปรึกษา"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.advisorCode"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.saveButton"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.closeIcon"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 37,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugCounsellingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
