import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField
} from 'semantic-ui-react'

const CardConsultOrderDetailUX = (props: any) => {
    return(
      <div
        style={{padding: "15px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0px 20px"}}>
          
          <div
            style={{color: "#0D71BB",  fontSize: "18px", fontWeight: "bold"}}>
            รายการส่ง Consult
          </div>
          <div>
            {props.labelStatus}
          </div>
        </div>
        <div
          style={{border: "1px solid #ECECEC", position: "relative", padding: "20px 10px 16px"}}>
          
          <div
            style={{position: "absolute",  fontWeight: "bold", color: "#0D71BB", padding: "10px", backgroundColor: "white",  bottom: "60px",  left: "58px", transform: "translate(-50%, -50%)",   flexWrap: "nowrap",}}>
            ผู้ส่งปรึกษา:
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={8}>
                <div>
                  แพทย์:
                </div>
                <div>
                  {props.orderDoctor}
                </div>
              </FormField>
              <FormField
                width={8}>
                <div>
                  แผนก:
                </div>
                <div>
                  {props.orderDivision}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline="true">
              <FormField
                width={8}>
                <div>
                  วันที่ส่งปรึกษา:
                </div>
                <div>
                  {props.orderDateTime}
                </div>
              </FormField>
              <FormField
                width={8}>
                <div>
                  Encounter No.
                </div>
                <div>
                  {props.orderEncounterNo}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{border: "1px solid #ECECEC", position: "relative", padding: "20px 10px 16px", marginTop: "30px"}}>
          
          <div
            style={{position: "absolute",  fontWeight: "bold", color: "#0D71BB", padding: "10px", backgroundColor: "white",  bottom: "100px",  left: "100px", transform: "translate(-50%, -50%)",   flexWrap: "nowrap",}}>
            รายละเอียดการส่งปรึกษา:
          </div>
          <div
            style={{textAlign: "left", maxHeight: "100px", height: "100px", overflow: "auto", whiteSpace: "pre-line"}}>
            {props.consultedNote}
          </div>
        </div>
        <div
          style={{border: "1px solid #ECECEC", position: "relative", padding: "20px 10px 16px", marginTop: "30px"}}>
          
          <div
            style={{position: "absolute",  fontWeight: "bold", color: "#0D71BB", padding: "10px", backgroundColor: "white",  bottom: "60px",  left: "58px", transform: "translate(-50%, -50%)",   flexWrap: "nowrap",}}>
            ผู้รับปรึกษา:
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={8}>
                <div>
                  แพทย์:
                </div>
                <div>
                  {props.consultedDoctor}
                </div>
              </FormField>
              <FormField
                width={8}>
                <div>
                  แผนก:
                </div>
                <div>
                  {props.consultedDivision}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline="true">
              <FormField
                width={8}>
                <div>
                  วันที่นัดส่งปรึกษา:
                </div>
                <div>
                  {props.consultedDateTime}
                </div>
              </FormField>
              <FormField
                width={8}>
                <div>
                  Encounter No.
                </div>
                <div>
                  {props.consultedEncounterNo}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{padding: "15px 10px", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          {props.actionButton}
        </div>
      </div>
    )
}


export default CardConsultOrderDetailUX

export const screenPropsDefault = {}

/* Date Time : Thu Oct 19 2023 10:15:34 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\", padding: \"10px 0px 20px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border: \"1px solid #ECECEC\", position: \"relative\", padding: \"20px 10px 16px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการส่ง Consult"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0D71BB\",  fontSize: \"18px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.labelStatus"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ส่งปรึกษา:"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",  fontWeight: \"bold\", color: \"#0D71BB\", padding: \"10px\", backgroundColor: \"white\",  bottom: \"60px\",  left: \"58px\", transform: \"translate(-50%, -50%)\",   flexWrap: \"nowrap\",}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 8,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 9,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 10,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 10,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์:"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderDoctor"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก:"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderDivision"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ส่งปรึกษา:"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderDateTime"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter No."
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderEncounterNo"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border: \"1px solid #ECECEC\", position: \"relative\", padding: \"20px 10px 16px\", marginTop: \"30px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้รับปรึกษา:"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",  fontWeight: \"bold\", color: \"#0D71BB\", padding: \"10px\", backgroundColor: \"white\",  bottom: \"60px\",  left: \"58px\", transform: \"translate(-50%, -50%)\",   flexWrap: \"nowrap\",}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormGroup",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์:"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.consultedDoctor"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 26,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก:"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.consultedDivision"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormGroup",
      "parent": 25,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 33,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดส่งปรึกษา:"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "code",
          "value": "props.consultedDateTime"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 33,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter No."
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "props.consultedEncounterNo"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border: \"1px solid #ECECEC\", position: \"relative\", padding: \"20px 10px 16px\", marginTop: \"30px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการส่งปรึกษา:"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",  fontWeight: \"bold\", color: \"#0D71BB\", padding: \"10px\", backgroundColor: \"white\",  bottom: \"100px\",  left: \"100px\", transform: \"translate(-50%, -50%)\",   flexWrap: \"nowrap\",}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.consultedNote"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"left\", maxHeight: \"100px\", height: \"100px\", overflow: \"auto\", whiteSpace: \"pre-line\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.actionButton"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px 10px\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\"}"
        }
      },
      "seq": 43,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardConsultOrderDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
