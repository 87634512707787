import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const ModDrugRecommendationUX = (props: any) => {
    return(
      <div>
        <div
          style={{backgroundColor: "#066cbb", color: "white", fontWeight: "bold", fontSize: "22px", padding: "15px"}}>
          ใบคำแนะนำสำหรับการใช้ยา
        </div>
        <div>
          {props.drugRecommendations}
        </div>
        <div
          style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "20px"}}>
          
          <Button
            className="inverted"
            color="green"
            disabled={props.disableApprove}
            onClick={props.onApprove}
            style={{marginRight: "15%", display: props.haveData? "": "none" }}>
            ใช่
          </Button>
          <Button
            className="inverted"
            color="red"
            onClick={props.onCancel}>
            {props.haveData?  "ไม่" : "ปิดหน้าต่าง"}
          </Button>
        </div>
      </div>
    )
}

export default ModDrugRecommendationUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 16 2023 14:06:57 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบคำแนะนำสำหรับการใช้ยา"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#066cbb\", color: \"white\", fontWeight: \"bold\", fontSize: \"22px\", padding: \"15px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugRecommendations"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"20px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ใช่"
        },
        "className": {
          "type": "value",
          "value": "inverted"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableApprove"
        },
        "onClick": {
          "type": "code",
          "value": "props.onApprove"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"15%\", display: props.haveData? \"\": \"none\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.haveData?  \"ไม่\" : \"ปิดหน้าต่าง\""
        },
        "className": {
          "type": "value",
          "value": "inverted"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 5,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 65,
  "isMounted": false,
  "memo": false,
  "name": "ModDrugRecommendationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
