import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Segment, Dimmer, Loader } from 'semantic-ui-react';
import ErrorMessage from "../common/ErrorMessage";
import { useIntl } from "react-intl";

const ModConfirm = (props:any) => {
  const intl = useIntl();

  const handleButtonLeftClick = () => {
    props.onButtonLeftClick();
  }

  const handleButtonRightClick = () => {
    props.onButtonRightClick();
  }

  return (
    <Modal
      open={props.open}
      size={props.size}
    >
      <Segment inverted className={"modHeader " + props.titleColor} style={styles.header}>
        {props.titleName}
      </Segment>
      <Dimmer.Dimmable dimmed={props.loading} style={{height: "100%"}} >
        <Dimmer active={props.loading} inverted>
          <Loader inverted />
        </Dimmer>
      <Segment padded align="center" className="modContent">
        <ErrorMessage error={props.error}/>
        <div>{props.alertText}</div>
        <div style={{ marginTop: 20 }}>
          <Button  
            loading={props.buttonLeftLoading} 
            disabled={props.buttonRightLoading}
            color={props.buttonLeftColor} 
            onClick={handleButtonLeftClick}>
            {props.buttonLeft}
          </Button>
          <Button 
            loading={props.buttonRightLoading} 
            disabled={props.buttonLeftLoading}
            color={props.buttonRightColor} 
            onClick={handleButtonRightClick}>
            {props.buttonRight}
          </Button>
        </div>
      </Segment>
      </Dimmer.Dimmable>
    </Modal >
  )
} 

const styles = {
  header: {
    lineHeight: "1.3em"
  },
  button: {
    marginTop: "10px"
  }
};

ModConfirm.defaultProps = {
  open: false,
  size: "mini",
  titleName: "แจ้งเตือน",
  titleColor: "blue",
  alertText: "",
  buttonLeft: "ยกเลิก",
  buttonLeftColor: "grey",
  onButtonLeftClick: () => {},
  buttonRight: "ตกลง",
  buttonRightColor: "blue",
  onButtonRightClick: () => {},
  buttonLeftLoading: false,
  buttonRightLoading: false,
  loading: false,
  error: null,
};

ModConfirm.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.string,
  titleName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleColor:  PropTypes.string,
  alertText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonLeftColor: PropTypes.string,
  onButtonLeftClick: PropTypes.func,
  buttonRight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonRightColor: PropTypes.string,
  onButtonRightClick: PropTypes.func,
};

export default ModConfirm;