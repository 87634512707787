import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { Button, Modal, Icon } from "semantic-ui-react";
import ModInfo from "./ModInfo";
import config from "config/config";
import { useIntl } from "react-intl";

type WebcamComponentProps = {
  open?: boolean;
  onClose?: () => void;
  setNewImage?: (value: string) => void;
  isCheckSize?: boolean;
};

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user"
};

const WebcamComponent = (props: WebcamComponentProps) => {
  const intl = useIntl();
  const [isCapture, setIsCapture] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [opneModInfo, setOpneModInfo] = useState<boolean>(false);

  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    setIsCapture(true);
  }, [webcamRef]);

  const confirmImage = () => {
    props.setNewImage?.(imageSrc)
    props.onClose?.()
    setIsCapture(false)
  }

  const handleCheckSize = () => {
    const img = document.createElement("img");
    img.onload = function handleLoad() {
      // console.log(`Width: ${img.width}, Height: ${img.height}`);
      if (img.width > config?.CU_UPLOAD_GALLERY?.width || img.height > config?.CU_UPLOAD_GALLERY?.height) {
        setOpneModInfo(true);
      } else {
        props.setNewImage?.(imageSrc);
        props.onClose?.();
        setIsCapture(false)
      }
    };
    img.src = imageSrc;
  };

  const handleCloseModInfo = () => {
    setOpneModInfo(false);
    setIsCapture(false)
    props.setNewImage?.("");
  };

  return (
    <>
      <Modal open={props.open} basic size="large">
        {!isCapture ? (
          <>
            <Modal.Header style={{ textAlign: "center" }}>Take a photo</Modal.Header>
            <Modal.Description style={{ justifyContent: "center", textAlign: "center" }}>
              {props.isCheckSize && (
                <div style={{ color: "red", fontSize: "16px", fontWeight: "bold", paddingBottom: "20px" }}>
                  <Icon className="exclamation circle" color="red" size="large" />
                  {config?.CU_UPLOAD_GALLERY?.alertText}
                </div>
              )}

              <Webcam
                audio={false}
                width={props.isCheckSize ? "100%" : 640}
                height={props.isCheckSize ? "100%" : 480}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  ...videoConstraints,
                  ...(props.isCheckSize && {
                    width: "100%",
                    height: "100%",
                  }),
                }}
              />
            </Modal.Description>
            <Modal.Actions style={{ textAlign: "center" }}>
              <Button color="green" onClick={capture} inverted>
                <Icon name="camera" /> Capture photo
              </Button>
              <Button color="red" onClick={props.onClose} inverted>
                <Icon name="cancel" /> Cancel
              </Button>
            </Modal.Actions>
          </>
        ) : (
          <>
            <Modal.Header style={{ textAlign: "center" }}>Please confirmed this Image</Modal.Header>
            <Modal.Description style={{ textAlign: "center" }}>
              <img src={imageSrc} />
            </Modal.Description>

            <Modal.Actions style={{ textAlign: "center" }}>
              <Button color="green" onClick={props.isCheckSize ? handleCheckSize : confirmImage} inverted>
                <Icon name="check" /> OK
              </Button>
              <Button
                color="red"
                onClick={() => {
                  setIsCapture(false);
                }}
                inverted
              >
                <Icon name="cancel" /> Cancel
              </Button>
            </Modal.Actions>
          </>
        )}
      </Modal>

      <ModInfo
        open={opneModInfo}
        size="tiny"
        titleColor={"red"}
        alertText={intl.formatMessage({ id: "ไม่สามารถอัพโหลดภาพได้ เนื่องจากไฟล์เกินขนาดจำกัดของระบบ" })}        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
        closeOnDimmerClick
        style={{ margin: "auto" }}
        btnText={intl.formatMessage({ id: "ปิด" })}
        buttonStyle={{ marginTop: "20px" }}
        buttonColor={"blue"}
      />
    </>
  );
};

WebcamComponent.defaultProps = {
  open: false,
  onClose: () => {},
  setNewImage: () => {},
  isCheckSize: false,
};

export default WebcamComponent;
