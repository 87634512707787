import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ANES_ChargeItem_SetupTemplate_Treatment = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{height: "400px",width:"100%",display:"flex",flexDirection: "column", padding: "0px 0rem 0.5rem 1rem"}}>

        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(35, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
          <div
            style={{"gridRow":"2/5","gridColumn":"1/51","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <div
              style={{width:"100%", flexGrow:1}}>

              <label
                style={{fontSize: "22px", fontWeight:"bold"}}>
                {props.languageUX === "en" ? "Edit template : " : "ชุดรายการค่าใช้จ่ายที่กำลังแก้ไข : "}
              </label>
              <label
                style={{fontSize: "16px", fontWeight:"normal"}}>
                {props.template?.name}
              </label>
            </div>
          </div>
          <div
            style={{"gridRow":"6/8","gridColumn":"2/33","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <div
              style={{width:"100%",flexGrow:9, flexShrink:4}}>

              <label
                style={{fontWeight:"bold"}}>
                รายการหัตถการ ที่เลือก :
              </label>
            </div>
          </div>
          <div
            style={{ gridRow: "6/8", gridColumn: "10/33", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>

            <Input
              fluid={true}
              onChange={props.onTreatmentSearchTextChange}
              value={props.data?.treatmentSelected?.name_code || props.data?.treatmentSearch || ""}>
            </Input>
            <div
              style={{ display: props.showSearchResult ? "block" : "none",  position: "absolute", zIndex: 100, width: "80%", height: "400px" }}>

              <Table
                data={props.treatmentSearchResult}
                getTrProps={props.treatmentRowProps}
                headers="Code,Name"
                keys="code,name"
                minRows={8}
                showPagination={false}
                style={{ height: "250px" }}>
              </Table>
            </div>
          </div>
          <div
            style={{ gridRow: "32/34", gridColumn: "2/6", border: "solid 0px rgb(200, 200, 200, 0.5)" }}>
            <label
              style={{fontWeight:"bold"}}>
              Remark
            </label>
          </div>
          <div
            style={{"gridRow":"32/35","gridColumn":"6/22","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <Input
              name="remark"
              onChange={props.onChangeTreatmentANS}
              style={{height:"100%",width:"100%"}}
              value={props.data?.remark || ""}>
            </Input>
          </div>
          <div
            style={{"gridRow":"32/34","gridColumn":"37/42","border":"solid 1px rgb(200, 200, 200, 0.5)"}}>
            <div>
              {props.buttonCreateTreatment}
            </div>
          </div>
          <div
            style={{"gridRow":"32/34","gridColumn":"43/50","border":"solid 1px rgb(200, 200, 200, 0.5)"}}>
            <Button
              color="red"
              onClick={props.onCancelEdit}
              style={{width:"100%", flexShrink: 9 }}>
              ยกเลิกการแก้ไข
            </Button>
          </div>
          <div
            style={{"gridRow":"28/30","gridColumn":"2/6","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <label
              style={{fontWeight:"bold"}}>
              Eligibility type
            </label>
          </div>
          <div
            style={{"gridRow":"28/31","gridColumn":"6/22","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <Dropdown
              name="eligibilityType"
              onChange={props.onChangeTreatmentANS}
              options={props.masterOptions?.claimImaging || []}
              selection={true}
              style={{width:"100%",height:"100%"}}
              value={props.data?.eligibilityType}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "12/14", gridColumn: "2/6", border: "solid 0px rgb(200, 200, 200, 0.5)" }}>
            <label
              style={{fontWeight:"bold"}}>
              ระบุ ICD9
            </label>
          </div>
          <div
            style={{"gridRow":"12/15","gridColumn":"6/22","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <Input
              fluid={true}
              onChange={props.onICD9SearchTextChange}
              style={{width:"100%"}}
              value={props.dataICD9?.treatmentSelected?.icdterm || props.dataICD9?.treatmentSearch || ""}>
            </Input>
            <div
              style={{ display: props.showSearchICD9 ? "block" : "none",  position: "absolute", zIndex: 100, width: "80%", height: "400px" }}>
              {}
              <Table
                data={props.icd9SearchResult}
                getTrProps={props.icd9RowProps}
                headers="Medical Term,ICD9CM Term,ICD9CM Code"
                keys="medterm,icdterm,icdcode"
                minRows={8}
                showPagination={false}
                style={{ height: "250px" }}>
              </Table>
            </div>
          </div>
          <div
            style={{"gridRow":"16/18","gridColumn":"2/6","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <label
              style={{fontWeight:"bold"}}>
              ผู้ทำหัตถการ
            </label>
          </div>
          <div
            style={{"gridRow":"16/19","gridColumn":"6/22","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <Dropdown
              name="performer"
              onChange={props.onChangeTreatmentANS}
              options={props.performerOptions || []}
              selection={true}
              style={{width:"100%",height:"100%"}}
              value={props.data?.performer}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "20/22", gridColumn: "2/6", border: "solid 0px rgb(200, 200, 200, 0.5)" }}>
            <label
              style={{fontWeight:"bold"}}>
              รายละเอียดเพิ่มเติม
            </label>
          </div>
          <div
            style={{"gridRow":"20/23","gridColumn":"6/22","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <Input
              name="orderDetail"
              onChange={props.onChangeTreatmentANS}
              style={{height:"100%",width:"100%"}}
              value={props.data?.orderDetail || ""}>
            </Input>
          </div>
          <div
            style={{ gridRow: "24/26", gridColumn: "2/6", border: "solid 0px rgb(200, 200, 200, 0.5)" }}>
            <label
              style={{fontWeight:"bold"}}>
              อุปกรณ์
            </label>
          </div>
          <div
            style={{"gridRow":"24/27","gridColumn":"6/22","border":"solid 0px rgb(200, 200, 200, 0.5)"}}>
            <Input
              name="equipmentDetail"
              onChange={props.onChangeTreatmentANS}
              style={{height:"100%",width:"100%"}}
              value={props.data?.equipmentDetail || ""}>
            </Input>
          </div>
          <div
            style={{ gridRow: "12/14", gridColumn: "23/30", border: "solid 0px rgb(200, 200, 200, 0.5)" }}>
            <Button
              color="blue"
              onClick={props?.getICD9}
              style={{display: props.dataICD9?.treatmentSelected?.icdterm ? "none" : "", width:"100%", flexShrink: 9 }}>
              ค้นหา ICD9
            </Button>
            <Button
              color="red"
              onClick={props?.removeICDterm}
              style={{display: !props.dataICD9?.treatmentSelected?.icdterm ? "none" : "", width:"100%", flexShrink: 9 }}>
              ยกเลิกการเลือก
            </Button>
          </div>
        </div>
      </div>
    )
}


export default ANES_ChargeItem_SetupTemplate_Treatment

export const screenPropsDefault = {}

/* Date Time : Mon Aug 26 2024 12:49:07 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\",width:\"100%\",display:\"flex\",flexDirection: \"column\", padding: \"0px 0rem 0.5rem 1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexGrow:1}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ชุดรายการค่าใช้จ่ายที่กำลังแก้ไข : ",
          "valueEN": "Edit template : "
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"22px\", fontWeight:\"bold\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",flexGrow:9, flexShrink:4}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.template?.name"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight:\"normal\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการหัตถการ ที่เลือก : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(35, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/5\",\"gridColumn\":\"1/51\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"6/8\",\"gridColumn\":\"2/33\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/34\", gridColumn: \"2/6\", border: \"solid 0px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"32/35\",\"gridColumn\":\"6/22\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Input",
      "parent": 64,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentANS"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.remark || \"\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"32/34\",\"gridColumn\":\"37/42\",\"border\":\"solid 1px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"32/34\",\"gridColumn\":\"43/50\",\"border\":\"solid 1px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการแก้ไข"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelEdit"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexShrink: 9 }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"28/30\",\"gridColumn\":\"2/6\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "Eligibility type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"28/31\",\"gridColumn\":\"6/22\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "name": {
          "type": "value",
          "value": "eligibilityType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentANS"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.claimImaging || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.eligibilityType"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/14\", gridColumn: \"2/6\", border: \"solid 0px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ ICD9"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"12/15\",\"gridColumn\":\"6/22\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Input",
      "parent": 78,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onICD9SearchTextChange"
        },
        "readOnly": {
          "type": "value",
          "value": ""
        },
        "readonly": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.dataICD9?.treatmentSelected?.icdterm || props.dataICD9?.treatmentSearch || \"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/18\",\"gridColumn\":\"2/6\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ทำหัตถการ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/19\",\"gridColumn\":\"6/22\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Dropdown",
      "parent": 82,
      "props": {
        "name": {
          "type": "value",
          "value": "performer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentANS"
        },
        "options": {
          "type": "code",
          "value": "props.performerOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.performer"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"20/22\", gridColumn: \"2/6\", border: \"solid 0px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดเพิ่มเติม"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"20/23\",\"gridColumn\":\"6/22\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Input",
      "parent": 86,
      "props": {
        "name": {
          "type": "value",
          "value": "orderDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentANS"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.orderDetail || \"\""
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/26\", gridColumn: \"2/6\", border: \"solid 0px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "อุปกรณ์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"24/27\",\"gridColumn\":\"6/22\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Input",
      "parent": 90,
      "props": {
        "name": {
          "type": "value",
          "value": "equipmentDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentANS"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.equipmentDetail || \"\""
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/14\", gridColumn: \"23/30\", border: \"solid 0px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Button",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา ICD9"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props?.getICD9"
        },
        "style": {
          "type": "code",
          "value": "{display: props.dataICD9?.treatmentSelected?.icdterm ? \"none\" : \"\", width:\"100%\", flexShrink: 9 }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/8\", gridColumn: \"10/33\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Input",
      "parent": 95,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onTreatmentSearchTextChange"
        },
        "value": {
          "type": "code",
          "value": "props.data?.treatmentSelected?.name_code || props.data?.treatmentSearch || \"\""
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showSearchResult ? \"block\" : \"none\",  position: \"absolute\", zIndex: 100, width: \"80%\", height: \"400px\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 98,
      "name": "Table",
      "parent": 97,
      "props": {
        "data": {
          "type": "code",
          "value": "props.treatmentSearchResult"
        },
        "defaultPageSize": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.treatmentRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Code,Name"
        },
        "keys": {
          "type": "value",
          "value": "code,name"
        },
        "minRows": {
          "type": "code",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"250px\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showSearchICD9 ? \"block\" : \"none\",  position: \"absolute\", zIndex: 100, width: \"80%\", height: \"400px\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 100,
      "name": "Table",
      "parent": 99,
      "props": {
        "data": {
          "type": "code",
          "value": "props.icd9SearchResult"
        },
        "defaultPageSize": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.icd9RowProps"
        },
        "headers": {
          "type": "value",
          "value": "Medical Term,ICD9CM Term,ICD9CM Code"
        },
        "keys": {
          "type": "value",
          "value": "medterm,icdterm,icdcode"
        },
        "minRows": {
          "type": "code",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"250px\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCreateTreatment"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Button",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการเลือก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props?.removeICDterm"
        },
        "style": {
          "type": "code",
          "value": "{display: !props.dataICD9?.treatmentSelected?.icdterm ? \"none\" : \"\", width:\"100%\", flexShrink: 9 }"
        }
      },
      "seq": 102,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ChargeItem_SetupTemplate_Treatment",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
