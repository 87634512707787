import React, { useEffect, useRef } from "react";
import { Button, Tab, Icon, Modal, Segment } from "semantic-ui-react";
import PureReactTable from "react-lib/apps/common/PureReactTable";
import { useIntl } from "react-intl";

const VaccineUpload = (props: any) => {
  const intl = useIntl();

    const fileRef = useRef<any>()

    useEffect(() => {
        if (props.division) {
            props.onEvent({
                message: "GetVaccineInfo",
                params: {}
            })
        }
    }, [props.division]);

    const columns: any[] = [
        {   Header: "Reference ID", accessor: "reference_id" },
        {   Header: "Name", accessor: "name" },
        {   Header: "Last Name", accessor: "last_name" },
        {   Header: "Citizen ID", accessor: "citizen_id" },
        {   Header: "Brand", accessor: "brand" },
        {   Header: "Mobile", accessor: "mobile" },
        {   Header: "Email", accessor: "email" },
    ];

    console.log(props.appointmentList);

    const panes = [
        {
            menuItem: "Success",
            render: () => 
            <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button 
                        color="blue"
                        compact
                        icon
                        onClick={(e: any) => {
                            props.onEvent({ message: "GetDataList", params: {}});
                        }}>
                        Refresh
                        <Icon name="refresh" />
                    </Button>
                </div>
                <PureReactTable
                    data={props.appointmentList
                        .filter((item: any) => item.status === "SUCCESS")}
                    columns={columns}
                    showPagination={false}
                    manual
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                        style: {
                            backgroundColor: rowInfo?.index === props.selectedRowIndex ? "rgba(93, 188, 210, 0.5)" : ""
                        }
                        };
                    }}
                />
            </div>
        },
        {
            menuItem: "Fail",
            render: () => 
            <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button 
                        color="blue"
                        compact
                        icon
                        onClick={(e: any) => {
                            props.onEvent({ message: "GetDataList", params: {}});
                        }}>
                        Refresh
                        <Icon name="refresh" />
                    </Button>
                </div>
                <PureReactTable
                    data={props.appointmentList
                        .filter((item: any) => item.status === "FAIL")}
                    columns={columns}
                    showPagination={false}
                    manual
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                        style: {
                            backgroundColor: rowInfo?.index === props.selectedRowIndex ? "rgba(93, 188, 210, 0.5)" : ""
                        }
                        };
                    }}
                />
                
            </div>
        },
        {
            menuItem: "Pending",
            render: () => 
            <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button 
                        color="blue"
                        compact
                        icon
                        onClick={(e: any) => {
                            props.onEvent({ message: "GetDataList", params: {}});
                        }}>
                        Refresh
                        <Icon name ="refresh" />
                    </Button>
                </div>
                <PureReactTable
                    data={props.appointmentList
                        .filter((item: any) => item.status === "PENDING")}
                    columns={columns.concat([
                        {   Header: "Manual create", 
                            Cell: (cellProps: any) => (   
                                <Button
                                    size="mini"
                                    color="red"
                                    onClick={(e: any) => {
                                        props.onEvent({
                                            message: "GenerateUser",
                                            params: { row_id: cellProps.original.id }
                                        })
                                    }}>
                                    Test
                                </Button>
                            )
                        }
                    ])}
                    showPagination={false}
                    manual
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                        style: {
                            backgroundColor: rowInfo?.index === props.selectedRowIndex ? "rgba(93, 188, 210, 0.5)" : ""
                        }
                        };
                    }}
                />
            </div>
        },
        {
            menuItem: "Upload",
            render: () => 
            <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <input 
                        type="file"
                        accept=".xlsx,.xls,.csv"
                        ref={fileRef} 
                        onClick={(event: any) => {
                            event.target.value = null;
                        }}
                        onChange={(event: any) => {
                            if (event.target.value) {
                                props.onEvent({
                                    message: "ChooseVaccineFile",
                                    params: { file: event.target }
                                });
                            }
                        }}
                    />
                    <div style={{ color: "red" }}>
                        สำหรับการทดสอบจะใช้เฉพาะ 5 แถวแรก
                    </div>
                    <Button 
                        color="green"
                        icon
                        compact
                        onClick={(e: any) => {
                            props.onEvent({ message: "UploadData", params: {}});
                        }}>
                        Upload
                        <Icon name="upload" />
                    </Button>
                </div>
                <PureReactTable
                    data={props.vaccineInfo}
                    columns={columns}
                    showPagination={false}
                    manual
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                        style: {
                            backgroundColor: rowInfo?.index === props.selectedRowIndex ? "rgba(93, 188, 210, 0.5)" : ""
                        }
                        };
                    }}
                />
            </div>
        },
    ]

    console.log(props.generateUserResult);
    const canGenerate = props.generateUserResult !== null;
    const link = canGenerate ? 
        `https://ishealthapp-staging.web.app/VaccineUploadLogin/?token=${props.generateUserResult.upload_user}`
        : ""

    return <div>
        <Modal
            size="small"
            open={props.generateUserModal}>
            <Segment>
                <div 
                    style={{ 
                        display: "flex", 
                        flexDirection: "column",
                        alignItems: "center" 
                    }}>
                    <div style={{ margin: "20px" }}>
                        <p>{`ทดสอบสร้างข้อมูล: ${canGenerate ? "สำเร็จ": "ไม่สำเร็จ"}`}</p>
                        {canGenerate ? 
                        <div>
                            <p>{`อีเมล์จะถูกส่งไปยัง ${props.generateUserResult.email}`}</p>
                            <p>{intl.formatMessage({ id: "รวมถึงลิงค์สำหรับ Login (สำหรับเปิดในมือถือ)" })}</p>
                            <p><a href={link} target="_blank">{link}</a></p>
                        </div>
                        :
                        <p>{intl.formatMessage({ id: "กรุณาติดต่อผู้ประสานงาน" })}</p>
                        }
                    </div>
                    <Button
                        color="red"
                        onClick={(e: any) => {
                            props.onEvent({ message: "CloseGenerateUserModal", params: {}})
                        }}>
                        Close
                    </Button>
                </div>
            </Segment>
        </Modal>
        <Tab
            menu={{ secondary: true, pointing: true }}
            panes={panes}
        />
    </div>
}

export default VaccineUpload