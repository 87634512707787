import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "semantic-ui-react";

import { useIntl } from "react-intl";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Types
type ModLockExpenseProps = {
  onEvent: (e: any) => void;
  setProp: (key: any, value: any, callback?: () => void) => any;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, Record<string, any>[] | null>;
};

type Styles = Record<"btnAcknowledge" | "btnUnlock" | "message", CSSProperties>;

// Constants
const styles: Styles = {
  btnAcknowledge: {
    background: "white",
    border: "1px solid #db2828",
    color: "#db2828",
    flex: 1,
  },
  btnUnlock: {
    background: "white",
    border: "1px solid #21ba45",
    color: "#21ba45",
    flex: 1,
  },
  message: { zIndex: 999 },
};

const BUTTON_ACTIONS = {
  unlock: "UNLOCK_REQUEST",
};

const MOD_LOCK_EXPENSE = "ModLockExpense";

const ACTION_UNLOCK = `${MOD_LOCK_EXPENSE}_${BUTTON_ACTIONS.unlock}`;

const ModLockExpense = (props: ModLockExpenseProps) => {
  const intl = useIntl();
  const [openModSuccess, setOpenModSuccess] = useState<boolean>(false);

  // Effect
  useEffect(
    () => () => {
      handleClose();
    },
    []
  );

  // Memo callback
  const billingLockError = useMemo(() => {
    const lockError = props.errorMessage?.[MOD_LOCK_EXPENSE] || [];
    const unlockError = props.errorMessage?.[ACTION_UNLOCK];
    const isLocked = lockError.some((error) => error.__error_type__ === "BILLING_LOCKED");

    if (unlockError) {
      return { message: unlockError };
    }

    const encounterIds = lockError
      .filter((error) => error.enable_unlock_request)
      .map((error) => error.encounter as number);

    const messages = lockError.map(
      (error) =>
        `ไม่สามารถสั่ง Order ของ HN ${error.patient_hn} EN ${error.encounter_number} ได้ เนื่องจาก ${error.locked_by_name} กำลังตรวจสอบค่าใช้จ่าย`
    );

    return isLocked
      ? {
          allEncounterId: lockError.map((error) => error.encounter as number),
          encounterId: encounterIds,
          isEnable: lockError.some((error) => error.enable_unlock_request),
          message: messages,
        }
      : { isEnable: false, message: lockError[0] as unknown as Record<string, any> | undefined };
  }, [props.errorMessage]);

  const showButtonAction = useMemo(() => {
    const { message } = billingLockError;

    const isPermissionErr =
      !Array.isArray(message) && typeof message !== "string" && !!message?.detail;

    return !isPermissionErr;
  }, [billingLockError]);

  // callback
  const handleClose = useCallback(() => {
    const { [ACTION_UNLOCK]: eApt, [MOD_LOCK_EXPENSE]: eApv, ...error } = props.errorMessage || {};

    console.log(eApv, eApt);

    props.setProp("errorMessage", error);
  }, []);

  const handleUnlock = useCallback(() => {
    props.onEvent({
      message: "UpdateEncounterLocker",
      params: {
        action: "UNLOCK_REQUEST",
        id: billingLockError.encounterId,
        btnAction: ACTION_UNLOCK,
        onSuccess: () => {
          props.onEvent({
            message: "CheckEncounterLocker",
            params: { ids: billingLockError.allEncounterId },
          });

          setOpenModSuccess(true);
        },
      },
    });
  }, [billingLockError]);

  const handleCloseModSuccess = useCallback(() => {
    setOpenModSuccess(false);
  }, []);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={billingLockError.message}
        shouldAutoHide={!showButtonAction}
        success={null}
        sx={showButtonAction ? styles.message : {}}
        onClose={handleClose}
      >
        {showButtonAction ? (
          <div
            style={{
              display: "flex",
              gap: "4.5rem",
              justifyContent: "space-around",
              minWidth: "40rem",
              padding: "0 4rem",
            }}
          >
            <ButtonLoadCheck
              setProp={props.setProp}
              disabled={!billingLockError.isEnable}
              paramKey={ACTION_UNLOCK}
              style={styles.btnUnlock}
              buttonLoadCheck={props.buttonLoadCheck?.[ACTION_UNLOCK]}
              onClick={handleUnlock}
              title={
                billingLockError.isEnable === false
                  ? "ส่งคำขอไปยังการเงินแล้ว"
                  : "แจ้งการเงินปลดล็อค"
              }
            />
            <Button style={styles.btnAcknowledge} onClick={handleClose}>
              {intl.formatMessage({ id: "รับทราบ" })}
            </Button>
          </div>
        ) : null}
      </SnackMessage>

      <ModInfo
        buttonColor="green"
        open={openModSuccess}
        titleColor="blue"
        titleName="แจ้งเตือน"
        onApprove={handleCloseModSuccess}
        onClose={handleCloseModSuccess}
      >
        <div style={{ fontWeight: "bold", margin: "2rem 0 1rem" }}>
          {intl.formatMessage({ id: "ระบบส่งคำขอปลดล็อคไปยังเจ้าหน้าที่การเงินแล้ว" })}
        </div>
      </ModInfo>
    </div>
  );
};

export default React.memo(ModLockExpense);
