import TPDService from "../services/TPDService";
import { to } from "../../utils";
export default class TPDManager {
  apiToken?: string | null;
  tpdService: TPDService;

  constructor(apiToken: string | null = null) {
    this.tpdService = new TPDService(apiToken);
    // console.log('TPD case')
    // this.tpdService._consoleIntercept();
  }
  getListMedAdminRecord = async (params: any) => {
    var vsResponse = {};
    var vsError = null;
    const [error, response, network] = await to(
      this.tpdService.getListMedAdminRecord(params)
    );
    if (response) {
      vsResponse = response.items;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError, network];
  };
  postCreateMedAdminRecord = async (params: any) => {
    const [error, response, network] = await to(
      this.tpdService.postCreateMedAdminRecord(params)
    )
    return [response, error, network];
  };
  putUpdateMedAdminRecord = async (medicationRecordId: any, params: any) => {
    const [error, response, network] = await to(
      this.tpdService.putUpdateMedAdminRecord(medicationRecordId, params)
    )
    return [response, error, network];
  };
  putMedAdminRecord = async (medicationRecordId: any, params: any) => {
    const [error, response, network] = await to(
      this.tpdService.putMedAdminRecord(medicationRecordId, params)
    )
    return [response, error, network];
  };

  putDrugOrderItemIdAdminDivision = async (drugOrderItemId: any, params: any) => {
    const [response, error, network] = await to(
      this.tpdService.putDrugOrderItemIdAdminDivision(drugOrderItemId, params));
    return [response, error, network];
  }
  postMedAdminRecord = async (params: any) => {
    const [error, response, network] = await to(
      this.tpdService.postMedAdminRecord(params)
    )
    return [response, error, network];
  };
  getPrintMedRecord = async (params: any) => {
    var vsResponse = {};
    var vsError = null;
    const [error, response, network] = await to(
      this.tpdService.getPrintMedRecord(params)
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError, network];
  };

  getDrugOrderItemForMedReconcile = async (params: any) => {
    var vsResponse = {};
    var vsError = null;
    const [error, response, network] = await to(
      this.tpdService.getDrugOrderItemForMedReconcile(params)
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError, network];
  };

}
