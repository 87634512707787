import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import CardLayout from "react-lib/apps/common/CardLayout";
import SubFormViewer from "react-lib/apps/PTM/SubFormViewer";
import { Label } from "semantic-ui-react";
import { useIntl } from "react-intl";

const CardReassessment = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [reassessmentData, setReassessmentData] = React.useState([]);
  const [subFormViewData, setSubFormViewerData] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    refresh: ({ encounterId } = {}) => {
      refresh({ encounterId });
    },
  }));

  React.useEffect(() => {
    refresh({ encounterId: props.encounterId });
  }, [props.encounterId]);

  React.useEffect(() => {
    formatFormViewData();
  }, [reassessmentData]);

  const refresh = ({ encounterId } = {}) => {
    getFormDataLatest({ encounterId });
  };

  const formatFormViewData = () => {
    let newObj = { ...reassessmentData };
    newObj.education_need = YES_NO[newObj.education_need];
    newObj.barriers = YES_NO[newObj.barriers];
    newObj.readiness = YES_NO[newObj.readiness];
    newObj.education_evaluation =
      EDUCATION_EVALUATION[newObj.education_evaluation];
    newObj.follow_up = YES_NO[newObj.follow_up];
    setSubFormViewerData(newObj);
  };

  const getFormDataLatest = async ({ encounterId }) => {
    setIsLoading(true);
    const [data, error] = await props.controller.getFormDataLatest({
      encounterId: encounterId
        ? encounterId
        : props.patientData.ENCOUNTER.encounter_id,
      formCode: "CardReassessment",
      formVersion: "0.1",
    });

    if (data) {
      let newEducationWay = "";

      (data?.data?.education_way || []).map((item: any) => {
        if (newEducationWay === "") {
          newEducationWay = `${educationWay[item]}`;
        } else {
          newEducationWay = `${newEducationWay}, ${educationWay[item]}`;
        }
      });

      let newEducationMethod = "";

      (data?.data?.education_method || []).map((item: any) => {
        let medthodData = (props.patientEducationOptions || []).find(
          (data: any) => data.key === Number(item)
        )?.text;

        if (newEducationMethod === "") {
          newEducationMethod = `${medthodData}`;
        } else {
          newEducationMethod = `${newEducationMethod}, ${medthodData}`;
        }
      });

      let newEducation = "";

      (data?.data?.education || []).map((item: any) => {
        if (newEducation === "") {
          newEducation = `${education[item]}`;
        } else {
          newEducation = `${newEducation}, ${education[item]}`;
        }
      });

      setStatus(data.status);
      setReassessmentData({
        ...data.data,
        user_fullname: data?.edit_user_name,
        education_way: newEducationWay,
        education_method: newEducationMethod,
        education: newEducation,
        ...(!data?.data?.readiness && { readiness: false }),
      });
    }
    setIsLoading(false);
  };

  let statusColor = "black";
  if (status === CONSTANT.FORM_DATA_STATUS.DRAFT) {
    statusColor = "yellow";
  } else if (status === CONSTANT.FORM_DATA_STATUS.EDITED) {
    statusColor = "olive";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CONFIRMED) {
    statusColor = "green";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CANCELLED) {
    statusColor = "red";
  }

  let labelStatus = <Label color={statusColor}>{status}</Label>;

  return (
    <CardLayout
      headerColor="grey"
      loading={isLoading}
      titleText="ประเมินผู้ป่วยหลังออกจากห้องตรวจ (OPD Reassessment)"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      {props.showSummary ? (
        <SubFormViewer
          fieldList={rmdFieldList}
          fieldMap={rmdFieldMap}
          data={subFormViewData}
          languageUX={props.languageUX}
        />
      ) : (
        "TODO ---> Implement ShowSummary = false"
      )}
    </CardLayout>
  );
});

const EDUCATION_EVALUATION = {
  EXP: "เข้าใจดี/ทำได้ดี",
  EAM: "ยังมีข้อสงสัย/ทำได้บางส่วน ทบทวน",
  VDO: "ทำซ้ำในส่วนที่ยังไม่เข้าใจ หรือทำได้ไม่ดี",
  LLT: "ไม่เข้าใจ/ทำไม่ได้ต้องสอนใหม่",
};

const YES_NO = {
  true: "Yes",
  false: "No",
};

const rmdFieldList = [
  "user_fullname",
  "education_need",
  "barriers",
  "barriers_detail",
  "readiness",
  "readiness_detail",
  "education_way",
  "education_method",
  "education_method_detail",
  "education",
  "education_evaluation",
  "education_evaluation_detail",
  "follow_up",
  "follow_up_detail",
];

const rmdFieldMap = {
  user_fullname: "ผู้บันทึก/แก้ไข",
  education_need: "Patient and Family Education Need",
  barriers: "ข้อจำกัดในการรับรู้",
  barriers_detail: "ระบุ ข้อจำกัดในการรับรู้",
  readiness: "Readiness and Willingness",
  readiness_detail: "ระบุ Readiness and Willingness",
  education_way: "ผู้รับคำแนะนำ",
  education_method: "วิธีการให้ความรู้",
  education_method_detail: "ระบุ วิธีการให้ความรู้",
  education: "คำแนะนำที่ให้",
  education_evaluation: "Education Evaluation",
  education_evaluation_detail: "ระบุ Education Evaluation",
  follow_up: "Follow Up",
  follow_up_detail: "ระบุ Follow Up",
};

const educationWay = {
  PT: "ผู้ป่วย",
  REL: "ญาติสายตรง",
  PAR: "ผู้ดูแล",
};

const education = {
  DRD: "ตามแผนการรักษาของแพทย์",
  MED: "การรับประทานยา/อาการข้างเคียง",
  FOD: "อาหาร/นม",
  PIN: "การจัดการความปวด",
  CRE: "วิธีการดูแลตนเอง",
  SCR: "การดูแลพิเศษ",
  PRT: "การป้องกัน การส่งเสริม การฟื้นฟูสภาพ",
  EXE: "การออกกำลังกาย",
  LIM: "ข้อจำกัด",
  TOL: "การใช้อุปกรณ์ช่วย",
  SYN: "อาการสำคัญที่ควรมาพบแพทย์ก่อนนัด",
  MET: "การมาตรวจตามนัด",
};

CardReassessment.defaultProps = {
  onClose: () => {},
  controller: {},
  encounterId: null,
  showSummary: false,
};

CardReassessment.propTypes = {
  onClose: PropTypes.func,
  controller: PropTypes.object,
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSummary: PropTypes.bool,
};

export default React.memo(CardReassessment);
