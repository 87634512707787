import React from "react";
import PropTypes from "prop-types";
import PureReactTable from "../common/PureReactTable";
import CardLayout from "react-lib/apps/common/CardLayout";
import { useIntl } from "react-intl";

// * Do not have their own controller
const CardADRNoteDetail = React.forwardRef((props, ref) => {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false)
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
  }, [])

  React.useEffect(() => {
    const getADRNoteDetail = async () => {
      setIsLoading(true)
      const [response, error] = await props.controller.getAdverseReactionList({
        patientId: props.patientData.patient_id,
        today: true,
        excludeCancel: true
      });
      if (isMounted.current) {
        setIsLoading(false)
        if (response) {
          setData(response.items);
        } else {
          setData([]);
        }
      }
    };

    getADRNoteDetail();
  }, [props.patientData.patient_id]);

  return (
    <CardLayout
      titleText="รายละเอียดการบันทึก ADR ในวันนี้"
      onClose={props.onClose}
      toggleable={false}
      loading={isLoading}
    >
      <PureReactTable
        testid="adrTable"
        style={styles.table}
        className=""
        showPagination={false}
        data={data}
        minRows={0}
        columns={[
          {
            Header: "ADR",
            accessor: "status",
            Cell: row => {
              if (row.value == "NOT_KNOWN") {
                return "ไม่ทราบ";
              } else if (row.value == "NO_REACTION") {
                return "ไม่แพ้";
              } else if (row.value == "SAME_ADR") {
                return "ข้อมูลเดิม";
              } else {
                return "แพ้";
              }
            }
          },
          {
            Header: "Detail",
            accessor: "note"
          },
          {
            Header: "User",
            accessor: "edit_user"
          },
          {
            Header: "Time",
            accessor: "edited"
          }
        ]}
      />
    </CardLayout>
  );
});

const styles = {
  table: {
    height: "250px"
  }
};

CardADRNoteDetail.defaultProps = {
  controller: {},
  onClose: () => {},
  patientData: {}
};

CardADRNoteDetail.propTypes = {
  controller: PropTypes.object,
  onClose: PropTypes.func,
  patientData: PropTypes.object
};

export default React.memo(CardADRNoteDetail);
