
import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Checkbox,
  Button
} from 'semantic-ui-react'

const CardSupplyDeliveryQueueUX = (props: any) => {
    return(
      <div
        style={{margin: "10px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "large"}}>
          คิวส่งเวชภัณฑ์
        </div>
        <div
          className="ui form"
          style={{marginTop: "10px"}}>
          
          <FormGroup>
            <FormField
              width="2">
              <label
                style={{fontWeight: "normal"}}>
                รหัส 
              </label>
              <Input
                fluid={true}
                icon="search"
                onChange={props.handleChangeValue}
                placeholder="ค้นหาจากรหัส"
                size="small"
                style={{width: "100%"}}
                value={props.idSearch}>
              </Input>
            </FormField>
            <FormField
              width="3">
              <label
                style={{fontWeight: "normal"}}>
                หน่วยงาน
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.handleChangeDivision}
                options={props.divisionOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterDivision ?? ""}>
              </Dropdown>
            </FormField>
            <FormField>
              <Checkbox
                checked={props.checkedFilterDate || false}
                label="วันที่พิมพ์"
                onChange={props.handleChangeChecked}
                style={{"marginBottom":"5px","marginTop":"2px","fontSize":".92857143em"}}>
              </Checkbox>
              <div>
                {props.startDate}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <div>
                {props.endDate}
              </div>
            </FormField>
            <FormField
              width={6}>
              <label
                style={{fontWeight: "normal"}}>
                Order Status
              </label>
              <div
                style={{width: "100%"}}>
                {props.orderSearchBox}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <Button
                color="yellow"
                onClick={props.OnClear}
                size="small"
                style={{minWidth: "max-content"}}>
                ล้างตัวกรอง
              </Button>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <div>
                {props.handleSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <Table
          data={props.supplyDeliveryQueueData}
          getTrProps={props.getTrProps}
          headers="คิว,รหัส,สถานะ,หน่วยงาน,พิมพ์,จัด,จ่าย,รับ"
          keys="no,code,status_name,division_name,printed,checked,delivered,received"
          minRows="10"
          showPagination={false}
          style={{height:"500px"}}
          widths="50,200,200,auto,150,150,150,150">
        </Table>
      </div>
    )
}


export default CardSupplyDeliveryQueueUX

export const screenPropsDefault = {}

/* Date Time : Tue Jul 18 2023 04:44:54 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "คิวส่งเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"large\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 6,
      "name": "Table",
      "parent": 0,
      "props": {
        "columns": {
          "type": "value",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.supplyDeliveryQueueData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "คิว,รหัส,สถานะ,หน่วยงาน,พิมพ์,จัด,จ่าย,รับ"
        },
        "keys": {
          "type": "value",
          "value": "no,code,status_name,division_name,printed,checked,delivered,received"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"500px\"}"
        },
        "widths": {
          "type": "value",
          "value": "50,200,200,auto,150,150,150,150"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"10px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormGroup",
      "parent": 19,
      "props": {
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 20,
      "props": {
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 20,
      "props": {
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 20,
      "props": {
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 20,
      "props": {
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 20,
      "props": {
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัส "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Input",
      "parent": 21,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue"
        },
        "placeholder": {
          "type": "value",
          "value": "ค้นหาจากรหัส"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.idSearch"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDivision"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterDivision ?? \"\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Checkbox",
      "parent": 23,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedFilterDate || false"
        },
        "label": {
          "type": "value",
          "value": "วันที่พิมพ์"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked"
        },
        "style": {
          "type": "code",
          "value": "{\"marginBottom\":\"5px\",\"marginTop\":\"2px\",\"fontSize\":\".92857143em\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Status"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 20,
      "props": {
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 20,
      "props": {
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Button",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างตัวกรอง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "props.handleSearch"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 43,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyDeliveryQueueUX",
  "project": "Ishealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
