import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardOTObjectivePhysicalDysfunction1.module.css'

export interface CardOTObjectivePhysicalDysfunction1ViewDataType {
    CardOtObjectivePhysicalDysfunction14: string,
    CardOtObjectivePhysicalDysfunction14Disabled: boolean,
    CardOtObjectivePhysicalDysfunction16: boolean,
    CardOtObjectivePhysicalDysfunction16Disabled: boolean,
    CardOtObjectivePhysicalDysfunction17: boolean,
    CardOtObjectivePhysicalDysfunction17Disabled: boolean,
    CardOtObjectivePhysicalDysfunction19: boolean,
    CardOtObjectivePhysicalDysfunction19Disabled: boolean,
    CardOtObjectivePhysicalDysfunction111: boolean,
    CardOtObjectivePhysicalDysfunction111Disabled: boolean,
    CardOtObjectivePhysicalDysfunction113: boolean,
    CardOtObjectivePhysicalDysfunction113Disabled: boolean,
    CardOtObjectivePhysicalDysfunction116: boolean,
    CardOtObjectivePhysicalDysfunction116Disabled: boolean,
    CardOtObjectivePhysicalDysfunction118: boolean,
    CardOtObjectivePhysicalDysfunction118Disabled: boolean,
    CardOtObjectivePhysicalDysfunction121: boolean,
    CardOtObjectivePhysicalDysfunction121Disabled: boolean,
    CardOtObjectivePhysicalDysfunction129: boolean,
    CardOtObjectivePhysicalDysfunction129Disabled: boolean,
    CardOtObjectivePhysicalDysfunction130: boolean,
    CardOtObjectivePhysicalDysfunction130Disabled: boolean,
    CardOtObjectivePhysicalDysfunction131: boolean,
    CardOtObjectivePhysicalDysfunction131Disabled: boolean,
    CardOtObjectivePhysicalDysfunction133: boolean,
    CardOtObjectivePhysicalDysfunction133Disabled: boolean,
    CardOtObjectivePhysicalDysfunction139: string,
    CardOtObjectivePhysicalDysfunction139Disabled: boolean,
    CardOtObjectivePhysicalDysfunction140: boolean,
    CardOtObjectivePhysicalDysfunction140Disabled: boolean,
    CardOtObjectivePhysicalDysfunction144: boolean,
    CardOtObjectivePhysicalDysfunction144Disabled: boolean,
    CardOtObjectivePhysicalDysfunction146: boolean,
    CardOtObjectivePhysicalDysfunction146Disabled: boolean,
    CtConsciousness: string,
    CtConsciousnessDisabled: boolean,
    CtConsciousnessOptions: any,
    Ct2Communication: string,
    Ct2CommunicationDisabled: boolean,
    Ct2CommunicationOptions: any,
    Ct2CoOperation: string,
    Ct2CoOperationDisabled: boolean,
    Ct2CoOperationOptions: any,
    CtCognitive: string,
    CtCognitiveDisabled: boolean,
    CtCognitiveOptions: any,
    CardOtObjectivePhysicalDysfunction168: boolean,
    CardOtObjectivePhysicalDysfunction168Disabled: boolean,
    CardOtObjectivePhysicalDysfunction171: boolean,
    CardOtObjectivePhysicalDysfunction171Disabled: boolean,
    CardOtObjectivePhysicalDysfunction179: string,
    CardOtObjectivePhysicalDysfunction179Disabled: boolean,
    CardOtObjectivePhysicalDysfunction182: string,
    CardOtObjectivePhysicalDysfunction182Disabled: boolean,
    CardOtObjectivePhysicalDysfunction183: string,
    CardOtObjectivePhysicalDysfunction183Disabled: boolean,
    CardOtObjectivePhysicalDysfunction189: string,
    CardOtObjectivePhysicalDysfunction189Disabled: boolean,
    CtSensory: string,
    CtSensoryDisabled: boolean,
    CtSensoryOptions: any,
    CardOtObjectivePhysicalDysfunction194: string,
    CardOtObjectivePhysicalDysfunction194Disabled: boolean,
    CardOtObjectivePhysicalDysfunction196: string,
    CardOtObjectivePhysicalDysfunction196Disabled: boolean,
    CardOtObjectivePhysicalDysfunction197: string,
    CardOtObjectivePhysicalDysfunction197Disabled: boolean,
    CardOtObjectivePhysicalDysfunction198: boolean,
    CardOtObjectivePhysicalDysfunction198Disabled: boolean,
    CardOtObjectivePhysicalDysfunction1100: string,
    CardOtObjectivePhysicalDysfunction1100Disabled: boolean,
    CardOtObjectivePhysicalDysfunction1101: boolean,
    CardOtObjectivePhysicalDysfunction1101Disabled: boolean,
    CardOtObjectivePhysicalDysfunction1104: boolean,
    CardOtObjectivePhysicalDysfunction1104Disabled: boolean,
    CardOtObjectivePhysicalDysfunction1106: boolean,
    CardOtObjectivePhysicalDysfunction1106Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const CardOTObjectivePhysicalDysfunction1InitialViewData: CardOTObjectivePhysicalDysfunction1ViewDataType = {
    CardOtObjectivePhysicalDysfunction14: "",
    CardOtObjectivePhysicalDysfunction14Disabled: false,
    CardOtObjectivePhysicalDysfunction16: false,
    CardOtObjectivePhysicalDysfunction16Disabled: false,
    CardOtObjectivePhysicalDysfunction17: false,
    CardOtObjectivePhysicalDysfunction17Disabled: false,
    CardOtObjectivePhysicalDysfunction19: false,
    CardOtObjectivePhysicalDysfunction19Disabled: false,
    CardOtObjectivePhysicalDysfunction111: false,
    CardOtObjectivePhysicalDysfunction111Disabled: false,
    CardOtObjectivePhysicalDysfunction113: false,
    CardOtObjectivePhysicalDysfunction113Disabled: false,
    CardOtObjectivePhysicalDysfunction116: false,
    CardOtObjectivePhysicalDysfunction116Disabled: false,
    CardOtObjectivePhysicalDysfunction118: false,
    CardOtObjectivePhysicalDysfunction118Disabled: false,
    CardOtObjectivePhysicalDysfunction121: false,
    CardOtObjectivePhysicalDysfunction121Disabled: false,
    CardOtObjectivePhysicalDysfunction129: false,
    CardOtObjectivePhysicalDysfunction129Disabled: false,
    CardOtObjectivePhysicalDysfunction130: false,
    CardOtObjectivePhysicalDysfunction130Disabled: false,
    CardOtObjectivePhysicalDysfunction131: false,
    CardOtObjectivePhysicalDysfunction131Disabled: false,
    CardOtObjectivePhysicalDysfunction133: false,
    CardOtObjectivePhysicalDysfunction133Disabled: false,
    CardOtObjectivePhysicalDysfunction139: "",
    CardOtObjectivePhysicalDysfunction139Disabled: false,
    CardOtObjectivePhysicalDysfunction140: false,
    CardOtObjectivePhysicalDysfunction140Disabled: false,
    CardOtObjectivePhysicalDysfunction144: false,
    CardOtObjectivePhysicalDysfunction144Disabled: false,
    CardOtObjectivePhysicalDysfunction146: false,
    CardOtObjectivePhysicalDysfunction146Disabled: false,
    CtConsciousness: "",
    CtConsciousnessDisabled: false,
    CtConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct2Communication: "",
    Ct2CommunicationDisabled: false,
    Ct2CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct2CoOperation: "",
    Ct2CoOperationDisabled: false,
    Ct2CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    CtCognitive: "",
    CtCognitiveDisabled: false,
    CtCognitiveOptions: [{key: 0, value: "A", text: "A"}],
    CardOtObjectivePhysicalDysfunction168: false,
    CardOtObjectivePhysicalDysfunction168Disabled: false,
    CardOtObjectivePhysicalDysfunction171: false,
    CardOtObjectivePhysicalDysfunction171Disabled: false,
    CardOtObjectivePhysicalDysfunction179: "",
    CardOtObjectivePhysicalDysfunction179Disabled: false,
    CardOtObjectivePhysicalDysfunction182: "",
    CardOtObjectivePhysicalDysfunction182Disabled: false,
    CardOtObjectivePhysicalDysfunction183: "",
    CardOtObjectivePhysicalDysfunction183Disabled: false,
    CardOtObjectivePhysicalDysfunction189: "",
    CardOtObjectivePhysicalDysfunction189Disabled: false,
    CtSensory: "",
    CtSensoryDisabled: false,
    CtSensoryOptions: [{key: 0, value: "A", text: "A"}],
    CardOtObjectivePhysicalDysfunction194: "",
    CardOtObjectivePhysicalDysfunction194Disabled: false,
    CardOtObjectivePhysicalDysfunction196: "",
    CardOtObjectivePhysicalDysfunction196Disabled: false,
    CardOtObjectivePhysicalDysfunction197: "",
    CardOtObjectivePhysicalDysfunction197Disabled: false,
    CardOtObjectivePhysicalDysfunction198: false,
    CardOtObjectivePhysicalDysfunction198Disabled: false,
    CardOtObjectivePhysicalDysfunction1100: "",
    CardOtObjectivePhysicalDysfunction1100Disabled: false,
    CardOtObjectivePhysicalDysfunction1101: false,
    CardOtObjectivePhysicalDysfunction1101Disabled: false,
    CardOtObjectivePhysicalDysfunction1104: false,
    CardOtObjectivePhysicalDysfunction1104Disabled: false,
    CardOtObjectivePhysicalDysfunction1106: false,
    CardOtObjectivePhysicalDysfunction1106Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.CardOtObjectivePhysicalDysfunction13}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction14}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction14}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction14Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction14',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Objective</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction16}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction16}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction16Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction16"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction17}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction17}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction17Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction17"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction19}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction19}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction19Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction19"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction110}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction111}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction111}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction111Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction111"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction112}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Wheelchair</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction113}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction113}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction113Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction113"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction114}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Stroller</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction116}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction116}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction116Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction116"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction117}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cane/walker</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction118}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction118}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction118Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction118"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction121}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction121}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction121Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction121"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction122}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Arm sling</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction124}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On Ventilator</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction125}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ET</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction128}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>TT tube</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction129}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction129}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction129Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction129"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction130}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction130}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction130Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction130"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction131}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction131}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction131Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction131"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction133}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction133}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction133Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction133"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction136}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On Foley's catheter</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction137}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On IV fluid</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction139}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction139}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction139Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction139',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction140}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction140}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction140Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction140"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction142}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ICD drainage</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction144}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction144}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction144Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction144"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction145}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>NG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction146}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction146}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction146Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction146"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction147}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>OG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction148}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>PEG</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction149}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.CtConsciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtConsciousness}
                    disabled={ props.viewData.CtConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CtConsciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct2Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Communication}
                    disabled={ props.viewData.Ct2CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "Ct2Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2CommunicationOptions}
                />
            </div>
            <div className={styles.Ct2CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2CoOperation}
                    disabled={ props.viewData.Ct2CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "Ct2CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2CoOperationOptions}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction161}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.CtCognitive}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCognitive}
                    disabled={ props.viewData.CtCognitiveDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CtCognitive",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCognitiveOptions}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction165}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction166}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction167}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Cognitive</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction168}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction168}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction168Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction168"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction169}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Motor Power:</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction171}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction171}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction171Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction171"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction172}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Right</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction173}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Left</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction179}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction179}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction179Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction179',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction182}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction182}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction182Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction182',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "110%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction183}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction183}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction183Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction183',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "110%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction188}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction189}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction189}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction189Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction189',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtSensory}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSensory}
                    disabled={ props.viewData.CtSensoryDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CtSensory",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSensoryOptions}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction194}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction194}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction194Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction194',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction195}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sensory</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction196}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction196}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction196Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction196',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction197}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction197}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction197Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction197',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction198}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction198}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction198Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction198"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction199}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle tone</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction1100}>
                <Input
                    value={props.viewData.CardOtObjectivePhysicalDysfunction1100}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction1100Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'CardOtObjectivePhysicalDysfunction1100',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction1101}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction1101}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction1101Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction1101"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction1103}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction1104}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction1104}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction1104Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction1104"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction1105}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spasticity</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction1106}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePhysicalDysfunction1106}
                    disabled={ props.viewData.CardOtObjectivePhysicalDysfunction1106Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePhysicalDysfunction1",
                                name: "CardOtObjectivePhysicalDysfunction1106"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction1107}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Flaccid</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePhysicalDysfunction1108}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardOTObjectivePhysicalDysfunction1',
                            name: 'SaveRequest'
                        }
                    })}}
                    style={{color: "#FFFFFF",backgroundColor: "#27AE60", height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
