import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Label,
  Icon
} from 'semantic-ui-react'

const CardReturnSupplyUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              className="ui label brown ribbon">
              {props.isStatus? props.orderCode: "NEW"}
            </div>
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              คืนเวชภัณฑ์กลับเข้าห้องเวชภัณฑ์ 
            </div>
          </div>
          <div
            style={{display: "flex"}}>
            
            <Label>
              {props.statusName ?  props.statusName : "NEW"}
            </Label>
            <div
              style={{cursor: "pointer", margin: "-5px -5px 0 15px"}}>
              
              <Icon
                className="red large"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          className="ui divider"
          style={{margin: "0.5rem 0",  display: props.showStatus ? "" : "none"}}>
          
        </div>
        <div>
          {props.ErrorMessage}
        </div>
        <div
          style={{display:"flex",padding: props.statusName ? "10px 0px 0px 5px" : "10px 0px 10px 5px"}}>
          
          <div
            style={{display: "flex"}}>
            
            <div
              style={{margin: "0 5px 0 5px", display: "flex", alignItems: "center"}}>
              รายการขอคืนเวชภัณฑ์อื่นๆ
            </div>
            <Button
              name="New"
              onClick={props.onClickAction}
              size="mini">
              New
            </Button>
            <div
              style={{display: props.buttonOrderCode ? "" : "none"}}>
              {props.buttonOrderCode}
            </div>
          </div>
        </div>
        <div
          style={{display: props.statusName ? "" :"none",padding:"10px"}}>
          {props.userActionLogReturnList}
        </div>
        <div>
          
          <Table
            data={props.supplyOrderList}
            getTrProps={props.drugRowProps}
            headers="ลำดับ,รหัสเวชภัณฑ์,ชื่อเวชภัณฑ์,จำนวนจ่าย,จำนวนคงเหลือ,จำนวนขอคืน,จำนวนรับคืนเข้าคลัง,จำนวนรับคืนทำลาย,หน่วย"
            keys="no,code,name,quantity_issue,quantity_left,quantity_request,quantity_restock,quantity_discard,stock_unit_name"
            minRows="4"
            showPagination={false}
            style={{height: "300px"}}
            widths="60">
          </Table>
        </div>
        <div
          style={{padding:"5px"}}>
          {props.errorMessage}
        </div>
        <div
          style={{display: "flex",  marginTop: "10px"}}>
          
          <Button
            name="Log"
            onClick={props.onClickAction}
            size="mini"
            style={{display:props.statusName? "" : "none"}}>
            LOG
          </Button>
          <div
            style={{flex:"1"}}>
            
            <Label
              size="large">
              {`จากใบเวชภัณฑ์: ${props.code}`}
            </Label>
            <Label
              size="large">
              {`จากหน่วยงาน: ${props.divisionOrder}`}
            </Label>
          </div>
          <div>
            {props.buttonAction?.REQUEST}
          </div>
          <div>
            {props.buttonAction?.EDIT}
          </div>
          <div>
            {props.buttonForward}
          </div>
          <div>
            {props.buttonBackward}
          </div>
          <div>
            {props.buttonAction?.REJECT}
          </div>
          <div>
            {}
          </div>
          <div>
            {props.buttonAction?.CANCEL}
          </div>
        </div>
        <div>
          {props.loading}
        </div>
      </div>
    )
}


export default CardReturnSupplyUX

export const screenPropsDefault = {"drugSearch":"[Drug Search Box]","language":"EN"}

/* Date Time : Wed May 10 2023 03:53:25 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",padding: props.statusName ? \"10px 0px 0px 5px\" : \"10px 0px 10px 5px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  marginTop: \"10px\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.supplyOrderList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugRowProps"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับ,รหัสเวชภัณฑ์,ชื่อเวชภัณฑ์,จำนวนจ่าย,จำนวนคงเหลือ,จำนวนขอคืน,จำนวนรับคืนเข้าคลัง,จำนวนรับคืนทำลาย,หน่วย"
        },
        "keys": {
          "type": "value",
          "value": "no,code,name,quantity_issue,quantity_left,quantity_request,quantity_restock,quantity_discard,stock_unit_name"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        },
        "widths": {
          "type": "value",
          "value": "60"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการขอคืนเวชภัณฑ์อื่นๆ"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "LOG"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "Log"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAction"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{display:props.statusName? \"\" : \"none\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.5rem 0\",  display: props.showStatus ? \"\" : \"none\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isStatus? props.orderCode: \"NEW\""
        },
        "className": {
          "type": "value",
          "value": "ui label brown ribbon"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนเวชภัณฑ์กลับเข้าห้องเวชภัณฑ์ "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Label",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusName ?  props.statusName : \"NEW\""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", margin: \"-5px -5px 0 15px\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Icon",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "New"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "New"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAction"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Label",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "`จากใบเวชภัณฑ์: ${props.code}`"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "large"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Label",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "`จากหน่วยงาน: ${props.divisionOrder}`"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "large"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonOrderCode"
        },
        "style": {
          "type": "code",
          "value": "{display: props.buttonOrderCode ? \"\" : \"none\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.loading"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.userActionLogReturnList"
        },
        "style": {
          "type": "code",
          "value": "{display: props.statusName ? \"\" :\"none\",padding:\"10px\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"5px\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAction?.EDIT"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonForward"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonBackward"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAction?.REJECT"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAction?.CANCEL"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAction?.REQUEST"
        }
      },
      "seq": 146,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardReturnSupplyUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "drugSearch": "[Drug Search Box]",
    "language": "EN"
  },
  "width": 80
}

*********************************************************************************** */
