import React, { useMemo } from "react";
import { Icon, Label, Popup, Image } from "semantic-ui-react";
import { useIntl } from "react-intl";

const RenderNameStatus = (props: any) => {
  const intl = useIntl();
  const config = useMemo(() => {
    const activeFlagMap = {
      INSUFFICIENT: {
        title: "เวชภัณฑ์ขาดคราว (สั่งได้)",
        icon: "cube",
        color: "yellow",
      },
      UNAVAILABLE: {
        title: "เวชภัณฑ์ขาดคราว (สั่งไม่ได้)",
        icon: "cube",
        color: "orange",
      },
      TERMINATED: {
        title: "เวชภัณฑ์ยกเลิกจำหน่าย",
        icon: "lock",
        color: "red",
      },
      INACTIVE: {
        title: "เวชภัณฑ์ไม่พร้อมจำหน่าย",
        icon: "lock",
        color: "gray",
      },
    } as any;

    return activeFlagMap[props.data.active_flag];
  }, [props.data]);

  // console.log(' RenderNameStatus props: ', props);

  return (
    <div>
      {props.data.name}
      <div style={{ display: "inline-block", float: "right" }}>
        {!props.hideStatus ? (
          <>
            {config && (
              <Popup
                content={config.title}
                trigger={
                  <Label
                    color={config.color}
                    circular={true}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  >
                    <Icon name={config.icon} style={{ margin: 0 }} />
                  </Label>
                }
              />
            )}
          </>
        ) : (
          <></>
        )}
        {props.data.images.length > 0 ? (
          <Popup
            trigger={<Icon name="picture" color={"black"} style={{ margin: 0 }} />}
            content={
              <Image src={props.data.images.length > 0 ? props.data.images[0] : ""} size="small" />
            }
            inverted
          />
        ) : (
          <Icon name="picture" disabled />
        )}
      </div>
    </div>
  );
};

export default RenderNameStatus;
