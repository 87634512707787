import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardReportDrugUseRecordUX = (props: any) => {
    return(
      <div
        style={{ margin: "10px" }}>
        <div
          style={{ display: "flex" }}>
          
          <div
            style={{ display: "flex" }}>
            
            <label
              style={{ margin: "10px 5px 10px 0px" }}>
              Division type
            </label>
            <label
              style={{ margin: "10px 10px 10px 0px", color: "red" }}>
              *
            </label>
            <Dropdown
              clearable={true}
              onChange={props.handleDivisionTypeChange}
              options={props.divisionTypeOptions || []}
              search={true}
              selection={true}
              value={props.ReportDrugUseRecordSequence?.filterDrugList?.division_type || ""}>
            </Dropdown>
          </div>
          <div
            style={{ display: "flex" }}>
            
            <label
              style={{ margin: "10px" }}>
              Division/Ward
            </label>
            <label>
              
            </label>
            <Dropdown
              clearable={true}
              onChange={(e, data) => props.setProp("ReportDrugUseRecordSequence.filterDrugList.division", data.value)}
              options={props.divisionOptions || []}
              search={true}
              selection={true}
              value={props.ReportDrugUseRecordSequence?.filterDrugList?.division || ""}>
            </Dropdown>
          </div>
          <div
            style={{ display: "flex" }}>
            
            <label
              style={{ margin: "10px 10px 10px 20px" }}>
              วันที่บริหารยา
            </label>
            <DateTextBox
              onChange={(date: string) => props.setProp("ReportDrugUseRecordSequence.filterDrugList.start_admin", date)}
              value={props.ReportDrugUseRecordSequence?.filterDrugList?.start_admin || ""}>
            </DateTextBox>
          </div>
          <div
            style={{ display: "flex" }}>
            
            <label
              style={{ margin: "10px" }}>
              ถึง
            </label>
            <DateTextBox
              onChange={(date: string) => props.setProp("ReportDrugUseRecordSequence.filterDrugList.end_admin", date)}
              value={props.ReportDrugUseRecordSequence?.filterDrugList?.end_admin || ""}>
            </DateTextBox>
          </div>
        </div>
        <div
          style={{ display: "flex", marginTop:"10px" }}>
          
          <div
            style={{ display: "flex" }}>
            
            <label
              style={{ margin: "10px 5px 10px 0px", width: "40px" }}>
              ชื่อยา
            </label>
            <Dropdown
              clearable={true}
              onChange={(e, data) => props.setProp("ReportDrugUseRecordSequence.filterDrugList.drug", data.value)}
              options={props.masterOptions?.drugName || []}
              search={true}
              selection={true}
              style={{width: "300px"}}
              value={props.ReportDrugUseRecordSequence?.filterDrugList?.drug || ""}>
            </Dropdown>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}>
          
          <div
            style={{ display: "flex" }}>
            
            <div
              style={{ margin: "10px 10px" }}>
              {props.buttonSearch}
            </div>
            <div
              style={{ margin: "10px 10px" }}>
              {props.buttonPrint}
            </div>
          </div>
        </div>
        <div
          style={{ margin: "10px 0px" }}>
          
          <Table
            data={props.ReportDrugUseRecordSequence?.drugUseRecordList?.items || []}
            defaultPageSize={10}
            headers="วันที่สั่งใช้ยา, แพทย์ผู้สั่ง, HN (AN), ชื่อ-นามสกุล, ชื่อยา, ยาและวิธีใช้ยา, จำนวนจ่าย, วันที่บริหารยา, เวลาบริหารยา, ใช้, ทิ้ง, พยาบาล 1, พยาบาล 2"
            keys="order_date_text, order_by_fullname, hn_an, patient_fullname, drug_label_name, drug_item_label, drug_quantity_text, admin_date_text, admin_time_text, apply_text, reject_text, nurse1_fullname, nurse2_fullname"
            showPagination={false}
            widths="100, auto, 80, auto, auto, auto, 80, 100, 100, 50, 50, auto, auto">
          </Table>
        </div>
      </div>
    )
}


export default CardReportDrugUseRecordUX

export const screenPropsDefault = {}

/* Date Time : Fri Jul 19 2024 12:38:25 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Division type"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 5px 10px 0px\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 10px 0px\", color: \"red\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Dropdown",
      "parent": 2,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleDivisionTypeChange"
        },
        "options": {
          "type": "code",
          "value": "props.divisionTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReportDrugUseRecordSequence?.filterDrugList?.division_type || \"\""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่บริหารยา"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 10px 20px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 13,
      "name": "DateTextBox",
      "parent": 3,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.setProp(\"ReportDrugUseRecordSequence.filterDrugList.start_admin\", date)"
        },
        "value": {
          "type": "code",
          "value": "props.ReportDrugUseRecordSequence?.filterDrugList?.start_admin || \"\""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 14,
      "name": "DateTextBox",
      "parent": 4,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.setProp(\"ReportDrugUseRecordSequence.filterDrugList.end_admin\", date)"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.ReportDrugUseRecordSequence?.filterDrugList?.end_admin || \"\""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 0px\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 16,
      "name": "Table",
      "parent": 15,
      "props": {
        "data": {
          "type": "code",
          "value": "props.ReportDrugUseRecordSequence?.drugUseRecordList?.items || []"
        },
        "defaultPageSize": {
          "type": "code",
          "value": "10"
        },
        "headers": {
          "type": "value",
          "value": "วันที่สั่งใช้ยา, แพทย์ผู้สั่ง, HN (AN), ชื่อ-นามสกุล, ชื่อยา, ยาและวิธีใช้ยา, จำนวนจ่าย, วันที่บริหารยา, เวลาบริหารยา, ใช้, ทิ้ง, พยาบาล 1, พยาบาล 2"
        },
        "keys": {
          "type": "value",
          "value": "order_date_text, order_by_fullname, hn_an, patient_fullname, drug_label_name, drug_item_label, drug_quantity_text, admin_date_text, admin_time_text, apply_text, reject_text, nurse1_fullname, nurse2_fullname"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "100, auto, 80, auto, auto, auto, 80, 100, 100, 50, 50, auto, auto"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginTop:\"10px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อยา"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 5px 10px 0px\", width: \"40px\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 26,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.setProp(\"ReportDrugUseRecordSequence.filterDrugList.drug\", data.value)"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.drugName || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"300px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReportDrugUseRecordSequence?.filterDrugList?.drug || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "Division/Ward"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.setProp(\"ReportDrugUseRecordSequence.filterDrugList.division\", data.value)"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReportDrugUseRecordSequence?.filterDrugList?.division || \"\""
        }
      },
      "seq": 32,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardReportDrugUseRecordUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
