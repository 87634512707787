import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSurgicalSafetyChecklistHistoryUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px",  }}>
        <div
          style={{backgroundColor: "rgba(198, 235, 243, 0.2)",padding: "10px 20px",marginBottom: "10px" }}>

          <div
            style={{display: "flex", justifyContent: "space-between"}}>

            <label
              style={{ fontWeight: "bold", fontSize: "18px" }}>
              {props.title || "-"}
            </label>
          </div>
        </div>
        <Form
          className="size small">
          <FormGroup
            inline={true}
            style={{margin: "0.5rem 0 0.65rem 1rem"}}>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "max-content"}}>
                วันที่
              </label>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  onChange={(value: string)=>props.onChangeFilter?.({name:"startDate", value})}
                  value={props.filter?.startDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label>
                ถึง
              </label>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  onChange={(value: string)=>props.onChangeFilter?.({name:"endDate", value})}
                  value={props.filter?.endDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="blue"
                onClick={props.onSearch}
                size="small">
                ค้นหา
              </Button>
            </FormField>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}>
              <Button
                basic={true}
                disabled={props.disabledPrevious}
                icon="angle left"
                name="left"
                onClick={props.onStepChange}
                size="mini"
                style={{marginBottom: "-0.5rem"}}>
              </Button>
              <Button
                basic={true}
                disabled={props.disabledNext}
                icon="angle right"
                name="right"
                onClick={props.onStepChange}
                size="mini"
                style={{marginBottom: "-0.5rem"}}>
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <div>

          <Table
            columns={props.columns}
            data={props.data}
            minRows={1}
            resizable={false}
            showPagination={false}
            sortable={false}>
          </Table>
        </div>
      </div>
    )
}

CardSurgicalSafetyChecklistHistoryUX.displayName = "CardSurgicalSafetyChecklistHistoryUX";
export default React.memo(CardSurgicalSafetyChecklistHistoryUX)

export const screenPropsDefault = {}

/* Date Time : Tue Oct 29 2024 17:52:35 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(198, 235, 243, 0.2)\",padding: \"10px 20px\",marginBottom: \"10px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 157,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "size small"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormGroup",
      "parent": 160,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.5rem 0 0.65rem 1rem\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 161,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 161,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 161,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "label",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Button",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 170,
      "name": "DateTextBox",
      "parent": 168,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.({name:\"endDate\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.endDate || \"\""
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 171,
      "name": "DateTextBox",
      "parent": 167,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.({name:\"startDate\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.startDate || \"\""
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 173,
      "name": "Table",
      "parent": 172,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columns"
        },
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "code",
          "value": ""
        },
        "keys": {
          "type": "code",
          "value": ""
        },
        "minRows": {
          "type": "code",
          "value": "1"
        },
        "resizable": {
          "type": "code",
          "value": "false"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "sortable": {
          "type": "code",
          "value": "false"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "FormField",
      "parent": 161,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "FormField",
      "parent": 161,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Button",
      "parent": 175,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrevious"
        },
        "icon": {
          "type": "value",
          "value": "angle left"
        },
        "name": {
          "type": "value",
          "value": "left"
        },
        "onClick": {
          "type": "code",
          "value": "props.onStepChange"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-0.5rem\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Button",
      "parent": 175,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledNext"
        },
        "icon": {
          "type": "value",
          "value": "angle right"
        },
        "name": {
          "type": "value",
          "value": "right"
        },
        "onClick": {
          "type": "code",
          "value": "props.onStepChange"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-0.5rem\"}"
        }
      },
      "seq": 177,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardSurgicalSafetyChecklistHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
