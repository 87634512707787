import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Icon,
  Form,
  Input,
  Checkbox,
  Header,
  Dropdown,
  Select,
} from "semantic-ui-react";
import DateTextBox from "../../common/DateTextBox";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import { formatADtoBEString } from "../../../utils/dateUtils";
import ModInfo from "../../common/ModInfo";
import { useIntl } from "react-intl";

const UserProfile = (props) => {
  const intl = useIntl();
  const inputFile = useRef(null);
  const history = useHistory();

  const [profileImageSrc, setProfileImageSrc] = useState(
    process.env.PUBLIC_URL + "/static/images/person-placeholder.png"
  );

  const [position, setPosition] = useState("");
  const isMounted = useRef(true);
  const [ihId, setIhId] = useState("");
  const [loginWith, setLoginWith] = useState("");
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [certNo, setCertNo] = useState("");
  const [birthdate, setBirthDate] = useState(null);
  const [positionList, setPosistionList] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [fileImage, setFileImage] = useState(null);
  const [openModInfo, setOpenModInfo] = useState(false)
  const [modInfoMsg, setModInfoMsg] = useState("")


  useEffect(() => {
    console.log("props", props.controller);

    const getPositionOptions = async ({ apiToken }) => {
      const [data, error, network] = await props.controller.getUserPosition({
        apiToken,
      });
      if (data?.items) {
        let prepare = data?.items
          ?.filter((item) => item.is_active)
          .filter((item) => item.code)
          .filter((item) => item.name)
          .map((item) => {
            let option = {};
            option.key = item.id;
            option.value = item.code;
            option.text = item.name;
            return option;
          });
        setPosistionList(data?.items);
        setPositionOptions(prepare);
        setPosition(prepare[0].value);
      }
    };

    if (Cookies.get("apiToken") && props.controller?.getUserPosition) {
      getPositionOptions({ apiToken: Cookies.get("apiToken") });
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const getProfile = async ({ apiToken }) => {
      const [data, error, network] = await props.controller.getUserProfile({
        apiToken,
      });
      console.log("getUserProfile data", data, error, network);

      const [data2, error2, network2] = await props.controller.getOAUserProfile(
        {
          apiToken,
        }
      );
      console.log("getOAUserProfile data2", data2, error2, network2);

      if (data) {
        setName(data.first_name);
        setSurname(data.last_name);
        setIhId(data.user);
      }

      if (data2) {
        setPosition(data2.position);
        setCertNo(data2.licence);
      }

      if (data2?.image) {
        setProfileImageSrc(data.image);
      } else if (data?.image_url) {
        setProfileImageSrc(data.image_url);
      }

      console.log(" data?.dob", data?.dob, formatADtoBEString(data.dob));
      if (data?.dob) {
        setBirthDate(formatADtoBEString(data.dob));
      }
    };

    if (Cookies.get("apiToken") && props.controller?.getUserProfile) {
      getProfile({ apiToken: Cookies.get("apiToken") });
    }
  }, []);

  useEffect(() => {
    props.onGetDivision()
  }, [])

  const handleSave = async () => {
    if (!name || !surname || !birthdate || !position || !certNo) {
      alert(
        "กรุณาระบุฟิลด์จำเป็น " +
        (!name ? "ชื่อ " : "") +
        (!surname ? "นามสกุล " : "") +
        (!birthdate ? "วันเกิด " : "") +
        (!position ? "ตำแหน่ง " : "") +
        (!certNo ? "เลขที่ใบประกอบ" : "")
      );
      return;
    }

    setIsSaveLoading(true);
    const [data, error, network] = await props.controller.updateUserProfile({
      apiToken: Cookies.get("apiToken"),
      firstName: name,
      lastName: surname,
      dob: birthdate,
    });

    const [data2, error2, network2] = await props.controller.patchOAUserProfile(
      {
        apiToken: Cookies.get("apiToken"),
        firstName: name,
        lastName: surname,
        dob: birthdate,
        position: position,
        licence: certNo,
        image: fileImage,
      }
    );
    setIsSaveLoading(false);
    if (error || error2) {
      setModInfoMsg("เกิดข้อผิดพลาด");
    } else {
      setModInfoMsg("บันทึกสำเร็จ");
      props?.onRefreshUserInfo()
    }
    setOpenModInfo(true);

    console.log(" isLineLogin", props.isLineLogin);
    console.log(" isFacebookLogin", props.isFacebookLogin);
    console.log(" isEmailLogin", props.isEmailLogin);
    // If came from Login Page goto ShowDivision
    if (props.isLineLogin || props.isFacebookLogin || props.isEmailLogin) {
      // push to next page

      // Logic Same Condition##Goto #2
      // API that check request
      const [listDivision, error2, network2] =
        await props.controller.getListOARequestCheck({
          apiToken: Cookies.get("apiToken"),
        });
      console.log(" listDivision", listDivision, error2, network2);
      if (listDivision?.items?.length > 0) {
        // props.controller.setState({ messageShowPending: "listDivision" });
        // Goto Waiting if have pending...
        console.log(
          "handleLoginSuccess Goto /ShowDivision  from Login success"
        );
        history.push({ pathname: "/ShowDivision" });
        return;
      }

      console.log("handleLoginSuccess props.myDivisions", props.myDivisions);
      // No Division
      if (
        (props.myDivisions?.length === 1 &&
          props.myDivisions[0].code === "create_oa") ||
        props.myDivisions?.length === 0
      ) {
        console.log("handleLoginSuccess Goto /CreateOA  from Login success");
        history.push({ pathname: "/CreateOA" });
        return;
      }

      if (props.myDivisions?.length > 1) {
        console.log("handleLoginSuccess Goto /Chat  from Login success");
        history.push({ pathname: "/Chat" });
        return;
      }
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile?.current?.click();
  };

  const imageChange = (event) => {
    let file = URL.createObjectURL(event.target.files[0]);
    console.log(" event.target.files[0])", event.target.files);
    setFileImage(event.target.files[0]);
    setProfileImageSrc(file);
  };

  console.log("birthdat", birthdate);
  return (
    <>
      {openModInfo && <ModInfo
        titleColor={modInfoMsg.includes("บันทึกสำเร็จ") ? "green" : "red"}
        titleName={modInfoMsg.includes("บันทึกสำเร็จ") ? "บันทึกเรียบร้อย" : "เกิดข้อผิดพลาด !!"}
        btnText={intl.formatMessage({ id: "ตกลง" })}
        size="tiny"
        open={openModInfo}
        alertText={modInfoMsg}
        onApprove={() => setOpenModInfo(false)}
      />}

      <div className="register-new-user">
        <div className="profile-image-section">
          <img className="profile-image" src={profileImageSrc} alt="profile" />
          <div className="profile-button">
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={imageChange}
            />
            <Button
              style={{ backgroundColor: "#5DBCD2", color: "white" }}
              icon
              labelPosition="right"
              onClick={onButtonClick}
            >
              แก้ไขรูปภาพ <Icon name="picture" />
            </Button>
          </div>

          <span className="profile-text">
            กรุณาเลือกไฟล์ .jpg, .jpeg หรือ .png
          </span>
          <span className="profile-text">{intl.formatMessage({ id: "ขนาดไม่เกิน 2 Mb เท่านั้น" })}</span>
        </div>
        <div className="vl"></div>
        <div className="profile-info">
          <Header style={{ color: "#5DBCD2" }} as="h1">
            {" "}
            ข้อมูลส่วนตัว{" "}
          </Header>
          <Form>
            <Form.Field inline>
              <label className="reg">IH ID</label>
              <input className="reg" placeholder={intl.formatMessage({ id: "IH ID (ตัวเลข)" })} value={ihId} />
            </Form.Field>
            {props.isJustLogin ? (
              <Form.Field inline>
                <label className="reg">{intl.formatMessage({ id: "Login ด้วย" })}</label>
                <input
                  className="reg"
                  value={
                    props.isLineLogin
                      ? "Line"
                      : props.isFacebookLogin
                        ? "Facebook"
                        : "Email"
                  }
                />
              </Form.Field>
            ) : null}

            {props.isLineLogin ? (
              <Form.Field inline>
                <label className="reg">Line ID</label>
                <input className="reg" value={props.lineId} />
              </Form.Field>
            ) : null}

            {props.isFacebookLogin ? (
              <Form.Field inline>
                <label className="reg">Facebook Name</label>
                <input className="reg" value={props.facebookName} />
              </Form.Field>
            ) : null}

            {props.isEmailLogin ? (
              <Form.Field inline>
                <label className="reg">Email:</label>
                <input className="reg" value={props.emailLogin} />
              </Form.Field>
            ) : null}

            <Form.Field inline required>
              <label className="reg">{intl.formatMessage({ id: "ชื่อ" })}</label>
              <input
                className="reg"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Field>
            <Form.Field inline required>
              <label className="reg">{intl.formatMessage({ id: "นามสกุล" })}</label>
              <input
                className="reg"
                value={surname}
                onChange={(e) => {
                  setSurname(e.target.value);
                }}
              />
            </Form.Field>
            <Form.Field inline required>
              <label className="reg">{intl.formatMessage({ id: "เลขที่ใบประกอบวิชาชีพ" })}</label>
              <input
                style={{ width: "200px" }}
                value={certNo}
                onChange={(e) => {
                  setCertNo(e.target.value);
                }}
              />
            </Form.Field>

            <Form.Field inline required>
              <label className="reg">{intl.formatMessage({ id: "วันเกิด" })}</label>
              <DateTextBox
                onChange={(value) => {
                  setBirthDate(value);
                }}
                value={birthdate}
              />
            </Form.Field>

            <Form.Field inline required>
              <label className="reg">{intl.formatMessage({ id: "ตำแหน่ง" })}</label>
              <Dropdown
                scrolling
                style={{ width: "200px" }}
                options={positionOptions}
                value={position}
                onChange={(e, { value }) => {
                  setPosition(value);
                }}
              />
            </Form.Field>
            <br />
            <div className="last-div">
              <Button
                loading={isSaveLoading}
                style={{ backgroundColor: "#5DBCD2", color: "white" }}
                type="submit"
                onClick={handleSave}
              >
                บันทึก
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
