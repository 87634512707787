import { TDocumentDefinitions } from "pdfmake/interfaces";
import { HeaderPrintListLandscape } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";
import { getCompanyFont } from "react-lib/apps/HISV3/common/CompanyLogoForm";
import AddSymbolPrintList from "./AddSymbolPrintList";

const FORM_NAME = "FormIntravenousCareFlowsheet";

const createTable = () => {
  const timeList = Array.from({ length: 24 }, (_, index) => {
    const hour = (index + 1).toLocaleString("en-US", { minimumIntegerDigits: 2 });
    return { text: `${hour}.00`, style: "timeText" };
  });

  const rows = [
    { text: "Attempt : Time", style: "contentText" },
    { text: "Device / Brand", style: "contentText" },
    { text: "Catheter : Gauge (Size)", style: "contentText" },
    { text: "Insertion in to ........................", style: "contentText" },
    { text: "Dressing Type ......................", style: "contentText" },
    { text: "Intravenous Access for :", style: "contentText", fillColor: "#cccccc" },
    { text: "• Intravenous Fluid", style: "contentText" },
    { text: "• NSS Lock", style: "contentText" },
    { text: "Patient Education :", style: "contentText", fillColor: "#cccccc" },
    { text: "• V = Verbal", style: "contentText" },
    { text: "• PI = Patient Instruction", style: "contentText" },
    { text: "Site Inspection", style: "contentText" },
    { text: "Visual Infusion Phlebitis Score (0-5)", style: "contentText" },
    { text: "Reason for Removal :", style: "contentText", fillColor: "#cccccc" },
    { text: "• Routine Site Rotation", style: "contentText" },
    { text: "• Phlebitis", style: "contentText" },
    { text: "• Infiltrated", style: "contentText" },
    { text: "• Occluded", style: "contentText" },
    { text: "• Leaking", style: "contentText" },
    { text: "• Positional", style: "contentText" },
    { text: "• Doctor Order", style: "contentText" },
    { text: "• Chang Dressing", style: "contentText" },
    { text: "Nurse's Name", style: "contentText", fillColor: "#cccccc" },
  ].map((row) => [
    row,
    ...(row.fillColor && row.text !== "Nurse's Name"
      ? Array(24).fill({ text: " ", style: "contentText", fillColor: "#cccccc" })
      : Array(24).fill({ text: " ", style: "contentText" })),
  ]);

  return {
    table: {
      widths: Array(1).fill("*").concat(Array(24).fill("auto")),
      body: [
        [
          {
            stack: [
              { text: "Insertion : Date ........................", style: "contentText" },
              {
                columns: [
                  {
                    columns: [
                      { image: "unchecked", width: 6, margin: [0, 4, 0, 0] },
                      { text: "OPD", style: "contentText" },
                    ],
                    columnGap: 5,
                  },
                  {
                    columns: [
                      { image: "unchecked", width: 6, margin: [0, 4, 0, 0] },
                      { text: "Ward", style: "contentText" },
                    ],
                    columnGap: 5,
                  },
                ],
              },
              {
                columns: [
                  { image: "unchecked", width: 6, margin: [0, 4, 0, 0] },
                  { text: "Other ....................................", style: "contentText" },
                ],
                columnGap: 5,
              },
            ],
          },
          {
            text: "Date/Time for Rounding .............../.............../...............",
            style: "roundingText",
            colSpan: 24,
          },
          ...Array(23).fill({}),
        ],
        [
          {
            text: "Time: Re - Insertion",
            style: "contentText",
          },
          ...timeList,
        ],
        ...rows,
      ],
    },
  };
};

const FormIntravenousCareFlowsheet = async (props: any): Promise<TDocumentDefinitions> => {
  const headerForm = await HeaderPrintListLandscape({
    detail: props.detail,
    font: getCompanyFont(),
    form: FORM_NAME,
    logoHeight: 56,
    pageMargins: [16, 100, 16, 16],
    titleContent: [],
    formName: "Intravenous Care Flowsheet (Peripheral)",
  });

  const { font, fontSizes, images, lineHeights, styles } = headerForm;

  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    defaultStyle: {
      font: font,
      fontSize: fontSizes[14],
      lineHeight: lineHeights[1],
    },
    ...headerForm,
    images: { ...images, ...AddSymbolPrintList },
    styles: {
      ...styles,
      contentText: {
        font: "THSarabunNew",
        fontSize: 10,
        lineHeight: 1,
      },
      roundingText: {
        font: "THSarabunNew",
        fontSize: 16,
        lineHeight: 1,
        bold: true,
        alignment: "center",
      },
      timeText: {
        font: "THSarabunNew",
        fontSize: 10,
        lineHeight: 1,
        alignment: "center",
      },
    },
    content: [createTable()],
  };
};

export default FormIntravenousCareFlowsheet;
