import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const CardPatientCoverageUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%", padding: "15px"}}>
        <div
          style={{fontSize: "1.15rem", fontWeight: "bold", marginTop: "-0.25rem"}}>
          รายชื่อสิทธิการรักษา
        </div>
        <div
          className="ui divider"
          style={{margin: "0.75rem 0"}}>
          
        </div>
        <Table
          data={props.patientCoverageData}
          getTrProps={props.onSelectedReim}
          headers="สิทธิ,ผู้จ่ายเงิน,ประเภทบริการ,ลำดับการใช้สิทธิ,สถานะ,วันที่เริ่ม,วันที่หมดอายุ"
          keys="coverage_name,payer_name,service_type_text,priority_text,status_text,start_date_text,stop_date_text"
          minRows="10"
          showPagination={false}
          style={{height: "450px"}}
          widths="^100,^150,^90,^90,^90,^90,^90">
        </Table>
      </div>
    )
}


export default CardPatientCoverageUX

export const screenPropsDefault = {}

/* Date Time : Fri Apr 12 2024 09:52:12 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายชื่อสิทธิการรักษา"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\", marginTop: \"-0.25rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 3,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.patientCoverageData"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectedReim"
        },
        "headers": {
          "type": "value",
          "value": "สิทธิ,ผู้จ่ายเงิน,ประเภทบริการ,ลำดับการใช้สิทธิ,สถานะ,วันที่เริ่ม,วันที่หมดอายุ"
        },
        "keys": {
          "type": "value",
          "value": "coverage_name,payer_name,service_type_text,priority_text,status_text,start_date_text,stop_date_text"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"450px\"}"
        },
        "widths": {
          "type": "value",
          "value": "^100,^150,^90,^90,^90,^90,^90"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.75rem 0\"}"
        }
      },
      "seq": 3,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "CardPatientCoverageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 70
}

*********************************************************************************** */
