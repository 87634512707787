import React, { CSSProperties, useCallback, useEffect, useMemo } from "react";

import { Button } from "semantic-ui-react";

import { RowInfo } from "react-table-6";
import moment from "moment";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import CardPackagePurchaseHistoryUX from "./CardPackagePurchaseHistoryUX";
import CardAppointmentPackage, { CARD_APPOINTMENT_PACKAGE } from "./CardAppointmentPackage";

// Interface
import {
  MasterDataType,
  MasterOptionsType,
  ORDER_PAYMENT_STATUS,
  PACKAGE_SEARCH_AUH,
  PACKAGE_SEARCH_PH,
  PRODUCT_SEARCH_AUH,
  RunSequence,
  State,
  StatusesFilterType,
  USAGE_STATUS,
} from "./sequence/PackagePurchase";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// Types
type CardPackagePurchaseHistoryProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // seq
  runSequence: RunSequence;
  PackagePurchaseSequence?: State["PackagePurchaseSequence"];
  // data
  disabledOrderIds?: number[];
  selectable?: boolean;
  selectedDivision?: Record<string, any> | null;
  statusesFilter?: StatusesFilterType;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  masterData?: MasterDataType;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
  // callback
  onOpenHistory?: () => any;
  onSelectPackage?: (data: Record<string, any>) => any;
};

type HistoryDataType = {
  package_code: string;
  package_id: number;
  package_name: string;
  product_code: string;
  product_id: number;
  product_name: string;
};

// Const
const USAGE_OPTIONS = [
  { key: 1, text: "ไม่สมบูรณ์", value: USAGE_STATUS.INCOMPLETED },
  { key: 2, text: "สมบูรณ์", value: USAGE_STATUS.COMPLETED },
];

const BUTTON_ACTIONS = {
  print: "PRINT_PACKAGE_ORDER",
  search: "SEARCH",
  select: "SELECT_PACKAGE_ORDER",
};

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

const CARD_PACKAGE_PURCHASE_HISTORY = "CardPackagePurchaseHistory";

const ACTION_SELECT = `${CARD_PACKAGE_PURCHASE_HISTORY}_${BUTTON_ACTIONS.select}`;
const ACTION_SEARCH = `${CARD_PACKAGE_PURCHASE_HISTORY}_${BUTTON_ACTIONS.search}`;
const ACTION_PRINT = `${CARD_PACKAGE_PURCHASE_HISTORY}_${BUTTON_ACTIONS.print}`;

const CardPackagePurchaseHistory = (props: CardPackagePurchaseHistoryProps) => {
  const intl = useIntl();
  // Effect
  useEffect(
    () => () => {
      props.setProp(`searchedItemListWithKey.${PACKAGE_SEARCH_PH}`, []);
    },
    []
  );

  useEffect(() => {
    const order = props.PackagePurchaseSequence?.selectedPackageOrder;
    const list = props.PackagePurchaseSequence?.purchaseHistoryList || [];

    const findOrder = list.find((item) => item.id === order?.id);

    if (!!order && !findOrder) {
      props.setProp("PackagePurchaseSequence.selectedPackageOrder", null);
    }
  }, [
    props.PackagePurchaseSequence?.purchaseHistoryList,
    props.PackagePurchaseSequence?.selectedPackageOrder,
  ]);

  // Memo callback
  const filterPurchaseHistory = useMemo(
    () => props.PackagePurchaseSequence?.filterPurchaseHistory || {},
    [props.PackagePurchaseSequence?.filterPurchaseHistory]
  );

  // Use Callback
  const handleSelectedItem = useCallback(
    async (value: any) => {
      props.setProp("PackagePurchaseSequence.filterPurchaseHistory", {
        ...filterPurchaseHistory,
        product: value || null,
      });
    },
    [props.searchedItemListWithKey]
  );

  const handleClickHistory = useCallback(async (data: Partial<HistoryDataType>) => {
    const packageId = data.package_id;
    const productId = data.product_id;

    const initialFilter = async (id: string, key: "package" | "product") => {
      props.setProp(`searchedItemListWithKey.${id}`, [
        {
          id: data[`${key}_id`],
          code: data[`${key}_code`],
          name: data[`${key}_name`],
        },
      ]);

      props.setProp("PackagePurchaseSequence.filterAppointmentHistory", {
        [key]: data[`${key}_id`],
      });
    };

    if (packageId) {
      await initialFilter(PACKAGE_SEARCH_AUH, "package");
    } else if (productId) {
      await initialFilter(PRODUCT_SEARCH_AUH, "product");
    }

    props.onOpenHistory?.();
  }, []);

  const handleSelectRow = useCallback(
    (data: any, index: number) => {
      if (data) {
        props.runSequence({
          sequence: "PackagePurchase",
          action: "SELECT_PACKAGE_ORDER",
          card: CARD_PACKAGE_PURCHASE_HISTORY,
          index,
          isFetchDetail: props.selectable,
          orderId: data.id,
        });
      } else {
        props.setProp("PackagePurchaseSequence.selectedPackageOrder", null);
      }
    },
    [props.PackagePurchaseSequence?.purchaseHistoryList]
  );

  const handlePrint = (data: any, index: number) => {
    if (data) {
      props.runSequence({
        sequence: "PackagePurchase",
        action: "PRINT_PACKAGE_ORDER",
        card: CARD_PACKAGE_PURCHASE_HISTORY,
        index,
        orderId: data.id,
      });
    }
  };

  // Use Memo
  const serviceDivisionOptions = useMemo(() => {
    const divisions = new Set<number>(
      (props.masterData?.packageServiceType || []).flatMap((item) =>
        (item.divisions as number[]).map(Number)
      )
    );

    const options = (props.masterOptions?.division || []).filter((option) =>
      divisions.has(Number(option.value))
    );

    if (props.selectedDivision?.id) {
      const { id } = props.selectedDivision;

      options.push({
        key: id,
        text: props.selectedDivision.name,
        value: id,
      });
    }

    return [...new Map(options.map((item) => [item.value, item])).values()];
  }, [
    props.masterOptions?.division,
    props.masterOptions?.packageServiceType,
    props.selectedDivision,
  ]);

  const searchedItemListWithKey = useMemo(() => {
    const items: Record<string, any>[] = props.searchedItemListWithKey?.[PACKAGE_SEARCH_PH] || [];

    return {
      ...props.searchedItemListWithKey,
      [PACKAGE_SEARCH_PH]: items.map((item) => ({
        ...item,
        name_code: `[${item.code}] ${item.name}`,
      })),
    };
  }, [props.searchedItemListWithKey]);

  const selectedPackageOrder = useMemo(() => {
    const order = props.PackagePurchaseSequence?.selectedPackageOrder;
    const items: Record<string, any>[] = order?.items || [];

    if (order) {
      order.items = items.map((item) => ({
        ...item,
        balance: Number(item.quantity_left),
      }));
    }

    return order;
  }, [props.PackagePurchaseSequence?.selectedPackageOrder]);

  const allowedCancel = useMemo(
    () =>
      selectedPackageOrder?.usage_status === USAGE_STATUS.INCOMPLETED &&
      selectedPackageOrder.status === ORDER_PAYMENT_STATUS.BILLED,
    [selectedPackageOrder]
  );

  const isAppointmentSelectable = useMemo(
    () => props.selectable && selectedPackageOrder?.usage_status !== USAGE_STATUS.COMPLETED,
    [props.selectable]
  );

  const purchaseHistoryItems = useMemo(
    () =>
      (props.PackagePurchaseSequence?.purchaseHistoryList || []).map((item, index) => {
        const payments = {
          BILLED: "รอรับชำระ",
          PAID: "ชำระแล้ว",
        } as any;

        let color = "#1B9D2C";

        if (props.disabledOrderIds?.includes(item.id || "")) {
          color = "#BDBDBD";
        } else if (item.usage_status === USAGE_STATUS.INCOMPLETED) {
          color = "#DA0000";
        }

        return {
          ...item,
          date: formatDatetime(moment(item.created_at)),
          description: (
            <div style={GridCenter}>
              <Button
                color="blue"
                disabled={color === "#BDBDBD"}
                loading={!!props.buttonLoadCheck?.[`${ACTION_SELECT}_${index}`]}
                size="mini"
                style={{ fontSize: "0.75rem", padding: "0.60rem 0.75rem" }}
                // callback
                onClick={(e) => {
                  e.stopPropagation();

                  handleSelectRow(item, index);
                }}
              >
                รายละเอียด
              </Button>
            </div>
          ),
          print: (
            <div style={GridCenter}>
              <Button
                color="blue"
                disabled={color === "#BDBDBD"}
                loading={!!props.buttonLoadCheck?.[`${ACTION_PRINT}_${index}`]}
                size="mini"
                style={{ fontSize: "0.75rem", padding: "0.60rem 0.75rem" }}
                // callback
                onClick={(e) => {
                  e.stopPropagation();

                  handlePrint(item, index);
                }}
              >
                พิมพ์รายละเอียด
              </Button>
            </div>
          ),
          history: (
            <div style={GridCenter}>
              <Button
                color="grey"
                icon="history"
                size="mini"
                style={{ fontSize: "0.75rem" }}
                onClick={(e) => {
                  e.stopPropagation();

                  handleClickHistory({
                    package_code: item.product_code,
                    package_id: item.product,
                    package_name: item.product_name,
                  });
                }}
              />
            </div>
          ),
          payment: <div style={GridCenter}>{payments[item.status]}</div>,
          price: (
            <div style={GridCenter}>
              {Number(item.price).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "decimal",
              })}
            </div>
          ),
          quantity: <div style={GridCenter}>{item.quantity}</div>,
          usage: <div style={{ ...GridCenter, color }}>{item.usage_status_label}</div>,
        };
      }),
    [
      props.buttonLoadCheck,
      props.disabledOrderIds,
      props.PackagePurchaseSequence?.purchaseHistoryList,
    ]
  );

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    const value = data.value === undefined ? data.checked : data.value;

    const detail = {
      ...props.PackagePurchaseSequence?.filterPurchaseHistory,
    };

    (detail as any)[data.name] = value;

    props.setProp("PackagePurchaseSequence.filterPurchaseHistory", {
      ...detail,
    });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "PackagePurchase",
      action: "SEARCH",
      card: CARD_PACKAGE_PURCHASE_HISTORY,
      statusesFilter: props.statusesFilter,
    });
  };

  const handleClear = () => {
    if (props.selectable) {
      const currentFilter = props.PackagePurchaseSequence?.filterPurchaseHistory || {};

      props.setProp("PackagePurchaseSequence.filterPurchaseHistory", {
        division: currentFilter.division,
      });

      return;
    }

    // ถ้า selectable เป็นเท็จ ให้ล้างค่าทั้งหมด
    props.setProp("PackagePurchaseSequence.filterPurchaseHistory", {});
  };

  const handleGetTrProps = (state: any, rowInfo?: RowInfo) => {
    const disabled = props.disabledOrderIds?.includes(rowInfo?.original?.id || "");

    return {
      // onClick: () => {
      //   if (!disabled) {
      //     handleSelectRow(rowInfo);
      //   }
      // },

      className:
        !!selectedPackageOrder?.id && rowInfo?.original?.id === selectedPackageOrder.id
          ? "blueSelectedRow"
          : "",
      style: {
        color: disabled ? "#BDBDBD" : "",
      },
    };
  };

  const handleSelectPackage = (data: any) => {
    props.onSelectPackage?.({
      ...selectedPackageOrder,
      items: data,
    });
  };

  const handleCancelPackage = (reason: any) => {
    props.runSequence({
      sequence: "PackagePurchase",
      action: "CANCEL_PACKAGE",
      card: CARD_APPOINTMENT_PACKAGE,
      data: {
        orderId: selectedPackageOrder?.id,
        packageId: selectedPackageOrder?.product,
        quantity: selectedPackageOrder?.quantity,
        reason,
      },
      statusesFilter: props.statusesFilter,
    });
  };

  const handleCloseSelectOrder = () => {
    props.setProp("PackagePurchaseSequence.selectedPackageOrder", null);
  };

  console.log("CardPackagePurchaseHistoryUX", props, selectedPackageOrder);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_APPOINTMENT_PACKAGE]}
        success={null}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_APPOINTMENT_PACKAGE}`, null);
        }}
        languageUX={props.languageUX}
      />

      <CardPackagePurchaseHistoryUX
        // data
        filterPurchaseHistory={filterPurchaseHistory}
        purchaseHistoryItems={purchaseHistoryItems}
        selectable={props.selectable}
        packageServiceTypeOptions={props.masterOptions?.packageServiceType}
        // options
        packageTypeOptions={props.masterOptions?.packageType}
        serviceDivisionOptions={serviceDivisionOptions}
        usageOptions={USAGE_OPTIONS}
        // table
        getTrProps={handleGetTrProps}
        // callback
        onChangeValue={handleChangeValue}
        onClear={handleClear}
        // Element
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            // data
            paramKey={ACTION_SEARCH}
            size="medium"
            title={intl.formatMessage({ id: "ค้นหา" })}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
            onClick={handleSearch}
          />
        }
        // SearchBoxPackageType={
        //   <SearchBoxDropdown
        //     onEvent={props.onEvent}
        //     // config
        //     type="PackageType"
        //     id="1"
        //     style={{ width: "100%" }}
        //     fluid={true}
        //     useWithKey={true}
        //     icon="search"
        //     limit={20}
        //     // Select
        //     searchedItemListWithKey={props.searchedItemListWithKey}
        //     selectedItem={null}
        //     setSelectedItem={handleSelectedItem}
        //     // options
        //     mapOptions={mapPackageOptions}
        //   />
        SearchBoxPackageName={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            id="PH"
            icon="search"
            limit={20}
            selectedItem={filterPurchaseHistory.product || null}
            style={{ width: "100%" }}
            // config
            type="Package"
            fluid
            inline
            useWithKey
            // Select
            searchedItemListWithKey={searchedItemListWithKey}
            setSelectedItem={handleSelectedItem}
            onAdditionalUrlParams={() => ({ active: true })}
          />
        }
        languageUX={props.languageUX}
      />

      {selectedPackageOrder?.id && (
        <div style={{ paddingBottom: props.selectable ? "0" : "2rem" }}>
          <CardAppointmentPackage
            setProp={props.setProp}
            // data
            data={selectedPackageOrder.items}
            // config
            isCancel={allowedCancel}
            selectable={isAppointmentSelectable}
            showQty={props.selectable}
            usageLimit={selectedPackageOrder.usage_limit}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            // options
            masterOptions={props.masterOptions}
            // callback
            onCancel={handleCancelPackage}
            onClose={handleCloseSelectOrder}
            onHistory={handleClickHistory}
            onSelect={handleSelectPackage}
            languageUX={props.languageUX}
          />
        </div>
      )}
    </div>
  );
};

CardPackagePurchaseHistory.displayName = "CardPackagePurchaseHistory";

export default React.memo(CardPackagePurchaseHistory);
