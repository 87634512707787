import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  Icon,
  Input,
  Modal,
  Pagination,
  PaginationProps,
} from "semantic-ui-react";

// Framework
import { Table } from "react-lib/frameworks/Table";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import CardLayout from "react-lib/apps/common/CardLayout";
import { formatPrice } from "react-lib/apps/common/PureReactTable";
import { useIntl } from "react-intl";

import {
  PRODUCT_PACKAGE_LIMIT,
  PRODUCT_TYPES,
  ProductForPackageSerializer,
  ProductTypeKey,
  SearchedProductList,
} from "./sequence/SettingPackage";

// Types
type CardProductSearchProps = {
  setProp: any;
  // data
  data?: SearchedProductList;
  type: ProductTypeKey;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onSearch: OnSearchHandler;
  onSelected: (selectedItems: ProductForPackageSerializer[]) => any;
};

export type OnSearchHandler = (data: {
  card: string;
  params: { activePage?: number; searchText: string; type: ProductTypeKey };
}) => void;

type Styles = Record<"button" | "formField" | "formGroup" | "table", CSSProperties>;

const styles: Styles = {
  button: { marginLeft: "1rem" },
  formField: { marginRight: "2rem", minWidth: "max-content" },
  formGroup: { marginTop: "-0.25rem" },
  table: { height: "450px" },
};

const PAGINATION_CONFIG = {
  ellipsis: {
    icon: true,
    content: <Icon name="ellipsis horizontal" />,
  },
  first: {
    icon: true,
    content: <Icon name="angle double left" />,
  },
  last: {
    icon: true,
    content: <Icon name="angle double right" />,
  },
  next: { icon: true, content: <Icon name="angle right" /> },
  prev: { icon: true, content: <Icon name="angle left" /> },
};

const CARD_PRODUCT_SEARCH = "CardProductSearch";

const CardProductSearch = (props: CardProductSearchProps) => {
  const intl = useIntl();
  // mod
  const [open, setOpen] = useState<boolean>(false);
  // data
  const [searchText, setSearchText] = useState<string>("");
  // selected
  const [checkedItems, setCheckedItems] = useState<ProductForPackageSerializer[]>([]);

  // Effect
  useEffect(() => {
    setSearchText("");
  }, [props.type]);

  // Callback
  const updateCheckedItems = (
    prevChecked: ProductForPackageSerializer[],
    data: ProductForPackageSerializer,
    isChecked: boolean
  ): ProductForPackageSerializer[] => {
    if (isChecked) {
      return [...prevChecked, data];
    }

    return prevChecked.filter((item) => item.id !== data.id);
  };

  const handleChecked = useCallback(
    (data: ProductForPackageSerializer) => (e: SyntheticEvent, v: CheckboxProps) => {
      setCheckedItems((prevChecked) => updateCheckedItems(prevChecked, data, !!v.checked));
    },
    []
  );

  const handleChange = useCallback((e: any, data: any) => {
    setSearchText(data.value);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setCheckedItems([]);
  }, []);

  const handleSearch = useCallback(() => {
    props.onSearch({
      card: CARD_PRODUCT_SEARCH,
      params: { searchText, type: props.type },
    });
  }, [props.type, searchText]);

  const handleOpen = useCallback(() => {
    setOpen(true);

    handleSearch();
  }, [handleSearch]);

  const handleAddItem = useCallback(() => {
    props.onSelected(checkedItems);

    handleClose();
  }, [checkedItems, handleClose]);

  const handleOnKeyPress = useCallback(
    (ev: { key: string; preventDefault: () => void }) => {
      if (ev.key === "Enter") {
        handleOpen();
      }
    },
    [handleOpen]
  );

  const handlePageChange = useCallback(
    (e: SyntheticEvent, data: PaginationProps) => {
      props.onSearch({
        card: CARD_PRODUCT_SEARCH,
        params: { activePage: Number(data.activePage), searchText, type: props.type },
      });
    },
    [props.type, searchText]
  );

  // Memo
  const titleText = useMemo(() => `ค้นหารายการ${PRODUCT_TYPES[props.type].title}`, [props.type]);

  const tableConfig = useMemo(
    () =>
      props.type === "ROOM"
        ? {
            headers: ",Service Code,Product Name,ชั่วโมงสูงสุดสำหรับอัตราค่าห้องพัก,ราคา",
            keys: "checkbox,code,name,max_hours,unit_price",
            widths: "60,150,^250,150,150",
          }
        : {
            headers: ",Service Code,Product Name,ราคา",
            keys: "checkbox,code,name,unit_price",
            widths: "60,150,^250,150",
          },
    [props.type]
  );

  const items = useMemo(
    () =>
      props.data?.items.map((item, index) => {
        const isChecked = checkedItems.find((accumulator) => accumulator.id === item.id);

        return {
          ...item,
          checkbox: (
            <div style={{ display: "grid", placeContent: "center" }}>
              <Checkbox id={`CardProductSearch-Checkbox-Selected-${index}`} checked={!!isChecked} onChange={handleChecked(item)} />
            </div>
          ),
          max_hours: <div style={{ textAlign: "center" }}>{item.max_hours}</div>,
          unit_price: formatPrice(item.unit_price),
        };
      }),
    [checkedItems, props.data]
  );

  return (
    <div style={{ display: "flex" }}>
      <Input id="CardProductSearch-Input-ProductSearch" value={searchText} onChange={handleChange} onKeyPress={handleOnKeyPress} />

      <Button id="CardProductSearch-Button-ProductSearch" color="blue" style={styles.button} onClick={handleOpen}>
        ค้นหา
      </Button>

      <Modal open={open} closeOnDimmerClick onClose={handleClose}>
        <CardLayout
          closeable={false}
          headerColor=""
          titleText={titleText}
          toggleable={false}
          hideHeaderIcon
        >
          {/* @ts-ignore */}
          <Form>
            <Form.Group style={styles.formGroup} inline>
              <Form.Field style={styles.formField}>
                <span>{intl.formatMessage({ id: "ชื่อหรือรหัส" })}</span>
              </Form.Field>
              <Form.Field width={15}>
                <Input value={searchText} onChange={handleChange} />
              </Form.Field>
              <Form.Field>
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  // config
                  color={"blue"}
                  // data
                  paramKey={CARD_PRODUCT_SEARCH}
                  size="medium"
                  title={intl.formatMessage({ id: "ค้นหา" })}
                  buttonLoadCheck={props.buttonLoadCheck?.[CARD_PRODUCT_SEARCH]}
                  onClick={handleSearch}
                />
              </Form.Field>
            </Form.Group>
          </Form>

          <Table
            data={items}
            headers={tableConfig.headers}
            keys={tableConfig.keys}
            minRows={12}
            showPagination={false}
            // #getTheadThProps={handleGetTopTheadThProps}
            style={styles.table}
            // callback
            widths={tableConfig.widths}
          />

          <div style={{ display: "flex", justifyContent: "flex-end", margin: "0.5rem 0 1.5rem" }}>
            <Pagination
              activePage={props.data?.activePage || 1}
              ellipsisItem={PAGINATION_CONFIG.ellipsis}
              firstItem={PAGINATION_CONFIG.first}
              lastItem={PAGINATION_CONFIG.last}
              nextItem={PAGINATION_CONFIG.next}
              prevItem={PAGINATION_CONFIG.prev}
              size="mini"
              totalPages={Math.ceil((props.data?.total || 0) / PRODUCT_PACKAGE_LIMIT)}
              // callback
              onPageChange={handlePageChange}
            />
          </div>
          {/* @ts-ignore */}
          <Form>
            <Form.Group className="rightAlign" style={{ margin: "1rem 0 0" }} inline>
              <Form.Field width={2}>
                <Button color="green" content={intl.formatMessage({ id: "เลือก" })} type="button" fluid onClick={handleAddItem} />
              </Form.Field>
              <Form.Field width={2}>
                <Button color="red" content={intl.formatMessage({ id: "ยกเลิก" })} type="button" fluid onClick={handleClose} />
              </Form.Field>
            </Form.Group>
          </Form>
        </CardLayout>
      </Modal>
    </div>
  );
};

CardProductSearch.displayName = "CardProductSearch";

export default React.memo(CardProductSearch);
