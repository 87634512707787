import { isMoment } from "moment";
import React, {
  CSSProperties,
  useEffect,
  useState,
} from "react";
import { Table, Form, Icon } from "semantic-ui-react";
import _ from "../../compat/lodashplus";
import "./SemanticTable.scss";
import { FormattedMessage, useIntl } from "react-intl";

type SpanType = {
  rowSpan?: 0 | 1 | 2;
  colSpan?: number;
}

type getPropsType = {
  className?: string;
  style?: CSSProperties;
  onClick?: (ev: HTMLElement, row?: any) => void
} & SpanType;

type CellArray = ({
  Cell?: JSX.Element,
  getProps?: getPropsType
} | JSX.Element)[]

type ColumnsProps = {
  Header?: string;
  accessor?: string;
  Cell?: ((row: any) => JSX.Element) | ((row: any) => CellArray);
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  getProps?: (row: any) => getPropsType;
  columns?: ColumnsProps[];
} & SpanType;

type TablePropsType = {
  data: any[];
  columns: ColumnsProps[];
  pageSize?: number;
  headerLevel?: number;
  toggleChildBy?: string;
  selectedRow?: any;
  defaultToggle?: boolean;
  tableHeight?: string;
};

type TableStateType = {
  isLoading: boolean
  toggleRow: number[],
  defaultToggle: boolean
  data: any[]
  isChange: boolean,
  toggleTab?: number;
  isMount: boolean;
  tableScrollTop: number
}

type HeaderStateType = {
  item: ColumnsProps;
  index: number;
  span: SpanType;
  prevItem: ColumnsProps;
  headerLevel: number;
};

type RowStateType = {
  clearValue?: boolean;
  tableRef?: React.RefObject<HTMLTableElement>
  tabIndex?: number;
  level: number;
  thisProps: any;
  headerLevel: number;
  rowChild?: number;
  isChange: boolean;
  toggleTab: number;
  defaultToggle?: boolean
} & Omit<TablePropsType, "headerLevel">;

type CellStateType = {
  data: any;
  toggleChildBy?: string;
} & Omit<HeaderStateType, "span" | "prevItem">
  & RowStateType;

const CELL_WIDTH = ''

const styleCellWidth = (item: ColumnsProps) => {
  let width = ""
  if (!item.minWidth) {
    width = item.width ? `${item.width}px` : CELL_WIDTH
  }
  return {
    width,
    minWidth: item.minWidth ? `${item.minWidth}px` : "",
    maxWidth: item.maxWidth ? `${item.maxWidth}px` : `${item.width}px`,
  }
}

// Create cell table form (TD)
class Cell extends React.Component<CellStateType>  {

  componentDidMount() {
    let byLevel = ""
    if (typeof this.props.rowChild === "undefined") {
      byLevel = ""
    } else {
      byLevel = `[rowchild="${this.props.rowChild}"]`
    }
    const rowTarget = this.props.tableRef?.current?.querySelector(
      `td[index="${this.props.index}"][row="${this.props.tabIndex}"][level="${this.props.level}"]${byLevel}`) as HTMLElement

    const headerTarget = this.props.tableRef?.current?.querySelector(
      `th[index="${this.props.index}"][headerlevel="${this.props.level}"]`) as HTMLElement

    if (headerTarget && rowTarget &&
      headerTarget?.offsetHeight !== rowTarget?.offsetHeight) {
      const styleWidth = styleCellWidth({
        ...this.props.item,
        width: headerTarget.offsetWidth
      })

      //@ts-ignore
      const getLevel = +rowTarget.getAttribute("level")
      if (this.props.level === getLevel && getLevel === 0) {
        this.onSetWidthElement(rowTarget, styleWidth)
      }
    }
  }
  handleClick(item: any, ev: HTMLElement) {
    if (item?.getProps) {
      if (typeof item.getProps === "function") {
        /* eslint-disable */
        item.getProps(this.props.data)?.onClick && item.getProps(this.props.data)?.onClick(ev, this.props.data, item)
      }
      else {
        /* eslint-disable */
        item.getProps?.onClick && item.getProps?.onClick(ev, this.props.data, item)
      }
    }
  }
  handleDoubleClick(item: any, ev: HTMLElement) {
    if (item?.getProps) {
      if (typeof item.getProps === "function") {
        /* eslint-disable */
        item.getProps(this.props.data)?.onDoubleClick && item.getProps(this.props.data)?.onDoubleClick(ev, this.props.data, item)
      }
      else {
        /* eslint-disable */
        item.getProps?.onDoubleClick && item.getProps?.onDoubleClick(ev, this.props.data, item)
      }
    }
  }
  /**
   * Create sub cell
   * @param element
   */
  createCellArray(element: CellArray) {


    // Map: element sub cell
    return element.map((elm, index) =>

      <div
        key={`sub-${index + this.props.tabIndex + this.props.index}`}
        //@ts-ignore
        {...(elm?.getProps || {})}
        onDoubleClick={(ev: HTMLElement) => {
          this.handleDoubleClick(elm, ev)
        }}
        onClick={(ev: HTMLElement) => {
          this.handleClick(elm, ev)
        }}
        ref={(instance) => {
          if (instance && this.props.isChange) {
            const parent = instance.parentElement
            const prevElement = parent.previousElementSibling
            let minHeight = ~~(parent.offsetHeight / element.length)

            if (typeof this.props.toggleTab === "number" && this.props.toggleTab !== this.props.tabIndex) {
              return
            }
            if (prevElement.getAttribute("status") === "split") {

              const prevChild = prevElement.childNodes[index] as HTMLElement
              // console.log(minHeight, prevChild, instance, prevChild.offsetHeight, instance.offsetHeight)
              if (element.length === 1 && parent.offsetHeight === minHeight) {
                instance.style.minHeight = `${parent.clientHeight}px`
                return
              }
              if (~~prevChild.offsetHeight >= ~~instance.offsetHeight) {
                const height = minHeight > prevChild.offsetHeight ? minHeight : prevChild.offsetHeight
                instance.style.minHeight = `${height}px`
              }

              if (~~prevChild.offsetHeight < ~~instance.offsetHeight) {
                let i = prevElement;
                while (i.getAttribute("status") === "split") {
                  //@ts-ignore
                  i.childNodes[index].style.minHeight = `${instance.offsetHeight}px`
                  i = i.previousElementSibling
                }
              }
              // if (~~prevChild.previousElementSibling?.offsetHeight < ~~instance.previousElementSibling?.offsetHeight) {
              //   let x = prevElement;
              //   while (x?.getAttribute("status") === "split") {
              //     //@ts-ignore
              //     x.childNodes[index - 1].style.minHeight = `${prevChild.previousElementSibling?.offsetHeight}px`
              //     x = x.nextElementSibling
              //   }
              // }
            }

            if (prevElement.getAttribute("status") === "") {

              if (minHeight === parent.offsetHeight) {
                instance.style.minHeight = `${minHeight}px`
              }
              if ((minHeight) * (element.length) <= parent.clientHeight) {
                instance.style.minHeight = `${minHeight}px`
              }
              else if (minHeight * element.length === parent.offsetHeight) {
                instance.style.minHeight = `${minHeight}px`
              }
            }
            if (instance.offsetHeight < 35) {
              minHeight = minHeight < 35 ? 35 : minHeight
              instance.style.minHeight = `${minHeight}px`
            }
            if (!instance.style.minHeight) {
              instance.style.minHeight = `${instance.clientHeight}px`
            }
          }
        }
        }
        status="split"
        style={{

          borderBottom:
            index === element.length - 1 ? "" : "1px solid rgba(34,36,38,.05)",
          padding: "0.2em 0.25em",
          //@ts-ignore
          ...(elm?.getProps?.style || {}),
          display: "flex",
          minHeight: ""
        }}
      >
        {/* @ts-ignore */}
        {elm.Cell || (elm.$$typeof && elm)}
      </div>

    );
  }
  /**
   * Handle: toggle row for show/hidden child data
   * @param thisProps - this parent component
   * @param tabIndex - index row
   */
  handleToggleRow(thisProps: any, tabIndex: number) {

    (thisProps.state.defaultToggle) && thisProps.setState({
      defaultToggle: false
    })

    const toggleIcon = document.querySelector(`.toggle-icon.i-${tabIndex}`) as HTMLElement

    if (thisProps.state.toggleRow.includes(tabIndex)) {
      thisProps.setState({
        toggleRow: thisProps.state.toggleRow.filter(
          (acc: number) => acc !== tabIndex),
        isChange: false,
        toggleTab: null
      })
      toggleIcon.style.transform = "rotate(0deg)"
      toggleIcon.style.transition = "all 300ms ease 0s"

    } else {
      thisProps.setState({
        toggleRow: [...thisProps.state.toggleRow, tabIndex],
        isChange: true,
        toggleTab: tabIndex
      })
      toggleIcon.style.transform = "rotate(90deg)"
    }

  }

  onSetWidthElement(elm: HTMLElement, styleWidth: any) {
    elm.style.width = styleWidth.width
    elm.style.maxWidth = styleWidth.maxWidth
    elm.style.minWidth = styleWidth.minWidth
    elm.style.display = ""
  }

  render() {
    const { item, data, index, tabIndex, toggleChildBy, level, thisProps, rowChild } = this.props;

    const headerTarget = this.props.tableRef?.current?.querySelector(
      `th[index="${this.props.index}"][headerlevel="${level}"]`) as HTMLElement
    let itemx = styleCellWidth(item)
    if (typeof tabIndex !== "undefined" && headerTarget) {
      const styleWidth = styleCellWidth({
        ...item,
        width: headerTarget.offsetWidth
      })
      itemx = styleWidth
    }

    return (
      <Table.Cell
        index={index}
        row={tabIndex}
        {...(item.getProps ? item.getProps(data) : {})}
        onDoubleClick={(cellProps: HTMLElement) => {

          this.handleDoubleClick(item, cellProps)
        }}
        onClick={(cellProps: HTMLElement) => {
          this.handleClick(item, cellProps)
          //want show child data
          if (index === 0 && level === 0 && toggleChildBy && tabIndex !== undefined) {
            this.handleToggleRow(thisProps, tabIndex)
          }
        }}
        rowchild={rowChild}
        level={level}
        style={{
          ...itemx,

          padding: item.Cell && item.Cell(data) instanceof Array ? "0px" : "",
          cursor: index === 0 && toggleChildBy && level === 0 ? "pointer" : "",
          userSelect: index === 0 && toggleChildBy && level === 0 ? "none" : "",
          ...(item.getProps ? {
            ...item.getProps(data)?.style,
            cursor: this.props.clearValue ? "" : item.getProps(data)?.style.cursor
          } : {}),
          // display: clearValue ? "" : "none",
          // paddingBottom:
          // item.Cell && item.Cell(data) instanceof Array ? "0.05em" : "",

        }}
        className={item.getProps ? item.getProps(data)?.className : ""}
        status={item.Cell && item.Cell(data) instanceof Array ? "split" : ""}
      >
        {
          item.Cell ? (
            //@ts-ignore
            item.Cell(data) instanceof Array ? (
              //@ts-ignore
              this.createCellArray(item.Cell(data))
            ) : index === 0 && level === 0 && toggleChildBy ? (
              <Form >
                <Form.Group inline style={{ margin: "0" }}>
                  <Form.Field style={{
                    paddingRight: "0px",
                    marginLeft: "-8px"
                  }} className="form-icon">
                    <Icon
                      name="caret right"
                      className={`toggle-icon i-${tabIndex}`}
                      style={this.props.defaultToggle ?
                        {
                          transform: "rotate(90deg)",
                          transition: "all 300ms ease 0s"
                        } : {}} />
                  </Form.Field>
                  <Form.Field>
                    {item.Cell(data)}
                  </Form.Field>
                </Form.Group>

              </Form>
            ) : (
              item.Cell(data)
            )
          ) : (
            data?.[item.accessor || ""]
          )
        }
      </Table.Cell >
    );
  }
};

// Create row table form (TR)
class Row extends React.Component<RowStateType> {

  sumIndex: number = 0
  constructor(props: RowStateType) {
    super(props)
  }
  /**
   * Create cell form
   * @return {JSX.Element}
   */
  createCell(
    item: ColumnsProps,
    index: number,
    parent?: ColumnsProps,

  ) {
    const width: number = item.width || parent?.width || 100;
    const headerHeight =
      this.props.tableRef?.current && this.props.pageSize
        ? (this.props.tableRef.current.offsetHeight / this.props.pageSize) * 1.15
        : 15;

    const itemProps: ColumnsProps = !this.props.clearValue
      ? {
        ...item,
        width,
      }
      : {
        width,
        getProps: () => ({
          style: {
            ...(item?.getProps ? item.getProps(this.props.data).style : {}),
            background: "white",
            height: `${headerHeight}px`,
          },
        }),
      };
    return (
      <Cell
        {...this.props}
        index={index}
        key={`cell-${this.props.tabIndex}-${index}`}
        item={itemProps}
        headerLevel={this.props.headerLevel}
      />
    );
  };

  /**
   * Calculate the sum of width cell
   */
  calculateSumWidth(col: ColumnsProps): number {
    let width = col?.width || 0;
    if (col?.columns) {
      width = col.columns.reduce((result: number, acc) => {
        return (result += acc?.width || acc?.minWidth || 100);
      }, 0);
    }
    return width;
  };

  /**
   * Create cell by reference to the header level
   */
  checkHeaderLevel(
    col: ColumnsProps,
    index: number,
    prevCol: ColumnsProps,
    nextCol: ColumnsProps
  ) {
    if (this.props.headerLevel === 0 && col.columns) {
      let width = col.width || col.minWidth;
      if (!width) {
        width = this.calculateSumWidth(col);
      }
      setTimeout(() => {
        const tdTarget = this.props.tableRef?.current?.querySelector(
          `td[index="${index}"][class="item_remove"]`) as HTMLElement
        if (tdTarget) {
          tdTarget.remove()
        }
      }, 10);
      return this.createCell(
        {
          ...col,
          width,
          getProps: prevCol?.rowSpan ?
            () => ({
              className: prevCol?.rowSpan ? "item_remove" : "",
              style: {
                display: prevCol?.rowSpan ? "none" : "",
              },
            }) : col.getProps
        },
        index,
      );
    }
    else if (!this.props.headerLevel) {
      return this.createCell(
        {
          ...col,
          width:
            col.rowSpan && col.width
              ? col.width + this.calculateSumWidth(nextCol)
              : col.width,
        },
        index,
      );
    }
    if (this.props.headerLevel === 1) {
      if (col?.columns) {
        let width = this.calculateSumWidth(nextCol);
        return _.map(col?.columns, (item, idx) => {
          idx = idx - this.sumIndex
          item.colSpan && (this.sumIndex += 1)
          if (this.sumIndex > 1) {
            this.sumIndex = 0
          }
          if (index + idx < 0) {
            this.sumIndex = 0
            idx = 0
          }
          return this.createCell(
            item.colSpan
              ? {
                ...item,
                width: (col.width || 0) + width,
              }
              : item,
            index + idx,
            col
          )
        }
        );
      } else if (!col.colSpan && !col.rowSpan) {
        return this.createCell(col, index)
      }
    }
  }

  render() {
    const { columns } = this.props;
    return (
      columns.map((col, index) =>
        this.checkHeaderLevel(col, index, columns[index - 1], columns[index + 1])
      )
    );
  }

};

// Create header cell table form (TH)
class HeaderCell extends React.Component<HeaderStateType>{

  render() {
    const { index, item, span, prevItem, headerLevel } = this.props
    return (
      <Table.HeaderCell
        index={index}
        headerlevel={headerLevel}
        {...span}
        style={{
          ...styleCellWidth(item),
          display: prevItem?.rowSpan ? "none" : "",
          background: "var(--primary-theme-color, #2f3e53)",
        }}
      >
        {item.Header}
      </Table.HeaderCell>
    );
  }
};

class SemanticTable extends React.Component<TablePropsType, TableStateType>{
  private tableRef: React.RefObject<HTMLTableElement> = React.createRef();

  state = {
    isLoading: false,//* loading for setValue tableRef
    toggleRow: [],//* keep index row to display child data
    defaultToggle: false,
    data: [],
    isChange: true,
    toggleTab: null,
    isMount: false,
    tableScrollTop: 0
  }
  handleViewportHandler: any
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: true
      })
      this.calculateTableWidth();
      this.handleResizeHeader()
      this.setState({ defaultToggle: this.props.defaultToggle || false })
    }, 20);
    setTimeout(() => {
      this.setState({
        isMount: true
      })
    }, 100);
    this.handleViewportHandler = (e: any) => this.viewportHandler(e.currentTarget);
    //@ts-ignore
    window.visualViewport.addEventListener("resize", this.handleViewportHandler);
  }
  componentDidUpdate(prevProps: TablePropsType, prevState: TableStateType) {
    const tbody = this.tableRef.current?.querySelector("tbody");

    if (tbody && this.state.tableScrollTop) {
      tbody.scrollTop = this.state.tableScrollTop;
    }

    if (!_.isEqual(this.props.data, this.state.data)) {
      const tableScrollTop = this.state.tableScrollTop || tbody?.scrollTop || 0;

      this.setState(
        {
          isLoading: false,
          isChange: true,
          tableScrollTop,
        },
        () => {
          this.setState({
            data: this.props.data,
            isChange: true,
            isLoading: true,
          });

          const scrollHeight = tbody?.scrollHeight;
          const offsetHeight = tbody?.offsetHeight;
          const thead = this.tableRef.current.querySelector("thead");
          if (thead) {
            if (scrollHeight <= offsetHeight) {
              thead.style.paddingRight = "5px";
            } else {
              thead.style.paddingRight = "14px";
            }
          }
        }
      );

      if (this.props.defaultToggle) {
        const { length } = this.props.data
        this.setState({
          toggleRow: _.range(length)
        })
      }
    } else {
      if (this.state.isMount) {
        this.state.isChange &&
          this.setState({
            isChange: false,
          });
      }

      if (this.state.tableScrollTop) {
        this.setState({
          tableScrollTop: 0,
        });
      }
    }
  }

  componentWillUnmount() {
    window.visualViewport.removeEventListener("resize", this.handleViewportHandler);
  }

  viewportHandler(event: any) {
    // NOTE: This doesn't actually work at time of writing
    this.setState({
      isChange: true
    })
  }

  handleResizeHeader() {
    let thElm: HTMLDivElement | undefined;
    let startOffset: HTMLDivElement | number;
    if (this.tableRef?.current) {
      Array.prototype.forEach.call(
        this.tableRef.current.querySelectorAll("table th"),
        function (th) {
          th.style.position = "relative";

          var grip = document.createElement("div") as HTMLDivElement;
          grip.innerHTML = "&nbsp;";
          grip.style.top = "0";
          grip.style.right = "0";
          grip.style.bottom = "0";
          grip.style.width = "10px";
          grip.style.position = "absolute";
          grip.style.cursor = "col-resize";
          grip.addEventListener("mousedown", function (e) {
            thElm = th;

            startOffset = th.offsetWidth - e.pageX;
          });
          th.appendChild(grip);
        }
      );
    }

    const onMouseMove = (e: any) => {
      if (thElm) {
        thElm.style.width = startOffset + e.pageX + "px";
      }
    };

    const onMouseUp = () => {
      if (thElm) {
        this.setState({
          isLoading: true
        })
      }
      thElm = undefined;
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }
  /**
   * Calculate table width
   */
  calculateTableWidth() {
    let width: string | number = this.props.columns.reduce((result: number, column) => {
      return (
        result +
        (column.columns
          ? column.columns.reduce(
            (colResult: number, acc) => colResult + (acc.width || 100),
            0
          )
          : column.width || 100)
      );
    }, 0);
    width = width > 1350 ? `${width}px` : "1350px"
    if (this.tableRef?.current) {
      const tbody = this.tableRef.current?.querySelector("tbody")
      tbody && (tbody.style.minWidth = width)
      const thead = this.tableRef.current.querySelector("thead")
      thead && (thead.style.minWidth = width)
      const table = this.tableRef.current.querySelector("table")
      table && (table.style.minWidth = width)
    }
  };

  // List sub columns
  columnsList() {
    let index = 0;
    return _.map(
      _.without(_.flatten(_.map(this.props.columns, (col) => col.columns)), undefined),
      (acc: any) => {
        const value = {
          ...acc,
          index,
        };
        acc?.colSpan ? (index += acc.colSpan) : (index += 1);
        return value;
      }
    );
  }

  render() {
    const isMoreColums = this.columnsList().length > 0;
    let { pageSize = 10,
      headerLevel = 0,
      selectedRow = null,
    } = this.props
    let { data } = this.state
    headerLevel = headerLevel > 0 ? 1 : 0
    pageSize = data[0] ? data.length : pageSize
    data = Array.isArray(data) ? data : []
    return (
      <div
        className="medication-admin-custom-table"
        ref={this.tableRef}
        style={{ height: this.props.tableHeight }}>
        <Table celled striped selectable role="grid" aria-labelledby="header">
          <Table.Header
            style={{ background: "var(--primary-theme-color, #2f3e53)" }}
          >
            {/* First header level */}
            <Table.Row>
              {this.props.columns.map((col, index) => (
                <HeaderCell
                  key={`hc-${index}`}
                  index={index}
                  item={col}
                  prevItem={this.props.columns[index - 1]}
                  span={
                    col.columns
                      ? {
                        colSpan: col.colSpan || col.columns.length,
                      }
                      : {
                        rowSpan: col.rowSpan || (isMoreColums ? 2 : 1),
                        colSpan: col.colSpan || 0,
                      }
                  }
                  headerLevel={0}
                />
              ))}
            </Table.Row>
            {/* Second header level */}
            <Table.Row>
              {this.columnsList().map((col: any, index: number) => (
                <HeaderCell
                  key={`shc-${index}`}
                  index={index}
                  item={col}
                  prevItem={this.columnsList()[index - 1]}
                  span={{
                    colSpan: col?.colSpan ? col.colSpan : 1,
                  }}
                  headerLevel={1}
                />
              ))}
            </Table.Row>
          </Table.Header>

          {this.state.isLoading && (
            <Table.Body>
              {!data.length && <div className="not-found"><FormattedMessage id="กรุณาทำการค้นหาผู้ป่วยด้วย HN เพื่อแสดงรายการยา"/></div>}

              {data?.map((acc, idx) => {
                return (
                  <>
                    <Table.Row
                      key={`row-${idx}`}
                      //@ts-ignore
                      className={
                        this.state.toggleRow.includes(idx) ||
                          JSON.stringify(acc) === JSON.stringify(selectedRow)
                          ? "toggle-selected"
                          : ""
                      }
                    // tabIndex={idx === 0 ? 0 : -1}
                    >
                      <Row
                        tableRef={this.tableRef}
                        tabIndex={idx}
                        {...this.props}
                        data={acc}
                        headerLevel={headerLevel}
                        level={0}
                        thisProps={this}
                        isChange={this.state.isChange}
                        toggleTab={this.state.toggleTab}
                      />
                    </Table.Row>
                    {
                      // Show child data
                      //@ts-ignore
                      this.state.toggleRow.includes(idx) &&
                      acc[
                        this.props.toggleChildBy?.split(".")?.[0] || ""
                      ]?.map((child: any, index: number) => {
                        //@ts-ignore
                        const split = this.props.toggleChildBy.split(".");
                        const isHasProps = split[1]
                          ? child.hasOwnProperty(split[1])
                          : true;
                        return isHasProps ? (
                          <Table.Row
                            key={`srow-${idx + index}`}
                            //  tabIndex={idx === 0 ? 0 : -1}
                            style={{
                              transition:
                                "background .3s cubic- bezier(.25, .8, .5, 1)",
                            }}
                          >
                            <Row
                              tableRef={this.tableRef}
                              tabIndex={idx}
                              {...this.props}
                              isChange={this.state.isChange}
                              data={child}
                              headerLevel={1}
                              level={1}
                              thisProps={this}
                              rowChild={index}
                              toggleTab={this.state.toggleTab}
                            />
                          </Table.Row>
                        ) : (
                          <></>
                        );
                      })
                    }
                  </>
                );
              })}

              {new Array(data.length >= 10 ? 0 : pageSize - data.length)
                .fill("")
                .map((acc, index) => (
                  <Table.Row
                    key={`prow-${index}`}
                  //@ts-ignore

                  // tabIndex={idx === 0 ? 0 : -1}
                  >
                    <Row
                      tableRef={this.tableRef}
                      tabIndex={index}
                      {...this.props}
                      data={acc}
                      headerLevel={headerLevel}
                      clearValue
                      level={0}
                      thisProps={this}
                      isChange={this.state.isChange}
                      pageSize={data.length >= 10 ? 0 : pageSize - data.length}
                      toggleTab={this.state.toggleTab}
                    />
                  </Table.Row>
                ))}
            </Table.Body>
          )}
        </Table>
      </div>
    );
  }
};

export default React.memo(SemanticTable)
