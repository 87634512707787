import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea
} from 'semantic-ui-react'



import styles from './CardPTDiagnosisOrthopedicOPDIPDLOW.module.css'

export interface CardPTDiagnosisOrthopedicOPDIPDLOWViewDataType {
    PtDiagnosisOrthoOpdipdLow2: string,
    PtDiagnosisOrthoOpdipdLow2Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow3: string,
    PtDiagnosisOrthoOpdipdLow3Disabled: boolean,
    SaveRequestDisabled: boolean,
    PtDiagnosisOrthoOpdipdLow7: boolean,
    PtDiagnosisOrthoOpdipdLow7Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow8: boolean,
    PtDiagnosisOrthoOpdipdLow8Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow10: boolean,
    PtDiagnosisOrthoOpdipdLow10Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow12: boolean,
    PtDiagnosisOrthoOpdipdLow12Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow15: boolean,
    PtDiagnosisOrthoOpdipdLow15Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow17: boolean,
    PtDiagnosisOrthoOpdipdLow17Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow21: string,
    PtDiagnosisOrthoOpdipdLow21Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow22: boolean,
    PtDiagnosisOrthoOpdipdLow22Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow24: boolean,
    PtDiagnosisOrthoOpdipdLow24Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow25: boolean,
    PtDiagnosisOrthoOpdipdLow25Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow26: boolean,
    PtDiagnosisOrthoOpdipdLow26Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow29: boolean,
    PtDiagnosisOrthoOpdipdLow29Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow31: boolean,
    PtDiagnosisOrthoOpdipdLow31Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow33: boolean,
    PtDiagnosisOrthoOpdipdLow33Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow35: boolean,
    PtDiagnosisOrthoOpdipdLow35Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow39: boolean,
    PtDiagnosisOrthoOpdipdLow39Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow40: string,
    PtDiagnosisOrthoOpdipdLow40Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow41: boolean,
    PtDiagnosisOrthoOpdipdLow41Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow42: boolean,
    PtDiagnosisOrthoOpdipdLow42Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow44: boolean,
    PtDiagnosisOrthoOpdipdLow44Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow46: boolean,
    PtDiagnosisOrthoOpdipdLow46Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow48: boolean,
    PtDiagnosisOrthoOpdipdLow48Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow52: string,
    PtDiagnosisOrthoOpdipdLow52Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow53: boolean,
    PtDiagnosisOrthoOpdipdLow53Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow55: string,
    PtDiagnosisOrthoOpdipdLow55Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow57: boolean,
    PtDiagnosisOrthoOpdipdLow57Disabled: boolean,
    Ct6Premorbid: string,
    Ct6PremorbidDisabled: boolean,
    Ct6PremorbidOptions: any,
    PtDiagnosisOrthoOpdipdLow62: string,
    PtDiagnosisOrthoOpdipdLow62Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow64: boolean,
    PtDiagnosisOrthoOpdipdLow64Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow65: boolean,
    PtDiagnosisOrthoOpdipdLow65Disabled: boolean,
    PtDiagnosisOrthoOpdipdLow69: string,
    PtDiagnosisOrthoOpdipdLow69Disabled: boolean,
}

export const CardPTDiagnosisOrthopedicOPDIPDLOWInitialViewData: CardPTDiagnosisOrthopedicOPDIPDLOWViewDataType = {
    PtDiagnosisOrthoOpdipdLow2: "",
    PtDiagnosisOrthoOpdipdLow2Disabled: false,
    PtDiagnosisOrthoOpdipdLow3: "",
    PtDiagnosisOrthoOpdipdLow3Disabled: false,
    SaveRequestDisabled: false,
    PtDiagnosisOrthoOpdipdLow7: false,
    PtDiagnosisOrthoOpdipdLow7Disabled: false,
    PtDiagnosisOrthoOpdipdLow8: false,
    PtDiagnosisOrthoOpdipdLow8Disabled: false,
    PtDiagnosisOrthoOpdipdLow10: false,
    PtDiagnosisOrthoOpdipdLow10Disabled: false,
    PtDiagnosisOrthoOpdipdLow12: false,
    PtDiagnosisOrthoOpdipdLow12Disabled: false,
    PtDiagnosisOrthoOpdipdLow15: false,
    PtDiagnosisOrthoOpdipdLow15Disabled: false,
    PtDiagnosisOrthoOpdipdLow17: false,
    PtDiagnosisOrthoOpdipdLow17Disabled: false,
    PtDiagnosisOrthoOpdipdLow21: "",
    PtDiagnosisOrthoOpdipdLow21Disabled: false,
    PtDiagnosisOrthoOpdipdLow22: false,
    PtDiagnosisOrthoOpdipdLow22Disabled: false,
    PtDiagnosisOrthoOpdipdLow24: false,
    PtDiagnosisOrthoOpdipdLow24Disabled: false,
    PtDiagnosisOrthoOpdipdLow25: false,
    PtDiagnosisOrthoOpdipdLow25Disabled: false,
    PtDiagnosisOrthoOpdipdLow26: false,
    PtDiagnosisOrthoOpdipdLow26Disabled: false,
    PtDiagnosisOrthoOpdipdLow29: false,
    PtDiagnosisOrthoOpdipdLow29Disabled: false,
    PtDiagnosisOrthoOpdipdLow31: false,
    PtDiagnosisOrthoOpdipdLow31Disabled: false,
    PtDiagnosisOrthoOpdipdLow33: false,
    PtDiagnosisOrthoOpdipdLow33Disabled: false,
    PtDiagnosisOrthoOpdipdLow35: false,
    PtDiagnosisOrthoOpdipdLow35Disabled: false,
    PtDiagnosisOrthoOpdipdLow39: false,
    PtDiagnosisOrthoOpdipdLow39Disabled: false,
    PtDiagnosisOrthoOpdipdLow40: "",
    PtDiagnosisOrthoOpdipdLow40Disabled: false,
    PtDiagnosisOrthoOpdipdLow41: false,
    PtDiagnosisOrthoOpdipdLow41Disabled: false,
    PtDiagnosisOrthoOpdipdLow42: false,
    PtDiagnosisOrthoOpdipdLow42Disabled: false,
    PtDiagnosisOrthoOpdipdLow44: false,
    PtDiagnosisOrthoOpdipdLow44Disabled: false,
    PtDiagnosisOrthoOpdipdLow46: false,
    PtDiagnosisOrthoOpdipdLow46Disabled: false,
    PtDiagnosisOrthoOpdipdLow48: false,
    PtDiagnosisOrthoOpdipdLow48Disabled: false,
    PtDiagnosisOrthoOpdipdLow52: "",
    PtDiagnosisOrthoOpdipdLow52Disabled: false,
    PtDiagnosisOrthoOpdipdLow53: false,
    PtDiagnosisOrthoOpdipdLow53Disabled: false,
    PtDiagnosisOrthoOpdipdLow55: "",
    PtDiagnosisOrthoOpdipdLow55Disabled: false,
    PtDiagnosisOrthoOpdipdLow57: false,
    PtDiagnosisOrthoOpdipdLow57Disabled: false,
    Ct6Premorbid: "",
    Ct6PremorbidDisabled: false,
    Ct6PremorbidOptions: [{key: 0, value: "A", text: "A"}],
    PtDiagnosisOrthoOpdipdLow62: "",
    PtDiagnosisOrthoOpdipdLow62Disabled: false,
    PtDiagnosisOrthoOpdipdLow64: false,
    PtDiagnosisOrthoOpdipdLow64Disabled: false,
    PtDiagnosisOrthoOpdipdLow65: false,
    PtDiagnosisOrthoOpdipdLow65Disabled: false,
    PtDiagnosisOrthoOpdipdLow69: "",
    PtDiagnosisOrthoOpdipdLow69Disabled: false,
}

const Sub = (props: any) => {
    console.log(props.viewData, 'viewDaaa')
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtDiagnosisOrthoOpdipdLow1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow2}>
                <Input
                    value={props.viewData.PtDiagnosisOrthoOpdipdLow2}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTDiagnosisOrthopedicOPDIPDLOW',
                            name: 'PtDiagnosisOrthoOpdipdLow2',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow3}>
                <Input
                    value={props.viewData.PtDiagnosisOrthoOpdipdLow3}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow3Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTDiagnosisOrthopedicOPDIPDLOW',
                            name: 'PtDiagnosisOrthoOpdipdLow3',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow4}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Chief complaint*</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardPTDiagnosisOrthopedicOPDIPDLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{width: "100%"}}
                >
                    Save
                </Button>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Underlying disease</strong></p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow7}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow8}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow8}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow8Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow8"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consult for*</strong></p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow10}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow10}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow10Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow10"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Decrease pain</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow12}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow12}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow12Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow12"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow13}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Improve range of motion</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow15}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow15}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow15Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow15"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Improve balance &amp; ambulation</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow17}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow17}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow17Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow17"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Improve strengthening</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Chest physical therapy</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Development training</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow21}>
                <Input
                    value={props.viewData.PtDiagnosisOrthoOpdipdLow21}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow21Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTDiagnosisOrthopedicOPDIPDLOW',
                            name: 'PtDiagnosisOrthoOpdipdLow21',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow22}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow22}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow22Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow22"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow24}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow25}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow26}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Precaution*</strong></p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow29}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow30}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Infectious precaution</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow31}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow31}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Malignancy</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow33}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow33}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow33Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow33"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow34}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Vascular disorder</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow35}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow35}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow35Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow35"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow36}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Internel Instrument</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Osteoporosis</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow38}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pregnancy</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow39}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow39}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow39Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow39"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow40}>
                <Input
                    value={props.viewData.PtDiagnosisOrthoOpdipdLow40}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow40Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTDiagnosisOrthopedicOPDIPDLOW',
                            name: 'PtDiagnosisOrthoOpdipdLow40',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow41}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow41}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow41Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow41"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow42}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow42}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow42Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow42"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow43}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hypertension</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow44}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow44}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow44Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow44"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow45}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Falling</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow46}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow46}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow46Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow46"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow47}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Diabetes mellitus</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow48}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow48}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow48Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow48"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow49}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Fracture</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow50}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Aspiration</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow51}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Seizure</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow52}>
                <Input
                    value={props.viewData.PtDiagnosisOrthoOpdipdLow52}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow52Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTDiagnosisOrthopedicOPDIPDLOW',
                            name: 'PtDiagnosisOrthoOpdipdLow52',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow53}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow53}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow53Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow53"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow54}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow55}>
                <Input
                    value={props.viewData.PtDiagnosisOrthoOpdipdLow55}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow55Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTDiagnosisOrthopedicOPDIPDLOW',
                            name: 'PtDiagnosisOrthoOpdipdLow55',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow56}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Functional Limitation:</strong></p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow57}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow57}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow57Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow57"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow58}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.Ct6Premorbid}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct6Premorbid}
                    disabled={ props.viewData.Ct6PremorbidDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "Ct6Premorbid",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct6PremorbidOptions}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow62}>
                <Input
                    value={props.viewData.PtDiagnosisOrthoOpdipdLow62}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow62Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTDiagnosisOrthopedicOPDIPDLOW',
                            name: 'PtDiagnosisOrthoOpdipdLow62',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow63}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Premorbid</strong></p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow64}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow64}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow64Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow64"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow65}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtDiagnosisOrthoOpdipdLow65}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow65Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardPTDiagnosisOrthopedicOPDIPDLOW",
                                name: "PtDiagnosisOrthoOpdipdLow65"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow66}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Family Status</strong></p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow67}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Caregiver</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow68}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Non-Caregiver</p>
                </div>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow69}>
                <TextArea
                    value={props.viewData.PtDiagnosisOrthoOpdipdLow69}
                    disabled={ props.viewData.PtDiagnosisOrthoOpdipdLow69Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTDiagnosisOrthopedicOPDIPDLOW',
                            name: 'PtDiagnosisOrthoOpdipdLow69',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PtDiagnosisOrthoOpdipdLow70}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Present and Past History</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
