import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Icon,
  Dropdown,
  Button,
  Label
} from 'semantic-ui-react'

const CardMedicationErrorListUX = (props: any) => {
    return(
      <div
        style={{padding: "0 5px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem", padding: "1rem 0"}}>
          Medication Error List
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{width: "60px"}}>
                วันที่
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.StartDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.EndDate}
              </div>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              inline={true}
              width={1}>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              inline={true}>
              <label>
                ผู้ที่ทำให้เกิดความผิดพลาด
              </label>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              inline={true}
              width={3}>
              <div
                style={{width: "100%", marginRight: "1rem"}}>
                {props.SearchBox}
              </div>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              inline={true}>
              <div>
                {props.ButtonPrintReport}
              </div>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              inline={true}>
              <div
                style={{cursor: "pointer"}}>
                
                <Icon
                  className="grey large"
                  name="cog"
                  onClick={props.onOpenModFeedbackReport}>
                </Icon>
              </div>
            </FormField>
            <FormField
              className={props.allowedPrint ? "hidden" : ""}
              inline={true}>
              <label>
                หน่วยงาน
              </label>
            </FormField>
            <FormField
              className={props.allowedPrint ? "hidden" : ""}>
              <Dropdown
                name="division"
                onChange={props.onChange}
                options={props.divisionDrugOptions}
                search={true}
                selection={true}
                value={props.filterMedError?.division || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              inline={true}>
              <label
                style={{width: "60px"}}>
                หน่วยงาน
              </label>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              inline={true}
              width={2}>
              <Dropdown
                fluid={true}
                name="division"
                onChange={props.onChange}
                options={props.divisionDrugOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterMedError?.division || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label>
                สถานะ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.statusDropdown}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ประเภท
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{width: "100%"}}>
                {props.typeDropdown}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                onClick={props.onClear}
                size="small"
                style={{minWidth: "max-content"}}>
                ล้างการค้นหา
              </Button>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              inline={true}>
              <label
                style={{color: "#767676", minWidth: "max-content"}}>
                ไตรมาสที่
              </label>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              inline={true}>
              <div
                style={{display: "flex", alignItems: "center"}}>
                
                <Label
                  size="large"
                  style={{minWidth: "80px", textAlign: "center"}}>
                  {props.quarter || "\u00a0"}
                </Label>
                <div
                  style={{cursor: "pointer", display: props.quarter ? "": "none"}}>
                  
                  <Icon
                    className="grey"
                    name="close"
                    onClick={props.onClearQuarter}>
                  </Icon>
                </div>
              </div>
            </FormField>
            <div
              className="field"
              style={{flex: 1}}>
              
            </div>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}>
              <Button
                color="orange"
                onClick={props.onClickStatistics}
                size="small"
                style={{minWidth: "max-content"}}>
                สถิติ
              </Button>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}>
              <Button
                color="brown"
                disabled={!props.medErrorList?.length}
                onClick={props.onExportExcel}
                size="small"
                style={{minWidth: "max-content"}}>
                Export Excel
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.medErrorList}
            headers={props.headers ? props.headers : "Date,Drug,Error Type,Brief,Division,Stakeholder,Status,Edit,Del"}
            keys={`${props.allowedPrint ? "chk," : ""}no,datetime,drug_name,type_name,type_detail,cause_detail,stakeholder_name,status,action`}
            minRows="10"
            showPagination={false}
            style={{minHeight: "300px", maxHeight: "70vh"}}
            widths={`${props.allowedPrint ? "70," : ""}70,140,auto,auto,auto,auto,auto,120,120`}>
          </Table>
        </div>
      </div>
    )
}

export default CardMedicationErrorListUX

export const screenPropsDefault = {"userSearch":"[User Search Box]"}

/* Date Time : Thu Mar 30 2023 09:27:59 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"0 5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication Error List"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\", padding: \"1rem 0\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.medErrorList"
        },
        "headers": {
          "type": "code",
          "value": "props.headers ? props.headers : \"Date,Drug,Error Type,Brief,Division,Stakeholder,Status,Edit,Del\""
        },
        "keys": {
          "type": "code",
          "value": "`${props.allowedPrint ? \"chk,\" : \"\"}no,datetime,drug_name,type_name,type_detail,cause_detail,stakeholder_name,status,action`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{minHeight: \"300px\", maxHeight: \"70vh\"}"
        },
        "widths": {
          "type": "code",
          "value": "`${props.allowedPrint ? \"70,\" : \"\"}70,140,auto,auto,auto,auto,auto,120,120`"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormGroup",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormGroup",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{width: \"60px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ที่ทำให้เกิดความผิดพลาด"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"1rem\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Icon",
      "parent": 59,
      "props": {
        "className": {
          "type": "value",
          "value": "grey large"
        },
        "name": {
          "type": "value",
          "value": "cog"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModFeedbackReport"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน"
        },
        "style": {
          "type": "code",
          "value": "{width: \"60px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 62,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.divisionDrugOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterMedError?.division || \"\""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusDropdown"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "code",
          "value": "props.typeDropdown"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Button",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ไตรมาสที่"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#767676\", minWidth: \"max-content\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Button",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "สถิติ"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickStatistics"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Button",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "Export Excel"
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "disabled": {
          "type": "code",
          "value": "!props.medErrorList?.length"
        },
        "onClick": {
          "type": "code",
          "value": "props.onExportExcel"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"hidden\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"hidden\" : \"\""
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Dropdown",
      "parent": 90,
      "props": {
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.divisionDrugOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filterMedError?.division || \"\""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonPrintReport"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", display: props.quarter ? \"\": \"none\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Label",
      "parent": 95,
      "props": {
        "children": {
          "type": "code",
          "value": "props.quarter || \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", textAlign: \"center\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Icon",
      "parent": 96,
      "props": {
        "className": {
          "type": "value",
          "value": "grey"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearQuarter"
        }
      },
      "seq": 98,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardMedicationErrorListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "userSearch": "[User Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
