import WasmController from "react-lib/frameworks/WasmController";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

// Utils

// APIs
import CostCenterIncomePerReceipt from "issara-sdk/apis/CostCenterIncomePerReceipt_apps_INF";

export type State = Partial<{
  // Sequence
  CostCenterReceiptSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    searchStatus: "SUCCESS" | "ERROR" | null;
    searchInput: string;
    searchResult: any;
  }>;
}>;

type Picked = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage" | "django">>;

export const StateInitial: State = {
  // Sequence
  CostCenterReceiptSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

// Const
const Masters: any = [
  // ["coverage", {}],
  // ["bilStation", {}],
  // ["bilStationLog", {}],
  // ["payer", {}],
  // ["division", {}],
];

const BTN_ACTION = `CostCenterReceipt_SEARCH_RECEIPT`;

type Handler = (controller: WasmController<State & Picked, Event, Data>, params?: any) => any;

/* ------------------------------------------------------ */
/*                          START                         */
/* ------------------------------------------------------ */
export const Start: Handler = async (controller, params) => {
  console.log("CostCenterReceipt START State");

  let state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  console.log("CostCenterReceipt Controller Data: ", controller);
  console.log("CostCenterReceipt Params Data: ", params);

  controller.setState(
    {
      CostCenterReceiptSequence: {
        ...state.CostCenterReceiptSequence,
        sequenceIndex: "Action",
        // Initial Sub-menu
      },
    },
    () => Action(controller, { action: "FETCH_REPORT" })
  );
};

/* ------------------------------------------------------ */
/*                         ACTION                         */
/* ------------------------------------------------------ */
export const Action: Handler = async (controller, params) => {
  if (params.action === "FETCH_DATA") {
    HandleFetchData(controller, params);
  } else if (params.action === "SEARCH_RECEIPT") {
    HandleSearchReceipt(controller, params);
  }
};

const HandleFetchData: Handler = async (controller, params) => {
  console.log("CostCenterReceipt Seq FETCH_REPORT SEQUENCE");

  let state = controller.getState();

  console.log("CostCenterReceipt FETCH_REPORT Data: ", state.CostCenterReceiptSequence);
};

const HandleSearchReceipt: Handler = async (controller, params) => {
  console.log("CostCenterReceipt Seq Search");

  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "LOADING" },
  });

  // Call API
  const [result, error] = await CostCenterIncomePerReceipt.get({
    apiToken: controller.apiToken,
    params: {
      code: state.CostCenterReceiptSequence?.searchInput,
    },
    extra: {
      division: controller.data.division,
    },
  });

  if (error) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "ERROR" },
    });

    controller.setState({
      CostCenterReceiptSequence: {
        ...state.CostCenterReceiptSequence,
        searchStatus: "ERROR",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "SUCCESS" },
    });

    controller.setState({
      CostCenterReceiptSequence: {
        ...state.CostCenterReceiptSequence,
        searchStatus: "SUCCESS",
        searchResult: result,
      },
    });
  }
};
