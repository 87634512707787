import CONFIG from "config/config";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";

type HeaderCUFormProps = Partial<{
  header: Record<string, any>;
  styles: Record<string, any>;
  images: Record<string,any>;
  pageMargins: number[];
}>;

const FORM_NAME = "HeaderCUForm";

const HeaderCUForm = async (props: HeaderCUFormProps = {}) => {
  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    height: 60,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  return {
    // * Logo height 100
    pageMargins: props.pageMargins ? props.pageMargins : [43, 100, 43, 40],
    header: (currentPage: number, pageCount: number) => {
      return {
        stack: [ !CONFIG.HIDE_COMPANY_LOGO_PRINT ?
          {
            columns: [
              { image: "logo", width: 200, height: 60, margin: [20, 0, 0, 0] },
              { text: "", width: "*" },
              {
                table: {
                  body: [
                    [
                      {
                        stack: [
                          {
                            text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
          
                            style: "hospitalName",
                          },
                          {
                            text: "Faculty of Dentistry, Chulalongkorn University",
            
                            style: "hospitalInfo",
                          },
                          {
                            text: "34 ถนนอังรีดูนังต์ แขวงวังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330",
         
                            style: "hospitalInfo",
                          },
                          {
                            text: "โทร 02-218-8705",
            
                            style: "hospitalInfo",
                          },
                        ],
                        border: [false, false, false, false],
                        alignment: "right",
                      },
                    ]
                  ],
                },
                width: 220,
              },
            ],
            columnGap: 10,
            margin: [0, 16, 16, 0],
          } : null ,
        ].concat(props.header || ({ text: "" } as any)),
      }

      
      
      // {
      //   margin: [2.5, 2, 10, 0],
      //   columns: [
      //     !CONFIG.HIDE_COMPANY_LOGO_PRINT ? {
      //       width: "50%",
      //       stack: [{ image: "logo", width: 150 }],
      //       marginTop: 8.5
            
      //     } : null,
      //     !CONFIG.HIDE_COMPANY_LOGO_PRINT ? {
      //       width: "50%",
      //       margin: [0, 2.5, 0, 0],
      //       stack: [
      //         {
      //           text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
      //           style: "hospitalName",
      //         },
      //         {
      //           margin: [0, -3, 0, 0],
      //           text: "Faculty of Dentistry, Chulalongkorn University",
      //           style: "hospitalInfo",
      //         },
      //         {
      //           margin: [0, -3, 0, 0],
      //           text:
      //             "34 ถนนอังรีดูนังต์ แขวงวังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330 โทร 02-218-8705",
      //           style: "hospitalInfo",
      //         }
      //       ]
      //     } : null
      //   ]
      // }
    },
    images: {
      ...images,
    },
    styles: {
      hospitalName: {
        fontSize: 12,
        bold: true,
      },
      hospitalInfo: {
        fontSize: 11,
      },
      header: {
        fontSize: 20,
        bold: true,
        margin: [0, 0, 0, 2],
      },
    }
  };
};

export default HeaderCUForm;


