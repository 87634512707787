import React, { useCallback, useEffect, useState } from "react";

import moment from "moment";

import NotificationContainer, { NotificationItem } from "../common/NotificationContainer";

// Types
type CardNotiMessageProps = {
  setProp: (key: string, value: any, callback?: () => void) => any;
  notificationMessageList?: NotificationItem[];
};

type NotiMessageItem = NotificationItem &
  Partial<{ description: string; image: string; title: string }>;

// Constants
const IMAGES = {
  noti: "/static/images/icon-noti.png",
};

// Utility function
const calculateTime = (dateTime: string) => {
  const dateTimeNow = moment();
  const timestamp = moment(dateTime);
  const duration = moment.duration(dateTimeNow.diff(timestamp));

  if (duration.days() < 1) {
    if (duration.hours() >= 1) {
      return `${duration.hours().toFixed(0)} ชั่วโมงที่แล้ว`;
    } else if (duration.minutes() < 60 && duration.minutes() > 1) {
      return `${duration.minutes().toFixed(0)} นาทีที่แล้ว`;
    }

    return "now";
  }

  return `${duration.asDays().toFixed(0)} วันที่แล้ว`;
};

const CardNotiMessage = (props: CardNotiMessageProps) => {
  const handleSetNotifications = useCallback((notifications: NotiMessageItem[]) => {
    props.setProp("notificationMessageList", [...notifications]);
  }, []);

  const renderCard = useCallback((cardProps: NotiMessageItem) => <CardNoti {...cardProps} />, []);

  return (
    <NotificationContainer
      notifications={props.notificationMessageList}
      renderCard={renderCard}
      showCloseAll
      setNotifications={handleSetNotifications}
    />
  );
};

/* ====================================================== */
/*                        CardNoti                        */
/* ====================================================== */
const CardNoti = (props: NotiMessageItem) => {
  const [showTime, setShowTime] = useState<string>("");

  // Effect
  useEffect(() => {
    setShowTime(calculateTime(props.time));
  }, [props.time]);

  useEffect(() => {
    setInterval(() => {
      setShowTime(calculateTime(props.time));
    }, 1000 * 60);
  }, []);

  return (
    <div style={{ display: "flex", position: "relative" }}>
      <div
        style={{
          display: "flex",
          marginRight: "10px",
          padding: "5px 5px 10px 10px",
        }}
      >
        <img
          alt="notifications"
          src={props.image || IMAGES.noti}
          style={{ height: "25px", width: "25px" }}
        />
      </div>
      <div>
        <div style={{ color: "black" }}>{props.title}</div>
        <div style={{ color: props.image ? "black" : "red" }}>{props.description}</div>
        <div>{showTime}</div>
      </div>
    </div>
  );
};

export default React.memo(CardNotiMessage);
