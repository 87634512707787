import moment from "moment";
import CONFIG from "config/config";
// Utils
import { formatDate, beToAd } from "react-lib/utils/dateUtils";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";

const FORM_NAME = "FormFeedbackReport";

// Utils
// 01/02/2566 to 1 กุภาพันธ์ 2566
const formatFullDate = (date: string) => {
  const adDate = beToAd(date);
  const day = adDate?.format("D");
  const month = adDate?.clone()?.locale("th")?.format("MMMM");
  const year = Number(adDate?.format("YYYY")) + 543;

  return date ? `${day} ${month} ${year}` : "";
};

const getWidth = (text: string) => {
  const combine = text;
  const div = document.createElement("div");

  div.setAttribute(
    "style",
    "width:fit-content;font-size:20px;font-family:'THSarabunNew', sans-serif;"
  );
  div.innerHTML = combine;
  document.body.appendChild(div);

  const width = div.offsetWidth;

  div.remove();

  return width;
};

const FormFeedbackReport = async (props: any) => {
  console.log("FormFeedbackReport", props);

  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    height: 45.45,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  return {
    pageSize: "A4",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 18,
    },
    pageMargins: [40, 185, 30, 40],
    styles: {
      fieldHeader: {
        fontSize: 24,
        bold: true,
      },
      tableHeader: {
        fontSize: 23,
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 20,
      },
      fieldValue: {
        fontSize: 20,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 18,
      },
      miniFieldValue: {
        fontSize: 18,
      },
      footerValue: {
        fontSize: 14,
      },
    },
    header: (currentPage: number, pageCount: number) => {
      return {
        margin: [0, 35, 0, 0],
        stack: [
          !CONFIG.HIDE_COMPANY_LOGO_PRINT ? { image: "logo", alignment: "center", width: 135 } : null,
          {
            margin: [0, 20, 0, 0],
            text: "รายงานอุบัติการณ์ความเสี่ยงและความคลาดเคลื่อนทางยา (Feeback Report)",
            style: "fieldHeader",
            alignment: "center",
          },
          {
            margin: [0, 0, 0, 0],
            text: `ไตรมาสที่ ${props.quarterDetail?.quarter || ""}${props.quarterDetail?.quarter || props.quarterDetail?.year
              ? "/"
              : ""
              }${props.quarterDetail?.year || ""}`,
            style: "fieldHeader",
            alignment: "center",
          },
        ],
      };
    },
    content: [
      {
        text: `เรียน ${props.stakeholder_name}`,
        bold: true,
        fontSize: 21,
      },
      {
        margin: [25, 10, 0, 0],
        text: "คณะกรรมการระบบยา ได้รวมรวมข้อมูลรายงานความเสี่ยงและความคลาดเคลื่อน",
        style: "fieldValue",
        lineHeight: 0.85,
      },
      {
        text: "ทางด้านยา พร้อมจัดทำเป็นรายงานป้อนกลับเฉพาะราย (Personal Feedback Report)",
        style: "fieldValue",
        lineHeight: 0.85,
      },
      {
        text: "โดยมีวัตถุประสงค์เพื่อพัฒนาการให้บริการพร้อมขอความร่วมมือจากผู้ที่เกี่ยวข้องในการ",
        style: "fieldValue",
        lineHeight: 0.85,
      },
      {
        text: "ป้องกันอันตรายที่อาจจะเกิดจากการสั่งใช้ยา ไปจนถึงกระบวนการที่เกี่ยวข้อง",
        style: "fieldValue",
      },
      {
        margin: [25, 10, 0, 0],
        text: `ใน${`ระหว่างวันที่ ${formatFullDate(
          props.quarterDetail.startDate
        )}${props.quarterDetail?.startDate || props.quarterDetail?.endDate
          ? " - "
          : ""
          }${formatFullDate(
            props.quarterDetail?.endDate
          )}`} พบรายงานที่เกี่ยวข้องกับท่าน`,
        style: "fieldValue",
        lineHeight: 0.85,
      },
      {
        text: "รายละเอียดดังนี้",
        style: "fieldValue",
        lineHeight: 0.85,
      },
      {
        margin: [0, 15, 0, 0],
        columns: [
          {
            width: "auto",
            text: "ประเภทความเสี่ยง: ",
            style: "fieldKey",
          },
          {
            margin: [30, 0, 0, 0],
            text: props.type_name,
            style: "fieldValue",
          },
        ],
      },
      {
        margin: [0, 4, 0, 0],
        columns: [
          {
            width: "auto",
            text: "หัวข้อความเสี่ยงเรื่อง: ",
            style: "fieldKey",
          },
          {
            width: "*",
            margin: [20, 0, 0, 0],
            text: props.type_detail.split(",").join(", "),
            style: "fieldValue",
          },
        ],
      },
      {
        margin: [0, 4, 0, 0],
        columns: [
          {
            width: "auto",
            text: "วันที่เกิดเหตุ: ",
            style: "fieldKey",
          },
          {
            margin: [20, 0, 0, 0],
            text: props.request_time
              ? `${formatDate(moment(props.request_time))} [${moment(
                props.request_time
              ).format("HH:mm")}]`
              : "",
            style: "fieldValue",
          },
        ],
      },
      {
        margin: [0, 4, 0, 0],
        columns: [
          {
            width: "auto",
            text: "สถานที่: ",
            style: "fieldKey",
          },
          {
            margin: [20, 0, 0, 0],
            text: props.order_division_name,
            style: "fieldValue",
          },
        ],
      },
      {
        margin: [0, -21, 0, 0],
        columns: [
          {
            width: "auto",
            text: "ชื่อ-สกุลผู้ป่วย: ",
            style: "fieldKey",
          },
          {
            margin: [20, 0, 0, 0],
            text: props.patient_name,
            style: "fieldValue",
          },
        ],
      },
      {
        margin: [0, 4, 0, 0],
        columns: [
          {
            width: "auto",
            text: "รายละเอียดเหตุการณ์: ",
            style: "fieldKey",
          },
          {
            margin: [20, 0, 0, 0],
            text: props.cause_detail,
            style: "fieldValue",
          },
        ],
      },
      {
        margin: [0, 4, 0, 0],
        columns: [
          {
            width: "auto",
            text: "ผลกระทบที่อาจเกิดขึ้น: ",
            style: "fieldKey",
          },
          {
            margin: [20, 0, 0, 0],
            text: props.category,
            style: "fieldValue",
          },
        ],
      },
      {
        text: "จึงเรียนมาเพื่อทราบ",
        style: "fieldValue",
        absolutePosition: { x: 75, y: 695 },
      },
      {
        text: "ขอแสดงความนับถือ",
        style: "fieldValue",
        absolutePosition: { x: 410, y: 725 },
      },
      {
        text: `(${props.staff})`,
        style: "fieldValue",
        absolutePosition: {
          x: 503 - getWidth(`(${props.staff})`) / 2,
          y: 775,
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [15, 0, 0, 0],
        stack: [
          {
            text: `วันเวลาที่พิมพ์: ${formatDate(moment())} [${moment().format(
              "HH:mm"
            )}]`,
            style: "footerValue",
            lineHeight: 0.85,
          },
          { text: `ผู้ออกรายงาน ${props.staff}`, style: "footerValue" },
        ],
      };
    },
    images: {
      ...images,
    },
  };
};

export default FormFeedbackReport;
