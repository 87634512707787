import React, {forwardRef, useEffect, useState, useImperativeHandle}from "react";
import PropTypes from "prop-types";
import CardLayout from "react-lib/apps/common/CardLayout";
import PureReactTable from "./PureReactTable";
import { Form, Button } from "semantic-ui-react";
import { useIntl } from "react-intl";

const limit = 50;

const CardEncounterList = forwardRef((props: {
    patientId: string | number,
    patientHn: string | number,
    controller: any,
    onSelect: any,
  },
  ref) => {
  const intl = useIntl();
  const [encounterList, setEncounterList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const isMounted = React.useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.patientId) {
      getEncounterSearch();
    }
  }, [props.patientId]);

  useEffect(() => {
    getEncounterSearch();
  }, [currentPage]);

  const getEncounterSearch = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getEncounterSearch({
      // patientId: props.patientId, // #65599 show all of encounter
      patientHn: props.patientHn,
      limit,
      offset: currentPage * limit
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setTotalPage(parseInt(data.total) / limit + 1);
        setEncounterList(data.items);
      }
    }
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <CardLayout titleText="Encounter" hideHeaderIcon loading={isLoading}>
      <PureReactTable
        style={styles.encounterTable}
        testid="encounterTable"
        data={encounterList}
        showPageSizeOptions={false}
        pages={totalPage}
        onPageChange={handlePageChange}
        page={currentPage}
        onSelect={(originalRow: any) => setSelectedRow(originalRow)}
        onEnter={(originalRow: any) => {
          props.onSelect(originalRow)
        }}
        selectedRow={selectedRow}
        selectedClassName="blueSelectedRow"
        minRows={7}
        columns={[
          {
            Header: "Patient",
            accessor: "patient_name"
          },
          {
            Header: "Number",
            accessor: "number"
          },
          {
            Header: "Division",
            accessor: "division_name"
          },
          {
            Header: "Doctor",
            accessor: "doctor_name"
          },
          {
            Header: "Status",
            accessor: "status_name"
          },
          {
            Header: "Date - Time",
            accessor: "created"
          }
        ]}
      />
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={3}>
            <Button
              fluid
              color="green"
              content={intl.formatMessage({ id: "เลือก" })}
              onClick={() => {
                if(!selectedRow){
                  return
                }
                props.onSelect(selectedRow);
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </CardLayout>
  );
});

const styles = {
  encounterTable: {
    height: "400px"
  }
};

CardEncounterList.propTypes = {
  patientId: PropTypes.any,
  patientHn: PropTypes.any,
  controller: PropTypes.object,
  onSelect: PropTypes.func
};

CardEncounterList.defaultProps = {
  patientId: "",
  patientHn: "",
  controller: {},
  onSelect: () => {}
};

export default React.memo(CardEncounterList);
