import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";
import WalletPatientGetBalance from "issara-sdk/apis/WalletPatientGetBalance_apps_BIL";
import NewReportMedicalFeeDetailView from "issara-sdk/apis/NewReportMedicalFeeDetailView_apps_BIL";
import WalletTransactionList from "issara-sdk/apis/WalletTransactionList_apps_BIL";

let toDate = moment();
let year = parseInt(toDate.format("YYYY")) + 543;

export type State = {
  selectedEncounter?: any;
  selectedPatient?: any;
  billReportPrint?: Record<string, any>;
  // Sequence
  BillingDepositHistorySequence?: {
    sequenceIndex?: string | null;
    depositHistory?: any;
    receiptNo?: string;
    startDate?: any;
    endDate?: any;
    depositTotal?: any;
  } | null;
};

export const StateInitial: State = {
  BillingDepositHistorySequence: {
    receiptNo: "",
    startDate: `${toDate.format("DD/MM/")}${year}`,
    endDate: `${toDate.format("DD/MM/")}${year}`,
    depositTotal: "0.00",
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  divisionDict?: any;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillingDepositHistorySequence) return;

  const [
    [walletRes, walletErr, walletNet],
    [depositHistoryRes, depositHistoryErr, depositHistoryNet],
  ] = await Promise.all([
    WalletPatientGetBalance.get({
      params: {
        patient: state.selectedEncounter?.patient_id || state.selectedPatient?.id,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    }),
    WalletTransactionList.list({
      params: {
        patient: state.selectedEncounter?.patient_id || state.selectedPatient?.id,
        tx_type: "PAYMENT",
        start_use_date: toDate.format("YYYY-MM-DD"),
        end_use_date: toDate.format("YYYY-MM-DD"),
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    }),
  ]);

  controller.setState({
    BillingDepositHistorySequence: {
      ...StateInitial.BillingDepositHistorySequence,
      sequenceIndex: "Action",
      depositTotal: walletRes?.balance || "0.00",
      depositHistory: depositHistoryRes?.items || [],
    },
  });
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillingDepositHistorySequence) return;

  if (params.action === "printReceipt") {
    const state = controller.getState();

    const report = await NewReportMedicalFeeDetailView.get({
      params: {
        receipt: params.receipt,
        patient_name_lang: "TH",
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: controller.data.device,
      },
    });

    controller.setState({
      billReportPrint: { open: true, task_id: report[0]?.task_id || "" },
    });
  } else if (params.action === "getDepositHistory") {
    const state = controller.getState();

    let startDate = changDate(state.BillingDepositHistorySequence?.startDate);
    let endDate = changDate(state.BillingDepositHistorySequence?.endDate);

    const [depositHistoryRes, depositHistoryErr, depositHistoryNet] =
      await WalletTransactionList.list({
        params: {
          patient: state.selectedEncounter?.patient_id || state.selectedPatient?.id,
          tx_type: "PAYMENT",
          start_use_date: startDate,
          end_use_date: endDate,
          deposit_receipt_no:
            state.BillingDepositHistorySequence?.receiptNo,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

    controller.setState({
      BillingDepositHistorySequence: {
        ...state.BillingDepositHistorySequence,
        depositHistory: depositHistoryRes?.items || [],
      },
    });
  }
};

const changDate = (date: any) => {
  let splitDate = date.split("/");
  let year = parseInt(splitDate?.[2]) - 543;

  return `${year}-${splitDate?.[1]}-${splitDate?.[0]}`;
};
