import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";

// APIs
import RegisterAPI from "issara-sdk/apis/RegisterAPI_users";
import RequestToken from "issara-sdk/apis/RequestTokenAPI_users";
import UserProfileAPI from "issara-sdk/apis/UserProfileAPI_users";
import MyProxyPatientView from "issara-sdk/apis/MyProxyPatientView_apps_PRX";

export type State = {
  theme?: string;
  projectName?: string;
  device_id?: string | number;
  subscription?: any;
  apiToken?: string;
  userId?: string | number;
  loggedin?: boolean;
  language?: "en-US" | "th-TH";
  login?: boolean;
  profile?: boolean;

  // Data
  inputErrorLogin?: {
    username: boolean;
    password: boolean;
    message?: string;
  };
  viewIndexRegister?: number;
  registerInfo?: {
    username: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    birthdate: string;
    hn: string;
    hidePassword: boolean;
    hideConfirmPassword: boolean;
    error: {
      username: any;
      password: any;
      confirmPassword: any;
      firstName: any;
      lastName: any;
      birthdate: any;
      hn: any;
      message: string;
      viewIndex?: number;
    };
  };
  inputEmail?: string;
  inputEmailError?: boolean;
  signInByEmailError?: boolean;
  firstRegistration?: boolean;
  inputEmailRequest?: string;
  openModCheckEmail?: boolean;
  changePasswordData?: {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    error: {
      currentPassword: any;
      newPassword: any;
      confirmNewPassword: any;
      message: any;
    };
  };
  requestTokenDetail?: { [_: string]: any };
  userProfile?: { [_: string]: any };
  openDiagRuleSearch?: boolean;
  slideRouteRemember?: any[];
  slideDuration?: number;
  previousPathname?: string;
  disabledScrollIntoView?: boolean;
  // Loading
  loadingMainTransform?: boolean;
  // Error
  errorMessage?: any;
  // Success
  successMessage?: any;
  // ChatList
  chatSearchValue?: string;
  chatLoading?: boolean;
  channelList?: any[];
  chatDivision?: number;
  patientData?: any;
  chatName?: string;
  backToApp?: boolean;
  targetForm?: any;
  targetDiagRule?: any;
  openChatBox?: boolean;
  openSettingMod?: boolean;
  openLogoutMod?: boolean;
  profileStepperIndex?: number;
  openAllergyProfile?: boolean;
  openDeleteMod?: boolean;
};

export const StateInitial: State = {
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  // language: "th-TH",

  // Data
  inputErrorLogin: {
    username: false,
    password: false,
    message: "",
  },
  viewIndexRegister: 0,
  registerInfo: {
    username: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    birthdate: "",
    hn: "",
    hidePassword: true,
    hideConfirmPassword: true,
    error: {
      username: null,
      password: null,
      confirmPassword: null,
      firstName: null,
      lastName: null,
      birthdate: null,
      hn: null,
      message: "",
      viewIndex: 0,
    },
  },
  inputEmailError: false,
  signInByEmailError: false,
  firstRegistration: false,
  inputEmailRequest: "",
  openModCheckEmail: false,
  changePasswordData: {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    error: {
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      message: "",
    },
  },
  requestTokenDetail: {},
  userProfile: {},
  openDiagRuleSearch: false,
  slideRouteRemember: [],
  // Loading
  loadingMainTransform: false,
  // Error
  errorMessage: null,
  // Success
  successMessage: null,
  slideDuration: 225,
  // ChatList
  chatSearchValue: "",
  chatLoading: false,
  channelList: [],
  chatDivision: 0,
  patientData: {},
  chatName: "Unknown",
  backToApp: false,
  openChatBox: false,
  openSettingMod: false,
  openLogoutMod: false,
  profileStepperIndex: 0,
  openAllergyProfile: false,
  openDeleteMod: false,
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  // Setter
  | { message: "HandleSetViewIndexRegister"; params: { index: number } }
  | {
    message: "HandleSetValueRegisterInfo";
    params: { key: string; value: any };
  }
  | { message: "HandleSetInputEmail"; params: { value: any } }
  | { message: "HandleSetInputEmailRequest"; params: { value: any } }
  | { message: "HandleSetOpenModCheckEmail"; params: { value: boolean } }
  | {
    message: "HandleSetValueChangePassword";
    params: { key: string; value: any };
  }
  | {
    message: "HandleNextViewRegister";
    params: { current: string; next: string; history: any; data?: any };
  }
  // Sign
  | {
    message: "HandleSignIn";
    params: { username: string; password: string; history: any };
  }
  | { message: "HandleSignInByEmail"; params: { history: any } }
  | { message: "HandleEmailRequest"; params: { email: string } }
  | {
    message: "HandleConfirmChangePassword";
    params: { history: any; data?: any };
  }
  // GET
  | { message: "GetPatientDetail"; params: {} }
  | {
    message: "HandleGoBackSignInEmail";
    params: { headerName: string; history: any };
  }
  | {
    message: "HandleGoBackVerify";
    params: { history: any };
  }
  | { message: "HandleCloseMessage"; params: {} }
  | { message: "HandleRouteChange"; params: {} };
export type Data = {};

export const DataInitial = {};

const MOR_COMPANY = "@mor.company";

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => { };

export const DidUpdate: Handler = async (controller, params) => { };

// API
export const PostRegisterAPI: Handler = async (controller, params) => {
  const [res, error] = await RegisterAPI.post({
    data: params,
  });
  return [res, error];
};

export const PostRequestTokenAPI: Handler = async (controller, params) => {
  const [res, error] = await RequestToken.post({
    data: {
      ...params,
    },
  });
  return [res, error];
};

export const GetUserProfile: Handler = async (controller, params) => {
  const [res, error] = await UserProfileAPI.retrieve({
    apiToken: params.apiToken,
  });

  return [res, error];
};

// Setter
export const HandleSetViewIndexRegister: Handler = (controller, params) => {
  if (params.index >= 0) {
    controller.setState({ viewIndexRegister: params.index });
  } else {
    clearRegisterInfo(controller);
  }
};

export const HandleSetValueRegisterInfo: Handler = (controller, params) => {
  const { registerInfo } = controller.getState();
  const { key, value } = params;

  if (registerInfo) {
    (registerInfo as any)[key] = value;
  }

  controller.setState({ registerInfo: { ...(registerInfo || ({} as any)) } });
};

export const HandleSetValueChangePassword: Handler = (controller, params) => {
  const { changePasswordData } = controller.getState();
  const { key, value } = params;

  if (changePasswordData) {
    (changePasswordData as any)[key] = value;
  }

  controller.setState({
    changePasswordData: { ...(changePasswordData || ({} as any)) },
  });
};

export const HandleSetInputEmail: Handler = (controller, params) => {
  controller.setState({ inputEmail: params.value });
};

export const HandleSetInputEmailRequest: Handler = (controller, params) => {
  controller.setState({ inputEmailRequest: params.value });
};

export const HandleSetOpenModCheckEmail: Handler = (controller, params) => {
  controller.setState({ openModCheckEmail: params.value });
};

// Sign
export const HandleSignIn: Handler = async (controller, params) => {
  let { username, password, history } = params;
  if (!username || !password) {
    controller.setState({
      inputErrorLogin: {
        username: !username,
        password: !password,
        message: "กรุณาระบุชื่อผู้ใช้งานและรหัสผ่าน",
      },
    });
    return;
  }

  controller.setState({ loadingMainTransform: true });

  username = `${username}${MOR_COMPANY}`;

  const login = { username, password };

  const [resToken, errToken] = await PostRequestTokenAPI(controller, login);

  if (errToken) {
    controller.setState({
      inputErrorLogin: {
        username: true,
        password: true,
      },
      errorMessage: errToken,
    });
    controller.setState({ loadingMainTransform: false });
    return;
  }

  const [resProfile, errProfile] = await GetUserProfile(controller, {
    apiToken: resToken.token,
  });
  controller.setState({
    userProfile: resProfile,
    loadingMainTransform: false,
    inputErrorLogin: { username: false, password: false },
    requestTokenDetail: resToken,
  });

  // history.push(`/tuh-transform/verify-identity`);
  // history.push(`/tuh-transform/chat`);
};

export const HandleNextViewRegister: Handler = async (controller, params) => {
  const { viewIndexRegister, registerInfo, requestTokenDetail } =
    controller.getState();
  const {
    current,
    next,
    history,
  }: {
    current: "username" | "password" | "hn" | "info";
    next: string;
    history: any;
  } = params;

  if (!registerInfo) {
    return;
  }

  let isError = false;
  const required = {
    username: {
      input: ["username"],
      check: {
        prefix: ["0-9", "a-z", "A-Z"],
        minLength: 6,
      },
    },
    password: {
      input: ["password", "confirmPassword"],
      check: {
        prefix: ["0-9", "a-z", "A-Z"],
        minLength: 8,
      },
    },
    info: {
      input: ["firstName", "lastName", "birthdate"],
      check: { prefix: [] },
    },
    hn: {
      input: ["hn"],
      check: {
        prefix: ["0-9"],
        length: 7,
      },
    },
  };

  registerInfo.error = {} as any;
  required[current].input.map((key: string) => {
    const value: string = (registerInfo as any)[key];
    const check = required[current].check
      ? checkValidInput({ ...required[current].check, text: value })
      : true;

    if (!value || !check) {
      (registerInfo as any)["error"][key] = true;
      isError = true;
    }

    if (key === "birthdate" && !checkValidBirthdate(value)) {
      (registerInfo as any)["error"][key] = true;
      registerInfo.error.message = "กรุณาระบุวันเกิดให้ถูกต้อง";
      registerInfo.error.viewIndex = viewIndexRegister;
      isError = true;
    }
  });

  if (
    current === "password" &&
    registerInfo?.password !== registerInfo?.confirmPassword
  ) {
    registerInfo.error.message = "รหัสผ่านไม่ตรงกัน";
    registerInfo.error.viewIndex = viewIndexRegister;
    isError = true;
  }

  if (isError) {
    controller.setState({ registerInfo: { ...registerInfo } });
    return;
  }

  // เมื่ออยู่หน้ากรอก password
  if (current === "password") {
    controller.setState({ loadingMainTransform: true });

    let { username, password, confirmPassword } = registerInfo;
    username = `${username}${MOR_COMPANY}`;

    // register
    const [res, error] = await PostRegisterAPI(controller, {
      confirm_password: confirmPassword,
      email: username,
      password: password,
    });

    const login = { username, password };
    let [resToken, errToken]: [any, any] = [null, null];

    if (error) {
      // ถ้ามีชื่อผู้ใช้นี้อยู่แล้ว
      if ("email" in error) {
        // get token เพื่อใช้ update profile
        [resToken, errToken] = await PostRequestTokenAPI(controller, login);
      } else {
        controller.setState({
          loadingMainTransform: false,
          errorMessage: error,
        });
        return;
      }
    } else {
      // get token เพื่อใช้ update profile
      [resToken, errToken] = await PostRequestTokenAPI(controller, login);
    }

    if (errToken) {
      controller.setState({
        loadingMainTransform: false,
        errorMessage: errToken,
      });
      return;
    }

    // get user profile
    const [resProfile, errProfile] = await GetUserProfile(controller, {
      apiToken: resToken?.token,
    });

    if (!resProfile?.first_name || !resProfile.last_name || !resProfile.dob) {
      registerInfo.firstName = "";
      registerInfo.lastName = "";
      registerInfo.birthdate = "";
      registerInfo.hn = "";
      controller.setState({ firstRegistration: true });
    } else {
      registerInfo.hn = JSON.parse(resProfile?.extra || "{}")?.hn || "";
      controller.setState({ firstRegistration: false });
    }

    controller.setState({
      loadingMainTransform: false,
      requestTokenDetail: resToken || {},
      registerInfo: { ...registerInfo },
    });
    history.push(`${history.location.pathname}?screen=${next}`);
  } else if (current === "info") {
    controller.setState({ loadingMainTransform: true });

    // อัพเดตข้อมูลผู้ใช้
    const [resProfile, errorProfile] = await UserProfileAPI.patch({
      apiToken: requestTokenDetail?.token,
      data: {
        first_name: registerInfo.firstName,
        last_name: registerInfo.lastName,
        dob: registerInfo.birthdate.replace(
          /(\d{2})\/(\d{2})\/(\d{4})/,
          (...res) => `${+res[3] - 543}-${res[2]}-${res[1]}`
        ),
      },
    });
    controller.setState({ loadingMainTransform: false });

    if (errorProfile) {
      controller.setState({ errorMessage: errorProfile });
      return;
    }
    history.push(`${history.location.pathname}?screen=${next}`);
  } else if (current === "hn") {
    controller.setState({ loadingMainTransform: true });

    // อัพเดต hn
    const [res, error] = await UserProfileAPI.patch({
      apiToken: requestTokenDetail?.token,
      data: {
        extra: JSON.stringify({
          hn: registerInfo.hn,
        }),
      },
    });

    if (error) {
      controller.setState({ errorMessage: error, loadingMainTransform: false });
      return;
    }

    // get user profile
    const [resProfile, errProfile] = await GetUserProfile(controller, {
      apiToken: requestTokenDetail?.token,
    });
    controller.setState({
      userProfile: resProfile,
      loadingMainTransform: false,
    });

    history.push(`/tuh-transform/verify-identity`);
    // history.push(`/tuh-transform/login`);
    return;
  } else {
    history.push(`${history.location.pathname}?screen=${next}`);
  }
  controller.setState({ viewIndexRegister: (viewIndexRegister || 0) + 1 });
};

export const HandleConfirmChangePassword: Handler = (controller, params) => {
  const { changePasswordData } = controller.getState();
  const { history } = params;

  if (!changePasswordData) {
    return;
  }

  let isError = false;
  const required = {
    input: ["currentPassword", "newPassword", "confirmNewPassword"],
    check: {
      prefix: ["0-9", "a-z", "A-Z"],
      minLength: 8,
    },
  };

  (changePasswordData as any).error = {};
  required.input.map((key: string) => {
    const value: string = (changePasswordData as any)[key];
    const check =
      required.check && key !== "currentPassword"
        ? checkValidInput({ ...required.check, text: value })
        : true;
    if (!value || !check) {
      (changePasswordData as any)["error"][key] = true;
      isError = true;
    }
  });

  if (
    changePasswordData?.newPassword !== changePasswordData?.confirmNewPassword
  ) {
    changePasswordData.error.message = "รหัสผ่านไม่ตรงกัน";
    isError = true;
  }

  if (isError) {
    controller.setState({ changePasswordData: { ...changePasswordData } });
    return;
  }

  console.log(history);
};

export const HandleSignInByEmail: Handler = async (controller, params) => {
  const { inputEmail } = controller.getState();
  const { history } = params;

  if (!inputEmail) {
    controller.setState({ inputEmailError: true });
    return;
  }

  controller.setState({ inputEmailError: false });

  const [res, error] = await new Promise((resolve) => {
    setTimeout(() => {
      resolve([null, {}]);
    });
  });
  // console.log(res, error)

  if (error) {
    controller.setState({ signInByEmailError: true, viewIndexRegister: 0 });
    history.push(`${history.location.pathname}?screen=${"info"}`);
  } else {
    // history.push(`/tuh-transform/register?screen=${next}`);
  }
};

const clearRegisterInfo: Handler = (controller, params) => {
  controller.setState({
    registerInfo: {
      username: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      birthdate: "",
      hn: "",
      hidePassword: true,
      hideConfirmPassword: true,
      error: {
        username: null,
        password: null,
        confirmPassword: null,
        firstName: null,
        lastName: null,
        birthdate: null,
        hn: null,
        message: "",
      },
    },
  });
};
export const HandleGoBackSignInEmail: Handler = (controller, params) => {
  const { signInByEmailError } = controller.getState();
  const { headerName, history } = params;

  if (!signInByEmailError) {
    controller.setState({ inputEmailError: false, inputEmail: "" });
  } else if (signInByEmailError && headerName === "ลงทะเบียน") {
    clearRegisterInfo(controller, {});
    controller.setState({ signInByEmailError: false });
  }
  history.goBack();
};

export const HandleEmailRequest: Handler = (controller, params) => {
  controller.setState({ openModCheckEmail: true });
};

export const LoginIssara: Handler = async (controller, params) => {
  console.log("LoginIssara", params);

  let { username, password, history } = params;

  controller.setState({ loadingMainTransform: true });

  username = `${username}${MOR_COMPANY}`;

  let login: any = { username, password, application: "ishealth" };

  if (typeof window.MobNative !== "undefined") {
    const deviceDetail = window.MobNative?.getDevice?.();
    const device = JSON.parse(deviceDetail || "{}");
    login = {
      ...login,
      device_id: device.device_id,
      device_type: device.device_type,
      device_name: device.device_name,
      device_token: device.device_token,
      device_api_version: "20211219",
      application: device.application,
    };
  }

  const [resToken, errToken] = await PostRequestTokenAPI(controller, login);

  if (errToken) {
    controller.setState({
      loggedin: false,
      inputErrorLogin: {
        username: true,
        password: true,
        message: "Login ไม่สำเร็จ กรุณากรอก username, password ให้ถูกต้อง",
      },
      loadingMainTransform: false,
    });
    return;
  }

  Cookies.set("apiToken", resToken?.token);
  Cookies.set("division_id", resToken?.division);
  controller.apiToken = resToken?.token;
  Cookies.set("userId", resToken?.user_id);

  const [resProfile, errProfile] = await GetUserProfile(controller, {
    apiToken: resToken.token,
  });
  controller.setState({
    userProfile: resProfile || {},
    loadingMainTransform: false,
    inputErrorLogin: { username: false, password: false },
    userId: resToken?.user_id,
    apiToken: resToken?.token,
  });

  LoginFirebase(controller, { username: params.username });

  history.push("/tuh-transform/Chat");
};

export const LoginFirebase: Handler = (controller, params) => {
  console.log(" LoginFirebase")
  controller.functions
    .httpsCallable("createTokenFromUser")(params.username)
    .then((result: any) => {
      console.log("after createTokenFromUser Token", result.data);
      controller.app
        .auth()
        .signInWithCustomToken(result.data)
        .then((result: any) => {
          console.log("signInWithCustomToken sucess");

          // TODO: ตรงนี้ ถ้าไม่ใช้ firebase แล้ว ต้องย้ายกลับไป function login issara
          controller.setState({ loggedin: true });
        })
        .catch(function (error: any) {
          console.log("SiginWithCustomToken error");

          // TODO: ตรงนี้ ถ้าไม่ใช้ firebase แล้ว ต้องย้ายกลับไป function login issara
          controller.setState({ loggedin: true });
        });
      console.log(" SiginWithCustomToken !!!")
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const GetPatientDetail: Handler = async (controller, params) => {
  const [res, error] = await MyProxyPatientView.retrieve({
    apiToken: Cookies.get("apiToken"),
  });

  controller.setState({ patientData: res || {} });
};

export const HandleGoBackVerify: Handler = (controller, params) => {
  params.history.push("/tuh-transform/login");
  clearRegisterInfo(controller);
  controller.setState({
    viewIndexRegister: 0,
    userProfile: {},
    requestTokenDetail: {},
  });
};

export const HandleCloseMessage: Handler = (controller, params) => {
  controller.setState({ errorMessage: null, successMessage: null });
};

const BOTTOM_MENU = ["/profile", "/chat", "/appointment", "/login"]

export const HandleRouteChange: Handler = (controller, params) => {
  const { slideRouteRemember } = controller.getState();
  const { currentPathname, nextPathname, action } = params;

  const current: string = getPathname(currentPathname);
  const next: string = getPathname(nextPathname);
  let isDuration = isSlideDuration(currentPathname, nextPathname);
  const duration = 350;
  let cloneArray = [...(slideRouteRemember || [])];

  const slide: any = document.querySelector(".slide-routes.slide");
  const childNodes = slide?.childNodes;

  const onSlide = (translate: number) => {
    const isForward = translate === 100;
    const ceil = isForward ? 0 : 1;
    const floor = isForward ? 1 : 0;

    childNodes[0].style.transform = `translateX(0%)`;
    childNodes[0].style.transition = `none`;
    if (childNodes[1]) {
      childNodes[1].style.transform = `translateX(0%)`;
      childNodes[1].style.transition = `none`;
    }

    setTimeout(() => {
      if (!childNodes[1]) {
        return;
      }

      if (!isDuration) {
        setStopDuration(childNodes, floor, ceil);
        return;
      }

      childNodes[ceil].style.transition = `none`;
      childNodes[ceil].style.transform = `translateX(${translate}%)`;
      childNodes[floor].style.transition = `none`;
      childNodes[floor].style.transform = `translateX(${translate === 0 ? -20 : 0
        }%)`;

      setTimeout(() => {
        if (!childNodes[1]) {
          clearPosition(childNodes);
          return;
        }

        childNodes[ceil].classList.add("position");
        childNodes[ceil].style.transition = `transform ${duration * 0.9
          }ms ease`;
        childNodes[floor].style.transition = `transform ${duration}ms ease`;

        childNodes[ceil].style.transform = `translateX(${isForward ? 0 : 100
          }%)`;
        childNodes[floor].style.transform = `translateX(${isForward ? -20 : 0
          }%)`;

        clearPosition(childNodes);
      });
    });
  };

  const setStopDuration = (childNodes: any, floor: number, ceil: number) => {
    childNodes[floor].style.transition = `none`;
    childNodes[floor].style.transform = `translateX(${0}%)`;
    childNodes[ceil].style.opacity = 0;
    childNodes[ceil].style.transition = `none`;
    childNodes[ceil].style.transform = `translateX(${100}%)`;
    clearPosition(childNodes);
  };

  const clearPosition = (childNodes: any) => {
    const timer = () => {
      if (!childNodes[1]) {
        clearInterval(interval);
        childNodes[0].style.transition = ``;
        childNodes[0].style.transform = ``;
        childNodes[0].style.opacity = ``;
        childNodes[0].classList.remove("position");
      }
    };

    var interval = setInterval(timer);
  };

  const backExit = () => {
    onSlide(0);
  };

  const forwardExit = () => {
    onSlide(100);
  };

  if (["PUSH", "REPLACE"].includes(action)) {
    const nextFindIndex = cloneArray.findIndex((item) => item.key === next);
    const currentFindIndex = cloneArray.findIndex(
      (item) => item.key === current
    );

    if (nextFindIndex !== -1) {
      cloneArray = cloneArray.filter((_, index) => index < nextFindIndex);
    } else if (currentFindIndex !== -1) {
      cloneArray = cloneArray.filter((_, index) => index < currentFindIndex);
    } else {
      cloneArray = [...cloneArray, { key: current }];
    }
    if (
      BOTTOM_MENU.includes(next) ||
      (nextFindIndex !== -1 && !BOTTOM_MENU.includes(current))
    ) {
      backExit();
    } else {
      forwardExit();
    }
  } else if (action === "POP") {
    backExit();
    cloneArray = cloneArray.filter((item: any) => item.key !== next);
  }
  controller.setState({
    slideRouteRemember: cloneArray,
    previousPathname: current,
  });
};

// Utils
const checkValidInput = (params: {
  text: string;
  prefix: string[];
  minLength?: number;
  length?: number;
}) => {
  const { text = "", prefix = [], minLength = 0, length = 0 } = params;
  if (minLength && text.length < minLength) {
    return false;
  }

  if (length && text.length !== length) {
    return false;
  }

  const validAll = prefix.every((value) => {
    const regex = new RegExp(`[${value}]`, "g");
    return regex.test(text);
  });

  return validAll;
};

const checkValidBirthdate = (value: string) => {
  const reg = /\d{2}\/\d{2}\/\d{4}/g;

  if (!reg.test(value)) {
    return false;
  }

  const formatDate = value.replace(
    /(\d{2})\/(\d{2})\/(\d{4})/g,
    (...res) => `${+(res?.[3] || 0) - 543}-${res?.[2]}-${res?.[1]}`
  );

  const momentToAdd = (moment: moment.Moment) => {
    return moment.format("YYYY-MM-DD");
  };

  return moment(formatDate).isBetween(
    momentToAdd(moment().add(-130, "years")),
    momentToAdd(moment())
  );
};

export const getPathname = (path: string) => {
  const reg = /(\?.*)|(\/tuh-transform)/g;
  let split: string[] = path.replace(reg, "").split("/") || [];

  try {
    if (!split.at(-1)) {
      split.splice(-1, 1);
    }
  } catch (error) {
    split = [];
  }

  path = split
    .map((item, index) => (index >= 2 ? `ID${index - 1}` : item))
    .join("/");
  if (path === "") {
    path = "/Chat";
  }
  return path.toLowerCase();
};

export const isSlideDuration = (
  currentPathname: string,
  nextPathname: string
) => {
  let isDuration = true;
  const current: string = getPathname(currentPathname);
  const next: string = getPathname(nextPathname);

  if (BOTTOM_MENU.includes(current) && BOTTOM_MENU.includes(next)) {
    isDuration = false;
  }

  return isDuration;
};
