import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Button
} from 'semantic-ui-react'

const CardSegmentDetailUX = (props: any) => {
    return(
      <div>
        <div
          style={{ width: "100%", paddingBottom: "20px" }}>

          <Form>
            <FormGroup
              inline={true}>
              <FormField
                style={{minWidth: "5.5rem"}}
                width={1}>
                <label>
                  Title :
                </label>
              </FormField>
              <FormField
                width={12}>
                <Input
                  name="name"
                  onChange={props.onChangeCreateValue}
                  value={props.data?.name || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                style={{minWidth: "5.5rem"}}
                width={1}>
                <label>
                  Icon :
                </label>
              </FormField>
              <FormField
                style={{ display: "flex" }}
                width={7}>
                <Input
                  readOnly={true}
                  value={props.data?.icon?.name || ""}>
                </Input>
                <Button
                  color="blue"
                  onClick={props.onBrowseFile}
                  size="small">
                  Browse
                </Button>
              </FormField>
              <FormField
                style={{ display: "flex" }}
                width={3}>
                <label
                  style={{minWidth: "max-content"}}>
                  Icon Color :
                </label>
                <div
                  style={{ paddingLeft: "10px" }}>
                  {props.iconColor}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                style={{minWidth: "5.5rem"}}
                width={1}>
                <label>
                  Sequence :
                </label>
              </FormField>
              <FormField>
                <Input
                  name="display_seq"
                  onChange={props.onChangeCreateValue}
                  value={props.data?.display_seq || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                width={11}>
              </FormField>
              <FormField
                style={{ display: "flex" }}
                width={5}>
                <div>
                  {props.buttonSave}
                </div>
                <div>
                  {props.buttonDel}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardSegmentDetailUX

export const screenPropsDefault = {}

/* Date Time : Thu Oct 17 2024 11:43:52 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", paddingBottom: \"20px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Form",
      "parent": 2,
      "props": {
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"5.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 8,
      "props": {
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 10,
      "props": {
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCreateValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.name || \"\"",
          "valueEN": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "Title :"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"5.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Icon :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Input",
      "parent": 17,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data?.icon?.name || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "Icon Color :"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 24,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"5.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Sequence :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 24,
      "props": {
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Input",
      "parent": 27,
      "props": {
        "name": {
          "type": "value",
          "value": "display_seq"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCreateValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.display_seq || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Browse"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onBrowseFile"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.iconColor"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 31,
      "props": {
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 31,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonDel"
        }
      },
      "seq": 35,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardSegmentDetailUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
