import WasmController from "react-lib/frameworks/WasmController";
import moment from 'moment'
import { beToAd, adToBe } from "react-lib/utils/dateUtils";
// APIS
import DrugCounsellingListView from "issara-sdk/apis/DrugCounsellingListView_apps_TPD"
import DrugCounsellingDetailView from "issara-sdk/apis/DrugCounsellingDetailView_apps_TPD"
import _ from "react-lib/compat/lodashplus";

export type State = {
  errorMessage?: any;
  userTokenize?: any;
  selectedPatient?: any;
  buttonLoadCheck?: any;
  DrugCounsellingSequence?: {
    sequenceIndex?: string | null;
    filterStartDate?: string | null;
    filterEndDate?: string | null;
    filterTitle?: string | null;
    selectedAdvisor?: any;
    showCounsellingDetail?: Boolean | null;
    adviseDate?: string | null;
    title?: string | null;
    description?: string | null;
    drugCounsellingData?: any[];
    page?: number | null;
    totalPage?: number | null;
    selectedId?: number | null;
    note?: string | null;
    // for mod
    openMod?: boolean | null;
    modTitle?: string | null;
    modTitleColor?: string | null;
    modAction?: "EDIT" | "DELETE" | null;
  } | null
};

export const StateInitial: State = {
  DrugCounsellingSequence: null
}

export type Event = {}

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

const PAGE_LIMIT = 5;
const BE_FORMAT = "DD/MM/YYYY"
const AD_FORMAT = "YYYY-MM-DD"

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState()

  controller.setState({
    DrugCounsellingSequence: {
      sequenceIndex: "Action",
      filterStartDate: adToBe(moment().format("DD/MM/YYYY")),
      filterEndDate: adToBe(moment().format("DD/MM/YYYY")),
      filterTitle: "",
      selectedAdvisor: null,
      showCounsellingDetail: false,
      adviseDate: adToBe(moment().format("DD/MM/YYYY")),
      title: "",
      description: ""
    }
  }, async () => {
    await Action(controller, { action: "search" })
  })
}

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "search") {
    const drugCounselling = await DrugCounsellingListView.list({
      apiToken: controller.apiToken,
      params: {
        patient: state.selectedPatient?.id,
        start_date: state.DrugCounsellingSequence?.filterStartDate,
        end_date: state.DrugCounsellingSequence?.filterEndDate,
        title: state.DrugCounsellingSequence?.filterTitle,
        advisor: state.DrugCounsellingSequence?.selectedAdvisor?.id,
        offset: 0,
        limit: PAGE_LIMIT
      }
    })

    if (drugCounselling[1]) {
      return controller.setState({ errorMessage: { ...state.errorMessage, [params?.card]: drugCounselling[1] } })
    }

    controller.setState({
      DrugCounsellingSequence: {
        ...state.DrugCounsellingSequence,
        drugCounsellingData: drugCounselling[0]?.items || [],
        page: 1,
        totalPage: Math.ceil((drugCounselling[0]?.total || 1) / PAGE_LIMIT)
      }
    })

  } else if (params?.action === "page_change" && params?.page) {
    const drugCounselling = await DrugCounsellingListView.list({
      apiToken: controller.apiToken,
      params: {
        patient: state.selectedPatient?.id,
        start_date: state.DrugCounsellingSequence?.filterStartDate,
        end_date: state.DrugCounsellingSequence?.filterEndDate,
        title: state.DrugCounsellingSequence?.title,
        advisor: state.DrugCounsellingSequence?.selectedAdvisor,
        offset: (params?.page - 1) * PAGE_LIMIT,
        limit: PAGE_LIMIT
      }
    })

    if (drugCounselling[1]) {
      return controller.setState({ errorMessage: { ...state.errorMessage, [params?.card]: drugCounselling[1] } })
    }

    controller.setState({
      DrugCounsellingSequence: {
        ...state.DrugCounsellingSequence,
        drugCounsellingData: drugCounselling[0]?.items || [],
        page: params.page,
        totalPage: Math.ceil((drugCounselling[0]?.total || 1) / PAGE_LIMIT)
      }
    })
  } else if (params?.action === "change_value") {
    controller.setState({
      DrugCounsellingSequence: {
        ...state.DrugCounsellingSequence,
        [params.name]: params.value
      }
    })
  } else if (params?.action === "add_item") {
    controller.setState({
      DrugCounsellingSequence: {
        ...state.DrugCounsellingSequence,
        showCounsellingDetail: true,
        adviseDate: adToBe(moment().format("DD/MM/YYYY")),
        title: "",
        description: "",
        selectedId: null,
      },
      userTokenize: null
    })
  } else if (params?.action === "edit_item") {
    const counsellingRetrive = await DrugCounsellingDetailView.retrieve({
      pk: params?.data?.id,
      apiToken: controller.apiToken,
    })

    if (counsellingRetrive[1]) {
      return controller.setState({ errorMessage: { ...state.errorMessage, [params?.card]: counsellingRetrive[1] } })
    }

    controller.setState({
      DrugCounsellingSequence: {
        ...state.DrugCounsellingSequence,
        showCounsellingDetail: true,
        selectedId: counsellingRetrive[0]?.id,
        title: counsellingRetrive[0]?.title,
        adviseDate: adToBe(counsellingRetrive[0]?.date),
        description: counsellingRetrive[0]?.description
      },
      userTokenize: null
    })
  } else if (params?.action === "save") {
    if (state.DrugCounsellingSequence?.selectedId) {
      if (!state.DrugCounsellingSequence?.note) {
        return controller.setState({
          DrugCounsellingSequence: {
            ...state.DrugCounsellingSequence,
            openMod: true,
            modTitle: "กรุณาระบุเหตุผลในการแก้ไข",
            modTitleColor: "yellow",
            modAction: "EDIT"
          }
        })
      }

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "LOADING",
        },
      })
      const counsellingUpdate = await DrugCounsellingDetailView.update({
        pk: state.DrugCounsellingSequence?.selectedId,
        apiToken: controller.apiToken,
        data: {
          patient: state.selectedPatient?.id,
          title: state.DrugCounsellingSequence?.title,
          description: state.DrugCounsellingSequence?.description,
          date: moment(beToAd(state.DrugCounsellingSequence?.adviseDate as string), BE_FORMAT).format(AD_FORMAT),
          advisor_full_name: state.userTokenize?.employeeName,
          note: state.DrugCounsellingSequence?.note
        }
      })

      if (counsellingUpdate[1]) {
        return controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params?.card]: counsellingUpdate[1]
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.card]: "ERROR",
          }
        })
      }

    } else {

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "LOADING",
        },
      })
      const counsellingCreate = await DrugCounsellingListView.create({
        apiToken: controller.apiToken,
        data: {
          patient: state.selectedPatient?.id,
          title: state.DrugCounsellingSequence?.title,
          description: state.DrugCounsellingSequence?.description,
          date: moment(beToAd(state.DrugCounsellingSequence?.adviseDate as string), BE_FORMAT).format(AD_FORMAT),
          advisor_full_name: state.userTokenize?.employeeName,
          note: ""
        }
      })

      if (counsellingCreate[1]) {
        return controller.setState({
          errorMessage: { ...state.errorMessage, [params?.card]: counsellingCreate[1] },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.card]: "ERROR",
          }
        })
      }
    }
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card]: "SUCCESS",
      },
      DrugCounsellingSequence: {
        ...state.DrugCounsellingSequence,
        openMod: false,
        modTitle: "",
        modTitleColor: "",
        modAction: null
      }
    }, async () => (await setTimeout(() => {
      controller.setState({
        DrugCounsellingSequence: {
          ...state.DrugCounsellingSequence,
          adviseDate: adToBe(moment().format("DD/MM/YYYY")),
          title: "",
          description: "",
          showCounsellingDetail: false,
          selectedId: null,
          note: "",
          openMod: false,
          modTitle: "",
          modTitleColor: "",
          modAction: null
        },
        userTokenize: null
      }, async () => {
        await Action(controller, { action: "search" })
      })
    }, 1500)))

  } else if (params?.action === "delete_item") {
    if (params?.data?.id || state.DrugCounsellingSequence?.selectedId) {
      if (!state.DrugCounsellingSequence?.note) {
        return controller.setState({
          DrugCounsellingSequence: {
            ...state.DrugCounsellingSequence,
            openMod: true,
            modTitle: "กรุณาระบุเหตุผลในการลบ",
            modTitleColor: "red",
            modAction: "DELETE",
            selectedId: params?.data?.id || state.DrugCounsellingSequence?.selectedId
          }
        })
      }
      const counsellingUpdate = await DrugCounsellingDetailView.delete({
        apiToken: controller.apiToken,
        pk: state.DrugCounsellingSequence?.selectedId,
        params: {
          note: state.DrugCounsellingSequence?.note
        }
      })

      if (counsellingUpdate[1]) {
        return controller.setState({ errorMessage: { ...state.errorMessage, [params?.card]: counsellingUpdate[1] } })
      }

    }

    controller.setState({
      DrugCounsellingSequence: {
        ...state.DrugCounsellingSequence,
        adviseDate: adToBe(moment().format("DD/MM/YYYY")),
        title: "",
        description: "",
        showCounsellingDetail: false,
        note: "",
        openMod: false,
        modTitle: "",
        modTitleColor: "",
        modAction: null
      }
    }, async () => {
      await Action(controller, { action: "search" })
    })

  } else if (params?.action === "close_detail") {
    controller.setState({
      DrugCounsellingSequence: {
        ...state.DrugCounsellingSequence,
        showCounsellingDetail: false
      }
    })
  } else if (params?.action === "close_modal") {
    controller.setState({
      DrugCounsellingSequence: {
        ...state.DrugCounsellingSequence,
        note: "",
        openMod: false,
        modTitle: "",
        modTitleColor: "",
        modAction: null
      }
    })
  }
}
