import React, { FC, forwardRef, useImperativeHandle, useRef } from "react";
import { Form, Grid, Header } from "semantic-ui-react";
import CardMuscleTone from "./CardMuscleTone";
import CardMuscleStrength from "./CardMuscleStrength";
import CardRangeOfMotion from "./CardRangeOfMotion";
import CardSensory from "./CardSensory";
import { useIntl } from "react-intl";

const styles = {
  gridBG: {
    background: "#F3F3F3",
    marginLeft: "1px",
    marginRight: "1px",
    paddingTop: "15px",
    paddingLeft: "0px",
  },
  noPadding: {
    padding: "0px",
  },
  leftPadding: {
    paddingLeft: "5px",
  },
};

export interface CardPOPhysicalExamAnkleFootOrthosisMIDViewDataType {
  dropDownOptions: {
    sensoryOptions: any[];
  };
  viewData: {
    muscleStrength: {
      isNormal: boolean;
      items: [
        {
          normalRemark: string;
          grade: string;
        }
      ];
    };
    muscleTone: {
      isNormal: boolean;
      normal: string;
      isSpasticity: boolean;
      spasticity: string;
      isFlaccid: boolean;
      flaccid: string;
      isNA: boolean;
    };
    sensory: {
      choice: number;
      remark: string;
    };
    rangeOfMotion: {
      isFull: boolean;
      items: [{ limit: string }];
    };
  };
  readonly: boolean;
}

const CardPOPhysicalExamAnkleFootOrthosisMID: FC<CardPOPhysicalExamAnkleFootOrthosisMIDViewDataType> = forwardRef(
  (props, ref) => {
    // Muscle Strength
    const subMuscleStrengthRef = useRef();

    // Muscle tone
    const subMuscleToneRef = useRef();

    // Sensory
    const subSensoryRef = useRef();

    // Range Of Motion
    const subRangeOfMotionRef = useRef();

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          muscleStrength: subMuscleStrengthRef.current.getData(),
          muscleTone: subMuscleToneRef.current.getData(),
          sensory: subSensoryRef.current.getData(),
          rangeOfMotion: subRangeOfMotionRef.current.getData(),
        };
      },
    }));

    return (
      <>
        {/* Muscle strength */}
        <Grid style={styles.gridBG}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field width={14}>
                    <Header.Content>
                      <strong>Muscle strength</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.leftPadding}>
              <CardMuscleStrength
                ref={subMuscleStrengthRef}
                data={props.viewData.muscleStrength}
                readonly={props.readonly}
                languageUX={props.languageUX}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Muscle Tone */}
        <Grid style={styles.gridBG}>
          <Grid.Row columns={1} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field width={14}>
                    <Header.Content>
                      <strong>Muscle tone</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.leftPadding}>
              <CardMuscleTone
                ref={subMuscleToneRef}
                data={props.viewData.muscleTone}
                readonly={props.readonly}
                languageUX={props.languageUX}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Sensory */}
        <Grid style={styles.gridBG}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field width={14}>
                    <Header.Content>
                      <strong>Sensory</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.leftPadding}>
              <CardSensory
                ref={subSensoryRef}
                data={props.viewData.sensory}
                dropDownOptions={props.dropDownOptions}
                readonly={props.readonly}
                languageUX={props.languageUX}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Range of motion */}
        <Grid style={styles.gridBG}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={1}></Form.Field>
                  <Form.Field width={15}>
                    <Header.Content>
                      <strong>Range of motion</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.leftPadding}>
              <CardRangeOfMotion
                ref={subRangeOfMotionRef}
                data={props.viewData.rangeOfMotion}
                readonly={props.readonly}
                languageUX={props.languageUX}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
);

export const CardPOPhysicalExamAnkleFootOrthosisMIDInitialViewData: CardPOPhysicalExamAnkleFootOrthosisMIDViewDataType = {
  dropDownOptions: { sensoryOptions: [] },
  viewData: {
    muscleStrength: {
      isNormal: false,
      items: [{ normalRemark: "", grade: "" }],
    },
    muscleTone: {
      isNormal: true,
      normal: "",
      isSpasticity: false,
      spasticity: "",
      isFlaccid: false,
      flaccid: "",
      isNA: false,
    },
    sensory: {
      choice: null,
      remark: "",
    },
    rangeOfMotion: {
      isFull: true,
      items: [{ limit: "" }],
    },
  },
  readonly: false,
};

CardPOPhysicalExamAnkleFootOrthosisMID.defaultProps = CardPOPhysicalExamAnkleFootOrthosisMIDInitialViewData;

export default CardPOPhysicalExamAnkleFootOrthosisMID;
