import React, { useEffect } from "react";
import { Icon, Tab, Input, TabProps } from "semantic-ui-react";

// UI
import { MenuItem } from "./CardSurgeryTeam";
import CardAddSurgeryTeamUX from "./CardAddSurgeryTeamUX";
import SubAssistantsInput from "./SubAssistantsInput";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";

// Interface
import { TeamInitial } from "./sequence/PerioperativeNursing";
import { useIntl } from "react-intl";

const CardBasicInfoSurgeryTeam = (props: any) => {
  const intl = useIntl();
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
    });
  }, []);

  const handleSelectTeam = (e: any, data: TabProps) => {
    if (props.surgery_teams?.length === Number(data.activeIndex)) {
      handleAddTeam(e);
      return;
    }
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: "basicInfoData",
      key: "activeSurgeonTeam",
      value: Number(data.activeIndex),
    });
  };

  const handleAddTeam = (e: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "add_surgery_team",
    });
  };

  const handleChiefSurgeon = (index: number) => async (item: any) => {
    await props.setProp(
      `PerioperativeNursingSequence.basicInfoData.surgery_teams.${index}.chief_surgeon`,
      item || null
    );

    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "selected_surgeon_team",
      index,
      item,
    });
  };

  const handleUpdateAssistantSurgeon = (index: number) => (items: any[]) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: "basicInfoData",
      key: `surgery_teams.${index}.assistant_surgeon_items`,
      value: items,
    });
  };

  const handleUpdateScrubNurse = (index: number) => (items: any[]) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: "basicInfoData",
      key: `surgery_teams.${index}.scrub_nurses_items`,
      value: items,
    });
  };

  const handleAddScrubNurse = (index: number, items: any[]) => {
    const result = items || [];
    if (result.every((item: any) => item)) {
      result.push(null);
    } else {
      return;
    }
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: "basicInfoData",
      key: `surgery_teams.${index}.scrub_nurses_items`,
      value: result,
    });
  };

  const handleAddCirculatingNurse = (index: number, items: any[]) => {
    const result = items || [];
    if (result.every((item: any) => item)) {
      result.push(null);
    } else {
      return;
    }
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: "basicInfoData",
      key: `surgery_teams.${index}.circulating_nurses_items`,
      value: result,
    });
  };

  const handleUpdateCirculatingNurse = (index: number) => (items: any[]) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: "basicInfoData",
      key: `surgery_teams.${index}.circulating_nurses_items`,
      value: items,
    });
  };

  const handleDeleteTeam = async () => {
    const teams = props.surgery_teams || [];

    if (teams.length === 1) {
      teams[props.activeIndex] = TeamInitial;
    } else {
      teams.splice(props.activeIndex, 1);

      const index = props.activeIndex - 2;

      await props.runSequence({
        sequence: "PerioperativeNursing",
        action: "SET_DATA",
        cardKey: "basicInfoData",
        key: "activeSurgeonTeam",
        value: index < 0 ? 0 : index,
      });
    }

    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: "basicInfoData",
      key: "surgery_teams",
      value: teams,
    });
  };

  return (
    <div style={{ padding: "1rem", marginTop: "-1rem" }}>
      <Tab
        menu={{ secondary: true, pointing: true }}
        onTabChange={handleSelectTeam}
        panes={[
          ...(props?.surgery_teams || []).map((item: any, index: number) => ({
            menuItem: {
              key: `MenuItem-${index}`,
              content: (
                <MenuItem
                  index={index}
                  activeIndex={props.activeIndex}
                  title="Surgery Team"
                  isStar={item.is_main}
                />
              ),
            },
            render: () => (
              <CardAddSurgeryTeamUX
                isMain={item.is_main}
                handleDeleteTeam={handleDeleteTeam}
                onAddScrubNurse={() => handleAddScrubNurse(index, item.scrub_nurses_items)}
                onAddCirculatingNurse={() =>
                  handleAddCirculatingNurse(index, item.circulating_nurses_items)
                }
                chiefSurgeon={
                  <SearchBoxWithKey
                    // callback
                    onEvent={props.onEvent}
                    type="Doctor"
                    id={`Surgeon_${index + 1}`}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    selectedItem={item.chief_surgeon || null}
                    setSelected={handleChiefSurgeon(index)}
                  />
                  // item.is_main ? (
                  //   <Input
                  //     fluid={true}
                  //     readOnly={true}
                  //     value={item.chief_surgeon_item.name_code}
                  //     style={{ width: "100%" }}
                  //   />
                  // ) : (
                  //   <SearchBoxWithKey
                  //     // callback
                  //     onEvent={props.onEvent}
                  //     type="Doctor"
                  //     id={`Surgeon_${index + 1}`}
                  //     searchedItemListWithKey={props.searchedItemListWithKey}
                  //     selectedItem={item.chief_surgeon || null}
                  //     setSelected={handleChiefSurgeon(index)}
                  //     disabled={item.main}
                  //   />
                  // )
                }
                assistant_surgeons={
                  <SubAssistantsInput
                    // callback
                    onEvent={props.onEvent}
                    // data
                    index={index}
                    items={item.assistant_surgeon_items || []}
                    type="Doctor"
                    field="Assist_Surgeon"
                    hideDateTime={true}
                    isDetail={true}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    // setSelected={handleActionAssistantSurgeon(index)}
                    // onClick={handleActionAssistantSurgeon(index)}
                    onUpdated={handleUpdateAssistantSurgeon(index)}
                    languageUX={props.languageUX}
                  />
                }
                scrub_nurses={
                  <SubAssistantsInput
                    // callback
                    onEvent={props.onEvent}
                    // data
                    index={index}
                    items={item.scrub_nurses_items || []}
                    type="User"
                    role="REGISTERED_NURSE"
                    field="Nurse_Scrub_Surgeon"
                    hideDateTime={false}
                    isDetail={true}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    // setSelected={handleActionScrubNurse(index)}
                    // onClick={handleActionScrubNurse(index)}
                    onUpdated={handleUpdateScrubNurse(index)}
                    languageUX={props.languageUX}
                  />
                }
                circulating_nurses={
                  <SubAssistantsInput
                    // callback
                    onEvent={props.onEvent}
                    // data
                    index={index}
                    items={item.circulating_nurses_items || []}
                    type="User"
                    role="REGISTERED_NURSE"
                    field="Nurse_Circulating_Surgeon_"
                    hideDateTime={false}
                    isDetail={true}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    // setSelected={handleActionCirculatingNurse(index)}
                    // onClick={handleActionCirculatingNurse(index)}
                    onUpdated={handleUpdateCirculatingNurse(index)}
                    languageUX={props.languageUX}
                  />
                }
                languageUX={props.languageUX}
              />
            ),
          })),
          {
            menuItem: {
              key: `MenuItem-${props.items?.length || 0}`,
              content: (
                <div>
                  <Icon name="add circle" color="teal" style={{ cursor: "pointer" }} />
                </div>
              ),
            },
          },
        ]}
      />
    </div>
  );
};

export default React.memo(CardBasicInfoSurgeryTeam);
