// Utils
import moment from "moment";
import { formatDatetime } from "react-lib/utils/dateUtils";
import { HeaderLogoWithPublishDetail } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";
import { TDocumentDefinitions } from "pdfmake/interfaces";

const FORM_NAME = `FormAnesDurationStatReport`;

const FormAnesDurationStatReport = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Yeti Anes Duration Props: ", props);

  const currentDate = moment();

  const headerForm = await HeaderLogoWithPublishDetail({
    form: FORM_NAME,
    font: "THSarabunNew",
    logoHeight: 30,
    isHospitalVertical: true,
    haveBorder: true,
    pageMargins: [10, 85, 10, 10],
    headerMargins: [10, 10, 10, 0],
    titleContent: [
      {
        text: `จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามช่วงเวลาดมยา`,
        alignment: "center",
        fontSize: 15,
        bold: true,
      },
      {
        text: `วิธีระงับความรู้สึก ${props.params?.anesthesia_label || "ทั้งหมด"}`,
        alignment: "center",
        fontSize: 15,
      },
      {
        text: `วันที่ดมยา ${props.params?.startDate || " "} - ${props.params?.endDate || " "}`,
        alignment: "center",
        fontSize: 15,
      },
    ],
    publishContent: [
      {
        text: [
          { text: "ผู้พิมพ์: ", bold: true, preserveLeadingSpaces: true },
          { text: props.params?.print_user || " " },
        ],
        alignment: "right",
        fontSize: 12,
      },
      {
        text: [
          { text: "วันที่พิมพ์: ", bold: true, preserveLeadingSpaces: true },
          { text: formatDatetime(currentDate, true) },
        ],
        alignment: "right",
        fontSize: 12,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const generateTableBody = (data: any, columns: string[]) => {
    let body: any = [];

    // Header of Table
    body.push([
      { text: "วิธีระงับความรู้สึก", bold: true, alignment: "center" },
      { text: "0 - 2 ชม.", bold: true, alignment: "center" },
      { text: "> 2 - 4 ชม.", bold: true, alignment: "center" },
      { text: "> 4 - 8 ชม.", bold: true, alignment: "center" },
      { text: "> 8 ชม.", bold: true, alignment: "center" },
      { text: "รวม", bold: true, alignment: "center" },
    ]);

    if (Object.keys(data).length > 0) {
      const fieldsData = data?.items;

      fieldsData.map((item: any) => {
        item.duration_range.forEach((range: string, index: number) => {
          item[range] = item.duration_count[index];
        });
      });

      const summaryData = data?.summaryTotals?.duration;

      summaryData.duration_range.forEach((range: string, index: number) => {
        summaryData[range] = summaryData.duration_count[index];
      });

      fieldsData.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          switch (column) {
            case "anesthesia_name":
              dataRow.push({ text: row[column], bold: true });
              break;
            default:
              dataRow.push({ text: row[column].toString(), alignment: "center" });
              break;
          }
        });
        body.push(dataRow);
      });

      if (summaryData) {
        body.push([
          {
            text: "จำนวนผู้รับบริการรวม",
            fillColor: "#cccccc",
          },
          {
            text: `${summaryData["0 - 2 ชม."]}`,
            fillColor: "#cccccc",
            bold: true,
            alignment: "center",
          },
          {
            text: `${summaryData["> 2 - 4 ชม."]}`,
            fillColor: "#cccccc",
            bold: true,
            alignment: "center",
          },
          {
            text: `${summaryData["> 4 - 8 ชม."]}`,
            fillColor: "#cccccc",
            bold: true,
            alignment: "center",
          },
          {
            text: `${summaryData["> 8 ชม."]}`,
            fillColor: "#cccccc",
            bold: true,
            alignment: "center",
          },
          {
            text: `${summaryData["รวม"]}`,
            fillColor: "#cccccc",
            bold: true,
            alignment: "center",
          },
        ]);
      }
    }

    return body;
  };

  const tableReport = (data: any, columns: string[]) => {
    return {
      table: {
        widths: ["*", "15%", "15%", "15%", "15%", "15%"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };

  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 16, // default
    },
    ...headerForm,
    styles: { ...styles },
    images: { ...images },
    content: [
      tableReport(props || {}, [
        "anesthesia_name",
        "0 - 2 ชม.",
        "> 2 - 4 ชม.",
        "> 4 - 8 ชม.",
        "> 8 ชม.",
        "รวม",
      ]),
    ],
  };
};

export default FormAnesDurationStatReport;
