import {
  ContentStack,
  ContentColumns,
  TDocumentDefinitions,
  ContentTable,
  LineStyle,
} from "pdfmake/interfaces";

// Common
import moment from "moment";
// import { checkPageBreakBefore } from "react-lib/apps/HISV3/common/CommonInterface";
import {
  HeaderLogoWithPatientDetail,
  HeaderPrintListProps,
} from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";
import { getCompanyFont } from "react-lib/apps/HISV3/common/CompanyLogoForm";
import FormSymbolImages from "react-lib/apps/common/pdfPrintList/AddSymbolPrintList";

// Util
import { formatDatetime } from "react-lib/utils/dateUtils";

type FormPreOperationProps = {
  detail: HeaderPrintListProps["detail"];
  userFullName: string;
  preOpVisitData: any;
  orRegisAreaData: any;
  holdingRoomData: any;
};

type topicName = "Pre - OP Visit" | "OR Registration Area" | "Holding Room";

interface ContentStackWithId extends Omit<ContentStack, "stack"> {
  id?: string;
  stack: ContentStack["stack"];
}

interface ITDocumentDefinitions extends TDocumentDefinitions {
  content: (ContentStackWithId | TDocumentDefinitions["content"])[];
}

const FONT_SIZE = 14;

const FORM_PRE_OPERATION = "FormPreOperation";

const PRE_OP_VISIT_KEY: Record<string, string> = {
  history_illness: `History`,
  previous_operating: `Previous Operating`,
  status_of_consciousness: `Status of Conscious`,
  psychological_status: `Psychological Status`,
  respiratory_status: `Respiratory Status`,
  limitation: `Limitation`,
  pre_implant: `Previous Implant`,
  Remark: `Remark`,
} as const;

//* แบ่งเป็น Part สำหรับจัดลำดับการ gen
const OR_RIGISTER_AREA_ONE_KEY: Record<string, string> = {
  type_surgeon: `Type of Surgeon`,
} as const;

const OR_RIGISTER_AREA_TWO_KEY: Record<string, string> = {
  mark_site_confirm: `Mark Site Confirm`,
  is_procedure_confirm: `Procedure Confirm`,
  is_consent_form: `Consent Form`,
  is_npo: `NPO`,
} as const;

const OR_RIGISTER_AREA_THREE_KEY: Record<string, string> = {
  lab_remark: `Lab Remark`,
  blood: `Blood`,
  imaging: `Imaging Test`,
  remark: `Remark`,
} as const;

const HOLDING_ROOM_ONE_KEY: Record<string, string> = {
  history_illness: `History Illness`,
  previous_operation: `Previous Operation`,
} as const;

const HOLDING_ROOM_TWO_KEY: Record<string, string> = {
  confirm_surgical: `Confirmation Surgical Site per PT/Family`,
} as const;

const HOLDING_ROOM_THREE_KEY: Record<string, string> = {
  limitation: `Limitation`,
  status_of_conscious: `Status of Conscious`,
  psychological_status: `Psychological Status`,
  respiratory_status: `Respiratory Status`,
  lab_remark: `Lab Remark`,
  blood: `Blood`,
  remark: `Remark`,
} as const;

const FormPreOperation = async (props: FormPreOperationProps): Promise<ITDocumentDefinitions> => {
  const currentDate = moment();

  const headerForm = await HeaderLogoWithPatientDetail({
    detail: props.detail,
    font: getCompanyFont(),
    form: FORM_PRE_OPERATION,
    formName: "PRE OPERATION",
    logoHeight: 38,
    pageMargins: [8, 95.25, 8, 30],
    titleContent: [],
  });
  const { font, fonts, fontSizes, images, lineHeights, styles } = headerForm;

  console.log("FormPreOperation Props: ", props);

  const genContentColumns = (
    keyMapping: Record<string, string>,
    data: any,
    options: {
      keyStyle?: string;
      valueStyle?: string;
      columnGap?: number;
      keyWidth?: number | "auto";
      valueWidth?: number | "auto";
    } = {}
  ): ContentColumns[] => {
    const {
      keyStyle = "fieldKeyBolder",
      valueStyle = "fieldValue",
      columnGap = 5,
      keyWidth = 120,
      valueWidth = "auto",
    } = options;

    return Object.keys(keyMapping)
      .filter((key) => data[key] !== undefined && data[key] !== null && data[key] !== "")
      .map((key) => {
        const label = `${keyMapping[key]}:` || key;
        const value = data[key] ?? "-";

        return {
          columns: [
            { text: label, style: keyStyle, width: keyWidth },
            { text: value, style: valueStyle, width: valueWidth },
          ],
          margin: [8, 0, 0, 0],
          columnGap,
        };
      });
  };

  const genPreOPVisit = (data: any): ContentStack => {
    const dateOfVisitCaseCol: ContentColumns[] =
      data["date_of_visit"] !== undefined
        ? [
            {
              columns: [
                {
                  columns: [
                    { text: `Date of Visit:`, style: "fieldKeyBolder" },
                    { text: data["date_of_visit"], style: "fieldValue" },
                  ],
                  columnGap: 5,
                  width: `auto`,
                },
                {
                  columns: [
                    { text: `Location:`, style: "fieldKeyBolder" },
                    { text: data["location"], style: "fieldValue" },
                  ],
                  columnGap: 5,
                  width: `auto`,
                },
                //* ไม่มี has_no_visit_reason_detail
                {
                  columns: [
                    { text: `No Visit Reason:`, style: "fieldKeyBolder" },
                    { text: data["has_no_visit_reason"] ? "Yes" : "-", style: "fieldValue" },
                  ],
                  columnGap: 5,
                  width: `auto`,
                },
              ],
              margin: [8, 0, 0, 0],
              columnGap: 10,
            },
          ]
        : [];

    const ambulatoryCaseCol: ContentColumns[] =
      data["ambulatory_case"] !== undefined
        ? [
            {
              columns: [
                { text: "Ambulatory Case:", style: "fieldKeyBolder", width: 120 },
                {
                  text: data["anbulatory_case"] ? "Yes" : "No",
                  style: "fieldValue",
                  width: "auto",
                },
                {
                  text: data["take_home"] ? "มีบุคคลนำผู้ป่วยกลับบ้าน" : "",
                  style: "fieldValue",
                  width: "auto",
                },
                { text: data["take_home_detail"] ?? "-", style: "fieldValue", width: "auto" },
              ],
              columnGap: 5,
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    return {
      stack: [
        ...dateOfVisitCaseCol,
        ...genContentColumns(PRE_OP_VISIT_KEY, data),
        ...ambulatoryCaseCol,
      ],
    };
  };

  const genOrRegisArea = (data: any): ContentStack => {
    const typeOfCaseCol: ContentColumns = {
      columns: [
        {
          columns: [
            { text: `Type of Case:`, style: "fieldKeyBolder" },
            { text: data["order_type_label"], style: "fieldValue" },
          ],
          columnGap: 5,
          width: `auto`,
        },
        {
          columns: [
            { text: `Transfer By:`, style: "fieldKeyBolder" },
            { text: data["transfer_by"], style: "fieldValue" },
          ],
          columnGap: 5,
          width: `auto`,
        },
      ],
      margin: [8, 0, 0, 0],
      columnGap: 10,
    };

    const patientIdentificationCol: ContentColumns = {
      columns: [
        { text: "Patient Identification: ", style: "fieldKeyBolder", width: 115 },
        {
          columns: [
            {
              image: data["is_verbally"] ? "checked" : "unchecked",
              width: 10,
              margin: [0, 3, 0, 0],
            },
            { text: "Verbally", style: "fieldValue" },
          ],
          columnGap: 5,
          width: `auto`,
        },
        {
          columns: [
            {
              image: data["is_name_band"] ? "checked" : "unchecked",
              width: 10,
              margin: [0, 3, 0, 0],
            },
            { text: "Name band", style: "fieldValue" },
          ],
          columnGap: 5,
          width: `auto`,
        },
        {
          columns: [
            { image: data["is_chart"] ? "checked" : "unchecked", width: 10, margin: [0, 3, 0, 0] },
            { text: "Chart", style: "fieldValue" },
          ],
          columnGap: 5,
          width: `auto`,
        },
      ],
      margin: [8, 0, 0, 0],
      columnGap: 10,
    };

    const valuableCol: ContentColumns[] =
      data["is_valuable"] !== undefined
        ? [
            {
              columns: [
                { text: "Valuable:", style: "fieldKeyBolder", width: 120 },
                { text: data["is_valuable"] ? "Yes" : "No", style: "fieldValue", width: "auto" },
                {
                  text: data["valuable"] ? data["valuable"].join(", ") : "",
                  style: "fieldValue",
                  width: "auto",
                },
              ],
              columnGap: 5,
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    const implantCol: ContentColumns[] =
      data["is_impant"] !== undefined
        ? [
            {
              columns: [
                { text: "Implant:", style: "fieldKeyBolder", width: 120 },
                { text: data["is_impant"] ? "Yes" : "No", style: "fieldValue", width: "auto" },
                { text: data["implant"] ?? "", style: "fieldValue", width: "auto" },
              ],
              columnGap: 5,
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    return {
      stack: [
        typeOfCaseCol,
        ...genContentColumns(OR_RIGISTER_AREA_ONE_KEY, data),
        patientIdentificationCol,
        ...genContentColumns(OR_RIGISTER_AREA_TWO_KEY, data),
        ...valuableCol,
        ...implantCol,
        ...genContentColumns(OR_RIGISTER_AREA_THREE_KEY, data),
      ],
    };
  };

  const genHoldingRoom = (data: any): ContentStack => {
    const patientIdentificationCol: ContentColumns = {
      columns: [
        { text: "Patient Identification: ", style: "fieldKeyBolder", width: 115 },
        {
          columns: [
            { image: data["verbally"] ? "checked" : "unchecked", width: 10, margin: [0, 3, 0, 0] },
            { text: "Verbally", style: "fieldValue" },
          ],
          columnGap: 5,
          width: `auto`,
        },
        {
          columns: [
            { image: data["name_band"] ? "checked" : "unchecked", width: 10, margin: [0, 3, 0, 0] },
            { text: "Name band", style: "fieldValue" },
          ],
          columnGap: 5,
          width: `auto`,
        },
        {
          columns: [
            { image: data["chart"] ? "checked" : "unchecked", width: 10, margin: [0, 3, 0, 0] },
            { text: "Chart", style: "fieldValue" },
          ],
          columnGap: 5,
          width: `auto`,
        },
      ],
      margin: [8, 0, 0, 0],
      columnGap: 10,
    };

    const procedureConfirmCol: ContentColumns[] =
      data["procedure_confirm"] !== undefined
        ? [
            {
              columns: [
                { text: "Procedure Confirm:", style: "fieldKeyBolder", width: 120 },
                {
                  text: data["procedure_confirm"] ? "Yes" : "No",
                  style: "fieldValue",
                  width: "auto",
                },
                {
                  text: data["procedure_confirm_detail"] ?? "",
                  style: "fieldValue",
                  width: "auto",
                },
              ],
              columnGap: 5,
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    const markSiteConfirmCol: ContentColumns[] =
      data["mark_site_confirm"] !== undefined
        ? [
            {
              columns: [
                { text: "Mark Site Confirm:", style: "fieldKeyBolder", width: 120 },
                { text: data["mark_site_confirm"], style: "fieldValue", width: "auto" },
                {
                  text: data["mark_site_confirm_detail"] ?? "",
                  style: "fieldValue",
                  width: "auto",
                },
              ],
              columnGap: 5,
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    const homeMedicationCol: ContentColumns[] =
      data["anti_coagulation_drug"] !== undefined ||
      data["dm_drug"] !== undefined ||
      data["anti_hypertensive_drug"] !== undefined
        ? [
            {
              columns: [
                { text: "Home Medication:", style: "fieldKeyBolder", width: 120 },
                {
                  text: [
                    data["anti_coagulation_drug"] ? "Anti Coagulation Drug" : null,
                    data["dm_drug"] ? "DM Drug" : null,
                    data["anti_hypertensive_drug"] ? "Anti Hypertensive Drug" : null,
                  ]
                    .filter((val) => val !== null)
                    .join(", "),
                  style: "fieldValue",
                  width: "auto",
                },
              ],
              columnGap: 5,
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    const valuableCol: ContentColumns[] =
      data["valuable"] !== undefined
        ? [
            {
              columns: [
                { text: "Valuable:", style: "fieldKeyBolder", width: 120 },
                { text: data["valuable"] ? "Yes" : "No", style: "fieldValue", width: "auto" },
                {
                  text: data["valuable_detail"] ? data["valuable_detail"].join(", ") : "",
                  style: "fieldValue",
                  width: "auto",
                },
              ],
              columnGap: 5,
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    const implantCol: ContentColumns[] =
      data["implant"] !== undefined
        ? [
            {
              columns: [
                { text: "Implant:", style: "fieldKeyBolder", width: 120 },
                { text: data["implant"] ? "Yes" : "No", style: "fieldValue", width: "auto" },
                { text: data["implant_detail"] ?? "", style: "fieldValue", width: "auto" },
              ],
              columnGap: 5,
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    const oxygenCol: ContentColumns[] =
      data["oxygen"] !== undefined
        ? [
            {
              columns: [
                { text: "Oxygen:", style: "fieldKeyBolder", width: 120 },
                {
                  text: data["oxygen"] ? `${data["oxygen"]} L/min` : "",
                  style: "fieldValue",
                  width: "auto",
                },
              ],
              columnGap: 5,
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    const medicationItemCol: ContentColumns[] =
      data["medication_item"] !== undefined
        ? [
            {
              columns: [
                { text: "Medication:", style: "fieldKeyBolder", width: 70 },
                {
                  stack: data["medication_item"].map((item: any) => ({
                    columns: [
                      { text: `${item.medicine_name || "-"}`, style: "fieldValue", width: "auto" },
                      {
                        columns: [
                          { text: "Route:", style: "fieldKeyBolder", width: "auto" },
                          { text: `${item.route || "-"}`, style: "fieldValue", width: "auto" },
                        ],
                        columnGap: 5,
                        width: "auto",
                      },
                      {
                        columns: [
                          { text: "Time:", style: "fieldKeyBolder", width: "auto" },
                          { text: `${item.time || "-"}`, style: "fieldValue", width: "auto" },
                        ],
                        columnGap: 5,
                        width: "auto",
                      },
                      {
                        columns: [
                          { text: "Given by:", style: "fieldKeyBolder", width: "auto" },
                          { text: `${item.codeText || "-"}`, style: "fieldValue", width: "auto" },
                        ],
                        columnGap: 5,
                        width: "auto",
                      },
                    ],
                    columnGap: 10,
                    margin: [0, 2, 0, 2],
                  })),
                },
              ],
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    const medicationOtherCol: ContentColumns[] =
      data["medication_other_item"] !== undefined
        ? [
            {
              columns: [
                { text: "Other:", style: "fieldKeyBolder", width: 70 },
                {
                  stack: data["medication_other_item"].map((item: any) => ({
                    columns: [
                      { text: `${item.medicine_name || "-"}`, style: "fieldValue", width: "auto" },
                      {
                        columns: [
                          { text: "Route:", style: "fieldKeyBolder", width: "auto" },
                          { text: `${item.route || "-"}`, style: "fieldValue", width: "auto" },
                        ],
                        columnGap: 5,
                        width: "auto",
                      },
                      {
                        columns: [
                          { text: "Time:", style: "fieldKeyBolder", width: "auto" },
                          { text: `${item.time || "-"}`, style: "fieldValue", width: "auto" },
                        ],
                        columnGap: 5,
                        width: "auto",
                      },
                      {
                        columns: [
                          { text: "Given by:", style: "fieldKeyBolder", width: "auto" },
                          { text: `${item.codeText || "-"}`, style: "fieldValue", width: "auto" },
                        ],
                        columnGap: 5,
                        width: "auto",
                      },
                    ],
                    columnGap: 10,
                    margin: [0, 2, 0, 2],
                  })),
                },
              ],
              margin: [8, 0, 0, 0],
            },
          ]
        : [];

    return {
      stack: [
        patientIdentificationCol,
        ...genContentColumns(HOLDING_ROOM_ONE_KEY, data),
        ...procedureConfirmCol,
        ...markSiteConfirmCol,
        ...genContentColumns(HOLDING_ROOM_TWO_KEY, data),
        ...homeMedicationCol,
        ...valuableCol,
        ...implantCol,
        ...genContentColumns(HOLDING_ROOM_THREE_KEY, data),
        ...oxygenCol,
        ...medicationItemCol,
        ...medicationOtherCol,
      ],
    };
  };

  const genDataSection = (data: any, topicName: topicName): ContentTable => {
    let contentData: ContentStack = { stack: [] };

    switch (topicName) {
      case "Pre - OP Visit":
        contentData = genPreOPVisit(data);
        break;
      case "OR Registration Area":
        contentData = genOrRegisArea(data);
        break;
      case "Holding Room":
        contentData = genHoldingRoom(data);
        break;
      default:
        contentData = { stack: [] };
        break;
    }

    return {
      table: {
        widths: ["*"],
        headerRows: 1,
        body: [[{ text: topicName, style: "fieldKeyTopic" }], [contentData]],
      },
      layout: {
        hLineWidth: function (i: number, node: any): number {
          if (
            i === node.table.body.length &&
            ["Pre - OP Visit", "OR Registration Area"].includes(topicName)
          ) {
            return 1;
          }
          return 0;
        },
        vLineWidth: function (): number {
          return 0;
        },
        hLineStyle: function (i: number, node: any): LineStyle | null {
          if (i === node.table.body.length) {
            return { dash: { length: 3, space: 2 } };
          }
          return null;
        },
        paddingBottom: function (i: number, node: any): number {
          if (i === node.table.body.length - 1) {
            return 8;
          }
          return 0;
        },
      },
    };
  };

  return {
    defaultStyle: {
      font,
      fontSize: fontSizes[FONT_SIZE],
      lineHeight: lineHeights[1],
    },
    pageSize: "A4",
    ...headerForm,
    images: {
      ...images,
      ...FormSymbolImages,
    },
    styles: {
      ...styles,
      fieldKeyTopic: { bold: true, font: fonts.POPPINS_LM, fontSize: fontSizes[16] },
      fieldKey: { bold: true, font: fonts.POPPINS_LM, fontSize: fontSizes[13.25] },
      fieldKeyBolder: { bold: true, font: fonts.POPPINS, fontSize: fontSizes[13.25] },
      fieldNote: {
        font: fonts.POPPINS_LM,
        fontSize: fontSizes[12],
        italics: true,
      },
      fieldValue: { font: fonts.POPPINS_LM, fontSize: fontSizes[13.25] },
      miniField: { fontSize: fontSizes[12] },
    },
    content: [
      genDataSection(props.preOpVisitData, "Pre - OP Visit"),
      genDataSection(props.orRegisAreaData, "OR Registration Area"),
      genDataSection(props.holdingRoomData, "Holding Room"),
    ],
    footer: (currentPage, pageCount) => ({
      stack: [
        {
          marginTop: 7.5,
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  columns: [
                    {
                      text: [
                        "วันเวลาที่พิมพ์เอกสาร ",
                        { text: formatDatetime(currentDate, true) },
                        ` โดย ${props.userFullName}`,
                      ],
                    },
                    { alignment: "right", text: `หน้า ${currentPage}/${pageCount}` },
                  ],
                  margin: [15, 0, 15, 0],
                  style: "miniField",
                  border: [false, true, false, false],
                },
              ],
            ],
          },
          layout: {
            hLineColor: () => "#c0c0c0",
            hLineStyle: () => ({ dash: { length: 1, space: 1 } }),
            hLineWidth: () => 1,
            vLineWidth: () => 0,
          },
        },
      ],
    }),
  };
};

export default FormPreOperation;
