import React, { CSSProperties, useMemo, useState, useEffect } from "react";
// CSS
import { Modal, Label, Icon, TextArea, Form } from "semantic-ui-react";

// Config
import config from "../../../../config/config";
import { PacsGalleryDetailType } from "./sequence/ImagingResult";
import { useIntl } from "react-intl";

type ModalGalleryProps = {
  // data
  data: PacsGalleryDetailType | null;
  languageUX?: string;
  open: boolean;
  // callback
  onClose: () => any;
};

const styles = {
  cardList: {
    overflow: "hidden",
    width: "100%",
    display: "flex",
    padding: "0 20px 0 0",
  } as CSSProperties,
  boxCard: {
    height: "130px",
    width: "31%",
    margin: "0 10px",
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    padding: "5px",
  } as CSSProperties,
  boxIconAngle: {
    background: "#E0E0E0",
    display: "flex",
    alignItems: "center",
    fontSize: "2rem",
    justifyContent: "center",
    color: "#828282",
    cursor: "pointer",
  } as CSSProperties,
  slide: {
    display: "grid",
    gridTemplateColumns: "30px auto 30px",
    height: "130px",
    marginTop: "20px",
  } as CSSProperties,
  ribbon: {
    backgroundColor: "#5DBCD2",
    color: "white",
    padding: "8px",
  } as CSSProperties,
  detail: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  } as CSSProperties,
  previewImage: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    maxHeight: "360px",
  } as CSSProperties,
  disabled: {
    opacity: 0.5,
    cursor: "unset",
  },
};

const MAX_VIEW_CARD = 3;

const ModalGallery: React.FunctionComponent<ModalGalleryProps> = (props) => {
  const [viewIndex, setViewIndex] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<
    PacsGalleryDetailType["items"][number] | null
  >(null);

  useEffect(() => {
    setSelectedImage(props.data?.items?.[0] || null);
  }, [props.data]);

  const subItems = useMemo(() => {
    const items = [...(props.data?.items || [])];
    return items.slice(viewIndex, MAX_VIEW_CARD + viewIndex);
  }, [viewIndex, props.data]);

  const handleClose = () => {
    setSelectedImage(null);
    setViewIndex(0);

    props.onClose();
  };

  let selectedImageLink = selectedImage?.image;

  if (!selectedImage?.image.includes("http")) {
    selectedImageLink = config.API_HOST + selectedImage?.image;
  }

  return (
    <div>
      <Modal
        open={props.open}
        closeOnDimmerClick={true}
        // callback
        onClose={handleClose}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 5px 0 0",
          }}
        >
          <div style={styles.ribbon}>PACS Gallery</div>
          <Icon size="large" name="close" link={true} onClick={handleClose} />
        </div>
        <div style={{ padding: "1rem 1.5rem 1.5rem" }}>
          <div style={styles.detail}>
            <div style={{ display: "flex" }}>
              <strong>ACCESSION NO. </strong>
              {props.data?.accessionId}
            </div>
            <div>
              <strong>Qty of Images:</strong> {props.data?.items?.length || "-"}
            </div>
          </div>
          <div
            style={{
              padding: "1.5rem 0px 0",
              fontWeight: "bold",
              marginBottom: "-0.5rem",
            }}
          >
            {props.data?.name}
          </div>
          {/* <br /> */}
          <div style={styles.slide}>
            <ButtonNextPrev
              type="backward"
              items={props.data?.items || []}
              subItems={subItems}
              viewIndex={viewIndex}
              // callback
              onViewChange={setViewIndex}
            />
            <div>
              {
                <div style={styles.cardList}>
                  {subItems.map((item: any) => {
                    let imageLnk = item?.image;

                    if (!item?.image.includes("http")) {
                      imageLnk = config.API_HOST + item?.image;
                    }

                    return (
                      <div
                        key={item.id}
                        style={{
                          ...styles.boxCard,
                          backgroundColor:
                            item.id === selectedImage?.id
                              ? "#5DBCD2"
                              : "#E0E0E0",
                        }}
                        onClick={() => setSelectedImage(item)}
                      >
                        <img
                          src={`${imageLnk}`}
                          alt="imaging x-ray"
                          style={{
                            height: "100%",
                            objectFit: "cover",
                            width: "100%",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              }
            </div>

            <ButtonNextPrev
              type="forward"
              items={props.data?.items || []}
              subItems={subItems}
              viewIndex={viewIndex}
              // callback
              onViewChange={setViewIndex}
            />
          </div>

          <div style={{ marginTop: "15px" }}>
            {selectedImage?.image && (
              <img
                src={`${selectedImageLink}`}
                alt="preview.image"
                style={styles.previewImage}
              />
            )}
          </div>
          {/* Description */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0 5px",
            }}
          >
            {selectedImage?.description}
          </div>
        </div>
      </Modal>
    </div>
  );
};
/* ------------------------------------------------------ */

/*                     ButtonNextPrev                     */

/* ------------------------------------------------------ */
type ButtonNextPrevProps = {
  type: "forward" | "backward";
  items: PacsGalleryDetailType["items"];
  subItems: PacsGalleryDetailType["items"];
  viewIndex: number;
  // callback
  onViewChange?: (view: number) => any;
};

const ButtonNextPrev = (props: ButtonNextPrevProps) => {
  const disabledBackward = useMemo(() => {
    return (
      props.type === "backward" && props.subItems[0]?.id === props.items[0]?.id
    );
  }, [props.subItems, props.items, props.type]);

  const disabledForward = useMemo(() => {
    return (
      props.type === "forward" &&
      props.subItems.slice(-1)[0]?.id === props.items.slice(-1)[0]?.id
    );
  }, [props.subItems, props.items, props.type]);

  const handleChangeStep = () => {
    if (disabledBackward || disabledForward) {
      return;
    }

    let index =
      props.type === "backward" ? +props.viewIndex - 1 : +props.viewIndex + 1;

    const last = index === (props.items?.length || 0) - MAX_VIEW_CARD + 1;

    index = index < 0 ? 0 : last ? index - 1 : index;

    props.onViewChange?.(index);
  };

  return (
    <div
      style={{
        ...styles.boxIconAngle,
        ...(disabledBackward ? styles.disabled : {}),
        ...(disabledForward ? styles.disabled : {}),
      }}
      onClick={handleChangeStep}
    >
      <Icon name={props.type === "backward" ? "angle left" : "angle right"} />
    </div>
  );
};

export default React.memo(ModalGallery);
