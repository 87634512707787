import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../common/ErrorMessage';
import CardLayout from '../common/CardLayout';
import ReactTable from "react-table-6";
import Dropzone from 'react-dropzone';
import {
  Form,
  Button,
  Grid
} from 'semantic-ui-react';
import _ from 'lodash';

const uploadStyle = {
  width: '100%',
  height: '100%',
  border: '3px dashed #b2b2c8',
  backgroundColor: '#fff',
  borderRadius: '5px',
  cursor: 'pointer',
  textAlign: 'center',
};

const uploadHeaderStyle = {
  color: '#474646',
  paddingTop: '5%',
};

const uploadWarningStyle = {
  color: 'darkred',
};

const alignRightButton = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '1em'
};

class CardUploadSentClaimResponseFile extends React.Component {
  constructor(props) {
    super(props);
    this.gridColumns = [
      {
        Header: 'Invoice No',
        accessor: 'invoice_no',
        width: 200,
      },
      {
        Header: 'สถานะ',
        accessor: 'formatted_success',
        width: 200,
      },
      {
        Header: 'เหตุผลในการติด C',
        accessor: 'formatted_reason',
      }
    ];
  }

  handleGetTrProps = (state, rowInfo, column, instance) => {
    const options = {};
    if (rowInfo && !_.get(rowInfo, 'original.success')) {
      options.style = {
        background: 'rgb(255, 197, 197)',
        color: 'black',
      };
    }
    return options;
  };

  handleGetTdProps = (state, rowInfo, column, instance) => {
    return {
      style: {
        whiteSpace: 'pre-line'
      }
    };
  };

  render() {
    const {
      items,
      isLoading,
      error,
      hospitalCode,
      sentNo,
      responseNo,
      responseAt,
      onUploadFile,
      onConfirmItems,
      coverageGroupName,
    } = this.props;

    return (
      <CardLayout
        titleText='บันทึกผลการส่งเบิก'
        headerColor='green'
        loading={isLoading}
        closeable={false}
      >
        <Form>
          <ErrorMessage error={error} />
          <Form.Group inline>
            <Form.Field width={2}>
              <label>สิทธิ</label>
            </Form.Field>
            <Form.Input
              width={3}
              value={coverageGroupName}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Field width={2}>
              <label>รหัสโรงพยาบาล</label>
            </Form.Field>
            <Form.Input
              width={3}
              value={hospitalCode}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Field width={2}>
              <label>เลขที่ตอบรับ</label>
            </Form.Field>
            <Form.Input
              width={3}
              value={responseNo}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Field width={2}>
              <label>วันที่ออกเลขตอบรับ</label>
            </Form.Field>
            <Form.Input
              inline
              width={3}
              value={responseAt}
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Field width={2}>
              <label>งวดส่ง</label>
            </Form.Field>
            <Form.Input
              inline
              width={14}
              value={sentNo}
            />
          </Form.Group>
        </Form>
        <br />
        <Grid>
          <Grid.Column width={3}>
            <Dropzone
              multiple={false}
              style={uploadStyle}
              accept='.BIL'
              onDrop={onUploadFile}
            >
              <h3 style={uploadHeaderStyle}> อัพโหลดไฟล์ </h3>
              <p> คลิกเพื่อเลือกไฟล์ หรือลากไฟล์มาวาง </p>
              <p style={uploadWarningStyle}> รองรับเฉพาะนามสกุล .BIL </p>
            </Dropzone>
          </Grid.Column>
          <Grid.Column width={13}>
            <ReactTable
              className='-striped -highlight'
              defaultPageSize={10}
              loading={isLoading}
              data={items}
              columns={this.gridColumns}
              getTrProps={this.handleGetTrProps}
              getTdProps={this.handleGetTdProps}
            />
            <div style={alignRightButton}>
              <Button
                color='green'
                onClick={onConfirmItems}
              >
                ยืนยัน
              </Button>
            </div>
          </Grid.Column>
        </Grid>
      </CardLayout>
    )
  }
}

export default CardUploadSentClaimResponseFile;
