import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  FormGroup,
  FormField,
  Input,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const ModSelectQuarterUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          
          <div
            style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0"}}>
            รายงานอุบัติการณ์ความเสี่ยงและความคลาดเคลื่อนทางยา (Feedback Report)
          </div>
          <div
            style={{cursor: "pointer"}}>
            
            <Icon
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <div
              className="field"
              style={{flex:1}}>
              
            </div>
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "normal"}}>
                ไตรมาสที่
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                name="quarter"
                onChange={props.onChange}
                style={{width: "80px"}}
                type="number"
                value={props.quarterDetail?.quarter || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "normal"}}>
                /
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                name="year"
                onChange={props.onChange}
                style={{width: "150px"}}
                type="number"
                value={props.quarterDetail?.year || ""}>
              </Input>
            </FormField>
            <div
              className="field"
              style={{flex:1}}>
              
            </div>
          </FormGroup>
          <FormGroup
            inline={true}>
            <div
              className="field"
              style={{flex:1}}>
              
            </div>
            <FormField>
              <label
                style={{fontWeight: "normal"}}>
                ระหว่าง
              </label>
            </FormField>
            <FormField>
              <DateTextBox
                onChange={(date)=>props.onChange(null,{value: date, name: "startDate"})}
                value={props.quarterDetail?.startDate || ""}>
              </DateTextBox>
            </FormField>
            <FormField>
              <label
                style={{fontWeight: "normal"}}>
                ถึง
              </label>
            </FormField>
            <FormField>
              <DateTextBox
                onChange={(date)=>props.onChange(null,{value: date, name: "endDate"})}
                value={props.quarterDetail?.endDate || ""}>
              </DateTextBox>
            </FormField>
            <div
              className="field"
              style={{flex:1}}>
              
            </div>
          </FormGroup>
        </div>
        <div
          style={{display: "flex", justifyContent: "center"}}>
          
          <Button
            basic={true}
            color="green"
            disabled={!props.quarterDetail?.quarter || !props.quarterDetail?.year || !props.quarterDetail?.startDate || !props.quarterDetail?.endDate}
            onClick={props.onSave}>
            ตกลง
          </Button>
        </div>
      </div>
    )
}

export default React.memo(ModSelectQuarterUX)

export const screenPropsDefault = {}

/* Date Time : Thu Mar 30 2023 09:27:59 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานอุบัติการณ์ความเสี่ยงและความคลาดเคลื่อนทางยา (Feedback Report)"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Icon",
      "parent": 4,
      "props": {
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ไตรมาสที่"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Input",
      "parent": 11,
      "props": {
        "name": {
          "type": "value",
          "value": "quarter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "style": {
          "type": "code",
          "value": "{width: \"80px\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.quarterDetail?.quarter || \"\""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "/"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Input",
      "parent": 13,
      "props": {
        "name": {
          "type": "value",
          "value": "year"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.quarterDetail?.year || \"\""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 18,
      "props": {
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 18,
      "props": {
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 18,
      "props": {
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 18,
      "props": {
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "ระหว่าง"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 26,
      "name": "DateTextBox",
      "parent": 22,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date)=>props.onChange(null,{value: date, name: \"startDate\"})"
        },
        "value": {
          "type": "code",
          "value": "props.quarterDetail?.startDate || \"\""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 28,
      "name": "DateTextBox",
      "parent": 24,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date)=>props.onChange(null,{value: date, name: \"endDate\"})"
        },
        "value": {
          "type": "code",
          "value": "props.quarterDetail?.endDate || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Button",
      "parent": 29,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "ตกลง"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "!props.quarterDetail?.quarter || !props.quarterDetail?.year || !props.quarterDetail?.startDate || !props.quarterDetail?.endDate"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 30,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "ModSelectQuarterUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
