import React from 'react'
import {
   Button,
   Checkbox,
   Input,
} from 'semantic-ui-react'



import styles from './POPhysicalexaminationOrthoticFittingandCheckout2LOW.module.css'

export interface POPhysicalexaminationOrthoticFittingandCheckout2LOWViewDataType {
    PoPhysicalOrthoFittingCheckout2Low3: string,
    PoPhysicalOrthoFittingCheckout2Low3Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low4: boolean,
    PoPhysicalOrthoFittingCheckout2Low4Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low6: string,
    PoPhysicalOrthoFittingCheckout2Low6Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low7: boolean,
    PoPhysicalOrthoFittingCheckout2Low7Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low9: boolean,
    PoPhysicalOrthoFittingCheckout2Low9Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low12: string,
    PoPhysicalOrthoFittingCheckout2Low12Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low13: boolean,
    PoPhysicalOrthoFittingCheckout2Low13Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low14: boolean,
    PoPhysicalOrthoFittingCheckout2Low14Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low15: string,
    PoPhysicalOrthoFittingCheckout2Low15Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low16: boolean,
    PoPhysicalOrthoFittingCheckout2Low16Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low18: string,
    PoPhysicalOrthoFittingCheckout2Low18Disabled: boolean,
    SaveRequestDisabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low21: boolean,
    PoPhysicalOrthoFittingCheckout2Low21Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low24: string,
    PoPhysicalOrthoFittingCheckout2Low24Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low25: boolean,
    PoPhysicalOrthoFittingCheckout2Low25Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low26: string,
    PoPhysicalOrthoFittingCheckout2Low26Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low27: boolean,
    PoPhysicalOrthoFittingCheckout2Low27Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low29: boolean,
    PoPhysicalOrthoFittingCheckout2Low29Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low32: string,
    PoPhysicalOrthoFittingCheckout2Low32Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low33: boolean,
    PoPhysicalOrthoFittingCheckout2Low33Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low35: string,
    PoPhysicalOrthoFittingCheckout2Low35Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low36: boolean,
    PoPhysicalOrthoFittingCheckout2Low36Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low37: string,
    PoPhysicalOrthoFittingCheckout2Low37Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low38: boolean,
    PoPhysicalOrthoFittingCheckout2Low38Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low40: boolean,
    PoPhysicalOrthoFittingCheckout2Low40Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low43: string,
    PoPhysicalOrthoFittingCheckout2Low43Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low45: boolean,
    PoPhysicalOrthoFittingCheckout2Low45Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low48: string,
    PoPhysicalOrthoFittingCheckout2Low48Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low50: boolean,
    PoPhysicalOrthoFittingCheckout2Low50Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low53: string,
    PoPhysicalOrthoFittingCheckout2Low53Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Low55: string,
    PoPhysicalOrthoFittingCheckout2Low55Disabled: boolean,
}

export const POPhysicalexaminationOrthoticFittingandCheckout2LOWInitialViewData: POPhysicalexaminationOrthoticFittingandCheckout2LOWViewDataType = {
    PoPhysicalOrthoFittingCheckout2Low3: "",
    PoPhysicalOrthoFittingCheckout2Low3Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low4: false,
    PoPhysicalOrthoFittingCheckout2Low4Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low6: "",
    PoPhysicalOrthoFittingCheckout2Low6Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low7: false,
    PoPhysicalOrthoFittingCheckout2Low7Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low9: false,
    PoPhysicalOrthoFittingCheckout2Low9Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low12: "",
    PoPhysicalOrthoFittingCheckout2Low12Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low13: false,
    PoPhysicalOrthoFittingCheckout2Low13Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low14: false,
    PoPhysicalOrthoFittingCheckout2Low14Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low15: "",
    PoPhysicalOrthoFittingCheckout2Low15Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low16: false,
    PoPhysicalOrthoFittingCheckout2Low16Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low18: "",
    PoPhysicalOrthoFittingCheckout2Low18Disabled: false,
    SaveRequestDisabled: false,
    PoPhysicalOrthoFittingCheckout2Low21: false,
    PoPhysicalOrthoFittingCheckout2Low21Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low24: "",
    PoPhysicalOrthoFittingCheckout2Low24Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low25: false,
    PoPhysicalOrthoFittingCheckout2Low25Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low26: "",
    PoPhysicalOrthoFittingCheckout2Low26Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low27: false,
    PoPhysicalOrthoFittingCheckout2Low27Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low29: false,
    PoPhysicalOrthoFittingCheckout2Low29Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low32: "",
    PoPhysicalOrthoFittingCheckout2Low32Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low33: false,
    PoPhysicalOrthoFittingCheckout2Low33Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low35: "",
    PoPhysicalOrthoFittingCheckout2Low35Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low36: false,
    PoPhysicalOrthoFittingCheckout2Low36Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low37: "",
    PoPhysicalOrthoFittingCheckout2Low37Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low38: false,
    PoPhysicalOrthoFittingCheckout2Low38Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low40: false,
    PoPhysicalOrthoFittingCheckout2Low40Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low43: "",
    PoPhysicalOrthoFittingCheckout2Low43Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low45: false,
    PoPhysicalOrthoFittingCheckout2Low45Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low48: "",
    PoPhysicalOrthoFittingCheckout2Low48Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low50: false,
    PoPhysicalOrthoFittingCheckout2Low50Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low53: "",
    PoPhysicalOrthoFittingCheckout2Low53Disabled: false,
    PoPhysicalOrthoFittingCheckout2Low55: "",
    PoPhysicalOrthoFittingCheckout2Low55Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low2}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low3}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low3}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low3Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low3',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low4}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low4}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low4Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low4"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Device</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low6}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low6}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low6Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low6',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low7}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low8}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Foot Orthosis</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low9}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low9}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low9Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low9"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low10}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Insole</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Rigid(UCBL)</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low12}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low12}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low12Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low12',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low13}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low13}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low13Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low13"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low14}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low14}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low14Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low14"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low15}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low15}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low15Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low15',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low16}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low16}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low16Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low16"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Ankle foot orthosis</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low18}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low18}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low18Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low18',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{width: "100%"}}
                >
                    Save
                </Button>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Rigid</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low21}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low21}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low21Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low21"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Articulated</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low24}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low24}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low24Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low24',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low25}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low26}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low26}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low26Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low26',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low27}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low27}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low27"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Shoe</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low29}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low30}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>modification</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Orthopedic shoe</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low32}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low32}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low32Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low32',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low33}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low33}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low33Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low33"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low34}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Knee orthosis</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low35}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low35}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low35Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low35',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low36}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low36}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low36Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low36"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low37}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low37}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low37Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low37',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low38}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low38}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low38Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low38"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Spinal Orthosis</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low40}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low40}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low40Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low40"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Prefabricate (modify)</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low42}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Custom made</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low43}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low43}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low43Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low43',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low44}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Gait pattern without device</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low45}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low45}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low45Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low45"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low46}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>No gait deviation</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low48}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low48}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low48Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low48',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low49}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Gait pattern with device</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low50}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Low50}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low50Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2LOW",
                                name: "PoPhysicalOrthoFittingCheckout2Low50"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low51}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>No gait deviation</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low53}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low53}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low53Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low53',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low54}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Device quality</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low55}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Low55}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Low55Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2LOW',
                            name: 'PoPhysicalOrthoFittingCheckout2Low55',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Low56}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Other</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
