import React, { useEffect, useState, useMemo } from "react";
// ADM
import { MenuItem } from "react-lib/apps/HISV3/ADM/CardADM";
import CONFIG from "config/config";
import { useIntl } from "react-intl";

const HOST = `${CONFIG.API_HOST}`;

const CARD_KEY = "CardWithDrawalRules";

const CardWithDrawalRules = (props: any) => {
  const intl = useIntl();
  const [mode, setMode] = useState("Claim เวชภัณฑ์");

  return (
    <div style={{ position: "relative", padding: "1em" }}>
      <div style={{ display: "flex" }}>
        <label style={{ fontWeight: "bold", fontSize: "large" }}>{intl.formatMessage({ id: "ตั้งค่ากฏการเบิก" })}</label>
      </div>
      <hr />
      <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <MenuItem menu={intl.formatMessage({ id: "Claim เวชภัณฑ์" })} mode={mode} name={intl.formatMessage({ id: "Claim เวชภัณฑ์" })} setMode={setMode} />
        <MenuItem menu={intl.formatMessage({ id: "Claim ยา" })} mode={mode} name={intl.formatMessage({ id: "Claim ยา" })} setMode={setMode} />
        <MenuItem menu={intl.formatMessage({ id: "Claim บริการ" })} mode={mode} name={intl.formatMessage({ id: "Claim บริการ" })} setMode={setMode} />
        <MenuItem menu="Claim common" mode={mode} name="Claim common" setMode={setMode} />
      </div>

      {mode === "Claim เวชภัณฑ์" ? (
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <a href={`${HOST}/manage/CLM/supplyclaimpattern/`} target="_blank">
            S.1 กำหนดรูปแบบการเคลมเวชภัณฑ์
          </a>
          <a href={`${HOST}/manage/CLM/coveragesupplyclaimpattern/`} target="_blank">
            S.2 ผูกสิทธิกับรูปแบบการเคลมเวชภัณฑ์
          </a>
          <a href={`${HOST}/manage/CLM/question/`} target="_blank">
            S.3 กำหนดคำถาม
          </a>
          <a href={`${HOST}/CLM/ModConditionList.qml?for_claim_type=1`} target="_blank">
            S.4 กำหนดเงื่อนไขรหัสเบิกเวชภัณฑ์
          </a>
          <a href={`${HOST}/manage/CLM/claimsupply/`} target="_blank">
            S.5 กำหนดรหัสเบิกเวชภัณฑ์
          </a>
          <a href={`${HOST}/manage/MSD/supplyforclaim/`} target="_blank">
            S.6 กำหนดหมวดเวชภัณฑ์
          </a>
          <a href={`${HOST}/CLM/CardSupplyClaimSupply.qml`} target="_blank">
            S.7 ผูกรหัสเบิกกับเวชภัณฑ์
          </a>
          <a href={`${HOST}/manage/CLM/maxreimbclaimsupplygroup/`} target="_blank">
            S.8 กำหนดเพดานเบิกแบบหลายรหัสเบิกไม่เกินเพดานที่กำหนด
          </a>
          <a href={`${HOST}/manage/CLM/icd9cmbeauty/`} target="_blank">
            S.11 กำหนดรายการ Icd9cm เพื่อความสวยงาม
          </a>
          <a href={`${HOST}/manage/CLM/overridesupplymode/`} target="_blank">
            S.13 เปลี่ยนหมวดเวชภัณฑ์เมื่อสั่งด้วยสิทธิที่ระบุ
          </a>
        </div>
      ) : mode === "Claim ยา" ? (
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <a href={`${HOST}/manage/CLM/drugclaimpattern/`} target="_blank">
            D.1 กำหนดรูปแบบการเคลมยา
          </a>
          <a href={`${HOST}/manage/CLM/coveragedrugclaimpattern/`} target="_blank">
            D.2 ผูกสิทธิกับรูปแบบการเคลมยา
          </a>
          <a href={`${HOST}/manage/CLM/question/`} target="_blank">
            D.3 กำหนดคำถาม
          </a>
          <a href={`${HOST}/manage/core/doctorgroup/`} target="_blank">
            D.4 กำหนดกลุ่มแพทย์
          </a>
          <a href={`${HOST}/CLM/ModConditionList.qml?for_claim_type=2`} target="_blank">
            D.5 กำหนดเงื่อนไขการเบิกยา
          </a>
          <a href={`${HOST}/CLM/CardDrugClaimPatternItemList.qml`} target="_blank">
            D.6 กำหนดรายละเอียดการเบิกยา
          </a>
          <a href={`${HOST}/manage/CLM/coveragedrugclaimpatternforclaimdrug/`} target="_blank">
            D.7 ผูกสิทธิกับรูปแบบการเคลมยา สำหรับเพดานเบิกยา
          </a>
          <a href={`${HOST}/manage/CLM/claimdrug/`} target="_blank">
            D.8 กำหนดเพดานเบิกยา
          </a>
        </div>
      ) : mode === "Claim บริการ" ? (
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <a href={`${HOST}/manage/CLM/otherclaimpattern/`} target="_blank">
            O.1 กำหนดรูปแบบการเคลมบริการ
          </a>
          <a href={`${HOST}/manage/CLM/coverageotherclaimpattern/`} target="_blank">
            O.2 ผูกสิทธิกับรูปแบบการเคลมบริการ
          </a>
          <a href={`${HOST}/CLM/ModConditionList.qml?for_claim_type=3`} target="_blank">
            O.3 กำหนดเงื่อนไขรหัสเบิกบริการ
          </a>
          <a href={`${HOST}/manage/CLM/claimother/`} target="_blank">
            O.4 กำหนดรหัสเบิกบริการ
          </a>
          <a href={`${HOST}/CLM/CardOtherClaimOther.qml`} target="_blank">
            O.5 ผูกรหัสเบิกกับบริการ
          </a>
        </div>
      ) : (
        mode === "Claim common" && (
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <a href={`${HOST}/manage/CLM/selfreimbproduct/`} target="_blank">
              C.1 กำหนดรายการสินค้าที่นำไปตั้งเบิกเอง
            </a>
            <a href={`${HOST}/manage/CLM/notreimbproduct/`} target="_blank">
              C.2 กำหนดรายการสินค้าห้ามเบิก
            </a>
            <a href={`${HOST}/manage/CLM/notreimbicd10/`} target="_blank">
              C.3 กำหนดรายการ ICD10 ห้ามเบิก
            </a>
            <a href={`${HOST}/manage/CLM/coveragesetting/`} target="_blank">
              C.4 ตั้งค่าสิทธิ
            </a>
            <a href={`${HOST}/manage/CLM/coverageabsorb/`} target="_blank">
              C.5 ตั้งค่า Absorb (โรงพยาบาลรองรับค่าใช้จ่าย)
            </a>
          </div>
        )
      )}
    </div>
  );
};

export default CardWithDrawalRules;
