import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const ModCertificateErrorUX = (props: any) => {
    return(
      <div>
        <div
          style={{ backgroundColor: "#db2828", color: "white", fontSize: "1.2em", padding: "8px", fontWeight: "bold"}}>
          แจ้งเตือน
        </div>
        <div
          style={{padding: "10px"}}>
          กรุณาระบุเลข ว แพทย์ให้ถูกต้อง
        </div>
        <div
          style={{textAlign: "center", paddingBottom: "20px"}}>
          
          <Button
            color="red"
            onClick={props.onConfirm}
            size="tiny">
            ตกลง
          </Button>
        </div>
      </div>
    )
}

export default ModCertificateErrorUX

export const screenPropsDefault = {}

/* Date Time : Thu Jun 02 2022 02:09:35 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "แจ้งเตือน"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#db2828\", color: \"white\", fontSize: \"1.2em\", padding: \"8px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาระบุเลข ว แพทย์ให้ถูกต้อง"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", paddingBottom: \"20px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ตกลง"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirm"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 4,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 20,
  "isMounted": false,
  "memo": false,
  "name": "ModCertificateErrorUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 10
}

*********************************************************************************** */
