import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ModDownloadPreviousSendClaimFile from 'react-lib/component-js/hbill/ModDownloadPreviousSendClaimFile';
import BillService from 'react-lib/services/BillService';
import { useIntl } from "react-intl";

const INITIAL_STATE = {
  items: [],
  isLoading: false,
  summaryOpen: false,
  summaryHeader: '',
  totalRows: '',
  totalPrice: '',
  totalSentClaim: '',
  totalPaid: '',
  totalOtherPay: '',
  totalPriceBillItemMode_2: '',
  totalSentClaimPriceBillItemMode_2: '',
  totalPriceBillItemMode_3_5: '',
  totalSentClaimPriceBillItemMode_3_5: '',
  totalPriceBillItemModeRemain: '',
  totalSentClaimPriceBillItemModeRemain: '',
  totalPriceDispensing: '',
  totalSentClaimPriceDispensing: '',
  totalPriceOperationService: '',
  isCalculating: false
};

class ModDownloadPreviousSendClaimFileContainer extends React.PureComponent {
  static propTypes = {
    arTransactionId: PropTypes.number,
    header: PropTypes.string,
    open: PropTypes.bool,
    apiToken: PropTypes.string,
    modalStyle: PropTypes.object,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.billService = new BillService();
  }

  componentDidUpdate = async (prevProps) => {
    const { arTransactionId } = this.props;
    const { arTransactionId: prevArTransactionId } = prevProps;

    if (arTransactionId && prevArTransactionId !== arTransactionId) {
      this.setState({ isLoading: true });
      this.billService.getSendClaimTransactions(arTransactionId, this.props.apiToken)
        .then((data) => {
          this.setState({
            items: [...data.items],
            isLoading: false,
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
          toast.error('เกิดความผิดพลาด');
          this.props.onClose();
        });
    }
  }

  handleClose = () => {
    this.setState(INITIAL_STATE);
    this.props.onClose();
  }

  handleDownloadSendCalimFile = async (sendClaimTransactionId) => {
    this.setState({ isLoading: true });
    this.billService
      .downloadSentClaimFileFromSendClaimTransaction(
        sendClaimTransactionId,
        this.props.apiToken
      )
      .then(() => {
        this.setState({ isLoading: false });
        toast.success("Download สำเร็จ");
      })
      .catch(() => {
        this.setState({ isLoading: false });
        toast.error("เกิดความผิดพลาด");
      });
  }

  handleSummary = async (sentClaimTransactionId, sentNo) => {
    this.setState({ summaryOpen: true, isCalulating: true });
    this.billService
      .getBillTransactionSummary({
        sent_claim_transaction: sentClaimTransactionId,
        apiToken: this.props.apiToken,
      })
      .then((summaryResponse) => {
        this.setState({
          isLoading: false,
          isCalulating: false,
          summaryHeader: "งวดส่ง " + sentNo,
          totalRows: summaryResponse.total_rows,
          totalPrice: summaryResponse.total_price,
          totalSentClaim: summaryResponse.total_sent_claim_price,
          totalPaid: summaryResponse.total_paid,
          totalOtherPay: summaryResponse.total_other_pay,
          totalPriceBillItemMode_2:
            summaryResponse.total_price_bill_item_mode_2,
          totalSentClaimPriceBillItemMode_2:
            summaryResponse.total_sent_claim_price_bill_item_mode_2,
          totalPriceBillItemMode_3_5:
            summaryResponse.total_price_bill_item_mode_3_5,
          totalSentClaimPriceBillItemMode_3_5:
            summaryResponse.total_sent_claim_price_bill_item_mode_3_5,
          totalPriceBillItemModeRemain:
            summaryResponse.total_price_bill_item_mode_remain,
          totalSentClaimPriceBillItemModeRemain:
            summaryResponse.total_sent_claim_price_bill_item_mode_remain,
          totalPriceDispensing: summaryResponse.total_price_dispensing,
          totalSentClaimPriceDispensing:
            summaryResponse.total_sent_claim_price_dispensing,
          totalPriceOperationService:
            summaryResponse.total_price_operation_service,
        });
      })
      .catch((error) => {
        this.setState({ summaryOpen: false, isCalculating: false });
        console.log(error);
        toast.error("เกิดความผิดพลาด");
      });
  }

  handleSummaryClose = () => {
    this.setState({
      isLoading: false,
      summaryOpen: false,
      summaryHeader: '',
      totalRows: '',
      totalPrice: '',
      totalSentClaim: '',
      totalPaid: '',
      totalOtherPay: '',
      totalPriceBillItemMode_2: '',
      totalSentClaimPriceBillItemMode_2: '',
      totalPriceBillItemMode_3_5: '',
      totalSentClaimPriceBillItemMode_3_5: '',
      totalPriceBillItemModeRemain: '',
      totalSentClaimPriceBillItemModeRemain: '',
      totalPriceDispensing: '',
      totalSentClaimPriceDispensing: '',
      totalPriceOperationService: '',
    })
  }

  render() {
    return (
      <ModDownloadPreviousSendClaimFile
        {...this.state}
        open={this.props.open}
        header={this.props.header}
        onClose={this.handleClose}
        onDownloadSendCalimFile={this.handleDownloadSendCalimFile}
        onSummary={this.handleSummary}
        modalStyle={this.props.modalStyle}
        onSummaryClose={this.handleSummaryClose}
        languageUX={this.props.lanugageUX}
      />
    );
  }
}

export default ModDownloadPreviousSendClaimFileContainer;