import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './PTObjectiveOrthoTOP.module.css'

export interface PTObjectiveOrthoTOPViewDataType {
    PtObjectiveOrthoTop2: string,
    PtObjectiveOrthoTop2Disabled: boolean,
    Ct9Consciousness: string,
    Ct9ConsciousnessDisabled: boolean,
    Ct9ConsciousnessOptions: any,
    Ct12Communication: string,
    Ct12CommunicationDisabled: boolean,
    Ct12CommunicationOptions: any,
    Ct12CoOperation: string,
    Ct12CoOperationDisabled: boolean,
    Ct12CoOperationOptions: any,
    PtObjectiveOrthoTop17: string,
    PtObjectiveOrthoTop17Disabled: boolean,
    PtObjectiveOrthoTop19: string,
    PtObjectiveOrthoTop19Disabled: boolean,
    PtObjectiveOrthoTop20: string,
    PtObjectiveOrthoTop20Disabled: boolean,
    PtObjectiveOrthoTop21: boolean,
    PtObjectiveOrthoTop21Disabled: boolean,
    PtObjectiveOrthoTop23: string,
    PtObjectiveOrthoTop23Disabled: boolean,
    PtObjectiveOrthoTop24: boolean,
    PtObjectiveOrthoTop24Disabled: boolean,
    PtObjectiveOrthoTop25: string,
    PtObjectiveOrthoTop25Disabled: boolean,
    PtObjectiveOrthoTop27: boolean,
    PtObjectiveOrthoTop27Disabled: boolean,
    PtObjectiveOrthoTop29: boolean,
    PtObjectiveOrthoTop29Disabled: boolean,
    PtObjectiveOrthoTop31: boolean,
    PtObjectiveOrthoTop31Disabled: boolean,
    Ct4Sensory: string,
    Ct4SensoryDisabled: boolean,
    Ct4SensoryOptions: any,
    PtObjectiveOrthoTop35: string,
    PtObjectiveOrthoTop35Disabled: boolean,
    PtObjectiveOrthoTop38: string,
    PtObjectiveOrthoTop38Disabled: boolean,
    PtObjectiveOrthoTop39: boolean,
    PtObjectiveOrthoTop39Disabled: boolean,
    PtObjectiveOrthoTop40: string,
    PtObjectiveOrthoTop40Disabled: boolean,
    PtObjectiveOrthoTop42: boolean,
    PtObjectiveOrthoTop42Disabled: boolean,
    PtObjectiveOrthoTop43: string,
    PtObjectiveOrthoTop43Disabled: boolean,
    PtObjectiveOrthoTop45: boolean,
    PtObjectiveOrthoTop45Disabled: boolean,
    PtObjectiveOrthoTop47: boolean,
    PtObjectiveOrthoTop47Disabled: boolean,
}

export const PTObjectiveOrthoTOPInitialViewData: PTObjectiveOrthoTOPViewDataType = {
    PtObjectiveOrthoTop2: "",
    PtObjectiveOrthoTop2Disabled: false,
    Ct9Consciousness: "",
    Ct9ConsciousnessDisabled: false,
    Ct9ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct12Communication: "",
    Ct12CommunicationDisabled: false,
    Ct12CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct12CoOperation: "",
    Ct12CoOperationDisabled: false,
    Ct12CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveOrthoTop17: "",
    PtObjectiveOrthoTop17Disabled: false,
    PtObjectiveOrthoTop19: "",
    PtObjectiveOrthoTop19Disabled: false,
    PtObjectiveOrthoTop20: "",
    PtObjectiveOrthoTop20Disabled: false,
    PtObjectiveOrthoTop21: false,
    PtObjectiveOrthoTop21Disabled: false,
    PtObjectiveOrthoTop23: "",
    PtObjectiveOrthoTop23Disabled: false,
    PtObjectiveOrthoTop24: false,
    PtObjectiveOrthoTop24Disabled: false,
    PtObjectiveOrthoTop25: "",
    PtObjectiveOrthoTop25Disabled: false,
    PtObjectiveOrthoTop27: false,
    PtObjectiveOrthoTop27Disabled: false,
    PtObjectiveOrthoTop29: false,
    PtObjectiveOrthoTop29Disabled: false,
    PtObjectiveOrthoTop31: false,
    PtObjectiveOrthoTop31Disabled: false,
    Ct4Sensory: "",
    Ct4SensoryDisabled: false,
    Ct4SensoryOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveOrthoTop35: "",
    PtObjectiveOrthoTop35Disabled: false,
    PtObjectiveOrthoTop38: "",
    PtObjectiveOrthoTop38Disabled: false,
    PtObjectiveOrthoTop39: false,
    PtObjectiveOrthoTop39Disabled: false,
    PtObjectiveOrthoTop40: "",
    PtObjectiveOrthoTop40Disabled: false,
    PtObjectiveOrthoTop42: false,
    PtObjectiveOrthoTop42Disabled: false,
    PtObjectiveOrthoTop43: "",
    PtObjectiveOrthoTop43Disabled: false,
    PtObjectiveOrthoTop45: false,
    PtObjectiveOrthoTop45Disabled: false,
    PtObjectiveOrthoTop47: false,
    PtObjectiveOrthoTop47Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtObjectiveOrthoTop1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop2}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop2}
                    disabled={ props.viewData.PtObjectiveOrthoTop2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop2',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop4}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Objective</strong></p>
                </div>
            </div>
            <div className={styles.Ct9Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct9Consciousness}
                    disabled={ props.viewData.Ct9ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "Ct9Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct9ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct12Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct12Communication}
                    disabled={ props.viewData.Ct12CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "Ct12Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct12CommunicationOptions}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.Ct12CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct12CoOperation}
                    disabled={ props.viewData.Ct12CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "Ct12CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct12CoOperationOptions}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop17}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop17}
                    disabled={ props.viewData.PtObjectiveOrthoTop17Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop17',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Palpation</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop19}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop19}
                    disabled={ props.viewData.PtObjectiveOrthoTop19Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop19',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop20}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop20}
                    disabled={ props.viewData.PtObjectiveOrthoTop20Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop20',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop21}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveOrthoTop21}
                    disabled={ props.viewData.PtObjectiveOrthoTop21Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "PtObjectiveOrthoTop21"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle length</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop23}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop23}
                    disabled={ props.viewData.PtObjectiveOrthoTop23Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop23',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveOrthoTop24}
                    disabled={ props.viewData.PtObjectiveOrthoTop24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "PtObjectiveOrthoTop24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop25}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop25}
                    disabled={ props.viewData.PtObjectiveOrthoTop25Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop25',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop26}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop27}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveOrthoTop27}
                    disabled={ props.viewData.PtObjectiveOrthoTop27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "PtObjectiveOrthoTop27"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveOrthoTop29}
                    disabled={ props.viewData.PtObjectiveOrthoTop29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "PtObjectiveOrthoTop29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop30}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop31}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveOrthoTop31}
                    disabled={ props.viewData.PtObjectiveOrthoTop31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "PtObjectiveOrthoTop31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.Ct4Sensory}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct4Sensory}
                    disabled={ props.viewData.Ct4SensoryDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "Ct4Sensory",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4SensoryOptions}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop35}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop35}
                    disabled={ props.viewData.PtObjectiveOrthoTop35Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop35',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sensory</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop38}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop38}
                    disabled={ props.viewData.PtObjectiveOrthoTop38Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop38',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop39}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveOrthoTop39}
                    disabled={ props.viewData.PtObjectiveOrthoTop39Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "PtObjectiveOrthoTop39"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop40}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop40}
                    disabled={ props.viewData.PtObjectiveOrthoTop40Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop40',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle tone</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop42}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveOrthoTop42}
                    disabled={ props.viewData.PtObjectiveOrthoTop42Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "PtObjectiveOrthoTop42"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop43}>
                <Input
                    value={props.viewData.PtObjectiveOrthoTop43}
                    disabled={ props.viewData.PtObjectiveOrthoTop43Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveOrthoTOP',
                            name: 'PtObjectiveOrthoTop43',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveOrthoTop44}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop45}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveOrthoTop45}
                    disabled={ props.viewData.PtObjectiveOrthoTop45Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "PtObjectiveOrthoTop45"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop46}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spasticity</p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop47}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveOrthoTop47}
                    disabled={ props.viewData.PtObjectiveOrthoTop47Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveOrthoTOP",
                                name: "PtObjectiveOrthoTop47"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveOrthoTop48}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Flaccid</p>
                </div>
            </div>
            <div className={styles.PtObjectiveOrthoTop49}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
