import { TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";

import ComponyLogoForm from "../HISV3/common/CompanyLogoForm";

import { formatDate } from "react-lib/utils/dateUtils";

import CONFIG from "config/config";

const FORM_NAME = "FormChairBooking";

const FormChairBooking = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("FormChairBooking Props:", props);

  const companyLogoForm = await ComponyLogoForm({
    font: "THSarabunNew",
    form: FORM_NAME,
    height: 30,
  });
  const { font, images } = companyLogoForm;

  const generateTableBody = (data: any[]): any[][] => {
    const headers = [
      "ลำดับ",
      "เก้าอี้",
      "ชื่อนิสิต",
      "ระดับชั้นปี",
      "เวลา",
      "HN",
      "ชื่อผู้ป่วย",
      "เบอร์โทรติดต่อ",
      "งานที่ทำ",
    ];

    const headerRow = headers.map((header) => ({
      alignment: "center",
      bold: true,
      text: header,
    }));

    const rows = data.map((row, index) => {
      const appointment = row.service_appointment || {};

      const patientName =
        appointment.patient_pre_name &&
        appointment.patient_first_name &&
        appointment.patient_last_name
          ? `${appointment.patient_pre_name}${appointment.patient_first_name} ${appointment.patient_last_name}`
          : "";

      return [
        { text: (index + 1).toString() },
        { text: row.chair_name || "" },
        { text: row.doctor_provider_name || "" },
        { text: row.provider_object?.position?.name || "" },
        { text: row.period_time || "" },
        { text: appointment.patient_hn || "" },
        { text: patientName },
        { text: appointment.patient_tel || "" },
        { text: appointment.extra?.jobToDo || "" },
      ];
    });

    return [headerRow, ...rows];
  };

  const createTableReport = (data: any[]): TDocumentDefinitions["content"] => ({
    table: {
      headerRows: 1,
      widths: ["auto", "auto", "15%", "8%", "8%", 35, "15%", "auto", "*"],
      body: generateTableBody(data),
    },
  });

  return {
    defaultStyle: {
      font,
      lineHeight: 1,
      // fontSize: 12, // default of font size
    },
    images: {
      ...images,
    },
    pageMargins: [15, 100, 15, 15],
    pageOrientation: "landscape",
    // pageSizes: "A4",
    header: (currentPage, pageCount) => ({
      margin: [15, 15, 15, 0],
      table: {
        widths: ["39%", "43%", "18%"],
        body: [
          [
            {
              // ...companyLogoForm,
              border: [true, true, false, true],
              columns: [
                ...(CONFIG.HIDE_COMPANY_LOGO_PRINT
                  ? []
                  : [
                      {
                        height: 30,
                        image: "logo",
                        width: 80,
                      },
                    ]),
                {
                  stack: [
                    ...(CONFIG.HIDE_COMPANY_LOGO_PRINT
                      ? []
                      : [
                          {
                            bold: true,
                            fontSize: 12,
                            text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                          },
                          {
                            bold: true,
                            fontSize: 12,
                            text: "Chulalongkorn University Faculty of Dentistry Hospital",
                          },
                        ]),
                  ],
                },
              ],
            },
            {
              border: [false, true, false, true],
              stack: [
                {
                  alignment: "center",
                  bold: true,
                  fontSize: 15,
                  text: "รายงานการจองเก้าอี้",
                },
                {
                  alignment: "center",
                  bold: true,
                  fontSize: 15,
                  text: props.division_name,
                },
                {
                  alignment: "center",
                  fontSize: 15,
                  text: `${props.date} (${props.period})`,
                },
              ],
            },
            {
              border: [false, true, true, true],
              stack: [
                {
                  alignment: "right",
                  fontSize: 15,
                  text: `ผู้พิมพ์ : ${props.staff}`,
                },
                {
                  alignment: "right",
                  fontSize: 15,
                  text: `วันที่พิมพ์ : ${formatDate(moment())} [${moment().format("HH:mm")}]`,
                },
                {
                  alignment: "right",
                  fontSize: 15,
                  text: `หน้า ${currentPage.toString()}/${pageCount.toString()}`,
                },
              ],
            },
          ],
        ],
      },
      layout: {
        paddingBottom: () => 5,
        paddingLeft: () => 10,
        paddingRight: () => 10,
        paddingTop: () => 5,
      },
    }),
    content: [createTableReport(props.data || [])],
  };
};

export default FormChairBooking;
