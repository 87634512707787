import { ContentColumns, Margins, TDocumentDefinitions } from "pdfmake/interfaces";

//* cm. -> pt (1 cm = 28.3465 pt)
const PT_UNIT = 28.3465;

const formatEnounterNumber = (an: string | null) => {
  if (!an) {
    return "-";
  }
  if (an.length > 2) {
    return an.slice(0, 2) + "-" + an.slice(2);
  }
  return an;
};

const FormPatientStickerHnIpd = (props: any): TDocumentDefinitions => {
  console.log("Patient Sticker Props: ", props);

  const stickerPerRow = 3;
  const stickerWidth = 5.5 * PT_UNIT || 155.9;
  const stickerHeight = 1.8 * PT_UNIT || 51.0;
  const colGap = 0.7 * PT_UNIT || 19.8;
  const rowGap = 0.5 * PT_UNIT || 14.1;

  const pageMargins: Margins = [1.3 * PT_UNIT || 36.85, rowGap, 1.8 * PT_UNIT || 43.8, 0];

  const genSticker = (): ContentColumns => {
    const rows: any = [];
    for (let i = 0; i < 7; i++) {
      const cols: any = [];
      for (let j = 0; j < stickerPerRow; j++) {
        cols.push({
          table: {
            widths: [stickerWidth],
            heights: stickerHeight,
            body: [
              [
                {
                  stack: [
                    { text: props.items?.full_name_th || props.items?.full_name },
                    {
                      columns: [
                        { text: `HN: ${props.items?.hn}` },
                        { text: props.items?.division_name, alignment: "right" },
                      ],
                    },
                    {
                      columns: [
                        { text: `AN: ${formatEnounterNumber(props.items?.encounter_no) || "-"}` },
                        { text: `อายุ: ${props.items?.age} ปี`, alignment: "right" },
                      ],
                    },
                  ],
                  margin: [4, 2, 4, 0],
                },
              ],
            ],
          },
          layout: "noBorders",
          width: "auto",
          // margin: i !== 6 ? [0, 0, 0, rowGap] : [0, 0, 0, 0],
        });
      }
      rows.push({
        columns: cols,
        columnGap: colGap,
      });
    }

    return rows;
  };

  return {
    pageOrientation: "landscape",
    pageSize: "A5",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 12,
    },
    pageMargins: pageMargins,
    content: genSticker(),
  };
};

export default FormPatientStickerHnIpd;
