import React, {
  useEffect,
  useState,
  useMemo,
  KeyboardEvent,
  MutableRefObject,
  useRef,
} from "react";
import moment from "moment";
import CardDrugTransferRequestHistoryUX from "./CardDrugTransferRequestHistoryUX";
import { useIntl } from "react-intl";

export const CARD_DRUG_TRANSFER_REQUEST_HISTORY: string = "CardDrugTransferRequestHistory";

const CardDrugTransferRequestHistory = (props: any) => {

  useEffect(() => {
    props.runSequence({
      sequence: "DrugTransferRequestHistory",
      restart: true,
    });
    return () => {
      props.runSequence({
        sequence: "DrugTransferRequestHistory",
        clear: true,
      });
    };
  }, []);


  const handleChangeValue = (key: string) => (e: any, v: any) =>
    props.setProp(`DrugTransferRequestHistorySequence.${key}`, v.value);

  const handleChangeChecked =
    (key: string) =>
    (e: any, { checked }) => {
      props.setProp(`DrugTransferRequestHistorySequence.${key}`, checked);
    };

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(`DrugTransferRequestHistorySequence.${key}`, date);
  };

  const dataDrugTransferRequestHistoryList = useMemo(() => {
    return (props.DrugTransferRequestHistoryList?.items || []).map(
      (item: any, index: number) => ({
        ...item,
        code: item.code,
        listDrug: item.items
          .map(
            (acc: any) =>
              `${acc.code}(${acc.provide_quantity || 0}/${
                acc.request_quantity || 0
              })`
          )
          .join(", "),
        request: item.requested?.datetime
          ? moment(item.requested?.datetime).format("HH:mm")
          : null,
        approve: item.approved?.datetime
          ? moment(item.approved?.datetime).format("HH:mm")
          : null,
        delivery: item.delivered?.datetime
          ? moment(item.delivered?.datetime).format("HH:mm")
          : null,
        receive: item.received?.datetime
          ? moment(item.received?.datetime).format("HH:mm")
          : null,
        statusName: item.status_name,
      })
    );
  }, [props.DrugTransferRequestHistoryList?.items]);

  return (
    <>
      <CardDrugTransferRequestHistoryUX
        divisionPharmaOptions={props.masterOptions?.divisionPharma || []}
        selectedDivisionRequest={
          props?.DrugTransferRequestHistorySequence?.selectedDivisionRequest ||
          ""
        }
        selectedDivisionProvider={
          props?.DrugTransferRequestHistorySequence?.selectedDivisionProvider ||
          ""
        }
        selectedStartDate={
          props?.DrugTransferRequestHistorySequence?.selectedStartDate || ""
        }
        selectedEndDate={
          props?.DrugTransferRequestHistorySequence?.selectedEndDate || ""
        }
        selectedCode={
          props?.DrugTransferRequestHistorySequence?.selectedCode || ""
        }
        checkedCode={props?.DrugTransferRequestHistorySequence?.checkedCode}
        checkedDivisionProvider={
          props?.DrugTransferRequestHistorySequence?.checkedDivisionProvider
        }
        checkedDivisionRequest={
          props?.DrugTransferRequestHistorySequence?.checkedDivisionRequest
        }
        checkedFilterDate={
          props?.DrugTransferRequestHistorySequence?.checkedFilterDate
        }
        dataDrugTransferRequestHistoryList={dataDrugTransferRequestHistoryList}
        //function
        handleChangeValue={handleChangeValue}
        handleChangeChecked={handleChangeChecked}
        handleChangeDate={handleChangeDate}
        OnSearch={() =>
          props.runSequence({
            sequence: "DrugTransferRequestHistory",
            action: "search",
          })
        }
        OnClear={() =>
          props.runSequence({
            sequence: "DrugTransferRequestHistory",
            action: "clear",
          })
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

export default CardDrugTransferRequestHistory;
