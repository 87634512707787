import { TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";

// Common
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import { splitStringNewLine } from "react-lib/apps/HISV3/common/CommonInterface";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

type FormStickerFoodProps = {
  id: number;
  birthdate: string;
  detail: string;
  detail_label: string;
  food_type_label: string;
  food_type_name: string;
  food_type_type: string;
  full_age: string;
  general_time: string[];
  hn: string;
  intensity: string;
  meal_time: string;
  mixture: string;
  mixture_volume: string;
  nutrition_control_detail: string;
  nutrition_control_detail_label: string;
  nutrition_control_remark: string;
  patient_fullname: string;
  printUser: string;
  regularMeal: string;
  religion: string;
  remark: string;
  room: string;
  routine_size: string;
  routine_time: any[];
  special_diet_detail: string;
  special_diet_label: string;
  special_diet_remark: string;
  times: string[];
  ward_name: string;
};

/**
 * Extracts the content from the given string that matches the pattern.
 * Specifically, it looks for text between the specified keyword and the next 'b>' tag, and then extracts content before '<br'.
 */

const extractContent = (detailLabel: string, keyword: string) => {
  // <b>แพ้:</b> อาหารทะเล (ทั้งหมด)<br/><b>เสริม:</b> Low Residual,Low Bacterial<br/><b>หมายเหตุ : </b>
  const regex = new RegExp(`${keyword}.*?b>(.*?)(<br|$)`, "i");
  const match = regex.exec(detailLabel);

  return match?.[1] || "";
};

const FORM_NAME = "FormStickerFood";

const FormStickerFood = async (props: FormStickerFoodProps): Promise<TDocumentDefinitions> => {
  const currentDate = moment();

  const companyLogoForm = await CompanyLogoForm({
    font: "KanitLM",
    form: FORM_NAME,
    height: 22.5,
    showAddress: ["CU"],
  });
  const { addressWidth, font, fontSizes, images, lineHeights } = companyLogoForm;

  const headerName = `หอผู้ป่วย ${props.ward_name} ห้อง ${props.room}`;

  const headerLines = splitStringNewLine(headerName, {
    fontSize: fontSizes[11],
    width: 175 - addressWidth,
  });

  console.log("FormStickerFood", props);

  return {
    defaultStyle: {
      font,
      fontSize: fontSizes[10.5],
      lineHeight: lineHeights[1],
    },
    images: {
      ...images,
    },
    pageMargins: [7.5, 58.5, 7.5, 15.5],
    pageOrientation: "landscape",
    // 5*8
    pageSize: { height: 302.36220472, width: 188.97637795 },
    styles: {
      fieldHeader: { bold: true, fontSize: fontSizes[11] },
      fieldKey: { bold: true },
      miniField: { fontSize: fontSizes[8.5] },
    },
    header: () => ({
      columns: [
        {
          stack: [
            {
              columns: [
                {
                  ...companyLogoForm,
                  margin: [3.5, -3, 0, 0],
                },
                {
                  alignment: "center",
                  marginTop: headerLines.length > 1 ? -2.25 : 6.5,
                  stack: headerLines.map((text) => ({ noWrap: true, text })),
                  style: "fieldHeader",
                  width: "*",
                },
              ],
            },
            {
              columns: [
                {
                  stack: [
                    {
                      lineHeight: lineHeights[0.8],
                      style: "fieldHeader",
                      text: props.patient_fullname,
                    },
                    {
                      style: "miniField",
                      text: [
                        { style: "fieldKey", text: "ว/ด/ป เกิด: " },
                        {
                          style: "fieldKey",
                          text: `${props.birthdate} อายุ ${props.full_age}`,
                        },
                      ],
                    },
                  ],
                  width: "60%",
                },
                { text: "", width: "1%" },
                {
                  noWrap: true,
                  stack: [
                    {
                      bold: true,
                      lineHeight: lineHeights[0.8],
                      text: [
                        { font: "PoppinsLM", text: `HN: ${props.hn}` },
                        {
                          text: ` วันที่ ${formatDate(currentDate)} เวลา ${currentDate.format(
                            "HH:mm"
                          )} น.`,
                        },
                      ],
                    },
                    {
                      style: "miniField",
                      text: [
                        { style: "fieldKey", text: "ศาสนา: " },
                        {
                          style: "fieldKey",
                          text: props.religion || "-",
                        },
                      ],
                    },
                  ],
                  width: "*",
                },
              ],
              margin: [5, 0, 20, 0],
            },
          ],
          width: "*",
        },
        {
          stack: [
            {
              alignment: "left",
              margin: [0, -2.5, 0, 0],
              style: "fieldKey",
              text: "ห้องโภชนาการ",
            },
          ],
          width: 50,
        },
      ],
      margin: [2.5, 7.5, 5, 0],
    }),
    content: [
      {
        lineHeight: lineHeights[1.05],
        stack: [
          {
            columns: [
              {
                style: "fieldKey",
                text: [{ text: "มื้ออาหาร: " }, { text: props.regularMeal }],
                width: "40%",
              },
              {
                style: "fieldKey",
                text: [{ text: "รายการอาหาร: " }, { text: props.food_type_name }],
                width: "*",
              },
            ],
          },
          {
            style: "fieldKey",
            text: [{ text: "อาหารเฉพาะโรค: " }, { text: props.special_diet_detail }],
          },
          {
            margin: [10, 0, 0, 0],
            style: "fieldKey",
            text: [{ text: "หมายเหตุ: " }, { text: props.special_diet_remark }],
          },
          { style: "fieldKey", text: "รายละเอียด: " },
          {
            margin: [10, 0, 0, 0],
            stack: ["แพ้", "เสริม", "ข้อจำกัด", "หมายเหตุ"].map((label) => ({
              text: [
                { text: `${label}: ` },
                { text: extractContent(props.detail_label, label) || " " },
              ],
            })),
            style: "fieldKey",
          },
        ],
      },
    ],
    footer: () => ({
      marginRight: 7.5,
      stack: [{ alignment: "right", text: `ผู้พิมพ์: ${props.printUser || "-"}` }],
      style: "fieldKey",
    }),
  };
};

export default FormStickerFood;
