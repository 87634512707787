import WasmController from "react-lib/frameworks/WasmController";

// Interface
import { State as MainState } from "HIS/MainHISInterface";

// Utils

// APIs
import DFPayrollPND1Attachment from "issara-sdk/apis/DFPayrollPND1Attachment_apps_INF";
import DFPayrollPND3Attachment from "issara-sdk/apis/DFPayrollPND3Attachment_apps_INF";
import WithholdingTaxCertificateDocument from "issara-sdk/apis/WithholdingTaxCertificateDocument_apps_INF";
import DFPayrollPND3Report from "issara-sdk/apis/DFPayrollPND3Report_apps_INF";
import DFPayrollPND3AReport from "issara-sdk/apis/DFPayrollPND3AReport_apps_INF";
import DFPayrollPND1Report from "issara-sdk/apis/DFPayrollPND1Report_apps_INF";
import DFPayrollPND3AAttachment from "issara-sdk/apis/DFPayrollPND3AAttachment_apps_INF";
import WithholdingTaxCertificateAttachment from "issara-sdk/apis/WithholdingTaxCertificateAttachment_apps_INF";
import ConstanceView from "issara-sdk/apis/ConstanceView_core";
// PDF
import FormPND3Report from "react-lib/apps/HISV3/CLM/pdf/FormPND3Report";
import FormPND3AReport from "react-lib/apps/HISV3/CLM/pdf/FormPND3AReport";
import FormTaxCertificateReport from "react-lib/apps/HISV3/CLM/pdf/FormTaxCertificateReport";
import FormPND1Report from "react-lib/apps/HISV3/CLM/pdf/FormPND1Report";
import FormPND3 from "react-lib/apps/HISV3/CLM/pdf/FormPND3";
import FormPND3A from "react-lib/apps/HISV3/CLM/pdf/FormPND3A";
import FormPND1 from "react-lib/apps/HISV3/CLM/pdf/FormPND1";
import FormTaxCertificate from "react-lib/apps/HISV3/CLM/pdf/FormTaxCertificate";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { combinePdfFiles } from "react-lib/apps/HISV3/common/CommonInterface";
import moment from "moment";

export type State = Partial<{
  // Sequence
  ClaimTaxDocumentSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    selectedMenu: string;
    // เมนูย่อย
  }>;
}>;

type Picked = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage" | "django">>;

export const StateInitial: State = {
  // Sequence
  ClaimTaxDocumentSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

// Const
const Masters = [
  ["coverage", {}],
  ["doctor", {}],
  // ["bilStation", {}],
  // ["bilStationLog", {}],
  // ["payer", {}],
  // ["division", {}],
];

const toDate = moment();

const REPORT_TYPES = {
  PND3Report: "ภ.ง.ด. 3",
  PND3AReport: "ภ.ง.ด 3ก",
  TaxCertificateReport: "หนังสือรับรองการหักภาษี ณ ที่จ่าย",
  PND1Report: "ภ.ง.ด. 1",
  PND3: "ใบแนบ ภ.ง.ด. 3",
  PND3A: "ใบแนบ ภ.ง.ด. 3 ก",
  TaxCertificate: "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย",
  PND1: "ใบแนบ ภ.ง.ด. 1",
} as const;

type ReportTypesKeys = keyof typeof REPORT_TYPES;

type RTV = (typeof REPORT_TYPES)[ReportTypesKeys];

type Handler = (controller: WasmController<State & Picked, Event, Data>, params?: any) => any;

/* ------------------------------------------------------ */
/*                          START                         */
/* ------------------------------------------------------ */
export const Start: Handler = async (controller, params) => {
  console.log("TaxDocument START State");

  let state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  console.log("TaxDoc Controller Data: ", controller);
  console.log("TaxDoc Params Data: ", params);

  controller.setState(
    {
      ClaimTaxDocumentSequence: {
        ...state.ClaimTaxDocumentSequence,
        sequenceIndex: "Action",
        // Initial Sub-menu
      },
    },
    () => Action(controller, { action: "FETCH_DATA" })
  );
};

/* ------------------------------------------------------ */
/*                         ACTION                         */
/* ------------------------------------------------------ */
export const Action: Handler = async (controller, params) => {
  if (params.action === "FETCH_DATA") {
    HandleFetchData(controller, params);
  } else if (params.action === "PREVIEW_REPORT") {
    HandlePreviewReport(controller, params);
  } else if (params.action === "PRINT_REPORT") {
    HandlePrintReport(controller, params);
  } else if (params.action === "GET_REPORT") {
    HandleGetReport(controller, params);
  }
};

const HandleFetchData: Handler = async (controller, params) => {
  console.log("TaxDoc Seq FETCH_REPORT SEQUENCE");

  let state = controller.getState();

  console.log("TaxDocl FETCH_REPORT Data: ", state.ClaimTaxDocumentSequence);
};

const HandlePreviewReport: Handler = async (controller, params) => {
  console.log("TaxDoc Seq Preview");

  let state = controller.getState();
};

const HandlePrintReport: Handler = async (controller, params) => {
  console.log("TaxDoc Seq Print");

  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.name}`]: "LOADING",
    },
    errorMessage: { ...state.errorMessage, [params.card]: null }
  });

  const type = params.type as RTV;

  const useAPI = {
    ["ภ.ง.ด. 3"]: DFPayrollPND3Report.get,
    ["ภ.ง.ด 3ก"]: DFPayrollPND3AReport.get,
    ["หนังสือรับรองการหักภาษี ณ ที่จ่าย"]: WithholdingTaxCertificateDocument.get,
    ["ภ.ง.ด. 1"]: DFPayrollPND1Report.get,
    ["ใบแนบ ภ.ง.ด. 3"]: DFPayrollPND3Attachment.get,
    ["ใบแนบ ภ.ง.ด. 3 ก"]: DFPayrollPND3AAttachment.get,
    ["ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย"]: WithholdingTaxCertificateAttachment.get,
    ["ใบแนบ ภ.ง.ด. 1"]: DFPayrollPND1Attachment.get,
  }[type];

  const [reportRes, reportErr, reportNet] = await useAPI({
    params: {
      year: params.year,
      month: params.months || 0,
      start_month:
        params.type === "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย" && params.startMonths === 0
          ? toDate?.format("M")
          : params.startMonths || 0,
      end_month:
        params.type === "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย" && params.endMonths === 0
          ? toDate?.format("M")
          : params.endMonths || 0,
      doctor: params.doctor === "ALL" ? "" : params.doctor || "",
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  let message = "ERROR";

  if (reportRes) {
    const [hospitalPhoneRes, hospitalPhoneErr, hospitalPhoneNet] = await ConstanceView.get({
      params: { list: "core_HOSPITAL_PHONE_NUMBER" },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    let usePDFForm: any = { content: [] };

    let fields = reportRes?.fields?.length === 0 ? [{}] : reportRes?.fields;

    let pdfData = {
      ...reportRes,
      fields: fields,
      taxPayerId: "4101030690",
      payerName: "คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
      street: "อังรีดูนังค์",
      subDistrict: "ปทุมวัน",
      district: "ปทุมวัน",
      province: "กรุงเทพฯ",
      zipCode: "10330",
      phone: "02-2188838-40",
      // TaxCertificate
      officerId: `0994000158831`,
      officerName: "คณะทันตแพทยศาสตร์จุฬาลงกรณ์มหาวิทยาลัย",
      officerAddress: "ถ.อังรีดูนังค์ แขวงปทุมวัน เขตปทุมวัน กรุงเทพฯ 10330",
      otherNote: "ประกอบโรคศิลปะ",
      datePayTex: `${monthShortName[reportRes?.params?.start_month || 0]} - ${
        monthShortName[reportRes?.params?.end_month || 0]
      } ${reportRes?.params?.year}`,
      hospitalPhone: hospitalPhoneRes?.result?.core_HOSPITAL_PHONE_NUMBER,
    };

    switch (type) {
      case "ภ.ง.ด. 3":
        usePDFForm = (await FormPND3Report(pdfData)).uint8Array;
        break;
      case "ภ.ง.ด 3ก":
        usePDFForm = (await FormPND3AReport(pdfData)).uint8Array;
        break;
      case "หนังสือรับรองการหักภาษี ณ ที่จ่าย":
        usePDFForm = await Promise.all(
          (pdfData?.fields || [{}]).map((data: any, index: number) =>
            FormTaxCertificateReport(pdfData, index).then((item) => item.uint8Array)
          )
        );
        break;
      case "ภ.ง.ด. 1":
        usePDFForm = (await FormPND1Report(pdfData)).uint8Array;
        break;
      case "ใบแนบ ภ.ง.ด. 3":
        usePDFForm = await Promise.all(
          (pdfData?.fields || [{}]).map((data: any, index: number) =>
            FormPND3(pdfData, index).then((item) => item.uint8Array)
          )
        );
        break;
      case "ใบแนบ ภ.ง.ด. 3 ก":
        usePDFForm = await Promise.all(
          (pdfData?.fields || [{}]).map((data: any, index: number) =>
            FormPND3A(pdfData, index).then((item) => item.uint8Array)
          )
        );
        break;
      case "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย":
        usePDFForm = FormTaxCertificate(pdfData);
        break;
      case "ใบแนบ ภ.ง.ด. 1":
        usePDFForm = await Promise.all(
          (pdfData?.fields || [{}]).map((data: any, index: number) =>
            FormPND1(pdfData, index).then((item) => item.uint8Array)
          )
        );
        break;
      default:
        break;
    }
    const selectPDFForm = Array.isArray(usePDFForm) ? usePDFForm : [usePDFForm];
    const filtered = selectPDFForm.filter(Boolean);

    if (filtered.length) {
      const blobURL = await combinePdfFiles(filtered as Uint8Array[]);

      globalThis.open(blobURL);
      message = "SUCCESS";
    }
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.name}`]: message,
    },
    errorMessage: { ...state.errorMessage, [params.card]: reportErr?.doctor?.[0] }
  });
};

const HandleGetReport: Handler = async (controller, params) => {
  console.log("TaxDoc Seq Get");

  let state = controller.getState();
};

const monthShortName = {
  "1": "ม.ค.",
  "2": "ก.พ.",
  "3": "มี.ค.",
  "4": "เม.ย.",
  "5": "พ.ค.",
  "6": "มิ.ย.",
  "7": "ก.ค.",
  "8": "ส.ค.",
  "9": "ก.ย.",
  "10": "ต.ค.",
  "11": "พ.ย.",
  "12": "ธ.ค.",
};

const jsonDataPND1 = {
  officerId: `0994000158831`,
  params: {
    page_count: 2,
  },
  fields: [
    {
      page_number: 1,
      total: 1232.0,
      items: [
        {
          seq: 1,
          first_name: "นทพ. ธัชชัย",
          last_name: "ชัยพร",
          citizen_id: "",
          net_income_price: 272.0,
        },
        {
          seq: 2,
          first_name: "ศ.ทญ. สมศรี",
          last_name: "วิถีพร",
          citizen_id: "3101600410395",
          net_income_price: 160.0,
        },
        {
          seq: 3,
          first_name: "รศ.ทญ. จินตนา",
          last_name: "ศิริชุมพันธ์",
          citizen_id: "3101300079103",
          net_income_price: 100.0,
        },
        {
          seq: 4,
          first_name: "รศ.ทญ. พรทิพย์",
          last_name: "ชิวชรัตน์",
          citizen_id: "3102401326263",
          net_income_price: 320.0,
        },
        {
          seq: 5,
          first_name: "นาย ชัยชาติ",
          last_name: "ชาติชัย",
          citizen_id: "",
          net_income_price: 200.0,
        },
        {
          seq: 6,
          first_name: "รศ.ทญ.ดร. ปิยมาศ",
          last_name: "สำเร็จกาญจนกิจ",
          citizen_id: "3100800616352",
          net_income_price: 100.0,
        },
        {
          seq: 7,
          first_name: "นาย ชัยชาติ",
          last_name: "ทองดี",
          citizen_id: "",
          net_income_price: 80.0,
        },
      ],
    },
    {
      page_number: 2,
      total: 1232.0,
      items: [
        {
          seq: 1,
          first_name: "นทพ. ธัชชัย",
          last_name: "ชัยพร",
          citizen_id: "",
          net_income_price: 272.0,
        },
        {
          seq: 2,
          first_name: "ศ.ทญ. สมศรี",
          last_name: "วิถีพร",
          citizen_id: "3101600410395",
          net_income_price: 160.0,
        },
        {
          seq: 3,
          first_name: "รศ.ทญ. จินตนา",
          last_name: "ศิริชุมพันธ์",
          citizen_id: "3101300079103",
          net_income_price: 100.0,
        },
        {
          seq: 4,
          first_name: "รศ.ทญ. พรทิพย์",
          last_name: "ชิวชรัตน์",
          citizen_id: "3102401326263",
          net_income_price: 320.0,
        },
        {
          seq: 5,
          first_name: "นาย ชัยชาติ",
          last_name: "ชาติชัย",
          citizen_id: "",
          net_income_price: 200.0,
        },
        {
          seq: 6,
          first_name: "รศ.ทญ.ดร. ปิยมาศ",
          last_name: "สำเร็จกาญจนกิจ",
          citizen_id: "3100800616352",
          net_income_price: 100.0,
        },
        {
          seq: 7,
          first_name: "นาย ชัยชาติ",
          last_name: "ทองดี",
          citizen_id: "",
          net_income_price: 80.0,
        },
      ],
    },
  ],
  taxId: "0000000000000",
  isMoneyType1: true,
  isMoneyType2: false,
  isMoneyType3: true,
  isMoneyType4: false,
  isMoneyType5: true,
  branch: "001",
  sheetNo: "00",
  quantity: 0,
  earnings: [
    {
      no: "01",
      earnerId: "1111111111115",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      payDate: "12/12/2567",
      paidAmount: 10000000,
      paidAmountDecimal: 0,
      taxCost: 0,
      taxCostDecimal: 0,
      condition: "****",
    },
    {
      no: "02",
      earnerId: "2222222222223",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      payDate: "12/12/2567",
      paidAmount: 100000,
      paidAmountDecimal: 0,
      taxCost: 0,
      taxCostDecimal: 0,
      condition: "****",
    },
    {
      no: "03",
      earnerId: "3333333333333",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      payDate: "12/12/2567",
      paidAmount: 0,
      paidAmountDecimal: 0,
      taxCost: 0,
      taxCostDecimal: 2000,
      condition: "****",
    },
    {
      no: "04",
      earnerId: "4444444444444",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      payDate: "12/12/2567",
      paidAmount: 0,
      paidAmountDecimal: 0,
      taxCost: 0,
      taxCostDecimal: 0,
      condition: "****",
    },
    {
      no: "05",
      earnerId: "5555555555555",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      payDate: "12/12/2567",
      paidAmount: 0,
      paidAmountDecimal: 0,
      taxCost: 0,
      taxCostDecimal: 0,
      condition: "****",
    },
    {
      no: "06",
      earnerId: "6666666666666",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      payDate: "12/12/2567",
      paidAmount: 0,
      paidAmountDecimal: 0,
      taxCost: 0,
      taxCostDecimal: 0,
      condition: "****",
    },
    {
      no: "07",
      earnerId: "7777777777777",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      payDate: "12/12/2567",
      paidAmount: 0,
      paidAmountDecimal: 0,
      taxCost: 0,
      taxCostDecimal: 0,
      condition: "****",
    },
    {
      no: "08",
      earnerId: "8888888888888",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      payDate: "12/12/2567",
      paidAmount: 10000,
      paidAmountDecimal: 0,
      taxCost: 0,
      taxCostDecimal: 0,
      condition: "****",
    },
  ],
  totalAmount: 100000,
  totalAmountDecimal: 0,
  totalTax: 0,
  totalTaxDecimal: 0,
  payerFullName: "นาย แดงเขียว",
  position: "worker",
  submitDate: "12",
  submitMonth: "ธันวาคม",
  submitYear: "2567",
};

const jsonDataPND3 = {
  taxId: "0000020000000",
  branch: "011",
  sheetNo: "00",
  quantity: 0,
  earnings: [
    {
      no: "01",
      earnerId: "1111111111111",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "001",
      payDate: "12/12/2567",
      moneyType: "as",
      taxPercent: 10,
      paidAmountType: 1000,
      paidAmountTypeDecimal: 50,
      money: 0,
      moneyDecimal: 0,
      condition: "****",
    },
    {
      no: "02",
      earnerId: "2222222222222",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "002",
      payDate: "12/12/2567",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 1000000,
      paidAmountTypeDecimal: 555555,
      money: 0,
      moneyDecimal: 0,
      condition: "****",
    },
    {
      no: "03",
      earnerId: "3333333333333",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "000",
      payDate: "12/12/2567",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 0,
      paidAmountTypeDecimal: 0,
      money: 0,
      moneyDecimal: 0,
      condition: "****",
    },
    {
      no: "04",
      earnerId: "4444444444444",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "000",
      payDate: "12/12/2567",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 0,
      paidAmountTypeDecimal: 0,
      money: 0,
      moneyDecimal: 0,
      condition: "****",
    },
    {
      no: "05",
      earnerId: "5555555555555",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "000",
      payDate: "12/12/2567",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 0,
      paidAmountTypeDecimal: 0,
      money: 0,
      moneyDecimal: 0,
      condition: "****",
    },
    {
      no: "06",
      earnerId: "6666666666666",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "000",
      payDate: "12/12/2567",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 0,
      paidAmountTypeDecimal: 0,
      money: 0,
      moneyDecimal: 0,
      condition: "****",
    },
  ],
  totalAmount: 0,
  totalAmountDecimal: 0,
  totalTaxCost: 100000,
  totalTaxCostDecimal: 255555,
  payerFullName: "นาย แดง เขียว",
  position: "worker",
  submitDate: "12",
  submitMonth: "ธันวาคม",
  submitYear: "2567 ",
};

const jsonDataPND3A = {
  taxId: "1000000000000",
  branch: "101",
  sheetNo: "00",
  quantity: 0,
  earnings: [
    {
      no: "01",
      earnerId: "1111111111111",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "001",
      moneyType: "as",
      taxPercent: 10,
      paidAmountType: 0,
      paidAmountTypeDecimal: 0,
      totalTax: 0,
      totalTaxDecimal: 0,
    },
    {
      no: "02",
      earnerId: "2222222222222",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "002",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 10000,
      paidAmountTypeDecimal: 50550,
      totalTax: 5555550,
      totalTaxDecimal: 0,
    },
    {
      no: "03",
      earnerId: "3333333333333",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "003",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 44444440,
      paidAmountTypeDecimal: 33330,
      totalTax: 0,
      totalTaxDecimal: 0,
    },
    {
      no: "04",
      earnerId: "4444444444444",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "000",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 0,
      paidAmountTypeDecimal: 0,
      totalTax: 0,
      totalTaxDecimal: 0,
    },
    {
      no: "05",
      earnerId: "5555555555555",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "000",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 0,
      paidAmountTypeDecimal: 0,
      totalTax: 0,
      totalTaxDecimal: 0,
    },
    {
      no: "06",
      earnerId: "6666666666666",
      prefix: "นาย",
      fullName: "GG",
      lastName: "Bro",
      address: "aa bb",
      earnerBranch: "000",
      moneyType: "as",
      taxPercent: 0,
      paidAmountType: 0,
      paidAmountTypeDecimal: 0,
      totalTax: 0,
      totalTaxDecimal: 0,
    },
  ],
  totalAmountIf: 500000,
  totalAmountIfDecimal: 0,
  totalTaxIf: 90,
  totalTaxIfDecimal: 500000,
  payerFullName: "นาย แดง เขียว",
  position: "worker",
  submitDate: "12",
  submitMonth: "ธันวาคม",
  submitYear: "2567",
};

const jsonDataTaxCertificateReport = {
  VolumeNo: "00",
  bookNo: "00",
  taxOfficerId13: "0000000000000",
  taxOfficerId: "0000000000",
  taxOfficerPrefix: "นาย",
  taxOfficerFullName: "GG",
  taxOfficerLastName: "Bro ",
  address: "aa ff gg",
  taxId13: "1000000000000",
  taxId: "1000000000",
  taxPrefix: "นาย",
  taxFullName: "sleep",
  taxLastName: "Bitcoin",
  taxAddress: "aa ff gg",
  formNo: 0,
  isTaxType1: false,
  isTaxType2: false,
  isTaxType3: true,
  isTaxType4: false,
  isTaxType5: false,
  isTaxType6: false,
  isTaxType7: false,
  moneyType1PayDate: "12/12/2567",
  moneyType1PayAmount: 555555555,
  moneyType1PayAmountDecimal: 0,
  moneyType1TaxCost: 0,
  moneyType1TaxCostDecimal: 0,
  moneyType2PayDate: "12/12/2567",
  moneyType2PayAmount: 200000,
  moneyType2PayAmountDecimal: 0,
  moneyType2TaxCost: 0,
  moneyType2TaxCostDecimal: 0,
  moneyType3PayDate: "12/12/2567",
  moneyType3PayAmount: 30000,
  moneyType3PayAmountDecimal: 0,
  moneyType3TaxCost: 0,
  moneyType3TaxCostDecimal: 0,
  moneyType4PayDate: "12/12/2567",
  moneyType4PayAmount: 0,
  moneyType4PayAmountDecimal: 0,
  moneyType4TaxCost: 0,
  moneyType4TaxCostDecimal: 0,
  moneyType4_30percentPayDate: "12/12/2567",
  moneyType4_30percentPayAmount: 0,
  moneyType4_30percentPayAmountDecimal: 0,
  moneyType4_30percentTaxCost: 0,
  moneyType4_30percentTaxCostDecimal: 0,
  moneyType4_25percentPayDate: "12/12/2567",
  moneyType4_25percentPayAmount: 0,
  moneyType4_25percentPayAmountDecimal: 0,
  moneyType4_25percentTaxCost: 0,
  moneyType4_25percentTaxCostDecimal: 0,
  moneyType4_20percentPayDate: "12/12/2567",
  moneyType4_20percentPayAmount: 0,
  moneyType4_20percentPayAmountDecimal: 0,
  moneyType4_20percentTaxCost: 0,
  moneyType4_20percentTaxCostDecimal: 0,
  moneyType4_OtherPercent: "dd",
  moneyType4_OtherPercentPayDate: "12/12/2567",
  moneyType4_OtherPercentPayAmount: 0,
  moneyType4_OtherPercentPayAmountDecimal: 0,
  moneyType4_OtherPercentTaxCost: 0,
  moneyType4_OtherPercentTaxCostDecimal: 0,
  moneyType4_2Point1PayDate: "12/12/2567",
  moneyType4_2Point1PayAmount: 10000,
  moneyType4_2Point1PayAmountDecimal: 0,
  moneyType4_2Point1TaxCost: 50,
  moneyType4_2Point1TaxCostDecimal: 0,
  moneyType4_2Point2PayDate: "12/12/2567",
  moneyType4_2Point2PayAmount: 0,
  moneyType4_2Point2PayAmountDecimal: 0,
  moneyType4_2Point2TaxCost: 0,
  moneyType4_2Point2TaxCostDecimal: 0,
  moneyType4_2Point3PayDate: null,
  moneyType4_2Point3PayAmount: 0,
  moneyType4_2Point3PayAmountDecimal: 0,
  moneyType4_2Point3TaxCost: 0,
  moneyType4_2Point3TaxCostDecimal: 0,
  moneyType4_2Point4PayDate: null,
  moneyType4_2Point4PayAmount: 0,
  moneyType4_2Point4PayAmountDecimal: 0,
  moneyType4_2Point4TaxCost: 0,
  moneyType4_2Point4TaxCostDecimal: 0,
  moneyType4_2Point5: "gg",
  moneyType4_2Point5PayDate: "12/12/2567",
  moneyType4_2Point5PayAmount: 0,
  moneyType4_2Point5PayAmountDecimal: 0,
  moneyType4_2Point5TaxCost: 0,
  moneyType4_2Point5TaxCostDecimal: 0,
  moneyType5PayDate: "12/12/2567",
  moneyType5PayAmount: 0,
  moneyType5PayAmountDecimal: 0,
  moneyType5TaxCost: 0,
  moneyType5TaxCostDecimal: 0,
  moneyType6PayDate: "12/12/2567",
  moneyType6PayAmount: 0,
  moneyType6PayAmountDecimal: 0,
  moneyType6TaxCost: 0,
  moneyType6TaxCostDecimal: 0,
  totalPayAmount: 5555550,
  totalPayAmountDecimal: 25,
  totalTaxCost: 100000,
  totalTaxCostDecimal: 50,
  teacherFunds: 0,
  socialFunds: 0,
  makeALiveFunds: 0,
  isPayerType1: false,
  isPayerType2: true,
  isPayerType3: false,
  isPayerType4: false,
  payerType4Detail: "hh",

  submitDate: "12",
  submitMonth: "ธันวาคม",
  submitYear: "2567",
};

const jsonDataPND3Report = {
  taxId: "0000000000000",
  branch: "001",
  taxOfficerPrefix: "นาย",
  taxOfficerFullName: "GG",
  taxOfficerLastName: "Bro ",
  buildingName: "gg",
  roomNo: "00/00",
  floor: "2a",
  village: "silent hills",
  No: "00/00",
  villageNo: "00",
  alley: "bb",
  Junction: "gg",
  street: "bla",
  subDistrict: "ha",
  districtOrArea: "ho",
  province: "AA",
  postCode: "10000",
  isNormalSubmit: true,
  isSpecialSubmit: false,
  specialSubmitNo: 0,
  buddhistEra: "0000",
  isJanuary: false,
  isFebruary: true,
  isMarch: false,
  isApril: false,
  isMay: false,
  isJune: false,
  isJuly: false,
  isAugust: false,
  isSeptember: true,
  isOctober: false,
  isNovember: false,
  isDecember: false,
  isStatute3: false,
  isStatute48: false,
  isStatute50: false,
  isAttachment: false,
  attachmentQuantity: 0,
  attachmentList: 0,
  isRecordingInComputer: true,
  recordingInComputerQuantity: 0,
  recordingInComputerList: 0,
  registerNo: 0,
  registerRefNo: 0,
  summaryNo1CaseMoney: 0,
  summaryNo1CaseMoneyDecimal: 0,
  summaryNo2CaseMoney: 0,
  summaryNo2CaseMoneyDecimal: 0,
  summaryNo3CaseMoney: 0,
  summaryNo3CaseMoneyDecimal: 0,
  summaryNo4CaseMoney: 4646455,
  summaryNo4CaseMoneyDecimal: 222220,
  payerFullName: "นาย แดง เขียว",
  position: "worker",
  submitDate: "12",
  submitMonth: "ธันวาคม",
  submitYear: "2567",
};

const jsonDataPND3AReport = {
  taxId: "0000000000000",
  branch: "001",
  taxOfficerPrefix: "นาย",
  taxOfficerFullName: "GG",
  taxOfficerLastName: "Bro ",
  buildingName: "gg",
  roomNo: "00/00",
  floor: "2a",
  village: "silent hills",
  No: "00/00",
  villageNo: "00",
  alley: "bb",
  Junction: "gg",
  street: "bla",
  subDistrict: "ha",
  districtOrArea: "ho",
  province: "AA",
  postCode: " 10000",
  specialSubmitNo: 0,
  buddhistEraTax: "0000",
  isNormalSubmit: true,
  isSpecialSubmit: true,
  isAttachment: true,
  attachmentQuantity: 0,
  attachmentList: 0,
  isRecordingInComputer: true,
  recordingInComputerQuantity: 0,
  recordingInComputerList: 0,
  registerNo: 0,
  registerRefNo: 0,
  summaryTotalAmount: 0,
  summaryTotalAmountDecimal: 0,
  summaryTotalTax: 100000000,
  summaryTotalTaxDecimal: 25000,
  payerFullName: "นาย แดง เขียว",

  position: "worker",
  submitDate: "12",
  submitMonth: "ธันวาคม",
  submitYear: "2567",
};

const jsonDataPND1Report = {
  taxId: "0000000000000",
  branch: "001",
  taxOfficerPrefix: "นาย",
  taxOfficerFullName: "GG",
  taxOfficerLastName: "Bro ",
  buildingName: "gg",
  roomNo: "00/00",
  floor: "2a",
  village: "silent hills",
  No: "00/00",
  villageNo: "00",
  alley: "bb",
  Junction: "gg",
  street: "bla",
  subDistrict: "ha",
  districtOrArea: "ho",
  province: "AA",
  postCode: "10000",
  payDate: "12/12/2024",
  isNormalSubmit: false,
  isSpecialSubmit: false,
  specialSubmitNo: 0,
  buddhistEra: "0000",
  isJanuary: true,
  isFebruary: false,
  isMarch: false,
  isApril: true,
  isMay: false,
  isJune: false,
  isJuly: false,
  isAugust: false,
  isSeptember: false,
  isOctober: false,
  isNovember: false,
  isDecember: false,
  isAttachment: true,
  attachmentQuantity: 0,
  isRecordingInComputer: false,
  recordingInComputerQuantity: 0,
  registerNo: 0,
  registerRefNo: 0,
  summaryNo1CaseNumber: "00",
  summaryNo1TotalIncome: 10000000000,
  summaryNo1TotalIncomeDecimal: 0,
  summaryNo1Tax: 0,
  summaryNo1TaxDecimal: 0,
  summaryNo2CaseNumber: "00",
  summaryNo2TotalIncome: 0,
  summaryNo2TotalIncomeDecimal: 0,
  summaryNo2Tax: 0,
  summaryNo2TaxDecimal: 0,
  summaryNo2BookNo: "00",
  summaryNo2Date: "12/12/2024",
  summaryNo3CaseNumber: "00",
  summaryNo3TotalIncome: 232320,
  summaryNo3TotalIncomeDecimal: 2000000,
  summaryNo3Tax: 0,
  summaryNo3TaxDecimal: 0,
  summaryNo4CaseNumber: "00",
  summaryNo4TotalIncome: 0,
  summaryNo4TotalIncomeDecimal: 0,
  summaryNo4Tax: 0,
  summaryNo4TaxDecimal: 0,
  summaryNo5CaseNumber: "00",
  summaryNo5TotalIncome: 0,
  summaryNo5TotalIncomeDecimal: 0,
  summaryNo5Tax: 0,
  summaryNo5TaxDecimal: 0,
  summaryNo6totalCaseNumber: 0,
  summaryNo6MoneyTotalIncome: 0,
  summaryNo6MoneyTotalIncomeDecimal: 0,
  summaryNo6TotalTax: 0,
  summaryNo6TotalTaxDecimal: 0,
  summaryNo7If: 0,
  summaryNo7IfDecimal: 0,
  summaryNo8: 0,
  summaryNo8Decimal: 0,
  payerFullName: "นาย แดง เขียว",
  position: "worker",
  submitDate: "12",
  submitMonth: "ธันวาคม",
  submitYear: "2567",
};

const jsonDataTaxCertificate = {
  tax: [
    {
      no: "1.",
      prefix: "นาง",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "0000000000000",
      taxId: "0000000000",
      earnPerYear: 255525,
      earnPerYearDecimal: 25,
      taxPerYear: 5555555,
      taxPerYearDecimal: 50,
    },
    {
      no: "2.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1111111111111",
      taxId: "1111111111",
      earnPerYear: 1000000000,
      earnPerYearDecimal: 25,
      taxPerYear: 90,
      taxPerYearDecimal: 25,
    },
    {
      no: "3.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "2222222222222",
      taxId: "2222222222",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "4.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "3333333333333",
      taxId: "3333333333",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "5.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "4444444444444",
      taxId: "4444444444",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "6.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "5555555555555",
      taxId: "5555555555",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "7.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "6666666666666",
      taxId: "6666666666",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "8.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "7777777777777",
      taxId: "7777777777",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "9.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "8888888888888",
      taxId: "8888888888",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "10.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "9999999999999",
      taxId: "9999999999",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "11.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1010101010101",
      taxId: "1010101010",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "12.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1212121212121",
      taxId: "1212121212",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "13.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1313131313131",
      taxId: "1313131313",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "14.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1414141414141",
      taxId: "1414141414",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "15.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1515151515151",
      taxId: "1515151515",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "16.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1616161616161",
      taxId: "1616161616",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "17.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1717171717171",
      taxId: "1717171717",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "18.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1818181818181",
      taxId: "1818181818",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "19.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "1919191919191",
      taxId: "1919191919",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
    {
      no: "20.",
      prefix: "นาย",
      fullName: "Bitcoin",
      lastName: "Bitcoin",
      idCard: "2020202020202",
      taxId: "2020202020",
      earnPerYear: 0,
      earnPerYearDecimal: 0,
      taxPerYear: 0,
      taxPerYearDecimal: 0,
    },
  ],
};
