import React, {ChangeEvent, useEffect, useRef, useState, useCallback} from "react";
import { Input, InputProps } from "semantic-ui-react";
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import _ from "react-lib/compat/lodashplus"


const CardCSDivisionSearch = (props) => {

  const isMounted = useRef(true)
  const divisionInputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [divisionOptions, setDivisionOptions] = useState([])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (props.allDivisionList?.length > 0) {
      const options = props.allDivisionList?.map( item => {
        return {
          key: item.id,
          value: item.value, 
          text: item.text, 
        }
      })
      setDivisionOptions(options)
    }
  }, [props.allDivisionList])


  const debounceKeying = useCallback(_.debounce(() =>{
    handleSearch()
    }, 5000, {leading: false}),
    []
  )

  const handleSearch = useCallback(
    () => {
      let divisionSearch = divisionInputRef?.current?.inputRef?.current?.value
      if (divisionSearch !== null && divisionSearch !== undefined) {
        setIsLoading(true)
        props.setDivisionSearch(divisionSearch)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000);
      }
    },
    [divisionInputRef],
  )
  
  return (
    <Input
      disabled={props.disabled}
      className="card-cs-division-search"
      size="mini"
      ref={divisionInputRef}
      icon="search"
      loading={props.isLoading || isLoading}
      onChange={(e: ChangeEvent, data: InputProps)=> {
        // console.log("e:", e)
        // console.log("data", data)
        debounceKeying()
      }}
      onKeyDown={(e: KeyboardEvent) => {
        console.log(" e.key", e.key)
        if (e.key === "Enter") {
          debounceKeying.cancel()
          handleSearch()
        } 
      }}
      placeholder="division..."
      // options={divisionOptions}
    />
  );
};

CardCSDivisionSearch.propTypes = {
  allDivisionList: PropTypes.arrayOf(PropTypes.object),
  setDivisionSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

CardCSDivisionSearch.defaultProps = {
  allDivisionList: [],
  setDivisionSearch: () => {},
  isLoading: false,
  disabled: false
};


export default CardCSDivisionSearch;
