import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Label,
  Input,
  List,
  Header,
  Grid,
  Menu,
  Icon,
} from "semantic-ui-react";
import "./App.css";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { FormattedMessage, injectIntl } from "react-intl";

export const DiagRule = ({ onStart = () => {} }) => {
  const handleGotoForm = () => {
    onStart();
  };

  console.log("DiagRule R");
  return (
    <Grid className="diag-rule" textAlign="center" style={{ margin: "auto" }} verticalAlign="middle">
      <Grid.Row>
        <Header className="diag-rule-header" size={"medium"}>
          กรุณาทำแบบประเมินความเสี่ยง
          <br />
          <p style={{ textAlign: "center" }}>เพื่อเริ่มใช้งาน</p>
        </Header>
      </Grid.Row>
      <Grid.Row>
        <Button
          onClick={() => {
            handleGotoForm();
          }}
        >
          เริ่มทำแบบประเมิน
        </Button>
      </Grid.Row>
    </Grid>
  );
};

// ======================================= DIAG RULE SEARCH ======================================= //

export const DiagRuleSearch = injectIntl((props) => {

  const isMounted = useRef(true);
  const inputRef = useRef();
  const history = useHistory();
  const [diagRuleList, setDiagRuleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  React.useEffect(() => {
    if(!props.apiToken && !Cookies.get("apiToken")){
      if(props.noApiToken){
        props.noApiToken()
      }
    }
  }, [])

  const loadDiagRuleList = async () => {
    let apiToken = props.apiToken ? props.apiToken : Cookies.get("apiToken")
    setIsLoading(true)
    const [data, error] = await props.controller.getDiagRuleList(apiToken);
    if (!isMounted.current) {
      return;
    }
    setIsLoading(false)
    if(data){
      if (data & inputRef.current.inputRef.current.value !== "") {
        // setDiagRuleList(data.items)
        setDiagRuleList(data.items.filter((item) => item.division.id !== 11037));
      }
    }
  };

  const searchDiagRuleList = async () => {
    let apiToken = props.apiToken ? props.apiToken : Cookies.get("apiToken")
    let search = inputRef.current.inputRef.current.value;
    setIsLoading(true)
    const [data, error] = await props.controller.searchDiagRuleList(apiToken, search);
    if (!isMounted.current) {
      return;
    }
    setIsLoading(false)
    if(data){
      if (data && search !== "") {
        // setDiagRuleList(data.items)
        setDiagRuleList(data.items.filter((item) => item.division.id !== 11037));
      }
    }
  };

  const createDiagRule = () => {
    return diagRuleList.map((item, index) => (
      <List.Item
        key={index}
        className="diag-rule-list-item"
        onClick={handleDiagRuleSelected(item)}
      >
        <Grid>
          <Grid.Column width={11}>
            <p>{item.name}</p>
          </Grid.Column>
          <Grid.Column width={5}>
            <Label
              size={"tiny"}
            >
              <Icon name={"map marker alternate"} color={"red"} />
              {item.division ? item.division.name : "No Location"}
            </Label>
          </Grid.Column>
        </Grid>
      </List.Item>
    ));
  };

  const handleDiagRuleSelected = (item) => (event, data) => {
    props.onSeleted(item);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      // GOTO Search
      searchDiagRuleList();
    }
  };

  useEffect(() => {
    if (props.controller) {
      loadDiagRuleList();
    }
  }, [props.controller]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div className="diag-rule-search">
    <div className="title"><FormattedMessage id="form.type_hospital_for_search" /></div>
      <Input
        ref={inputRef}
        fluid
        icon={"search"}
        iconPosition={"left"}
        placeholder={props.intl.formatMessage({id: "form.form_hospital"})}
        onKeyDown={handleInputKeyPress}
        loading={isLoading}
      />
      <List celled selection verticalAlign={"middle"}>
        <List.Item
          key={99999}
          className="diag-rule-list-title"
        >
          <Grid>
            <Grid.Column width={10}> 
              <p className="form-title">แบบฟอร์ม</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <p className="account-title">
                Official Account
              </p>
            </Grid.Column>
          </Grid>
        </List.Item>
        {createDiagRule()}
      </List>
    </div>
  );
});
