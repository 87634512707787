import React, { useMemo, useState, useEffect, SyntheticEvent } from "react";

import moment from "moment";

// UX
import ModStockAdjustDataUX from "./ModStockAdjustDataUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  BUTTON_ACTIONS,
  RunSequence,
  ProductStockSerializer,
  StockReconcileSerializer,
  PermissionsType,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";
import { formatDate, parseDate } from "react-lib/utils/dateUtils";
import { CARD_STOCK_MANAGEMENT_TAB_LOT } from "./CardStockManagementTabLot";
import { useIntl } from "react-intl";

type ModStockAdjustDataProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // data
  data: Partial<ProductStockSerializer>;
  stockReconcileList?: StockReconcileSerializer[];
  permissions?: PermissionsType;
  isCounting?: boolean;
  // options
  stockReconcileNoteOptions?: any[];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose?: () => any;
};

type AdjustDataType = {
  lot_no: string;
  expire_date?: moment.Moment;
  balance: string | number;
};

const MOD_STOCK_ADJUST_DATA = "ModStockAdjustData";

const ModStockAdjustData = (props: ModStockAdjustDataProps) => {
  const intl = useIntl();
  const [detail, setDetail] = useState<Partial<AdjustDataType>>({});
  const [notation, setNotation] = useState<string>("");
  const [notationOptions, setNotationOptions] = useState<any[]>([]);

  // Effect
  useEffect(() => {
    setDetail({
      lot_no: props.data.lot?.mfc_no,
      expire_date: props.data.lot?.exp_datetime
        ? moment(props.data.lot?.exp_datetime)
        : undefined,
      balance: props.data.quantity,
    });
    setNotation("");
  }, [props.data]);

  useEffect(() => {
    setNotationOptions(props.stockReconcileNoteOptions || []);
  }, [props.stockReconcileNoteOptions]);

  // Use memo
  const titleName = useMemo(() => {
    return `[${props.data?.product?.code || ""}]-${
      props.data?.product?.drug_name_from_format || props.data?.product?.name || ""
    }`;
  }, [props.data]);

  const finishDate = useMemo(() => {
    const datetime = props.stockReconcileList?.slice(-1)?.[0]?.start_datetime;
    return datetime
      ? `${formatDate(moment(datetime))} [${moment(datetime).format("HH:mm")}]`
      : "";
  }, [props.stockReconcileList]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    if (!props.permissions?.ADJUST_DATA_EDIT) {
      return;
    }

    let value = data.value;

    if (data.name === "quantity") {
      value = Number(value) < 0 ? 0 : value;
    } else if (data.name === "expire_date") {
      value = parseDate(value, true);
    }

    setDetail({
      ...detail,
      [data.name]: value,
    });
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleChangeNotation = (e: any, data: any) => {
    if (!props.permissions?.ADJUST_DATA_EDIT) {
      return;
    }

    setNotation(data.value);
  };

  const handleAddNotationOptions = (e: SyntheticEvent, v: any) => {
    setNotationOptions([
      ...notationOptions,
      { key: v.value, text: v.value, value: v.value },
    ]);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "SAVE_ADJUST_DATA",
      data: props.data,
      note: notation,
      isCounting: props.isCounting,
      interQuantity: detail.balance,
      expireDate: detail.expire_date,
      card: MOD_STOCK_ADJUST_DATA,
      errorKey: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.SAVE,
      onSuccess: handleSuccess,
    });
  };

  const handleSuccess = (isRefresh: boolean) => {
    if (isRefresh) {
      props.runSequence({
        sequence: "StockManagement",
        action: "SEARCH_LOT",
        card: CARD_STOCK_MANAGEMENT_TAB_LOT,
        errorKey: CARD_STOCK_MANAGEMENT,
        btnAction: BUTTON_ACTIONS.SAVE,
      });
    }

    props.onClose?.();
  };

  return (
    <div>
      <ModStockAdjustDataUX
        // data
        name={titleName}
        lotNo={detail.lot_no}
        balance={detail.balance}
        expireDate={formatDate(detail.expire_date)}
        editedDate={finishDate}
        notation={notation}
        storage={props.data.storage?.name}
        binLocation={props.data.bin_location}
        isCounting={props.isCounting}
        // options
        notationOptions={notationOptions}
        // callback
        onClose={props.onClose}
        onChangeValue={handleChangeValue}
        onChangeDate={handleChangeDate}
        onChangeNotation={handleChangeNotation}
        onAddItem={handleAddNotationOptions}
        onCancel={props.onClose}
        // Element
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${MOD_STOCK_ADJUST_DATA}_${BUTTON_ACTIONS.SAVE}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${MOD_STOCK_ADJUST_DATA}_${BUTTON_ACTIONS.SAVE}`
              ]
            }
            // config
            disabled={!notation || !props.permissions?.ADJUST_DATA_EDIT}
            color={"blue"}
            name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title={intl.formatMessage({ id: "บันทึก" })}
            style={{ width: "130px", margin: "0 2rem" }}
          />
        }
        languageUX={props.languageUX}
      />
    </div>
  );
};

ModStockAdjustData.displayName = "ModStockAdjustData";

export default React.memo(ModStockAdjustData);
