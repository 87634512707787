import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  Button,
  FormField,
  Input,
  Dropdown
} from 'semantic-ui-react'

const ModDrugDispensingOrderUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        className="ui form"
        style={{width: "100%"}}>
        <div
          style={{ padding: "14px 10px", fontWeight: "bold", color: "white", backgroundColor: "#5dbcd2", display: "flex", alignItems: "center", justifyContent: "space-between",borderTopRightRadius: "0.28571429rem", borderTopLeftRadius: "0.28571429rem"  }}>
          
          <div>
            ยาแบ่งจ่าย
          </div>
          <div>
            
          </div>
        </div>
        <div
          style={{margin: "20px"}}>
          
          <div
            style={{fontWeight: "bold"}}>
            {props.fullName|| "-"}
          </div>
        </div>
        <FormGroup
          style={{margin: "10px 15px"}}>
          <FormField
            className="required"
            width={4}>
            <label>
              Dose
            </label>
            <Input
              fluid={true}
              name="dose"
              onChange={props.onChangeValue}
              onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
              type="number"
              value={props.drugDetail?.dose ?? ""}>
            </Input>
          </FormField>
          <FormField
            className="required"
            width={4}>
            <label>
              Unit
            </label>
            <Dropdown
              disabled={true}
              fluid={true}
              name="unit"
              onChange={props.onChangeValue}
              options={props.unitOptions}
              search={true}
              selection={true}
              value={props.drugDetail?.unit ?? ""}>
            </Dropdown>
          </FormField>
          <FormField
            className="required"
            width={4}>
            <label>
              Route
            </label>
            <Dropdown
              className="inline-label"
              fluid={true}
              name="route"
              onChange={props.onChangeValue}
              options={props.routeOptions}
              search={true}
              selection={true}
              value={props.drugDetail?.route ?? ""}>
            </Dropdown>
          </FormField>
          <FormField
            className="required"
            width={4}>
            <label>
              Frequency
            </label>
            <Dropdown
              className="inline-label"
              fluid={true}
              name="frequency"
              onChange={props.onChangeValue}
              options={props.frequencyOptions}
              search={true}
              selection={true}
              value={props.drugDetail?.frequency ?? ""}>
            </Dropdown>
          </FormField>
        </FormGroup>
        <div
          style={{display: "flex", justifyContent: "center", margin:"20px"}}>
          
          <Button
            color="green"
            disabled={!props.allowSave}
            onClick={props.onClickSave}
            size="small"
            style={{width: "10rem"}}>
            SAVE
          </Button>
          <Button
            color="red"
            onClick={props.onClickCancel}
            size="small"
            style={{width: "10rem", marginLeft: "0.5rem"}}>
            CANCEL
          </Button>
        </div>
      </div>
    )
}


export default ModDrugDispensingOrderUX

export const screenPropsDefault = {"adrDetail":"รายละเอียด","adrType":"LOCKED","needNote":true}

/* Date Time : Wed Mar 20 2024 13:34:22 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"14px 10px\", fontWeight: \"bold\", color: \"white\", backgroundColor: \"#5dbcd2\", display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\",borderTopRightRadius: \"0.28571429rem\", borderTopLeftRadius: \"0.28571429rem\"  }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"20px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "ยาแบ่งจ่าย"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.fullName|| \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormGroup",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px 15px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", margin:\"20px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Button",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "!props.allowSave"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickSave"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"10rem\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Button",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickCancel"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"10rem\", marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 34,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 34,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 34,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 34,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Dose"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "Route"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Input",
      "parent": 38,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "dose"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.drugDetail?.dose ?? \"\""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Dropdown",
      "parent": 39,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.unitOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.drugDetail?.unit ?? \"\""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Dropdown",
      "parent": 40,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "route"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.routeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.drugDetail?.route ?? \"\""
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Dropdown",
      "parent": 41,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "frequency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.frequencyOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.drugDetail?.frequency ?? \"\""
        }
      },
      "seq": 49,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 35,
  "isMounted": true,
  "memo": false,
  "name": "ModDrugDispensingOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "adrDetail": "รายละเอียด",
    "adrType": "LOCKED",
    "needNote": true
  },
  "width": 25
}

*********************************************************************************** */
