import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Dropdown,
  Checkbox,
  FormGroup,
  FormField,
  Icon
} from 'semantic-ui-react'

const CardDrugOrderQueueUX = (props: any) => {
    return(
      <div
        id="CardDrugOrderQueue"
        style={{ width: "100%",padding: "5px" }}>
        <div
          style={{display: "flex", padding: "5px", margin: "0.5rem 0 1rem", justifyContent: "space-between", alignItems: "center",}}>
          
          <div
            style={{ fontSize: "1.5rem", fontWeight: "bold",  }}>
            {props.isVerbal ? "รายการรับคำสั่งแพทย์" : "รายการใบยา"}
          </div>
          <div
            style={{ display: "flex" }}>
            
            <div
              style={{ display: props.showDrugOrderPendingList ? "flex" : "none" }}>
              {props.refillPlaned}
            </div>
            <div
              style={{ marginLeft: "20px" }}>
              {props.countdownTimer}
            </div>
          </div>
        </div>
        <div
          className="ui form"
          style={{display: props.hidePanel ? "none" : ""}}>
          
          <FormGroup>
            <FormField
              className=" --readOnly --grey"
              width={3}>
              <FormField
                style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <label>
                  Patient
                </label>
                <div>
                  {props.clearFilterPatient}
                </div>
              </FormField>
              <div
                style={{width: "100%"}}>
                {props.patientSearch}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <Checkbox
                checked={props.checkedOPD}
                label="OPD"
                onChange={props.changeSetDrugOrderCheck("checkedOPD")}
                style={{height: "100%", display: "flex", alignItems: "center", marginTop: "-0.75rem"}}>
              </Checkbox>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <Checkbox
                checked={props.checkedIPD}
                label="IPD"
                onChange={props.changeSetDrugOrderCheck("checkedIPD")}
                style={{height: "100%", display: "flex", alignItems: "center", marginTop: "-0.75rem"}}>
              </Checkbox>
            </FormField>
            <FormField
              style={{ minWidth: "max-content"}}>
              <label>
                {"\u00a0"}
              </label>
              <Checkbox
                checked={props.checkedContinue}
                label="Continue for Day dose"
                onChange={props.changeSetDrugOrderCheck("checkedContinue")}
                style={{height: "100%", display: "flex", alignItems: "center", marginTop: "-0.75rem", minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              width={3}>
              <label>
                Division/Ward
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeSetDrugOrder("selectedDivision")}
                options={props.divisionOptions}
                search={true}
                selection={true}
                style={{minWidth:"100%", width: 0}}
                value={props.selectedDivision}>
              </Dropdown>
            </FormField>
            <FormField
              width={3}>
              <label>
                Created date
              </label>
              <div
                style={{minWidth:"100%", width: 0}}>
                {props.StartDate}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <label
                style={{height: "100%", display: "flex", alignItems: "center", marginTop: "-0.75rem"}}>
                to
              </label>
            </FormField>
            <FormField
              width={3}>
              <label>
                {"\u00a0"}
              </label>
              <div
                style={{minWidth:"100%", width: 0}}>
                {props.EndDate}
              </div>
            </FormField>
            <FormField
              width="3">
              <label>
                Approve order 
              </label>
              <Dropdown
                fluid={true}
                onChange={props.changeSetDrugOrder("selectedApprove")}
                options={props.approveOption}
                search={true}
                selection={true}
                style={{minWidth:"100%", width: 0}}
                value={props.selectedApprove}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={2}>
              <label>
                Location
              </label>
              <Dropdown
                fluid={true}
                onChange={props.changeSetDrugOrder("selectedLocation")}
                options={props.divisionTypeDrugOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedLocation}>
              </Dropdown>
            </FormField>
            <FormField
              width={props.hideSort ? 6: 5}>
              <div
                style={{display:"flex", marginBottom: ".28571429rem"}}>
                
                <label
                  style={{fontWeight: 700, color: "rgba(0,0,0,.87)", fontSize: ".92857143em"}}>
                  Order Status
                </label>
                <div
                  style={{marginLeft: "0.5rem"}}>
                  
                  <Icon
                    link={true}
                    name="undo"
                    onClick={props.onResetFilter("selectedDrugOrderStatus")}>
                  </Icon>
                </div>
              </div>
              <div
                id="cb-medOrderStatus"
                style={{width: "100%"}}>
                {props.OrderStatus}
              </div>
            </FormField>
            <FormField
              width={props.hideSort ? 8 : 7}>
              <div
                style={{display:"flex", marginBottom: ".28571429rem"}}>
                
                <label
                  style={{fontWeight: 700, color: "rgba(0,0,0,.87)", fontSize: ".92857143em"}}>
                  Order Type
                </label>
                <div
                  style={{marginLeft: "0.5rem"}}>
                  
                  <Icon
                    link={true}
                    name="undo"
                    onClick={props.onResetFilter("selectedDrugOrderType")}>
                  </Icon>
                </div>
              </div>
              <div
                id="cb-medOrderType"
                style={{width: "100%"}}>
                {props.OrderType}
              </div>
            </FormField>
            <FormField
              width={1}>
              <label>
                {"\u00a0"}
              </label>
              <div
                style={{marginTop: "6px"}}>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField
              style={{ display: props.hideSort ? "none" : "" }}>
              <label>
                {"\u00a0"}
              </label>
              <div
                style={{width: "100%", marginTop: "0.45rem", fontSize: "0.95rem"}}>
                {props.dropdownSort}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display: props.hidePanel ? "none" : "none", alignItems: "center"}}>
          
          <label
            style={{marginRight: "10px", width: "80px",textAlign: "right"}}>
            Patient
          </label>
          <div
            style={{marginRight: "5px", width: "230px"}}>
            {props.patientSearch}
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "5px", width: "100px",textAlign: "right"}}>
              Division/Ward
            </div>
            <Dropdown
              clearable={true}
              onChange={props.changeSetDrugOrder("selectedDivision")}
              options={props.divisionOptions}
              search={true}
              selection={true}
              value={props.selectedDivision}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "5px", width: "100px",textAlign: "right"}}>
              Order Type
            </div>
            <Dropdown
              clearable={true}
              multiple={true}
              onChange={props.changeSetDrugOrder("selectedDrugOrderType")}
              options={props.drugOrderType}
              search={true}
              selection={true}
              value={props.selectedDrugOrderType}>
            </Dropdown>
          </div>
          <div
            style={{flex: 1}}>
            
          </div>
          <Button
            color="green"
            size="tiny">
            พิมพ์ใบจัดยา
          </Button>
          <Button
            color="green"
            size="tiny">
            พิมพ์ใบเตรียมจัดส่งยา
          </Button>
        </div>
        <div
          style={{display: props.hidePanel ? "none" : "none", alignItems: "center", padding: "10px 0px"}}>
          
          <div
            style={{marginRight: "5px", width: "80px",textAlign: "right"}}>
            Order Status
          </div>
          <Dropdown
            clearable={true}
            multiple={true}
            onChange={props.changeSetDrugOrder("selectedDrugOrderStatus")}
            options={props.orderStatusOptions}
            search={true}
            selection={true}
            style={{width: "200px"}}
            value={props.selectedDrugOrderStatus}>
          </Dropdown>
          <div
            style={{margin: "0px 5px",width: "130px",textAlign: "right"}}>
            Shipper
          </div>
          <Dropdown
            clearable={true}
            onChange={props.changeSetDrugOrder("selectedShipper")}
            options={props.shipperOptions}
            search={true}
            selection={true}
            value={props.selectedShipper}>
          </Dropdown>
          <div
            style={{display: "flex", alignItems: "center", margin: "0px 10px"}}>
            
            <Checkbox
              checked={props.checkedOPD}
              onChange={props.changeSetDrugOrderCheck("checkedOPD")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              OPD
            </div>
            <Checkbox
              checked={props.checkedIPD}
              onChange={props.changeSetDrugOrderCheck("checkedIPD")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              IPD
            </div>
          </div>
          <Button
            color="blue"
            loading={props.searching}
            onClick={props.onSearch}
            size="tiny">
            ค้นหา
          </Button>
        </div>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          data={props.drugOrderQueue}
          getTrProps={props.rowProps}
          headers={props.drugOrderQueueHeaders ? props.drugOrderQueueHeaders : ",Order No.,Status,Encounter Type,Order Type,HN,Patient name,Order by,Department,Order date/time,Shipper,Tracking No."}
          id="tb-medOrderQueueList"
          keys={`${props.hidePanel? "": "checkbox,"}code,status,encounter_type,type,hn,patientName, order_by_name, order_div_name,requested,prescription,shipping_company,tracking_number`}
          minRows={6}
          showPagination={false}
          style={{ height: props?.height || "80vh", display: props.isVerbal ? "none" : "flex", alignItems: "center", marginTop: "1rem"}}
          widths={`${props.hidePanel? "": "^40,"}^95,^95,^60,^90,^80,^130,^130,^120,^110,^70,^70`}>
        </Table>
        <div>
          {props.searchVerbal}
        </div>
        <Table
          data={props.verbalOrderQueue}
          getTrProps={props.verbalRowProps}
          headers="ชื่อนามสกุล,เลขที่,division,ประเภท,VN/AN,วันเวลา"
          keys="patient_name,drug_order_code,division,drug_order_type,en,edited"
          showPagination={false}
          style={{height: props?.height || "80vh", display: props.isVerbal ? "flex":"none", alignItems: "center"}}>
        </Table>
      </div>
    )
}


export default CardDrugOrderQueueUX

export const screenPropsDefault = {"height":"40vh","patientSearch":"[Patient Search Box]"}

/* Date Time : Wed Nov 06 2024 12:44:21 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDrugOrderQueue"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\",padding: \"5px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 8,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.drugOrderQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.drugOrderQueueHeaders ? props.drugOrderQueueHeaders : \",Order No.,Status,Encounter Type,Order Type,HN,Patient name,Order by,Department,Order date/time,Shipper,Tracking No.\""
        },
        "id": {
          "type": "value",
          "value": "tb-medOrderQueueList"
        },
        "keys": {
          "type": "code",
          "value": "`${props.hidePanel? \"\": \"checkbox,\"}code,status,encounter_type,type,hn,patientName, order_by_name, order_div_name,requested,prescription,shipping_company,tracking_number`"
        },
        "minRows": {
          "type": "code",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: props?.height || \"80vh\", display: props.isVerbal ? \"none\" : \"flex\", alignItems: \"center\", marginTop: \"1rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "`${props.hidePanel? \"\": \"^40,\"}^95,^95,^60,^90,^80,^130,^130,^120,^110,^70,^70`"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"5px\", margin: \"0.5rem 0 1rem\", justifyContent: \"space-between\", alignItems: \"center\",}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isVerbal ? \"รายการรับคำสั่งแพทย์\" : \"รายการใบยา\""
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.5rem\", fontWeight: \"bold\",  }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบจัดยา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบเตรียมจัดส่งยา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"none\", alignItems: \"center\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"230px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Division/Ward"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"100px\",textAlign: \"right\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Dropdown",
      "parent": 17,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivision"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Type"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"100px\",textAlign: \"right\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedDrugOrderType\")"
        },
        "options": {
          "type": "code",
          "value": "props.drugOrderType"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugOrderType"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 31,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.verbalOrderQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.verbalRowProps"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อนามสกุล,เลขที่,division,ประเภท,VN/AN,วันเวลา"
        },
        "keys": {
          "type": "value",
          "value": "patient_name,drug_order_code,division,drug_order_type,en,edited"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props?.height || \"80vh\", display: props.isVerbal ? \"flex\":\"none\", alignItems: \"center\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", width: \"80px\",textAlign: \"right\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"none\", alignItems: \"center\", padding: \"10px 0px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Status"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"80px\",textAlign: \"right\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Shipper"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 5px\",width: \"130px\",textAlign: \"right\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedDrugOrderStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.orderStatusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugOrderStatus"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedShipper\")"
        },
        "options": {
          "type": "code",
          "value": "props.shipperOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedShipper"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"0px 10px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Checkbox",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedOPD\")"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "OPD"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Checkbox",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedIPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedIPD\")"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "IPD"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Button",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.searching"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 45,
      "props": {
        "className": {
          "type": "value",
          "value": " --readOnly --grey"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Checkbox",
      "parent": 49,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOPD"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedOPD\")"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-0.75rem\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Checkbox",
      "parent": 50,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedIPD"
        },
        "label": {
          "type": "value",
          "value": "IPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedIPD\")"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-0.75rem\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "to"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-0.75rem\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"100%\", width: 0}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"100%\", width: 0}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "props.hideSort ? 6: 5"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "code",
          "value": "props.OrderStatus"
        },
        "id": {
          "type": "value",
          "value": "cb-medOrderStatus"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "props.hideSort ? 8 : 7"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "code",
          "value": "props.OrderType"
        },
        "id": {
          "type": "value",
          "value": "cb-medOrderType"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Checkbox",
      "parent": 89,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedContinue"
        },
        "label": {
          "type": "value",
          "value": "Continue for Day dose"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedContinue\")"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-0.75rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Dropdown",
      "parent": 92,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedLocation\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionTypeDrugOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedLocation"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Dropdown",
      "parent": 95,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivision"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Dropdown",
      "parent": 119,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedApprove\")"
        },
        "options": {
          "type": "code",
          "value": "props.approveOption"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedApprove"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "Division/Ward"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "Approve order "
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", marginBottom: \".28571429rem\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Status"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: 700, color: \"rgba(0,0,0,.87)\", fontSize: \".92857143em\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Icon",
      "parent": 132,
      "props": {
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "undo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onResetFilter(\"selectedDrugOrderStatus\")"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", marginBottom: \".28571429rem\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: 700, color: \"rgba(0,0,0,.87)\", fontSize: \".92857143em\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "Icon",
      "parent": 136,
      "props": {
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "undo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onResetFilter(\"selectedDrugOrderType\")"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "Created date"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchVerbal"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"6px\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideSort ? \"none\" : \"\" }"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "label",
      "parent": 143,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 48,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "code",
          "value": "props.clearFilterPatient"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dropdownSort"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginTop: \"0.45rem\", fontSize: \"0.95rem\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "code",
          "value": "props.refillPlaned"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showDrugOrderPendingList ? \"flex\" : \"none\" }"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "code",
          "value": "props.countdownTimer"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"20px\" }"
        }
      },
      "seq": 154,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugOrderQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "height": "40vh",
    "patientSearch": "[Patient Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
