import BaseService from "./BaseService";
import { HRM } from "../../configs/apis";
import * as utils from "../utils";
class HRMService extends BaseService {
  /**
   * @param {object} {params} params from manager/time/EmployeeShiftScheduleManager
   */
  createCondition(params) {
    return this.client
      .post(HRM.SERVICE_SLOT, params)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * get service-slot data
   * @param {object} params
   */
  getServiceSlotData(params) {
    return this.client
      .get(HRM.SERVICE_SLOT, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * get Summary service-slot
   * @param {object} params
   */
  getSummaryServiceSlot(params) {
    return this.client
      .get(HRM.SERVICE_SLOT + "summary/", { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * create provider-assign
   * @param {object} params
   */
  createProviderAssign(params) {
    return this.client
      .post(HRM.PROVIDER_ASSIGN, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * edit provider-assign
   * @param {object} params
   */
  editProviderAssignNew(params) {
    return this.client
      .post(HRM.PROVIDER_ASSIGN_ACTION, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * edit provider-assign
   * @param {object} params
   */
  editProviderAssign(params, urlProviderAssignSlot) {
    const url = HRM.PROVIDER_ASSIGN + urlProviderAssignSlot + "/";
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * delete provider-assign
   * @param {object} params
   */
  deleteProviderAssign(params, storeProviderAssignSlotId) {
    const url = HRM.PROVIDER_ASSIGN + storeProviderAssignSlotId[0] + "/";
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * tag Incharge provider-assign
   * @param {object} params
   */
  tagInchargeProviderAssign(params, urlProviderAvailableSlotId) {
    const url =
      HRM.PROVIDER_ASSIGN +
      urlProviderAvailableSlotId[0].provider_assign_slot +
      "/";
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * tag OFF provider-assign
   * @param {object} params
   */
  tagOFFProviderAssign(params, urlProviderAvailableSlotId) {
    const url = HRM.PROVIDER + urlProviderAvailableSlotId + "/";
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * swap tag provider-assign
   * @param {object} params
   */
  tagSwapProvider(params) {
    const url = HRM.PROVIDER_ASSIGN_ACTION;
    return this.client
      .post(url, params)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * get Provider from search Employee
   * @param {object} params
   */
  getProvider(params) {
    return this.client
      .get(HRM.PROVIDER, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * get confirm shift schedule
   * @param {object} params
   */
  confirmShiftSchedule(params) {
    const url = HRM.PROVIDER_ASSIGN_ACTION;
    return this.client
      .post(url, params)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * get confirm shift schedule
   * @param {object} params
   */
  onCancelConfirmShiftSchedule(params) {
    const url = HRM.PROVIDER_ASSIGN_ACTION;
    return this.client
      .post(url, params)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * get result check monthly constraint
   * @param {object} params
   */
  getResultCheckMonthlyConstraintData(params) {
    return this.client
      .get(HRM.RESULT_CHECK_MONTHLY_CONSTRAINT, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * get swap shift request
   * @param {object} params
   */
  getSwapShiftRequest(params) {
    return this.client
      .get(HRM.SWAP_SHIFT_REQUEST, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  /**
   * do swap shift request action
   * @param {object} params
   */
  doSwapShiftRequestAction(params, swapShiftRequestId) {
    const url = HRM.SWAP_SHIFT_REQUEST_ACTION({
      swap_shift_request_id: swapShiftRequestId
    });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getProviderInfo({ apiToken, params, userId }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = HRM.PROVIDER_INFO({ user_id: userId });
    return this.client.get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
//   onExportExcel(params) {
//     const FileDownload = require('js-file-download');
//     return this.client.get(HRM.EXPORT_EXCEL, { params }, { responseType: 'arraybuffer' })
//       .then((response) => {
//         var blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
//         // window.navigator.msSaveOrOpenBlob(blob, "myExcel.xlsx");
//         FileDownload(blob, 'report.xls');
//         // var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//         // fileSaver.saveAs(blob, 'fixi.xlsx');
//       });
//     // utils.downloadFile(response))
//     // .catch(this.throwErrorMessage);
//   }
// }
export default HRMService;
