import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

// PDF
const PDF = "/static/pdf/taxDoctor/200360_attach1.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf";

const FormPND1 = async (data: any, number: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Load your custom font
    const fontUrl = Font; // Replace with your font URL
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const getDecimal = (value: number) => {
      let rounded = Math.floor(value);

      return (value - rounded || 0).toFixed(2)?.toLocaleString().slice(2, 4) || "";
    };

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const fontSize = 12;
    const textCoordinates = [
      // Tax ID
      { text: data?.officerId?.[0], x: 447, y: 565.5 },
      { text: data?.officerId?.[1], x: 464, y: 565.5 },
      { text: data?.officerId?.[2], x: 477, y: 565.5 },
      { text: data?.officerId?.[3], x: 490, y: 565.5 }, // Space after 3rd character
      { text: data?.officerId?.[4], x: 499, y: 565.5 },
      { text: data?.officerId?.[5], x: 518, y: 565.5 }, // Space after 5th character
      { text: data?.officerId?.[6], x: 529, y: 565.5 },
      { text: data?.officerId?.[7], x: 540, y: 565.5 },
      { text: data?.officerId?.[8], x: 553, y: 565.5 },
      { text: data?.officerId?.[9], x: 564, y: 565.5 },
      { text: data?.officerId?.[10], x: 583, y: 565.5 },
      { text: data?.officerId?.[11], x: 594, y: 565.5 }, // Space after 11th character
      { text: data?.officerId?.[12], x: 612, y: 565.5 },
      // Space after 13th character
      { text: data?.branch?.[0], x: 775, y: 537 },
      { text: data?.branch?.[1], x: 786, y: 537 },
      { text: data?.branch?.[2], x: 797, y: 537 },
      // Remaining fields

      { text: data?.fields?.[number]?.page_number?.toString(), x: 700, y: 480 },
      { text: data?.params?.page_count?.toString(), x: 770, y: 480 },
      {
        text:
          data?.fields?.[number]?.total >= 0
            ? Number(data?.fields?.[number]?.total?.toFixed(0))?.toLocaleString()
            : "",
        x:
          664 -
          customFont?.widthOfTextAtSize(
            (data?.fields?.[number]?.total || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 112,
      },
      {
        text:
          data?.fields?.[number]?.total >= 0
            ? data?.fields?.[number]?.total === 0
              ? "00"
              : getDecimal(Number(data?.fields?.[number]?.total))
            : "",
        x: 671,
        y: 112,
      },
      {
        text: data?.totalTax?.toString(),
        x: 767 - customFont.widthOfTextAtSize((data?.totalTax || 0)?.toString(), fontSize),
        y: 112,
      },
      {
        text: data?.totalTaxDecimal?.toString().slice(0, 2),
        x: 771,
        y: 112,
      },
      { text: data?.payerFullName, x: 650, y: 75 },
      { text: data?.position, x: 670, y: 55 },
      { text: data?.submitDate, x: 610, y: 40 },
      { text: data?.submitMonth, x: 670, y: 40 },
      { text: data?.submitYear, x: 730, y: 40 },
    ];

    for (const { text, x, y } of textCoordinates) {
      firstPage.drawText(text || "", {
        x,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
    }

    const checkSymbol = "\u2713"; // ✓ Check symbol

    const falseText = ""; // Empty text when isMoneyType is false

    // Adjust coordinates for each check symbol
    const isMoneyTypeTextCoordinates = [
      {
        text: data?.isMoneyType1 ? checkSymbol : falseText,
        x: 122,
        y: 517,
      },
      {
        text: data?.isMoneyType2 ? checkSymbol : falseText,
        x: 122,
        y: 500,
      },
      {
        text: data?.isMoneyType3 ? checkSymbol : falseText,
        x: 353,
        y: 517,
      },
      {
        text: data?.isMoneyType4 ? checkSymbol : falseText,
        x: 353,
        y: 500,
      },
      {
        text: data?.isMoneyType5 ? checkSymbol : falseText,
        x: 353,
        y: 483,
      },
    ];

    for (const { text, x, y } of isMoneyTypeTextCoordinates) {
      firstPage.drawText(text || "", {
        x,
        y,
        size: 12,
        font: zapFont,
        color: rgb(0, 0, 0),
      });
    }

    // Add earnings
    const earnings = data?.fields?.[number]?.items;
    const earningsFontSize = 12;
    const initialX = 80;
    const initialY = 415;
    const yStep = 38;

    for (let i = 0; i < earnings?.length; i++) {
      let earning = earnings[i];
      let x = initialX;
      let y = initialY - yStep * i;
      // const {
      //   no,
      //   earnerId,
      //   prefix,
      //   fullName,
      //   lastName,
      //   payDate,
      //   paidAmount,
      //   paidAmountDecimal,
      //   taxCost,
      //   taxCostDecimal,
      //   condition,
      // } = earning;

      // Draw earnerId character by character with custom spacing
      firstPage.drawText(`${earning?.seq || ""}`, {
        x: x,
        y: y - 5,
        size: earningsFontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      let currentX = x + 30;
      for (let j = 0; j < earning?.citizen_id?.length; j++) {
        const charSpacing = 7; // Adjust this value as needed to control the spacing between characters

        const char = earning?.citizen_id[j];
        // Adjust spacing for specific letters
        if (j === 1 || j === 5 || j === 10 || j === 12) {
          currentX += charSpacing; // Increase the X coordinate by charSpacing
        }
        firstPage.drawText(char, {
          x: currentX,
          y: y + 3,
          size: earningsFontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        currentX += customFont.widthOfTextAtSize(char, earningsFontSize) + charSpacing; // Update currentX with character width and spacing
      }

      // Draw other fields
      // firstPage.drawText(prefix, {
      //   x: x + 45,
      //   y: y - 15,
      //   size: earningsFontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });
      firstPage.drawText(`${earning?.first_name || ""}`, {
        x: x + 65,
        y: y - 15,
        size: earningsFontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(`${earning?.last_name || ""}`, {
        x: x + 230,
        y: y - 15,
        size: earningsFontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      // firstPage.drawText(payDate, {
      //   x: x + 400,
      //   y: y - 15,
      //   size: earningsFontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });
      firstPage.drawText(
        `${Number(earning?.net_income_price?.toFixed(0))?.toLocaleString() || ""}`,
        {
          x:
            664 -
            customFont?.widthOfTextAtSize(
              (earning?.net_income_price || 0)?.toFixed(0)?.toLocaleString(),
              fontSize
            ),
          y: y - 15,
          size: earningsFontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      );
      firstPage.drawText(
        earning?.net_income_price === 0 ? "00" : getDecimal(Number(earning?.net_income_price)),
        {
          x: 671,
          y: y - 15,
          size: earningsFontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      );
      // firstPage.drawText(taxCost?.toString(), {
      //   x: 767 - customFont.widthOfTextAtSize((taxCost || 0)?.toString(), fontSize),
      //   y: y - 15,
      //   size: earningsFontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });
      // firstPage.drawText(taxCostDecimal?.toString().slice(0, 2), {
      //   x: 771,
      //   y: y - 15,
      //   size: earningsFontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });
      // firstPage.drawText(condition, {
      //   x: x + 710,
      //   y: y - 15,
      //   size: earningsFontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });
    }

    // const modifiedPdfBytes = await pdfDoc.save();
    // const modifiedPdfUrl = URL.createObjectURL(
    //   new Blob([modifiedPdfBytes], { type: "application/pdf" })
    // );

    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfUrl = URL.createObjectURL(
      new Blob([modifiedPdfBytes], { type: "application/pdf" })
    );

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl };
  } catch (error) {
    console.error("Error modifying PDF:", error);

    return {};
  }
};

export default FormPND1;
