import React, { useState, useEffect, useMemo } from "react";

// UX
import CardCostCenterReceiptUX from "./CardCostCenterReceiptUX";

// Interface
import { State } from "./sequence/CostCenterReceipt";

// Util
import { Modal, Form, FormGroup, Header, ModalContent, Button } from "semantic-ui-react";

// Common
import { formatPrice } from "react-lib/apps/common/PureReactTable";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

// Props
type CardCostCenterReceiptProps = {
  onEvent: (e: any) => any;
  setProp: any;
  runSequence: any;
  // Sequence
  CostCenterReceiptSequence?: State["CostCenterReceiptSequence"];
  masterOptions?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
};

const CardCostCenterReceipt = (props: CardCostCenterReceiptProps) => {
  const intl = useIntl();
  console.log("CardCostCenterReceipt Props: ", props);

  // state
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  // useEffect
  useEffect(() => {
    props.runSequence({
      sequence: "CostCenterReceipt",
      restart: true,
    });
  }, []);

  // useMemo
  const getSearchNotification = useMemo(() => {
    if (props.buttonLoadCheck?.CostCenterReceipt_SEARCH_RECEIPT === "LOADING") {
      setOpenLoadingModal(true);
    } else {
      setOpenLoadingModal(false);
    }

    if (props.CostCenterReceiptSequence?.searchStatus === "ERROR") {
      setOpenErrorModal(true);
    }
    return null;
  }, [props.CostCenterReceiptSequence?.searchStatus, props.buttonLoadCheck]);

  const handleInputReceipt = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setProp(`CostCenterReceiptSequence.searchInput`, e.target.value);
  };

  const searchReceipt = () => {
    props.setProp(`CostCenterReceiptSequence.searchStatus`, null);

    props.runSequence({
      sequence: "CostCenterReceipt",
      action: "SEARCH_RECEIPT",
    });
  };

  const handleCloseModal = () => {
    setOpenErrorModal(false);
    props.setProp(`CostCenterReceiptSequence.searchStatus`, null);
  };

  const handleFormatCurrency = (value: number) => {
    if (!value) {
      value = 0;
    }
    return value.toLocaleString("th-TH", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const mapKeys = (params: Record<string, any>) => {
    return {
      cash: params?.เงินสด,
      transfer_qr: params?.เงินโอน,
      credit_card: params?.[`บัตรเดบิต/เครดิต`],
    };
  };

  const mappedParams = props.CostCenterReceiptSequence?.searchResult?.params
    ? mapKeys(props.CostCenterReceiptSequence?.searchResult?.params)
    : {};

  console.log("CostCenter Result: ", props.CostCenterReceiptSequence?.searchResult);

  return (
    <>
      <CardCostCenterReceiptUX
        onHandleInputReceipt={handleInputReceipt}
        onSearchReceipt={searchReceipt}
        searchNotification={getSearchNotification}
        // Props
        patientName={props.CostCenterReceiptSequence?.searchResult?.params?.patient_name || ""}
        issuedDate={props.CostCenterReceiptSequence?.searchResult?.params?.issued_date || ""}
        financialOfficer={
          props.CostCenterReceiptSequence?.searchResult?.params?.financial_officer || ""
        }
        receiptStatus={props.CostCenterReceiptSequence?.searchResult?.params?.status || ""}
        priceValue={handleFormatCurrency(
          props.CostCenterReceiptSequence?.searchResult?.params?.price
        )}
        // cashValue={handleFormatCurrency(props.CostCenterReceiptSequence?.searchResult?.params?.เงินสด)}
        // transferQrValue={handleFormatCurrency(props.CostCenterReceiptSequence?.searchResult?.params?.เงินโอน)}
        // creditCardValue={handleFormatCurrency(props.CostCenterReceiptSequence?.searchResult?.params?.บัตรเครดิต)}
        cashValue={handleFormatCurrency(mappedParams?.cash)}
        transferQrValue={handleFormatCurrency(mappedParams?.transfer_qr)}
        creditCardValue={handleFormatCurrency(mappedParams?.credit_card)}
        dataTable={props.CostCenterReceiptSequence?.searchResult?.fields.map((item: any) => ({
          ...item,
          value_format: formatPrice(item.value),
        }))}
        // Button
        searchButtonLoadCheck={
          <ButtonLoadCheck
            size="medium"
            setProp={props.setProp}
            onClick={searchReceipt}
            paramKey={`CostCenterReceipt_SEARCH_RECEIPT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`CostCenterReceipt_SEARCH_RECEIPT`]}
            color="blue"
            title={intl.formatMessage({ id: "ค้นหา" })}
          />
        }
        languageUX={props.languageUX}
      />

      <Modal open={openErrorModal} size="mini">
        <Header style={{ background: "red", color: "white" }}>{intl.formatMessage({ id: "แจ้งเตือน" })}</Header>
        <ModalContent style={{ padding: "1rem" }}>
          <Form>
            <FormGroup
              style={{ display: "flex", justifyContent: "center", padding: "1em 0em 1em 0em" }}
            >
              <label>{intl.formatMessage({ id: "ไม่พบข้อมูล กรุณาตรวจสอบเลขที่ใบเสร็จ" })}</label>
            </FormGroup>
            <FormGroup style={{ display: "flex", justifyContent: "center" }}>
              <Button basic color="red" onClick={handleCloseModal}>
                ปิด
              </Button>
            </FormGroup>
          </Form>
        </ModalContent>
      </Modal>

      <Modal open={openLoadingModal} size="mini">
        <Header style={{ background: "blue", color: "white" }}>{intl.formatMessage({ id: "แจ้งเตือน" })}</Header>
        <ModalContent style={{ padding: "1rem" }}>
          <Form>
            <FormGroup
              style={{ display: "flex", justifyContent: "center", padding: "1em 0em 1em 0em" }}
            >
              <label>{intl.formatMessage({ id: "ระบบกำลังค้นหาข้อมูล กรุณารอสักครู่" })}</label>
            </FormGroup>
            <FormGroup style={{ display: "flex", justifyContent: "center" }}></FormGroup>
          </Form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CardCostCenterReceipt;
