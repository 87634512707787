// Utils
import moment from "moment";
import { formatDatetime } from "react-lib/utils/dateUtils";
import { HeaderLogoWithPublishDetail } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";
import { TDocumentDefinitions } from "pdfmake/interfaces";

const FORM_NAME = `FormAnesMethodGenderStatReport`;

const FormAnesMethodGenderStatReport = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Yeti Anes Method & Gender Props: ", props);

  const currentDate = moment();

  const headerForm = await HeaderLogoWithPublishDetail({
    form: FORM_NAME,
    font: "THSarabunNew",
    logoHeight: 30,
    isHospitalVertical: true,
    haveBorder: true,
    pageMargins: [10, 85, 10, 10],
    headerMargins: [10, 10, 10, 0],
    titleContent: [
      {
        text: `จำนวนผู้รับบริการระงับความรู้สึก แบ่งตามเพศ และอายุ`,
        alignment: "center",
        fontSize: 15,
        bold: true,
      },
      {
        text: `วิธีระงับความรู้สึก ${props.params?.anesthesia_label || "ทั้งหมด"}`,
        alignment: "center",
        fontSize: 15,
      },
      {
        text: `วันที่ดมยา ${props.params?.startDate || " "} - ${props.params?.endDate || " "}`,
        alignment: "center",
        fontSize: 15,
      },
    ],
    publishContent: [
      {
        text: [
          { text: "ผู้พิมพ์: ", bold: true, preserveLeadingSpaces: true },
          { text: props.params?.print_user || " " },
        ],
        alignment: "right",
        fontSize: 12,
      },
      {
        text: [
          { text: "วันที่พิมพ์: ", bold: true, preserveLeadingSpaces: true },
          { text: formatDatetime(currentDate, true) },
        ],
        alignment: "right",
        fontSize: 12,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const filterData = (ageRange: string[], amount: number[]) => {
    return ageRange
      .map((age, index) => (age.length > 0 ? amount[index] : null))
      .filter((val) => val !== null);
  };

  const generateTableBody = (data: any, columns: string[]) => {
    let body = [];

    // Header of Table
    body.push([
      { text: "วิธีระงับความรู้สึก", bold: true, alignment: "center" },
      { text: "ช่วงอายุ (ปี)", bold: true, alignment: "center" },
      { text: "เพศชาย (คน)", bold: true, alignment: "center" },
      { text: "เพศหญิง (คน)", bold: true, alignment: "center" },
      { text: "รวมแต่ละช่วงอายุ", bold: true, alignment: "center" },
    ]);

    if (Object.keys(data).length > 0) {
      const fieldsData = data?.items;
      const summaryData = data?.summaryTotals;

      fieldsData.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          switch (column) {
            case "age_range":
              const filterAgeRange = row[column].filter((age: string) => age.length > 0).join("\n");
              dataRow.push({ text: filterAgeRange });
              break;
            case "amount_male":
              const filterAmountMale = filterData(row.age_range, row[column]).join("\n");
              dataRow.push({ text: filterAmountMale.toString(), alignment: "center" });
              break;
            case "amount_female":
              const filterAmountFemale = filterData(row.age_range, row[column]).join("\n");
              dataRow.push({ text: filterAmountFemale.toString(), alignment: "center" });
              break;
            case "amount_all":
              const filterAmountAll = filterData(row.age_range, row[column]).join("\n");
              dataRow.push({ text: filterAmountAll.toString(), alignment: "center" });
              break;
            default:
              dataRow.push({ text: row[column].toString(), bold: true });
              break;
          }
        });
        body.push(dataRow);
      });

      // Total Row
      if (summaryData) {
        body.push([
          {
            text: "จำนวนผู้รับบริการรวม",
            fillColor: "#cccccc",
            colSpan: 2,
            border: [true, true, false, true],
          },
          {},
          {
            text: `ชาย ${summaryData.total_male}`,
            fillColor: "#cccccc",
            bold: true,
            alignment: "center",
            border: [false, true, false, true],
          },
          {
            text: `หญิง ${summaryData.total_female}`,
            fillColor: "#cccccc",
            bold: true,
            alignment: "center",
            border: [false, true, false, true],
          },
          {
            text: `เท่ากับ ${summaryData.total_all}`,
            fillColor: "#cccccc",
            bold: true,
            alignment: "center",
            border: [false, true, true, true],
          },
        ]);
      }
    }

    return body;
  };

  const tableReport = (data: any, columns: string[]) => {
    return {
      table: {
        widths: ["*", "25%", "15%", "15%", "15%"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };

  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 16, // default
    },
    ...headerForm,
    styles: { ...styles },
    images: { ...images },
    content: [
      tableReport(props || {}, [
        "anesthesia_name",
        "age_range",
        "amount_male",
        "amount_female",
        "amount_all",
      ]),
    ],
  };
};

export default FormAnesMethodGenderStatReport;
