import React, { Component, useEffect, useRef, useState } from "react";
import { Segment, Button, Dimmer, Loader } from "semantic-ui-react";
import { useHistory, RouteComponentProps } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import * as Util from "react-lib/utils/tsUtils";
import { injectIntl } from "react-intl";
import { useIntl } from "react-intl";
interface RouterProps {
  id: string;
}

interface Props extends RouteComponentProps<RouterProps> {
  menuBar?: Component;
  controller: {
    getInvoice: ({
      apiToken,
      patientId,
      status
    }: {
      apiToken: string;
      patientId?: string | number;
      status?: string;
    }) => [null | { items: any[] }, null | {}, null | {}];
    getInvoiceDetail: ({
      apiToken,
      invoiceId
    }: {
      apiToken: string;
      invoiceId?: string | number;
    }) => [null | { items: any[] }, null | {}, null | {}];
  };
  apiToken: string;
}

const MockData = {
  id: 1,
  total_price: "2,200",
  date: "15/07/63",
  time: "15 : 00"
};

const PatientPaymentDetail = React.forwardRef((props: Props, ref) => {
  const [invoiceDetail, setInvoiceDetail] = useState<{
    price?: string | number;
    edited?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [paymentRedirect, setPaymentRedirect] = useState({});
  const [invoiceItem, setInvoiceItem] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    if (props.match.params.id) {
      handleGetInvoiceDetail();
      handleGetInvoiceItem();
      handlePostPaymentParameters();
    }
    return () => (isMounted.current = false);
  }, [props.match.params.id]);

  const handlePostPaymentParameters = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.controller.postPaymentParameters({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      invoiceId: props.match.params.id
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setPaymentRedirect(res);
      } else {
        setPaymentRedirect({});
      }
    }
  };

  const handleGetInvoiceDetail = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.controller.getInvoiceDetail({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      invoiceId: props.match.params.id
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setInvoiceDetail(res);
      } else {
        setInvoiceDetail({});
      }
    }
  };

  const handleGetInvoiceItem = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.controller.getInvoiceItemByItem({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      invoiceId: props.match.params.id
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setInvoiceItem(res.items);
      } else {
        setInvoiceItem([]);
      }
    }
  };

  const date = invoiceDetail.edited
    ? Util.convertToBEDate({
        date: moment(invoiceDetail.edited).format("DD/MM/YYYY")
      })
    : "";
  const time = invoiceDetail.edited
    ? moment(invoiceDetail.edited).format("HH:mm")
    : "";

  const genPaymentForm = () => {
    return Object.keys(paymentRedirect).map(key => {
      if (key === "endpoint" || key === "method") {
        return null;
      }
      return <input key={key} type="hidden" name={key} value={paymentRedirect[key] ? paymentRedirect[key] : ""} />;
    });
  };

  const createInvoiceItem = () => {
    return invoiceItem.map(item => {
      return (
          <tr key={item.id} style={{ display: "flex", paddingTop: "3px", paddingBottom: "3px" }}>
            <td style={{flexGrow: 1}}>{`${item.pricing_name}`}</td>
            {/*<td style={{whiteSpace: "nowrap", marginLeft: "5px", marginRight: "10px"}}>{`${item.pricing_quantity} ${item.pricing_unit}`}</td>*/}
            <td style={{whiteSpace: "nowrap"}}>{`${Util.formatComma(item.price)} Baht`}</td>
          </tr>
      );
    });
  };
  console.log(invoiceDetail, "invoiceDetail")
  return (
    <div className="patient-payment-detail">
      {props.menuBar}
      <Dimmer.Dimmable>
        <Segment>
          <div className="text-content">{props.intl.formatMessage({ id: 'payment.total_balance' })}</div>
          <h1 className="text-content price">{`${
            invoiceDetail.price ? Util.formatComma(invoiceDetail.price) : ""
          } ${props.intl.formatMessage({ id: 'payment.baht' })}`}</h1>
          <div className="date-time-wrapper">
            <div className="text-content">
              {props.intl.formatMessage({ id: 'common.date' })} <br /> <h4>{date}</h4>
            </div>
            <div className="text-content">
              {props.intl.formatMessage({ id: 'common.time' })} <br /> <h4>{time}</h4>
            </div>
          </div>
        </Segment>
        <Segment>
          <table style={{lineHeight: 1.5, width: "100%"}}>
            {createInvoiceItem()}
            <tr style={{borderTop: "1px solid gray", display: "flex", paddingTop: "5px" }}>
              <td style={{flexGrow: 1}}>{props.intl.formatMessage({ id: 'common.total' })}</td>
              <td>{Util.formatComma(invoiceDetail.price)} {props.intl.formatMessage({ id: 'payment.baht' })}</td>
            </tr>
          </table>
        </Segment>
        {/* <Segment>
        <a>Download เอกสาร</a>
      </Segment> */}
        <Dimmer active={isLoading} inverted>
          <Loader>Loading...</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
      <form
        method={paymentRedirect["method"]}
        action={paymentRedirect["endpoint"]}
      >
        {genPaymentForm()}
        {invoiceDetail.status_name !== "CANCELED" && invoiceDetail.price && <Button
          type="submit"
          className="pay-btn"
          fluid
          content={invoiceDetail.status_name === "PAID" ? `${props.intl.formatMessage({ id: 'payment.view_receipt' })}` : `${props.intl.formatMessage({ id: 'payment.pay' })}`}
          disabled={isLoading}
        />}
      </form>
    </div>
  );
});

export default React.memo(injectIntl(PatientPaymentDetail, { forwardRef: true }));
