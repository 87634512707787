import React, { CSSProperties, ReactElement, useMemo } from "react";

// Framework
import { Table } from "react-lib/frameworks/Table";
import { useIntl } from "react-intl";

// Types
type TableUploadExcelProps = {
  data?: TableData[];
  displayError?: boolean;
  headers: string[];
  style?: CSSProperties;
};

type TableData = { errors?: string[] } & Record<string, any>;

type Styles = Record<"header", CSSProperties>;

// Constant
const styles: Styles = {
  header: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "3",
  },
};

const TableUploadExcel = (props: TableUploadExcelProps) => {
  const intl = useIntl();
  // UseMemo
  const tableHeaders = useMemo(() => {
    const headers: (string | ReactElement)[] = props.headers.map((value) => (
      <div key={`header${value}`} style={styles.header}>
        {value}
      </div>
    ));

    if (props.displayError) {
      headers.unshift("Error");
    }

    return headers;
  }, [props.displayError, props.headers]);

  const tableKeys = useMemo(() => {
    const keys = Object.values(props.headers);

    if (props.displayError) {
      keys.unshift("errors");
    }

    return keys;
  }, [props.displayError, props.headers]);

  const data = useMemo(
    () =>
      (props.data || []).map((item) => ({
        ...item,
        errors: item.errors?.map((error: string) => <div key={error}>- {error}</div>),
      })),
    [props.data]
  );

  const style = useMemo(
    () => ({
      ...props.style,
    }),
    [props.style]
  );

  return (
    <Table
      className="--rt-tbody-overflow-y-hidden"
      data={data}
      headers={tableHeaders}
      keys={tableKeys}
      minRows={12}
      showPagination={false}
      style={style}
      widths={props.displayError ? "200" : ""}
    />
  );
};

TableUploadExcel.displayName = "TableUploadExcel";

export default React.memo(TableUploadExcel);
