import WasmController from "react-lib/frameworks/WasmController";
import BillingQueueList from "issara-sdk/apis/BillingQueueList_apps_BIL";
import WaitDispenseDrugQueueList from "issara-sdk/apis/WaitDispenseDrugQueueList_apps_TPD";
import PreparingDrugQueueList from "issara-sdk/apis/PreparingDrugQueueList_apps_TPD";
import DoctorInQueueList from "issara-sdk/apis/DoctorInQueueList_core";
import DoctorReceiveResultQueueList from "issara-sdk/apis/DoctorReceiveResultQueueList_core";
import DoctorCancelInQueueList from "issara-sdk/apis/DoctorCancelInQueueList_core";

export type State = {
  queueBill?: {
    billList?: any;
    division?: string | null;
  };
  queueDrug?: {
    drugList?: any;
    division?: string | null;
  };
  queuePrepareDrug?: {
    prepareDrugList?: any;
    division?: string | null;
  };
  queueDoctor?: {
    doctorList?: any;
    labList?: any;
    cancelQueueList?: any;
    division?: number | null;
  };
};

export const StateInitial: State = {
  queueBill: {
    division: "",
    billList: [],
  },
  queueDrug: {
    division: "",
    drugList: [],
  },
};

export type Event =
  | { message: "QueueForPatient"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  masterData?: any;
  divisionDetail?: any;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const QueueForPatient: Handler = async (controller, params) => {
  var state = controller.getState();

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["division", {}]],
    },
  } as any);

  state = controller.getState();

  if (params.action === "queueBill") {
    const queueBill = await BillingQueueList.list({
      params: {
        bil_queue: true,
        limit: 25,
        billing_division: controller.data?.divisionDetail?.billing_div || 81,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data?.division || "",
        device: controller.data?.device || "",
      },
    });
    controller.setState({
      queueBill: {
        billList: queueBill?.[0]?.items || [],
        division: controller.data.divisionDetail?.billing_div || 81,
      },
    });
  } else if (params.action === "queueDrug") {
    const druugList = await WaitDispenseDrugQueueList.get({
      params: {
        order_perform_div: controller.data?.divisionDetail?.drug_div || 45,
        type: 1,
        limit: 25,
      },
      extra: {
        division: controller.data?.division || "",
        device: controller.data?.device || "",
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      queueDrug: {
        division: controller.data?.divisionDetail?.drug_div || 45,
        drugList: druugList?.[0] || [],
      },
    });
  } else if (params.action === "queuePrepareDrug") {
    const drugList = await PreparingDrugQueueList.get({
      params: {
        order_perform_div: controller.data?.divisionDetail?.drug_div || 45,
        type: 1,
        limit: 25,
      },
      extra: {
        division: controller.data?.division || "",
        device: controller.data?.device || "",
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      queuePrepareDrug: {
        division: controller.data?.divisionDetail?.drug_div || 45,
        prepareDrugList: drugList?.[0] || [],
      },
    });
  } else if (params.action === "queueDoctor") {
    const queueDoctor = await DoctorInQueueList.get({
      params: {
        division: controller.data?.division,
      },
      extra: {
        division: controller.data?.division || "",
        device: controller.data?.device || "",
      },
      apiToken: controller.apiToken,
    });

    const queueLab = await DoctorReceiveResultQueueList.get({
      params: {
        division: controller.data?.division,
      },
      extra: {
        division: controller.data?.division || "",
        device: controller.data?.device || "",
      },
      apiToken: controller.apiToken,
    });

    const queueCancel = await DoctorCancelInQueueList.get({
      params: {
        division: controller.data?.division,
      },
      extra: {
        division: controller.data?.division || "",
        device: controller.data?.device || "",
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      queueDoctor: {
        doctorList: queueDoctor?.[0] || [],
        labList: queueLab?.[0] || [],
        cancelQueueList: queueCancel?.[0] || [],
        division: controller.data?.division,
      },
    });
  }
};
