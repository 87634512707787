import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const CardReceiptGroupEditUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div
          className="ui form"
          style={{boxShadow: "none", padding: "20px"}}>
          
          <div>
            {props.errorMessage}
          </div>
          <div
            className="ui form small">
            {}
            <div
              style={{display: "flex", justifyContent: "space-between"}}>
              {props.label}
            </div>
            <div
              style={{ padding: "0px 0px 15px"}}>
              
              <hr>
              </hr>
            </div>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div>
                  จุดรับชำระเงิน (Device)
                </div>
              </FormField>
              <FormField
                inline={true}
                width="2">
                <div
                  style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                  {props.stationName?.name || "-"}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={2}>
                <div>
                  หมายเลขจุดรับเงิน
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                  {props.stationName?.number || "-"}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={2}>
                <div>
                  ชื่อจุดรับเงิน
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                  {props.stationName?.station_name || "-"}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div>
                  วันที่เบิกใบเสร็จ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ color: "red", fontSize: "18px" }}>
                  *
                </div>
                <div
                  style={{ width: "100%" }}>
                  
                  <DateTextBox
                    disabled={props.isEdit}
                    onChange={props.onChangeDateEdit}
                    value={props.selectData?.date}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{  width: "100%" }}>
                  
                  <TimeFreeTextBox24
                    className="time-custom-react-picker"
                    disabled={props.isEdit}
                    onChange={props.onChangeTimeEdit}
                    value={props.selectData?.time}>
                  </TimeFreeTextBox24>
                </div>
              </FormField>
              <FormField
                style={{ ...((!props.checkReceiptField.includes("date") && !props.checkReceiptField.includes("time")) && { display: "none" }) }}>
                <div
                  style={{ fontWeight: "bold", color: "red", }}>
                  จำเป็นต้องระบุ
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div>
                  เล่มที่
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ color: "red", fontSize: "18px" }}>
                  *
                </div>
                <div
                  style={{  width: "100%" }}>
                  
                  <Input
                    fluid={true}
                    name="prefix"
                    onChange={props.onChangeDataEdit}
                    value={props.selectData?.prefix}>
                  </Input>
                </div>
              </FormField>
              <FormField
                style={{ ...(!props.checkReceiptField.includes("prefix") && { display: "none" }) }}>
                <div
                  style={{ fontWeight: "bold", color: "red", }}>
                  จำเป็นต้องระบุ
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div>
                  เลขที่เริ่มต้น
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ color: "red", fontSize: "18px" }}>
                  *
                </div>
                <div
                  style={{  width: "100%" }}>
                  
                  <Input
                    fluid={true}
                    name="running_start"
                    onChange={props.onChangeDataEdit}
                    value={props.selectData?.running_start}>
                  </Input>
                </div>
              </FormField>
              <FormField
                style={{ ...(!props.checkReceiptField.includes("running_start") && { display: "none" }) }}>
                <div
                  style={{ fontWeight: "bold", color: "red", }}>
                  จำเป็นต้องระบุ
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div>
                  เลขที่สิ้นสุด
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ color: "red", fontSize: "18px" }}>
                  *
                </div>
                <div
                  style={{  width: "100%" }}>
                  
                  <Input
                    fluid={true}
                    name="running_end"
                    onChange={props.onChangeDataEdit}
                    value={props.selectData?.running_end}>
                  </Input>
                </div>
              </FormField>
              <FormField
                style={{ ...(!props.checkReceiptField.includes("running_end") && { display: "none" }) }}>
                <div
                  style={{ fontWeight: "bold", color: "red", }}>
                  จำเป็นต้องระบุ
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div>
                  เลขที่กำลังใช้งาน
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ color: "red", fontSize: "18px" }}>
                  *
                </div>
                <div
                  style={{  width: "100%" }}>
                  
                  <Input
                    fluid={true}
                    name="current_running"
                    onChange={props.onChangeDataEdit}
                    value={props.selectData?.current_running}>
                  </Input>
                </div>
              </FormField>
              <FormField
                style={{ ...(!props.checkReceiptField.includes("current_running") && { display: "none" }) }}>
                <div
                  style={{ fontWeight: "bold", color: "red", }}>
                  จำเป็นต้องระบุ
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div>
                  สถานะชุดใบเสร็จ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ color: "red", fontSize: "18px" }}>
                  *
                </div>
                <div
                  style={{  width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    name="status"
                    onChange={props.onChangeDataEdit}
                    options={props.receiptGroupStatus}
                    search={true}
                    selection={true}
                    value={props.selectData?.status}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                style={{ ...(!props.checkReceiptField.includes("status") && { display: "none" }) }}>
                <div
                  style={{ fontWeight: "bold", color: "red", }}>
                  จำเป็นต้องระบุ
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%" }}>
                  {props.buttonUpdate}
                </div>
              </FormField>
              <FormField
                inline={true}
                width="2">
                <Button
                  color="red"
                  fluid={true}
                  onClick={props.closeModReceiptEdit}
                  size="mini">
                  ปิดหน้าต่าง
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default CardReceiptGroupEditUX

export const screenPropsDefault = {}

/* Date Time : Mon Oct 21 2024 17:28:11 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", padding: \"20px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationName?.number || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเลขจุดรับเงิน"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationName?.name || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "จุดรับชำระเงิน (Device)"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationName?.station_name || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อจุดรับเงิน"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0px 15px\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "hr",
      "parent": 82,
      "props": {
      },
      "seq": 83,
      "void": true
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.label"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 94,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormField",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 91,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 90,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 89,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 89,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 90,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 91,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 94,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 89,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เบิกใบเสร็จ"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "เล่มที่"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่เริ่มต้น"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่สิ้นสุด"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่กำลังใช้งาน"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะชุดใบเสร็จ"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\" }"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\" }"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 126,
      "name": "DateTextBox",
      "parent": 119,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.isEdit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateEdit"
        },
        "value": {
          "type": "code",
          "value": "props.selectData?.date"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 127,
      "name": "TimeFreeTextBox24",
      "parent": 120,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEdit"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTimeEdit"
        },
        "value": {
          "type": "code",
          "value": "props.selectData?.time"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Input",
      "parent": 121,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "prefix"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDataEdit"
        },
        "value": {
          "type": "code",
          "value": "props.selectData?.prefix"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Input",
      "parent": 122,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "running_start"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDataEdit"
        },
        "value": {
          "type": "code",
          "value": "props.selectData?.running_start"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Input",
      "parent": 123,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "running_end"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDataEdit"
        },
        "value": {
          "type": "code",
          "value": "props.selectData?.running_end"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Input",
      "parent": 124,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "current_running"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDataEdit"
        },
        "value": {
          "type": "code",
          "value": "props.selectData?.current_running"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Dropdown",
      "parent": 125,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDataEdit"
        },
        "options": {
          "type": "code",
          "value": "props.receiptGroupStatus"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectData?.status"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Button",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": "ปิดหน้าต่าง"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.closeModReceiptEdit"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUpdate"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", fontSize: \"18px\" }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", fontSize: \"18px\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", fontSize: \"18px\" }"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", fontSize: \"18px\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", fontSize: \"18px\" }"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", fontSize: \"18px\" }"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormField",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...(!props.checkReceiptField.includes(\"status\") && { display: \"none\" }) }"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 93,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...(!props.checkReceiptField.includes(\"current_running\") && { display: \"none\" }) }"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 92,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...(!props.checkReceiptField.includes(\"running_end\") && { display: \"none\" }) }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 91,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...(!props.checkReceiptField.includes(\"running_start\") && { display: \"none\" }) }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormField",
      "parent": 90,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...(!props.checkReceiptField.includes(\"prefix\") && { display: \"none\" }) }"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 89,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...((!props.checkReceiptField.includes(\"date\") && !props.checkReceiptField.includes(\"time\")) && { display: \"none\" }) }"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": "จำเป็นต้องระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", color: \"red\", }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "จำเป็นต้องระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", color: \"red\", }"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "จำเป็นต้องระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", color: \"red\", }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "จำเป็นต้องระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", color: \"red\", }"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": "จำเป็นต้องระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", color: \"red\", }"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "จำเป็นต้องระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", color: \"red\", }"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        }
      },
      "seq": 2,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardReceiptGroupEditUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
