const AnnotateContent = (content_dict) => {
  const getSortedContent = (output, content, node) => {
    output.push(node)
    for (var i=0; i < content.length; i++) {
      if (content[i].parent_id === node.id) {
        output = getSortedContent(output, content, content[i])
      }
    }
    return output
  }
  const setLevel = (content, node, level) => {
    node.level = level
    var last = 0
    for (var i=0; i < content.length; i++) {
      if (content[i].parent_id === node.id) {
        setLevel(content, content[i], level + 1)
        last = i
      }
    }
    if (last !== 0) {
      node.span = last - content.indexOf(node)
    } else {
      node.span = 0
    }
  }
  var content = Object.values(content_dict)
  content = getSortedContent([], content, content[0])
  setLevel(content, content[0], 0)
  return content
}

export default AnnotateContent