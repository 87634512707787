const styles = {
  header: {
    color: "#01B2AC",
    fontSize: "36px",
  },
  negative_header: {
    color: "#FF2929",
    fontSize: "36px",
  },
  info_header: {
    color: "#76cfe3",
    fontSize: "36px",
  },
  divider: {
    border: "1px solid #01B2AC",
  },
  basicButton: {
    border: "2px solid",
    fontWeight: "bold",
    borderRadius: 20,
  },
  basicSmallButton:{
    border: "1px solid",
    borderRadius: 20,
  },
  basicButtonLarge: {
    fontWeight: "bold",
    borderRadius: 20,
  },
  basicCircularButton: {
    border: "2px solid",
    fontWeight: "bold",
  },
  tableBorder: {
    border: "2px solid #01B2AC"
  },
  tableCellBorder: {
    border: "1px solid #01B2AC"
  },
  darkgreen: {
    backgroundColor: "#00798A",
    color: "white"
  },
  lightGreen: {
    backgroundColor: "#72F6BD",
    color: "#00798A"
  },
  lightOrange:{
    backgroundColor: "#f2711c",
    color: "white"
  },
  mobileTitleBar: {
    color: "white",
    backgroundColor: "#5dbcd2",
  }
}

export default styles