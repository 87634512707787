import WasmController from "../../../../../src/react-lib/frameworks/WasmController";
import { State as MainState } from "../../../../../src/Main/MainSegmentInterface";

// APIs
import PatientSegmentDetail from "../../../../../src/issara-sdk/apis/PatientSegmentDetail_apps_PHR";
import PatientSegmentList from "../../../../../src/issara-sdk/apis/PatientSegmentList_apps_PHR";
import PatientSegmentNameList from "../../../../../src/issara-sdk/apis/PatientSegmentNameList_apps_PHR";
import SegmentGroupDetail from "../../../../../src/issara-sdk/apis/SegmentGroupDetail_apps_PHRM";
import SegmentGroupList from "../../../../../src/issara-sdk/apis/SegmentGroupList_apps_PHRM";

export type State = {
  SegmentSequence?: {
    sequenceIndex?: string | null;

    loadingSegmentList?: boolean;
    loadingSegmentPatient?: boolean;
    segmentList?: any;

    filterSegment?: {
      name?: string;
    };

    selectedSegment?: {
      id?: number | null;
      code?: string;
      name?: string;
      icon?: any;
      color_code?: string;
      display_seq?: number;
    } | null;

    addHNList?: any[];

    patientList?: any;

    // Table
    currentPage: number;
    limit: number;
    totalPage: number;

  } | null;
};

export const StateInitial: State = {
  SegmentSequence: {
    sequenceIndex: null,

    loadingSegmentList: false,
    loadingSegmentPatient: false,
    segmentList: {},

    filterSegment: {
      name: "",
    },

    selectedSegment: {
      id: null,
      code: "",
      name: "",
      icon: null,
      color_code: "",
      display_seq: 999,
    },

    addHNList: [],

    patientList: {},

    currentPage: 0,
    limit: 20,
    totalPage: 1

  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "successMessage"
    | "masterOptions"
    | "django"
  >
>;

export const DataInitial = {};

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

export const Start: Handler = async (controller, params) => {
  controller.setState(
    {
      SegmentSequence: {
        sequenceIndex: "Action",
        currentPage: 0,
        limit: 20,
        totalPage: 1

      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: { ...params } });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  // SEGMENT
  if (params?.action === "SEARCH_SEGMENT") {
    let state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });
    const [resp, err, network] = await SegmentGroupList.list({
      apiToken: controller.apiToken,
      params: state.SegmentSequence?.filterSegment,
    });

    state = controller.getState();

    if (err) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.btnKey]: err,
        },
        SegmentSequence: {
          ...state.SegmentSequence,
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        SegmentSequence: {
          ...state.SegmentSequence,
          segmentList: resp,
        },
      });
    }
  } else if (params?.action === "SAVE_SEGMENT") {
    let state = controller.getState();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let resp = null;
    let err = null;
    let network = null;
    let data: any = {
      ...params.data,
      code: params.data.name.trim().slice(0, 10),
      active: true,
    };
    if (state.SegmentSequence?.selectedSegment?.id) {
      [resp, err, network] = await SegmentGroupDetail.patch({
        apiToken: controller.apiToken,
        pk: params.data?.id,
        data: data,
      });
    } else {
      [resp, err, network] = await SegmentGroupList.create({
        apiToken: controller.apiToken,
        data: data,
      });
    }

    state = controller.getState();

    if (err) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: err,
        },
      });
    } else {
      await controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        SegmentSequence: {
          ...state.SegmentSequence,
          selectedSegment: resp,
        },
      });
      await Action(controller, {
        action: "SEARCH_SEGMENT",
        cardKey: params.cardKey,
      });
    }
  } else if (params?.action === "DELETE_SEGMENT") {
    const state = controller.getState();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const [resp, err, network] = await SegmentGroupDetail.delete({
      apiToken: controller.apiToken,
      pk: params.data?.id,
    });

    if (err) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: err,
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        SegmentSequence: {
          ...state.SegmentSequence,
          selectedSegment: {},
          addHNList: [],
        },
      });
      Action(controller, { action: "SEARCH_SEGMENT", cardKey: params.cardKey });
    }
  }
  // PATIENT
  else if (params?.action === "SEARCH_PATIENT") {
    let state = controller.getState();

    controller.setState({
      SegmentSequence: {
        ...state.SegmentSequence,
        loadingSegmentPatient: true,
        loadingSegmentList: true,
        ...("currentPage" in params) && {currentPage: params.currentPage},
        ...("limit" in params) && {limit: params.limit},
       },
    });

    let limit = state.SegmentSequence?.limit
    let currentPage = state.SegmentSequence?.currentPage

    if ("currentPage" in params) {
      currentPage = params.currentPage
    }

    if ("limit" in params) {
      limit = params.limit
    }

    const [resp, err, net] = await PatientSegmentList.list({
      apiToken: controller.apiToken,
      params: {
        use_segment_group_code: true,
        group: state.SegmentSequence?.selectedSegment?.id,
        segment_type: "MARKETING",
        hn: params.findHN,
        limit: limit,
        offset: currentPage * limit,
      },
    });
    if (err) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.btnKey]: err,
        },
        SegmentSequence: {
          ...state.SegmentSequence,
          loadingSegmentList: false,
          loadingSegmentPatient: false,
        },
      });
    } else {
      state = controller.getState();
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        SegmentSequence: {
          ...state.SegmentSequence,
          loadingSegmentList: false,
          loadingSegmentPatient: false,
          patientList: resp,
          totalPage: Math.ceil(resp?.total / limit)

        },
      });
    }
  } else if (params?.action === "SAVE_PATIENT") {
    const state = controller.getState();
    if (
      state.SegmentSequence?.addHNList &&
      state.SegmentSequence?.selectedSegment?.id
    ) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "LOADING",
        },
      });

      const [resp, err, network] = await PatientSegmentNameList.post({
        apiToken: controller.apiToken,
        data: {
          ...params, // result, hn_list
          action: "CREATE",
          hn_list: state.SegmentSequence?.addHNList,
          segment_type: "MARKETING",
          segment_group: state.SegmentSequence?.selectedSegment?.code,
          group: state.SegmentSequence?.selectedSegment?.id,
        },
      });

      if (err) {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
          errorMessage: { ...state.errorMessage, [params.cardKey]: err },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
          SegmentSequence: {
            ...state.SegmentSequence,
            addHNList: [],
          },
        });
        Action(controller, {
          action: "SEARCH_PATIENT",
          cardKey: params.cardKey,
        });
      }
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "เลือก Segment หรือ ผู้ป่วยก่อนทำรายการ",
        },
      });
    }
  } else if (params?.action === "SAVE_REMARK") {
    const [resp, err, network] = await PatientSegmentDetail.patch({
      apiToken: controller.apiToken,
      pk: params.data?.id,
      data: { remark: params.data?.remark || "" },
    });
    const state = controller.getState();
    if (err) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: err,
        },
      });
    } else {
      Action(controller, { action: "SEARCH_PATIENT", cardKey: params.cardKey });
    }
  } else if (params?.action === "DELETE_PATIENT") {
    const [resp, err, network] = await PatientSegmentDetail.delete({
      apiToken: controller.apiToken,
      pk: params.data?.id,
    });
    if (err) {
      const state = controller.getState();
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: err,
        },
      });
    } else {
      Action(controller, {
        action: "SEARCH_PATIENT",
        cardKey: params.cardKey,
      });
    }
  }
  // CLEAR
  else if (params?.action === "CLEAR") {
    const state = controller.getState();

    const selectedSegment: any = {
      ...StateInitial?.SegmentSequence?.selectedSegment,
      name: "New Segment [Name]",
    };

    let segmentList: any = { ...(state.SegmentSequence?.segmentList || {}) };
    if (!segmentList?.items?.find((s: any) => s.id === selectedSegment.id)) {
      segmentList.items.push(selectedSegment);
    }

    controller.setState({
      SegmentSequence: {
        ...state.SegmentSequence,
        segmentList: segmentList,
        selectedSegment: selectedSegment,
        addHNList: StateInitial?.SegmentSequence?.addHNList,
        patientList: StateInitial?.SegmentSequence?.patientList,
      },
    });
  }
};
