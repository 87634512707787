import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './POPhysicalexaminationAnkleFootOrthosisTOP.module.css'

export interface POPhysicalexaminationAnkleFootOrthosisTOPViewDataType {
    PoPhysicalAnkleFootOrthosisTop3: string,
    PoPhysicalAnkleFootOrthosisTop3Disabled: boolean,
    CtGeneralApperanceDysfunction: string,
    CtGeneralApperanceDysfunctionDisabled: boolean,
    CtGeneralApperanceDysfunctionOptions: any,
    PoPhysicalAnkleFootOrthosisTop8: string,
    PoPhysicalAnkleFootOrthosisTop8Disabled: boolean,
    Ct4GeneralApperance: string,
    Ct4GeneralApperanceDisabled: boolean,
    Ct4GeneralApperanceOptions: any,
    Ct4Consciousness: string,
    Ct4ConsciousnessDisabled: boolean,
    Ct4ConsciousnessOptions: any,
    Ct7Communication: string,
    Ct7CommunicationDisabled: boolean,
    Ct7CommunicationOptions: any,
    Ct7CoOperation: string,
    Ct7CoOperationDisabled: boolean,
    Ct7CoOperationOptions: any,
    PoPhysicalAnkleFootOrthosisTop24: string,
    PoPhysicalAnkleFootOrthosisTop24Disabled: boolean,
    PoPhysicalAnkleFootOrthosisTop25: string,
    PoPhysicalAnkleFootOrthosisTop25Disabled: boolean,
    PoPhysicalAnkleFootOrthosisTop26: boolean,
    PoPhysicalAnkleFootOrthosisTop26Disabled: boolean,
    PoPhysicalAnkleFootOrthosisTop28: string,
    PoPhysicalAnkleFootOrthosisTop28Disabled: boolean,
    PoPhysicalAnkleFootOrthosisTop29: boolean,
    PoPhysicalAnkleFootOrthosisTop29Disabled: boolean,
    PoPhysicalAnkleFootOrthosisTop31: string,
    PoPhysicalAnkleFootOrthosisTop31Disabled: boolean,
    PoPhysicalAnkleFootOrthosisTop32: boolean,
    PoPhysicalAnkleFootOrthosisTop32Disabled: boolean,
    PoPhysicalAnkleFootOrthosisTop34: boolean,
    PoPhysicalAnkleFootOrthosisTop34Disabled: boolean,
    PoPhysicalAnkleFootOrthosisTop36: boolean,
    PoPhysicalAnkleFootOrthosisTop36Disabled: boolean,
}

export const POPhysicalexaminationAnkleFootOrthosisTOPInitialViewData: POPhysicalexaminationAnkleFootOrthosisTOPViewDataType = {
    PoPhysicalAnkleFootOrthosisTop3: "",
    PoPhysicalAnkleFootOrthosisTop3Disabled: false,
    CtGeneralApperanceDysfunction: "",
    CtGeneralApperanceDysfunctionDisabled: false,
    CtGeneralApperanceDysfunctionOptions: [{key: 0, value: "A", text: "A"}],
    PoPhysicalAnkleFootOrthosisTop8: "",
    PoPhysicalAnkleFootOrthosisTop8Disabled: false,
    Ct4GeneralApperance: "",
    Ct4GeneralApperanceDisabled: false,
    Ct4GeneralApperanceOptions: [{key: 0, value: "A", text: "A"}],
    Ct4Consciousness: "",
    Ct4ConsciousnessDisabled: false,
    Ct4ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct7Communication: "",
    Ct7CommunicationDisabled: false,
    Ct7CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct7CoOperation: "",
    Ct7CoOperationDisabled: false,
    Ct7CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    PoPhysicalAnkleFootOrthosisTop24: "",
    PoPhysicalAnkleFootOrthosisTop24Disabled: false,
    PoPhysicalAnkleFootOrthosisTop25: "",
    PoPhysicalAnkleFootOrthosisTop25Disabled: false,
    PoPhysicalAnkleFootOrthosisTop26: false,
    PoPhysicalAnkleFootOrthosisTop26Disabled: false,
    PoPhysicalAnkleFootOrthosisTop28: "",
    PoPhysicalAnkleFootOrthosisTop28Disabled: false,
    PoPhysicalAnkleFootOrthosisTop29: false,
    PoPhysicalAnkleFootOrthosisTop29Disabled: false,
    PoPhysicalAnkleFootOrthosisTop31: "",
    PoPhysicalAnkleFootOrthosisTop31Disabled: false,
    PoPhysicalAnkleFootOrthosisTop32: false,
    PoPhysicalAnkleFootOrthosisTop32Disabled: false,
    PoPhysicalAnkleFootOrthosisTop34: false,
    PoPhysicalAnkleFootOrthosisTop34Disabled: false,
    PoPhysicalAnkleFootOrthosisTop36: false,
    PoPhysicalAnkleFootOrthosisTop36Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop2}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop3}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisTop3}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop3Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisTOP',
                            name: 'PoPhysicalAnkleFootOrthosisTop3',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop4}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>General appearance</strong></p>
                </div>
            </div>
            <div className={styles.CtGeneralApperanceDysfunction}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGeneralApperanceDysfunction}
                    disabled={ props.viewData.CtGeneralApperanceDysfunctionDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "CtGeneralApperanceDysfunction",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGeneralApperanceDysfunctionOptions}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop8}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisTop8}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop8Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisTOP',
                            name: 'PoPhysicalAnkleFootOrthosisTop8',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct4GeneralApperance}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct4GeneralApperance}
                    disabled={ props.viewData.Ct4GeneralApperanceDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "Ct4GeneralApperance",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4GeneralApperanceOptions}
                />
            </div>
            <div className={styles.Ct4Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct4Consciousness}
                    disabled={ props.viewData.Ct4ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "Ct4Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct7Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct7Communication}
                    disabled={ props.viewData.Ct7CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "Ct7Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct7CommunicationOptions}
                />
            </div>
            <div className={styles.Ct7CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct7CoOperation}
                    disabled={ props.viewData.Ct7CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "Ct7CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct7CoOperationOptions}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop24}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisTop24}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop24Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisTOP',
                            name: 'PoPhysicalAnkleFootOrthosisTop24',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop25}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisTop25}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop25Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisTOP',
                            name: 'PoPhysicalAnkleFootOrthosisTop25',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalAnkleFootOrthosisTop26}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "PoPhysicalAnkleFootOrthosisTop26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle length</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop28}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisTop28}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop28Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisTOP',
                            name: 'PoPhysicalAnkleFootOrthosisTop28',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalAnkleFootOrthosisTop29}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "PoPhysicalAnkleFootOrthosisTop29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop30}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop31}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisTop31}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop31Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisTOP',
                            name: 'PoPhysicalAnkleFootOrthosisTop31',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop32}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalAnkleFootOrthosisTop32}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop32Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "PoPhysicalAnkleFootOrthosisTop32"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop34}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalAnkleFootOrthosisTop34}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop34Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "PoPhysicalAnkleFootOrthosisTop34"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop36}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalAnkleFootOrthosisTop36}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisTop36Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisTOP",
                                name: "PoPhysicalAnkleFootOrthosisTop36"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisTop38}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
