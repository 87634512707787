import { rgb } from "pdf-lib";

export const applyTextSpacing = (
  text: string,
  x: number,
  y: number,
  customFont: any,
  fontSize: number,
  firstPage: any
) => {
  let adjustedX = x; // Initialize adjustedX to the original x coordinate

  // Loop through each character in the text
  for (let j = 0; j < text?.length; j++) {
    const charSpacing = 7; // Adjust this value as needed

    // Apply spacing adjustment for specific characters
    if (j === 1 || j === 5 || j === 10 || j === 12) {
      adjustedX += charSpacing;
    }

    // Get the current character
    const char = text[j];

    // Draw the character at the adjustedX position
    firstPage.drawText(char, {
      x: adjustedX,
      y,
      size: fontSize,
      font: customFont,
      color: rgb(0, 0, 0),
    });

    // Increment adjustedX by the width of the character plus the spacing adjustment
    adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
  }
};

export const applyTextSpacingforBranch = (
  text: string,
  x: number,
  y: number,
  customFont: any,
  fontSize: number,
  firstPage: any
) => {
  let adjustedX = x; // Initialize adjustedX to the original x coordinate

  // Loop through each character in the text
  const charSpacing = 3.5;

  for (let j = 0; j < text?.length; j++) {
    const char = text[j];

    // Adjust spacing for specific letters
    if (j === 1 || j === 2 || j === 3) {
      adjustedX += charSpacing; // Increase the X coordinate by charSpacing
    }

    // Draw the character at the currentX position
    firstPage.drawText(char, {
      x: adjustedX,
      y,
      size: fontSize,
      font: customFont,
      color: rgb(0, 0, 0),
    });

    // Update currentX with character width and spacing
    adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
  }
};
