import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Checkbox,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardMedRequestReturnUX = (props: any) => {
    return(
      <div
        style={{padding:"5px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",marginTop:"10px"}}>
          ประวัติการขอคืนเวชภัณฑ์
        </div>
        <div
          style={{display: "flex", alignItems: "center", flexWrap: "wrap",marginTop:"10px" }}>
          
          <div
            style={{display: "flex", alignItems: "center", margin: "0 10px 10px 0"}}>
            
            <Checkbox
              checked={props?.checkedDivisionReturn}
              onChange={props.handleChangeChecked("checkedDivisionReturn")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              หน่วยงานคืน
            </div>
            <Dropdown
              defaultValue="แสดงทุกหน่วยงาน"
              onChange={props.handleChangeValue("selectedDivisionReturn","checkedDivisionReturn")}
              options={props?.divisionOptions}
              search={true}
              selection={true}
              value={props?.selectedDivisionReturn}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", margin: "0 10px 10px 0"}}>
            
            <Checkbox
              checked={props?.checkedDivisionReceive}
              onChange={props.handleChangeChecked("checkedDivisionReceive")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              หน่วยงานรับ
            </div>
            <Dropdown
              defaultValue="แสดงทุกหน่วยงาน"
              onChange={props.handleChangeValue("selectedDivisionReceive","checkedDivisionReceive")}
              options={props?.divisionOptions}
              search={true}
              selection={true}
              value={props?.selectedDivisionReceive}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", margin: "0 10px 10px 0"}}>
            
            <Checkbox
              checked={props?.checkedFilterDate}
              onChange={props.handleChangeChecked("checkedFilterDate")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              วันที่แจ้ง
            </div>
            <DateTextBox
              onChange={props.handleChangeDate("selectedStartDate","checkedFilterDate")}
              value={props?.selectedStartDate}>
            </DateTextBox>
            <DateTextBox
              onChange={props.handleChangeDate("selectedEndDate","checkedFilterDate")}
              style={{marginLeft:"10px"}}
              value={props?.selectedEndDate}>
            </DateTextBox>
          </div>
          <div
            style={{display: "flex", alignItems: "center", margin: "0 10px 10px 0"}}>
            
            <Checkbox
              checked={props?.checkedStatus}
              onChange={props.handleChangeChecked("checkedStatus")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              สถานะ
            </div>
            <Dropdown
              defaultValue="แสดงทุกสถานะ"
              onChange={props.handleChangeValue("selectedStatus","checkedStatus")}
              options={props?.statuOptions}
              selection={true}
              value={props?.selectedStatus}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", margin: "0 10px 10px 20px"}}>
            
            <Button
              color="blue"
              onClick={props.OnSearch}
              size="tiny">
              ค้นหา
            </Button>
            <Button
              color="yellow"
              onClick={props.OnClear}
              size="tiny">
              ล้าง
            </Button>
          </div>
        </div>
        <div>
          
          <Table
            data={props.MedRequestReturnList}
            getTrProps={props.handleRowProps}
            headers="เลขที่,รายการเวชภัณฑ์,ขอคืน,อนุมัติ,ส่ง,รับ,สถานะ"
            keys="code,listSupply,request,approve,delivery,receive,statusName"
            minRows="10"
            showPagination={false}
            style={{height: "400px"}}
            widths="120,auto,100,100,100,100,150">
          </Table>
        </div>
      </div>
    )
}

export default CardMedRequestReturnUX

export const screenPropsDefault = {"patientSearch":"[Patient Search Box]"}

/* Date Time : Wed Sep 28 2022 11:18:11 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:\"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการขอคืนเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",marginTop:\"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", flexWrap: \"wrap\",marginTop:\"10px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.MedRequestReturnList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.handleRowProps"
        },
        "headers": {
          "type": "value",
          "value": "เลขที่,รายการเวชภัณฑ์,ขอคืน,อนุมัติ,ส่ง,รับ,สถานะ"
        },
        "keys": {
          "type": "value",
          "value": "code,listSupply,request,approve,delivery,receive,statusName"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        },
        "widths": {
          "type": "value",
          "value": "120,auto,100,100,100,100,150"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"0 10px 10px 0\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"0 10px 10px 0\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedDivisionReturn"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDivisionReturn\")"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานคืน"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Dropdown",
      "parent": 5,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "แสดงทุกหน่วยงาน"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivisionReturn\",\"checkedDivisionReturn\")"
        },
        "options": {
          "type": "code",
          "value": "props?.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedDivisionReturn"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Checkbox",
      "parent": 7,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedFilterDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedFilterDate\")"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่แจ้ง"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 20,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedStartDate\",\"checkedFilterDate\")"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedStartDate"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 21,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedEndDate\",\"checkedFilterDate\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedEndDate"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"0 10px 10px 0\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"0 10px 10px 0\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", margin: \"0 10px 10px 20px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานรับ"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedDivisionReceive"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDivisionReceive\")"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Checkbox",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedStatus\")"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Dropdown",
      "parent": 37,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "แสดงทุกหน่วยงาน"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivisionReceive\",\"checkedDivisionReceive\")"
        },
        "options": {
          "type": "code",
          "value": "props?.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedDivisionReceive"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Dropdown",
      "parent": 38,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "แสดงทุกสถานะ"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedStatus\",\"checkedStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props?.statuOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedStatus"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Button",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnSearch"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Button",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้าง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnClear"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 47,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardMedRequestReturnUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "patientSearch": "[Patient Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
