export default function toPDFMakeData(props: any) {
  return {
    pageSize: `A4`,
    content: [
      {
        decorationColor: ``,
        alignment: `center`,
        decoration: ``,
        decorationStyle: ``,
        pageBreak: ``,
        color: ``,
        width: `auto`,
        fontSize: `18`,
        preserveLeadingSpaces: true,
        bold: `true`,
        text: `Medical Certificate`,
      },
      {
        width: `auto`,
        decoration: ``,
        alignment: `center`,
        decorationStyle: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        fontSize: `18`,
        bold: `true`,
        color: ``,
        text: `5-Disease`,
        decorationColor: ``,
      },
      {
        preserveLeadingSpaces: true,
        width: `auto`,
        alignment: `left`,
        decorationColor: ``,
        decoration: ``,
        color: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        text: ` `,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            width: 420,
            decoration: ``,
            decorationColor: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `Date `,
            fontSize: 15,
            decorationStyle: ``,
            bold: true,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
          },
          {
            text: ``,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 5,
            alignment: `left`,
          },
          {
            color: ``,
            text: props.items?.formatCreatedDate,
            decoration: ``,
            bold: true,
            decorationStyle: ``,
            fontSize: 15,
            alignment: `center`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
          },
        ],
      },
      {
        fontSize: 15,
        decoration: ``,
        width: `auto`,
        text: ` `,
        decorationStyle: ``,
        color: ``,
        bold: false,
        decorationColor: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            width: 30,
            bold: false,
            text: ``,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            text: `	  This is certify that `,
            decorationStyle: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            width: 10,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            text: ``,
            alignment: `left`,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                decoration: ``,
                text: `${
                  props.items.patientInfo?.full_name_en !== ""
                    ? props.items?.patientInfo?.full_name_en
                    : props.items?.patientInfo?.full_name_th !== ""
                    ? props.items?.patientInfo?.full_name_th
                    : props.items?.patientInfo?.full_name
                }`,
                color: ``,
                width: `auto`,
                alignment: `left`,
                preserveLeadingSpaces: true,
                bold: false,
                decorationColor: ``,
                fontSize: 15,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
              },
              {
                margin: [0, 0, 0, 0],
                bold: false,
                text: `.....................................................................................................................................................`,
                decoration: ``,
                width: `auto`,
                fontSize: 15,
                decorationStyle: ``,
                decorationColor: ``,
                pageBreak: ``,
                color: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            text: `Identification / Passport number / Others`,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            width: 10,
          },
          {
            stack: [
              {
                pageBreak: ``,
                preserveLeadingSpaces: true,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no === "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
                color: ``,
                bold: false,
                fontSize: 15,
                width: `auto`,
                alignment: `left`,
                decorationColor: ``,
                decoration: ``,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
              },
              {
                decorationColor: ``,
                fontSize: 15,
                decoration: ``,
                decorationStyle: ``,
                pageBreak: ``,
                color: ``,
                width: `auto`,
                margin: [0, 0, 0, 0],
                bold: false,
                text: `..............................................................................................................................`,
                alignment: `left`,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            text: `Age `,
            alignment: `left`,
            color: ``,
          },
          {
            width: 10,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            stack: [
              {
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
                color: ``,
                preserveLeadingSpaces: true,
                text: `${props.items?.ageYear} years ${props.items?.ageMonth} months ${props.items?.ageDay} days`,
                bold: false,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                decoration: ``,
                decorationStyle: ``,
                pageBreak: ``,
              },
              {
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                alignment: `left`,
                width: `auto`,
                pageBreak: ``,
                bold: false,
                fontSize: 15,
                decorationStyle: ``,
                decoration: ``,
                text: `..............................................................`,
                color: ``,
                decorationColor: ``,
              },
            ],
          },
          {
            text: ` has been examined and `,
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            bold: false,
          },
          {
            text: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: 10,
            alignment: `left`,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                decoration: ``,
                preserveLeadingSpaces: true,
                bold: false,
                alignment: `left`,
                fontSize: 15,
                width: `auto`,
                pageBreak: ``,
                color: ``,
                text:
                  props.items?.isFoundDisease === "Found"
                    ? "found some condition(s)"
                    : "free of the following diseases",
                decorationColor: ``,
                decorationStyle: ``,
              },
              {
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                decorationStyle: ``,
                width: `auto`,
                text: `.......................................................................`,
                decoration: ``,
                color: ``,
                bold: false,
                decorationColor: ``,
                alignment: `left`,
                fontSize: 15,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        bold: false,
        decoration: ``,
        decorationColor: ``,
        decorationStyle: ``,
        color: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        fontSize: 15,
        pageBreak: ``,
        width: `auto`,
        alignment: `left`,
      },
      {
        columns: [
          {
            stack: [
              {
                alignment: `left`,
                decorationStyle: ``,
                color: ``,
                width: `auto`,
                text: props.items?.count[0],
                pageBreak: ``,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                margin: [55, 0, 0, 0],
                decoration: ``,
                decorationColor: ``,
              },
              {
                bold: false,
                decorationColor: ``,
                color: ``,
                decoration: ``,
                alignment: `left`,
                pageBreak: ``,
                width: `auto`,
                margin: [55, 0, 0, 0],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                fontSize: 15,
                text: props.items?.count[1],
              },
              {
                text: props.items?.count[2],
                fontSize: 15,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                pageBreak: ``,
                color: ``,
                margin: [55, 0, 0, 0],
                decorationColor: ``,
                bold: false,
                alignment: `left`,
                width: `auto`,
              },
              {
                width: `auto`,
                decorationColor: ``,
                decorationStyle: ``,
                decoration: ``,
                pageBreak: ``,
                color: ``,
                margin: [55, 0, 0, 0],
                text: props.items?.count[3],
                preserveLeadingSpaces: true,
                fontSize: 15,
                bold: false,
                alignment: `left`,
              },
              {
                preserveLeadingSpaces: true,
                fontSize: 15,
                bold: false,
                color: ``,
                alignment: `left`,
                pageBreak: ``,
                text: props.items?.count[4],
                decorationColor: ``,
                decorationStyle: ``,
                decoration: ``,
                margin: [55, 0, 0, 0],
                width: `auto`,
              },
            ],
          },
          {
            stack: [
              {
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                fontSize: 15,
                alignment: `left`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decoration: ``,
                margin: [-189, 0, 0, 0],
                text: props.items?.isFirstDisease ? "โรคเรื้อน (Leprosy)" : "",
                bold: false,
                color: ``,
              },
              {
                text: props.items?.isSecondDisease ? "วัณโรคระยะอันตราย (Tuberculosis)" : "",
                pageBreak: ``,
                width: `auto`,
                fontSize: 15,
                alignment: `left`,
                margin: [-189, 0, 0, 0],
                color: ``,
                decoration: ``,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                bold: false,
              },
              {
                pageBreak: ``,
                alignment: `left`,
                fontSize: 15,
                decorationStyle: ``,
                color: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                decorationColor: ``,
                margin: [-189, 0, 0, 0],
                text: props.items?.isThirdDisease ? "โรคเท้าช้าง (Elephantiasis)" : "",
                width: `auto`,
                bold: false,
              },
              {
                decoration: ``,
                text: props.items?.isFourthDisease ? "โรคยาเสพติดให้โทษ (Drug Addiction)" : "",
                color: ``,
                bold: false,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                margin: [-189, 0, 0, 0],
                alignment: `left`,
                width: `auto`,
                decorationStyle: ``,
                pageBreak: ``,
              },
              {
                decoration: ``,
                color: ``,
                alignment: `left`,
                text: props.items?.isFifthDisease
                  ? "โรคซิฟิลิสในระยะที่ 3 (Third step of syphilis)"
                  : "",
                decorationColor: ``,
                bold: false,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                margin: [-189, 0, 0, 0],
                decorationStyle: ``,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        width: `auto`,
        decorationStyle: ``,
        alignment: `left`,
        decorationColor: ``,
        bold: false,
        text: ` `,
        preserveLeadingSpaces: true,
        decoration: ``,
        color: ``,
        pageBreak: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            text: `Other (if any) `,
            color: ``,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            bold: false,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
            alignment: `left`,
            width: 10,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decorationStyle: ``,
                text: props.items?.otherNote === "" ? " " : props.items?.otherNote,
                margin: [0, 0, 0, -15],
                width: `auto`,
                alignment: `left`,
                decorationColor: ``,
                fontSize: 15,
                decoration: ``,
                bold: false,
                color: ``,
              },
              {
                width: `auto`,
                alignment: `left`,
                decorationColor: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: `...................................................................................................................................................................................`,
                preserveLeadingSpaces: true,
                fontSize: 15,
                color: ``,
                decoration: ``,
                bold: false,
              },
            ],
          },
        ],
      },
      {
        alignment: `left`,
        decorationStyle: ``,
        bold: false,
        width: `auto`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        text: ` ..............................................................................................................................................................................................................`,
        color: ``,
        decorationColor: ``,
        decoration: ``,
        pageBreak: ``,
      },
      {
        bold: false,
        width: `auto`,
        color: ``,
        pageBreak: ``,
        fontSize: 15,
        decorationColor: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        text: ` ..............................................................................................................................................................................................................`,
        decoration: ``,
      },
      {
        width: `auto`,
        color: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decorationColor: ``,
        bold: false,
        text: ` `,
        pageBreak: ``,
        alignment: `left`,
        fontSize: 15,
        decoration: ``,
      },
      {
        width: `auto`,
        alignment: `left`,
        fontSize: 15,
        decorationColor: ``,
        decorationStyle: ``,
        color: ``,
        pageBreak: ``,
        bold: false,
        text: ` `,
        decoration: ``,
        preserveLeadingSpaces: true,
      },
      {
        alignment: `left`,
        pageBreak: ``,
        color: ``,
        decoration: ``,
        fontSize: 15,
        decorationStyle: ``,
        bold: false,
        decorationColor: ``,
        width: `auto`,
        text: ` `,
        preserveLeadingSpaces: true,
      },
      {
        pageBreak: ``,
        preserveLeadingSpaces: true,
        bold: false,
        color: ``,
        fontSize: 15,
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
        text: ` `,
        decorationStyle: ``,
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decorationStyle: ``,
        bold: false,
        text: ` `,
        decoration: ``,
        fontSize: 15,
        decorationColor: ``,
        pageBreak: ``,
        color: ``,
      },
      {
        alignment: `left`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        color: ``,
        text: ` `,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        decoration: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            text: `Physician’s Signature `,
            width: `auto`,
          },
          {
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            width: 30,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            alignment: `center`,
            text: `....................................................`,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            color: ``,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `center`,
            text: ` `,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            bold: false,
            width: 30,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            text: ` Medical License No. `,
            pageBreak: ``,
            color: ``,
          },
          {
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 30,
          },
          {
            decorationStyle: `dotted`,
            fontSize: 15,
            alignment: `center`,
            decorationColor: ``,
            pageBreak: ``,
            text: props.items?.doctorCertNumber
              ? props.items?.doctorCertNumber
              : "                    ",
            width: `auto`,
            bold: false,
            color: ``,
            decoration: `underline`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            width: 134,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `center`,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            text: `(`,
            width: `auto`,
            decorationColor: ``,
            color: ``,
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: props.items?.doctorNameEn,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            text: `)`,
            decoration: ``,
          },
        ],
      },
    ],
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    header: props.header,
    footer: props.footer,
  };
}
