import * as OrderSupplyI from "./OrderSupply";
import * as DispensingOrderSupplyI from "./DispensingOrderSupply";
import * as SupplyDeliveryI from "./SupplyDelivery";
import * as RefillDivisionPrintI from "./RefillDivisionPrint";
import * as ReturnSupplyI from"./ReturnSupply";
import * as MedRequestReturnI from"./MedRequestReturn";
import * as SupplyOrderHistoryI from"./SupplyOrderHistory";
import * as SupplyDeliveryHistoryI from "./SupplyDeliveryHistory";
import * as SupplyServiceCheckingI from "./SupplyServiceChecking";

const SequencePattern: { [name: string]: { [flavor: string] : { [func: string]: any }}} = {
  OrderSupply: {
    default: {
      START: OrderSupplyI.Start,
      SearchAndEdit: OrderSupplyI.SearchAndEdit,
    }
  },
  DispensingOrderSupply: {
    default: {
      START: DispensingOrderSupplyI.Start,
      SearchAndSelect: DispensingOrderSupplyI.SearchAndSelect,

    }
  },
  SupplyDelivery: {
    default: {
      START: SupplyDeliveryI.Start,
      SearchAndSelect: SupplyDeliveryI.SearchAndSelect,
    }
  },
  RefillDivisionPrint: {
    default: {
      START: RefillDivisionPrintI.Start,
      SearchAndSelect: RefillDivisionPrintI.SearchAndSelect,
    }
  },
  ReturnSupply :{
    default:{
      START: ReturnSupplyI.Start,
      SearchAndEdit: ReturnSupplyI.SearchAndEdit
    }
  },
  MedRequestReturn :{
    default:{
      START: MedRequestReturnI.SearchAndSelect,
    }
  },
  SupplyOrderHistory:{
    default:{
      START:SupplyOrderHistoryI.SearchAndSelect
    }
  },
  SupplyDeliveryHistory:{
    default:{
      START:SupplyDeliveryHistoryI.SearchAndSelect
    }
  },
  SupplyServiceChecking:{
    default:{
      START:SupplyServiceCheckingI.Start,
      ACTION: SupplyServiceCheckingI.Action
    }
  }
};

export default SequencePattern;
