import { TDocumentDefinitions } from "pdfmake/interfaces";
import { HeaderPrintListLandscape } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";
import { getCompanyFont } from "react-lib/apps/HISV3/common/CompanyLogoForm";
import AddSymbolPrintList from "./AddSymbolPrintList";

const FORM_NAME = "FormPainManagementFlowsheet";

type AssessmentTool = {
  label: string;
  checked: boolean;
};

const ASSESSMENT_TOOLS: AssessmentTool[] = [
  { label: "< 1 years (NIPS*)", checked: false },
  { label: "1-3 < 1 years (FLACC*)", checked: false },
  { label: "> 3-8 years (FACES)", checked: false },
  { label: "No - Communicative patient (BPS*)", checked: false },
];

const HEADERS = {
  basic: ["Date", "Time", "Score", "Location", "Duration", "Characteristic", "Freq"],
  informedPhysician: {
    title: "Informed Physician",
    options: ["Yes", "No"],
  },
  afterInformedPhysician: [
    "Non Medical Intervention",
    "Time Medication Give",
    "Name of Medication, Dose & Route (see MAR for other details)",
    "Sedation score (before Opiods given)",
  ],
  painReassessment: {
    title: "Pain Re-assessment",
    subtitle: "Pain score after intervention",
    columns: ["time", "IV min", "IM 30 min", "Oral/non med min 60 min"],
  },
  final: ["Sedation score (after Opiods given)", "SE", "Signature"],
};

const createAssessmentTools = (tools: AssessmentTool[]) => ({
  columns: [
    { text: "Pain Assessment Tool:", width: "auto" },
    ...tools.map((tool: any) => ({
      columns: [
        { image: tool.checked ? "checked" : "unchecked", width: 10, margin: [0, 4, 0, 0] },
        { text: tool.label, width: "auto", noWrap: true },
      ],
      columnGap: 5,
      width: "auto",
    })),
  ],
  columnGap: 10,
});

const createExplainedTable = () => ({
  margin: [0, 8, 0, 16],
  table: {
    widths: ["18%", "10%", "30%", "12%", "30%"],
    body: [
      [
        {
          stack: [
            { text: "Characteristic:", style: "explainedText" },
            {
              columns: [
                { text: "B = Burn", style: "explainedText" },
                { text: "P = Prick", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "C = Colic", style: "explainedText" },
                { text: "S = Sharp", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "D = Dull", style: "explainedText" },
                { text: "T = Throbbing", style: "explainedText" },
              ],
            },
            { text: "O = Other", style: "explainedText" },
          ],
        },
        {
          stack: [
            { text: "Frequency (Freq):", style: "explainedText" },
            { text: "C = Continuous", style: "explainedText" },
            { text: "I = Intermittent", style: "explainedText" },
          ],
        },
        {
          stack: [
            { text: "Non-Medical Intervention:", style: "explainedText" },
            {
              columns: [
                { text: "No = None", style: "explainedText" },
                { text: "MT = Music therapy", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "A = Advice", style: "explainedText" },
                { text: "RT = Relaxation techniques", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "M = Massage", style: "explainedText" },
                { text: "T = Talking", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "P = Position", style: "explainedText" },
                { text: "C = Cold", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "AT = Aroma therapy", style: "explainedText" },
                { text: "H = Heat", style: "explainedText" },
              ],
            },
          ],
        },
        {
          stack: [
            { text: "Sedation score:", style: "explainedText" },
            {
              text: "*Record before and after if use Opiods only",
              italics: true,
              style: "explainedText",
            },
            { text: "0 = Awake", style: "explainedText" },
            { text: "1 = Call to awake", style: "explainedText" },
            { text: "2 = Touch to awake", style: "explainedText" },
            { text: "3 = No response", style: "explainedText" },
          ],
        },
        {
          stack: [
            { text: "Side Effects (SE) code:", style: "explainedText" },
            {
              columns: [
                { text: "No = No side effects", style: "explainedText" },
                { text: "D = Dry mouth", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "O = Other", style: "explainedText" },
                { text: "N = Nausea", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "R = Respiratory Depression", style: "explainedText" },
                { text: "V = Vomiting", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "P = Pruritas", style: "explainedText" },
                { text: "U = Urinary Retension", style: "explainedText" },
              ],
            },
            {
              columns: [
                { text: "A = Altered Mental Status", style: "explainedText" },
                { text: "SD = Sleep Disturbance", style: "explainedText" },
              ],
            },
          ],
        },
      ],
    ],
  },
});

const createMainTable = (rows: number) => {
  const createHeaderRow = () => {
    const headers = [
      ...HEADERS.basic.map((header) => ({
        text: header,
        style: "mainTableText",
        rowSpan: 3,
      })),
      {
        text: HEADERS.informedPhysician.title,
        style: "mainTableText",
        colSpan: 2,
      },
      {},
      ...HEADERS.afterInformedPhysician.map((header) => ({
        text: header,
        style: "mainTableText",
        rowSpan: 3,
      })),
      {
        text: HEADERS.painReassessment.title,
        style: "mainTableText",
        colSpan: HEADERS.painReassessment.columns.length,
      },
      ...Array(HEADERS.painReassessment.columns.length - 1).fill({}),
      ...HEADERS.final.map((header) => ({
        text: header,
        style: "mainTableText",
        rowSpan: 3,
      })),
    ];

    return headers;
  };

  const createSecondRow = () => {
    const row = Array(getTotalColumns()).fill({});

    const informedStartIndex = HEADERS.basic.length;
    HEADERS.informedPhysician.options.forEach((option, index) => {
      row[informedStartIndex + index] = {
        text: option,
        style: "mainTableText",
        rowSpan: 2,
      };
    });

    const painStartIndex =
      HEADERS.basic.length +
      HEADERS.informedPhysician.options.length +
      HEADERS.afterInformedPhysician.length;
    row[painStartIndex] = {
      text: HEADERS.painReassessment.subtitle,
      style: "mainTableText",
      colSpan: HEADERS.painReassessment.columns.length,
    };

    return row;
  };

  const createThirdRow = () => {
    const row = Array(getTotalColumns()).fill({});

    const painStartIndex =
      HEADERS.basic.length +
      HEADERS.informedPhysician.options.length +
      HEADERS.afterInformedPhysician.length;

    HEADERS.painReassessment.columns.forEach((column, index) => {
      row[painStartIndex + index] = {
        text: column,
        style: "mainTableText",
      };
    });

    return row;
  };

  const createDataRow = () => Array(getTotalColumns()).fill({ text: " ", style: "mainTableText" });

  const getTotalColumns = () =>
    HEADERS.basic.length +
    HEADERS.informedPhysician.options.length +
    HEADERS.afterInformedPhysician.length +
    HEADERS.painReassessment.columns.length +
    HEADERS.final.length;

  return {
    table: {
      body: [
        createHeaderRow(),
        createSecondRow(),
        createThirdRow(),
        ...Array(rows)
          .fill(null)
          .map(() => createDataRow()),
      ],
    },
  };
};

const FormPainManagementFlowsheet = async (props: any): Promise<TDocumentDefinitions> => {
  const headerForm = await HeaderPrintListLandscape({
    detail: props.detail,
    font: getCompanyFont(),
    form: FORM_NAME,
    logoHeight: 56,
    pageMargins: [16, 100, 16, 16],
    titleContent: [],
    formName: "Pain Management Flowsheet",
  });

  const { font, fontSizes, images, lineHeights, styles } = headerForm;

  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    defaultStyle: {
      font: font,
      fontSize: fontSizes[14],
      lineHeight: lineHeights[1],
    },
    ...headerForm,
    images: { ...images, ...AddSymbolPrintList },
    styles: {
      ...styles,
      explainedText: {
        font: "THSarabunNew",
        fontSize: 12,
        lineHeight: 1,
      },
      mainTableText: {
        font: "THSarabunNew",
        fontSize: 12,
        lineHeight: 1,
        alignment: "center",
      },
    },
    content: [createAssessmentTools(ASSESSMENT_TOOLS), createExplainedTable(), createMainTable(9)],
  };
};

export default FormPainManagementFlowsheet;
