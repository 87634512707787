import moment from "moment";
import { beToAd } from "react-lib/utils/dateUtils";

// Utils
//import { formatDate } from "react-lib/utils/dateUtils";

const FormDeathCertificateEN = (props: any) => {
  //console.log("Properties Preview Data: ", props);

  const newBirthDate = beToAd(props.items.patientInfo?.birthdate);
  const newDeathDate = beToAd(props.items.deathDate);

  const diffDate = moment(newDeathDate).diff(newBirthDate);
  const yearDuration = moment.duration(diffDate).years();
  const monthDuration = moment.duration(diffDate).months();
  const dayDuration = moment.duration(diffDate).days();

  // get Day from Date
  const getDayDate = (date: string) => {
    return date.slice(0, 2);
  };
  // get Month Name from Date
  const getMonthLocale = (date: string) => {
    const getMonth = date.slice(3, 5);
    return moment(getMonth, "MM").format("MMMM");
  };
  // get Year from Date
  const getYearDate = (date: string) => {
    return date.slice(6, 10);
  };

  // get Gender ENG
  const getGender = (gender: string) => {
    switch (gender) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      default:
        return "N/A";
    }
  };

  // get Manner of Death ENG
  const getMannerOfDeathEN = (manner: string) => {
    switch (manner) {
      case "การตายโดยธรรมชาติ":
        return "Natural";
      case "การตายโดยอุบัติเหตุ":
        return "Accident";
      case "การตายโดยฆ่าตัวตาย":
        return "Suicide";
      case "การตายโดยถูกฆาตกรรม":
        return "Homicide";
      case "การตายโดยยังไม่ทราบสาเหตุ":
      case null || undefined:
        return "Undetermined";
      default:
        return manner;
    }
  };

  // get Duration ENG
  const getDurationEN = (duration: any, unit: any) => {
    if (duration > 1) {
      switch (unit) {
        case "นาที":
          return "mins";
        case "วัน":
          return "days";
        case "สัปดาห์":
          return "weeks";
        case "เดือน":
          return "months";
        case "ปี":
          return "years";
        default:
          return null;
      }
    } else {
      switch (unit) {
        case "นาที":
          return "min";
        case "วัน":
          return "day";
        case "สัปดาห์":
          return "week";
        case "เดือน":
          return "month";
        case "ปี":
          return "year";
        default:
          return null;
      }
    }
  };

  // Value Checking (Icd10TypeC)
  const haveIcd10TypeCValue = (value: string) => {
    if (value === "" || value === null || value === undefined) {
      return null;
    } else {
      return [
        { text: ``, border: [true, false, false, false] },
        {
          columns: [
            { text: `(c)`, alignment: "right", width: 20 },
            {
              stack: [
                { text: `${props.items.icd10TypeC}`, style: "icd10Text" },
                {
                  text: `due to (or as a consequence of)`,
                  style: "explainText",
                },
              ],
            },
          ],
          columnGap: 10,
          border: [false, false, true, false],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountC ? props.items.amountC : "-"} ${
            props.items.amountTypeC
              ? getDurationEN(props.items.amountC, props.items.amountTypeC)
              : " "
          }`,
          alignment: "center",
          border: [true, false, true, false],
        },
      ];
    }
  };

  // Value Checking (Icd10TypeD)
  const haveIcd10TypeDValue = (value: string) => {
    if (value === "" || value === null || value === undefined) {
      return null;
    } else {
      return [
        { text: ``, border: [true, false, false, true] },
        {
          columns: [
            { text: `(d)`, alignment: "right", width: 20 },
            {
              stack: [
                { text: `${props.items.icd10TypeD}`, style: "icd10Text" },
                {
                  text: `due to (or as a consequence of)`,
                  style: "explainText",
                },
              ],
            },
          ],
          columnGap: 10,
          border: [false, false, true, true],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountD ? props.items.amountD : "-"} ${
            props.items.amountTypeD
              ? getDurationEN(props.items?.amountD, props.items?.amountTypeD)
              : " "
          }`,
          alignment: "center",
          border: [true, false, true, true],
        },
      ];
    }
  };

  // Checking Value (Condition1)
  const haveCondition1Type = () => {
    if (
      props.items.condition2Type === undefined ||
      props.items.condition2Type === "undefined" ||
      props.items.condition2Type === null
    ) {
      return [
        {
          text: `Other significant conditions`,
          bold: true,
          border: [true, false, false, true],
        },
        {
          columns: [
            { text: ``, width: 5 },
            {
              text: `${undefinedCheck(props.items.condition1Type)}`,
              style: "icd10Text",
            },
          ],
          columnGap: 10,
          border: [false, false, true, true],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountCondition1 ? props.items.amountCondition1 : "-"} ${
            props.items.amountTypeCondition1
              ? getDurationEN(props.items.amountCondition1, props.items.amountTypeCondition1)
              : " "
          }`,
          alignment: "center",
          border: [true, false, true, true],
        },
      ];
    } else {
      return [
        {
          text: `Other significant conditions`,
          bold: true,
          border: [true, false, false, false],
        },
        {
          columns: [
            { text: ``, width: 5 },
            {
              text: `${undefinedCheck(props.items.condition1Type)}`,
              style: "icd10Text",
            },
          ],
          columnGap: 10,
          border: [false, false, true, false],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountCondition1 ? props.items.amountCondition1 : "-"} ${
            props.items.amountTypeCondition1
              ? getDurationEN(props.items.amountCondition1, props.items.amountTypeCondition1)
              : " "
          }`,
          alignment: "center",
          border: [true, false, true, false],
        },
      ];
    }
  };

  // Checking Value (Condition2)
  const haveCondition2Type = (value: string) => {
    if (value === "" || value === null || value === undefined) {
      return null;
    } else {
      return [
        { text: ``, border: [true, false, false, true] },
        {
          columns: [
            { text: ``, width: 5 },
            { text: `${props.items.condition2Type}`, style: "icd10Text" },
          ],
          columnGap: 10,
          border: [false, false, true, true],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountCondition2 ? props.items.amountCondition2 : "-"} ${
            props.items.amountTypeCondition2
              ? getDurationEN(props.items.amountCondition2, props.items.amountTypeCondition2)
              : " "
          }`,
          alignment: "center",
          border: [true, false, true, true],
        },
      ];
    }
  };

  // undefined Value Check
  const undefinedCheck = (value: any) => {
    if (value == undefined || value == null) {
      return "";
    } else {
      return value;
    }
  };

  // Empty Obj Check
  const emptyObjectCheck = (obj: any) => {
    return obj !== null && typeof obj === "object" && Object.keys(obj).length > 0;
  };

  // Format Address of Patient
  const formattedAddress = {
    haveAddress: emptyObjectCheck(props.items?.patientInfo?.present_address),
    no:
      props.items?.patientInfo?.present_address?.no !== null
        ? props.items?.patientInfo?.present_address?.no
        : " ",
    town:
      props.items?.patientInfo?.present_address?.town !== null
        ? props.items?.patientInfo?.present_address?.town
        : " ",
    name:
      props.items?.patientInfo?.present_address?.name !== null
        ? props.items?.patientInfo?.present_address?.name
        : " ",
    street:
      props.items?.patientInfo?.present_address?.street !== null
        ? props.items?.patientInfo?.present_address?.street
        : " ",
    road:
      props.items?.patientInfo?.present_address?.road !== null
        ? props.items?.patientInfo?.present_address?.road
        : " ",
    city:
      props.items?.patientInfo?.present_address?.city_en_label !== null
        ? props.items?.patientInfo?.present_address?.city_en_label
        : props.items?.patientInfo?.present_address?.city_label !== null
        ? props.items?.patientInfo?.present_address?.city_label
        : " ",
    district:
      props.items?.patientInfo?.present_address?.district_en_label !== null
        ? props.items?.patientInfo?.present_address?.district_en_label
        : props.items?.patientInfo?.present_address?.district_label !== null
        ? props.items?.patientInfo?.present_address?.district_label
        : " ",
    province:
      props.items?.patientInfo?.present_address?.province_en_label !== null
        ? props.items?.patientInfo?.present_address?.province_en_label
        : props.items?.patientInfo?.present_address?.province_label !== null
        ? props.items?.patientInfo?.present_address?.province_label
        : " ",
    zipcode:
      props.items?.patientInfo?.present_address?.zipcode !== null
        ? props.items?.patientInfo?.present_address?.zipcode
        : " ",
  };

  // Address of Patient
  const addressPatientCheck = (haveAddress: boolean) => {
    if (haveAddress == null) {
      return {
        columns: [
          {
            text: `Address`,
            bold: true,
            width: `auto`,
          },
          {
            text: ` :`,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
          {
            text: ``,
            width: 5,
          },
          {
            text: `Address unknown`,
            width: `auto`,
          },
        ],
      };
    } else {
      return {
        columns: [
          {
            text: `Address`,
            bold: true,
            width: `auto`,
          },
          {
            text: ` :`,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
          {
            text: ``,
            width: 5,
          },
          {
            text: `${formattedAddress.no} ${formattedAddress.name} ${formattedAddress.town} ${formattedAddress.road} ${formattedAddress.city} ${formattedAddress.district} ${formattedAddress.province} ${formattedAddress.zipcode}`,
            width: `auto`,
          },
        ],
      };
    }
  };

  return {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 14,
    },
    header: props.header,
    footer: props.footer,

    content: [
      // Certificate Header
      {
        text: `Medical Certificate`,
        fontSize: 18,
        bold: true,
        alignment: "center",
      },
      {
        text: `Cause of Death`,
        fontSize: 18,
        bold: true,
        alignment: `center`,
      },
      {
        text: `Date ${props.items?.formatCreatedDate}`,
        bold: true,
        alignment: "right",
      },

      // Table 1 Infomation Section
      {
        style: "tableFormat",
        unbreakable: true,
        table: {
          widths: ["35%", "35%", "25%", "5%"],
          //heights: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 30, 'auto', 50],
          headerRows: 1,
          body: [
            // border: [left, top, right, bottom]
            // Section 1 ( Patient Info )
            [
              {
                text: `INFOMATION ABOUT THE DECREASED`,
                bold: true,
                color: "white",
                colSpan: 4,
                fillColor: "black",
              },
              {},
              {},
              {},
            ],
            [
              {
                columns: [
                  {
                    text: `Name of decreased`,
                    bold: true,
                    width: `auto`,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${
                      props.items.patientInfo?.full_name_en !== ""
                        ? props.items?.patientInfo?.full_name_en
                        : props.items?.patientInfo?.full_name_th !== ""
                        ? props.items?.patientInfo?.full_name_th
                        : props.items?.patientInfo?.full_name
                    }`,
                    width: `auto`,
                  },
                ],
                border: [true, true, false, true],
                colSpan: 2,
              },
              {},
              {
                columns: [
                  {
                    text: `Sex`,
                    bold: true,
                    width: `auto`,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${getGender(props.items.patientInfo?.gender)}`,
                    width: `auto`,
                  },
                ],
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                columns: [
                  {
                    text: `Date of Birth`,
                    width: `auto`,
                    bold: true,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${getDayDate(props.items.patientInfo?.birthdate)} ${getMonthLocale(
                      props.items.patientInfo?.birthdate
                    )} ${getYearDate(props.items.patientInfo?.birthdate)}`,
                    width: `auto`,
                  },
                ],
                border: [true, true, false, true],
              },
              {
                columns: [
                  {
                    text: `Date of Death`,
                    width: `auto`,
                    bold: true,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${getDayDate(props.items.deathDate)} ${getMonthLocale(
                      props.items.deathDate
                    )} ${getYearDate(props.items.deathDate)}`,
                    width: `auto`,
                  },
                ],
                border: [false, true, false, true],
              },
              {
                columns: [
                  {
                    text: `Age`,
                    width: `auto`,
                    bold: true,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${yearDuration} years ${monthDuration} months ${dayDuration} days`,
                    width: `auto`,
                  },
                ],
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    columns: [
                      {
                        text: `Place of Death`,
                        width: `auto`,
                        bold: true,
                      },
                      {
                        text: ` :`,
                        preserveLeadingSpaces: true,
                        width: `auto`,
                      },
                      {
                        text: ``,
                        width: 5,
                      },
                      {
                        text: `${props.items.deathLocateEn}`,
                        width: `auto`,
                      },
                    ],
                  },
                  addressPatientCheck(formattedAddress.haveAddress),
                ],
                colSpan: 4,
              },
              {},
              {},
              {},
            ],
          ].filter((row) => row !== null),
        },
        layout: {
          hLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          paddingLeft: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingRight: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingTop: function (i: number) {
            return i === 0 ? 1 : 4;
          },
          paddingBottom: function (i: number) {
            return i === 0 ? 1 : 4;
          },
        },
      },

      // Table 2 Cause of Death Section
      {
        style: "tableFormat",
        unbreakable: true,
        table: {
          widths: ["25%", "53%", "2%", "20%"],
          body: [
            [
              {
                text: `CAUSE OF DEATH`,
                bold: true,
                color: "white",
                colSpan: 4,
                fillColor: "black",
              },
              {},
              {},
              {},
            ],
            // Section 2 ( ICD-10 Code )
            [
              {
                text: `Part I`,
                fontSize: 16,
                bold: true,
                border: [true, true, true, false],
                colSpan: 3,
              },
              {},
              {},
              {
                text: `Interval between\nonset and death`,
                alignment: "center",
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: `Immediate cause`,
                bold: true,
                border: [true, false, false, false],
              },
              {
                columns: [
                  {
                    text: `(a)`,
                    alignment: "right",
                    width: 20,
                  },
                  {
                    stack: [
                      {
                        text: props.items.icd10TypeA ? props.items.icd10TypeA : ` `,
                        style: "icd10Text",
                      },
                      {
                        text: `due to (or as a consequence of)`,
                        style: "explainText",
                      },
                    ],
                  },
                ],
                columnGap: 10,
                border: [false, false, true, false],
                colSpan: 2,
              },
              {},
              {
                text: `${props.items.amountA ? props.items.amountA : "-"} ${
                  props.items.amountTypeA
                    ? getDurationEN(props.items.amountA, props.items.amountTypeA)
                    : " "
                }`,
                alignment: "center",
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: `Antecedent causes`,
                bold: true,
                border: [true, false, false, false],
              },
              {
                columns: [
                  { text: `(b)`, alignment: "right", width: 20 },
                  {
                    stack: [
                      {
                        text: props.items.icd10TypeB ? props.items.icd10TypeB : ` `,
                        style: "icd10Text",
                      },
                      {
                        text: `due to (or as a consequence of)`,
                        style: "explainText",
                      },
                    ],
                  },
                ],
                columnGap: 10,
                border: [false, false, true, false],
                colSpan: 2,
              },
              {},
              {
                text: `${props.items.amountB ? props.items.amountB : "-"} ${
                  props.items.amountTypeB
                    ? getDurationEN(props.items.amountB, props.items.amountTypeB)
                    : " "
                }`,
                alignment: "center",
                border: [true, false, true, false],
              },
            ],
            haveIcd10TypeCValue(props.items.icd10TypeC),
            haveIcd10TypeDValue(props.items.icd10TypeD),
          ].filter((row) => row !== null),
        },
        layout: {
          hLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          paddingLeft: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingRight: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingTop: function (i: number) {
            return i === 0 ? 1 : 4;
          },
          paddingBottom: function (i: number) {
            return i === 0 ? 1 : 4;
          },
        },
      },

      // Table 3 Condition Section
      {
        style: "tableFormat",
        unbreakable: true,
        table: {
          widths: ["28%", "50%", "2%", "20%"],
          body: [
            // Section 3 ( Condition )
            [
              {
                text: `Part II`,
                fontSize: 16,
                bold: true,
                border: [true, true, true, false],
                colSpan: 3,
              },
              {},
              {},
              { text: ``, border: [true, true, true, false] },
            ],
            haveCondition1Type(),
            haveCondition2Type(props.items.condition2Type),
          ].filter((row) => row !== null),
        },
        layout: {
          hLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          paddingLeft: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingRight: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingTop: function (i: number) {
            return i === 0 ? 1 : 4;
          },
          paddingBottom: function (i: number) {
            return i === 0 ? 1 : 4;
          },
        },
      },

      // Table 4 Manner Section
      {
        style: "tableFormat",
        unbreakable: true,
        table: {
          widths: ["33%", "*", "25%", "*"],
          body: [
            // Section 4 ( Manner )
            [
              {
                text: `MANNER OF DEATH`,
                bold: true,
                color: "white",
                colSpan: 4,
                fillColor: "black",
              },
              {},
              {},
              {},
            ],
            [
              {
                text: props.items.deathManner
                  ? `${getMannerOfDeathEN(props.items.deathManner)}`
                  : `-`,
                colSpan: 4,
                preserveLeadingSpaces: true,
              },
              {},
              {},
              {},
            ],
          ].filter((row) => row !== null),
        },
        layout: {
          hLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          paddingLeft: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingRight: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingTop: function (i: number) {
            return i === 0 ? 1 : 4;
          },
          paddingBottom: function (i: number) {
            return i === 0 ? 1 : 4;
          },
        },
      },

      // Doctor's Signature [Setting with Note]
      {
        margin: [0, 24, 0, 30],
        unbreakable: true,
        //absolutePosition: {x: 0, y: 752},
        columns: [
          {
            columns: [
              { text: `Physician's Signature ` },
              {
                stack: [
                  {
                    text: "............................................................",
                  },
                  { text: `(${props.items.doctorNameEn}, MD)` },
                ],
              },
            ],
          },
          {
            columns: [
              { text: `Medical License No.` },
              { text: "", width: 5 },
              // Test Data
              // { text: `ก. 584120`, style: "icd10Text" },
              {
                text: undefinedCheck(props.items.doctorCertNumber)
                  ? undefinedCheck(props.items.doctorCertNumber)
                  : "                  ",
                preserveLeadingSpaces: true,
                decoration: "underline",
                decorationStyle: "dotted",
                margin: [-100, 0, 0, 0],
              },
            ],
          },
        ],
        alignment: "center",
      },
    ],
  };
};

export default FormDeathCertificateEN;
