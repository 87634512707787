// import XLSX from "xlsx";

export const readFileAsync = (file: File) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
        resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

export const splitCSV = (s: string) => {
// console.log(" Check match ", s)
var matches = s.match(/(\s*"[^"]+"\s*|\s*[^,]+|,)(?=,|$)/g);
// console.log(" matches", matches)

if (matches) {
    for (var n = 0; n < matches.length; ++n) {
    matches[n] = matches[n].trim();
    if (matches[n][0] === '"') {
        matches[n] = matches[n].replace('"', "");
    }
    if (matches[n] === ",") matches[n] = "";
    }
    if (s[0] === ",") matches.unshift("");
}

return matches;
};

export const getSheetInfo = async (
    file: File, 
    columnDef: { [key: string]: string }
  ) => {

    import("xlsx").then(async (XLSX) => {

      const arrayBuffer = (await readFileAsync(file)) as ArrayBuffer;
      const xl = XLSX.read(arrayBuffer, { type: "array" });
      const csv = XLSX.utils.sheet_to_csv(xl.Sheets[xl.SheetNames[0]]);
      const lines = csv.split("\n");
      const sheetInfo: any[] = lines
        .filter((item: any, index: number) => index > 0)
        .filter((item: any) => item)
        .map((item, index) => splitCSV(item))
        .map((item, index) =>
          item
            ? Object.fromEntries(Object.keys(columnDef)
                                       .map((key: string) => 
                                            [columnDef[key], item[parseInt(key)]]))
            : Object.fromEntries(Object.keys(columnDef)
                                       .map((key: string) => 
                                            [columnDef[key], ""]))
        );
      return sheetInfo;
    })

  };