import React, {
  MutableRefObject,
  useState,
  useRef,
  useEffect,
  KeyboardEvent,
  useMemo,
} from "react";
import { Input } from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";
import SubIcdDetailUX from "../ADM/SubIcdDetailUX";
import { useIntl } from "react-intl";

const SubIcdDetail = (props: any) => {
  const icdKeyUpSearchItems = useMemo(() => {
    return (props.icdKeyUpSearchList || []).map((item: any) => ({
      ...item,
      medterm: (
        <div style={{ color: item.sublevel ? "red" : "" }}>{item.medterm}</div>
      ),
      icdterm: (
        <div style={{ color: item.sublevel ? "red" : "" }}>{item.icdterm}</div>
      ),
      icdcode: (
        <div style={{ color: item.sublevel ? "red" : "" }}>{item.icdcode}</div>
      ),
    }));
  }, [props.icdKeyUpSearchList]);

  const handleSelect = (data: any) => {
    props.onSelect?.({ ...props.item, ...data, icdType: props.icdType });

    if (!data.sublevel) {
      handleSearch({ search: "", clearSearchList: true });
    }
  };

  const handleGetTheadThProps = () => {
    return {
      style: {
        display: "none",
      },
    };
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      onClick: () => handleSelect(props?.icdKeyUpSearchList[rowInfo?.index]),
    };
  };

  const handleSearch = (data: any) => {
    props.onSearch(data);
  };

  return (
    <div>
      <SubIcdDetailUX
        primaryText={props.primaryText}
        secondaryText={props.secondaryText}
        icdType={props.icdType}
        typeOptions={props.typeOptions}
        subType={props.subType}
        active_disease={props.active_disease}
        detail={props.detail}
        showHeader={props.showHeader}
        showSubType={props.showSubType}
        hideActiveDisease={props.hideActiveDisease}
        removable={props.removable}
        clearable={props.clearable}
        deletable={props.deletable}
        addable={props.addable}
        allowedAdd={props.allowedAdd}
        readOnly={props.readOnly}
        hideDetail={props.hideDetail}
        icdKeyUpSearchItems={icdKeyUpSearchItems}
        boxStyle={props.boxStyle || {}}
        primaryTextStyle={props.primaryTextStyle}
        secondaryTextStyle={props.secondaryTextStyle}
        requiredMedicalTerm={props.requiredMedicalTerm}
        // callback
        onGetTrProps={handleGetTrProps}
        onGetTheadThProps={handleGetTheadThProps}
        onChangeValue={props.onChangeValue}
        onClear={props.onClear}
        onRemove={props.onRemove}
        onAdd={props.onAdd}
        onDelete={props.onDelete}
        onClickAdd={props.onClickAdd}
        // Element
        SearchBoxMedTerm={
          <SearchBox
            type="dropdown"
            value={props.item.medterm || ""}
            method="medterm"
            icdType={props.icdType}
            onSearch={handleSearch}
            onSelect={handleSelect}
            readOnly={props.readOnly}
          />
        }
        SearchBoxICDTerm={
          <SearchBox
            type="input"
            value={props.item.icdterm || ""}
            method="icdterm"
            icdType={props.icdType}
            onSearch={handleSearch}
            onSelect={handleSelect}
            readOnly={props.readOnly}
          />
        }
        SearchBoxICDCode={
          <SearchBox
            type="input"
            value={props.item.icdcode || ""}
            method="icdcode"
            icdType={props.icdType}
            onSearch={handleSearch}
            onSelect={handleSelect}
            readOnly={props.readOnly}
          />
        }
        languageUX={props.languageUX}
      />
    </div>
  );
};

const SearchBox = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dropdown, setDropdown] = useState<any[]>([]);
  const [inputDropdown, setInputDropdown] = useState<any>("");
  const [input, setInput] = useState<any>("");
  const [typingTimeout, setTypingTimeout] = useState<any>();
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const inputRef = useRef() as MutableRefObject<any>;
  const isSelectRef = useRef<boolean>(false);

  useEffect(() => {
    if (props.value) {
      setDropdown([props.value]);
      setInputDropdown("")
    } else {
      setDropdown([]);
    }

    setInput(props.value);
  }, [props.value]);

  const handleBlur = () => {
    setTimeout(() => {
      setOpen(false);
      setIsSearch(false);

      // if (!isSelectRef.current) {
      //   handleSearch("", true);
      // }

      isSelectRef.current = false;
    }, 200);
  };

  const handleClick = () => {
    setOpen(true);
    inputRef.current.focus();
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setIsSearch(false);
    setInputDropdown(value);
    setInput(value);

    const check = props.type === "dropdown" ? !props.value : true;

    if (check) {
      if (typingTimeout) {
        clearTimeout(typingTimeout as any);
      }
      setTypingTimeout(
        setTimeout(() => {
          setIsSearch(true);
          handleSearch(value);
        }, 400) as any
      );
    }
  };

  const handleSearch = (value: string, clearSearchList: boolean = false) => {
    props.onSearch({
      search: value,
      method: props.method,
      icdType: props.icdType,
      clearSearchList,
    });
  };

  const handleRemove = (value: string) => () => {
    props.onSelect({ medterm: "", medterm_id: null });
  };

  const handleKeyDownDropdown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!inputDropdown && !!dropdown.length && e.key === "Backspace") {
      handleSearch("");
    }
  };

  return (
    <>
      {props.type === "input" ? (
        <div className="ui error fluid input">
          <input
            value={input}
            type="text"
            placeholder="Search..."
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={props.readOnly}
            style={{
              color: input === "Please Select Sublevel" ? "red" : "",
              width: 0,
              minWidth: "100%",
            }}
          />
        </div>
      ) : props.type === "dropdown" ? (
        <div
          role="combobox"
          aria-expanded={open}
          className={`ui ${open ? "active visible" : ""} ${
            props.readOnly ? "disabled" : ""
          } fluid multiple search selection dropdown`}
          style={{ boxShadow: "none", opacity: 1, width: 0, minWidth: "100%" }}
          onClick={handleClick}
        >
          {dropdown.map((text) => (
            <a className="ui label">
              {text}
              <i
                aria-hidden="true"
                className="delete icon"
                onClick={handleRemove(text)}
              />
            </a>
          ))}
          <input
            ref={inputRef}
            value={inputDropdown}
            aria-autocomplete="list"
            autoComplete="off"
            className="search"
            type="text"
            style={{ width: 0, minWidth: "100%" }}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDownDropdown}
          />
          <span className="sizer" />
          <div
            aria-atomic="true"
            aria-live="polite"
            role="alert"
            className="divider text"
          >
            {/* State */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SubIcdDetail;
