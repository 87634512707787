import React, { useMemo } from "react";

// Common
import ModInfo from "react-lib/apps/common/ModInfo";

// Interface
import { PackageDetailType } from "./sequence/SettingPackage";
import { useIntl } from "react-intl";

// Types
type ModDescriptionProps = {
  data: PackageDetailType | null;
  open: boolean;
  onClose?: () => any;
};

const ModPackageDescription = (props: ModDescriptionProps) => {
  const intl = useIntl();
  // Memo
  const formattedPrice = useMemo(() => {
    if (!props.data?.price) {
      return "-";
    }

    return Number(props.data.price).toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "decimal",
    });
  }, [props.data?.price]);

  const items = useMemo(() => {
    const initialItems = [
      {
        name: "รายการตรวจ",
        name_en: "",
        p_type_code: "ประเภทการใช้บริการ",
      },
    ];

    const mappedItems = (props.data?.items || []).map((item) => ({
      name: item.product_name,
      name_en: item.product_name_en,
      p_type_code: item.p_type_code,
    }));

    const allItems = [...initialItems, ...mappedItems];

    const groupedItems: Record<string, { name: string; name_en: string; p_type_code: string }[]> =
      {};

    for (const item of allItems) {
      const pTypeCode = item.p_type_code;

      groupedItems[pTypeCode] ??= [];
      groupedItems[pTypeCode].push(item);
    }

    return Object.values(groupedItems).flat();
  }, [props.data?.items]);

  return (
    <ModInfo
      buttonStyle={{ display: "none" }}
      open={props.open}
      size="small"
      style={{ maxWidth: "35%", minWidth: "30%", width: "auto" }}
      titleColor={"blue"}
      titleName={intl.formatMessage({ id: "รายละเอียด Package" })}
      closeIcon
      onClose={props.onClose}
    >
      <div
        style={{
          fontWeight: "bold",
          margin: "-1rem -1.5em -1.5em",
          maxHeight: "80vh",
          overflow: "auto",
          padding: "1.5em",
          paddingTop: "1rem",
          textAlign: "left",
        }}
      >
        <div style={{ color: "#0072BC", fontSize: "1.2rem" }}>{props.data?.name}</div>
        <div
          style={{
            alignItems: "baseline",
            display: "flex",
            justifyContent: "flex-end",
            margin: "0.5rem 0 1rem",
          }}
        >
          <div
            style={{
              color: "#0072BC",
              fontSize: "1.75rem",
              textAlign: "right",
            }}
          >
            {formattedPrice}
          </div>
          <div style={{ color: "#0072BC", marginLeft: "1rem" }}>{intl.formatMessage({ id: "บาท" })}</div>
        </div>
        {items.map((item, index) => (
          <ItemRow key={`item${item.name}`} index={index} item={item} />
        ))}
      </div>
    </ModInfo>
  );
};

/* ------------------------------------------------------ */
/*                         ItemRow                        */
/* ------------------------------------------------------ */
type ItemRowProps = {
  index: number;
  item: any;
};

const ItemRow = (props: ItemRowProps) => (
  // #const getProductName = useCallback(
  //   (code: string) => (code in PRODUCT_TYPES ? PRODUCT_TYPES[code as ProductTypeKey].name : code),
  //   [PRODUCT_TYPES]
  // );

  <div
    style={{
      backgroundColor: props.index % 2 === 0 ? "#C1E9EE" : "",
      display: "grid",
      fontWeight: "normal",
      gap: "20px",
      gridTemplateColumns: "11.5rem 1fr",
      padding: "10px 15px",
      ...(props.index === 0
        ? {
            backgroundColor: "#5DBCD2",
            color: "white",
            fontWeight: "bold",
          }
        : {}),
    }}
  >
    <div>{props.item.p_type_code}</div>
    <div>{props.item.name}</div>
  </div>
);

ModPackageDescription.displayName = "ModPackageDescription";

export default React.memo(ModPackageDescription);
