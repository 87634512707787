import React, { useEffect } from "react";
// ui ORM
import CardSurgicalPositionUX from "./CardSurgicalPositionUX";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";

const CARD_KEY: string = "surgicalPositionData";
const FORM_CODE: string = "CardSurgicalPosition";
const FORM_NAME: string = "Surgical Position & Positional Device (A)";
const FORM_VERSION: string = "1.0";

type CardSurgicalPositionProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardSurgicalPositionInitial: CardSurgicalPositionProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PerioperativeNursingSequence: {},
};

const CardSurgicalPosition: React.FC<CardSurgicalPositionProps> = (
  props: any
) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
    });
  }, []);

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  return (
    <>
      <CardSurgicalPositionUX
        masterOptions={props.masterOptions}
        handleChangeData={handleChangeData}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
        nurseDiagnosis={
          props.PerioperativeNursingSequence?.surgicalPositionData?.data
            ?.nursing_diagnosis
        }
        goal={
          props.PerioperativeNursingSequence?.surgicalPositionData?.data?.goal
        }
        position={
          props.PerioperativeNursingSequence?.surgicalPositionData?.data
            ?.position
        }
        warmer={
          props.PerioperativeNursingSequence?.surgicalPositionData?.data?.warmer
        }
        circulatorSupport={
          props.PerioperativeNursingSequence?.surgicalPositionData?.data
            ?.circulator_support
        }
        device={
          props.PerioperativeNursingSequence?.surgicalPositionData?.data?.device
        }
        planAndImplementation={
          props.PerioperativeNursingSequence?.surgicalPositionData?.data
            ?.plan_and_implementation
        }
        status={
          props.PerioperativeNursingSequence?.surgicalPositionData?.status
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

CardSurgicalPosition.defaultProps = CardSurgicalPositionInitial;

export default React.memo(CardSurgicalPosition);
