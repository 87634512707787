import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// TPD
import DrugTransferRequestList from "issara-sdk/apis/DrugTransferRequestList_apps_TPD";
import DrugTransferRequestDetail from "issara-sdk/apis/DrugTransferRequestDetail_apps_TPD";
import DrugTransferRequestItemPlanList from "issara-sdk/apis/DrugTransferRequestItemPlanList_apps_TPD";
import DrugTransferRequestActionLogList from "issara-sdk/apis/DrugTransferRequestActionLogList_apps_TPD";
// MSD
import SupplyTransferRequestList from "issara-sdk/apis/SupplyTransferRequestListCreate_apps_MSD";
import SupplyTransferRequestDetail from "issara-sdk/apis/SupplyTransferRequestDetail_apps_MSD";
import SupplyTransferRequestItemPlanList from "issara-sdk/apis/SupplyTransferRequestItemPlanList_apps_MSD";
import SupplyTransferRequestActionLogList from "issara-sdk/apis/SupplyTransferRequestActionLogList_apps_MSD";
// CORE
import ProductStockList from "issara-sdk/apis/ProductStockList_core";

// FORM
import { HandlePrintFormTransferStock } from "./StockManagement";

// Utils
import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";

// Interface
import { SetProperty } from "../../common/CommonInterface";

export type State = {
  // CommonInterface
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any; // * {cardName: LOADING || SUCCESS || ERROR}
  loadingStatus?: any;
  // seq
  StockTransferOrderSequence?: Partial<{
    sequenceIndex: string | null;
    apiMode: string;
    selectedBox: any;
    filterBox: FilterBoxType;
    actionLog: any[] | null;
    drugTransferRequestlist:
      | Partial<{
          drug: number | null;
          code: any;
          name: any;
          request_quantity: number | null;
          storekey: number | null;
          onhand_requester: number | null;
          onhand_provider: number | null;
          stock_unit_name: number | null;
          request?: number;
        }>[]
      | null;
  }> | null;
};

export type FilterBoxType = Partial<{
  status: string;
  provider: string;
  requester: string;
  from_date: string;
  to_date: string;
}>;

export type SetProp = SetProperty<State>;

export const StateInitial: State = {
  StockTransferOrderSequence: {
    drugTransferRequestlist: null,
    apiMode: "Drugs",
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

export type ButtonActionType = keyof typeof BUTTON_ACTIONS

const BUTTON_ACTIONS = {
  REQUEST: {
    by: "requester",
  },
  APPROVE: {
    action: "APPROVE",
    color: "yellow",
    by: "provider",
  },
  DELIVER: {
    action: "DELIVER",
    color: "green",
    by: "provider",
  },
  RECEIVE: {
    action: "RECEIVE",
    color: "green",
    by: "requester",
  },
  REJECT: {
    action: "REJECT",
    color: "red",
    by: "provider",
  },
  UNAPPROVE: {
    action: "UNAPPROVE",
    color: "red",
    by: "provider",
  },
};

export const FORWARD_ACTIONS = {
  REQUESTED: BUTTON_ACTIONS.APPROVE,
  APPROVED: BUTTON_ACTIONS.DELIVER,
  DELIVERED: BUTTON_ACTIONS.RECEIVE,
};

export const BACKWARD_ACTIONS = {
  REQUESTED: BUTTON_ACTIONS.REJECT,
  APPROVED: BUTTON_ACTIONS.UNAPPROVE,
};

type Handler<P = any, R = any> = (
  controller: WasmController<State, Event, Data>,
  params: P
) => R;

export const start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.StockTransferOrderSequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["divisionPharma", {}],
        ["divisionSetStorage", {}],
      ],
    },
  });

  controller.setState(
    {
      StockTransferOrderSequence: {
        sequenceIndex: "Action",
        apiMode: "Drugs",
        filterBox: {
          from_date: formatDate(moment()),
          to_date: formatDate(moment()),
        },
      },
    },
    () => {
      controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: "SEARCH" },
      });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "SET_SELECTED") {
    HandleSetSelected(controller, params);
  } else if (params.action === "GET_LOTS_DETAIL") {
    HandleGetLotDetail(controller, params);
  } else if (
    ["APPROVE", "REJECT", "DELIVER", "RECEIVE", "EDIT", "UNAPPROVE"].includes(
      params.action
    )
  ) {
    HandleUpdateStatus(controller, params);
  } else if (params.action === "LOG") {
    HandleGetActionLog(controller, params);
  }
};

const HandleSearch: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  let filterParams = {
    ...state.StockTransferOrderSequence?.filterBox,
    is_queue: true,
    sort: "code",
  };

  let [response, error]: any = [];

  if (state.StockTransferOrderSequence?.apiMode === "Supply") {
    [response, error] = await SupplyTransferRequestList.list({
      params: filterParams,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
  } else {
    [response, error] = await DrugTransferRequestList.list({
      params: filterParams,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
  }

  let tempSel = state.StockTransferOrderSequence?.selectedBox;

  if (tempSel) {
    const data = response?.items?.find((t: any) => t.id === tempSel.id) || {};

    tempSel = { ...tempSel, ...data, id: tempSel.id, items: tempSel.items };
  }

  if (error) {
    tempSel = {};

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
      StockTransferOrderSequence: {
        ...state.StockTransferOrderSequence,
        drugTransferRequestlist: response?.items || [],
        selectedBox: formatSelectedData(tempSel),
      },
    });
  }
};

const HandleUpdateStatus: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params?.card]: true },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const selectedBox = state.StockTransferOrderSequence?.selectedBox;

  let [response, error]: any = [];

  if (state.StockTransferOrderSequence?.apiMode === "Supply") {
    [response, error] = await SupplyTransferRequestDetail.update({
      pk: selectedBox?.id,
      data: {
        action: params?.action,
        items: selectedBox?.items,
        reject_note: params.rejectNote,
      } as any,
      extra: { division: controller.data.division, pdf: false },
      apiToken: controller.apiToken,
    });
  } else {
    [response, error] = await DrugTransferRequestDetail.update({
      pk: selectedBox?.id,
      data: {
        action: params?.action,
        items: selectedBox?.items,
        reject_note: params.rejectNote,
      } as any,
      extra: { division: controller.data.division, pdf: false },
      apiToken: controller.apiToken,
    });
  }

  if (response) {
    if (params.action === "APPROVE") {
      await HandlePrintFormTransferStock(controller, {
        data: response,
        items: selectedBox.items,
      });
    }

    controller.setState({
      successMessage: {
        ...state.successMessage,
        [params?.card]: response,
      },
      errorMessage: { ...state.errorMessage, [params?.card]: "" },
      loadingStatus: { ...state.loadingStatus, [params?.card]: false },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });

    params.onSuccess?.();

    HandleSearch(controller, params);
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: error },
      loadingStatus: { ...state.loadingStatus, [params?.card]: false },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  }
};

const HandleGetActionLog: Handler = async (controller, params) => {
  const state = controller.getState();

  const seq = state.StockTransferOrderSequence;
  const isSupply = seq?.apiMode === "Supply";
  const selectedBox = seq?.selectedBox;

  const division = {
    provider: selectedBox?.provider_name,
    requester: selectedBox?.requester_name,
  } as any;

  const api = isSupply
    ? SupplyTransferRequestActionLogList.list
    : DrugTransferRequestActionLogList.list;

  const [result] = await api({
    apiToken: controller.apiToken,
    pk: seq?.selectedBox?.id,
  });

  const items = (result?.items || []).map((item: any) => {
    const buttonAction = (BUTTON_ACTIONS as any)[item.action];

    const by = buttonAction?.by || "";

    return { ...item, division: division[by] || "" };
  });

  controller.setState({
    StockTransferOrderSequence: {
      ...seq,
      actionLog: items,
    },
  });
};

const HandleSetSelected: Handler = (controller, params) => {
  const state = controller.getState();

  params.selectedBox.items?.forEach((item: any, idx: number) => {
    if (state.StockTransferOrderSequence?.apiMode === "Supply") {
      GetListPlaning(controller, {
        id: item.id,
        index: idx,
        selectedBox: params.selectedBox,
        type: "supply",
      });
    } else {
      GetListPlaning(controller, {
        id: item.id,
        index: idx,
        selectedBox: params.selectedBox,
        type: "drug",
      });
    }
  });
};

const HandleGetLotDetail: Handler = (controller, params) => {
  const state = controller.getState();

  if (state.StockTransferOrderSequence?.apiMode === "Supply") {
    GetListPlaning(controller, {
      ...params,
      type: "supply",
    });
  } else {
    GetListPlaning(controller, {
      ...params,
      type: "drug",
    });
  }
};

const GetListPlaning: Handler<{
  type: "drug" | "supply";
  id: number;
  selectedBox?: any;
  index: number;
}> = async (controller, params) => {
  const state = controller.getState();

  const api =
    params.type === "supply"
      ? SupplyTransferRequestItemPlanList
      : DrugTransferRequestItemPlanList;

  const [response] = await api.list({
    pk: params.id,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  let plans = response?.items || [];

  plans = await Promise.all(
    plans.map(async (p: any) => {
      const [response2] = await ProductStockList.list({
        pk: p.lot.product,
        apiToken: controller.apiToken,
        params: { active: true, lot: p.lot.id },
        extra: {
          division: controller.data.division,
        },
      });

      let quantity = 0;

      if (response2?.total > 0) {
        quantity = response2?.items[0].quantity;
      }

      p.lot["get_quantity"] = quantity;

      return { ...p };
    })
  );

  if (plans) {
    let temp = params.selectedBox?.items[params.index];

    temp["items"] = plans;

    let parentItems = params.selectedBox?.items;

    parentItems[params.index] = temp;

    controller.setState({
      StockTransferOrderSequence: {
        ...state.StockTransferOrderSequence,
        selectedBox: {
          ...formatSelectedData(params.selectedBox),
          items: parentItems,
        },
      },
    });
  }
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

const formatSelectedData = (data?: Record<string, any>) => {
  let clickedOrder = data;

  const requestDatetime = data?.id && data.requested ? formatDatetime(moment(data.requested?.datetime)) : "";
  const approveDatetime =
    data?.id && data.approved ? formatDatetime(moment(data.approved?.datetime)) : "";
  const deliverDatetime =
    data?.id && data.delivered ? formatDatetime(moment(data.delivered?.datetime)) : "";

  const requested = {
    ...clickedOrder?.requested,
    datetime_str: requestDatetime,
  };
  const approved = {
    ...clickedOrder?.approved,
    datetime_str: clickedOrder?.approved ? approveDatetime : "",
  };
  const delivered = {
    ...clickedOrder?.delivered,
    datetime_str: clickedOrder?.delivered ? deliverDatetime : "",
  };

  return {
    ...clickedOrder,
    requested: requested,
    approved: approved,
    delivered: delivered,
    request_no: clickedOrder?.code,
    status: clickedOrder?.status_name,
  };
};
