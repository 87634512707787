import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button,
  Icon,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const SubSearchPackageUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{padding: "0 15px"}}>

          <Form>
            <FormGroup>
              <FormField
                width={4}>
                <label
                  style={{fontWeight: "normal"}}>
                  ชื่อ/รหัสแพ็กเกจ
                </label>
                <div
                  style={{width: "100%"}}>
                  {props.SearchBox}
                </div>
              </FormField>
              <FormField
                width={2}>
                <label
                  style={{fontWeight: "normal"}}>
                  ชนิดแพ็กเกจ
                </label>
                <Dropdown
                  className="inline-label"
                  fluid={true}
                  name="packageType"
                  onChange={props.onChangeValue}
                  options={props.packageTypeOptions|| []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPackage?.packageType || ""}>
                </Dropdown>
              </FormField>
              <FormField
                width={2}>
                <label
                  style={{fontWeight: "normal"}}>
                  ประเภทบริการ
                </label>
                <Dropdown
                  className="inline-label"
                  fluid={true}
                  name="serviceType"
                  onChange={props.onChangeValue}
                  options={props.packageServiceTypeOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPackage?.serviceType || ""}>
                </Dropdown>
              </FormField>
              <FormField
                width={2}>
                <label
                  style={{fontWeight: "normal"}}>
                  สถานะการใช้งาน
                </label>
                <Dropdown
                  className="inline-label"
                  fluid={true}
                  name="status"
                  onChange={props.onChangeValue}
                  options={props.packageStatusOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPackage?.status || ""}>
                </Dropdown>
              </FormField>
              <FormField
                style={{display:"none"}}>
                <Button
                  color="red"
                  disabled={!props.allowDelete}
                  onClick={props.onDelete}
                  style={{display: props.allowDelete ? "":"none"}}>

                  <div
                    style={{display: "flex", alignItems: "center"}}>

                    <label
                      style={{marginRight: "5px"}}>
                      ลบ
                    </label>
                    <Icon
                      name="trash alternate outline">
                    </Icon>
                  </div>
                </Button>
              </FormField>
              <FormField>
                <label>
                  {"\u00a0"}
                </label>
                <div>
                  {props.ButtonSearch}
                </div>
              </FormField>
              <FormField>
                <label>
                  {"\u00a0"}
                </label>
                <Button
                  color="yellow"
                  onClick={props.onClear}>
                  เคลียร์
                </Button>
              </FormField>
              <FormField
                style={{flex:1}}>
              </FormField>
              <FormField>
                <label>
                  {"\u00a0"}
                </label>
                <Button
                  color="green"
                  onClick={props.onAddNewPackage}
                  style={{minWidth: "max-content"}}>
                  <div
                    style={{display: "flex", alignItems: "center"}}>

                    <Icon
                      name="plus">
                    </Icon>
                    <label>
                      เพิ่มแพ็กเกจใหม่
                    </label>
                  </div>
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                width={16}>
                <Table
                  data={props.packageList||[]}
                  getTdProps={props.onGetTdProps}
                  getTrProps={props.onGetTrProps}
                  headers="รหัส,ชื่อแพ็กเกจ,ชนิดแพ็กเกจ,ประเภทบริการ,ราคา Normal,ราคา Premium,ราคาต่างชาติ,สถานะใช้งาน,"
                  keys="code,name,package_type_name,package_service_type_name,price_normal,price_premium,price_foreign,status_name,action"
                  minRows={props.showDetail?3:12}
                  showPagination={false}
                  style={{height: props.showDetail?"calc(100dvh - 44rem)": "calc(100dvh - 19rem)"}}
                  widths="130,^150,^100,^100,^70,^70,^70,^75,100">
                </Table>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                style={{flex:1}}>
              </FormField>
              <FormField>
                <div>
                  {props.Pagination}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default SubSearchPackageUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Wed Jul 31 2024 14:56:57 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 15px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Form",
      "parent": 4,
      "props": {
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 7,
      "props": {
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNewPackage"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Icon",
      "parent": 12,
      "props": {
        "name": {
          "type": "value",
          "value": "plus"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มแพ็กเกจใหม่"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:\"none\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Button",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "!props.allowDelete"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDelete"
        },
        "style": {
          "type": "code",
          "value": "{display: props.allowDelete ? \"\":\"none\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ลบ"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Icon",
      "parent": 20,
      "props": {
        "name": {
          "type": "value",
          "value": "trash alternate outline"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormGroup",
      "parent": 5,
      "props": {
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 24,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 26,
      "name": "Table",
      "parent": 25,
      "props": {
        "data": {
          "type": "code",
          "value": "props.packageList||[]"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onGetTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "รหัส,ชื่อแพ็กเกจ,ชนิดแพ็กเกจ,ประเภทบริการ,ราคา Normal,ราคา Premium,ราคาต่างชาติ,สถานะใช้งาน,"
        },
        "keys": {
          "type": "value",
          "value": "code,name,package_type_name,package_service_type_name,price_normal,price_premium,price_foreign,status_name,action"
        },
        "minRows": {
          "type": "code",
          "value": "props.showDetail?3:12"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props.showDetail?\"calc(100dvh - 44rem)\": \"calc(100dvh - 19rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "130,^150,^100,^100,^70,^70,^70,^75,100"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 27,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 27,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Pagination"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Dropdown",
      "parent": 31,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "packageType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageTypeOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPackage?.packageType || \"\""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "serviceType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageServiceTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPackage?.serviceType || \"\""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPackage?.status || \"\""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "ชนิดแพ็กเกจ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทบริการ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการใช้งาน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ/รหัสแพ็กเกจ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 11,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "SubSearchPackageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
