import WasmController from "react-lib/frameworks/WasmController";

// APIs
// MSD
import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";
import SupplyOrderQueueList from "issara-sdk/apis/SupplyOrderQueueList_apps_MSD";
import SupplyOrderActionLogList from "issara-sdk/apis/SupplyOrderActionLogList_apps_MSD";
import SupplyOrderItemPreview from "issara-sdk/apis/SupplyOrderItemPreview_apps_MSD";
import SupplyReturnItemList from "issara-sdk/apis/SupplyReturnItemList_apps_MSD";

// CORE
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";

// Interface
import { HandleGetEmployeeTokenization } from "../../TPD/TPDInterface";

export type State = {
  // CommonInterface
  masterOptions?: any;
  // Seq
  DispensingOrderSupplySequence?: {
    sequenceIndex?: any;
    orderQueue?: any[];
    filterDivision?: any;
    filterStatus?: any;
    isOPD?: boolean;
    isIPD?: boolean;
    hn?: string;
    selectedOrder?: any;
    selectedOrderId?: number | null;
    actionLog?: any;
    coUser?: string;
    coUserToken?: string;
    openModInfo?: boolean;
    titlePopup?: string;
    loading?: boolean;
    error?: any;
    isTransporter?: boolean;
  } | null;
  successMessage?: any;
  errorMessage?: any;
  selectedRecordViewIndex?: any;
  buttonLoadCheck?: any;
  selectedEncounter?: any;
};

export const StateInitial: State = {
  DispensingOrderSupplySequence: {
    orderQueue: [],
    filterDivision: "",
    // filterStatus: "",
    filterStatus: ["REQUESTED", "PRINTED", "CHECKED"],
    isOPD: true,
    isIPD: true,
    hn: "",
    selectedOrder: null,
    selectedOrderId: null,
    actionLog: null,
    coUser: "",
    coUserToken: "",
  },
  successMessage: null,
  errorMessage: null,
  selectedRecordViewIndex: 0,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} }
  | { message: "HandleGetDeliverSupplyTokenization"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

const SUPPLY_ORDER_LIMIT = 300;

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

/*
  ------------------------------------------------------------

    Start

  ------------------------------------------------------------
*/
export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DispensingOrderSupplySequence) {
    return;
  }

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["supplyOrderStatus", {}],
        ["division", {}],
      ],
    },
  });

  GetSupplyList(controller, params, true);
};

/*
  ------------------------------------------------------------

    SearchAndSelect
    // action: search
    // action: select
    // action: refresh


  ------------------------------------------------------------
*/
export const SearchAndSelect: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.DispensingOrderSupplySequence) return;

  if (params.action === "search") {
    GetSupplyList(controller, params);
  } else if (params.action === "select") {
    controller.setState({
      DispensingOrderSupplySequence: {
        ...state.DispensingOrderSupplySequence,
        selectedOrderId: params.orderId,
      },
    });

    const [r, e, n] = await EncounterDetail.retrieve({
      pk: params.encounter,
      apiToken: controller.apiToken,
    });

    controller.handleEvent({
      message: "SelectEncounter" as any,
      params: { encounter: r || null, selectedRecordViewIndex: 0 },
    });
  } else if (params.action === "refresh") {
    GetSupplyDetail(controller, params);
  } else if (params?.action === "getActionLog") {
    const [r, e, n] = await SupplyOrderActionLogList.list({
      pk: state.DispensingOrderSupplySequence?.selectedOrder?.id,
      apiToken: controller.apiToken,
      params: { limit: 999999 },
    });

    controller.setState({
      DispensingOrderSupplySequence: {
        ...state.DispensingOrderSupplySequence,
        actionLog: r ? r.items : [],
      },
    });
  } else if (params.action === "change") {
    const [r, e, n] = await SupplyOrderItemPreview.retrieve({
      pk: params.item?.id,
      apiToken: controller.apiToken,
      params: {
        change: params.change,
        encounter: "null",
        mode: params.item?.mode,
        product: params.item?.product,
        product_issue: params.item?.product_issue,
        quantity_request: params.item?.quantity_request,
        quantity_issue: params.item?.quantity_issue,
        quantity_postpone:
          params.change === "quantity_issue"
            ? params.item?.quantity_request - params.item?.quantity_issue
            : params.change === "quantity_postpone"
            ? params.item?.quantity_postpone
            : 0,
        eligibility_type: params.item?.eligibility_type,
        qa_claim_id: 0,
      },
    });

    state = controller.getState();

    const items = state.DispensingOrderSupplySequence?.selectedOrder?.items;
    const idx = items.findIndex((item: any) => item.id === r?.id);

    if (idx > -1) {
      items[idx] = r;
    }

    controller.setState({
      DispensingOrderSupplySequence: {
        ...state.DispensingOrderSupplySequence,
        selectedOrder: {
          ...state.DispensingOrderSupplySequence?.selectedOrder,
          items: [...items],
        },
      },
    });
  } else if (["PRINT", "REPRINT", "VERIFY"].includes(params.action)) {
    HandleUpdateSupplyOrder(controller, params);
  } else if (["CHECK", "RECEIVE"].includes(params.action)) {
    HandleUpdateSupplyOrder(controller, {
      ...params,
      note: "",
    });
  } else if (params.action === "DELIVER") {
    HandleUpdateSupplyOrder(controller, {
      ...params,
      note: "",
      data: {
        is_transporter:
          state?.selectedEncounter?.type === "IPD" &&
          state?.DispensingOrderSupplySequence?.isTransporter
            ? true
            : false,
      },
    });
  } else if (
    ["UNCHECK", "UNVERIFY", "UNDELIVER", "CANCEL"].includes(params.action)
  ) {
    const [r, e, n] = await SupplyOrderDetail.update({
      pk: state.DispensingOrderSupplySequence?.selectedOrderId,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
      data: {
        action: params?.action,
        co_user: state.DispensingOrderSupplySequence?.coUserToken,
        is_transporter: false,
        note: params?.note,
        items: state.DispensingOrderSupplySequence?.selectedOrder?.items,
      },
    });

    if (e) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: r },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
        DispensingOrderSupplySequence: {
          ...state.DispensingOrderSupplySequence,
          openModInfo: true,
          titlePopup: `${params.action} สำเร็จ`,
        },
      });

      GetSupplyDetail(controller, params);
    }
  } else if (params.action === "returnSupply") {
    const [r, e, n] = await SupplyReturnItemList.create({
      data: params.item,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (e) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: r },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
        DispensingOrderSupplySequence: {
          ...state.DispensingOrderSupplySequence,
          openModInfo: true,
          titlePopup: "รับคืนเวชภัณฑ์สำเร็จ",
        },
      });

      GetSupplyDetail(controller, params);
    }
  }
};

export const HandleGetDeliverSupplyTokenization: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    DispensingOrderSupplySequence: {
      ...state.DispensingOrderSupplySequence,
      loading: true,
    },
  });

  const [response, error, network] = await HandleGetEmployeeTokenization(
    controller as any,
    {
      code: params.code,
    }
  );

  if (error) {
    controller.setState({
      DispensingOrderSupplySequence: {
        ...state.DispensingOrderSupplySequence,
        error: error,
        loading: false,
      },
    });
    return;
  }

  controller.setState({
    DispensingOrderSupplySequence: {
      ...state.DispensingOrderSupplySequence,
      coUserToken: response?.token || "",
      coUser: decodeURIComponent(atob(response?.token.split(".")[1])) || "",
      loading: false,
    },
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */

const GetSupplyList = async (controller: any, params: any, init = false) => {
  const [r, e, n] = await SupplyOrderQueueList.list({
    params: {
      limit: SUPPLY_ORDER_LIMIT,
      order_perform_div: controller.data.division,
    },
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  if (init) {
    controller.setState({
      DispensingOrderSupplySequence: {
        ...StateInitial.DispensingOrderSupplySequence,
        sequenceIndex: "SearchAndSelect",
        orderQueue: r ? [...r.items] : [],
      },
    });
  } else {
    const state = controller.getState();

    controller.setState({
      DispensingOrderSupplySequence: {
        ...state.DispensingOrderSupplySequence,
        orderQueue: r ? [...r.items] : [],
      },
    });
  }
};

const GetSupplyDetail: Handler = async (controller) => {
  let state = controller.getState();

  const [r2, e2, n2] = await SupplyOrderDetail.retrieve({
    pk: state.DispensingOrderSupplySequence?.selectedOrderId,
    apiToken: controller.apiToken,
  });

  state = controller.getState();

  if (r2?.items) {
    r2.items = r2.items.map((item: any) => ({
      ...item,
      quantity_issue:
        r2.status === "REQUESTED" ? item.quantity_request : item.quantity_issue,
    }));
  }

  controller.setState({
    DispensingOrderSupplySequence: {
      ...state.DispensingOrderSupplySequence,
      selectedOrder: r2 ? { ...r2 } : null,
    },
  });
};

const HandleUpdateSupplyOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  if (
    params.action === "DELIVER" &&
    !state.DispensingOrderSupplySequence?.coUserToken &&
    params.deliverBy === "transporter"
  ) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: { ผู้จัดส่ง: "กรุณาระบุรหัสพนักงาน" } },
      },
    });

    return;
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const [r, e, n] = await SupplyOrderDetail.update({
    pk: state.DispensingOrderSupplySequence?.selectedOrderId,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
    data: {
      action: params.action,
      co_user: state.DispensingOrderSupplySequence?.coUserToken,
      is_transporter: false,
      note: params.note,
      items: state.DispensingOrderSupplySequence?.selectedOrder?.items,
      ...(params.data || {}),
    },
  });

  if (e) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: e },
      },
    });
  } else {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.card]: r },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: null },
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
      DispensingOrderSupplySequence: {
        ...state.DispensingOrderSupplySequence,
        openModInfo: true,
        titlePopup: `${params.action} สำเร็จ`,
        coUser: "",
        coUserToken: "",
      },
    });

    GetSupplyDetail(controller, params);
  }
};
