import WasmController from "react-lib/frameworks/WasmController";

// APIs
// QUE
import Scheduling from "issara-sdk/apis/Scheduling_apps_QUE";
import DSBPatientAppointmentCountView from "issara-sdk/apis/DSBPatientAppointmentCountView_apps_QUE";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
// APP
import AppointmentPackageList from "issara-sdk/apis/AppointmentPackageList_apps_APP";
import AppointmentPackageDetail from "issara-sdk/apis/AppointmentPackageDetail_apps_APP";

// Serializer
import AppointmentPackageSerializerI from "issara-sdk/types/AppointmentPackageSerializer_apps_APP";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { APPOINTMENT_STATUS } from "react-lib/apps/Scheduling/common/BlockList";
import { ProductTypeKey, UsageLimitType } from "./SettingPackage";
import { SetErrorMessage, SetProperty } from "../../common/CommonInterface";

import moment from "moment";

// Utils
import { serialToDate } from "react-lib/apps/Scheduling/common/Utils";

export type State = Partial<{
  // sequence
  PackageDateTimeSequence: Partial<{
    sequenceIndex: "START" | "Action" | null;
    appointmentPackageDetail: AppointmentPackageSerializer;
    packageBlockList: PackageBlockDetailType[];
    selectedPackageItems: PackageItemSerializer[];
  }> | null;
}>;

export type PickedState = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "django"
    | "searchedItemListWithKey"
    | "successMessage"
    | "selectedDivision"
    | "selectedPatient"
    | "selectedAppointment"
    | "appointmentList"
    | "userTokenize"
  >
>;

export type PickedProps = Partial<
  Omit<PickedState, "django" | "selectedPatient" | "appointmentList">
>;

export type AppointmentPackageSerializer = {} & AppointmentPackageSerializerI;

export type PackageItemSerializer = {
  id: number;
  product: number;
  product_code: string;
  product_name: string;
  usage_limit?: UsageLimitType;
  items: PackageItemDetailType[];
};

export type PackageBlockDetailType = {
  dsb_id: number;
  appointment_type: "PACKAGE";
  start: Date;
  end: Date;
  time: string;
  count: number;
  division_name: string;
  division: number;
  doctor_note?: string;
};

export type PackageItemDetailType = {
  id?: number;
  quantity_appointment?: string | null;
  balance?: number | null;
  product_code: string;
  product_name: string;
  p_type_code: ProductTypeKey;
  p_type_name: string;
  unit_price: string;
  price: string;
  created_at: string;
  edited_at: string;
  quantity: string;
  quantity_used: string;
  active: boolean;
  created_by: any;
  edited_by: any;
  package_order: number;
  package_item: number;
  product: number;
  quantity_left?: string;
};

export type MasterOptionsType = Record<
  (typeof Masters)[number][0],
  OptionType[]
>;

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "PackageDateTime";
  restart?: boolean;
  clear?: boolean;
  contentId?: number;
  card?: string;
};

// Handle Action
type ActionType =
  // Action
  | { action: "GET_DSB"; startDate: Date; endDate: Date }
  // Method
  | {
      action: "MAKE_APPOINTMENT";
      card: string;
      errorKey: string;
      data: Partial<PackageBlockDetailType>;
      onSuccess?: Function;
    }
  | {
      action: "CANCEL";
      card: string;
      statusNote: string;
      onSuccess?: Function;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

export type SetProp = SetProperty<State & PickedState>;

type Params<A extends ActionType["action"]> = Extract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  PackageDateTimeSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

const Masters = [
  ["postponeAppointment", {}],
  ["orCancelNote", {}],
  ["division", {}],
] as const;

export const SEQ_ACTIONS = {
  GET_DSB: "GET_DSB",
  MAKE_APPOINTMENT: "MAKE_APPOINTMENT",
  CANCEL: "CANCEL",
} as const;

const DATE_FORMAT = "YYYY-MM-DD";

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  let state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  let appointmentDetail: any = {};

  if (params.contentId) {
    appointmentDetail = await GetAppointmentPackage(controller, {
      appointmentId: params.contentId,
    });
  }

  state = controller.getState();

  controller.setState({
    PackageDateTimeSequence: {
      ...state.PackageDateTimeSequence,
      sequenceIndex: "Action",
      packageBlockList: [],
      selectedPackageItems: appointmentDetail.items || [],
      appointmentPackageDetail: appointmentDetail,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.card || ""]: "SUCCESS",
    },
  });
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === SEQ_ACTIONS.GET_DSB) {
    HandleGetDSB(controller, params);
  } else if (params.action === SEQ_ACTIONS.MAKE_APPOINTMENT) {
    HandleMakeAppointment(controller, params);
  } else if (params.action === SEQ_ACTIONS.CANCEL) {
    HandleCancelAppointment(controller, params);
  }
};

const HandleGetDSB: Handler<Params<"GET_DSB">> = async (controller, params) => {
  let state = controller.getState();

  const [dsb] = await Scheduling.get({
    command: "DivisionServiceBlockFilter",
    params: {
      divisions: [state.selectedDivision?.id],
      start_date: moment(params.startDate).format(DATE_FORMAT),
      end_date: moment(params.endDate).format(DATE_FORMAT),
    },
    apiToken: controller.apiToken,
  });

  const dsbIds = (dsb || []).map((item: any) => item.dsb_id);

  const [patientCount] = await DSBPatientAppointmentCountView.post({
    apiToken: controller.apiToken,
    data: {
      dsb_id_list: dsbIds,
    },
  });

  state = controller.getState();

  const blockList = (dsb || []).map((item: any) => {
    const startDate = serialToDate(item.start_serial);
    const endDate = serialToDate(item.end_serial);

    return {
      dsb_id: item.dsb_id,
      appointment_type: "PACKAGE",
      start: startDate,
      end: endDate,
      time: `${moment(startDate).format("HH:mm")} - ${moment(endDate).format(
        "HH:mm"
      )}`,
      count: patientCount?.[item.dsb_id],
      division_name: state.selectedDivision?.name,
      division: state.selectedDivision?.id,
    };
  });

  controller.setState({
    PackageDateTimeSequence: {
      ...state.PackageDateTimeSequence,
      packageBlockList: blockList,
    },
  });
};

const HandleMakeAppointment: Handler<Params<"MAKE_APPOINTMENT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [result, error] = await (state.selectedAppointment?.id
    ? UpdatePackageAppointment(controller, params)
    : CreatePackageAppointment(controller, params));

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    RefreshAppointment(controller, {
      contentId: result.content_id || result.id,
      // เพื่อให้หน้า Select package success
      card: "CARD_SELECT_PACKAGE",
      onSuccess: () => {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: null,
          },
        });

        params.onSuccess?.();
      },
    });
  }
};

const HandleCancelAppointment: Handler<Params<"CANCEL">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const result = await PatientAppointmentUpdate.patch({
    pk: state.selectedAppointment?.id,
    data: {
      status: APPOINTMENT_STATUS.REJECT,
      status_note: params.statusNote,
    },
    apiToken: controller.apiToken,
  });

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    controller.setState({
      selectedAppointment: null,
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: null,
      },
    });

    RefreshAppointment(controller, {
      ...params,
      contentId: state.selectedAppointment?.content_id,
    });
  }
};

const RefreshAppointment: Handler<{
  contentId: number;
  card: string;
  onSuccess?: Function;
}> = async (controller, params) => {
  const state = controller.getState();

  const [patientApp] = await PatientAppointmentView.list({
    params: {
      patient_id: state.selectedPatient?.id,
      exclude_cancel: true,
    },
    apiToken: controller.apiToken,
  });

  const items: any[] = patientApp?.items || [];

  const appointment = items.find(
    (item) => params.contentId === item.content_id
  );

  controller.setState({ appointmentList: items });

  if (appointment) {
    controller.handleEvent({
      message: "SelectAppointment" as any,
      params: { ...appointment },
    });

    GetMaster(controller, {
      sequence: "PackageDateTime",
      contentId: params.contentId,
      card: params.card,
    });
  }

  params.onSuccess?.();
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetAppointmentPackage: Handler<
  { appointmentId: number },
  Promise<AppointmentPackageSerializer[]>
> = async (controller, params) => {
  const [appointment] = await AppointmentPackageDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.appointmentId,
  });

  const items: (PackageItemDetailType & {
    package: number;
    package_code: string;
    package_name: string;
    package_order_item: number;
  })[] = appointment?.items || [];

  const group = items.reduce((result, item) => {
    const code = item.package_code;
    const data = {
      ...item,
      balance: Number(item.quantity_left),
    };

    if (result[code]) {
      result[code].items.push(data);
    } else {
      result[code] = {
        id: item.package_order_item,
        product: item.package,
        product_code: item.package_code,
        product_name: item.package_name,
        items: [data],
      };
    }

    return result;
  }, {} as Record<string, PackageItemSerializer>);

  return { ...(appointment || {}), items: Object.values(group) };
};

const CreatePackageAppointment: Handler<{
  data: Partial<PackageBlockDetailType>;
}> = async (controller, params) => {
  const state = controller.getState();

  const items = state.PackageDateTimeSequence?.selectedPackageItems || [];

  const packageItems = items.flatMap((item) =>
    item.items
      .filter((acc) => acc.active)
      .map((acc) => ({
        package_order_item: acc.id,
        quantity_appointment: acc.quantity_appointment,
      }))
  );

  return AppointmentPackageList.create({
    apiToken: controller.apiToken,
    data: {
      patient: state.selectedPatient?.id,
      dsb_id: params.data.dsb_id,
      doctor_note: params.data.doctor_note,
      items: packageItems,
    } as any,
    extra: {
      division: controller.data.division,
    },
  });
};

const UpdatePackageAppointment: Handler<{
  data: Partial<PackageBlockDetailType>;
}> = (controller, params) => {
  const state = controller.getState();

  return PatientAppointmentUpdate.patch({
    pk: state.selectedAppointment?.id,
    data: {
      status: 1,
      order_note: params.data.doctor_note,
      division_service_block: params.data.dsb_id,
      estimated_at_iso: moment(params.data.start).toISOString(),
    },
    apiToken: controller.apiToken,
  });
};
