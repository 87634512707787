import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getQueueList", params: any }
  |  { message: "getInvoice", params: any }
  |  { message: "getInvoiceDetail", params: any }
  |  { message: "postInvoice", params: any }
  |  { message: "getInvoiceItemByItem", params: any }
  |  { message: "postPaymentParameters", params: any }
  |  { message: "getReceipt", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getQueueList: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, params = _b.params;
//            return __awaiter(_this, void 0, void 0, function () {
//                var bilQueueData, bilQueueError, bilService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            bilQueueData = {};
//                            bilQueueError = null;
//                            bilService = new BillService_1.default();
//                            return [4 /*yield*/, utils_1.to(bilService.getQueueList({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                bilQueueData = response;
//                            }
//                            else {
//                                bilQueueError = error;
//                            }
//                            return [2 /*return*/, [bilQueueData, bilQueueError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params;
//        return this.client
//            .get(apis_1.BIL.BIL_QUEUE_LIST, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
///apis/BIL/opd-queue/
}

export const getInvoice: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientId = _b.patientId, status = _b.status, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, bilService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (patientId) {
//                                params.patient = patientId;
//                            }
//                            if (status) {
//                                params.status = status;
//                            }
//                            if (divisionId) {
//                                params.division = divisionId;
//                            }
//                            bilService = new BillService_1.default();
//                            return [4 /*yield*/, utils_1.to(bilService.getInvoice({ params: params, apiToken: apiToken }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client.get(apis_1.BIL.INVOICE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/BIL/invoice/
}

export const getInvoiceDetail: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, invoiceId = _b.invoiceId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, bilService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            bilService = new BillService_1.default();
//                            return [4 /*yield*/, utils_1.to(bilService.getInvoiceDetail({ params: params, apiToken: apiToken, invoiceId: invoiceId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, invoiceId = _b.invoiceId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client.get(apis_1.BIL.INVOICE_DETAIL({ invoiceId: invoiceId }), { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/BIL/invoice/' +
//((__t = (invoiceId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const postInvoice: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, encounterId = _b.encounterId, items = _b.items, patientId = _b.patientId, type = _b.type, device = _b.device;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, bilService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            if (encounterId) {
//                                data.encounter = encounterId;
//                            }
//                            if (items) {
//                                data.items = items;
//                            }
//                            if (patientId) {
//                                data.patient = patientId;
//                            }
//                            if (type) {
//                                data.type = type;
//                            }
//                            bilService = new BillService_1.default();
//                            return [4 /*yield*/, utils_1.to(bilService.postInvoice({ params: params, apiToken: apiToken, data: data, device: device }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, data = _b.data, device = _b.device;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        if (device) {
//            this.client.defaults.headers["device"] = device;
//        }
//        return this.client.post(apis_1.BIL.INVOICE, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/BIL/invoice/
}

export const getInvoiceItemByItem: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, encounterId = _b.encounterId, patientId = _b.patientId, invoiceId = _b.invoiceId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, bilService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            if (patientId) {
//                                params.patient = patientId;
//                            }
//                            if (invoiceId) {
//                                params.invoice = invoiceId;
//                            }
//                            bilService = new BillService_1.default();
//                            return [4 /*yield*/, utils_1.to(bilService.getInvoiceItemByItem({ params: params, apiToken: apiToken }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client.get(apis_1.BIL.INVOICEITEM_BYITEM, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/BIL/invoiceitem/byitem/
}

export const postPaymentParameters: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, invoiceId = _b.invoiceId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, bilService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            if (invoiceId) {
//                                data.invoice = invoiceId;
//                            }
//                            bilService = new BillService_1.default();
//                            return [4 /*yield*/, utils_1.to(bilService.postPaymentParameters({ params: params, data: data, apiToken: apiToken }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client.post(apis_1.BIL.PAYMENT_PARAMETERS, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/BIL/payment/parameters/
}

export const getReceipt: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientId = _b.patientId, divisionId = _b.divisionId, fromDate = _b.fromDate;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, bilService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (patientId) {
//                                params.patient = patientId;
//                            }
//                            if (divisionId) {
//                                params.division = divisionId;
//                            }
//                            if (fromDate) {
//                                params.from_date = fromDate;
//                            }
//                            bilService = new BillService_1.default();
//                            return [4 /*yield*/, utils_1.to(bilService.getReceipt({ params: params, apiToken: apiToken }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client.get(apis_1.BIL.RECEIPT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/BIL/receipt/
}
