import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSupplyServiceCheckingUX = (props: any) => {
    return(
      <div
        id="CardSupplyServiceChecking"
        style={{ position: "relative", padding: "10px"}}>
        <div
          className="ui form">

          <div
            className="ui form"
            style={{fontSize: "1.2rem", fontWeight: "bold"}}>
            ตรวจสอบรายการสินค้า และบริการภายในโรงพยาบาล
          </div>
        </div>
        <div
          className="ui divider">

        </div>
        <div
          className="ui form"
          style={{padding: "10px 15px 5px", marginBottom: "-10px"}}>

          <FormGroup>
            <FormField
              className="noPadding --hard">
              <label>
                รหัสค่ารักษา
              </label>
              <Input
                icon="search"
                name="filter.code"
                onChange={props.onChangeData}
                value={props.SupplyServiceCheckingSequence?.code}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              style={{marginLeft: "20px"}}
              width={4}>
              <label>
                ชื่อรายการ
              </label>
              <Input
                icon="search"
                name="filter.name"
                onChange={props.onChangeData}
                value={props.SupplyServiceCheckingSequence?.name}>
              </Input>
            </FormField>
            <Checkbox
              checked={props.SupplyServiceCheckingSequence?.filter?.isType === true}
              name="filter.isType"
              onChange={props.onChangeData}
              style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
            </Checkbox>
            <FormField
              className="noPadding --hard"
              style={{marginLeft: "10px"}}>
              <label>
                ประเภทค่ารักษา
              </label>
              <Dropdown
                disabled={props.SupplyServiceCheckingSequence?.filter?.isType === false}
                name="filter.type"
                onChange={props.onChangeData}
                options={props.typeOptions}
                selection={true}
                value={props.SupplyServiceCheckingSequence?.filter?.type}>
              </Dropdown>
            </FormField>
            <Checkbox
              checked={props.SupplyServiceCheckingSequence?.filter?.isStatus === true}
              name="filter.isStatus"
              onChange={props.onChangeData}
              style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
            </Checkbox>
            <FormField
              className="noPadding --hard"
              style={{marginLeft: "10px"}}>
              <label>
                สถานะ
              </label>
              <Dropdown
                disabled={props.SupplyServiceCheckingSequence?.filter?.isStatus === false}
                name="filter.status"
                onChange={props.onChangeData}
                options={props.SupplyServiceCheckingSequence?.statusOptions}
                selection={true}
                value={props.SupplyServiceCheckingSequence?.filter?.status}>
              </Dropdown>
            </FormField>
            <Checkbox
              checked={props.SupplyServiceCheckingSequence?.filter?.isCategory === true}
              name="filter.isCategory"
              onChange={props.onChangeData}
              style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
            </Checkbox>
            <FormField
              className="noPadding --hard"
              style={{marginLeft: "10px"}}>
              <label>
                หมวดค่ารักษา
              </label>
              <Dropdown
                disabled={props.SupplyServiceCheckingSequence?.filter?.isCategory === false}
                name="filter.category"
                onChange={props.onChangeData}
                options={props.categoryOptions}
                selection={true}
                value={props.SupplyServiceCheckingSequence?.filter?.category}>
              </Dropdown>
            </FormField>
            <FormField>
              <label>
                {"\u00A0"}
              </label>
              <div>
                {props.btnSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{ height: "100%", position: "relative" }}>

          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.tableItems || []}
            headers="รหัสค่ารักษา, ชื่อรายการ (TH), ชื่อรายการ (ENG), หน่วย, ประเภทค่ารักษา, สถานะ, หมวดค่ารักษา, ราคา Normal, ราคา Special, ราคา Premium, ราคา Foreign, วันที่เริ่มต้นใช้งานราคา, วันที่สิ้นสุดใช้งานราคา"
            keys="code, product.name, product.name_en, product.unit_name, product.p_type_code, product.active_flag_label, bill_mode_name, price_normal, price_special, price_premium, price_foreign, start_date, end_date"
            minRows="20"
            onPageChange={props.onPageChange}
            onPageSizeChange={props.onPageSizeChange}
            page={props.paginatedItems?.page || 0}
            pageSize={props.paginatedItems?.pageSize || 0}
            pages={Math.ceil(props.paginatedItems?.total / props.paginatedItems?.pageSize)}
            style={{ height: "calc(100vh - 14.5rem)"}}>
          </Table>
        </div>
      </div>
    )
}


export default CardSupplyServiceCheckingUX

export const screenPropsDefault = {}

/* Date Time : Wed Nov 13 2024 15:46:33 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSupplyServiceChecking"
        },
        "style": {
          "type": "code",
          "value": "{ position: \"relative\", padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ตรวจสอบรายการสินค้า และบริการภายในโรงพยาบาล"
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 15px 5px\", marginBottom: \"-10px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormGroup",
      "parent": 19,
      "props": {
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 20,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสค่ารักษา"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Input",
      "parent": 21,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "filter.code"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.code"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 20,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อรายการ"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Input",
      "parent": 24,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "filter.name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.name"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 20,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.filter?.isType === true"
        },
        "name": {
          "type": "value",
          "value": "filter.isType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginLeft: \"20px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทค่ารักษา"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Dropdown",
      "parent": 27,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.filter?.isType === false"
        },
        "name": {
          "type": "value",
          "value": "filter.type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.typeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.filter?.type"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.filter?.isStatus === true"
        },
        "name": {
          "type": "value",
          "value": "filter.isStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginLeft: \"20px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 20,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.filter?.isStatus === false"
        },
        "name": {
          "type": "value",
          "value": "filter.status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.statusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.filter?.status"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.filter?.isCategory === true"
        },
        "name": {
          "type": "value",
          "value": "filter.isCategory"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginLeft: \"20px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 20,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "หมวดค่ารักษา"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.filter?.isCategory === false"
        },
        "name": {
          "type": "value",
          "value": "filter.category"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.categoryOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.SupplyServiceCheckingSequence?.filter?.category"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", position: \"relative\" }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 41,
      "name": "Table",
      "parent": 40,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.tableItems || []"
        },
        "headers": {
          "type": "value",
          "value": "รหัสค่ารักษา, ชื่อรายการ (TH), ชื่อรายการ (ENG), หน่วย, ประเภทค่ารักษา, สถานะ, หมวดค่ารักษา, ราคา Normal, ราคา Special, ราคา Premium, ราคา Foreign, วันที่เริ่มต้นใช้งานราคา, วันที่สิ้นสุดใช้งานราคา"
        },
        "keys": {
          "type": "value",
          "value": "code, product.name, product.name_en, product.unit_name, product.p_type_code, product.active_flag_label, bill_mode_name, price_normal, price_special, price_premium, price_foreign, start_date, end_date"
        },
        "minRows": {
          "type": "value",
          "value": "20"
        },
        "onPageChange": {
          "type": "code",
          "value": "props.onPageChange"
        },
        "onPageSizeChange": {
          "type": "code",
          "value": "props.onPageSizeChange"
        },
        "page": {
          "type": "code",
          "value": "props.paginatedItems?.page || 0"
        },
        "pageSize": {
          "type": "code",
          "value": "props.paginatedItems?.pageSize || 0"
        },
        "pages": {
          "type": "code",
          "value": "Math.ceil(props.paginatedItems?.total / props.paginatedItems?.pageSize)"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"calc(100vh - 14.5rem)\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 20,
      "props": {
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00A0\""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.btnSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 44,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardSupplyServiceCheckingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
