import ORMService from "../services/ORMService";
import { to } from "../../utils";
export default class ORMManager {
  apiToken?: string | null;

  constructor(apiToken?: string | null) {
    this.apiToken = apiToken
  }

  // order
  getOperatingOrder = async (encounterId: any) => {
    let params = {
      active: "true",
      encounter: encounterId,
    };
    var order = {};
    var orderError = null;
    const ormService = new ORMService(this.apiToken);
    const [error, response] = await to(ormService.getOperatingOrder(params));
    orderError = error;
    if (response) {
      order = response;
    }
    return [order, orderError];
  };
  postDeliverOperatingAppointment = async ({
    encounterId,
    operatingOrderList,
  }: any={}) => {
    let params = {};
    let data: Record<string, any> = {};
    var deliver = [];
    var deliverError = null;
    const ormService = new ORMService(this.apiToken);
    if (encounterId) {
      data.encounter = encounterId;
    }
    if (operatingOrderList) {
      data.operating_order_list = operatingOrderList;
    }
    const [error, response] = await to(
      ormService.postDeliverOperatingAppointment({ params, data })
    );
    if (response) {
      deliver = response.items;
    } else {
      deliverError = error;
    }
    return [deliver, deliverError];
  };
  // location
  getOrLocation = async () => {
    let params = {};
    const ormService = new ORMService(this.apiToken);
    const [error, response, network] = await to(
      ormService.getOrLocation(params)
    );
    return [response, error, network];
  };
  // image
  getOperativeImage = async (operativeNoteId: any) => {
    const ormService = new ORMService(this.apiToken);
    const [error, response, network] = await to(
      ormService.getOperativeImage(operativeNoteId)
    );
    return [response, error, network];
  };
  getOperatingProcedureSummary = async (params: any) => {
    const ormService = new ORMService(this.apiToken);
    const [error, response, network] = await to(
      ormService.getOperatingProcedureSummary(params)
    );
    return [response, error, network];
  };
  // team
  printOperativeNote = async (teamId: any) => {
    const ormService = new ORMService(this.apiToken);
    const [error, response, network] = await to(
      ormService.printOperativeNote(teamId)
    );
    return [response, error, network];
  };
}
