export default class CardClassifyController {
  constructor(props) {
    this.coreManager = props.coreManager;
    this.prxManager = props.prxManager;
    this.userManager = props.userManager;
  }

  getTriageLevelClassify = async ({ apiToken, divisionId } = {}) => {
    const [response, error, network] =
      await this.prxManager.getTriageLevelClassify({
        apiToken,
        divisionId,
      });
    return [response, error, network];
  };

  createDivisionHasUser = async ({ apiToken, data } = {}) => {
    const [response, error, network] =
      await this.prxManager.createDivisionHasUser({ apiToken, data });
    return [response, error, network];
  };

  updateTriage = async ({ apiToken, encounterId, triageLevel, diagFormId = null } = {}) => {
    const [response, error, network] =
      await this.prxManager.patchEncounterReclassify({
        apiToken,
        encounterId,
        triageLevel,
        diagFormId
      });
    return [response, error, network];
  };

  getFinishedTriageLevel = async ({ data } = {}) => {
    const [response, error, network] = await this.userManager.getUserPermission(
      data
    );
    return [response, error, network];
  };

  getUserProfile = async ({ apiToken }) => {
    const [response, error, network] = await this.userManager.getUserProfile({
      apiToken,
    });
    return [response, error, network];
  };

  updateUserProfile = async ({ apiToken, firstName, lastName, dob, phone }) => {
    const [response, error, network] = await this.userManager.updateUserProfile(
      { apiToken, firstName, lastName, dob, phone }
    );
    return [response, error, network];
  };

  getListOARequestCheck = async ({ apiToken }) => {
    const [response, error, network] =
      await this.prxManager.getListOARequestCheck({ apiToken });
    return [response, error, network];
  };

  postCreatejoinOARequest = async ({ apiToken, token }) => {
    const [response, error, network] =
      await this.prxManager.postCreatejoinOARequest({ apiToken, token });
    return [response, error, network];
  };

  getUserPosition = async ({ apiToken }) => {
    const [response, error, network] = await this.userManager.getUserPosition({
      apiToken,
    });
    return [response, error, network];
  };

  getUserWithPatient = async ({ apiToken, userId }) => {
    const [response, error, network] = await this.userManager.getUserWithPatient({
      apiToken,
      userId
    });
    return [response, error, network];
  };

  getOAUserProfile = async ({ apiToken }) => {
    const [response, error, network] = await this.prxManager.getOAUserProfile({
      apiToken,
    });
    return [response, error, network];
  };

  patchOAUserProfile = async ({
    apiToken,
    email,
    first_name,
    last_name,
    dob,
    position,
    licence,
    image,
  }) => {
    const [response, error, network] =
      await this.prxManager.patchOAUserProfile({
        apiToken,
        email,
        first_name,
        last_name,
        dob,
        position,
        licence,
        image,
      });
    return [response, error, network];
  };
}
