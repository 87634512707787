import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'

const CardRiskOfBleedingUX = (props: any) => {
    return(
      <div>
        <div
          style={{height: "50px" }}>
          
          <div
            style={{ position: "absolute", right: "20px", display: "flex" }}>
            
            <label
              style={{ padding: "8px 5px 0px" }}>
              {`${props.data?.edit_by || ""}  ${props.data?.edited || ""}`}
            </label>
            <div>
              {props.ButtonSave}
            </div>
          </div>
        </div>
        <div
          className="ui form"
          style={{ border: "2px solid #0CABCF", padding: "20px" }}>
          
          <FormGroup
            inline={true}>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.active_bleeding}
                label="Active bleeding (Last 3 months unless low risk profile on endoscopy)"
                name="pre_op_medical_assessment.data.risk_bleeding.active_bleeding"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.intracranial_bleeding}
                label=" Intracranial bleeding within last year or until cleared by neurological services"
                name="pre_op_medical_assessment.data.risk_bleeding.intracranial_bleeding"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.active_gastroduodenal}
                label="Active gastroduodenal uicer"
                name="pre_op_medical_assessment.data.risk_bleeding.active_gastroduodenal"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.intraocular}
                label="Intraocular surgery within 2 weeks"
                name="pre_op_medical_assessment.data.risk_bleeding.intraocular"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.platelet_count}
                label="Platelet count < 50,000 QR < 100,000 and down trending"
                name="pre_op_medical_assessment.data.risk_bleeding.platelet_count"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.untreated}
                label="Untreated inherited bleeding disorders"
                name="pre_op_medical_assessment.data.risk_bleeding.untreated"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.platelet_dysfunction}
                label="Platelet dysfunction"
                name="pre_op_medical_assessment.data.risk_bleeding.platelet_dysfunction"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.hypertensive}
                label="Hypertensive urgency/emergency"
                name="pre_op_medical_assessment.data.risk_bleeding.hypertensive"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.therapeutic}
                label="Therapeutic level of anticoagulation"
                name="pre_op_medical_assessment.data.risk_bleeding.therapeutic"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.postoperative}
                label="Postoperative bleeding concerns"
                name="pre_op_medical_assessment.data.risk_bleeding.postoperative"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.advanced}
                label="Advanced liver disease with INR > 1.5"
                name="pre_op_medical_assessment.data.risk_bleeding.advanced"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.epidural}
                label="Epidural/Spinal anesthesia within previous 4 hours or expected within next 12 hours"
                name="pre_op_medical_assessment.data.risk_bleeding.epidural"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.heparin}
                label="Heparin-induced thrombocytopenia"
                name="pre_op_medical_assessment.data.risk_bleeding.heparin"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              width={8}>
              <Checkbox
                checked={props.data?.none_risk}
                disabled={props.data?.disabled_none_risk}
                name="pre_op_medical_assessment.data.risk_bleeding.none_risk"
                onChange={props.onChangeValue}
                style={{ color: "#1B9D2C", fontWeight: "bold" }}>
              </Checkbox>
              <label
                style={{ color: "#1B9D2C", fontWeight: "bold", ...( props.data?.disabled_none_risk && { opacity: "0.5" } ) }}>
                None of the above risk factor
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={8}>
              <label>
                Summary 
              </label>
              <Dropdown
                clearable={true}
                name="pre_op_medical_assessment.data.risk_bleeding.summary"
                onChange={props.onChangeValue}
                options={props.preAssessmentOptions || []}
                search={true}
                selection={true}
                value={props.data?.summary}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardRiskOfBleedingUX

export const screenPropsDefault = {}

/* Date Time : Thu Jul 25 2024 11:24:52 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Checkbox",
      "parent": 3,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.active_bleeding"
        },
        "label": {
          "type": "value",
          "value": "Active bleeding (Last 3 months unless low risk profile on endoscopy)"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.active_bleeding"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.intracranial_bleeding"
        },
        "label": {
          "type": "value",
          "value": " Intracranial bleeding within last year or until cleared by neurological services"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.intracranial_bleeding"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.active_gastroduodenal"
        },
        "label": {
          "type": "value",
          "value": "Active gastroduodenal uicer"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.active_gastroduodenal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Checkbox",
      "parent": 10,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.intraocular"
        },
        "label": {
          "type": "value",
          "value": "Intraocular surgery within 2 weeks"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.intraocular"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Checkbox",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.platelet_count"
        },
        "label": {
          "type": "value",
          "value": "Platelet count < 50,000 QR < 100,000 and down trending"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.platelet_count"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Checkbox",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.untreated"
        },
        "label": {
          "type": "value",
          "value": "Untreated inherited bleeding disorders"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.untreated"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onClick": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Checkbox",
      "parent": 18,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.platelet_dysfunction"
        },
        "label": {
          "type": "value",
          "value": "Platelet dysfunction"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.platelet_dysfunction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.hypertensive"
        },
        "label": {
          "type": "value",
          "value": "Hypertensive urgency/emergency"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.hypertensive"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onClick": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Checkbox",
      "parent": 23,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.therapeutic"
        },
        "label": {
          "type": "value",
          "value": "Therapeutic level of anticoagulation"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.therapeutic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Checkbox",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.postoperative"
        },
        "label": {
          "type": "value",
          "value": "Postoperative bleeding concerns"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.postoperative"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onClick": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.advanced"
        },
        "label": {
          "type": "value",
          "value": "Advanced liver disease with INR > 1.5"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.advanced"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 30,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.epidural"
        },
        "label": {
          "type": "value",
          "value": "Epidural/Spinal anesthesia within previous 4 hours or expected within next 12 hours"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.epidural"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onClick": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 32,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Summary "
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.summary"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data?.summary"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ border: \"2px solid #0CABCF\", padding: \"20px\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.data?.edit_by || \"\"}  ${props.data?.edited || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"8px 5px 0px\" }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"50px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ position: \"absolute\", right: \"20px\", display: \"flex\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Checkbox",
      "parent": 52,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.none_risk"
        },
        "disabled": {
          "type": "code",
          "value": "props.data?.disabled_none_risk"
        },
        "label": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.none_risk"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"#1B9D2C\", fontWeight: \"bold\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Checkbox",
      "parent": 51,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.heparin"
        },
        "label": {
          "type": "value",
          "value": "Heparin-induced thrombocytopenia"
        },
        "name": {
          "type": "value",
          "value": "pre_op_medical_assessment.data.risk_bleeding.heparin"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "None of the above risk factor"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"#1B9D2C\", fontWeight: \"bold\", ...( props.data?.disabled_none_risk && { opacity: \"0.5\" } ) }"
        }
      },
      "seq": 55,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardRiskOfBleedingUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
