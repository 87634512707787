import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  TextArea
} from 'semantic-ui-react'

const ANES_AnesComplication02 = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardAnesComplication02"
        style={{ margin: "0px 0px 50px" }}>
        <div
          style={{height:"50px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/41",display: "flex" , border: "solid 1px rgb(200, 200, 200, 0.5)","backgroundColor":"rgb(161, 221, 230, 0.49)", justifyContent: "space-between", alignItems: "center"}}>
              <label
                style={{textAlign:"center",marginLeft:"20px","fontWeight":"bold","fontSize":"16px"}}>
                Outcome
              </label>
              <div
                style={{ margin: "5px", display: props.buttonStatus === "CONFIRMED" ?  "none" : "", }}>
                {props.buttonSave}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{height:"27rem",border: "solid 1px rgb(200, 200, 200, 0.5)",}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(11, 1fr)","gridTemplateColumns":"repeat(1, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/2" }}>
              <Radio
                checked={props.AnesComplicationSequence?.Outcome === "Transfer to ward / Home as plan"}
                label="Transfer to ward / Home as plan"
                name="Outcome"
                onChange={props.onChangeData}
                style={{marginLeft:"20px", marginTop: "1.5rem"}}
                value="Transfer to ward / Home as plan">
              </Radio>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/2" }}>
              <Radio
                checked={props.AnesComplicationSequence?.Outcome === "Unplanned admit to ICU"}
                label="Unplanned admit to ICU"
                name="Outcome"
                onChange={props.onChangeData}
                style={{marginLeft:"20px", marginTop: "1rem"}}
                value="Unplanned admit to ICU">
              </Radio>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/2" }}>
              <Radio
                checked={props.AnesComplicationSequence?.Outcome === "Intensive card (ETT,Tracheostomytube,Ventilator,ICU)"}
                label="Intensive card (ETT,Tracheostomytube,Ventilator,ICU)"
                name="Outcome"
                onChange={props.onChangeData}
                style={{marginLeft:"20px", marginTop: "1rem"}}
                value="Intensive card (ETT,Tracheostomytube,Ventilator,ICU)">
              </Radio>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "1/2" }}>
              <Radio
                checked={props.AnesComplicationSequence?.Outcome === "Dead within 48 hours"}
                label="Dead within 48 hours"
                name="Outcome"
                onChange={props.onChangeData}
                style={{marginLeft:"20px", marginTop: "1rem"}}
                value="Dead within 48 hours">
              </Radio>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "1/2" }}>
              <Radio
                checked={props.AnesComplicationSequence?.Outcome === "DOT"}
                label="DOT"
                name="Outcome"
                onChange={props.onChangeData}
                style={{marginLeft:"20px", marginTop: "1rem"}}
                value="DOT">
              </Radio>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "1/2" }}>
              <Radio
                checked={props.AnesComplicationSequence?.Outcome === "Cancelled case before induction"}
                label="Cancelled case before induction"
                name="Outcome"
                onChange={props.onChangeData}
                style={{marginLeft:"20px", marginTop: "1rem"}}
                value="Cancelled case before induction">
              </Radio>
            </div>
            <div
              style={{ gridRow: "7/7", gridColumn: "1/2" }}>
              <Radio
                checked={props.AnesComplicationSequence?.Outcome === "Cancelled case after induction"}
                label="Cancelled case after induction"
                name="Outcome"
                onChange={props.onChangeData}
                style={{marginLeft:"20px", marginTop: "1rem"}}
                value="Cancelled case after induction">
              </Radio>
            </div>
            <div
              className="ui form"
              style={{ gridRow: "8/11", gridColumn: "1/2", display : "flex", width: "100%", paddingRight: "1rem", margin: "1rem 0 0rem"}}>
              <div
                style={{fontWeight: "bold", marginRight: "1rem", marginLeft: "20px"}}>
                เหตุผล: 
              </div>
              <div
                style={{width: "100%"}}>
                
                <TextArea
                  name="OutcomeRemark"
                  onChange={props.onChangeData}
                  rows={4}
                  style={{width: "100%"}}
                  value={props.AnesComplicationSequence?.OutcomeRemark}>
                </TextArea>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}


export default ANES_AnesComplication02

export const screenPropsDefault = {}

/* Date Time : Wed Dec 13 2023 09:43:01 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAnesComplication02"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 0px 50px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/41\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Outcome"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",\"fontWeight\":\"bold\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"27rem\",border: \"solid 1px rgb(200, 200, 200, 0.5)\",}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "label": "divgrid",
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(11, 1fr)\",\"gridTemplateColumns\":\"repeat(1, 1fr)\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Radio",
      "parent": 10,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.Outcome === \"Transfer to ward / Home as plan\""
        },
        "label": {
          "type": "value",
          "value": "Transfer to ward / Home as plan"
        },
        "name": {
          "type": "value",
          "value": "Outcome"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\", marginTop: \"1.5rem\"}"
        },
        "value": {
          "type": "value",
          "value": "Transfer to ward / Home as plan"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Radio",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.Outcome === \"Unplanned admit to ICU\""
        },
        "label": {
          "type": "value",
          "value": "Unplanned admit to ICU"
        },
        "name": {
          "type": "value",
          "value": "Outcome"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\", marginTop: \"1rem\"}"
        },
        "value": {
          "type": "value",
          "value": "Unplanned admit to ICU"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Radio",
      "parent": 14,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.Outcome === \"Intensive card (ETT,Tracheostomytube,Ventilator,ICU)\""
        },
        "label": {
          "type": "value",
          "value": "Intensive card (ETT,Tracheostomytube,Ventilator,ICU)"
        },
        "name": {
          "type": "value",
          "value": "Outcome"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\", marginTop: \"1rem\"}"
        },
        "value": {
          "type": "value",
          "value": "Intensive card (ETT,Tracheostomytube,Ventilator,ICU)"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Radio",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.Outcome === \"Dead within 48 hours\""
        },
        "label": {
          "type": "value",
          "value": "Dead within 48 hours"
        },
        "name": {
          "type": "value",
          "value": "Outcome"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\", marginTop: \"1rem\"}"
        },
        "value": {
          "type": "value",
          "value": "Dead within 48 hours"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Radio",
      "parent": 18,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.Outcome === \"DOT\""
        },
        "label": {
          "type": "value",
          "value": "DOT"
        },
        "name": {
          "type": "value",
          "value": "Outcome"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\", marginTop: \"1rem\"}"
        },
        "value": {
          "type": "value",
          "value": "DOT"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Radio",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.Outcome === \"Cancelled case before induction\""
        },
        "label": {
          "type": "value",
          "value": "Cancelled case before induction"
        },
        "name": {
          "type": "value",
          "value": "Outcome"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\", marginTop: \"1rem\"}"
        },
        "value": {
          "type": "value",
          "value": "Cancelled case before induction"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/7\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Radio",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.Outcome === \"Cancelled case after induction\""
        },
        "label": {
          "type": "value",
          "value": "Cancelled case after induction"
        },
        "name": {
          "type": "value",
          "value": "Outcome"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\", marginTop: \"1rem\"}"
        },
        "value": {
          "type": "value",
          "value": "Cancelled case after induction"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 7,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/11\", gridColumn: \"1/2\", display : \"flex\", width: \"100%\", paddingRight: \"1rem\", margin: \"1rem 0 0rem\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\", display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\", }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "เหตุผล: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"1rem\", marginLeft: \"20px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "TextArea",
      "parent": 32,
      "props": {
        "name": {
          "type": "value",
          "value": "OutcomeRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "4"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.OutcomeRemark"
        }
      },
      "seq": 33,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_AnesComplication02",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
