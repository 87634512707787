import React, { useEffect, useState } from 'react'
import {
  Segment,
  Form,
  Icon,
  Grid,
  Header,
  Divider,
  Select,
  Button,
  Menu,
  Checkbox,
  Loader,
  Dimmer
} from "semantic-ui-react";
import _ from "../../compat/lodashplus"
import moment from "moment"
import DateTextBox from "../common/DateTextBox"
import PureReactTable from "../common/PureReactTable"

//Mod
import ErrorMessage from "react-lib/apps/common/ErrorMessage"
import ModInfo from "../common/ModInfo"

import { ModErrorType } from "./CardPreAdminQueueWard"

import * as Util from "../../utils";
import { useIntl } from "react-intl";
import * as UtilTS from "../../utils/printURL"
type MedAdminPrintBoxStateType = {
  PATIENT_DATA: any
  DJANGO: any,
  onClose?: () => void
  controller: any;
  onPrinted: () => any
}

type ParamsPrintMedRecord = {
  startDate?: string,
  endDate?: string,
  type?: string
}

const MedAdminPrintBoxInitailState: MedAdminPrintBoxStateType = {
  PATIENT_DATA: {},
  DJANGO: {},
  onClose: () => null,
  controller: null,
  onPrinted: () => null
}

// option choose drug
const drugOptions = [
  { key: "a", text: "ALL", value: "" },
  { key: "s", text: "STAT", value: "STAT" },
  { key: "o", text: "ONE_DOSE", value: "ONE_DOSE" },
  { key: "d", text: "ONE_DAY", value: "ONE_DAY" },
  { key: "c", text: "CONTINUE_PLAN", value: "CONTINUE_PLAN" },
];

const initialModError: ModErrorType = {
  error: null,
  type: "",
  open: false,
  title: ""
}

const addDay = (days, date = "") => {
  date = date || Util.formatDate(moment())
  const dateSplit = date.split("/").reverse()
  const dateString = moment(`${+dateSplit[0] - 543}-${dateSplit[1]}-${dateSplit[2]}`)
    .add(days, "days")
    .format("DD/MM/YYYY")
    .split("/");
  dateString[2] = (parseInt(dateString[2]) + 543).toString();

  return dateString.join("/")
}

const MedAdminPrintBox: React.FC<MedAdminPrintBoxStateType> = (props) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false)
  const [medRecordList, setMedRecordList] = useState<any[]>([])
  const [startDate, setStartDate] = useState(addDay(-4))
  const [endDate, setEndDate] = useState(Util.formatDate(moment()))
  const [selectedType, setSelectedType] = useState("ALL")
  const [openModError, setOpenModError] = useState<ModErrorType>(initialModError)

  useEffect(() => {
    loadMedicationRecord({})
  }, [])

  const loadMedicationRecord = async (setParams: ParamsPrintMedRecord) => {
    const params = {
      mode: "PRINT",
      patient: props.PATIENT_DATA.patient_id,
      ward: props.DJANGO.division.id,
      start_date: setParams.startDate || startDate,
      end_date: setParams.endDate || endDate,
      type: setParams.type
    }

    setIsLoading(true)
    const [res, err] = await props.controller.tpdManager.getListMedAdminRecord(params)

    if (err) {
      handleSelectedList([], true)
      setIsLoading(false)
    }
    else if (res) {
      handleSelectedList(res, true)
      setIsLoading(false)
    }

  }

  const displayDrugName = (drug_info) => {
    return drug_info.changing_detail?.split("\n")[0] || drug_info.name
  }

  const handleOnSelectMedicineType = (ev: any, type) => {
    loadMedicationRecord({
      type: type.value
    })
  }

  const handleSelectedList = (items: any[], is_selected) => {
    let list = _.map(items, (item: any, index) =>
    ({
      ...item,
      is_selected,
      id: item?.id || index,
    }))

    setMedRecordList(list)

  }
  const handleModalClose = () => {
    props.onClose && props.onClose()
  }

  const handlePrint = async () => {

    const items = medRecordList.filter(record => record.is_selected)
      .map(list => list.id)

    if (!items[0]) {
      setOpenModError({
        ...openModError,
        open: true,
        type: "warning",
        title: `กรุณาเลือกรายการ`
      })
      return
    }

    const params = {
      encounter_id: props.PATIENT_DATA.ENCOUNTER.encounter_id,
      items,
      date: startDate,
      pdf: true
    }

    setIsLoading(true)
    const [res, err, net] = await props.controller.tpdManager.getPrintMedRecord(params)

    setIsLoading(false)
    if (err) {
      setOpenModError({
        ...openModError,
        open: true,
        error: err
      })
    } else {
      const contentType = 'application/pdf';
      const byteCharacters = atob(res.pdf_b64data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: contentType
      });
      const blobUrl = URL.createObjectURL(blob);
      UtilTS.printURL(blobUrl)
      props.onPrinted && props.onPrinted()
    }

  }

  const handleChangeStartDate = (value) => {
    const endDate = addDay(4, value)

    loadMedicationRecord({
      startDate: value,
      endDate
    })
    setStartDate(value)
    setEndDate(endDate)
  }

  const handleChangeEndDate = (value) => {
    const startDate = addDay(-4, value)

    loadMedicationRecord({
      endDate: value,
      startDate
    })
    setStartDate(startDate)
    setEndDate(value)
  }

  const gridColumns = [
    {
      Header: " ",
      accessor: " ",
      width: 50,
      Cell: (row: any) =>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox style={{ transform: "scale(1.2)" }} checked={row.original.is_selected} />
        </div>,
      getProps: (_state, row) => ({
        onClick: () => {
          setMedRecordList(_.map(medRecordList, (item) =>
            (item.id === row.original.id) ?
              ((item.is_selected = !item.is_selected), item) : item
          ))
        },
      }),
    },
    {
      Header: "MEDICATION AND DOSAGE",
      accessor: " ",
      Cell: (row: any) => {
        const { original } = row
        const { solvent } = original
        return <div>
          <b>{displayDrugName(original)}</b>
          <p style={{ margin: "0.2em" }}>
            {original.changing_detail?.split("\n")[1] ||
              `${+original.dose} ${original.code_unit} ${original.code_method} ${original.code_frequency}`
            }</p>

          {
            solvent && <div style={{ marginTop: "8px" }}>
              <b>+ {displayDrugName(solvent)}</b>
              {/* <div style={{display:"grid", gridTemplateColumns:"25% 25%"}}> */}
              <Form style={{ margin: "0.3em 0 0 0.2em" }}>
                <Form.Group inline>
                  <Form.Field width={4}>
                    Conc. {solvent.conc}
                  </Form.Field>
                  <Form.Field width={4}>
                    VOLUME: {solvent.volume}
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field width={4}>
                    Rate: {solvent.rate}
                  </Form.Field>
                  <Form.Field width={4}>
                    Drip in {solvent.drip}
                  </Form.Field>
                </Form.Group>
              </Form>
              {/* </div> */}
            </div>
          }

        </div>
      }
    }
  ]

  return (
    <Segment>
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Grid >
        <Grid.Row >
          <Grid.Column
            width={8}
          >
            <Header as='h5'>{intl.formatMessage({ id: "พิมพ์ใบ Medications Administrations" })}</Header>
          </Grid.Column>
          <Grid.Column
            textAlign="right"
            width={8}
          >
            <Icon
              name="close"
              size="large"
              color="red"
              style={{ cursor: "pointer" }}
              onClick={handleModalClose}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <label>{intl.formatMessage({ id: "ประเภท" })}</label>
          </Form.Field>

          <Form.Field width={6}>
            <Select
              size="tiny"
              placeholder="ALL"
              options={drugOptions}
              onChange={handleOnSelectMedicineType}
              style={{ marginRight: 14 }}
            />
          </Form.Field>

          <Form.Field>
            <label>{intl.formatMessage({ id: "วันที่" })}</label>
          </Form.Field>
          <Form.Field width={4}>
            <DateTextBox
              // minDate={startDateRange}
              // maxDate={stopDateRange}
              value={startDate}
              onChange={handleChangeStartDate}
            />
          </Form.Field>
          <Form.Field >
            -
          </Form.Field>
          <Form.Field width={4}>
            <DateTextBox
              // minDate={startDateRange}
              // maxDate={stopDateRange}
              value={endDate}
              onChange={handleChangeEndDate}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <br />
            <PureReactTable
              className="table-left"
              //@ts-ignore
              style={{ height: "400px" }}
              data={medRecordList}
              // @ts-ignore
              showPagination={false}
              pageSize={4}
              columns={gridColumns}
              getTheadProps={() => {
                return {
                  style: {
                    display: "none"
                  }
                }
              }}
            />
            <br />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>

          <Form.Field >
            <label >Select</label>
          </Form.Field>
          <Form.Field width={4}>
            <Menu size="tiny" inverted>
              <Menu.Item
                name="ALL"
                color="purple"
                active={selectedType === "ALL"}
                onClick={() => {
                  setSelectedType("ALL")
                  handleSelectedList(medRecordList, true)
                }
                }
              />

              <Menu.Item
                name="NONE"
                color="purple"
                active={selectedType === "NONE"}
                onClick={() => {
                  setSelectedType("NONE")
                  handleSelectedList(medRecordList, false)
                }}
              />
            </Menu>
          </Form.Field>
          <Form.Field width={10} />
          <Form.Field width={2} >
            <Button color="teal" onClick={handlePrint}>Print</Button>
          </Form.Field>
        </Form.Group>
      </Form>

      <ModInfo
        type={openModError.type || "error"}
        titleName={openModError.title}
        closeOnDimmerClick
        open={openModError.open}
        onApprove={() => setOpenModError(initialModError)}
        onClose={() => setOpenModError(initialModError)}
      >
        {typeof openModError.error === "string" ?
          <p>{openModError.error}</p> :
          <ErrorMessage error={openModError.error} />
        }
      </ModInfo>

    </Segment>
  )
}

MedAdminPrintBox.defaultProps = MedAdminPrintBoxInitailState

export default MedAdminPrintBox