import WasmController from "react-lib/frameworks/WasmController";

// APIs
import PatientList from "issara-sdk/apis/PatientList_apps_REG";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import PatientFileList from "issara-sdk/apis/PatientFileList_apps_REG";
import ClinicalTerm from "issara-sdk/apis/ClinicalTermList_core";
import UserDetailAPIView from "issara-sdk/apis/UserDetailAPIView_users";

// Common
import { PickMainState } from "react-lib/apps/HISV3/common/CommonInterface";

export const ACTIONS = {
  SEARCH: "SEARCH",
  SAVE: "SAVE",
};

export const SEQUENCE_NAME = "MergePatient" as const;

export type State = Partial<{
  MergePatientSequence: Partial<{
    sequenceIndex: "START" | "Action" | null;
    searchText: string | null;
    patientList: any[] | null;
    sourcePatient: any;
    destinationPatient: any;
    mergeReason: string | null;
    mergeReasonOptions: any[] | null;
    mergePatientDetail: any;
  }> | null;
}>;

export type PickedState = PickMainState<
  | "buttonLoadCheck"
  | "successMessage"
  | "errorMessage"
  | "selectedPatient"
  | "masterOptions"
  | "userId"
>;

export const StateInitial = {
  MergePatientSequence: null,
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State & PickedState, Event, Data>, params?: any) => any;

const Masters = [
  ["mergeReason", {}],
  ["bloodType", {}],
  ["nationality", {}],
  ["race", {}],
] as const;

// อ้างอิงจาก core Patient
const GENDER_LIST: any = {
  M: { name_th: "ชาย", name_en: "Male" },
  F: { name_th: "หญิง", name_en: "Female" },
  U: { name_th: "ไม่แน่ชัด", name_en: "Undetermined" },
  PM: { name_th: "Male (Not Approved)", name_en: "Male (Not Approved)" },
  PF: { name_th: "Female (Not Approved)", name_en: "Female (Not Approved)" },
  MM: { name_th: "Men who have sex with men", name_en: "Men who have sex with men" },
  TG: { name_th: "Transgender", name_en: "Transgender" },
  undefined: { name_th: "", name_en: "" },
} as const;

const MARRIAGE_STATUS = [
  { id: "S", text: "โสด" },
  { id: "M", text: "สมรส" },
  { id: "E", text: "หมั้น" },
  { id: "D", text: "หย่า" },
  { id: "W", text: "หม้าย" },
  { id: "A", text: "แยกกันอยู่" },
] as const;

const PATIENT_FILE_TYPE = {
  MERGE: "MERGE",
  TRANSFER: "TRANSFER",
} as const;

/**============================================
**                  START
=============================================**/
export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({ message: "GetMasterData", params: { master: Masters } });

  // get MergeReason Options
  const getOptions = await GetMergeReasonOptions(controller);

  const mergeReasonOptions = getOptions?.items
    ?.filter((item: any) => item.active)
    .map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));

  controller.setState(
    {
      MergePatientSequence: {
        ...state.MergePatientSequence,
        sequenceIndex: "Action",
        searchText: "",
        destinationPatient: null,
        patientList: [],
        mergeReason: "",
        // mergePatientDetail: getMergePatientDetail,
        mergeReasonOptions: mergeReasonOptions || [],
      },
    },
    () => {
      Action(controller, { ...params, action: "FETCH_DATA" });
    }
  );
};

/**============================================
**                  ACTION
=============================================**/
export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "FETCH_DATA") {
    HandleFetchData(controller, params);
  } else if (params.action === "SEARCH") {
    HandleSearchPatient(controller, params);
  } else if (params.action === "MERGE") {
    HandleMergePatient(controller, params);
  }
};

/**============================================
**                  HANDLER
=============================================**/
const HandleFetchData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    MergePatientSequence: {
      ...state.MergePatientSequence,
      sourcePatient: state.selectedPatient,
    },
  });
};

const HandleSearchPatient: Handler = async (controller, params) => {
  const state = controller.getState();
  let getPatientId: number | null;

  if (!params.hn || params.hn.length <= 0) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [SEQUENCE_NAME]: "กรุณากรอก HN สำหรับรวมแฟ้มผู้ป่วย!",
      },
    });
    return;
  }

  //* กรณี Search HN แล้วมีหลาย Patients ตอนนี้จะดึง Patient คนแรกมา
  const [getPatientResp, getPatientErr] = await PatientList.list({
    apiToken: controller.apiToken,
    params: {
      hn: params.hn,
    },
    extra: {
      division: controller.data?.division,
    },
  });

  if (getPatientErr) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [SEQUENCE_NAME]: getPatientErr,
      },
    });
  }

  if (getPatientResp?.items?.length > 0) {
    getPatientId = getPatientResp?.items[0]?.id;

    if (getPatientResp?.items?.length > 1) {
      console.log(
        `พบจำนวนผู้ป่วย ${getPatientResp?.items?.length} คน จะใช้ข้อมูลคนแรก`,
        getPatientResp?.items
      );
    }
  } else {
    getPatientId = null;
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [SEQUENCE_NAME]: `ไม่พบผู้ป่วยที่ค้นหา!`,
      },
    });
    return;
  }

  // console.log("Yeti getPatient Resp: ", {resp: getPatientResp, id: getPatientId});

  // getPatientDetail
  const [getPatientDetailResp, getPatientDetailErr] = await PatientDetailView.retrieve({
    pk: getPatientId,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  if (getPatientDetailErr) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [SEQUENCE_NAME]: `ไม่พบข้อมูลผู้ป่วยที่ค้นหา!`,
      },
    });
  }

  // Format Data
  const getFormatData = await formatPatientData(controller, { data: getPatientDetailResp });
  const destinationPatientDataWithFormat = {
    ...getPatientDetailResp,
    ...getFormatData,
  };

  controller.setState({
    MergePatientSequence: {
      ...state.MergePatientSequence,
      patientList: getPatientResp,
      destinationPatient: destinationPatientDataWithFormat,
    },
  });
};

const HandleMergePatient: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!params.data?.reason) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [SEQUENCE_NAME]: `กรุณาระบุเหตุผลในการโอนรักษา!`,
      },
    });
    return;
  }

  if (!params.data?.password) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [SEQUENCE_NAME]: `กรุณาระบุรหัสผ่าน!`,
      },
    });
    return;
  }

  const getUserName = await GetUserName(controller, state.userId);

  const data = {
    source_patient: state.MergePatientSequence?.sourcePatient?.id,
    destination_patient: state.MergePatientSequence?.destinationPatient?.id,
    type: PATIENT_FILE_TYPE.MERGE,
    reason: params.data.reason,
    username: getUserName,
    password: params.data.password,
  };

  const getMergePatient = await GetMergePatient(controller, data);

  if (getMergePatient) {
    state = controller.getState();
    Start(controller, { restart: true });
  }
};

/**============================================
**                   APIS
=============================================**/
const GetMergeReasonOptions: Handler = async (controller) => {
  const state = controller.getState();

  const [mergeReasonOptionsResp, mergeReasonOptionsErr] = await ClinicalTerm.list({
    apiToken: controller.apiToken,
    params: {
      limit: 999,
      type: "REASON_PATIENT_FILE",
    },
    extra: {
      division: controller.data.division,
    },
  });

  if (mergeReasonOptionsErr) {
    console.warn("Error! Cannot get Merge Reason Options: ", mergeReasonOptionsErr);
    return [];
  } else {
    return mergeReasonOptionsResp;
  }
};

const GetUserName: Handler = async (controller, userId) => {
  const state = controller.getState();

  if (!userId) {
    console.error("[Merge Patient]: Error! Cannot get username!");
    return;
  }

  const [userDetailResp, userDetailErr] = await UserDetailAPIView.retrieve({
    pk: userId,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  if (userDetailErr) {
    console.error("[Merge Patient]: Error! get username: ", userDetailErr);
  }

  return userDetailResp?.username;
};

const GetMergePatient: Handler = async (controller, data) => {
  const state = controller.getState();

  if (!data) {
    console.error("[Merge Patient]: Error! Have no data for Merge Patient!");
  }

  const [mergePatientResp, mergePatientErr] = await PatientFileList.create({
    data: data,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  if (mergePatientErr) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [SEQUENCE_NAME]: mergePatientErr,
      },
    });

    return false;
  } else {
    controller.setState({
      successMessage: {
        ...state.successMessage,
        [SEQUENCE_NAME]: "รวมแฟ้มผู้ป่วยสำเร็จ!",
      },
    });

    return true;
  }
};

/**============================================
**                  UTILS
=============================================**/
const formatPatientData: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!params.data) {
    return {};
  }

  const data = params.data;

  // Gender
  const formatGender = GENDER_LIST[data?.gender];
  // Marriage
  const formatMarriage = MARRIAGE_STATUS.find((status) => status.id === data?.marriage)?.text || ``;
  // Blood Type
  const formatBloodType =
    state.masterOptions?.bloodType.find((bloodtype: any) => bloodtype.value === data?.blood_type)
      ?.text || ``;
  // Race
  const formatRace =
    state.masterOptions?.race.find((race: any) => race.value === data?.race)?.text || ``;
  // Nationality
  const formatNationality =
    state.masterOptions?.nationality.find((nat: any) => nat.value === data?.nationality)?.text ||
    ``;
  // Address (One Line)
  const formatAddress = formatPatientAddress(data?.present_address);

  return {
    format_gender_th: formatGender?.name_th,
    format_gender_en: formatGender?.name_en,
    format_marriage: formatMarriage,
    format_blood_type: formatBloodType,
    format_race: formatRace,
    format_nationality_th: formatNationality.split(" ").at(-1),
    format_nationality_en: formatNationality.split(" ")[0],
    format_present_address: formatAddress,
  };
};

const formatPatientAddress = (addrData: any) => {
  if (!addrData) {
    return null;
  }

  const formatNo = addrData?.no ? addrData.no : null;
  const formatName = addrData?.name ? `หมู่บ้าน ${addrData?.name}` : null;
  const formatTown = addrData?.town ? `หมู่ ${addrData?.town}` : null;
  const formatStreet = addrData?.street ? `ซ.${addrData?.street}` : null;
  const formatRoad = addrData?.road ? `ถ.${addrData?.road}` : null;
  const formatCity = addrData?.city_label
    ? addrData?.province_label === "กรุงเทพมหานคร"
      ? `แขวง${addrData?.city_label}`
      : `ต.${addrData?.city_label}`
    : null;
  const formatDistrict = addrData?.district_label
    ? addrData?.province_label === "กรุงเทพมหานคร"
      ? addrData?.district_label
      : `อ.${addrData?.district_label}`
    : null;
  const formatProvince = addrData?.province_label
    ? addrData?.province_label === "กรุงเทพมหานคร"
      ? addrData?.province_label
      : `จ.${addrData?.province_label}`
    : null;
  const formatZipCode = addrData?.zipcode ? addrData?.zipcode : null;

  return [
    formatNo,
    formatName,
    formatTown,
    formatStreet,
    formatRoad,
    formatCity,
    formatDistrict,
    formatProvince,
    formatZipCode,
  ]
    .filter(Boolean)
    .join(" ");
};
