import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardPayingArUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%", padding: "10px", marginTop:"-1rem" }}>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","rowGap":"5px","gridTemplateColumns":"repeat(8, 1fr)"}}>
          <div
            style={{ padding: "10px", gridRow: "1/2", gridColumn: "1/3",display: "none"  }}>
            <Label
              className="ribbon large title blue">
              {props.patientNameAndHN}
            </Label>
          </div>
          <div
            style={{ padding: "10px", gridRow: "1/2", gridColumn: "3/6",display: "none" }}>
            <Label
              className="large tag blue">
              {`${props.citizenID || "-"}`}
            </Label>
          </div>
          <div
            style={{ gridRow: "1/2", gridColumn: "7/9",display: "none"}}>
            <div
              style={{ fontSize: "1.2rem", paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
              รับชำระเงิน (Paying)
            </div>
          </div>
          <div
            style={{ gridRow: "3/4", gridColumn: "1/9", padding: "10px", display: "flex", margin: "0.5rem 0" }}>
            
            <Label
              className="ribbon large title blue">
              {`เลขที่ใบแจ้งหนี้ : ${props.bilReceiptCode || "-"}`}
            </Label>
            <Label
              className="large tag blue"
              style={{marginLeft: "5rem"}}>
              {`หน่วยงานต้นสังกัดที่เรียกเก็บ : ${props.selectPaymentAr?.payer_code_name  || "-"}`}
            </Label>
            <div
              style={{flex:1}}>
              
            </div>
            <div
              style={{ fontSize: "1.2rem", paddingRight: "10px",  height: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
              รับชำระเงิน (Paying)
            </div>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "1/2",  }}>
            
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold" }}>
              ยอดเบิกทั้งหมด
            </div>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "2/4", margin: "2.5px 0"  }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              {props.sendClaim || "0.00"}
            </div>
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "1/2", }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold" }}>
              ต้องชำระ
            </div>
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "2/4", margin: "2.5px 0"  }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.payable || "0.00"}
            </div>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "1/2",  }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold"}}>
              ค้างชำระ
            </div>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "2/4", margin: "2.5px 0" }}>
            <div
              className={`ui label large fluid ${Number(props.totalPrice?.payable) > Number(props.priceText?.totalText) ? "red" : "green"}`}
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.oweText || "0.00"}
            </div>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "4/5", }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              เงินสด
            </div>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "5/7",  }}>
            <div
              className="ui input"
              style={{ width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              
              <input
                onBlur={props.onBlurCashAmountText}
                onChange={props.onChangeCashAmountText}
                onKeyUp={props.onEnterCashAmountText}
                style={{  textAlign: "right" }}
                value={props.priceText?.cashAmountText}>
              </input>
            </div>
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "4/5", }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              เงินโอน
            </div>
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "5/7", margin: "2.5px 0" }}>
            <div
              className="ui label large fluid"
              id="txt-cashTransfer"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center",}}>
              {props.transferText || "0.00"}
            </div>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "4/5", ...(props.isDeposit && {gridRow: "7/8"})}}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              แคชเชียร์เช็ค
            </div>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "5/7", margin: "2.5px 0", ...(props.isDeposit && {gridRow: "7/8"})  }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.chequeText || "0.00"}
            </div>
          </div>
          <div
            style={{ gridRow: "8/9", gridColumn: "4/5", ...(props.isDeposit && {gridRow: "10/11"})  }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              รวม
            </div>
          </div>
          <div
            className="ui label large fluid"
            id="(props.priceText?.totalText || 0).toFixed(2)"
            style={{ gridRow: "8/9", gridColumn: "5/7", ...(props.isDeposit && {gridRow: "10/11"})}}>
            {props.totalText || "0.00"}
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "7/9", display: "flex", alignItems: "center" }}>
            <Button
              className="circular"
              color="green"
              icon="plus"
              size="mini"
              style={{opacity: 0, margin: "3px 10px", pointerEvents: "none"}}>
            </Button>
            <label
              style={{fontWeight: "bold"}}>
              ทอน
            </label>
            <div
              className="ui label large fluid"
              style={{marginLeft: "15px", textAlign: "right"}}>
              {props.invoiceChange || "0.00"}
            </div>
          </div>
          <div
            style={{ gridArea: "5 / 7 / 6 / 9", display: "flex", alignItems: "center"}}>
            <Button
              className="circular"
              color="green"
              icon="plus"
              onClick={()=> props.onAddPaymentMethod(2)}
              size="mini"
              style={{margin: "3px 10px"}}>
            </Button>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "7/8", ...(props.isDeposit && {gridRow: "7/8"}) }}>
            <Button
              className="circular"
              color="green"
              icon="plus"
              onClick={()=> props.onAddPaymentMethod(4)}
              size="mini"
              style={{margin: "3px 10px"}}>
              
            </Button>
          </div>
          <div
            style={{ gridArea: "3 / 1 / 4/ 9" , margin: "20px 0" }}>
            {props.ErrorMessage}
          </div>
          <div
            style={{ gridArea: "12 / 1 / 12/ 9" , margin: "10px 0" , padding: "0 2rem"}}>
            {props.SubRepeaterPayMethod}
          </div>
          <div
            style={{ gridArea: "3 / 1 / 4/ 9" , margin: "20px 0" }}>
            {props.Loader}
          </div>
          <div
            style={{ gridRow: "14/15", gridColumn: "4/6",  marginRight: "1rem"}}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              ชื่อหน่วยงานบนใบเสร็จ
            </div>
          </div>
          <div
            style={{ gridRow: "14/15", gridColumn: "6/8",marginRight: "1rem", marginLeft: "-1rem"  }}>
            <div>
              {props.searchBoxPayerReceipt}
            </div>
          </div>
          <div
            style={{ gridRow: "14/15", gridColumn: "8/9" }}>
            <div>
              {props.buttonPay}
            </div>
          </div>
          <div
            style={{ gridRow: "13/16", gridColumn: "8/9" , pointerEvents: "none"}}>
            
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "4/5",  }}>
            
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", ...(props.isDeposit && {display: "none"}) }}>
              ผ่อนชำระ
            </div>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "5/7", margin: "2.5px 0", ...(props.isDeposit && {display: "none"})  }}>
            
            <div
              className="ui label large fluid"
              id="txt-cashInstallment"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.installmentText || "0.00"}
            </div>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "7/8", ...(props.isDeposit && {display: "none"}) }}>
            
            <Button
              className="circular"
              color="green"
              icon="plus"
              onClick={()=> props.onAddPaymentMethod(7)}
              size="mini"
              style={{margin: "3px 10px"}}>
            </Button>
          </div>
          <div
            style={{ gridRow: "8/9", gridColumn: "1/2",  }}>
            
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold"}}>
              
              <div>
                หน่วยงานต้นสังกัดผู้โอน
              </div>
              <div
                style={{ color: "red", paddingLeft: "5px" }}>
                *
              </div>
            </div>
          </div>
          <div
            style={{ gridRow: "8/9", gridColumn: "2/4", margin: "2.5px 0" }}>
            
            <div>
              {props.searchBoxPayer}
            </div>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "1/2",  }}>
            
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold"}}>
              
              <div>
                วันที่รับเงิน
              </div>
              <div
                style={{ color: "red", paddingLeft: "5px" }}>
                *
              </div>
            </div>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "2/4", margin: "2.5px 0" }}>
            
            <DateTextBox
              onChange={props.onChangePaidDate}
              style={{ width: "100%" }}
              value={props.payment?.paid_date}>
            </DateTextBox>
          </div>
        </div>
      </div>
    )
}


export default CardPayingArUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Fri May 17 2024 01:06:35 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\", padding: \"10px\", marginTop:\"-1rem\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"rowGap\":\"5px\",\"gridTemplateColumns\":\"repeat(8, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", gridRow: \"1/2\", gridColumn: \"1/3\",display: \"none\"  }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", gridRow: \"1/2\", gridColumn: \"3/6\",display: \"none\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/9\",display: \"none\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/2\",  }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/4\", margin: \"2.5px 0\"  }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/2\", }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"2/4\", margin: \"2.5px 0\"  }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/2\",  }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"2/4\", margin: \"2.5px 0\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"4/5\", }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"5/7\",  }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"4/5\", }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"5/7\", margin: \"2.5px 0\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"4/5\", ...(props.isDeposit && {gridRow: \"7/8\"})}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"5/7\", margin: \"2.5px 0\", ...(props.isDeposit && {gridRow: \"7/8\"})  }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"4/5\", ...(props.isDeposit && {gridRow: \"10/11\"})  }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.totalText || \"0.00\""
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "id": {
          "type": "value",
          "value": "(props.priceText?.totalText || 0).toFixed(2)"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"5/7\", ...(props.isDeposit && {gridRow: \"10/11\"})}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"7/9\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridArea: \"5 / 7 / 6 / 9\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"7/8\", ...(props.isDeposit && {gridRow: \"7/8\"}) }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"4/6\",  marginRight: \"1rem\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"6/8\",marginRight: \"1rem\", marginLeft: \"-1rem\"  }"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"8/9\" }"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดเบิกทั้งหมด"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ต้องชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้างชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "เงินสด"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "เงินโอน"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "แคชเชียร์เช็ค"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.sendClaim || \"0.00\""
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.payable || \"0.00\""
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.oweText || \"0.00\""
        },
        "className": {
          "type": "code",
          "value": "`ui label large fluid ${Number(props.totalPrice?.payable) > Number(props.priceText?.totalText) ? \"red\" : \"green\"}`"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui input"
        },
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.transferText || \"0.00\""
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "id": {
          "type": "value",
          "value": "txt-cashTransfer"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\",}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.chequeText || \"0.00\""
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อหน่วยงานบนใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน (Paying)"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "รวม"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "input",
      "parent": 54,
      "props": {
        "onBlur": {
          "type": "code",
          "value": "props.onBlurCashAmountText"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCashAmountText",
          "valueEN": ""
        },
        "onKeyUp": {
          "type": "code",
          "value": "props.onEnterCashAmountText"
        },
        "style": {
          "type": "code",
          "value": "{  textAlign: \"right\" }"
        },
        "value": {
          "type": "code",
          "value": "props.priceText?.cashAmountText"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Label",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.citizenID || \"-\"}`"
        },
        "className": {
          "type": "value",
          "value": "large tag blue"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Label",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientNameAndHN"
        },
        "className": {
          "type": "value",
          "value": "ribbon large title blue"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 30,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onAddPaymentMethod(2)"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"3px 10px\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Button",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onAddPaymentMethod(4)"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"3px 10px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubRepeaterPayMethod"
        },
        "style": {
          "type": "code",
          "value": "{ gridArea: \"12 / 1 / 12/ 9\" , margin: \"10px 0\" , padding: \"0 2rem\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "ทอน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Button",
      "parent": 29,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 0, margin: \"3px 10px\", pointerEvents: \"none\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.invoiceChange || \"0.00\""
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", textAlign: \"right\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{ gridArea: \"3 / 1 / 4/ 9\" , margin: \"20px 0\" }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Loader"
        },
        "style": {
          "type": "code",
          "value": "{ gridArea: \"3 / 1 / 4/ 9\" , margin: \"20px 0\" }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"4/5\",  }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"5/7\", margin: \"2.5px 0\", ...(props.isDeposit && {display: \"none\"})  }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"7/8\", ...(props.isDeposit && {display: \"none\"}) }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.installmentText || \"0.00\""
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "id": {
          "type": "value",
          "value": "txt-cashInstallment"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "ผ่อนชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", ...(props.isDeposit && {display: \"none\"}) }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Button",
      "parent": 84,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onAddPaymentMethod(7)"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"3px 10px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/16\", gridColumn: \"8/9\" , pointerEvents: \"none\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/9\", padding: \"10px\", display: \"flex\", margin: \"0.5rem 0\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Label",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "`เลขที่ใบแจ้งหนี้ : ${props.bilReceiptCode || \"-\"}`"
        },
        "className": {
          "type": "value",
          "value": "ribbon large title blue"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Label",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "`หน่วยงานต้นสังกัดที่เรียกเก็บ : ${props.selectPaymentAr?.payer_code_name  || \"-\"}`"
        },
        "className": {
          "type": "value",
          "value": "large tag blue"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5rem\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน (Paying)"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", paddingRight: \"10px\",  height: \"100%\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/2\",  }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"2/4\", margin: \"2.5px 0\" }"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/2\",  }"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"2/4\", margin: \"2.5px 0\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 123,
      "name": "DateTextBox",
      "parent": 118,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangePaidDate"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.payment?.paid_date"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานต้นสังกัดผู้โอน"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", paddingLeft: \"5px\" }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่รับเงิน"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", paddingLeft: \"5px\" }"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 116,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBoxPayer"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBoxPayerReceipt"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPay"
        }
      },
      "seq": 132,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 45,
  "isMounted": false,
  "memo": false,
  "name": "CardPayingArUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 60
}

*********************************************************************************** */
