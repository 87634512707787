import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

// PDF
const PDF = "/static/pdf/taxDoctor/270360_WHT3.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf";

const FormPND3Report = async (data: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Load your custom font
    const fontUrl = Font; // Replace with your font URL
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const getDecimal = (value: number) => {
      let rounded = Math.floor(value);

      return (value - rounded || 0).toFixed(2)?.toLocaleString().slice(2, 4);
    };

    const fontSize = 12;
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const textCoordinates = [
      { text: data?.officerId, x: 165, y: 735 },

      { text: data?.branch, x: 302, y: 703 },

      { text: data?.payerName, x: 50, y: 687 },
      { text: data?.buildingName, x: 90, y: 670 },
      { text: data?.roomNo, x: 187, y: 671 },
      { text: data?.floor, x: 227, y: 671 },
      { text: data?.village, x: 265, y: 671 },
      { text: data?.No, x: 60, y: 654 },
      { text: data?.villageNo, x: 158, y: 654 },
      { text: data?.alley, x: 212, y: 654 },
      { text: data?.Junction, x: 300, y: 654 },
      { text: data?.street, x: 60, y: 637 },
      { text: data?.subDistrict, x: 216, y: 637 },
      { text: data?.district, x: 82, y: 621 },
      { text: data?.province, x: 216, y: 621 },
      { text: data?.zipCode, x: 95, y: 607 },
      { text: data?.hospitalPhone, x: 170, y: 607 },
      { text: data?.year, x: 525, y: 717 },

      { text: data?.specialSubmitNo?.toString(), x: 287, y: 565 },

      { text: data?.doctor_count?.toString(), x: 530, y: 490 },
      { text: data?.total_page?.toString(), x: 530, y: 476 },

      {
        text: data?.recordingInComputerQuantity?.toString(),
        x: 530,
        y: 440,
      },
      { text: data?.recordingInComputerList?.toString(), x: 530, y: 418 },
      { text: data?.registerNo?.toString(), x: 530, y: 404 },
      { text: data?.registerRefNo?.toString(), x: 530, y: 392 },
      {
        text: Number(data?.total_net_income_price?.toFixed(0))?.toLocaleString(),
        x:
          504 -
          customFont?.widthOfTextAtSize(
            (data?.total_net_income_price || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 335,
      },
      {
        text:
          data?.total_net_income_price === 0
            ? "00"
            : getDecimal(Number(data?.total_net_income_price)),
        x: 511,
        y: 335,
      },
      {
        text: Number(data?.total_tax?.toFixed(0))?.toLocaleString(),
        x:
          504 -
          customFont?.widthOfTextAtSize(
            (data?.total_tax || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 315,
      },
      {
        text: data?.total_tax === 0 ? "00" : getDecimal(Number(data?.total_tax)),
        x: 511,
        y: 315,
      },
      {
        text: data?.summaryNo3CaseMoney?.toFixed(0)?.toLocaleString(),
        x:
          507 -
          customFont?.widthOfTextAtSize(
            (data?.summaryNo3CaseMoney || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 297,
      },
      {
        text:
          data?.summaryNo3CaseMoneyDecimal === 0
            ? "00"
            : (data?.summaryNo3CaseMoneyDecimal || "").toString().slice(0, 2),
        x: 511,
        y: 297,
      },
      {
        text: Number(data?.total_tax?.toFixed(0))?.toLocaleString(),
        x:
          504 -
          customFont?.widthOfTextAtSize(
            (data?.total_tax || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 279,
      },
      {
        text: data?.total_tax === 0 ? "00" : getDecimal(Number(data?.total_tax)),
        x: 511,
        y: 279,
      },
      { text: data?.payerFullName, x: 280, y: 185 },
      { text: data?.position, x: 280, y: 165 },
      { text: data?.submitDate, x: 240, y: 150 },
      { text: data?.submitMonth, x: 287, y: 150 },
      { text: data?.submitYear, x: 360, y: 150 },
      // Add other text fields using data? here...
    ];

    for (const { text, x, y } of textCoordinates) {
      let adjustedX = x; // Initialize adjustedX to the original x coordinate

      if (text === data?.officerId) {
        // Loop through each character in data?.taxId
        for (let j = 0; j < data?.officerId?.length; j++) {
          const charSpacing = 6.5;
          if (j === 1 || j === 5 || j === 10 || j === 12) {
            adjustedX += charSpacing;
          } // Apply spacing adjustment if necessary

          // Get the current character from taxId
          const char = data?.officerId[j];

          // Draw the character at the adjustedX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Increment adjustedX by the width of the character plus the spacing adjustment
          adjustedX += customFont?.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else if (text === data?.branch) {
        const charSpacing = 3.3;

        for (let j = 0; j < data?.branch?.length; j++) {
          const char = data?.branch[j];

          // Adjust spacing for specific letters
          if (j === 1 || j === 2 || j === 3) {
            adjustedX += charSpacing; // Increase the X coordinate by charSpacing
          }

          // Draw the character at the currentX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Update currentX with character width and spacing
          adjustedX += customFont?.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else if (text === data?.zipCode) {
        const charSpacing = 3;

        for (let j = 0; j < data?.zipCode?.length; j++) {
          const char = data?.zipCode[j];

          // Adjust spacing for specific letters
          if (j === 1 || j === 2 || j === 3 || j === 4 || j === 5) {
            adjustedX += charSpacing; // Increase the X coordinate by charSpacing
          }

          // Draw the character at the currentX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Update currentX with character width and spacing
          adjustedX += customFont?.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else {
        // Draw the text as it is without any adjustments
        firstPage.drawText(text, {
          x,
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      }
    }
    const checkSymbol = "\u2713"; // ✓ Check symbol
    const falseText = ""; // Empty text when isMoneyType is false

    // Adjust coordinates for each check symbol
    const isMonthTextCoordinates = [
      {
        text: checkSymbol,
        x: 85,
        y: 565,
      },
      {
        text: data?.isSpecialSubmit ? checkSymbol : falseText,
        x: 180,
        y: 565,
      },
      {
        text: data?.month === "1" ? checkSymbol : falseText,
        x: 340,
        y: 689,
      },
      {
        text: data?.month === "4" ? checkSymbol : falseText,
        x: 396,
        y: 689,
      },
      { text: data?.month === "7" ? checkSymbol : falseText, x: 454, y: 689 },
      { text: data?.month === "10" ? checkSymbol : falseText, x: 510, y: 689 },
      { text: data?.month === "2" ? checkSymbol : falseText, x: 340, y: 665 },
      { text: data?.month === "5" ? checkSymbol : falseText, x: 396, y: 665 },
      { text: data?.month === "8" ? checkSymbol : falseText, x: 454, y: 665 },
      { text: data?.month === "11" ? checkSymbol : falseText, x: 510, y: 665 },
      {
        text: data?.month === "3" ? checkSymbol : falseText,
        x: 340,
        y: 641,
      },
      {
        text: data?.month === "6" ? checkSymbol : falseText,
        x: 396,
        y: 641,
      },
      {
        text: data?.month === "9" ? checkSymbol : falseText,
        x: 454,
        y: 641,
      },
      {
        text: data?.month === "12" ? checkSymbol : falseText,
        x: 510,
        y: 641,
      },
      {
        text: data?.isStatute3 ? checkSymbol : falseText,
        x: 128,
        y: 522,
      },
      {
        text: data?.isStatute48 ? checkSymbol : falseText,
        x: 245,
        y: 522,
      },
      {
        text: data?.isStatute50 ? checkSymbol : falseText,
        x: 362,
        y: 522,
      },
      {
        text: data?.isAttachment ? checkSymbol : falseText,
        x: 277,
        y: 490,
      },
      {
        text: data?.isRecordingInComputer ? checkSymbol : falseText,
        x: 277,
        y: 436,
      },
    ];

    for (const { text, x, y } of isMonthTextCoordinates) {
      firstPage.drawText(text, {
        x,
        y,
        size: 12,
        font: zapFont,
        color: rgb(0, 0, 0),
      });
    }
    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfUrl = URL.createObjectURL(
      new Blob([modifiedPdfBytes], { type: "application/pdf" })
    );

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl };
  } catch (error) {
    console.error("Error modifying PDF:", error);

    return {};
  }
};

export default FormPND3Report;
