import WasmController from "react-lib/frameworks/WasmController";

import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import StartWardedView from "issara-sdk/apis/StartWardedView_apps_ADM";
import SyncMedReconcileAndDrugContinueView from "issara-sdk/apis/SyncMedReconcileAndDrugContinueView_apps_TPD";
import SyncMedReconcileAndDrugOneDayView from "issara-sdk/apis/SyncMedReconcileAndDrugOneDayView_apps_TPD";
import MedicationReconciliationLogList from "issara-sdk/apis/MedicationReconciliationLogList_apps_TPD";

import getPdfMake from "react-lib/appcon/common/pdfMake";

import FormMedReconcile from "../FormMedReconcile";

import { SORT_ADR_ORDER } from "./Allergy";

// DrugOrderQueueList_apps_TPD
export type State = {
  checkIPDHome?: boolean;
  checkMRAdmit?: boolean;
  checkSuggestion?: boolean;
  selectedPatient?: any;
  AllergySequence?: any;
  selectedEncounter?: any;
  medReconcileIPDAdmitItem?: any[];
  medReconcileIPDAdmitSelectOrder?: any[];
  medReconcileIPDAdmitSelectItem?: any[];
  medReconcile?: any;
  MedReconcileSequence?: {
    // Medication Error List
    sequenceIndex?: string | null;
    permission?: any;
    wardAt?: any;
    transferDrugContinue: any[];
    transferDrugOneDay: any[];
  } | null;
};

export const StateInitial: State = {
  checkIPDHome: true,
  checkMRAdmit: true,
  checkSuggestion: true,
  medReconcileIPDAdmitSelectItem: [],
  medReconcileIPDAdmitSelectOrder: [],
  MedReconcileSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetPermission"; params: {} };

export type Data = {
  user?: number;
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.MedReconcileSequence) return;
  console.log("MedReconcile Sequence START !!! ", params);

  const getPermission = await UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_TPD_MED_RECONCILIATION_AVAILABLE_TIME: 24,
      config_TPD_ENABLE_MED_RECONCILIATION_NOT_AVAILABLE_TIME_AFTER_CHECKED_EDIT:
        false,
      config_TPD_ENABLE_MED_RECONCILIATION_DRAFT_BUTTON_AFTER_AVAILABLE_TIME:
        false,
      config_TPD_ENABLE_MED_RECONCILIATION_NEW_BUTTON: false,
    },
  });

  console.log("getPermission: ", getPermission);
  console.log("params.selectedEncounterId: ", params.selectedEncounterId);

  const startWard = await StartWardedView.get({
    apiToken: controller.apiToken,
    params: { encounter: params.selectedEncounterId },
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  console.log("startWard: ", startWard);

  controller.setState({
    checkIPDHome: true,
    checkMRAdmit: true,
    checkSuggestion: true,
    MedReconcileSequence: {
      ...state.MedReconcileSequence,
      sequenceIndex: "Edit",
      permission: getPermission?.[0],
      wardAt: startWard?.[0]?.warded_at,
    },
  });
};

export const Edit: Handler = async (controller, params) => {
  console.log(" Edit params: ", params);
  const state = controller.getState();
  if (params?.action === "selected_drug_in_ipd_admit" && params.orderId) {
    let selectedOrder = [...(state.medReconcileIPDAdmitSelectOrder || [])];
    let selectedItem = [...(state.medReconcileIPDAdmitSelectItem || [])];
    let chooseOrderId = state.medReconcileIPDAdmitItem?.find(
      (order: any) => order.id === params?.orderId
    );
    let childItemList = chooseOrderId?.children?.map((item: any) => item.id);
    console.log("childItemList: ", childItemList);
    if (params?.orderId && !params.itemId) {
      if (selectedOrder.includes(params?.orderId)) {
        selectedOrder = selectedOrder.filter(
          (order: any) => order !== params.orderId
        );
        selectedItem = selectedItem.filter(
          (item: any) => !childItemList.includes(item)
        );
      } else {
        selectedOrder.push(params?.orderId);
        childItemList.forEach((id: number) => {
          if (!selectedItem.includes(id)) {
            selectedItem.push(id);
          }
        });
      }
    } else if (params?.itemId && params?.orderId) {
      if (selectedItem.includes(params?.itemId)) {
        selectedOrder = selectedOrder.filter(
          (order: any) => order !== params.orderId
        );
        selectedItem = selectedItem.filter(
          (item: any) => item !== params.itemId
          );
      } else {
        selectedItem.push(params?.itemId);
        let allItemSelected = true;
        childItemList.forEach((id: number) => {
          if (!selectedItem.includes(id)) {
            allItemSelected = false;
          }
        });
        if (allItemSelected) {
          selectedOrder.push(params?.orderId);
        }
      }
    }
    controller.setState({
      medReconcileIPDAdmitSelectOrder: selectedOrder,
      medReconcileIPDAdmitSelectItem: selectedItem,
    });
  } else if (params?.action === "add_to_mr_discharge_list") {
    let selectedItems: any = [];
    // Check duplicate
    let currentMedReconcileItems = [...(state.medReconcile?.items || [])];
    console.log('add_to_mr_discharge_list currentMedReconcileItems: ', currentMedReconcileItems);
    (state.medReconcileIPDAdmitItem || []).forEach((item: any) => {
      console.log('add_to_mr_discharge_list medReconcileIPDAdmitItem: ', state.medReconcileIPDAdmitItem);
      let newItems = item?.children || []
      // Issue 64678 ไม่ต้องเช็คยาซ้ำ
      // let newItems: any[] = item?.children?.filter((drug: any) => {
      //   let duplicate = currentMedReconcileItems.find(
      //     (cmr: any) => {
      //       // console.log("add_to_mr_discharge_list cmr", cmr)
      //       // console.log("add_to_mr_discharge_list drug", drug)
      //       return cmr.code === drug.code
      //     }
      //   );
      //   // console.log('add_to_mr_discharge_list duplicate: ', duplicate);
      //   return !duplicate;
      // });

      console.log('add_to_mr_discharge_list newItems: ', newItems);
      console.log('add_to_mr_discharge_list selectedItems: ', selectedItems);
      (newItems || []).forEach((ni: any) => {
        if (state.medReconcileIPDAdmitSelectItem?.includes(ni.id)) {
          selectedItems.push({
            ...ni,
            id: null,
            is_from_mr_ipd: true,
            acknowledge_by_users: [],
            has_changed: true,
            has_edited: true,
          });
        }
      });
    });

    selectedItems.forEach((item: any) => {
      currentMedReconcileItems.push({
        ...item,
        has_changed: true,
        is_suggestion: false,
      });
    });
    console.log('add_to_mr_discharge_list selectedItems: ', selectedItems);

    return controller.setState({
      medReconcileIPDAdmitSelectItem: [],
      medReconcileIPDAdmitSelectOrder: [],
      medReconcile: {
        ...state.medReconcile,
        items: [...(currentMedReconcileItems || [])],
      },
    });
  } else if (params?.action === "add_other_item") {
    console.log("add_other_item params: ", params);
    // target_type = "custom_drug" (Custom) : have params.item.name = "XX"
    // target_type = "tmt_tp" (Generic/Trade Product) : have params.item.product = "XX"
    // target_type = "tmt_tp" (Generic/Trade Product) : have params.item.product_name = "XX"
    if (
      !(
        (params?.item?.target_type === "custom_drug" && params?.item?.name) ||
        (params?.item?.target_type === "tmt_tp" &&
          params?.item?.product &&
          params?.item?.product_name)
      )
    ) {
      console.log("no new Item");
      return;
    }

    let itemsCheck = (state.medReconcile?.items || []).filter(
      (item: any, index: number) =>
        (params.item?.id === null ||
          typeof item?.id === "undefined" ||
          item.id !== params.item?.id) &&
        params?.index !== index
    );

    let isEqualList =
      itemsCheck.length === (state.medReconcile?.items || []).length;
    let editIndex = -1;
    let newItem = params.item;
    newItem.has_changed = true;
    newItem.has_edited = true;
    let editItems = [...state.medReconcile?.items];

    if (isEqualList) {
      // Add Item
      delete newItem.id;
      newItem.status = "OFF";
    } else {
      // Edit existing item - combine
      if (Number.isInteger(params?.index)) {
        editIndex = state.medReconcile?.items?.findIndex(
          (item: any, index: number) => index === params.index
        );
        newItem = {
          ...(state.medReconcile?.items || []).find(
            (item: any, index: number) => index === params.index
          ),
          ...newItem,
        };
      } else {
        editIndex = state.medReconcile?.items?.findIndex(
          (item: any) => item.id === params.item.id
        );
        newItem = {
          ...(state.medReconcile?.items || []).find(
            (item: any) => item.id === params.item.id
          ),
          ...newItem,
        };
      }
    }

    if (newItem.target_type === "custom_drug") {
      newItem[newItem.target_type] = newItem.name;
    } else {
      newItem[newItem.target_type] = newItem.pk;
    }

    if (isEqualList) {
      itemsCheck.push(newItem);
      console.log("setExternalItem Check Add New itemsCheck: ", itemsCheck);
    } else {
      if (editIndex !== -1) {
        editItems[editIndex] = newItem;
      }
      console.log("setExternalItem Check Edit editItems: ", editItems);
    }

    controller.setState({
      medReconcile: {
        ...state.medReconcile,
        items: isEqualList ? itemsCheck : editItems,
      },
    });
  } else if (
    params?.action === "change_status" &&
    params?.index !== undefined
  ) {
    let selectedItems: any = [...(state.medReconcile.items || [])];
    selectedItems = selectedItems.map((item: any, index: number) =>
      params?.index === index
        ? {
            ...item,
            take_medicine_status:
              item.take_medicine_status !== params?.status
                ? params?.status
                : "NONE",
            has_changed: true,
            has_edited: true
          }
        : { ...item }
    );

    controller.setState({
      medReconcile: {
        ...state.medReconcile,
        items: selectedItems,
      },
    });
  } else if (params?.action === "delete_item" && params?.index !== undefined) {
    let selectedItems: any = [...(state.medReconcile.items || [])];
    let item = selectedItems.at(params?.index);
    if (item && item.acknowledge_by_users?.length > 0) {
      selectedItems = selectedItems.map((item: any, index: number) =>
        params?.index === index
          ? { ...item, active: item.active ? false : true , has_edited: true}
          : { ...item }
      );
    } else {
      selectedItems = selectedItems.filter(
        (item: any, index: number) => params?.index !== index
      );
    }

    controller.setState({
      medReconcile: {
        ...state.medReconcile,
        items: selectedItems,
      },
    });
  } else if (params?.action === "syncDrugTransfer") {
    console.log("syncDrugTransfer: ");
    let state = controller.getState();

    let pendingContinue = SyncMedReconcileAndDrugContinueView.post({
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
      data: {
        items: state.medReconcile?.items_current_continue
          ? state.medReconcile?.items_current_continue
          : [],
        encounter: state.selectedEncounter.id,
      },
    });

    let pendingOneDay = SyncMedReconcileAndDrugOneDayView.post({
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
      data: {
        items: state.medReconcile?.items_current_one_day
          ? state.medReconcile?.items_current_one_day
          : [],
        encounter: state.selectedEncounter.id,
      },
    });

    let [[drugContinue, errorDrugContinue], [drugOneDay, errorDrugOneDay]] =
      await Promise.all([pendingContinue, pendingOneDay]);
    console.log("drugOneDay: ", drugOneDay);
    console.log("drugContinue: ", drugContinue);

    if (drugContinue || drugOneDay) {
      controller.setState({
        medReconcile: {
          ...state.medReconcile,
          items_current_continue: (drugContinue?.items?.map( (i:any) => ({...i, has_edited: true}))) || [],
          items_current_one_day: (drugOneDay?.items?.map( (i:any) => ({...i, has_edited: true}))) || [],

        },
      });
    }
  } else if (params?.action === "changeActionTransfer") {
    console.log("changeActionTransfer params: ", params);
    console.log("changeActionTransfer params.type: ", params.type);

    let state = controller.getState();
    if (params.type) {
      let lists = state.medReconcile?.[params.type] || [];
      console.log('changeActionTransfer lists: ', lists);

      // let index = lists?.findIndex(
      //   (item: any) => item.id === params.item.id
      // );
      let index = params.index
      console.log("changeActionTransfer index: ", index);
      if (index !== -1) {

        lists[index].status = params.status;
        lists[index].has_changed = true;
        lists[index].has_edited = true;
        controller.setState({
          medReconcile: {
            ...state.medReconcile,
            [params.type]: [...lists],
          },
        });
      }
    }
  } else if (params?.action === "editNote") {
    console.log("editNote: ");

    let state = controller.getState();
    if (params.type) {
      let lists = state.medReconcile?.[params.type] || [];

      // let index = lists?.findIndex(
      //   (item: any) => item.id === params.item.id
      // );
      let index = params.index
      console.log("index: ", index);
      if (index !== -1) {
        console.log("lists: ", lists);
        lists[index].note = params.note;
        lists[index].has_changed = true;
        lists[index].has_edited = true;
        controller.setState({
          medReconcile: {
            ...state.medReconcile,
            [params.type]: [...lists],
          },
        });
      }
    }
  } else if (params?.action === "changeDateTime") {
    // console.log("changeDateTime params: ", params);
    // Edit Table 63017
    if (!params.humanAction) {
      return;
    }

    let state = controller.getState();
    if (params.type) {
      let lists = state.medReconcile?.[params.type] || [];

      // let index = lists?.findIndex(
      //   (item: any) => item.id === params.item.id
      // );
      let index = params.index
      console.log("index: ", index);
      if (index !== -1) {
        console.log("lists: ", lists);
        lists[index][params.name] = params.value;
        lists[index].has_changed = true;
        lists[index].has_edited = true;
        controller.setState({
          medReconcile: {
            ...state.medReconcile,
            [params.type]: [...lists],
          },
        });
      }
    }
  } else if (params?.action === "print_med_reconcile") {
    const medReconcile = state.medReconcile || {};
    const {
      id,
      doctor,
      items_current_continue,
      med_reconciliation_type,
      nurse,
      nurse_ipd,
      pharmacist,
    } = medReconcile;

    const logItems: any[] = await GetMedicationReconciliationLog(controller, { id });

    logItems.reverse();

    const findUser = (user: number) => logItems.find((item) => item.user === user);

    const foundDoctor = findUser(doctor);
    const foundNurse = findUser(nurse);
    const foundNurseIpd = findUser(nurse_ipd);
    const foundPharmacist = findUser(pharmacist);

    const items: any[] = ["TRANSFER"].includes(med_reconciliation_type)
      ? items_current_continue || []
      : medReconcile.items || [];

    const patientImage = state.selectedPatient?.profile_image?.image || "";
    const adrList: any[] = state.AllergySequence.adrList || [];

    adrList.sort(
      (a: any, b: any) => SORT_ADR_ORDER[a.type_name_name] - SORT_ADR_ORDER[b.type_name_name]
    );

    const adrText = adrList
      .map((acc: any) => acc.adr_short_info)
      .filter(Boolean)
      .join(", ");

    const docDef = await FormMedReconcile({
      adrText,
      doctor: foundDoctor,
      encounter: state.selectedEncounter,
      items,
      nurse: foundNurse,
      nurseIpd: foundNurseIpd,
      patientImage,
      pharmacist: foundPharmacist,
      type: med_reconciliation_type,
    });

    const pdfMake = await getPdfMake(true);
    const pdf = pdfMake.createPdf(docDef);

    pdf.open();
  }
};

const GetMedicationReconciliationLog: Handler = async (controller, params) => {
  const medReconcile = await MedicationReconciliationLogList.list({
    apiToken: controller.apiToken,
    params: {
      med_reconcile: params.id,
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  return medReconcile?.[0]?.items || [];
};