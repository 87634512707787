import React, { useEffect } from "react";
import { Icon, Tab, TabProps } from "semantic-ui-react";

// UI
import { MenuItem } from "./CardSurgeryTeam";
import CardAnesthesiaTeamUX from "./CardAnesthesiaTeamUX";
import SubAssistantsInput from "./SubAssistantsInput";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";

import { AnesthesiaTeamInitial } from "./sequence/PerioperativeNursing";
import { useIntl } from "react-intl";

const CardBasicInfoAnesthesiaTeam = (props: any) => {
  const intl = useIntl();
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
    });
  }, []);
  const handleChange =
    (key: string, index: number) => async (e: any, v: any) => {
      props.onChangeValue(e, { ...v, name: `${key}.${index}.${v.name}` });
    };

  const handleAddTeam = (e: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "add_anesthesia_team",
    });
  };

  const handleSelectTeam = (e: any, data: TabProps) => {
    if (props.anesthesia_teams?.length === Number(data.activeIndex)) {
      handleAddTeam(e);
      return;
    }

    props.setProp(
      `PerioperativeNursingSequence.basicInfoData.activeAnesthesiaTeam`,
      Number(data.activeIndex)
    );
  };

  const handleDeleteTeam = async () => {
    const teams = props.anesthesia_teams || [];

    if (teams.length === 1) {
      teams[props.activeIndex] = AnesthesiaTeamInitial;
    } else {
      teams.splice(props.activeIndex, 1);

      const index = props.activeIndex - 2;

      await props.setProp(
        `PerioperativeNursingSequence.basicInfoData.activeAnesthesiaTeam`,
        index < 0 ? 0 : index
      );
    }

    props.setProp(
      `PerioperativeNursingSequence.basicInfoData.anesthesia_teams`,
      teams
    );
  };

  const handleUpdateAnesthetistSurgeon = (index: number) => (items: any[]) => {
    props.setProp(
      `PerioperativeNursingSequence.basicInfoData.anesthesia_teams.${index}.anesthetist_items`,
      items
    );
  };

  const handleAnesthesiologist = (index: number) => async (item: any) => {
    await props.setProp(
      `PerioperativeNursingSequence.basicInfoData.anesthesia_teams.${index}.anesthesiologist`,
      item?.id || null
    );
    props.setProp(
      `PerioperativeNursingSequence.basicInfoData.anesthesia_teams.${index}.anesthesiologist_item`,
      item
    );
  };

  return (
    <div style={{ padding: "1rem", marginTop: "-1rem" }}>
      <Tab
        activeIndex={props.activeIndex}
        menu={{ secondary: true, pointing: true, color: "purple" }}
        onTabChange={handleSelectTeam}
        panes={[
          ...(props.anesthesia_teams || []).map((item: any, index: number) => ({
            menuItem: {
              key: `MenuItem-team${index}`,
              content: (
                <MenuItem
                  index={index}
                  activeIndex={props.activeIndex}
                  title="Anesthesia Team"
                  isStar={item.is_main}
                  color="#a333c8"
                />
              ),
            },
            render: () => (
              <CardAnesthesiaTeamUX
                // callback
                onChangeValue={handleChange("anesthesia_teams", index)}
                onDeleteTeam={handleDeleteTeam}
                isDisabled={item.id && index === 0}
                card={"CardBasicInfo"}
                // Element
                AnesthetistElement={
                  <SubAssistantsInput
                    // callback
                    onEvent={props.onEvent}
                    // data
                    index={index}
                    items={item.anesthetist_items || []}
                    type="User"
                    field="Anesthetist"
                    hideDateTime={true}
                    role="REGISTERED_NURSE"
                    isDetail={true}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    // setSelected={handleActionAnesthetistSurgeon(index)}
                    // onClick={handleActionAnesthetistSurgeon(index)}
                    onUpdated={handleUpdateAnesthetistSurgeon(index)}
                    languageUX={props.languageUX}
                  />
                }
                AnesthesiologistElement={
                  <SearchBoxWithKey
                    // callback
                    onEvent={props.onEvent}
                    type="Doctor"
                    id={`Anesthesiologist_${index + 1}`}
                    isDetail={true}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    selectedItem={item.anesthesiologist_item?.id || null}
                    setSelected={handleAnesthesiologist(index)}
                  />
                }
                languageUX={props.languageUX}
              />
            ),
          })),
          {
            menuItem: {
              key: `MenuItem-teams`,
              content: (
                <div>
                  <Icon name="add circle" color="purple" style={{ cursor: "pointer" }} />
                </div>
              ),
            },
          },
        ]}
      />
    </div>
  );
};

export default React.memo(CardBasicInfoAnesthesiaTeam);
