/* global safari */
import React from "react";
import LineLoginManager from "./LineLoginManager";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import Fingerprint2 from "fingerprintjs2";
import {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription
} from "./push-notifications";
import * as serviceWorker from "../../../../serviceWorker";
import { useFilledButton } from "../../../utils/hooksUtils";
import settings from "../../../../config/config";
import { useIntl } from "react-intl";

export default class LineCallBack extends React.Component {
  constructor(props) {
    super(props);

    console.log("LineCallBack props", props);

    this.lineLoginManager = new LineLoginManager();

    const params = new URLSearchParams(window.location.search);

    console.log("LineCallBack URLSearchParams params", params)
    this.stateMatched = this.lineLoginManager.verifyCSRFState(
      params.get("state")
      );
      this.code = params.get("code");
      
    console.log('LineCallBack stateMatched: ', this.stateMatched);
    
    this.state = {
      success: false,
      message: this.stateMatched ? "Loading ..." : "CSRF's mismatch",
      auth: {},
      userId: "",
      name: "",
      picture: "",
      email: "",
      apiToken: ""
    };
  }

  async componentDidMount() {
    let isPrivate = false;
    try {
      isPrivate = safari.self.browserWindow.activeTab.private;
    } catch (_) {
      isPrivate = true;
    }
    console.log("CDM this.stateMatched", this.stateMatched)
    if (this.stateMatched || isPrivate) {
      try {
        let _device_id = null;
        let _subscription = null;
        let result = await this.getFingerPrint();
        if (result.device_id && result.subscription) {
          _device_id = result.device_id;
          _subscription = result.subscription;
        }
        await this.lineLoginManager.getToken(this.code);
        // Use this to get a PentaCenter API Token
        const pentaToken = await this.lineLoginManager.getTokenFromAccount(
          settings.APP_NAME,
          null,
          _subscription,
          _device_id
        );
        console.log("pentaToken", pentaToken);
        console.log("_subscription", _subscription);
        console.log("_device_id", _device_id);

        // Optional get profile from LINE API
        const profile = await this.lineLoginManager.getProfile();
        const verify = await this.lineLoginManager.getVerifyIDToken(
          profile.userId
        );
        console.log("CDM LineCallback");
        console.log(profile);
        console.log(verify);
        console.log(this.props);

        this.props.onLoginSuccess({
          profile: profile,
          verify: verify,
          pentaToken: pentaToken
        });

        // this.setState((state, props) => {
        //   return {
        //     userId: profile.userId,
        //     name: verify.name,
        //     picture: verify.picture + '/large',
        //     email: verify.email,
        //     success: true,
        //     apiToken: pentaToken.token,
        //   }
        // });
      } catch (err) {
        console.log(err, "Line Login Error");
        this.props.onLoginFailed({
          error: err
        });

        // this.props.history.push("/");
        // this.setState((state, props) => {
        //   return {
        //     message: err.message,
        //   }
        // });
      }
    }
    // this.props.onLoginFailed({
    //   error: "เกิดข้อผิดพลาด กรุณาปิดโหมด Private"
    // });
  }

  getFingerPrint = async () => {
    let promise = new Promise(function(resolve, reject) {
      navigator.serviceWorker
        .getRegistrations()
        .then(registrations => {
          console.log("check service worker", registrations);
          if (registrations.length <= 0) {
            console.log("Force regist service worker again !!!");
            serviceWorker.register();
            registerServiceWorker();
          }
        })
        .then(() => {
          var options = {};
          Fingerprint2.getPromise(options).then(components => {
            var values = components.map(function(component) {
              return component.value;
            });
            var device_id = Fingerprint2.x64hash128(values.join(""), 31);
            console.log("LCB Fingerprint id:", device_id);
            // Get subscription endpoint object
            createNotificationSubscription()
              .then(subscription => {
                console.log("LCB Success get notification subscription..");
                console.log(subscription);
                resolve({ device_id, subscription });
              })
              .catch(err => {
                console.log("LCB error getSubScriptionObejct");
                console.error(
                  "Couldn't create the notification subscription",
                  err,
                  "name:",
                  err.name,
                  "message:",
                  err.message,
                  "code:",
                  err.code
                );
                reject(err);
              });
          });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });

    return promise;
  };

  render() {
    // const { message, userId, name, picture, email, success, apiToken } = this.state;
    // const invalid = <div><h3>{ message }</h3><br /><a className='button' href='/'>BACK</a></div>;
    // const valid = (
    //   <div>
    //     <p>Id: {userId}</p>
    //     <p>Name: {name}</p>
    //     <p>
    //       Picture: {picture}<br/>
    //       <img src={picture} alt='profile' height={200} width='auto' />
    //     </p>
    //     <p>email: {email}</p>
    //     <p>API Token: {apiToken}</p>
    //   </div>
    // );
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }
}

LineCallBack.defaultProps = {
  onLoginSuccess: () => {},
  onLoginFailed: () => {}
};

LineCallBack.propTypes = {
  onLoginSuccess: PropTypes.func,
  onLoginFailed: PropTypes.func
};
