export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        decoration: ``,
        text: `Medical Certificate`,
        decorationStyle: ``,
        color: ``,
        bold: `true`,
        width: `auto`,
        alignment: `center`,
        fontSize: `18`,
        pageBreak: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
      },
      {
        pageBreak: ``,
        alignment: `center`,
        width: `auto`,
        bold: `true`,
        decorationColor: ``,
        fontSize: `18`,
        decorationStyle: ``,
        text: `5-Disease`,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
      },
      {
        decorationColor: ``,
        pageBreak: ``,
        decoration: ``,
        bold: false,
        text: ` `,
        fontSize: 15,
        width: `auto`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            width: 420,
            alignment: `left`,
            text: ``,
          },
          {
            text: `Date `,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: true,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            text: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 5,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `center`,
            decoration: ``,
            color: ``,
            bold: true,
            text: props.items?.formatCreatedDate,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        pageBreak: ``,
        width: `auto`,
        fontSize: 15,
        bold: false,
        color: ``,
        decorationStyle: ``,
        decoration: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        text: ` `,
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            text: ``,
            width: 30,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `This is certify that `,
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            fontSize: 15,
            bold: false,
            width: 10,
          },
          {
            stack: [
              {
                pageBreak: ``,
                decoration: ``,
                fontSize: 15,
                text: `${
                  props.items.patientInfo?.full_name_en !== ""
                    ? props.items?.patientInfo?.full_name_en
                    : props.items?.patientInfo?.full_name_th !== ""
                    ? props.items?.patientInfo?.full_name_th
                    : props.items?.patientInfo?.full_name
                }`,
                color: ``,
                decorationColor: ``,
                bold: false,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                alignment: `left`,
                width: `auto`,
                decorationStyle: ``,
              },
              {
                pageBreak: ``,
                decoration: ``,
                color: ``,
                width: `auto`,
                decorationStyle: ``,
                alignment: `left`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                bold: false,
                text: `.............................................................................................................................................................`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            text: `Identification / Passport number / Others`,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            width: 10,
            text: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                decorationColor: ``,
                bold: false,
                alignment: `left`,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no === "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decoration: ``,
                fontSize: 15,
                color: ``,
                decorationStyle: ``,
                width: `auto`,
              },
              {
                decorationColor: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                width: `auto`,
                text: `..............................................................................................................................`,
                alignment: `left`,
                fontSize: 15,
                decoration: ``,
                decorationStyle: ``,
                color: ``,
                bold: false,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            text: `Age `,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            width: `auto`,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 10,
            fontSize: 15,
            text: ``,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                decoration: ``,
                decorationColor: ``,
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                width: `auto`,
                color: ``,
                bold: false,
                text: `${props.items?.ageYear} years ${props.items?.ageMonth} months ${props.items?.ageDay} days`,
              },
              {
                text: `..............................................................`,
                alignment: `left`,
                preserveLeadingSpaces: true,
                bold: false,
                color: ``,
                fontSize: 15,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                width: `auto`,
                decorationColor: ``,
                pageBreak: ``,
                decoration: ``,
              },
            ],
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            text: ` has been examined and `,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
          },
          {
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            width: 5,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                alignment: `left`,
                width: `auto`,
                fontSize: 15,
                text:
                  props.items?.isFoundDisease === "Free" ? "free of the following diseases" : "",
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                bold: false,
                decorationColor: ``,
                color: ``,
                decoration: ``,
              },
              {
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                pageBreak: ``,
                decoration: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                text: `.........................................................................`,
                decorationColor: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        text: ` `,
        decorationColor: ``,
        fontSize: 15,
        bold: false,
        preserveLeadingSpaces: true,
        color: ``,
        alignment: `left`,
        decoration: ``,
        pageBreak: ``,
        width: `auto`,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            width: 55,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            width: `auto`,
            text: `1. โรคเรื้อน (Leprosy)`,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            width: 55,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
          },
          {
            decoration: ``,
            text: `2. วัณโรคระยะอันตราย (Tuberculosis)`,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            width: 55,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `3. โรคเท้าช้าง (Elephantiasis)`,
            alignment: `left`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            width: 55,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            decoration: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `4. โรคยาเสพติดให้โทษ (Drug Addiction)`,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 55,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            bold: false,
          },
          {
            text: `5. โรคซิฟิลิสในระยะที่ 3 (Third step of syphilis)`,
            color: ``,
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        fontSize: 15,
        color: ``,
        text: ` `,
        decoration: ``,
        bold: false,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        alignment: `left`,
        width: `auto`,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            text: `Other (if any) `,
            bold: false,
          },
          {
            pageBreak: ``,
            color: ``,
            text: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 10,
          },
          {
            stack: [
              {
                decorationColor: ``,
                margin: [0, 0, 0, -15],
                text: props.items?.otherNote === "" ? " " : props.items?.otherNote,
                bold: false,
                alignment: `left`,
                width: `auto`,
                decorationStyle: ``,
                pageBreak: ``,
                color: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decoration: ``,
              },
              {
                text: `...................................................................................................................................................................................`,
                decoration: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                pageBreak: ``,
                alignment: `left`,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                color: ``,
                decorationStyle: ``,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        color: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        decoration: ``,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        width: `auto`,
        text: `...............................................................................................................................................................................................................`,
        decorationStyle: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
      },
      {
        decorationColor: ``,
        bold: false,
        decoration: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        color: ``,
        fontSize: 15,
        width: `auto`,
        text: `...............................................................................................................................................................................................................`,
      },
      {
        text: ` `,
        width: `auto`,
        color: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        bold: false,
        decorationColor: ``,
        fontSize: 15,
        decoration: ``,
        alignment: `left`,
        pageBreak: ``,
      },
      {
        bold: false,
        pageBreak: ``,
        decorationStyle: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        color: ``,
        width: `auto`,
        alignment: `left`,
        text: `Patient is in good physical, mental health and free from any defect.`,
        decorationColor: ``,
        decoration: ``,
      },
      {
        color: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
        bold: false,
        fontSize: 15,
        alignment: `left`,
        decorationColor: ``,
        text: ` `,
      },
      {
        preserveLeadingSpaces: true,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        text: ` `,
        decorationStyle: ``,
        alignment: `left`,
        width: `auto`,
        fontSize: 15,
        color: ``,
        decoration: ``,
      },
      {
        width: `auto`,
        text: ` `,
        fontSize: 15,
        pageBreak: ``,
        decorationColor: ``,
        color: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        bold: false,
        decorationStyle: ``,
      },
      {
        alignment: `left`,
        bold: false,
        preserveLeadingSpaces: true,
        width: `auto`,
        decorationColor: ``,
        fontSize: 15,
        decoration: ``,
        color: ``,
        pageBreak: ``,
        text: ` `,
        decorationStyle: ``,
      },
      {
        alignment: `left`,
        fontSize: 15,
        decoration: ``,
        bold: false,
        color: ``,
        decorationColor: ``,
        text: ` `,
        width: `auto`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
      },
      {
        preserveLeadingSpaces: true,
        bold: false,
        pageBreak: ``,
        fontSize: 15,
        alignment: `left`,
        text: ` `,
        width: `auto`,
        decorationColor: ``,
        color: ``,
        decorationStyle: ``,
        decoration: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            text: `Physician’s Signature `,
            decoration: ``,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            width: 30,
            text: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            decoration: ``,
            fontSize: 15,
            color: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `....................................................`,
            width: `auto`,
            alignment: `center`,
            pageBreak: ``,
            color: ``,
            bold: false,
          },
          {
            decorationStyle: ``,
            bold: false,
            width: 30,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            alignment: `center`,
            text: ` `,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            bold: false,
            color: ``,
            text: `Medical License No. `,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            width: 30,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            alignment: `center`,
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: `underline`,
            text: props.items?.doctorCertNumber
              ? props.items?.doctorCertNumber
              : "                    ",
            bold: false,
            decorationStyle: `dotted`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 134,
            bold: false,
            text: `		             `,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            text: `(`,
            margin: [0, 0, 0, 0],
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            alignment: `center`,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            text: props.items?.doctorNameEn,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            text: `)`,
            width: `auto`,
            color: ``,
            decoration: ``,
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    pageSize: `A4`,
    header: props.header,
    footer: props.footer,
  };
}
