import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardAppointmentListUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%" }}>
        <div
          style={{...(props.advanceSearch ? {display: "none"} : {display:"flex"}) , marginBottom: "20px", marginTop: "20px"} }>
          
          <Input
            onChange={(e: any) => { props.setProp("patientSearchText", e.target.value); } }
            onKeyDown={props.onSearchKey}
            placeholder="กรอกชือผู้ป่วย หรือ HN"
            style={{ flex: 1 }}
            value={props.patientSearchText}>
          </Input>
          <Button
            color="blue"
            onClick={props.onSearchButton}>
            ค้นหา
          </Button>
        </div>
        <div
          style={{...(props.advanceSearch ? {display: "flex"} : {display:"none"}) , flexDirection: "column", gap: "10px", marginLeft: "10px"}}>
          
          <div
            style={{display: "flex",gap: "10px"}}>
            
            <div
              style={{display:"flex",alignItems: "center", gap:"10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                HN
              </div>
              <div>
                {}
                <Input
                  onChange={props.onChangeHN}
                  value={props.hn}>
                </Input>
              </div>
            </div>
            <div
              style={{display:"flex",alignItems: "center", gap:"10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                ชื่อ-นามสกุล
              </div>
              <div>
                
                <Input
                  onChange={props.onChangeName}
                  value={props.name}>
                </Input>
              </div>
            </div>
            <div
              style={{display:"flex",alignItems: "center", gap:"10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                วันเกิด
              </div>
              <div>
                
                <DateTextBox
                  maxDate={props.maxDateDOB}
                  onChange={props.onChangeDOB}
                  value={props.dob}>
                </DateTextBox>
              </div>
            </div>
          </div>
          <div
            style={{display: "flex", alignItems: "center", gap: "10px"}}>
            
            <div
              style={{display:"flex",alignItems: "center", gap:"10px", fontWeight: "bolder", color: "#5dbcd2"}}>
              
              <div>
                วันที่นัดหมาย
              </div>
              <div>
                เริ่ม
              </div>
              <div>
                
                <DateTextBox
                  onChange={props.onChangeStartDate}
                  value={props.startDate}>
                </DateTextBox>
              </div>
              <div>
                ถึง
              </div>
              <div>
                
                <DateTextBox
                  onChange={props.onChangeEndDate}
                  value={props.endDate}>
                </DateTextBox>
              </div>
            </div>
            <div
              style={{display:"flex",alignItems: "center", gap:"10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                แผนก
              </div>
              <div>
                
                <Dropdown
                  clearable={true}
                  onChange={props.onChangeDivision}
                  options={props.divisionList}
                  selection={true}
                  value={props.division}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{display:"flex",alignItems: "center", gap:"10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                แพทย์
              </div>
              <div>
                
                <Input
                  onChange={props.onChangeDoctor}
                  value={props.doctor}>
                </Input>
              </div>
            </div>
            <div
              style={{display:"flex",alignItems: "center"}}>
              
              <div>
                
                <Button
                  color="blue"
                  onClick={props.onSearchButton}>
                  ค้นหา
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
          
          <div>
            
            <div
              style={{ fontWeight: "bold", fontSize: "1.2rem", margin: "5px" }}>
              คนไข้จากคำค้นหา
            </div>
            <div>
              
              <Table
                data={props.patientList}
                getTrProps={props.patientRowProps}
                headers="HN,ชื่อ-นามสกุล,วันเกิด,วันที่นัดหมาย,เวลา,แผนก,แพทย์"
                keys="hn,full_name,patient_dob_be, appointment_date, appointment_time,division_name, doctor_name"
                minRows="5"
                showPagination={false}
                style={{height: "250px", marginBottom: "30px" }}>
              </Table>
            </div>
          </div>
          <div
            style={{ display: props.hideAppointmentTable ? "none" : "block" }}>
            
            <div
              style={{ fontWeight: "bold", fontSize: "1.2rem", margin: "5px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              
              <div>
                นัดหมายก่อนหน้า
              </div>
            </div>
            <div>
              
              <Table
                data={props.appointmentList}
                getTrProps={props.appointmentRowProps}
                headers="วันเวลา,ชื่อ,นามสกุล,คลีนิค,รายละเอียด"
                keys="start_datetime,patient_first_name,patient_last_name,division_name,description"
                minRows="5"
                showPagination={false}
                style={{height: "250px", marginBottom: "30px" }}>
              </Table>
            </div>
          </div>
          <div>
            {props.AppointmentEditor}
          </div>
        </div>
      </div>
    )
}

export default CardAppointmentListUX

export const screenPropsDefault = {}

/* Date Time : Fri Oct 07 2022 10:37:14 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchButton"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "Input",
      "parent": 3,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e: any) => { props.setProp(\"patientSearchText\", e.target.value); } "
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onSearchKey"
        },
        "placeholder": {
          "type": "value",
          "value": "กรอกชือผู้ป่วย หรือ HN"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1 }"
        },
        "value": {
          "type": "code",
          "value": "props.patientSearchText"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.advanceSearch ? {display: \"none\"} : {display:\"flex\"}) , marginBottom: \"20px\", marginTop: \"20px\"} "
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 4,
      "props": {
        "data": {
          "type": "code",
          "value": "props.patientList"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.patientRowProps"
        },
        "headers": {
          "type": "value",
          "value": "HN,ชื่อ-นามสกุล,วันเกิด,วันที่นัดหมาย,เวลา,แผนก,แพทย์"
        },
        "keys": {
          "type": "value",
          "value": "hn,full_name,patient_dob_be, appointment_date, appointment_time,division_name, doctor_name"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\", marginBottom: \"30px\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AppointmentEditor"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 8,
      "name": "Table",
      "parent": 7,
      "props": {
        "data": {
          "type": "code",
          "value": "props.appointmentList"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.appointmentRowProps"
        },
        "headers": {
          "type": "value",
          "value": "วันเวลา,ชื่อ,นามสกุล,คลีนิค,รายละเอียด"
        },
        "keys": {
          "type": "value",
          "value": "start_datetime,patient_first_name,patient_last_name,division_name,description"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\", marginBottom: \"30px\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "คนไข้จากคำค้นหา"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"1.2rem\", margin: \"5px\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"1.2rem\", margin: \"5px\", display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดหมายก่อนหน้า"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideAppointmentTable ? \"none\" : \"block\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.advanceSearch ? {display: \"flex\"} : {display:\"none\"}) , flexDirection: \"column\", gap: \"10px\", marginLeft: \"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",gap: \"10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", gap: \"10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems: \"center\", gap:\"10px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems: \"center\", gap:\"10px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems: \"center\", gap:\"10px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems: \"center\", gap:\"10px\", fontWeight: \"bolder\", color: \"#5dbcd2\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems: \"center\", gap:\"10px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems: \"center\", gap:\"10px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems: \"center\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเกิด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดหมาย"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "เริ่ม"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Input",
      "parent": 32,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeHN"
        },
        "value": {
          "type": "code",
          "value": "props.hn"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Input",
      "parent": 34,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeName"
        },
        "value": {
          "type": "code",
          "value": "props.name"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 45,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDoctor"
        },
        "value": {
          "type": "code",
          "value": "props.doctor"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Dropdown",
      "parent": 43,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDivision"
        },
        "options": {
          "type": "code",
          "value": "props.divisionList"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.division"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchButton"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 53,
      "name": "DateTextBox",
      "parent": 39,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 54,
      "name": "DateTextBox",
      "parent": 41,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 55,
      "name": "DateTextBox",
      "parent": 36,
      "props": {
        "maxDate": {
          "type": "code",
          "value": "props.maxDateDOB"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDOB"
        },
        "value": {
          "type": "code",
          "value": "props.dob"
        }
      },
      "seq": 55,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": true,
  "memo": false,
  "name": "CardAppointmentListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
