import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Form,
  FormGroup,
  FormField,
  Dropdown,
  TextArea,
  Input
} from 'semantic-ui-react'

const CardPainAssessmentUX = (props: any) => {
    return(
      <div
        style={{padding: "15px"}}>
        <div
          style={{padding: "5px", fontSize: "1.2rem", fontWeight: "bold"}}>
          Pain Assessment
        </div>
        <Table
          data={props.data}
          getTdProps={props.rowProps}
          headers="Date,User,Pain Score,Assessment,Location,Characteristic,Frequency,Duration(min),Duration(sec),Nursing,Remark,Delete"
          keys="date,user_fullname,pain_score_desc,pain_assessment_desc,locationText,characteristic,frequency_desc,duration_min,duration_sec,nursing_desc,nursing_remark,delete"
          minRows="3"
          showPagination={false}
          style={{height: "150px"}}>
        </Table>
        <div
          style={{ margin: "20px 25px" }}>
          
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                width={3}>
                <div>
                  Pain Score
                </div>
              </FormField>
              <FormField
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    onChange={props.changePain("pain_score")}
                    options={props.painScoreOptions}
                    selection={true}
                    value={props.newPainData?.pain_score || ""}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                width={3}>
                <div>
                  Pain Assessment Tool
                </div>
              </FormField>
              <FormField
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    onChange={props.changePain("pain_assessment_tool")}
                    options={props.painAssessmentOptions}
                    selection={true}
                    value={props.newPainData?.pain_assessment_tool || ""}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                width={3}>
                <div>
                  Location
                </div>
              </FormField>
              <FormField
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    onChange={props.changePain("location")}
                    options={props.painLocationOptions}
                    selection={true}
                    value={props.newPainData?.location || ""}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(props.newPainData?.location !== "อื่นๆ" && { display: "none" }) }}
                width={5}>
                <div>
                  ระบุ
                </div>
                <div
                  style={{ width: "100%" }}>
                  
                  <TextArea
                    onChange={props.changePain("locationRemark")}
                    rows="1"
                    value={props.newPainData?.locationRemark || ""}>
                  </TextArea>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                width={3}>
                <div>
                  Characteristic
                </div>
              </FormField>
              <FormField
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    onChange={props.changePain("characteristic")}
                    options={props.painCharacteristicOptions}
                    selection={true}
                    value={props.newPainData?.characteristic || ""}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                width={3}>
                <div>
                  Frequency
                </div>
              </FormField>
              <FormField
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    onChange={props.changePain("frequency")}
                    options={props.painFrequencyOptions}
                    selection={true}
                    value={props.newPainData?.frequency || ""}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                width={3}>
                <div>
                  Duration (ครั้งละประมาณ)
                </div>
              </FormField>
              <FormField
                width={6}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Form>
                    <FormGroup
                      inline={true}>
                      <FormField
                        inline={true}
                        width={8}>
                        <div
                          style={{ width: "100%" }}>
                          
                          <Input
                            fluid={true}
                            onChange={props.changePain("duration_min")}
                            value={props.newPainData?.duration_min || ""}>
                          </Input>
                        </div>
                        <div>
                          นาที
                        </div>
                      </FormField>
                      <FormField
                        inline={true}
                        width={8}>
                        <div
                          style={{ width: "100%" }}>
                          
                          <Input
                            fluid={true}
                            onChange={props.changePain("duration_sec")}
                            value={props.newPainData?.duration_sec || ""}>
                          </Input>
                        </div>
                        <div>
                          วินาที
                        </div>
                      </FormField>
                    </FormGroup>
                  </Form>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                width={3}>
                <div>
                  การให้การพยาบาลผู้ป่วย
                </div>
              </FormField>
              <FormField
                width={6}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    multiple={true}
                    onChange={props.changePain("nursing")}
                    options={props.painNursingOptions}
                    selection={true}
                    value={props.newPainData?.nursing || ""}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ alignItems: "baseline" }}>
              <FormField
                width={3}>
                <div>
                  ระบุ
                </div>
              </FormField>
              <FormField
                width={6}>
                <div
                  style={{ width: "100%" }}>
                  
                  <TextArea
                    onChange={props.changePain("nursing_remark")}
                    rows="3"
                    value={props.newPainData?.nursing_remark || ""}>
                  </TextArea>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", margin: "0px 20px" }}>
          
          <Button
            color="orange"
            onClick={props.updatePain}
            style={props.openEdit}>
            แก้ไข Pain Assessment
          </Button>
          <Button
            color="teal"
            onClick={props.clearPain}
            style={props.openClear}>
            ล้างหน้าจอ Pain Assessment
          </Button>
          <Button
            color="blue"
            onClick={props.onReassess}>
            Reassessment
          </Button>
        </div>
      </div>
    )
}


export default CardPainAssessmentUX

export const screenPropsDefault = {}

/* Date Time : Wed Aug 14 2024 16:56:42 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain Assessment"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 38,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "Date,User,Pain Score,Assessment,Location,Characteristic,Frequency,Duration(min),Duration(sec),Nursing,Remark,Delete"
        },
        "keys": {
          "type": "value",
          "value": "date,user_fullname,pain_score_desc,pain_assessment_desc,locationText,characteristic,frequency_desc,duration_min,duration_sec,nursing_desc,nursing_remark,delete"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", margin: \"0px 20px\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไข Pain Assessment"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.updatePain"
        },
        "style": {
          "type": "code",
          "value": "props.openEdit"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Button",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างหน้าจอ Pain Assessment"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearPain"
        },
        "style": {
          "type": "code",
          "value": "props.openClear"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Button",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "Reassessment"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onReassess"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px 25px\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Form",
      "parent": 60,
      "props": {
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"baseline\" }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 62,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 63,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 63,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 64,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 64,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 65,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 65,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 66,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 66,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 67,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 67,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 68,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 68,
      "props": {
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 69,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 69,
      "props": {
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain Score"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain Assessment Tool"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "Characteristic"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "Duration (ครั้งละประมาณ)"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาลผู้ป่วย"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Dropdown",
      "parent": 87,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"pain_score\")"
        },
        "options": {
          "type": "code",
          "value": "props.painScoreOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.pain_score || \"\""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Dropdown",
      "parent": 89,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"pain_assessment_tool\")"
        },
        "options": {
          "type": "code",
          "value": "props.painAssessmentOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.pain_assessment_tool || \"\""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Dropdown",
      "parent": 91,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"location\")"
        },
        "options": {
          "type": "code",
          "value": "props.painLocationOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.location || \"\""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Dropdown",
      "parent": 93,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"characteristic\")"
        },
        "options": {
          "type": "code",
          "value": "props.painCharacteristicOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.characteristic || \"\""
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Dropdown",
      "parent": 95,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"frequency\")"
        },
        "options": {
          "type": "code",
          "value": "props.painFrequencyOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.frequency || \"\""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Dropdown",
      "parent": 99,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"nursing\")"
        },
        "options": {
          "type": "code",
          "value": "props.painNursingOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.nursing || \"\""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "TextArea",
      "parent": 101,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"nursing_remark\")"
        },
        "rows": {
          "type": "value",
          "value": "3"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.nursing_remark || \"\""
        }
      },
      "seq": 108,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Form",
      "parent": 97,
      "props": {
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormGroup",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Input",
      "parent": 114,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"duration_min\")"
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.duration_min || \"\""
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Input",
      "parent": 113,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"duration_sec\")"
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.duration_sec || \"\""
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": "นาที"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "วินาที"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.newPainData?.location !== \"อื่นๆ\" && { display: \"none\" }) }"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "TextArea",
      "parent": 121,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"locationRemark\")"
        },
        "rows": {
          "type": "value",
          "value": "1"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.newPainData?.locationRemark || \"\""
        }
      },
      "seq": 122,
      "void": true
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        }
      },
      "seq": 121,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "CardPainAssessmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
