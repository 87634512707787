import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Input,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSupplyOrderSearchUX = (props: any) => {
    return(
      <div
        style={{width:"100%", height:"100%", padding: "1rem"}}>
        <Form>
          <FormGroup
            style={{display: "grid", margin: 0}}>
            <FormField
              inline={true}>
              <div
                style={{fontSize: "1.28571429em", fontWeight: "bold"}}>
                ค้นหาเวชภัณฑ์
              </div>
            </FormField>
            <FormField
              width={16}>
              <div
                className="ui divider"
                style={{width: "calc(100% - -16px)", marginLeft: "-8px"}}>
                
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={12}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                ชื่อ หรือ รหัส
              </label>
              <Input
                id="txt-searchSupplyitemList"
                onChange={props.onChangeSearchName}
                onKeyDown={(e: any) => { e.key === "Enter" && props.onSearch(); }}
                style={{width: "100%"}}
                value={props.searchName}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Button
                color="blue"
                id="btn-searchSupplyitem"
                loading={props.nameLoading}
                onClick={props.onSearch}
                style={{width: "100%"}}>
                ค้นหา
              </Button>
              <Button
                color="blue"
                disabled={true}
                onClick={props.onClickListView}
                style={{width: "100%"}}>
                แสดงภาพ
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{fontWeight: "normal"}}>
                หมวด
              </label>
              <Dropdown
                fluid={true}
                id="cb-supplyMode"
                onChange={props.onChangeSearchMode}
                options={props.modeOptions}
                selection={true}
                style={{width: "100%"}}
                value={props.searchMode}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal"}}>
                ผู้ผลิต
              </label>
              <Dropdown
                fluid={true}
                id="search"
                loading={props.manufactureLoading}
                minCharacters={2}
                onChange={props.onChangeSearchManufacturer}
                onClose={props.onCloseSearchManufacturer}
                onSearchChange={props.onSearchChangeQueryManufacturer}
                open={props.openManufacturer}
                openOnFocus={false}
                options={props.manufacturerOptions}
                search={true}
                searchQuery={props.searchQueryManufacturer}
                selection={true}
                style={{width: "100%"}}
                value={props.searchManufacturer}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{fontWeight: "normal"}}>
                กองทุน
              </label>
              <Dropdown
                disabled={true}
                onChange={props.onChangeSearchGroup}
                options={props.groupOptions}
                selection={true}
                style={{width: "100%"}}
                value={props.searchGroup}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Button
                color={props.isFavorite ?  "yellow" : "grey"}
                onClick={props.onSelectFavorite}
                style={{width: "100%"}}>
                เฉพาะรายการโปรด
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={16}>
              <Table
                columns={props.columns}
                data={props.data}
                getTdProps={props.getTdprops}
                getTheadThProps={props.getThreadThprops}
                getTrProps={props.getTrprops}
                headers={`${!props.isShowPriceUnit ? ",No,Code,Mode,Product Name,จำนวน,ขนาดบรรจุ,ราคา,ใช้บ่อย" : ",No,Code,Mode,Product Name,จำนวน,ขนาดบรรจุ,ใช้บ่อย,ราคา Normal (ระดับที่1),ราคา Special (ระดับที่2),ราคา Premium (ระดับที่3),ราคาชาวต่างชาติ"}`}
                id="tb-supplyListItem"
                keys={`${!props.isShowPriceUnit ? "selected,no,code,mode,name,count,package_size,price_unit,favorite" : "selected,no,code,mode,name,count,package_size,favorite,price_normal,price_special,price_premium,price_foreign"}`}
                minRows="10"
                onPageChange={props.onPageChange}
                onPageSizeChange={props.onPageSizeChange}
                page={props.paginatedItems?.page || 0}
                pageSize={props.paginatedItems?.pageSize || 0}
                pages={Math.ceil(props.paginatedItems?.total / props.paginatedItems?.pageSize)}
                showPagination={true}
                style={{height: "420px"}}
                widths={`${!props.isShowPriceUnit ? "40,80,100,140,auto,80,100,100,60" : "40,80,100,140,auto,80,100,60"}`}>
              </Table>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField>
              <Button
                color="green"
                id="btn-selectSupply"
                onClick={props.onSelect}>
                เลือก
              </Button>
            </FormField>
            <FormField>
              <Button
                color="red"
                id="btn-cancelSupply"
                onClick={props.onCancel}>
                ยกเลิก
              </Button>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardSupplyOrderSearchUX

export const screenPropsDefault = {}

/* Date Time : Fri Nov 22 2024 15:02:45 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", height:\"100%\", padding: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", margin: 0}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.28571429em\", fontWeight: \"bold\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 6,
      "props": {
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 6,
      "props": {
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ หรือ รหัส"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "หมวด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ผลิต"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "กองทุน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-supplyMode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchMode"
        },
        "options": {
          "type": "code",
          "value": "props.modeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchMode"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "search"
        },
        "loading": {
          "type": "code",
          "value": "props.manufactureLoading"
        },
        "minCharacters": {
          "type": "code",
          "value": "2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchManufacturer"
        },
        "onClose": {
          "type": "code",
          "value": "props.onCloseSearchManufacturer"
        },
        "onSearchChange": {
          "type": "code",
          "value": "props.onSearchChangeQueryManufacturer"
        },
        "open": {
          "type": "code",
          "value": "props.openManufacturer"
        },
        "openOnFocus": {
          "type": "code",
          "value": "false"
        },
        "options": {
          "type": "code",
          "value": "props.manufacturerOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "searchQuery": {
          "type": "code",
          "value": "props.searchQueryManufacturer"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchManufacturer"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchGroup"
        },
        "options": {
          "type": "code",
          "value": "props.groupOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchGroup"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Input",
      "parent": 9,
      "props": {
        "id": {
          "type": "value",
          "value": "txt-searchSupplyitemList"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchName"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.onSearch(); }"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchName"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-searchSupplyitem"
        },
        "loading": {
          "type": "code",
          "value": "props.nameLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "แสดงภาพ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickListView"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "เฉพาะรายการโปรด"
        },
        "color": {
          "type": "code",
          "value": "props.isFavorite ?  \"yellow\" : \"grey\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectFavorite"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 32,
      "name": "Table",
      "parent": 16,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columns"
        },
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdprops"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.getThreadThprops"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrprops"
        },
        "headers": {
          "type": "code",
          "value": "`${!props.isShowPriceUnit ? \",No,Code,Mode,Product Name,จำนวน,ขนาดบรรจุ,ราคา,ใช้บ่อย\" : \",No,Code,Mode,Product Name,จำนวน,ขนาดบรรจุ,ใช้บ่อย,ราคา Normal (ระดับที่1),ราคา Special (ระดับที่2),ราคา Premium (ระดับที่3),ราคาชาวต่างชาติ\"}`"
        },
        "id": {
          "type": "value",
          "value": "tb-supplyListItem"
        },
        "keys": {
          "type": "code",
          "value": "`${!props.isShowPriceUnit ? \"selected,no,code,mode,name,count,package_size,price_unit,favorite\" : \"selected,no,code,mode,name,count,package_size,favorite,price_normal,price_special,price_premium,price_foreign\"}`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "onPageChange": {
          "type": "code",
          "value": "props.onPageChange"
        },
        "onPageSizeChange": {
          "type": "code",
          "value": "props.onPageSizeChange"
        },
        "page": {
          "type": "code",
          "value": "props.paginatedItems?.page || 0"
        },
        "pageSize": {
          "type": "code",
          "value": "props.paginatedItems?.pageSize || 0"
        },
        "pages": {
          "type": "code",
          "value": "Math.ceil(props.paginatedItems?.total / props.paginatedItems?.pageSize)"
        },
        "showPagination": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height: \"420px\"}"
        },
        "widths": {
          "type": "code",
          "value": "`${!props.isShowPriceUnit ? \"40,80,100,140,auto,80,100,100,60\" : \"40,80,100,140,auto,80,100,60\"}`"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Button",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "id": {
          "type": "value",
          "value": "btn-cancelSupply"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "btn-selectSupply"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelect"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{width: \"calc(100% - -16px)\", marginLeft: \"-8px\"}"
        }
      },
      "seq": 36,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyOrderSearchUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
