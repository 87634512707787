import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Checkbox,
  Dropdown,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDrugTransferRequestHistoryUX = (props: any) => {
    return(
      <div>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem", marginBottom: "10px"}}>
          ประวัติการขอยา
        </div>
        <div
          style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
          
          <div
            style={{display: "flex", alignItems: "center", marginRight: "5px"}}>
            
            <Checkbox
              checked={props?.checkedDivisionRequest}
              onChange={props.handleChangeChecked("checkedDivisionRequest")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              หน่วยงานขอ
            </div>
            <Dropdown
              onChange={props.handleChangeValue("selectedDivisionRequest")}
              options={props?.divisionPharmaOptions}
              selection={true}
              value={props?.selectedDivisionRequest}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "5px"}}>
            
            <Checkbox
              checked={props?.checkedDivisionProvider}
              onChange={props.handleChangeChecked("checkedDivisionProvider")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              หน่วยงานจ่าย
            </div>
            <Dropdown
              onChange={props.handleChangeValue("selectedDivisionProvider")}
              options={props?.divisionPharmaOptions}
              selection={true}
              value={props?.selectedDivisionProvider}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "5px"}}>
            
            <Checkbox
              checked={props?.checkedFilterDate}
              onChange={props.handleChangeChecked("checkedFilterDate")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              วันที่
            </div>
            <DateTextBox
              onChange={props.handleChangeDate("selectedStartDate")}
              value={props?.selectedStartDate}>
            </DateTextBox>
            <DateTextBox
              onChange={props.handleChangeDate("selectedEndDate")}
              value={props?.selectedEndDate}>
            </DateTextBox>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "5px"}}>
            
            <Checkbox
              checked={props?.checkedCode}
              onChange={props.handleChangeChecked("checkedCode")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              เลขที่ใบขอยา
            </div>
            <Input
              onChange={props.handleChangeValue("selectedCode")}
              value={props?.selectedCode}>
            </Input>
          </div>
          <Button
            color="blue"
            onClick={props.OnSearch}
            size="tiny">
            ค้นหา
          </Button>
          <Button
            color="yellow"
            onClick={props.OnClear}
            size="tiny">
            ล้าง
          </Button>
        </div>
        <div>
          
          <Table
            data={props?.dataDrugTransferRequestHistoryList}
            headers="เลขที่,รายการยา,ขอ,ตรวจ,จ่าย,รับ,สถานะ"
            keys="code,listDrug,request,approve,delivery,receive,statusName"
            minRows="15"
            showPagination={false}
            style={{height: "400px"}}>
          </Table>
        </div>
      </div>
    )
}

export default CardDrugTransferRequestHistoryUX

export const screenPropsDefault = {}

/* Date Time : Mon Jul 11 2022 14:22:23 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการขอยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\", marginBottom: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", flexWrap: \"wrap\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.dataDrugTransferRequestHistoryList"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "เลขที่,รายการยา,ขอ,ตรวจ,จ่าย,รับ,สถานะ"
        },
        "keys": {
          "type": "value",
          "value": "code,listDrug,request,approve,delivery,receive,statusName"
        },
        "minRows": {
          "type": "value",
          "value": "15"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"5px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"5px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"5px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"5px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnSearch"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้าง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnClear"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedDivisionRequest"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDivisionRequest\")"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานขอ"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Dropdown",
      "parent": 5,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivisionRequest\")"
        },
        "options": {
          "type": "code",
          "value": "props?.divisionPharmaOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedDivisionRequest"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Checkbox",
      "parent": 6,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedDivisionProvider"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDivisionProvider\")"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Dropdown",
      "parent": 6,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivisionProvider\")"
        },
        "options": {
          "type": "code",
          "value": "props?.divisionPharmaOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedDivisionProvider"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Checkbox",
      "parent": 7,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedFilterDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedFilterDate\")"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 19,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedStartDate\")"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedStartDate"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 20,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedEndDate\")"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedEndDate"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedCode\")"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบขอยา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Input",
      "parent": 8,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedCode\")"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedCode"
        }
      },
      "seq": 24,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugTransferRequestHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
