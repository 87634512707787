import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Input,
  Button
} from 'semantic-ui-react'

const ModCancelConsultAppointmentUX = (props: any) => {
    return(
      <div>
        <div
          style={{display: "flex", padding: "10px", backgroundColor: "#da0000", color: "white", fontSize: "16px", fontWeight: "bold"}}>
          ข้อความแจ้งเตือน
        </div>
        <div
          style={{display: "flex", padding: "15px", alignItems: "center"}}>
          {}
          <div
            style={{display: "flex", paddingRight: "10px"}}>
            
            <div
              style={{fontWeight: "bold"}}>
              ยกเลิกนัดหมาย Consult โดย
            </div>
            <div
              style={{color :"red", ...(props.showCancelNote?{display: "none"}: {})}}>
              *
            </div>
          </div>
          <div
            style={{...(props.showCancelNote?{display: "none"}: {})}}>
            
            <Dropdown
              onChange={props.onSelectCancelOption}
              options={props.cancelOptions}
              selection={true}
              value={props.cancelCause}>
            </Dropdown>
          </div>
          <div
            style={{...(props.showCancelNote?{display: "flex"}: {display: "none"})}}>
            {props.cancelCause}
          </div>
        </div>
        <div
          style={{display: "flex", padding: "15px", alignItems: "center"}}>
          
          <div
            style={{fontWeight: "bold"}}>
            กรุณาระบุเหตุผลในการยกเลิก
          </div>
          <div
            style={{color :"red", ...(props.showCancelNote?{display: "none"}: {})}}>
            *
          </div>
        </div>
        <div
          style={{padding: "10px", ...(props.showCancelNote?{display: "none"}: {})}}>
          
          <Input
            fluid={true}
            onChange={props.onReasonChange}
            value={props.cancelReason}>
          </Input>
        </div>
        <div
          style={{padding: "5px 15px", ...(props.showCancelNote?{display: "flex"}: {display: "none"})}}>
          {props.cancelReason}
        </div>
        <div
          style={{display: "flex", alignItems: "center",  padding: "10px", ...(props.showCancelNote?{display: "none"}: {})}}>
          
          <div
            style={{color: "red", paddingRight: "5px"}}>
            *
          </div>
          <Input
            onChange={props.onChangeUsername}
            placeholder="Username"
            style={{paddingRight: "5px"}}
            value={props.username}>
          </Input>
          <Input
            icon={props.passwordIcon}
            onChange={props.onChangePassword}
            placeholder="Password"
            style={{paddingRight: "5px"}}
            type={props.visible? "text" : "password"}
            value={props.password}>
          </Input>
          <Button
            className="inverted"
            color="green"
            onClick={props.onConfirm}>
            ยืนยัน
          </Button>
          <Button
            className="inverted"
            color="red"
            onClick={props.onCancel}>
            ยกเลิก
          </Button>
        </div>
        <div
          style={{padding: "15px", ...(props.showCancelNote?{display: "flex"}: {display: "none"})}}>
          {props.cancelBy}
        </div>
        <div
          style={{...(props.showCancelNote?{display: "flex"}: {display: "none"}), justifyContent: "flex-end", alignItems: "center", padding: "10px"}}>
          
          <Button
            className="inverted"
            color="red"
            onClick={props.onCancel}>
            ปิดหน้าต่าง
          </Button>
        </div>
      </div>
    )
}


export default ModCancelConsultAppointmentUX

export const screenPropsDefault = {}

/* Date Time : Wed Oct 11 2023 11:49:38 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อความแจ้งเตือน"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"10px\", backgroundColor: \"#da0000\", color: \"white\", fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"15px\", alignItems: \"center\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"15px\", alignItems: \"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", ...(props.showCancelNote?{display: \"none\"}: {})}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",  padding: \"10px\", ...(props.showCancelNote?{display: \"none\"}: {})}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", paddingRight: \"10px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกนัดหมาย Consult โดย"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color :\"red\", ...(props.showCancelNote?{display: \"none\"}: {})}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.showCancelNote?{display: \"none\"}: {})}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Dropdown",
      "parent": 9,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onSelectCancelOption"
        },
        "options": {
          "type": "code",
          "value": "props.cancelOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.cancelCause"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาระบุเหตุผลในการยกเลิก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color :\"red\", ...(props.showCancelNote?{display: \"none\"}: {})}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Input",
      "parent": 4,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onReasonChange"
        },
        "value": {
          "type": "code",
          "value": "props.cancelReason"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Input",
      "parent": 5,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeUsername"
        },
        "placeholder": {
          "type": "value",
          "value": "Username"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Input",
      "parent": 5,
      "props": {
        "icon": {
          "type": "code",
          "value": "props.passwordIcon"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePassword"
        },
        "placeholder": {
          "type": "value",
          "value": "Password"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"5px\"}"
        },
        "type": {
          "type": "code",
          "value": "props.visible? \"text\" : \"password\""
        },
        "value": {
          "type": "code",
          "value": "props.password"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", paddingRight: \"5px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยัน"
        },
        "className": {
          "type": "value",
          "value": "inverted"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirm"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "className": {
          "type": "value",
          "value": "inverted"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.showCancelNote?{display: \"flex\"}: {display: \"none\"}), justifyContent: \"flex-end\", alignItems: \"center\", padding: \"10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Button",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "ปิดหน้าต่าง"
        },
        "className": {
          "type": "value",
          "value": "inverted"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cancelCause"
        },
        "style": {
          "type": "code",
          "value": "{...(props.showCancelNote?{display: \"flex\"}: {display: \"none\"})}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cancelReason"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px 15px\", ...(props.showCancelNote?{display: \"flex\"}: {display: \"none\"})}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cancelBy"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px\", ...(props.showCancelNote?{display: \"flex\"}: {display: \"none\"})}"
        }
      },
      "seq": 22,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 50,
  "name": "ModCancelConsultAppointmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 35
}

*********************************************************************************** */
