import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { FormGroup, FormField, Input, Button, Icon } from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";

const CardHealthMemberDetailUX = (props: any) => {
  return (
    <div style={{ padding: "15px" }}>
      <div className="ui form" style={{ padding: "1rem 0" }}>
        {}
        <FormGroup inline={true}>
          <FormField inline={true} width={2}>
            <label>ชื่อกลุ่ม :</label>
          </FormField>
          <FormField inline={true} width={6}>
            <Input
              autoFocus={true}
              name="name"
              onChange={props.onChangeSegment}
              type="text"
              value={props.segmentDetail?.name || ""}
            ></Input>
          </FormField>
          <FormField inline={true} width={3}></FormField>
          <FormField inline={true} width={2}>
            <div>{props.ButtonSave}</div>
          </FormField>
          <FormField inline={true} width={3}>
            <div>{props.ButtonDelete}</div>
          </FormField>
        </FormGroup>
      </div>
      <hr></hr>
      <div
        className="ui form"
        style={{ marginTop: "1rem", display: props.segmentDetail?.id ? "" : "none" }}
      >
        <FormGroup inline={true}>
          <FormField inline={true}>
            <label>เพิ่มรายบุคคล :</label>
          </FormField>
          <FormField inline={true} width={12}>
            <div style={{ width: "100%" }}>{props.AddPatientSearchBox}</div>
          </FormField>
          <FormField>
            <div>{props.ButtonAdd}</div>
          </FormField>
        </FormGroup>
        <FormGroup inline={true}>
          <FormField inline={true}>
            <label>เพิ่มเป็นกลุ่ม:</label>
          </FormField>
          <FormField inline={true}>
            <Input
              action={{ content: "Browse", color: "teal", onClick: props.onBrowse }}
              readOnly={true}
              value={props.fileName || ""}
            ></Input>
          </FormField>
          <FormField inline={true}>
            <div>{props.ButtonImport}</div>
          </FormField>
          <FormField inline={true}>
            <Button
              basic={true}
              color="blue"
              disabled={props.disabledDownloadTemplate || false}
              onClick={props.onDownloadTemplate}
            >
              Download templete
            </Button>
          </FormField>
        </FormGroup>
        <div>{props.ImportError}</div>
        <div className="ui form" style={{ marginTop: "2.5rem", padding: "0 10px" }}>
          <FormGroup>
            <FormField>
              <label style={{ margin: 0 }}>ค้นหาสมาชิกกลุ่ม</label>
            </FormField>
            <FormField inline={true} width={8}>
              <div style={{ width: "100%" }}>{props.SearchPatientSearchBox}</div>
            </FormField>
            <FormField>
              <div>{props.ButtonSearch}</div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <div className="field" style={{ flex: 1 }}></div>
            <FormField>
              <Button color="red" onClick={props.onRemove} size="tiny">
                <Icon name="trash alternate"></Icon>
                <label>ลบ</label>
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div style={{ padding: "0 10px" }}>
          <Table
            data={props.patientSegmentList || []}
            headers={" ,HN,ชื่อ-นามสกุล"}
            keys="chk,hn,fullname"
            minRows="3"
            showPagination={false}
            style={{ maxHeight: "49vh" }}
            widths="80"
          ></Table>
        </div>
      </div>
    </div>
  );
};

CardHealthMemberDetailUX.displayName = "CardHealthMemberDetailUX";
export default React.memo(CardHealthMemberDetailUX);

export const screenPropsDefault = {};

/* Date Time : Fri Jun 07 2024 15:31:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"1rem 0\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\", display: props.segmentDetail?.id ? \"\" : \"none\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormGroup",
      "parent": 15,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 15,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายบุคคล : "
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AddPatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มเป็นกลุ่ม: "
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 27,
      "props": {
        "action": {
          "type": "code",
          "value": "{content: \"Browse\", color: \"teal\", onClick: props.onBrowse}"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.fileName || \"\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 29,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "Download templete"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDownloadTemplate || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDownloadTemplate"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 16,
      "props": {
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2.5rem\", padding: \"0 10px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormGroup",
      "parent": 38,
      "props": {
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 39,
      "props": {
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาสมาชิกกลุ่ม"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 39,
      "props": {
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormGroup",
      "parent": 38,
      "props": {
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 57,
      "props": {
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Button",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRemove"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Icon",
      "parent": 63,
      "props": {
        "name": {
          "type": "value",
          "value": "trash alternate"
        },
        "onClick": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "ลบ"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 69,
      "name": "Table",
      "parent": 68,
      "props": {
        "data": {
          "type": "code",
          "value": "props.patientSegmentList || []"
        },
        "headers": {
          "type": "code",
          "value": "\" ,HN,ชื่อ-นามสกุล\""
        },
        "keys": {
          "type": "value",
          "value": "chk,hn,fullname"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"49vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "80"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonAdd"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonImport"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormGroup",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อกลุ่ม : "
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Input",
      "parent": 76,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSegment"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.segmentDetail?.name || \"\""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonDelete"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 15,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchPatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ImportError"
        }
      },
      "seq": 84,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": true,
  "name": "CardHealthMemberDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
