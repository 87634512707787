import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Button,
  Checkbox
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardStockManagementUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          className="ui form size small">

          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "fit-content"}}>
                สินค้า
              </label>
              <div
                style={{width: "100%"}}>
                {props.ProductSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                คลังสินค้า
              </label>
              <Dropdown
                fluid={true}
                name="storage"
                onChange={props.onChangeValue}
                options={props.storageOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.filterStock?.storage || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "fit-content"}}>
                P-type
              </label>
              <Dropdown
                fluid={true}
                name="productType"
                onChange={props.onChangeValue}
                options={props.productTypeDrugSupplyOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.filterStock?.productType || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                disabled={props.disabledClear || false}
                onClick={props.onClear}
                size="small">
                เคลียร์
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                basic={true}
                color="green"
                disabled={props.disabledAdd || false}
                onClick={props.onAddNewProduct}
                size="small">
                สร้างรหัสสินค้าใหม่
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{padding: 0}}>
              <Checkbox
                checked={props.filterStock?.isMinQTY || false}
                label="จำนวนต่ำกว่า Min QTY"
                name="isMinQTY"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold", paddingRight: "1rem", minWidth: "max-content", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{padding: 0}}>
              <Checkbox
                checked={props.filterStock?.isGrandTotal || false}
                label="Grand total เท่ากับ 0"
                name="isGrandTotal"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold", paddingRight: "1rem", minWidth: "max-content", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{padding: 0}}>
              <Checkbox
                checked={props.filterStock?.isExpiryDate || false}
                label="Expiry date ภายใน 6 เดือน"
                name="isExpiryDate"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold", paddingRight: "1rem",minWidth: "max-content", fontSize: "1em"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "fit-content"}}>
                สถานะ
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{padding: 0}}>
              <Dropdown
                name="status"
                onChange={props.onChangeValue}
                options={props.statusOptions || []}
                selection={true}
                value={props.filterStock?.status || ""}>
              </Dropdown>
            </FormField>
            <FormField>
              <label
                style={{paddingLeft: "1rem", minWidth: "max-content"}}>
                สถานะการนับ
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                name="counting"
                onChange={props.onChangeValue}
                options={props.countingStatusOptions || []}
                selection={true}
                value={props.filterStock?.counting|| ""}>
              </Dropdown>
            </FormField>
            <FormField
              style={{padding: 0}}>
              <div
                style={{ minWidth: "max-content"}}>
                {props.ButtonExportExcel}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">

          <Table
            data={props.stockItems || []}
            getTdProps={props.getTdProps}
            getTrProps={props.getTrProps}
            headers="Product Type,Code,Name,Storage,Bin Location,Balance (all),Balance  (not EXP.),Grand Total (not EXP.),Min QTY,Max QTY,Remark,Counting,Active,"
            keys="type,code,name,storage,bin_location,total_qty,unexp_qty,grand_qty,min_qty,max_qty,remark,counting,active,action"
            minRows={5}
            showPagination={false}
            style={{height: "275px"}}
            widths=",^90,,,^90,^90,^90,^90,^90,^90,,80,70,70">
          </Table>
          <div
            style={{display: "flex", flex: 1, justifyContent: "flex-end", marginTop: "0.5rem"}}>
            {props.Pagination}
          </div>
        </div>
        <div
          className="ui form"
          style={{marginTop: "0rem"}}>

          <div>
            {props.TabPans}
          </div>
        </div>
        <div
          className="ui form">

          <div>
            {props.TabContent}
          </div>
        </div>
      </div>
    )
}

CardStockManagementUX.displayName = "CardStockManagementUX";
export default React.memo(CardStockManagementUX)

export const screenPropsDefault = {}

/* Date Time : Tue Jul 30 2024 16:57:42 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form size small"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "storage"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.storageOptions || []"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterStock?.storage || \"\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Dropdown",
      "parent": 5,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "productType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.productTypeDrugSupplyOptions || []"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterStock?.productType || \"\""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledClear || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 8,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "สร้างรหัสสินค้าใหม่"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAdd || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNewProduct"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "สินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "คลังสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "P-type"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 18,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 18,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 18,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 18,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Checkbox",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterStock?.isMinQTY || false"
        },
        "label": {
          "type": "value",
          "value": "จำนวนต่ำกว่า Min QTY"
        },
        "name": {
          "type": "value",
          "value": "isMinQTY"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", paddingRight: \"1rem\", minWidth: \"max-content\", fontSize: \"1em\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterStock?.isGrandTotal || false"
        },
        "label": {
          "type": "value",
          "value": "Grand total เท่ากับ 0"
        },
        "name": {
          "type": "value",
          "value": "isGrandTotal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", paddingRight: \"1rem\", minWidth: \"max-content\", fontSize: \"1em\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Checkbox",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterStock?.isExpiryDate || false"
        },
        "label": {
          "type": "value",
          "value": "Expiry date ภายใน 6 เดือน"
        },
        "name": {
          "type": "value",
          "value": "isExpiryDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", paddingRight: \"1rem\",minWidth: \"max-content\", fontSize: \"1em\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 18,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.filterStock?.status || \"\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 32,
      "name": "Table",
      "parent": 31,
      "props": {
        "data": {
          "type": "code",
          "value": "props.stockItems || []"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "Product Type,Code,Name,Storage,Bin Location,Balance (all),Balance  (not EXP.),Grand Total (not EXP.),Min QTY,Max QTY,Remark,Counting,Active,"
        },
        "keys": {
          "type": "value",
          "value": "type,code,name,storage,bin_location,total_qty,unexp_qty,grand_qty,min_qty,max_qty,remark,counting,active,action"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"275px\"}"
        },
        "widths": {
          "type": "value",
          "value": ",^90,,,^90,^90,^90,^90,^90,^90,,80,70,70"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0rem\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TabPans"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TabContent"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ProductSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Pagination"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flex: 1, justifyContent: \"flex-end\", marginTop: \"0.5rem\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 18,
      "props": {
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 18,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการนับ"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Dropdown",
      "parent": 43,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "counting"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.countingStatusOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.filterStock?.counting|| \"\""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonExportExcel"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\"}"
        }
      },
      "seq": 47,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardStockManagementUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
