import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardOTObjectivePediatrics.module.css'

export interface CardOTObjectivePediatricsViewDataType {
    CardOtObjectivePediatrics2: string,
    CardOtObjectivePediatrics2Disabled: boolean,
    CardOtObjectivePediatrics4: boolean,
    CardOtObjectivePediatrics4Disabled: boolean,
    CardOtObjectivePediatrics6: boolean,
    CardOtObjectivePediatrics6Disabled: boolean,
    CardOtObjectivePediatrics7: boolean,
    CardOtObjectivePediatrics7Disabled: boolean,
    CardOtObjectivePediatrics9: boolean,
    CardOtObjectivePediatrics9Disabled: boolean,
    CardOtObjectivePediatrics11: boolean,
    CardOtObjectivePediatrics11Disabled: boolean,
    CardOtObjectivePediatrics13: boolean,
    CardOtObjectivePediatrics13Disabled: boolean,
    CardOtObjectivePediatrics15: boolean,
    CardOtObjectivePediatrics15Disabled: boolean,
    CardOtObjectivePediatrics18: boolean,
    CardOtObjectivePediatrics18Disabled: boolean,
    CardOtObjectivePediatrics25: boolean,
    CardOtObjectivePediatrics25Disabled: boolean,
    CardOtObjectivePediatrics26: boolean,
    CardOtObjectivePediatrics26Disabled: boolean,
    CardOtObjectivePediatrics27: boolean,
    CardOtObjectivePediatrics27Disabled: boolean,
    CardOtObjectivePediatrics29: boolean,
    CardOtObjectivePediatrics29Disabled: boolean,
    CardOtObjectivePediatrics36: string,
    CardOtObjectivePediatrics36Disabled: boolean,
    CardOtObjectivePediatrics37: boolean,
    CardOtObjectivePediatrics37Disabled: boolean,
    CardOtObjectivePediatrics41: boolean,
    CardOtObjectivePediatrics41Disabled: boolean,
    CardOtObjectivePediatrics44: boolean,
    CardOtObjectivePediatrics44Disabled: boolean,
    CtCommunication: string,
    CtCommunicationDisabled: boolean,
    CtCommunicationOptions: any,
    CtCoOperation: string,
    CtCoOperationDisabled: boolean,
    CtCoOperationOptions: any,
    CtLevelOfArousal: string,
    CtLevelOfArousalDisabled: boolean,
    CtLevelOfArousalOptions: any,
    SaveRequestDisabled: boolean,
}

export const CardOTObjectivePediatricsInitialViewData: CardOTObjectivePediatricsViewDataType = {
    CardOtObjectivePediatrics2: "",
    CardOtObjectivePediatrics2Disabled: false,
    CardOtObjectivePediatrics4: false,
    CardOtObjectivePediatrics4Disabled: false,
    CardOtObjectivePediatrics6: false,
    CardOtObjectivePediatrics6Disabled: false,
    CardOtObjectivePediatrics7: false,
    CardOtObjectivePediatrics7Disabled: false,
    CardOtObjectivePediatrics9: false,
    CardOtObjectivePediatrics9Disabled: false,
    CardOtObjectivePediatrics11: false,
    CardOtObjectivePediatrics11Disabled: false,
    CardOtObjectivePediatrics13: false,
    CardOtObjectivePediatrics13Disabled: false,
    CardOtObjectivePediatrics15: false,
    CardOtObjectivePediatrics15Disabled: false,
    CardOtObjectivePediatrics18: false,
    CardOtObjectivePediatrics18Disabled: false,
    CardOtObjectivePediatrics25: false,
    CardOtObjectivePediatrics25Disabled: false,
    CardOtObjectivePediatrics26: false,
    CardOtObjectivePediatrics26Disabled: false,
    CardOtObjectivePediatrics27: false,
    CardOtObjectivePediatrics27Disabled: false,
    CardOtObjectivePediatrics29: false,
    CardOtObjectivePediatrics29Disabled: false,
    CardOtObjectivePediatrics36: "",
    CardOtObjectivePediatrics36Disabled: false,
    CardOtObjectivePediatrics37: false,
    CardOtObjectivePediatrics37Disabled: false,
    CardOtObjectivePediatrics41: false,
    CardOtObjectivePediatrics41Disabled: false,
    CardOtObjectivePediatrics44: false,
    CardOtObjectivePediatrics44Disabled: false,
    CtCommunication: "",
    CtCommunicationDisabled: false,
    CtCommunicationOptions: [{key: 0, value: "A", text: "A"}],
    CtCoOperation: "",
    CtCoOperationDisabled: false,
    CtCoOperationOptions: [{key: 0, value: "A", text: "A"}],
    CtLevelOfArousal: "",
    CtLevelOfArousalDisabled: false,
    CtLevelOfArousalOptions: [{key: 0, value: "A", text: "A"}],
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.CardOtObjectivePediatrics1}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics2}>
                <Input
                    value={props.viewData.CardOtObjectivePediatrics2}
                    disabled={ props.viewData.CardOtObjectivePediatrics2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePediatrics',
                            name: 'CardOtObjectivePediatrics2',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePediatrics3}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Objective</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics4}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics4}
                    disabled={ props.viewData.CardOtObjectivePediatrics4Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics4"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics6}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics6}
                    disabled={ props.viewData.CardOtObjectivePediatrics6Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics6"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics7}
                    disabled={ props.viewData.CardOtObjectivePediatrics7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics8}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics9}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics9}
                    disabled={ props.viewData.CardOtObjectivePediatrics9Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics9"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics10}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Wheelchair</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics11}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics11}
                    disabled={ props.viewData.CardOtObjectivePediatrics11Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics11"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics12}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Stroller</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics13}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics13}
                    disabled={ props.viewData.CardOtObjectivePediatrics13Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics13"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cane/walker</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics15}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics15}
                    disabled={ props.viewData.CardOtObjectivePediatrics15Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics15"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Arm sling</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics18}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics18}
                    disabled={ props.viewData.CardOtObjectivePediatrics18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On Ventilator</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ET</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>TT tube</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics25}
                    disabled={ props.viewData.CardOtObjectivePediatrics25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics26}
                    disabled={ props.viewData.CardOtObjectivePediatrics26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics27}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics27}
                    disabled={ props.viewData.CardOtObjectivePediatrics27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics27"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics29}
                    disabled={ props.viewData.CardOtObjectivePediatrics29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On Foley's catheter</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics34}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On IV fluid</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics36}>
                <Input
                    value={props.viewData.CardOtObjectivePediatrics36}
                    disabled={ props.viewData.CardOtObjectivePediatrics36Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTObjectivePediatrics',
                            name: 'CardOtObjectivePediatrics36',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtObjectivePediatrics37}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics37}
                    disabled={ props.viewData.CardOtObjectivePediatrics37Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics37"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ICD drainage</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics41}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics41}
                    disabled={ props.viewData.CardOtObjectivePediatrics41Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics41"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics43}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>NG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics44}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtObjectivePediatrics44}
                    disabled={ props.viewData.CardOtObjectivePediatrics44Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CardOtObjectivePediatrics44"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics45}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>OG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics46}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>PEG</p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics47}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.CtCommunication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCommunication}
                    disabled={ props.viewData.CtCommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CtCommunication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCommunicationOptions}
                />
            </div>
            <div className={styles.CtCoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCoOperation}
                    disabled={ props.viewData.CtCoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CtCoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCoOperationOptions}
                />
            </div>
            <div className={styles.CtLevelOfArousal}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtLevelOfArousal}
                    disabled={ props.viewData.CtLevelOfArousalDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTObjectivePediatrics",
                                name: "CtLevelOfArousal",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtLevelOfArousalOptions}
                />
            </div>
            <div className={styles.CardOtObjectivePediatrics57}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics58}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtObjectivePediatrics59}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Level of arousal</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardOTObjectivePediatrics',
                            name: 'SaveRequest'
                        }
                    })}}
                    style={{color: "#FFFFFF",backgroundColor: "#27AE60", height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
