import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  SyntheticEvent,
  ChangeEvent,
} from "react";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Icon,
  Input,
  Radio,
  Segment,
  InputOnChangeData,
} from "semantic-ui-react";

import "./CardPTObjectiveOrthoLOW.css"

// MuscleStrangth
interface CardPTObjectiveOrthoLOWMuscleStrangthDataType {
  isNormal: boolean;
  normalTxt: string;
  isGrade: boolean;
  gradeTxt: string;
}

// RangeOfMotion
interface CardPTObjectiveOrthoLOWRangeOfMotionDataType {
  isLimited: boolean;
  limitedTxt: string;
}

const CardPTObjectiveOrthoLOWMuscleStrangthDefaultData: CardPTObjectiveOrthoLOWMuscleStrangthDataType = {
  isNormal: false,
  normalTxt: "",
  isGrade: false,
  gradeTxt: "",
}

const CardPTObjectiveOrthoLOWRangeOfMotionDefaultData: CardPTObjectiveOrthoLOWRangeOfMotionDataType = {
  isLimited: false,
  limitedTxt: ""
}


export interface CardPTObjectiveOrthoLOWViewDataType {
  viewData: { 
    muscle: CardPTObjectiveOrthoLOWMuscleStrangthDataType[]; 
    rangeOfMotion: CardPTObjectiveOrthoLOWRangeOfMotionDataType[];
  },
  SaveRequestDisabled: boolean;
}

export const CardPTObjectiveOrthoLOWInitialViewData: CardPTObjectiveOrthoLOWViewDataType = {
  viewData: {
    muscle: [Object.create(CardPTObjectiveOrthoLOWMuscleStrangthDefaultData)],
    rangeOfMotion: [Object.create(CardPTObjectiveOrthoLOWRangeOfMotionDefaultData)],
  },
  SaveRequestDisabled: false,
};


interface MuscleStrangthProps {
  data: CardPTObjectiveOrthoLOWMuscleStrangthDataType[];
  readonly?: boolean;
  add(event: SyntheticEvent, data: object): void;
  delete(index: number):(event: SyntheticEvent, data: object)=> void;
  onClick(index: number, name: string):(event: SyntheticEvent, data: object)=>void;
  onTextChange(index:number, name:string):(event: ChangeEvent, data: InputOnChangeData)=>void;
  label: string;
}

interface RangeOfMotionProps {
  data: Array<CardPTObjectiveOrthoLOWRangeOfMotionDataType>;
  readonly?: boolean;
  add(event: SyntheticEvent, data: object):void;
  delete(index: number):(event: SyntheticEvent, data: object)=>void;
  onClick(index: number, name: string):(event: SyntheticEvent, data: object)=>void;
  onTextChange(index:number, name:string):(event: ChangeEvent, data: InputOnChangeData)=>void;
  label: string;
}

interface CardPTObjectiveOrthoLOWProps {
  ref: any;
  viewData: CardPTObjectiveOrthoLOWViewDataType;
  onEvent: (arg0: any) => void;
  readonly: boolean;
}

const MuscleStrangth:FC<MuscleStrangthProps> = (props) => {

  return (
    <>
      {props.data.map((item, index) => {
        return (
          <Form.Group key={index} inline>
            <Form.Field width={2}>
              <label>{index === 0 ? props.label : ""}</label>
            </Form.Field>
            <Form.Field width={1}>
              { index === 0 ?
                <Checkbox 
                  label="Normal" 
                  readOnly={props.readonly}
                  checked={props.data[index].isNormal}
                  onChange={props.onClick(index,"Normal")}
                /> : null
              }
            </Form.Field>
            <Form.Field width={4}>
              <Input 
                readOnly={props.readonly}
                value={props.data[index].normalTxt}
                onChange={props.onTextChange(index,"Normal")}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                label="Grade" 
                readOnly={props.readonly}
                checked={props.data[index].isGrade}
                onChange={props.onClick(index,"Grade")}/>
            </Form.Field>
            <Form.Field width={4}>
              <Input
                readOnly={props.readonly} 
                value={props.data[index].gradeTxt}
                onChange={props.onTextChange(index,"Grade")} 
              />
            </Form.Field>
            { props.data.length === index + 1 ? (
              <Button icon color="green" disabled={props.readonly}
                onClick={props.add}>
                <Icon name="plus" />
              </Button>) : null 
            }
            {
              index === 0 ? null : (
                <Button icon color="red" disabled={props.readonly}
                onClick={props.delete(index)}>
                  <Icon name="close" />
                </Button>
              )
            }
          </Form.Group>
        );
      })}
    </>
  );
};


const RangeOfMotion: FC<RangeOfMotionProps> = (props) => {
  return (
    <> 
    {props.data.map((item, index) => {
      return (
        <Form.Group key={index} inline>
            <Form.Field width={3}>
              <label>{index === 0 ? props.label : "" }</label>
            </Form.Field>
            <Form.Field width={1}>
              { index === 0 ? 
                <Checkbox 
                  label="Limit" 
                  readOnly={props.readonly}
                  checked={props.data[index].isLimited} 
                  onChange={props.onClick(index,"Limit")}
                /> : null 
              }
            </Form.Field>
            <Form.Field width={8}>
              <Input 
                value={props.data[index].limitedTxt} 
                readOnly={props.readonly}
                onChange={props.onTextChange(index,"Limit")} />
            </Form.Field>
            { props.data.length === index + 1 ? (
              <Button icon color="green" onClick={props.add} disabled={props.readonly}>
                <Icon name="plus" />
              </Button>) : null 
            }
            {
              index === 0 ? null : (
                <Button icon color="red" 
                  onClick={props.delete(index)} disabled={props.readonly}>
                  <Icon name="close" />
                </Button>
              )
            }
          </Form.Group>
      )
    })}
    </>
  )
}

const CardPTObjectiveOrthoLOW: FC<CardPTObjectiveOrthoLOWProps> = forwardRef((props, ref) => {

  const [muscle, setMuscle] = useState<CardPTObjectiveOrthoLOWMuscleStrangthDataType[]>([]);
  const [rangeOfMotion, setRangeOfMotion] = useState<CardPTObjectiveOrthoLOWRangeOfMotionDataType[]>([]);


  useImperativeHandle(ref, () => ({
    getData: () => {
      return {muscle, rangeOfMotion};
    },
  }));

  useEffect( () => {
    setMuscle(props.viewData.viewData.muscle)
    setRangeOfMotion(props.viewData.viewData.rangeOfMotion)
  },[props.viewData])

  const handleAddMuscle = () => {
    if (!props.readonly) {
      let m = muscle
      let newMuscle = Object.create(CardPTObjectiveOrthoLOWMuscleStrangthDefaultData)
      m.push(newMuscle)
      setMuscle(Object.assign([], m))
    }
  }

  const handleDeleteMuscle = (index: number) => () => {
    if (!props.readonly) {
      let m = muscle 
      m.splice(index,1)
      setMuscle(Object.assign([], m))
    }
  }

  const handleAddRange = () => {
    if (!props.readonly) {
      let r = rangeOfMotion 
      let newRangeOfMotion = Object.create(CardPTObjectiveOrthoLOWRangeOfMotionDefaultData)
      r.push(newRangeOfMotion)
      setRangeOfMotion(Object.assign([], r))
    }
  }

  const handleDeleteRange = (index: number) => () => {
    if (!props.readonly) {
      let r = rangeOfMotion 
      r.splice(index,1)
      setRangeOfMotion(Object.assign([], r))
    }
  }


  const handleOnClick = (idx: number, name: string) => () => {
    switch (name) {
      case "Normal": 
        let mn = muscle
        mn[idx].isNormal = !mn[idx].isNormal
        console.log('mn', mn)
        setMuscle(Object.assign([], mn))
        break;
      case "Grade": 
        let mg = muscle
        mg[idx].isGrade = !mg[idx].isGrade
        console.log('mg', mg)
        setMuscle(Object.assign([], mg))
        break;
      case "Limit":
        let r = rangeOfMotion 
        r[idx].isLimited = !r[idx].isLimited
        setRangeOfMotion(Object.assign([], r))
        break;
      default:
        break;
    }
    return 
  }

  const handleOnTextChange = (idx: number, name: string) => (event: ChangeEvent, data: InputOnChangeData) => {
    switch (name) {
      case "Normal": 
        let mn = muscle
        mn[idx].normalTxt = data.value
        console.log('mn', mn)
        setMuscle(Object.assign([], mn))
        break;
      case "Grade": 
        let mg = muscle
        mg[idx].gradeTxt = data.value
        console.log('mg', mg)
        setMuscle(Object.assign([], mg))
        break;
      case "Limit":
        let r = rangeOfMotion 
        r[idx].limitedTxt = data.value
        setRangeOfMotion(Object.assign([], r))
        break;
      default:
        break;
    }
    return 
  }
  
  console.log(props.readonly)
  return (
    <div className="pt-objective-ortho-low">
      <Segment className="dynamic-segment" style={{ marginRight: '10px', marginLeft: '15px' }}>
        <Form>
          <MuscleStrangth
            label={"Muscle strength"} 
            data={muscle} 
            readonly={props.readonly}
            add={handleAddMuscle} 
            delete={handleDeleteMuscle}
            onClick={handleOnClick}
            onTextChange={handleOnTextChange}
          />
          <RangeOfMotion 
            label={"Range of motion"} 
            data={rangeOfMotion} 
            add={handleAddRange}
            readonly={props.readonly}
            delete={handleDeleteRange}
            onClick={handleOnClick}
            onTextChange={handleOnTextChange}
          />
          <div style={{display: "flex", justifyContent: "flex-end"}}> 
            <Button 
              color='green' 
              disabled={ props.SaveRequestDisabled }
              onClick={(e) => { props.onEvent({
                  message: 'clickButton',
                  params: {
                      view: 'CardPTObjectiveOrthoLOW',
                      name: 'SaveRequest'
                  }
              })}}
            > Save </Button>
          </div>
        </Form>
      </Segment>
    </div>
  );
});

export default CardPTObjectiveOrthoLOW;
