import WasmController from "react-lib/frameworks/WasmController";

// APIs
// IME
import ImagingOrderDetail from "issara-sdk/apis/ImagingOrderDetail_apps_IME";
import ImagingOrderResultReportDetail from "issara-sdk/apis/ImagingOrderResultReportDetail_apps_IME";
import ImagingOrderResultReportList from "issara-sdk/apis/ImagingOrderResultReportList_apps_IME";
import ImagingQueueList from "issara-sdk/apis/ImagingQueueList_apps_IME";
// CORE
import EncounterMedicalRecordList from "issara-sdk/apis/EncounterMedicalRecordList_core";
// DPO
import DiagnosisMedicalRecordDetail from "issara-sdk/apis/DiagnosisMedicalRecordDetail_apps_DPO";

// Serializer
import ImagingOrderResultSerializerI from "issara-sdk/types/ImagingOrderResultSerializer_apps_IME";

// Form
import getPdfMake from "react-lib/appcon/common/pdfMake";

import FormRadiologyReport from "../pdf/FormRadiologyReport";

// Interface
import { GetIcd10KeyUp } from "../../ADM/sequence/ReportPatientList";
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { SetErrorMessage, SetProperty } from "../../common/CommonInterface";

import {
  CreateUpdateImagingResult,
  GetAuditLogList,
  GetClinicalFindingList,
  GetOrderItemImageList,
  GetOrgan,
  HandleModXrayDetail,
  ImagingItemSerializer,
  ImagingOrderItemActionLogSerializer,
  ImagingOrderSerializer,
  ImagingQueueSerializer,
  ModXrayDetailType,
  OpenPacsViewer,
  ORDER_ITEM_STATUS_LABEL,
  OrderStatusType,
  PacsGalleryDetailType,
  ReportActionStatusType,
} from "./ImagingHandler";

import CONFIG from "config/config";

export type State = Partial<{
  // sequence
  ImagingResultSequence: Partial<{
    sequenceIndex: "Action" | "Start" | null;
    auditLogList: ImagingOrderItemActionLogSerializer[];
    imagingItemDetail: Partial<ImagingOrderSerializer> | null;
    modXrayDetail: ({ index: number } & ImagingItemSerializer) | null;
    orderList: ImagingQueueSerializer[];
    pacsGalleryDetail: PacsGalleryDetailType | null;
    principalDiagnosis: Record<string, any> | null;
    readOnly?: boolean;
    reportDetail: Partial<ImagingOrderResultSerializer>;
    selectedOrder: ImagingQueueSerializer | null;
  }> | null;
  // CommonInterface
  imagingResultEditData?: ({ readOnly?: boolean } & ImagingQueueSerializer) | null;
}>;

type PickedState = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "masterOptions"
    | "searchedItemListWithKey"
    | "selectedEncounter"
    | "selectedPatient"
  >
>;

export type PickedProps = Partial<Omit<PickedState, "masterOptions">>;

export type ImagingOrderResultSerializer = {
  edit_reason?: string;
  order_item_status: OrderStatusType;
  password?: string;
  username?: string;
} & Omit<ImagingOrderResultSerializerI, "order_item_status">;

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[] | undefined>;

type OptionType = {
  key: number | string;
  text: string;
  value: number | string;
};

// Sequence
type SeqState = {
  sequence: "ImagingResult";
  clear?: boolean;
  encounterId?: number | null;
  orderStatus?: OrderStatusType;
  restart?: boolean;
};

// Handle Action
type ActionType =
  | {
      action: "EDIT";
      actionType: ReportActionStatusType;
      card: string;
    }
  | {
      action: "GET_AUDIT_LOG";
      orderItemId: number;
    }
  | {
      action: "MOD_XRAY";
      data?: ModXrayDetailType;
      type: "CLOSE" | "OPEN";
    }
  | {
      action: "PRINT_REPORT";
      card: string;
      patient?: Record<string, any>;
    }
  | {
      action: "SEARCH";
      encounterId?: number | null;
      orderStatus?: OrderStatusType;
    }
  | { action: "CLOSE_ORDER" }
  | { action: "OPEN_PACS"; card: string; data: ImagingQueueSerializer }
  | { action: "SELECT_ORDER"; data: ImagingQueueSerializer };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => any;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends T ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  ImagingResultSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  device?: number;
  division?: number;
  userProfile?: Record<string, any>;
};

export const DataInitial = {};

const Masters = [
  ["division", {}],
  ["eligibilityType", {}],
  ["resultSummary", {}],
] as const;

export const CARD_IMAGING_RESULT = "CardImagingResult";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_IMAGING_RESULT}_${T[K] & string}`;
};

export const ACTIONS = {
  APPROVE: "APPROVE",
  CLOSE_ORDER: "CLOSE_ORDER",
  EDIT: "EDIT",
  GET_AUDIT_LOG: "GET_AUDIT_LOG",
  MOD_XRAY: "MOD_XRAY",
  OPEN_PACS: "OPEN_PACS",
  PRINT_REPORT: "PRINT_REPORT",
  REPORT: "REPORT",
  SEARCH: "SEARCH",
  SELECT_ORDER: "SELECT_ORDER",
  UNAPPROVE: "UNAPPROVE",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${CARD_IMAGING_RESULT}_${key}`])
) as Optional<typeof ACTIONS>;

export const LABEL_STATUS = {
  APPROVE: "Approved ",
  EDIT: "Edited",
  EDIT_REPORT: "Edited result",
  EXECUTE: "Executed",
  ORDER: "Requested",
  REGISTER: "Registered",
  REPORT: "Reported",
};

export const DIAGNOSIS_SEARCH_ID = "Diagnosis_IR";

type Handler<P = unknown, R = void> = (
  controller: WasmController<PickedState & State, Event, Data>,
  ...params: unknown extends P ? [params?: P] : [params: P]
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: Masters,
    },
  });

  GetClinicalFindingList(controller, {});

  GetOrgan(controller, {});

  controller.setState(
    {
      ImagingResultSequence: {
        ...state.ImagingResultSequence,
        sequenceIndex: "Action",
      },
    },
    () => {
      Action(controller, { ...params, action: "SEARCH" });
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    CLOSE_ORDER: HandleCloseOrder,
    EDIT: HandleEditReport,
    GET_AUDIT_LOG: HandleGetAuditLog,
    MOD_XRAY: HandleModDetail,
    OPEN_PACS: HandleOpenPacs,
    PRINT_REPORT: HandlePrintReport,
    SEARCH: HandleSearch,
    SELECT_ORDER: HandleSelectOrder,
  };

  const { action } = params;

  actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  const orderStatus = Object.fromEntries(
    Object.entries(ORDER_ITEM_STATUS_LABEL).map(([k, v]) => [v, k])
  );

  const [result] = await ImagingQueueList.list({
    apiToken: controller.apiToken,
    params: {
      encounter: state.selectedEncounter?.id || params.encounterId,
      exclude_cancel: true,
      status: orderStatus[params.orderStatus || ""],
    },
  });

  state = controller.getState();

  // เมื่อกด ปุ่ม report entry จากหน้า imaging worklist
  if (state.imagingResultEditData) {
    Action(controller, {
      action: "SELECT_ORDER",
      data: state.imagingResultEditData,
    });
  }

  controller.setState({
    ImagingResultSequence: {
      ...state.ImagingResultSequence,
      orderList: result?.items || [],
      readOnly: state.imagingResultEditData?.readOnly,
      sequenceIndex: "Action",
    },
    imagingResultEditData: null,
  });
};

const HandleSelectOrder: Handler<Params<"SELECT_ORDER">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SELECT_ORDER]: "LOADING" },
  });

  const [[result], [detail]] = await Promise.all([
    ImagingOrderResultReportList.list({
      apiToken: controller.apiToken,
      params: {
        order_item: params.data.id,
      },
    }),
    ImagingOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.data.order,
    }),
  ]);

  const [medicalRecord] = await EncounterMedicalRecordList.list({
    apiToken: controller.apiToken,
    pk: detail.encounter,
  });

  const medicalRecordId = medicalRecord?.items?.[0]?.id;

  const [[diagnosis], [icd10KeyUp]] = await Promise.all([
    DiagnosisMedicalRecordDetail.retrieve({
      apiToken: controller.apiToken,
      pk: medicalRecordId,
    }),
    GetIcd10KeyUp(controller, {
      method: "icdcode",
      search: detail.suspected_diagnosis_code,
    }),
  ]);

  state = controller.getState();

  const items: any[] = result?.items || [];
  const icd10 = icd10KeyUp?.response?.[0];

  controller.setState({
    ImagingResultSequence: {
      ...state.ImagingResultSequence,
      imagingItemDetail: detail || {},
      principalDiagnosis: diagnosis?.principal_diagnosis?.[0] || {},
      reportDetail: items[0]
        ? { ...items[0] }
        : {
            order_item: params.data.id,
            result_summary: "",
          },
      selectedOrder: params.data,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SELECT_ORDER]: null },
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [DIAGNOSIS_SEARCH_ID]: icd10
        ? [
            {
              ...icd10,
              id: icd10.icd10_id,
              name: `[${icd10.icdcode}] ${icd10.icdterm}`,
            },
          ]
        : [],
    },
  });
};

const HandleEditReport: Handler<Params<"EDIT">> = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${BTN_ACTS.EDIT}_${params.actionType}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const imaging = state.ImagingResultSequence || {};
  const detail = imaging.reportDetail || {};

  const { userId } = await controller.handleEvent({
    message: "CheckUserCredentials",
    params: { ...detail, btnKey, cardKey: params.card },
  });

  if (!userId) {
    return;
  }

  const result = await CreateUpdateImagingResult(controller, {
    actionType: params.actionType,
    data: { ...detail, username: controller.data.userProfile?.username },
  });

  if (detail.id && result[0]) {
    await UpdateImagingOrderResultReport(controller, {
      data: detail,
      result: result[0],
    });
  }

  if (result[1]) {
    SetErrorMessage(controller, {
      ...params,
      btnAction: `${params.action}_${params.actionType}`,
      error: result[1],
    });

    return;
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${BTN_ACTS.EDIT}_${params.actionType}`]: "SUCCESS",
    },
  });

  Action(controller, { action: "SEARCH" });

  if (imaging.selectedOrder) {
    Action(controller, {
      action: "SELECT_ORDER",
      data: {
        ...imaging.selectedOrder,
        status: result[0]?.order_item_status || "",
      },
    });
  }
};

const HandleOpenPacs: Handler<Params<"OPEN_PACS">> = async (controller, params) => {
  // #const state = controller.getState();

  OpenPacsViewer(controller, params);
  // #controller.setState({
  //   ImagingResultSequence: {
  //     ...state.ImagingResultSequence,
  //     pacsGalleryDetail: detail,
  //   },
  // });
};

const HandleCloseOrder: Handler<Params<"CLOSE_ORDER">> = (controller) => {
  const state = controller.getState();

  controller.setState({
    ImagingResultSequence: {
      ...state.ImagingResultSequence,
      imagingItemDetail: null,
      principalDiagnosis: null,
      reportDetail: {},
      selectedOrder: null,
    },
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [DIAGNOSIS_SEARCH_ID]: [],
    },
  });
};

const HandleModDetail: Handler<Params<"MOD_XRAY">> = async (controller, params) => {
  HandleModXrayDetail(controller, params);
};

const HandleGetAuditLog: Handler<Params<"GET_AUDIT_LOG">> = async (controller, params) => {
  const list = await GetAuditLogList(controller, params);

  const state = controller.getState();

  controller.setState({
    ImagingResultSequence: {
      ...state.ImagingResultSequence,
      auditLogList: list,
    },
  });
};

const HandlePrintReport: Handler<Params<"PRINT_REPORT">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT_REPORT]: "LOADING" },
  });

  const seq = state.ImagingResultSequence;

  const actionLogs = await GetAuditLogList(controller, { orderItemId: seq?.selectedOrder?.id });

  const divisionOptions: any[] = state.masterOptions?.division || [];

  const divisionName =
    divisionOptions.find(
      (item) => item.value === state.ImagingResultSequence?.imagingItemDetail?.order_div
    )?.text || "";

  const docDef = await FormRadiologyReport({
    actionLogs,
    data: seq?.selectedOrder,
    divisionName,
    patient: params.patient || state.selectedPatient,
    report: seq?.reportDetail,
  });

  const pdfMake = await getPdfMake(true);
  const pdf = pdfMake.createPdf(docDef);

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT_REPORT]: "SUCCESS" },
  });

  pdf.open();
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */

const UpdateImagingOrderResultReport: Handler<
  {
    data: Partial<ImagingOrderResultSerializer>;
    result: any;
  },
  Promise<void>
> = async (controller, params) => {
  await ImagingOrderResultReportDetail.update({
    apiToken: controller.apiToken,
    data: {
      ...params.result,
      action: "EDIT_REPORT",
      edit_reason: params.data.edit_reason,
      password: params.data.password,
      username: controller.data.userProfile?.username,
    } as any,
    pk: params.result.id,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });
};
