import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  FormGroup,
  FormField,
  Dropdown,
  TextArea,
  Button,
  Input
} from 'semantic-ui-react'

const ModXrayDetailUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          id="ModXrayDetail-Div-XrayDetailHeader"
          style={{backgroundColor: "var(--primary-theme-color,#5DBCD2)",margin: "-0.75rem -0.75rem -1rem", padding: "0.25rem 1rem", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          
          <div
            style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0", color: "white", lineHeight: 1.25}}>
            
            <div>
              {props.detail?.group_name || "-"}
            </div>
            <div>
              {props.detail?.name_code || "-"}
            </div>
          </div>
          <div
            style={{cursor: "pointer", color: "white", marginTop: "2.5px"}}>
            
            <Icon
              className="white large"
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          style={{padding: "1rem"}}>
          
          <div
            className="ui form">
            
            <FormGroup
              className={props.readOnly ? "--override-disabled" : ""}
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{width: "100px"}}>
                  Eligibility
                </label>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <Dropdown
                  disabled={props.readOnly || false}
                  fluid={true}
                  id="ModXrayDetail-Dropdown-Eligibility"
                  name="eligibility_type"
                  onChange={props.onChangeValue}
                  options={props.eligibilityOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%", opacity: 1}}
                  value={props.detail?.eligibility_type|| ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "max-content", width: "100px", textAlign: "right"}}>
                  Urgency
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{padding: 0}}
                width={6}>
                <Dropdown
                  disabled={props.readOnly || false}
                  fluid={true}
                  id="ModXrayDetail-Dropdown-Urgency"
                  name="urgency"
                  onChange={props.onChangeValue}
                  options={props.urgencyOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%", opacity: 1}}
                  value={props.detail?.urgency|| ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              className={props.readOnly ? "--override-disabled" : ""}>
              <FormField
                inline={true}>
                <label
                  style={{width: "100px"}}>
                  Note
                </label>
              </FormField>
              <FormField
                inline={true}
                width={16}>
                <TextArea
                  disabled={props.readOnly || false}
                  id="ModXrayDetail-TextArea-Note"
                  name="note"
                  onChange={props.onChangeValue}
                  rows={4}
                  style={{opacity: 1}}
                  value={props.detail?.note || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              style={{display: props.hideDiagram?"none":""}}>
              <FormField
                inline={true}>
                <div
                  style={{width: "100px"}}>
                  
                  <div
                    style={{marginTop: "1.25rem", fontWeight: "bold"}}>
                    Dental diagram
                  </div>
                  <div>
                    
                    <Button
                      id="ModXrayDetail-Button-DeciduousTeeth"
                      onClick={props.onClickDeciduousTeeth}
                      style={{marginTop: "1rem",border: "0.5px solid #5DBCD2", backgroundColor: props.hideBabyTooth?"#5DBCD2":"#DCF4FA", display: "flex", padding: "0.5rem",}}>
                      
                      <div
                        style={{color: props.hideBabyTooth?"white":"#5DBCD2", fontSize: "1.2rem"}}>
                        
                        <Icon
                          name={props.hideBabyTooth ? "eye slash" : "eye"}>
                        </Icon>
                      </div>
                      <label
                        style={{fontWeight: "bold", color: props.hideBabyTooth? "white":"rgba(0,0,0,0.87)",fontSize: "0.8rem"}}>
                        Deciduous teeth
                      </label>
                    </Button>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={16}>
                <div>
                  {props.DentalDiagram}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              style={{display: props.hideLocation?"none":""}}>
              <FormField
                inline={true}>
                <label
                  style={{width: "100px"}}>
                  Location
                </label>
              </FormField>
              <FormField
                className="--override-disabled"
                inline={true}
                width={16}>
                <TextArea
                  disabled={true}
                  id="ModXrayDetail-TextArea-Location"
                  rows={3}
                  style={{opacity: 1}}
                  value={props.locationsName || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}
              style={{margin: 0}}>
              <FormField>
                <div>
                  {props.ButtonReadyToView}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{position: "relative"}}
                width={16}>
                <Input
                  disabled={!props.allowReadyToView}
                  id="ModXrayDetail-Input-XrayLink"
                  onChange={props.onChangeImageUrl}
                  style={{margin:0, opacity:1, display: props.hideImageUrl?"none":""}}
                  value={props.detail?.image_url || ""}>
                </Input>
                <label
                  style={{fontWeight: "normal", minWidth: "max-content", position: "absolute", bottom: "-1.05rem", fontSize: "0.75rem", color: "red", display:props.detail?.ready_to_view && !props.detail?.image_url && props.allowReadyToView && !props.hideImageUrl ?"":"none"}}>
                  กรุณาแนบ Link ผลภาพถ่ายทางรังสี
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{display: props.readOnly && !props.allowReadyToView ? "none" : ""}}>
                  {props.ButtonSave}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}>
                <Button
                  basic={true}
                  color="red"
                  id="ModXrayDetail-Button-Cancel"
                  onClick={props.onCancel}>
                  CANCEL
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}

ModXrayDetailUX.displayName = "ModXrayDetailUX";
export default React.memo(ModXrayDetailUX)

export const screenPropsDefault = {}

/* Date Time : Thu Nov 14 2024 12:44:29 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModXrayDetail-Div-XrayDetailHeader"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"var(--primary-theme-color,#5DBCD2)\",margin: \"-0.75rem -0.75rem -1rem\", padding: \"0.25rem 1rem\", display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\", color: \"white\", lineHeight: 1.25}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", color: \"white\", marginTop: \"2.5px\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Icon",
      "parent": 122,
      "props": {
        "className": {
          "type": "value",
          "value": "white large"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.group_name || \"-\""
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.name_code || \"-\""
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1rem\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormGroup",
      "parent": 142,
      "props": {
        "className": {
          "type": "code",
          "value": "props.readOnly ? \"--override-disabled\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormGroup",
      "parent": 142,
      "props": {
        "className": {
          "type": "code",
          "value": "props.readOnly ? \"--override-disabled\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "Eligibility"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100px\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "label",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "Urgency"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", width: \"100px\", textAlign: \"right\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "Dropdown",
      "parent": 146,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModXrayDetail-Dropdown-Eligibility"
        },
        "name": {
          "type": "value",
          "value": "eligibility_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.eligibilityOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.eligibility_type|| \"\""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Dropdown",
      "parent": 148,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModXrayDetail-Dropdown-Urgency"
        },
        "name": {
          "type": "value",
          "value": "urgency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.urgencyOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.urgency|| \"\""
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 144,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 144,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "Note"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100px\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "TextArea",
      "parent": 154,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "id": {
          "type": "value",
          "value": "ModXrayDetail-TextArea-Note"
        },
        "name": {
          "type": "value",
          "value": "note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "4"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.note || \"\""
        }
      },
      "seq": 156,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormGroup",
      "parent": 142,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.hideDiagram?\"none\":\"\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 157,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 157,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormGroup",
      "parent": 142,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.hideLocation?\"none\":\"\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 161,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 161,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "label",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100px\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "TextArea",
      "parent": 163,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModXrayDetail-TextArea-Location"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.locationsName || \"\""
        }
      },
      "seq": 165,
      "void": true
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "FormGroup",
      "parent": 166,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Button",
      "parent": 175,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "id": {
          "type": "value",
          "value": "ModXrayDetail-Button-Cancel"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 172,
      "props": {
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonReadyToView"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DentalDiagram"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100px\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": "Dental diagram"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.25rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "Button",
      "parent": 183,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModXrayDetail-Button-DeciduousTeeth"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDeciduousTeeth"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\",border: \"0.5px solid #5DBCD2\", backgroundColor: props.hideBabyTooth?\"#5DBCD2\":\"#DCF4FA\", display: \"flex\", padding: \"0.5rem\",}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "label",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": "Deciduous teeth"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", color: props.hideBabyTooth? \"white\":\"rgba(0,0,0,0.87)\",fontSize: \"0.8rem\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{color: props.hideBabyTooth?\"white\":\"#5DBCD2\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "Icon",
      "parent": 187,
      "props": {
        "name": {
          "type": "code",
          "value": "props.hideBabyTooth ? \"eye slash\" : \"eye\""
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "Input",
      "parent": 173,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.allowReadyToView"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModXrayDetail-Input-XrayLink"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeImageUrl"
        },
        "style": {
          "type": "code",
          "value": "{margin:0, opacity:1, display: props.hideImageUrl?\"none\":\"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.image_url || \"\""
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "label",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาแนบ Link ผลภาพถ่ายทางรังสี"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"max-content\", position: \"absolute\", bottom: \"-1.05rem\", fontSize: \"0.75rem\", color: \"red\", display:props.detail?.ready_to_view && !props.detail?.image_url && props.allowReadyToView && !props.hideImageUrl ?\"\":\"none\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 174,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        },
        "style": {
          "type": "code",
          "value": "{display: props.readOnly && !props.allowReadyToView ? \"none\" : \"\"}"
        }
      },
      "seq": 191,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "ModXrayDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
