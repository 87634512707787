import moment from "moment";
import React, { useEffect, useState, useMemo, CSSProperties, SyntheticEvent } from "react";
import CardBillingDepositUX from "react-lib/apps/HISV3/BIL/CardBillingDepositUX";
import CardPaying from "react-lib/apps/HISV3/BIL/CardPaying";

import ModInfo from "react-lib/apps/common/ModInfo";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

import {
  Button,
  Dropdown,
  Form,
  FormField,
  FormGroup,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";

type CardBillingDepositProps = {
  // function
  onEvent?: any;
  setProp?: any;
  DJANGO?: any;
  runSequence?: any;
  patientInfo?: any;
  selectDeposit?: any;
  divisionList?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
  masterOptions?: Record<string, any[]>;

  // seq
  BillingDepositSequence?: any;
  BillingHistorySequence?: any;
  BillPaymentSequence?: any;
  bilReceiptCodeDetail?: any;
  invoiceItemByItems?: any;
  billingPatientLanguage?: any;
};

const CARD_BILLING_DEPOSIT = "CardBillingDeposit";

const AlignRightStyle = { textAlign: "right" } as CSSProperties;

const CardBillingDeposit = (props: CardBillingDepositProps) => {
  const intl = useIntl();
  const [walletOptions, setWalletOptions] = useState([]);
  const [walletFocus, setWalletFocus] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [options, setOptions] = useState<any[]>([
    {
      key: 1,
      value: "ผู้ป่วยต้องการยกเลิก",
      text: "ผู้ป่วยต้องการยกเลิก",
    },
  ]);

  const [openModSuccess, setOpenModSuccess] = useState(false);

  useEffect(() => {
    props.onEvent({ message: "GetPatientLanguage" });

    props.runSequence({
      sequence: "BillingDeposit",
      restart: true,
    });
  }, []);

  useEffect(() => {
    let optionsData: any = [];
    if (props.BillingDepositSequence?.billDepositDetail?.walletType) {
      props.BillingDepositSequence?.billDepositDetail?.walletType.map((item: any) => {
        optionsData.push({
          key: item.id,
          value: item.id,
          text: `[${item.code}] ${item.name}`,
        });
      });
    }

    setWalletOptions(optionsData);
  }, [props.BillingDepositSequence?.billDepositDetail?.walletType]);

  const citizenDetail = useMemo(() => {
    const patient = props.patientInfo;
    let text = "";
    let patientId = "";
    if (patient?.citizen_type == "T") {
      text = intl.formatMessage({ id: "หมายเลขบัตรประชาชน" }) + patient?.citizen_no;
      patientId = patient?.citizen_no;
    } else if (patient?.citizen_type == "F") {
      text = intl.formatMessage({ id: "หมายเลขพาสปอร์ต" }) + patient?.passport_no;
      text += patient?.reimburse_foreign_code && `[${patient?.reimburse_foreign_code}]`;
      patientId = patient?.reimburse_foreign_code;
    } else if (patient?.citizen_type == "A") {
      text = intl.formatMessage({ id: "ใบต่างด้าว" }) + patient?.alien_no;
      text += patient?.reimburse_foreign_code && `[${patient?.reimburse_foreign_code}]`;
      patientId = patient?.reimburse_foreign_code;
    }

    return {
      text,
      patientId,
    };
  }, [props.patientInfo]);

  // set form comma
  const formatComma = (val: any) =>
    !isNaN(parseFloat(val))
      ? parseFloat(val)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
      : val;

  const onChangeData = (_event: any, data: any) => {
    props.setProp(`BillingDepositSequence.billDepositDetail.${data.name}`, data?.value);
  };

  const onChangeDate = (date: any, name: any) => {
    props.setProp(`BillingDepositSequence.billDepositHistory.${name}`, date);
  };

  const onChangeHistoryData = (_event: any, data: any) => {
    props.setProp(`BillingDepositSequence.billDepositHistory.${data.name}`, data?.value);
  };

  const onAddDeposit = () => {
    let data = {
      ...props.BillingDepositSequence?.billDepositDetail,
      selectDeposit: {
        ...props.BillingDepositSequence?.billDepositDetail?.selectDeposit,
        init_balance: formatComma(
          props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.walletValue
        ),
        add_patient_paid: formatComma(
          props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.walletValue
        ),
        payment: "0.00",
        balance: "0.00",
        code: null,
        receipt_no: null,
      },
      sumWallet: {
        ...props.BillingDepositSequence?.billDepositDetail?.sumWallet,
        sumTotal: replacesumWallet(
          props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.deposit || "0"
        ),
        sumPayable: replacesumWallet(
          // props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.patient_paid || "0"
          "0.00"
        ),
      },
    };

    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillingDeposit",
        action: "onAddDeposit",
        card: CARD_BILLING_DEPOSIT,
        data: data,
        walletValue: props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.walletValue,
      },
    });
  };

  const formatDeposit = (data: any) => {
    return formatComma(Number(data.replaceAll(",", "")));
  };
  const memoDepositItems = useMemo(() => {
    return ([props.BillingDepositSequence?.billDepositDetail?.selectDeposit] || []).map(
      (item: any) => ({
        ...item,
        initBalanceFormat: (
          <div style={AlignRightStyle}>{formatDeposit(item?.init_balance || "0.00")}</div>
        ),
        patientPaidFormat: (
          <div style={AlignRightStyle}>
            {formatDeposit(
              item?.add_patient_paid
                ? item?.add_patient_paid || "0.00"
                : item?.deposit_receipt
                ? item?.patient_paid || "0.00"
                : "0.00"
            )}
          </div>
        ),
        paymentFormat: <div style={AlignRightStyle}>{formatDeposit(item?.payment || "0.00")}</div>,
        balanceFormat: <div style={AlignRightStyle}>{formatDeposit(item?.balance || "0.00")}</div>,
      })
    );
  }, [props.BillingDepositSequence?.billDepositDetail?.selectDeposit]);

  const memoDepositHistoryItems = useMemo(() => {
    return (props.BillingDepositSequence?.billDepositHistory?.receiptItems || []).map(
      (item: any) => ({
        ...item,
        no: <div style={{ textAlign: "center" }}>{item?.no}</div>,
        initBalanceFormat: (
          <div style={AlignRightStyle}>{formatDeposit(item?.init_balance || "0.00")}</div>
        ),
        paymentFormat: <div style={AlignRightStyle}>{formatDeposit(item?.payment || "0.00")}</div>,
        balanceFormat: <div style={AlignRightStyle}>{formatDeposit(item?.balance || "0.00")}</div>,
        buttonDetail: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="blue" size="small" onClick={() => selectDepositHistory(item)}>
              รายละเอียด
            </Button>
          </div>
        ),
      })
    );
  }, [props.BillingDepositSequence?.billDepositHistory?.receiptItems]);

  const selectDepositHistory = (items: any) => {
    let walletType = props.BillingDepositSequence.billDepositDetail.walletType;
    let walletId = (walletType || [])?.filter((item: any) => item.id === items?.wallet_type)?.[0];
    let data = {
      ...props.BillingDepositSequence.billDepositDetail.selectDeposit,
      ...items,
      walletValue: items?.init_balance,
      id: items?.wallet_type,
      mode_code: walletId?.id,
      mode_name: walletId?.name,
      depositHistoryId: items?.id,
    };

    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillingDeposit",
        action: "selectWallet",
        id: data.wallet_type,
        data: data,
      },
    });
  };

  const walletValueNumber = (e: any) => {
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== ".") {
      e.preventDefault();
    }
  };

  const onSearchDepositHistory = () => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "BillingDeposit", action: "getHistory", card: CARD_BILLING_DEPOSIT },
    });
  };

  const onSelectWallet = (_event: any, data: any) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillingDeposit",
        action: "selectWallet",
        id: data.value,
      },
    });
  };

  const onSaveWalletCancel = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillingDeposit",
        action: "walletCancel",
        card: CARD_BILLING_DEPOSIT,
        callback: () => {
          setOpenCancel(false);
          setOpenModSuccess(true);
        },
      },
    });
  };

  const onCloseWalletCancel = () => {
    props.setProp(`BillingDepositSequence.billDepositDetail.editDeposit`, {
      note: "",
      username: "",
      password: "",
    });
    setOpenCancel(false);
  };

  // #64849
  const replacesumWallet = (data: any) => {
    let replaceData = data?.replaceAll(",", "");
    let sumData =
      Number(replaceData) +
      Number(props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.walletValue);

    return formatComma(sumData) || "0";
  };

  const onCloseData = () => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "BillingDeposit", action: "onCloseData" },
    });
  };

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    setOptions([...options, { key: v.value, text: v.value, value: v.value }]);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.BillPaymentSequence?.payErrorMessage}
        success={null}
        onClose={() => {
          props.setProp("BillPaymentSequence.payErrorMessage", null);
        }}
        languageUX={props.languageUX}
      />

      <ModInfo
        type="error"
        titleName={intl.formatMessage({ id: "ไม่สามารถยกเลิกใบเสร็จได้" })}
        open={props.errorMessage?.[`${CARD_BILLING_DEPOSIT}_walletCancel`]}
        alertText={
          <ErrorMessage error={props.errorMessage?.[`${CARD_BILLING_DEPOSIT}_walletCancel`]} />
        }
        onClose={() => {
          props.setProp(`errorMessage.${CARD_BILLING_DEPOSIT}_walletCancel`, null);
        }}
        onApprove={() => {
          props.setProp(`errorMessage.${CARD_BILLING_DEPOSIT}_walletCancel`, null);
        }}
      />

      <CardBillingDepositUX
        onSelectWallet={onSelectWallet}
        onChangeData={onChangeData}
        billDepositDetail={props.BillingDepositSequence?.billDepositDetail}
        selectDeposit={props.BillingDepositSequence?.billDepositDetail?.selectDeposit}
        sumWallet={props.BillingDepositSequence?.billDepositDetail?.sumWallet}
        isEdit={props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.receipt_no === null}
        onPrintDeposit={() => {
          props.onEvent({
            message: "RunSequence",
            params: { sequence: "BillingDeposit", action: "onPrintDeposit" },
          });
        }}
        openCancelDeposit={() => setOpenCancel(true)}
        // walletOptions
        walletOptions={walletOptions}
        //
        inputWalletValue={
          <Input
            id="CardBillingDeposit-Input-WalletValue"
            fluid={true}
            name="selectDeposit.walletValue"
            onFocus={() => setWalletFocus(true)}
            onBlur={() => setWalletFocus(false)}
            onChange={onChangeData}
            onKeyDown={walletValueNumber}
            disabled={
              props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.receipt_no !== null
            }
            value={
              walletFocus
                ? props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.walletValue
                : formatComma(
                    props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.walletValue
                  )
            }
          ></Input>
        }
        selectDepositData={memoDepositItems}
        patientValue={props.billingPatientLanguage?.value}
        patientOptions={props.billingPatientLanguage?.options}
        onChangePatient={(e: any, v: any) => {
          props.setProp("billingPatientLanguage.value", v.value);
        }}
        buttonAddDeposit={
          props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.mode_code !== "" &&
          props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.init_balance !==
            "0.00" ? (
            <div>
              <Icon name="edit"></Icon> แก้ไขรายการ
            </div>
          ) : (
            "เพิ่มรายการ"
          )
        }
        onAddDeposit={onAddDeposit}
        // Element
        receiptCode={
          <>
            รหัสเอกสาร :{" "}
            <strong style={{ color: "rgba(0,0,0,0.87)" }}>
              {props.bilReceiptCodeDetail?.code === "undefined"
                ? "-"
                // : props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.code !== null
                // ? props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.code
                : props.bilReceiptCodeDetail?.code || "ยังไม่ได้ตั้งเลขที่ใบเสร็จ"}
            </strong>
          </>
        }
        billPayment={(e: any) => {
          props.onEvent({
            message: "RunSequence",
            params: {
              sequence: "BillPayment",
              // invoiceData,
              invoiceData: {
                items: [
                  {
                    ...props.BillingDepositSequence?.billDepositDetail?.selectDeposit,
                    pay: props.BillingDepositSequence?.billDepositDetail?.selectDeposit
                      ?.walletValue,
                  },
                ],
                orderBy: "Item",
              },
              invoiceItemByItems: props.BillingHistorySequence?.billDepositDetail?.selectDeposit,
            },
          });
        }}
        // depositHistory
        onChangeHistoryData={onChangeHistoryData}
        depositStatusOptions={[
          {
            key: 1,
            value: "PAID",
            text: "PAID",
          },
          {
            key: 2,
            value: "CANCELED",
            text: "CANCELED",
          },
          {
            key: 3,
            value: "REFUND",
            text: "REFUND",
          },
        ]}
        startDate={props.BillingDepositSequence?.billDepositHistory?.startDate}
        endDate={props.BillingDepositSequence?.billDepositHistory?.endDate}
        onChangeStartDate={(date: any) => onChangeDate(date, "startDate")}
        onChangeEndDate={(date: any) => onChangeDate(date, "endDate")}
        depositHistoryData={memoDepositHistoryItems}
        onSearchDepositHistory={onSearchDepositHistory}
        onCloseData={onCloseData}
        errorMessage={<ErrorMessage error={props.errorMessage?.[CARD_BILLING_DEPOSIT]?.error} />}
        buttonSearchDepositHistory={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={onSearchDepositHistory}
            // data
            paramKey={`${CARD_BILLING_DEPOSIT}_getHistory`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_BILLING_DEPOSIT}_getHistory`]}
            // config
            color="blue"
            style={{ margin: "0px 10px" }}
            title={intl.formatMessage({ id: "ค้นหา" })}
          />
        }
        languageUX={props.languageUX}
      />

      {/* CardPaying */}
      <Modal
        closeOnDimmerClick={true}
        style={{ maxHeight: "80%", width: "80%", overflow: "auto" }}
        open={!!props?.BillPaymentSequence?.openCardPaying}
        onClose={() => {
          props.onEvent({
            message: "RunSequence",
            params: { sequence: "BillPayment", action: "close" },
          });
        }}
      >
        <CardPaying
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          BillPaymentSequence={props.BillPaymentSequence}
          patientInfo={props.patientInfo}
          citizenDetail={citizenDetail}
          patientOptionData={props.billingPatientLanguage}
          // invoiceData={invoiceData}
          invoiceItemByItems={props.invoiceItemByItems}
          masterOptions={props.masterOptions}
          // config
          isDeposit={true}
          invoiceData={{
            items: [
              {
                ...props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.[0],
                pay: props.BillingDepositSequence?.billDepositDetail?.sumRemian,
              },
            ],
            ids: [],
            orderBy: "Item",
          }}
          walletType={props.BillingDepositSequence?.billDepositDetail?.selectDeposit?.id}
          languageUX={props.languageUX}
        />
      </Modal>

      {/* CancelDeposit */}
      <Modal
        open={openCancel}
        size={"tiny"}
        onClose={() => setOpenCancel(false)}
      >
        <Modal.Header style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}>
          ยกเลิกและคืนเงินมัดจำ
        </Modal.Header>
        <Modal.Content>
          <Form>
            <FormGroup inline>
              <FormField inline width={6}>
                <div style={{ display: "flex", fontWeight: "bold" }}>
                  <div>{intl.formatMessage({ id: "ระบุเหตุผลในการยกเลิก" })}</div>
                  <div style={{ color: "red" }}>*</div>
                </div>
              </FormField>
              <FormField inline width={10}>
                <div style={{ width: "100%" }}>
                  <Dropdown
                    fluid={true}
                    search={true}
                    selection={true}
                    allowAdditions={true}
                    name="editDeposit.note"
                    onChange={onChangeData}
                    onAddItem={handleAddOptions}
                    options={options}
                    value={props.BillingDepositSequence?.billDepositDetail?.editDeposit?.note}
                  ></Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup inline>
              <FormField inline width={6}>
                <div style={{ display: "flex", fontWeight: "bold" }}>
                  <div>username</div>
                  <div style={{ color: "red" }}>*</div>
                </div>
              </FormField>
              <FormField inline width={10}>
                <div style={{ width: "100%" }}>
                  <Input
                    fluid={true}
                    name="editDeposit.username"
                    onChange={onChangeData}
                    value={props.BillingDepositSequence?.billDepositDetail?.editDeposit?.username}
                  ></Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup inline>
              <FormField inline width={6}>
                <div style={{ display: "flex", fontWeight: "bold" }}>
                  <div>password</div>
                  <div style={{ color: "red" }}>*</div>
                </div>
              </FormField>
              <FormField inline width={10}>
                <div style={{ width: "100%" }}>
                  <Input
                    fluid={true}
                    type="password"
                    name="editDeposit.password"
                    onChange={onChangeData}
                    value={props.BillingDepositSequence?.billDepositDetail?.editDeposit?.password}
                  ></Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup inline>
              <FormField inline width={16}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "15px",
                  }}
                >
                  <ButtonLoadCheck
                    // function
                    setProp={props.setProp}
                    onClick={onSaveWalletCancel}
                    // data
                    paramKey={`${CARD_BILLING_DEPOSIT}_walletCancel`}
                    buttonLoadCheck={
                      props.buttonLoadCheck?.[`${CARD_BILLING_DEPOSIT}_walletCancel`]
                    }
                    // config
                    color="red"
                    style={{ margin: "0px 10px" }}
                    title={intl.formatMessage({ id: "ยกเลิกและคืนเงินมัดจำ" })}
                  />
                  <Button onClick={onCloseWalletCancel}>{intl.formatMessage({ id: "ปิดหน้าต่าง" })}</Button>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </Modal.Content>
      </Modal>

      <ModInfo
        open={openModSuccess}
        titleColor={"green"}
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}        onApprove={() => setOpenModSuccess(false)}
        onClose={() => setOpenModSuccess(false)}
        alertText={
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {"ยกเลิกและคืนเงินมัดจำ สำเร็จ"}
          </div>
        }
      />
    </div>
  );
};

export default CardBillingDeposit;
