import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardNursingDiagnosisTemplateUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%", padding: "1rem"}}>
        <div
          className="ui form">

          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{fontWeight: "bold",fontSize:"large"}}>
                 Nursing Diagnosis (New)
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{flex: 1}}>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSave}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label>
                Template
              </label>
              <Dropdown
                fluid={true}
                onChange={props.changeNursingDiagnosis("templateID")}
                options={props.templateOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.templateID}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{width: "100%"}}>

          <Table
            data={props.nursingDiagnosisTemplate}
            getTrProps={props.rowProps}
            headers="ข้อวินิจฉัยทางการพยาบาล,แผนการพยาบาล,ผลลัพธ์ที่คาดหวัง,วันที่สิ้นสุดปัญหา"
            keys="diagnosis,plan,goal,end_date"
            minRows={2}
            showPagination={false}
            style={{height: "65vh", marginBottom: "10px"}}>
          </Table>
        </div>
      </div>
    )
}


export default CardNursingDiagnosisTemplateUX

export const screenPropsDefault = {"roomDetail":"7C06 Ward  อายุรกรรม ห้อง Standard","searchLabel":"ค้นหา"}

/* Date Time : Mon Aug 05 2024 15:34:45 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", padding: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormGroup",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 72,
      "name": "Table",
      "parent": 71,
      "props": {
        "data": {
          "type": "code",
          "value": "props.nursingDiagnosisTemplate"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "ข้อวินิจฉัยทางการพยาบาล,แผนการพยาบาล,ผลลัพธ์ที่คาดหวัง,วันที่สิ้นสุดปัญหา"
        },
        "keys": {
          "type": "value",
          "value": "diagnosis,plan,goal,end_date"
        },
        "minRows": {
          "type": "code",
          "value": "2"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"65vh\", marginBottom: \"10px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": " Nursing Diagnosis (New)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "Template"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Dropdown",
      "parent": 70,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNursingDiagnosis(\"templateID\")"
        },
        "options": {
          "type": "code",
          "value": "props.templateOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.templateID"
        }
      },
      "seq": 76,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "CardNursingDiagnosisTemplateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "roomDetail": "7C06 Ward  อายุรกรรม ห้อง Standard",
    "searchLabel": "ค้นหา"
  },
  "width": 75
}

*********************************************************************************** */
