import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
import DownloadEmployeePatientCoverageTemplate from "issara-sdk/apis/DownloadEmployeePatientCoverageTemplate_apps_CLM";
import ImportEmployeePatientCoverageList from "issara-sdk/apis/ImportEmployeePatientCoverageList_apps_CLMM";

import {
  SetErrorMessage,
  SetProperty,
  downloadXLSX,
} from "react-lib/apps/HISV3/common/CommonInterface";

import { State as MainState } from "HIS/MainHISInterface";

export type State = Partial<{
  // sequence
  UploadPatientCoverageSequence: Partial<
    {
      sequenceIndex: "Action" | "Start" | null;
      activeTab: TabMenuKeys;
      coverageGroup: CoverageGroupKeys;
      items_failed: ItemType[];
      items_success: ItemType[];
    } & DetailType
  > | null;
}>;

type PickedState = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage">>;

export type PickedProps = Partial<Omit<PickedState, "">>;

export type DetailType = {
  excelFile: File | null;
  fiscalYearId: number | string;
  items: ItemType[];
  maxReimb: string;
};

type ItemType = Record<string, string | null>;

export type TabMenuKeys = keyof typeof TAB_KEYS;

export type CoverageGroupKeys = (typeof COVERAGE_GROUPS)[keyof typeof COVERAGE_GROUPS];

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  text: string;
  value: number | string;
};

// Sequence
type SeqState = {
  sequence: "UploadPatientCoverage";
  clear?: boolean;
  restart?: boolean;
};

// Handle Action
type ActionType =
  // Action
  | {
      action: "CHANGE_FILE";
      file: File;
    }
  | { action: "CHANGE_COVERAGE"; name: CoverageGroupKeys }
  | { action: "CLEAR_FILE" }
  | { action: "DOWNLOAD_TEMPLATE"; card: string }
  | { action: "UPLOAD"; card: string };
// Method

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  UploadPatientCoverageSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  device?: number;
  division?: number;
};

export const DataInitial = {};

const Masters = [] as const;

export const UPLOAD_PATIENT_COVERAGE = "CardUploadPatientCoverage";

type Optional<T> = {
  [K in keyof T]: `${typeof UPLOAD_PATIENT_COVERAGE}_${T[K] & string}`;
};

export const ACTIONS = {
  CHANGE_COVERAGE: "CHANGE_COVERAGE",
  CHANGE_FILE: "CHANGE_FILE",
  CLEAR_FILE: "CLEAR_FILE",
  DOWNLOAD_TEMPLATE: "DOWNLOAD_TEMPLATE",
  UPLOAD: "UPLOAD",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${UPLOAD_PATIENT_COVERAGE}_${key}`])
) as Optional<typeof ACTIONS>;

const COVERAGE_GROUPS = {
  BOT: "bot",
  CROWN_PROPERTY: "crown_property",
  GROUP: "group",
  GSB: "gsb",
} as const;

export const TABLE_HEADERS = {
  [COVERAGE_GROUPS.BOT]: [
    "รหัสสิทธิ",
    "รหัสสังกัด",
    "รหัสพนักงาน",
    "ชื่อ-นามสกุลผู้ใช้สิทธิ",
    "เลขที่บัตรพนักงาน/เลขที่บัตรครอบครัว",
    "เลขที่บัตรประชาชน",
    "สถานะ",
  ],
  [COVERAGE_GROUPS.CROWN_PROPERTY]: [
    "รหัสพนักงาน",
    "คำนำหน้า",
    "ชื่อ",
    "นามสกุล",
    "เลขบัตรประจำตัวประชาชน",
    "วันเริ่มสัญญา",
    "วันสิ้นสุดสัญญา",
    "สถานะ",
  ],
  [COVERAGE_GROUPS.GROUP]: [
    "ประทับเวลา",
    "ชื่อ-นามสกุล",
    "รหัสพนักงาน/ข้าราชการ",
    "บัตรประชาชน",
    "หน่วยงาน/สังกัด",
    "สิทธิประกันสังคม 900 บาท",
    "ชื่อบิดา",
    "เลขบัตรประชาชน (บิดา)",
    "ชื่อมารดา",
    "เลขบัตรประชาชน (มารดา)",
    "คู่สมรส : ชื่อ - นามสกุล",
    "คู่สมรส : หมายเลขบัตรประชำตัวประชาชน",
    "บุตรคนที่ 1 : ชื่อ - นามสกุล (อายุไม่เกิน 20 ปี)",
    "บุตรคนที่ 1 : หมายเลขบัตรประชำตัวประชาชน",
    "บุตรคนที่ 2 : ชื่อ - นามสกุล (อายุไม่เกิน 20 ปี)",
    "บุตรคนที่ 2 : หมายเลขบัตรประชำตัวประชาชน",
    "บุตรคนที่ 3 : ชื่อ - นามสกุล (อายุไม่เกิน 20 ปี)",
    "บุตรคนที่ 3 : หมายเลขบัตรประชำตัวประชาชน",
    "บุตรคนที่ 4 : ชื่อ - นามสกุล (อายุไม่เกิน 20 ปี)",
    "บุตรคนที่ 4 : หมายเลขบัตรประชำตัวประชาชน",
    "แนบทะเบียนบ้าน กรุณาตั้งชื่อไฟล์เป็นชื่อของท่าน (ต้อง Login ด้วย email @chula.ac.th หรือ @gmail ก่อนแนบไฟล์)",
    "บุตรคนที่ 1 : แนบทะเบียนบ้านหรือเอกสารใบรับรองบุตรบุญธรรม",
    "บุตรคนที่ 1 : แนบใบรับรองบุตร",
    "บุตรคนที่ 2 : แนบทะเบียนบ้านหรือเอกสารใบรับรองบุตรบุญธรรม",
    "บุตรคนที่ 2 : หมายเลขบัตรประชำตัวประชาชน.1",
    "บุตรคนที่ 2 : แนบใบรับรองบุตร",
    "บุตรคนที่ 3 : แนบทะเบียนบ้านหรือเอกสารใบรับรองบุตรบุญธรรม",
    "บุตรคนที่ 3 : แนบใบรับรองบุตร",
    "บุตรคนที่ 4 : แนบทะเบียนบ้านหรือเอกสารใบรับรองบุตรบุญธรรม",
    "ที่อยู่อีเมล",
    "Unnamed: 30",
    "Unnamed: 31",
    "บุตรคนที่ 4 : แนบใบรับรองบุตร",
  ],
  [COVERAGE_GROUPS.GSB]: [
    "รหัสพนักงาน",
    "คำนำหน้า",
    "ชื่อ",
    "นามสกุล",
    "เลขบัตรประจำตัวประชาชน",
    "วันเริ่มสัญญา",
    "วันสิ้นสุดสัญญา",
    "สถานะ",
  ],
} as const;

export const TAB_KEYS = {
  FAIL: "FAIL",
  SUCCESS: "SUCCESS",
  UPLOAD: "UPLOAD",
} as const;

export const TAB_MENUS = {
  [TAB_KEYS.FAIL]: {
    bg: "#FF0000",
    key: "items_failed",
    title: "Fail",
  },
  [TAB_KEYS.SUCCESS]: {
    bg: "#27AE60",
    key: "items_success",
    title: "Success",
  },
  [TAB_KEYS.UPLOAD]: {
    bg: "",
    key: "items",
    title: "Upload",
  },
} as const;

const TEMPLATE_NAMES = {
  [COVERAGE_GROUPS.BOT]: "สิทธิสวัสดิการธนาคารแห่งประเทศไทย",
  [COVERAGE_GROUPS.CROWN_PROPERTY]: "สิทธิทรัพย์สินพระมหากษัตริย์",
  [COVERAGE_GROUPS.GROUP]: "สิทธิสวัสดิการบุคลากรคณะ",
  [COVERAGE_GROUPS.GSB]: "สิทธิธนาคารออมสิน",
};

export const CURRENT_YEAR = Number(moment().format("YYYY")) + 543;

const DETAIL_INIT: DetailType = {
  excelFile: null,
  fiscalYearId: CURRENT_YEAR,
  items: [],
  maxReimb: "",
};

type Handler<P = any, R = any> = (
  controller: WasmController<PickedState & State, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  controller.setState({
    UploadPatientCoverageSequence: {
      ...state.UploadPatientCoverageSequence,
      sequenceIndex: "Action",
      ...DETAIL_INIT,
      activeTab: TAB_KEYS.UPLOAD,
      coverageGroup: COVERAGE_GROUPS.GROUP,
    },
  });
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.CHANGE_COVERAGE]: HandleChangeCoverage,
    [ACTIONS.CHANGE_FILE]: HandleChangeFile,
    [ACTIONS.CLEAR_FILE]: HandleClearFile,
    [ACTIONS.DOWNLOAD_TEMPLATE]: HandleDownloadTemplate,
    [ACTIONS.UPLOAD]: HandleUploadFile,
  };

  const { action } = params;

  return actionHandlers[action]?.(controller, params as any);
};

const HandleChangeCoverage: Handler<Params<"CHANGE_COVERAGE">> = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    UploadPatientCoverageSequence: {
      ...state.UploadPatientCoverageSequence,
      activeTab: TAB_KEYS.UPLOAD,
      coverageGroup: params.name,
      ...DETAIL_INIT,
      items_failed: [],
      items_success: [],
    },
  });
};

const HandleChangeFile: Handler<Params<"CHANGE_FILE">> = (controller, params) => {
  const state = controller.getState();

  const coverageGroup = state.UploadPatientCoverageSequence?.coverageGroup;

  if (!coverageGroup) {
    return;
  }

  const fileReader = new FileReader();

  fileReader.readAsBinaryString(params.file);

  fileReader.onload = async (event) => {
    if (!event.target) {
      return;
    }

    const XLSX = await import("xlsx");

    const data = event.target.result;
    const workbook = XLSX.read(data, { type: "binary" });
    const rowObject: any[] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    if (rowObject.length === 0) {
      console.warn("Please check the correctness file.");

      return;
    }

    const items = rowObject
      .map((item) =>
        Object.assign(
          {},
          ...TABLE_HEADERS[coverageGroup].map((header) => ({
            [header]: item[header] || "",
          }))
        )
      )
      .filter((item) => Object.values(item).some(Boolean)) as ItemType[];

    controller.setState({
      UploadPatientCoverageSequence: {
        ...state.UploadPatientCoverageSequence,
        excelFile: params.file,
        items,
      },
    });
  };
};

const HandleUploadFile: Handler<Params<"UPLOAD">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPLOAD]: "LOADING" },
  });

  const seq = state.UploadPatientCoverageSequence;

  const [result, error] = await ImportEmployeePatientCoverageList.create({
    apiToken: controller.apiToken,
    data: {
      file: seq?.excelFile || "",
      fiscal_year: seq?.fiscalYearId,
      ...(seq?.maxReimb ? { max_reimb: seq.maxReimb } : {}),
      coverage_group: seq?.coverageGroup,
      save: true,
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });

    return;
  }

  let activeTab: TabMenuKeys = TAB_KEYS.UPLOAD;

  if (result.items_failed?.length) {
    activeTab = TAB_KEYS.FAIL;
  } else if (result.items_success?.length) {
    activeTab = TAB_KEYS.SUCCESS;
  }

  controller.setState({
    UploadPatientCoverageSequence: {
      ...state.UploadPatientCoverageSequence,
      ...result,
      activeTab,
      ...DETAIL_INIT,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPLOAD]: null },
  });
};

const HandleClearFile: Handler<Params<"CLEAR_FILE">> = async (controller) => {
  const state = controller.getState();

  controller.setState({
    UploadPatientCoverageSequence: {
      ...state.UploadPatientCoverageSequence,
      excelFile: null,
      items: [],
    },
  });
};

const HandleDownloadTemplate: Handler<Params<"DOWNLOAD_TEMPLATE">> = async (controller, params) => {
  const state = controller.getState();

  const coverageGroup = state.UploadPatientCoverageSequence?.coverageGroup;

  if (!coverageGroup) {
    return;
  }

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_TEMPLATE]: "LOADING" },
  });

  const [result, error] = await DownloadEmployeePatientCoverageTemplate.get({
    apiToken: controller.apiToken,
    params: {
      coverage_group: coverageGroup,
    },
    extra: { responseType: "arraybuffer" },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });
  } else {
    downloadXLSX(result, TEMPLATE_NAMES[coverageGroup]);

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_TEMPLATE]: "SUCCESS" },
    });
  }
};
