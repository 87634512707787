import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardReconfirmUX = (props: any) => {
    return(
      <div>

        <div
          style={{margin: "10px 0px"}}>

          <div
            style={{display: "flex", alignItems:"center"}}>

            <div
              style={{flex: "0 0 30%"}}>
              HN ผู้ป่วย
              <div>
                {props.searchHN}
              </div>
            </div>
            <div
              style={{flex: "0 0 30%", marginLeft: "35px"}}>
              ประเภทนัดหมาย
              <div>
                {props.searchAppointmentType}
              </div>
            </div>
          </div>
          <div
            style={{display: "flex", alignItems:"center"}}>

            <div
              style={{flex: "0 0 30%"}}>
              ทันตแพทย์ / นิสิต
              <div>
                {props.searchDoctor}
              </div>
            </div>
            <div
              style={{flex: "0 0 30%", marginLeft: "35px"}}>
              หน่วยงาน / คลินิก
              <div>
                {props.searchClinic}
              </div>
            </div>
          </div>
          <div
            style={{display: "flex", alignItems:"flex-end"}}>

            <div
              style={{display:"flex",flexDirection: "column", flex: "0 0 30%"}}>
              วันที่เริ่มต้น
              <DateTextBox
                inputClassNames="fluid"
                onChange={props.onChangeStartDate}
                value={props.startDate}>
              </DateTextBox>
            </div>
            <div
              style={{margin : "10px 10px"}}>
               ถึง
            </div>
            <div
              style={{display:"flex",flexDirection: "column", flex: "0 0 30%"}}>
              วันที่สิ้นสุด
              <DateTextBox
                inputClassNames="fluid"
                onChange={props.onChangeEndDate}
                value={props.endDate}>
              </DateTextBox>
            </div>
            <Button
              color="green"
              onClick={props.onClickSearch}
              style={{marginLeft: "20px"}}>
              ค้นหา
            </Button>
          </div>
        </div>
        <Table
          data={props.reconfirmList}
          getTrProps={props.rowProps}
          headers=",HN,ชื่อ-สกุล,แพทย์/นิสิต,หน่วยงาน/คลินิก,วันเวลานัดหมายเดิม,หมายเหตุ"
          keys="icon,patient_hn,patient_name,provider_name, division_name,estimated_at, status_note"
          minRows="10"
          showPagination={false}
          style={{height: "400px"}}
          widths="50,^80,^70,^70,^70,^90,^65">
        </Table>
      </div>
    )
}


export default CardReconfirmUX

export const screenPropsDefault = {}

/* Date Time : Thu Jun 13 2024 12:41:32 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.reconfirmList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": ",HN,ชื่อ-สกุล,แพทย์/นิสิต,หน่วยงาน/คลินิก,วันเวลานัดหมายเดิม,หมายเหตุ"
        },
        "keys": {
          "type": "value",
          "value": "icon,patient_hn,patient_name,provider_name, division_name,estimated_at, status_note"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        },
        "widths": {
          "type": "value",
          "value": "50,^80,^70,^70,^70,^90,^65"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"flex-end\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "HN ผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\", marginLeft: \"35px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เริ่มต้น"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection: \"column\", flex: \"0 0 30%\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": " ถึง"
        },
        "style": {
          "type": "code",
          "value": "{margin : \"10px 10px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection: \"column\", flex: \"0 0 30%\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickSearch"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchHN"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchAppointmentType"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 20,
      "name": "DateTextBox",
      "parent": 11,
      "props": {
        "inputClassNames": {
          "type": "value",
          "value": "fluid"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "overlayClassNames": {
          "type": "value",
          "value": ""
        },
        "overlayWrapperClassNames": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 21,
      "name": "DateTextBox",
      "parent": 13,
      "props": {
        "inputClassNames": {
          "type": "value",
          "value": "fluid"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "ทันตแพทย์ / นิสิต"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchDoctor"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน / คลินิก"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\", marginLeft: \"35px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchClinic"
        }
      },
      "seq": 29,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardReconfirmUX",
  "project": "cudent2",
  "screenPropsDefault": {
  },
  "width": 25
}

*********************************************************************************** */
