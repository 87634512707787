import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  TimeFreeTextBox24,
  DateTextBox
} from 'react-lib/apps/common'

const CardPreAnestheticFormUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPreAnestheticReEvaluation"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",padding:"20px 10px 10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props?.buttonSave}
            </div>
          </div>
          <Form
            className="--override-disabled --grey">
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationNoneDueto || false}
                  label="None due to"
                  name="PreanestheticReEvaluationNoneDueto"
                  onChange={props.onChangeData}
                  style={{minWidth: "120px", maxWidth: "120px", fontWeight: "bold"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={16}>
                <Input
                  disabled={props.readOnly}
                  name="PreanestheticReEvaluationIsNoneDueto"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsNoneDueto || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Date
                </label>
              </FormField>
              <FormField
                inline={true}
                width={8}>
                <div
                  style={{width: "100%"}}>
                  
                  <DateTextBox
                    disabled={props.readOnly}
                    onChange={props.onChangeDateForm}
                    value={props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONDate || ""}>
                  </DateTextBox>
                </div>
                <TimeFreeTextBox24
                  className="time-custom-react-picker"
                  disabled={props.readOnly}
                  onChange={props.onChangeTimeForm}
                  value={props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONTime || ""}>
                </TimeFreeTextBox24>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{marginLeft: "1rem",minWidth: "40px", maxWidth: "40px"}}>
                   Body weight
                </label>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="PREANESTHETICREEVALUATIONBodyWeight"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONBodyWeight || ""}>
                </Input>
                <label
                  style={{marginLeft: "1rem",minWidth: "20px", maxWidth: "20px"}}>
                  kg.
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{padding: 0}}
                width={4}>
                <label
                  style={{minWidth: "25px", maxWidth: "25px"}}>
                  BP
                </label>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="PREANESTHETICREEVALUATIONBP"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONBP || ""}>
                </Input>
                <label
                  style={{marginLeft: "1rem",minWidth: "40px", maxWidth: "40px"}}>
                  mmHg
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  PR
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{padding: 0}}
                width={8}>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="PreanestheticReEvaluationIsPR"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsPR || ""}>
                </Input>
                <label>
                  /min
                </label>
                <label
                  style={{marginLeft: "1rem"}}>
                   RR
                </label>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="PreanestheticReEvaluationIsRR"
                  onChange={props.onChangeData}
                  style={{marginLeft: "1rem"}}
                  value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsRR || ""}>
                </Input>
                <label
                  style={{marginLeft: "1rem"}}>
                  /min
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{marginLeft: "1rem",minWidth: "40px", maxWidth: "40px"}}>
                  Temp
                </label>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="PreanestheticReEvaluationTemp"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationTemp || ""}>
                </Input>
                <label
                  style={{marginLeft: "1rem",minWidth: "20px", maxWidth: "20px"}}>
                  °C
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{padding: 0}}
                width={4}>
                <label
                  style={{minWidth: "25px", maxWidth: "25px"}}>
                  LMP
                </label>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="PreanestheticReEvaluationLMP"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationLMP || ""}>
                </Input>
                <label
                  style={{marginLeft: "1rem",minWidth: "40px", maxWidth: "40px"}}>
                  
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Proposed operation
                </label>
              </FormField>
              <FormField
                inline={true}
                width={11}>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="PreanestheticReEvaluationProposedOperation"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationProposedOperation || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Change in clinical condition
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  disabled={props.readOnly}
                  fluid={true}
                  name="IsChangeInClinicalCondition"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_change_clini || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.IsChangeInClinicalCondition || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="ChangeInClinicalConditionRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.ChangeInClinicalConditionRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Change in current medication
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  disabled={props.readOnly}
                  fluid={true}
                  name="IsChangeInCurrentMedication"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_change_in_cu || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.IsChangeInCurrentMedication || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="ChangeInCurrentMedicationRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.ChangeInCurrentMedicationRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  New disease / illness
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  disabled={props.readOnly}
                  fluid={true}
                  name="IsNewDiseaseIllness"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_new_disease || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.IsNewDiseaseIllness || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Input
                  disabled={props.readOnly}
                  fluid={true}
                  name="NewDiseaseIllnessRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.NewDiseaseIllnessRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Assessment
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.AssessmentIsStableClinicalCondition || false}
                  disabled={props.readOnly}
                  label="Stable clinical condition"
                  name="AssessmentIsStableClinicalCondition"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.AssessmentIsUnstableDueTo || false}
                  disabled={props.readOnly}
                  label="Unstable due to"
                  name="AssessmentIsUnstableDueTo"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Plan
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.PlanIsGoOnAnesthesiaAsPlan || false}
                  disabled={props.readOnly}
                  label="Go on anesthesia as plan"
                  name="PlanIsGoOnAnesthesiaAsPlan"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.PlanIsOther || false}
                  disabled={props.readOnly}
                  name="PlanIsOther"
                  onChange={props.onChangeData}>
                </Checkbox>
                <Input
                  disabled={props.readOnly}
                  name="PlanOtherRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PlanOtherRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={8}>
                <label
                  style={{minWidth: "max-content"}}>
                  Anesthesiologist / Anesthetist
                </label>
                <div
                  style={{width: "100%"}}>
                  {props.anesthesiologistSearchBox}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <label
                  style={{minWidth: "max-content"}}>
                  Anesthesia nurse
                </label>
                <div
                  style={{width: "100%"}}>
                  {props.anesthesiaNurseSearchBox}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardPreAnestheticFormUX

export const screenPropsDefault = {}

/* Date Time : Fri Nov 22 2024 15:08:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticReEvaluation"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",padding:\"20px 10px 10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginBottom: \"1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Form",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 121,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 121,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 122,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "FormField",
      "parent": 122,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "FormField",
      "parent": 122,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "FormField",
      "parent": 122,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Checkbox",
      "parent": 123,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationNoneDueto || false"
        },
        "label": {
          "type": "value",
          "value": "None due to"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationNoneDueto"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "label",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": "Date"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 132,
      "name": "TimeFreeTextBox24",
      "parent": 126,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTimeForm"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONTime || \"\""
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 133,
      "name": "DateTextBox",
      "parent": 131,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateForm"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONDate || \"\""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": " Body weight"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\",minWidth: \"40px\", maxWidth: \"40px\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "kg."
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\",minWidth: \"20px\", maxWidth: \"20px\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "label",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": "BP"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"25px\", maxWidth: \"25px\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "label",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": "mmHg"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\",minWidth: \"40px\", maxWidth: \"40px\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Input",
      "parent": 128,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PREANESTHETICREEVALUATIONBP"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONBP || \"\""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Input",
      "parent": 127,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PREANESTHETICREEVALUATIONBodyWeight"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONBodyWeight || \"\""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Input",
      "parent": 124,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationIsNoneDueto"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsNoneDueto || \"\""
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "label",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "PR"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "label",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "Temp"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\",minWidth: \"40px\", maxWidth: \"40px\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "°C"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\",minWidth: \"20px\", maxWidth: \"20px\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "label",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "LMP"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"25px\", maxWidth: \"25px\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\",minWidth: \"40px\", maxWidth: \"40px\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Input",
      "parent": 145,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationLMP"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationLMP || \"\""
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "Input",
      "parent": 144,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationTemp"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationTemp || \"\""
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Input",
      "parent": 143,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationIsPR"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsPR || \"\""
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "Input",
      "parent": 143,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationIsRR"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsRR || \"\""
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "label",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": " RR"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 160,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormField",
      "parent": 161,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormField",
      "parent": 162,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 160,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "FormField",
      "parent": 160,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormField",
      "parent": 161,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormField",
      "parent": 161,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "FormField",
      "parent": 162,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "FormField",
      "parent": 162,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "label",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": "New disease / illness"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": "Change in current medication"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "label",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "Change in clinical condition"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "Proposed operation"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Input",
      "parent": 167,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationProposedOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationProposedOperation || \"\""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Dropdown",
      "parent": 168,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "IsChangeInClinicalCondition"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_change_clini || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsChangeInClinicalCondition || \"\""
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Input",
      "parent": 169,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ChangeInClinicalConditionRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ChangeInClinicalConditionRemark || \"\""
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Dropdown",
      "parent": 170,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "IsChangeInCurrentMedication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_change_in_cu || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsChangeInCurrentMedication || \"\""
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Dropdown",
      "parent": 172,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "IsNewDiseaseIllness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_new_disease || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsNewDiseaseIllness || \"\""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "Input",
      "parent": 171,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ChangeInCurrentMedicationRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ChangeInCurrentMedicationRemark || \"\""
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "Input",
      "parent": 173,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "NewDiseaseIllnessRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.NewDiseaseIllnessRemark || \"\""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 185,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormField",
      "parent": 186,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "FormField",
      "parent": 185,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormField",
      "parent": 185,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "FormField",
      "parent": 186,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "FormField",
      "parent": 186,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": "Assessment"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "Checkbox",
      "parent": 189,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AssessmentIsStableClinicalCondition || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "label": {
          "type": "value",
          "value": "Stable clinical condition"
        },
        "name": {
          "type": "value",
          "value": "AssessmentIsStableClinicalCondition"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Checkbox",
      "parent": 190,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AssessmentIsUnstableDueTo || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "label": {
          "type": "value",
          "value": "Unstable due to"
        },
        "name": {
          "type": "value",
          "value": "AssessmentIsUnstableDueTo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Checkbox",
      "parent": 191,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanIsGoOnAnesthesiaAsPlan || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "label": {
          "type": "value",
          "value": "Go on anesthesia as plan"
        },
        "name": {
          "type": "value",
          "value": "PlanIsGoOnAnesthesiaAsPlan"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Checkbox",
      "parent": 192,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanIsOther || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "name": {
          "type": "value",
          "value": "PlanIsOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Input",
      "parent": 192,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "name": {
          "type": "value",
          "value": "PlanOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanOtherRemark || \"\""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormGroup",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "FormField",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "FormField",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "label",
      "parent": 201,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesiologist / Anesthetist"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "label",
      "parent": 202,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia nurse"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "div",
      "parent": 201,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthesiologistSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "div",
      "parent": 202,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthesiaNurseSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 206,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardPreAnestheticFormUX",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
