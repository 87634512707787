import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Form,
  TextArea,
  Input,
  Checkbox,
  Icon,
  FormGroup,
  FormField,
  Dropdown
} from 'semantic-ui-react'
import {
  TimeTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const CardAppointmentDetailUX = (props: any) => {
    return(
      <div
        style={{padding: "10px 10px"}}>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(11, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
          <div
            style={{"gridRow":"2/11","gridColumn":"1/41",...(props.hideGeneralDetail ? {display: "none"} : {display: "block"} )}}>
            <Table
              data={props.blockAppointments}
              headers="ชื่อคนไข้,เวลาเริ่ม,ระยะเวลา,ผู้ทำนัดหมาย,วันเวลาที่ทำนัดหมาย"
              keys="patient_name,estimated_at_display,estimated_duration,edit_user_name,updated"
              minRows="4"
              showPagination={false}
              style={{height: "200px"}}>
            </Table>
          </div>
          <div
            style={{ gridRow: "1/2", gridColumn: "1/15", ...(props.hideGeneralDetail ? {display: "none"} : {display: "block"} )}}>
            รายชื่อคนไข้อื่นๆในคาบเดียวกัน
          </div>
        </div>
        <div
          style={{display: "flex", flexDirection: "column", gap: "10px"}}>

          <div
            style={{display: "flex", justifyContent: "space-between"}}>

            <div
              style={{display: "flex", flexDirection: "column", gap: "10px"}}>

              <div
                style={{display: props.hideGeneralDetail ? "none":"flex",justifyContent:"flex-start", gap:"10px"}}>

                <div>

                  <div
                    style={{justifyContent:"flex-start", gap: "35px", alignItems: "center" , display: "flex"}}>

                    <div
                      style={{display: "flex", gap: "10px", alignItems:"center"}}>

                      <div
                        style={{fontWeight: "bolder"}}>
                        HN:
                      </div>
                      <div>
                        {}
                        <Input
                          disabled={true}
                          readOnly={true}
                          value={props.selectedAppointment?.hn || props.selectedAppointment?.patient_hn || ""}>
                        </Input>
                      </div>
                    </div>
                    <div
                      style={{display: "flex", gap: "10px"}}>

                      <div
                        style={{fontWeight: "bolder"}}>
                        {props.languageUX === "en" ? "Name  -  Family name:" : "ชื่อ-สกุล : "}
                      </div>
                      <div>
                        {props.selectedAppointment?.patient_name}
                      </div>
                    </div>
                    <div
                      style={{display: "flex", gap: "10px"}}>

                      <div
                        style={{fontWeight: "bolder"}}>
                        เบอร์โทร :
                      </div>
                      <div>
                        {props.selectedAppointment?.patient_tel}
                      </div>
                    </div>
                    <div
                      style={{minWidth: "max-content", fontWeight:"bolder",marginLeft:"100px", ...(!props.isShowChair && { display: "none" })}}>
                      งานที่ทำ:
                    </div>
                  </div>
                  <div
                    style={{justifyContent:"flex-start", gap: "35px",alignItems: "center"}}>

                    <div
                      style={{display: "flex", gap: "10px", margin: "18px 0px"}}>

                      <div
                        style={{fontWeight: "bolder"}}>
                        พบแพทย์:
                      </div>
                      <div>
                        {props.selectedAppointment?.detail}
                      </div>
                    </div>
                    <div
                      style={{display: "flex", gap: "10px", alignItems:"center", marginBottom:"8px"}}>

                      <div
                        style={{fontWeight: "bolder"}}>
                        คลินิก :
                      </div>
                      <div>
                        {}
                        <Input
                          disabled={true}
                          readOnly={true}
                          value={props.selectedAppointment?.division_name}>
                        </Input>
                      </div>
                    </div>
                    <div
                      style={{display: "flex", gap: "10px", alignItems:"center", ...(!props.isShowChair && { display: "none" })}}>

                      <div
                        style={{fontWeight: "bolder"}}>
                        เก้าอี้ :
                      </div>
                      <div>

                        <Input
                          disabled={true}
                          readOnly={true}
                          value={props.selectedAppointment?.extra?.chair}>
                        </Input>
                      </div>
                    </div>
                  </div>
                </div>
                <Form>
                  <TextArea
                    onChange={props.onChangeJobToDo}
                    style={{ ...(!props.isShowChair && { display: "none" }) }}
                    value={props.jobToDo}>
                  </TextArea>
                </Form>
              </div>
              <div
                style={{justifyContent:"flex-start", gap: "35px",alignItems: "center", ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>

                <div
                  style={{display: "flex",alignItems: "center", gap: "10px"}}>

                  <div
                    style={{fontWeight: "bolder"}}>
                    วันที่นัดหมาย :
                  </div>
                  <div>

                    <Input
                      disabled={true}
                      readOnly={true}
                      value={props.appointmentedDate}>
                    </Input>
                  </div>
                </div>
                <div
                  style={{display: "flex",alignItems: "center", gap: "10px"}}>

                  <div
                    style={{fontWeight: "bolder"}}>
                    เวลานัดหมาย :
                  </div>
                  <div
                    style={{display: props.manualTime ? "none" : "block"}}>
                    {}
                    <TimeTextBox
                      availableOptions={props.availableOptions}
                      interval={props.intervalTimeSelected}
                      maxHour={props.maxHour}
                      minHour={props.minHour}
                      onTimeChange={props.onTimeAppointmentChange}
                      reservedText={props.reservedText}
                      useDefaultTime={true}
                      value={props.estimatedAt}>
                    </TimeTextBox>
                  </div>
                  <div
                    style={{display: props.manualTime ? "block" : "none"}}>

                    <TimeFreeTextBox24
                      className="time-custom-react-picker"
                      maxHour={props.maxHour}
                      minHour={props.minHour}
                      onChange={(value: any) => props.setEstimatedAt(value)}
                      value={props.estimatedAt}>
                    </TimeFreeTextBox24>
                  </div>
                </div>
                <div>

                  <Checkbox
                    checked={props.manualTime}
                    label="กำหนดเวลาแบบ manual"
                    onChange={() => props.setManualTime(!props.manualTime)}>
                  </Checkbox>
                </div>
              </div>
            </div>
            <div
              style={{display: props.showEmergencyContact? "flex": "none", flexDirection: "column", gap: "10px", marginRight: "40px", width: "auto", border: "1px solid red", padding: "10px", borderRadius: "10px"}}>

              <div
                style={{display: "flex", justifyContent:"flex-start", gap: "5px", alignItems: "center", flex: 1}}>

                <div
                  style={{fontWeight: "bolder"}}>
                  ผู้ติดต่อกรณีฉุกเฉิน
                </div>
                <Icon
                  className="red large"
                  name="user">
                </Icon>
              </div>
              <div
                style={{display: "flex", justifyContent:"flex-start", gap: "15px", alignItems: "center", flex: 1}}>

                <div
                  style={{fontWeight: "bolder"}}>
                  ชื่อ - สกุล:
                </div>
                <div>
                  {props.emergency_contact || "ไม่ระบุ"}
                </div>
              </div>
              <div
                style={{display: "flex", justifyContent:"flex-start", gap: "15px", alignItems: "center" , flex: 1}}>

                <div
                  style={{fontWeight: "bolder"}}>
                  เบอร์โทรศัพท์:
                </div>
                <div>
                  {props.emergency_contact_phone_no || "-"}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{display: props.hideGeneralDetail ? "none":"flex",}}>

            <div
              className="ui form"
              style={{width: "100%"}}>

              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={4}>
                  <label
                    style={{minWidth: "max-content"}}>
                    ระยะเวลา (นาที) :
                  </label>
                  <Input
                    fluid={true}
                    min={1}
                    onChange={(e: any) => props.setEstimatedDuration(e.target.value)}
                    type="number"
                    value={props.estimatedDuration}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}
                  style={{minWidth: "max-content"}}>
                  <Checkbox
                    checked={props.full}
                    label="บันทึกว่าคาบนี้เต็มแล้ว"
                    onChange={props.toggleFull}>
                  </Checkbox>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <label
                    style={{minWidth: "max-content"}}>
                    ประเภทการนัดหมาย
                  </label>
                  <Dropdown
                    className="inline-label"
                    fluid={true}
                    onChange={props.onChangeTreatmentType}
                    options={props.treatmentTypeOptions || []}
                    search={true}
                    selection={true}
                    style={{width: "100%"}}
                    value={props.treatmentType}>
                  </Dropdown>
                </FormField>
                <FormField
                  inline={true}
                  style={{padding:0}}
                  width={6}>
                  <label
                    style={{minWidth: "max-content"}}>
                    นัดเพื่อ:
                  </label>
                  <Dropdown
                    allowAdditions="true"
                    className="inline-label"
                    clearable={true}
                    fluid={true}
                    multiple={true}
                    onChange={props.onChangeAppointmentFor}
                    options={props.appointmentForNoteOptions}
                    search={true}
                    selection={true}
                    style={{width: "100%"}}
                    value={props.appointmentFor}>
                  </Dropdown>
                </FormField>
              </FormGroup>
            </div>
          </div>
          <div>
            {props.divider}
          </div>
          <div
            style={{justifyContent:"flex-start", gap: "35px",alignItems: "center", ...(props.hideGeneralDetail || props.hideDrugAllergy ? {display: "none"} : {display: "flex"} )}}>

            <div
              style={{display: "flex", alignItems:"center", gap: "10px"}}>

              <div
                style={{fontWeight: "bolder"}}>
                การแพ้ยา :
              </div>
              <div>

                <Input
                  onChange={props.onAllergyChange}
                  value={props.allergy}>
                </Input>
              </div>
            </div>
            <div
              style={{display: "flex", alignItems:"center", gap: "10px"}}>

              <div
                style={{fontWeight: "bolder"}}>
                Premedication :
              </div>
              <div>

                <Input
                  onChange={props.onPreMedicationChange}
                  value={props.premedication}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{fontWeight: "bolder", margin: "20px 0px 5px 0px", ...(props.hideGeneralDetail || props.hideDrugAllergy? {display: "none"} : {display: "flex"} )}}>
            คำสั่งแพทย์
          </div>
          <div
            style={{justifyContent:"flex-start", gap: "35px",alignItems: "center", ...(props.hideGeneralDetail || props.hideDrugAllergy  ? {display: "none"} : {display: "flex"} )}}>

            <div
              style={{display: "flex", alignItems:"center", gap: "10px"}}>

              <div
                style={{fontWeight: "bolder"}}>
                สั่งทำ Lab :
              </div>
              <div>

                <Input
                  onChange={props.onOrderLabChange}
                  value={props.orderLab}>
                </Input>
              </div>
            </div>
            <div
              style={{display: "flex", alignItems:"center", gap: "10px"}}>

              <div
                style={{fontWeight: "bolder"}}>
                สั่งทำ x-ray :
              </div>
              <div>

                <Input
                  onChange={props.onOrderXrayChange}
                  value={props.orderXray}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{justifyContent:"space-between", gap: "10px",alignItems: props.useNoteTemplate ? "flex-end" : "center", ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>

            <div
              style={{fontWeight: "bolder"}}>
              คำแนะนำในการปฏิบัติตัวเพิ่มเติม
            </div>
            <div>

              <Button
                color="blue"
                onClick={props.onAddNote}>
                เพิ่มคำแนะนำ
              </Button>
            </div>
          </div>
          <div
            style={{width: "100%", ...(props.hideGeneralDetail ? {display: "none"} : {display: "block"} )}}>

            <Form>
              <label
                style={{fontWeight: "bold", display: props.useNoteTemplate ? "none" : ""}}>
                รายละเอียดการนัด
              </label>
              <TextArea
                onChange={props.setOrderNote}
                style={{width: "100%"}}
                value={props.orderNote}>
              </TextArea>
            </Form>
          </div>
          <div
            id="CardPreOrder"
            style={{width: "100%", display: props.showOrderAppointment? "block": "none"}}>

            <Form>
              <label
                style={{fontWeight: "bold"}}>
                สั่งออเดอร์ล่วงหน้า
              </label>
              <div
                style={{display: "grid", gridTemplateColumns: "auto 82%"}}>

                <div>

                  <div
                    className="ui vertical menu">
                    {props.buttonLists}
                  </div>
                </div>
                <div>

                  <Table
                    data={props.preOrderList || []}
                    headers="ID, Type, Details, Del, Edit"
                    keys="id, specific_type_name, order_detail, del, edit"
                    showPagination={false}
                    style={{height: "200px"}}
                    widths="^70,^100,^150,^70,^70">
                  </Table>
                </div>
              </div>
            </Form>
          </div>
          <div
            className="appointment-button-action"
            style={{display: "flex", gap: "10px", margin: "1rem 0 4rem", justifyContent: "flex-end"}}>

            <div>
              {props.buttonSave}
            </div>
            <div
              style={props.showConfirmButton ? {display: "flex"} : {display: "none"}}>
              {props.confirmButton}
            </div>
            <Button
              className="ignore-prevent"
              color="red"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              onClick={props.onCancelAppointment}
              style={{display: props.config?.hideDoctorCancel ? "none": "flex"}}>
              แพทย์ยกเลิก
            </Button>
            <Button
              className="ignore-prevent"
              color="red"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              onClick={props.onCancelAppointmentByPatient}
              style={{display: props.config?.hidePatientCancel ? "none": "flex"}}>
              ผู้ป่วยยกเลิก
            </Button>
            <Button
              className="ignore-prevent"
              color="orange"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              onClick={props.onPostponeAppointment}
              style={{ ...(props.config?.disabledPostpone ? { display: "none"} : {display: "flex"})}}>
              เลื่อนนัด
            </Button>
            <Button
              className="ignore-prevent"
              color="blue"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              name="TH"
              onClick={props.printAppointment}
              style={{display: props.hidePrintAppointmentDoc? "none": ""}}>
              ใบนัด TH
            </Button>
            <Button
              className="ignore-prevent"
              color="blue"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              name="EN"
              onClick={props.printAppointment}
              style={{display: props.hidePrintAppointmentDoc? "none": ""}}>
              ใบนัด EN
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardAppointmentDetailUX

export const screenPropsDefault = {"manualTime":false}

/* Date Time : Mon Nov 25 2024 17:25:39 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(11, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/11\",\"gridColumn\":\"1/41\",...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"block\"} )}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 19,
      "name": "Table",
      "parent": 18,
      "props": {
        "data": {
          "type": "code",
          "value": "props.blockAppointments"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อคนไข้,เวลาเริ่ม,ระยะเวลา,ผู้ทำนัดหมาย,วันเวลาที่ทำนัดหมาย"
        },
        "keys": {
          "type": "value",
          "value": "patient_name,estimated_at_display,estimated_duration,edit_user_name,updated"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายชื่อคนไข้อื่นๆในคาบเดียวกัน"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/15\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"block\"} )}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\", gap: \"10px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divider"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{justifyContent:\"flex-start\", gap: \"35px\",alignItems: \"center\", ...(props.hideGeneralDetail || props.hideDrugAllergy ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "คำสั่งแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\", margin: \"20px 0px 5px 0px\", ...(props.hideGeneralDetail || props.hideDrugAllergy? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{justifyContent:\"flex-start\", gap: \"35px\",alignItems: \"center\", ...(props.hideGeneralDetail || props.hideDrugAllergy  ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{justifyContent:\"space-between\", gap: \"10px\",alignItems: props.useNoteTemplate ? \"flex-end\" : \"center\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"block\"} )}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "การแพ้ยา : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": "Premedication : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งทำ Lab : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งทำ x-ray : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "คำแนะนำในการปฏิบัติตัวเพิ่มเติม"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Button",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มคำแนะนำ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNote"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Form",
      "parent": 51,
      "props": {
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "TextArea",
      "parent": 86,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.setOrderNote"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.orderNote"
        }
      },
      "seq": 123,
      "void": true
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "appointment-button-action"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\", margin: \"1rem 0 4rem\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ยกเลิก"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAppointment"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideDoctorCancel ? \"none\": \"flex\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "เลื่อนนัด"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPostponeAppointment"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.config?.disabledPostpone ? { display: \"none\"} : {display: \"flex\"})}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบนัด TH"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "name": {
          "type": "value",
          "value": "TH"
        },
        "onClick": {
          "type": "code",
          "value": "props.printAppointment"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePrintAppointmentDoc? \"none\": \"\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", gap: \"10px\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", gap: \"10px\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", gap: \"10px\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", gap: \"10px\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Input",
      "parent": 72,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onAllergyChange"
        },
        "value": {
          "type": "code",
          "value": "props.allergy"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Input",
      "parent": 74,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onPreMedicationChange"
        },
        "value": {
          "type": "code",
          "value": "props.premedication"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Input",
      "parent": 76,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onOrderLabChange"
        },
        "value": {
          "type": "code",
          "value": "props.orderLab"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Input",
      "parent": 78,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onOrderXrayChange"
        },
        "value": {
          "type": "code",
          "value": "props.orderXray"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการนัด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", display: props.useNoteTemplate ? \"none\" : \"\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreOrder"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: props.showOrderAppointment? \"block\": \"none\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "Form",
      "parent": 124,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"auto 82%\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 133,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 133,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 136,
      "name": "Table",
      "parent": 135,
      "props": {
        "data": {
          "type": "code",
          "value": "props.preOrderList || []"
        },
        "headers": {
          "type": "value",
          "value": "ID, Type, Details, Del, Edit"
        },
        "keys": {
          "type": "value",
          "value": "id, specific_type_name, order_detail, del, edit"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        },
        "widths": {
          "type": "value",
          "value": "^70,^100,^150,^70,^70"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLists"
        },
        "className": {
          "type": "value",
          "value": "ui vertical menu"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "label",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งออเดอร์ล่วงหน้า"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยยกเลิก"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAppointmentByPatient"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hidePatientCancel ? \"none\": \"flex\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\", gap: \"10px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showEmergencyContact? \"flex\": \"none\", flexDirection: \"column\", gap: \"10px\", marginRight: \"40px\", width: \"auto\", border: \"1px solid red\", padding: \"10px\", borderRadius: \"10px\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 199,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{justifyContent:\"flex-start\", gap: \"35px\", alignItems: \"center\" , display: \"flex\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\", alignItems:\"center\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "HN: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Input",
      "parent": 155,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedAppointment?.hn || props.selectedAppointment?.patient_hn || \"\""
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล : ",
          "valueEN": "Name  -  Family name:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedAppointment?.patient_name"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทร : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 160,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedAppointment?.patient_tel"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 199,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{justifyContent:\"flex-start\", gap: \"35px\",alignItems: \"center\"}"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\", margin: \"18px 0px\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "พบแพทย์: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedAppointment?.detail"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\", alignItems:\"center\", marginBottom:\"8px\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิก : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "Input",
      "parent": 169,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedAppointment?.division_name"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{justifyContent:\"flex-start\", gap: \"35px\",alignItems: \"center\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems: \"center\", gap: \"10px\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดหมาย : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Input",
      "parent": 174,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "onFocus": {
          "type": "code",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.appointmentedDate"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems: \"center\", gap: \"10px\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลานัดหมาย : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.manualTime ? \"none\" : \"block\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 179,
      "name": "TimeTextBox",
      "parent": 178,
      "props": {
        "availableOptions": {
          "type": "code",
          "value": "props.availableOptions"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "interval": {
          "type": "code",
          "value": "props.intervalTimeSelected"
        },
        "maxHour": {
          "type": "code",
          "value": "props.maxHour"
        },
        "minHour": {
          "type": "code",
          "value": "props.minHour"
        },
        "onTimeChange": {
          "type": "code",
          "value": "props.onTimeAppointmentChange"
        },
        "reservedText": {
          "type": "code",
          "value": "props.reservedText"
        },
        "useDefaultTime": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.estimatedAt"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.manualTime ? \"block\" : \"none\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 181,
      "name": "TimeFreeTextBox24",
      "parent": 180,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "maxHour": {
          "type": "code",
          "value": "props.maxHour"
        },
        "minHour": {
          "type": "code",
          "value": "props.minHour"
        },
        "onChange": {
          "type": "code",
          "value": "(value: any) => props.setEstimatedAt(value)"
        },
        "value": {
          "type": "code",
          "value": "props.estimatedAt"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "Checkbox",
      "parent": 182,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.manualTime"
        },
        "label": {
          "type": "value",
          "value": "กำหนดเวลาแบบ manual"
        },
        "onChange": {
          "type": "code",
          "value": "() => props.setManualTime(!props.manualTime)"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"flex-start\", gap: \"5px\", alignItems: \"center\", flex: 1}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"flex-start\", gap: \"15px\", alignItems: \"center\", flex: 1}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"flex-start\", gap: \"15px\", alignItems: \"center\" , flex: 1}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ติดต่อกรณีฉุกเฉิน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "Icon",
      "parent": 184,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "value",
          "value": "user"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ - สกุล:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "code",
          "value": "props.emergency_contact || \"ไม่ระบุ\""
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทรศัพท์:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 186,
      "props": {
        "children": {
          "type": "code",
          "value": "props.emergency_contact_phone_no || \"-\""
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.confirmButton"
        },
        "style": {
          "type": "code",
          "value": "props.showConfirmButton ? {display: \"flex\"} : {display: \"none\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Form",
      "parent": 198,
      "props": {
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "TextArea",
      "parent": 196,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeJobToDo"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.isShowChair && { display: \"none\" }) }"
        },
        "value": {
          "type": "code",
          "value": "props.jobToDo"
        }
      },
      "seq": 197,
      "void": true
    },
    {
      "from": null,
      "id": 198,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideGeneralDetail ? \"none\":\"flex\",justifyContent:\"flex-start\", gap:\"10px\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 198,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "งานที่ทำ: "
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight:\"bolder\",marginLeft:\"100px\", ...(!props.isShowChair && { display: \"none\" })}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideGeneralDetail ? \"none\":\"flex\",}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\", alignItems:\"center\", ...(!props.isShowChair && { display: \"none\" })}"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "div",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": "เก้าอี้ : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "div",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Input",
      "parent": 209,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedAppointment?.extra?.chair"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบนัด EN"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "name": {
          "type": "value",
          "value": "EN"
        },
        "onClick": {
          "type": "code",
          "value": "props.printAppointment"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePrintAppointmentDoc? \"none\": \"\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "div",
      "parent": 206,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "FormGroup",
      "parent": 214,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "FormField",
      "parent": 215,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "FormField",
      "parent": 215,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormField",
      "parent": 215,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 215,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "label",
      "parent": 216,
      "props": {
        "children": {
          "type": "value",
          "value": "ระยะเวลา (นาที) :"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "label",
      "parent": 218,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "label",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดเพื่อ:"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "Input",
      "parent": 216,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "min": {
          "type": "code",
          "value": "1"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any) => props.setEstimatedDuration(e.target.value)"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.estimatedDuration"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "Checkbox",
      "parent": 217,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.full"
        },
        "label": {
          "type": "value",
          "value": "บันทึกว่าคาบนี้เต็มแล้ว"
        },
        "onChange": {
          "type": "code",
          "value": "props.toggleFull"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "Dropdown",
      "parent": 218,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentType"
        },
        "options": {
          "type": "code",
          "value": "props.treatmentTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.treatmentType"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "Dropdown",
      "parent": 219,
      "props": {
        "allowAdditions": {
          "type": "value",
          "value": "true"
        },
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onAddItem": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAppointmentFor"
        },
        "options": {
          "type": "code",
          "value": "props.appointmentForNoteOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.appointmentFor"
        }
      },
      "seq": 228,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAppointmentDetailUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "manualTime": false
  },
  "width": 75
}

*********************************************************************************** */
