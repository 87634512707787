import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardOPDLabSummaryUX = (props: any) => {
    return(
      <div
        style={{width: "100%", padding: "15px 10px"}}>
        <div>
          
          <label
            style={{fontSize: "1.5rem", fontWeight: "bold"}}>
            {props.title || ""}
          </label>
        </div>
        <div
          className="ui form"
          style={{paddingTop: "2rem"}}>
          
          <div
            className="inline fields"
            style={{display: props.config?.hideClinicalSummary ? "none" : ""}}>
            
            <FormField
              inline={true}
              width={13}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Button
                color="teal"
                disabled={props.config?.disabledClinicalSummary || false}
                fluid={true}
                onClick={props.onClinicalSummary}>
                Clinical summary
              </Button>
            </FormField>
          </div>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "bold", width: "110px"}}>
                Date (Encounter)
              </label>
            </FormField>
            <FormField
              inline={true}
              width={16}>
              <Dropdown
                clearable={props.config?.showFromDate || false}
                name="encounterId"
                onChange={props.onChangeValue}
                options={props.encounterOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.encounterId || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <div
            className="inline fields"
            style={{display: props.config?.showFromDate ? "" : "none"}}>
            
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "bold",width: "110px"}}>
                From
              </label>
            </FormField>
            <FormField
              inline={true}
              width={16}>
              <Dropdown
                clearable={true}
                name="fromEncounterId"
                onChange={props.onChangeValue}
                options={props.encounterOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.fromEncounterId || ""}>
              </Dropdown>
            </FormField>
          </div>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "bold",width: "110px"}}>
                Lab Division
              </label>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <Dropdown
                multiple={props.multipleLabDivision|| false}
                name="divisionId"
                onChange={props.onChangeValue}
                options={props.labDivisionOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.divisionId}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{width: "100%", textAlign: "center"}}>
                Lab Name
              </label>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <Dropdown
                multiple={props.multipleLabName || false}
                name="labName"
                onChange={props.onChangeValue}
                options={props.labNameOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.labName}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.labResultList || []}
            getTdProps={props.getTdProps}
            getTheadThProps={props.getTheadThProps}
            headers={`Code,Lab Name,Reference value, Unit,Result ${props.encounterDate ? `[${props.encounterDate}]`:""},Previous Result`}
            keys="code, lab_name, ref_value,unit,result, prev_result"
            showPagination={false}
            style={{height: "600px", display: props.cumulativeLabTable ? "none" : ""}}>
          </Table>
          <div>
            {props.cumulativeLabTable}
          </div>
        </div>
      </div>
    )
}

export default CardOPDLabSummaryUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Fri Apr 07 2023 18:25:20 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", padding: \"15px 10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title || \"\""
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.5rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"2rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "Date (Encounter)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"110px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "clearable": {
          "type": "code",
          "value": "props.config?.showFromDate || false"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "encounterId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.encounterOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.encounterId || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Division"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",width: \"110px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Name"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"center\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "multiple": {
          "type": "code",
          "value": "props.multipleLabDivision|| false"
        },
        "name": {
          "type": "value",
          "value": "divisionId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.labDivisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.divisionId"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "multiple": {
          "type": "code",
          "value": "props.multipleLabName || false"
        },
        "name": {
          "type": "value",
          "value": "labName"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.labNameOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.labName"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 25,
      "name": "Table",
      "parent": 24,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labResultList || []"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.getTheadThProps"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "code",
          "value": "`Code,Lab Name,Reference value, Unit,Result ${props.encounterDate ? `[${props.encounterDate}]`:\"\"},Previous Result`"
        },
        "keys": {
          "type": "value",
          "value": "code, lab_name, ref_value,unit,result, prev_result"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"600px\", display: props.cumulativeLabTable ? \"none\" : \"\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline fields"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideClinicalSummary ? \"none\" : \"\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Button",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "Clinical summary"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledClinicalSummary || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClinicalSummary"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline fields"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showFromDate ? \"\" : \"none\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "From"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",width: \"110px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "fromEncounterId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.encounterOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.fromEncounterId || \"\""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cumulativeLabTable"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 36,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardOPDLabSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
