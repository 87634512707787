import WasmController from 'react-lib/frameworks/WasmController';
import Scheduling from "issara-sdk/apis/Scheduling_apps_QUE";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import { serialToDate } from 'react-lib/apps/Scheduling/common/Utils';

export type State = 
  {
    selectedPatient?: any,
    selectedDivision?: any,
    selectedEncounter?: any,
    SetAppointmentSequence?: {
      sequenceIndex: string | null,
      selectedDivision?: any,
      appointmentList?: any[]
      selectedAppointment?: any,
      blockList?: any[],
    } | null,
  }

export const StateInitial: State = 
  {
    SetAppointmentSequence: null,
  }

export type Event = 
  { message: "RunSequence", params: {} }

export type Data = 
  {
    division?: number,
    divisionDict?: any,
    device?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetAppointmentSequence) return

  const appointmentList = await RefreshAppointment(controller, params);
  const blockList = await RefreshDsb(controller, params);

  // Set state and go to the next step
  controller.setState({
    SetAppointmentSequence: {
      ...state.SetAppointmentSequence,
      sequenceIndex: "Edit",
      selectedDivision: state.selectedDivision,
      appointmentList: appointmentList,
      blockList: blockList,
    }
  });
}

export const Edit: Handler = async (controller, params) => {
  if (params?.restart) return await Restart(controller, params);
  const state = controller.getState();
  if (!state.SetAppointmentSequence) return
  if (params?.action === "appointment" && params?.item) {
    console.log(params.item);
    controller.setState({
      SetAppointmentSequence: {
        ...state.SetAppointmentSequence,
        selectedAppointment: params.item
      }
    });
  } else if (params?.action === "block" && params?.item) {
    if (state.SetAppointmentSequence?.selectedAppointment?.id) {
      // Update
      const update = await PatientAppointmentUpdate.patch({
        pk: state.SetAppointmentSequence?.selectedAppointment?.id,
        data: { division_service_block: params.item.dsb_id },
        apiToken: controller.apiToken
      });
      console.log(update[1] ? update[1] : update[0]);
      const appointmentList = await RefreshAppointment(controller, params);
      controller.setState({
        SetAppointmentSequence: {
          ...state.SetAppointmentSequence,
          appointmentList: appointmentList
        }
      });
    } else {
      const appointment = await PatientAppointmentView.create({
        data: {
          patient: state.selectedPatient?.id,
          division: state.selectedDivision?.id, 
          provider: params.item.provider_id,
          division_service_block: params.item.dsb_id,
          encounter_id: state.selectedEncounter?.id,
        } as any,
        apiToken: controller.apiToken
      });
      console.log(appointment[1] ? appointment[1] : appointment[0]);
      const appointmentList = await RefreshAppointment(controller, params);
      controller.setState({
        SetAppointmentSequence: {
          ...state.SetAppointmentSequence,
          appointmentList: appointmentList
        }
      });
    }
  }
}

// Utilities ===================================================
const Restart: Handler = async (controller, params) => {
  controller.setState({
    SetAppointmentSequence: {
      sequenceIndex: "START"
    }
  }, 
  () => controller.handleEvent(
    {message: "RunSequence", params: {sequence: "SetAppointment"}}))
}

const RefreshAppointment: Handler = async (controller, params) => {
  const state = controller.getState();
  // Get appointment
  const appointment = await PatientAppointmentView.list({
    params: { patient_id: state.selectedPatient?.id },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  console.log(appointment[1] ? appointment[1] : appointment[0]);
  const appointmentList = (appointment[0]?.items || []).map((item: any) => ({
    ...item,
    start_datetime: item.display_info?.start_datetime || "",
  }));
  return appointmentList
}

const RefreshDsb: Handler = async (controller, params) => {
  const state = controller.getState();
  // Get dsb
  const dsb = await Scheduling.list({
    command: "DivisionServiceBlockFilter",
    params: { divisions: [state.selectedDivision?.id] },
    apiToken: controller.apiToken
  });
  console.log(dsb[1] ? dsb[1] : dsb[0]);
  const blockList = (dsb[0] || []).map((item: any, index: number) => { 
    return {
      ...item,
      id: index,
      start: serialToDate(item.start_serial),
      end: serialToDate(item.end_serial),
      title: `${item.start_time} - ${item.end_time}`
    }
  });
  return blockList
}