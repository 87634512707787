import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { FormGroup, FormField, Icon, Dropdown, TextArea } from "semantic-ui-react";

const SubDrugDiseaseISegmentResultDetailUX = (props: any) => {
  return (
    <div>
      <div
        className="ui form"
        style={{ padding: "15px 5px 5px 25px", ...(props.contentStyle || {}) }}
      >
        <FormGroup inline={true}>
          <FormField inline={true}>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "" }}>
              <label style={{ fontWeight: "bold" }}>Underlying disease group</label>
              <label style={{ color: "red" }}>*</label>
              <label style={{ paddingLeft: "2.5px" }}>:</label>
            </div>
          </FormField>
          <FormField inline={true} width={4}>
            <Dropdown
              clearable={true}
              fluid={true}
              name="segment_group_id"
              onChange={props.onChangeValue}
              options={props.segmentGroupOptions || []}
              search={true}
              selection={true}
              style={{ width: "100%" }}
              value={props.segmentGroup || ""}
            ></Dropdown>
          </FormField>
          <FormField inline={true}>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "" }}>
              <label style={{ fontWeight: "bold" }}>Subgroup</label>
              <label style={{ color: "red" }}>*</label>
              <label style={{ paddingLeft: "2.5px" }}>:</label>
            </div>
          </FormField>
          <FormField inline={true} width={4}>
            <Dropdown
              clearable={true}
              disabled={props.disabledSubgroup || false}
              fluid={true}
              name="sub_segment_group_id"
              onChange={props.onChangeValue}
              options={props.subSegmentGroupOptions || []}
              search={true}
              selection={true}
              style={{ width: "100%" }}
              value={props.subSegmentGroup || ""}
            ></Dropdown>
          </FormField>
        </FormGroup>
        <FormGroup inline={true}>
          <FormField inline={true} width={3}>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "" }}>
              <label style={{ fontWeight: "bold" }}>ข้อความแจ้งเตือน</label>
              <label style={{ color: "red" }}></label>
              <label style={{ paddingLeft: "2.5px" }}>:</label>
            </div>
          </FormField>
          <FormField inline={true} width={12}>
            <TextArea
              name="message"
              onChange={props.onChangeValue}
              readOnly={props.readOnly || false}
              rows={8}
              value={props.message || ""}
            ></TextArea>
          </FormField>
          <FormField width={1}>
            <div style={{ cursor: "pointer" }}>
              <Icon className="red large" name="trash alternate" onClick={props.onRemove}></Icon>
            </div>
          </FormField>
        </FormGroup>
      </div>
    </div>
  );
};

SubDrugDiseaseISegmentResultDetailUX.displayName = "SubDrugDiseaseISegmentResultDetailUX";
export default React.memo(SubDrugDiseaseISegmentResultDetailUX);

export const screenPropsDefault = {};

/* Date Time : Thu Nov 14 2024 09:44:57 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px 5px 5px 25px\", ...(props.contentStyle || {})}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อความแจ้งเตือน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"2.5px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "Underlying disease group"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"2.5px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 5,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Icon",
      "parent": 34,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "value",
          "value": "trash alternate"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRemove"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "segment_group_id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.segmentGroupOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.segmentGroup || \"\""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 39,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledSubgroup || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "sub_segment_group_id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.subSegmentGroupOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.subSegmentGroup || \"\""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Subgroup"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"2.5px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "TextArea",
      "parent": 10,
      "props": {
        "name": {
          "type": "value",
          "value": "message"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "rows": {
          "type": "code",
          "value": "8"
        },
        "value": {
          "type": "code",
          "value": "props.message || \"\""
        }
      },
      "seq": 44,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": true,
  "name": "SubDrugDiseaseISegmentResultDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
