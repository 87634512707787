import React, {
  CSSProperties,
  Ref,
  SyntheticEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import { Checkbox, Icon, Label, Pagination, Popup } from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModLockExpense from "react-lib/apps/HISV3/BIL/ModLockExpense";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import ActionButton from "./ActionButton";
import CardImagingWorkListUX from "./CardImagingWorkListUX";
import ModExecuteRadiologyOrder from "./ModExecuteRadiologyOrder";
import ModImagingAuditLog from "./ModImagingAuditLog";
import ModalGallery from "./ModalGallery";
import ModCancelImagingOrder, { ApproveDataType } from "./ModCancelImagingOrder";
import ModXrayDetail, { ButtonReadyToView } from "./ModXrayDetail";

// Interface
import { State as OrderState } from "./sequence/ImagingOrder";
import {
  ActionStatusType,
  BILLING_STATUS,
  GenderType,
  ImagingQueueSerializer,
  ORDER_STATUS,
  ReportActionStatusType,
} from "./sequence/ImagingHandler";
import {
  BILLING_STATUS_COLORS,
  BILLING_STATUS_OPTIONS,
  ENCOUNTER_TYPE_OPTIONS,
  MasterOptionsType,
  ORDER_STATUS_COLORS,
  ORDER_STATUS_OPTIONS,
  OrderItemUpdateStatus,
  RADIOLOGIST_SEARCH_ID,
  RunSequence,
  State,
  WORK_LIST_LIMIT,
  checkReadyToView,
} from "./sequence/ImagingWorkList";

import CONFIG from "config/config";
import { useIntl } from "react-intl";

// Const
const PATIENT_SEARCH_ID = "Patient_IWL";

// Types
type CardImagingWorkListProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // seq
  runSequence: RunSequence;
  ImagingWorkListSequence: State["ImagingWorkListSequence"];
  // data
  django?: {
    authenticated: true;
    csrf: string;
    division: number;
    user: {
      id: number;
      full_name: string;
      role_types: string[];
      token: string;
    };
  };
  doctorDetail?: { id: number; name_code: string };
  modXrayDetail: OrderState["modXrayDetail"];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  userTokenize?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;

  // callback
  forward?: () => any;

  // Dental disgram
  clinicalFindingId?: number | null;
  clinicalFindingIndex?: number | null;
  clinicalFindings: Record<string, any>[];
  clinicaltags?: { items: any[]; name: string };
  filterClinicalFindingIds: number[];
  organ?: { items: any[]; name: string };
  // ----- #
};

export type CardImagingWorkListRef = {
  refresh: () => void;
};

type GoToEncounterHandler = (
  item: { readOnly?: boolean } & ImagingQueueSerializer,
  menu: "Imaging Order" | "Imaging Result"
) => any;

type ReadyToViewHandler = (
  data: {
    id: number;
    image_id?: number | null;
    image_url?: string;
    ready_to_view: boolean;
    onSuccess?: () => any;
  },
  buttonAction?: string
) => () => void;

type UpdateStatusHandler = (
  data: {
    action: ActionStatusType | ReportActionStatusType;
    index?: number;
    orderItems: OrderItemUpdateStatus[];
    onSuccess?: () => any;
  },
  buttonAction?: string
) => any;

type RegisterHandler = (data: { index: number; orderItems: { id: number }[] }) => any;

type ModActionOrderType = {
  action: "Approve" | "UNEXECUTE" | "Unapprove";
  authUsrPass?: boolean;
  backgroundColor?: CSSProperties["backgroundColor"];
  data: any;
  hideReason?: boolean;
  infoOnly?: boolean;
  orderStatus: "APPROVED" | "REGISTERED" | "reported";
  title?: string;
};

// Const
const IMAGES = {
  comment_green: "/static/images/x-ray/comment-outline-green.png",
  comment_grey: "/static/images/x-ray/comment-outline-grey.png",
  female: "/static/images/x-ray/female.png",
  male: "/static/images/x-ray/male.png",
};

const GENDER_ICONS = {
  F: {
    alt: "blue female symbol",
    src: IMAGES.female,
    style: { marginLeft: "0.75rem", width: "10px" },
  },
  M: {
    alt: "blue male symbol",
    src: IMAGES.male,
    style: { marginLeft: "0.65rem", width: "13px" },
  },
};

const BUTTON_ACTIONS = {
  approve: "APPROVE_RESULT",
  open_pacs: "OPEN_PACS",
  ready_to_view: "READY_TO_VIEW",
  register: "REGISTER",
  search: "SEARCH",
  unregister: "UNREGISTER",
  update_status: "UPDATE_STATUS",
} as const;

const GridCenter = {
  display: "grid",
  placeContent: "center",
  textAlign: "center",
} as CSSProperties;

const CARD_IMAGING_WORK_LIST = "CardImagingWorkList";

const CardImagingWorkList = (props: CardImagingWorkListProps, ref: Ref<any>) => {
  const intl = useIntl();
  const [modActionOrder, setModActionOrder] = useState<ModActionOrderType | null>(null);
  const [modExecute, setModExecute] = useState<{
    orderItemIds: number[];
  } | null>(null);
  const [modAuditLog, setModAuditLog] = useState<ImagingQueueSerializer | null>(null);

  const [popupIndex, setPopupIndex] = useState<number>(-1);

  const [checkedItems, setCheckedItems] = useState<ImagingQueueSerializer[]>([]);

  // Ref
  useImperativeHandle<CardImagingWorkListRef, CardImagingWorkListRef>(ref, () => ({
    refresh: () => {
      const page = props.ImagingWorkListSequence?.orderQueue?.activePage || 1;

      handleSearch(page, true);
    },
  }));

  // Effect
  useEffect(() => {
    const userId = props.django?.user.id;

    // * restart เมื่อเข้ามาครั้งแรก
    if (props.ImagingWorkListSequence?.userId === userId) {
      handleSearch(props.ImagingWorkListSequence?.orderQueue?.activePage || 1);
    } else {
      props.runSequence({
        sequence: "ImagingWorkList",
        card: CARD_IMAGING_WORK_LIST,
        doctorDetail: props.doctorDetail,
        restart: true,
      });
    }
  }, []);

  useEffect(() => {
    const items = props.ImagingWorkListSequence?.orderQueue?.items || [];

    const filterCheckedItems = (checkedItem: ImagingQueueSerializer) =>
      items.some((item) => item.id === checkedItem.id && item.status !== checkedItem.status)
        ? []
        : [checkedItem];

    setCheckedItems((prevCheckedItems) =>
      prevCheckedItems.flatMap((element) => filterCheckedItems(element))
    );
  }, [props.ImagingWorkListSequence?.orderQueue?.items]);

  // Memo callback
  const orderList = useMemo(
    () => props.ImagingWorkListSequence?.orderQueue?.items || [],
    [props.ImagingWorkListSequence?.orderQueue]
  );

  // callback
  const handleSelectedItem = useCallback(
    (data: any) => async (value: any) => {
      props.setProp(`ImagingWorkListSequence.${data.name}`, value || null);
    },
    [props.searchedItemListWithKey]
  );

  const handleOpenModAuditLog = useCallback(
    (item: ImagingQueueSerializer) => () => {
      props.runSequence({
        sequence: "ImagingWorkList",
        action: "GET_AUDIT_LOG",
        orderItemId: item.id,
      });

      setModAuditLog(item);
      setPopupIndex(-1);
    },
    []
  );

  const handleOpenModDetail = useCallback(
    (data: any) => (e: SyntheticEvent) => {
      e.stopPropagation();

      props.runSequence({
        sequence: "ImagingWorkList",
        action: "MOD_XRAY",
        data,
        type: "OPEN",
      });
    },
    []
  );

  const handleGotoEncounter: GoToEncounterHandler = useCallback(
    async (item, menu) => {
      props.runSequence({
        sequence: "ImagingWorkList",
        action: "GO_TO_ENCOUNTER",
        forward: props.forward,
        item,
        menu,
      });
    },
    [props.masterOptions?.division]
  );

  const handleChecked = useCallback(
    (item: ImagingQueueSerializer) => (e: any, data: any) => {
      setCheckedItems((checked) =>
        data.checked ? [...checked, item] : checked.filter((value) => value.id !== item.id)
      );
    },
    []
  );

  const handleRegister: RegisterHandler = useCallback(
    async (data) => {
      const enIds = data.orderItems.map(
        (item) => orderList.find((acc) => acc.id === item.id)?.encounter_id as number | undefined
      );

      const isLocked = await props.onEvent({
        message: "CheckEncounterLocker",
        params: { ids: [...new Set(enIds.filter(Boolean))] },
      });

      if (isLocked) {
        return;
      }

      handleUpdateStatus({
        action: "REGISTER",
        index: data.index,
        orderItems: data.orderItems,
      });
    },
    [orderList]
  );

  const handleUnregister: RegisterHandler = useCallback((data) => {
    handleUpdateStatus({
      action: "UNREGISTER",
      index: data.index,
      orderItems: data.orderItems,
    });
  }, []);

  const handleClickPacs = useCallback((data: ImagingQueueSerializer, index: number) => {
    props.runSequence({
      sequence: "ImagingWorkList",
      action: "OPEN_PACS",
      card: CARD_IMAGING_WORK_LIST,
      data,
      index,
    });
  }, []);

  const handleReadyToView: ReadyToViewHandler = useCallback(
    (data, buttonAction) => () => {
      const action: "READY_TO_VIEW" | "UNREADY_TO_VIEW" = data.ready_to_view
        ? "UNREADY_TO_VIEW"
        : "READY_TO_VIEW";

      const detail = {
        action,
        index: data.id,
        orderItems: [
          {
            id: data.id,
            image_id: data.image_id,
            image_url: data.image_url,
            ready_to_view: !data.ready_to_view,
          },
        ],
        onSuccess: data.onSuccess,
      };

      handleUpdateStatus(detail, buttonAction);
    },
    []
  );

  const handleGetTdProps = useCallback((state: any, rowInfo?: RowInfo, column?: Column) => {
    const original = rowInfo?.original;
    // #console.log('handleGetTdProps original: ', original);

    return {
      style:
        original?.urgency === "STAT" &&
        [
          "datetime",
          "division_name",
          "doctor_name",
          "order_no",
          "patient",
          "request",
          "type",
        ].includes(column?.id || "")
          ? { color: "red", fontWeight: "bold" }
          : {},
    };
  }, []);

  const isCheckAll = useMemo(() => {
    const list = orderList.filter((item) =>
      ([ORDER_STATUS.REGISTERED, ORDER_STATUS.REQUESTED] as string[]).includes(
        ORDER_STATUS[item.status]
      )
    );

    return checkedItems.length > 0 && checkedItems.length === list.length;
  }, [checkedItems, orderList]);

  const checkedRegisterItems = useMemo(
    () =>
      orderList.filter(
        (item) =>
          ORDER_STATUS[item.status] === ORDER_STATUS.REQUESTED &&
          checkedItems.find((acc) => acc.id === item.id)
      ),
    [checkedItems, orderList]
  );

  const checkedExecuteItems = useMemo(
    () =>
      orderList.filter(
        (item) =>
          ORDER_STATUS[item.status] === ORDER_STATUS.REGISTERED &&
          checkedItems.find((acc) => acc.id === item.id)
      ),
    [checkedItems, orderList]
  );

  const allowedReadyToView = useMemo(() => {
    const detail = props.modXrayDetail;

    return (
      !!detail?.status &&
      !!detail.payment_status &&
      checkReadyToView(ORDER_STATUS[detail.status], detail.payment_status)
    );
  }, [props.modXrayDetail]);

  const orderItems = useMemo(
    () =>
      orderList.map((item, index) => {
        const icon =
          item.patient_gender in GENDER_ICONS
            ? GENDER_ICONS[item.patient_gender as GenderType]
            : undefined;
        const status = ORDER_STATUS[item.status];

        return {
          ...item,
          action: (
            <div style={{ ...GridCenter, height: "100%", position: "relative" }}>
              <Popup
                open={popupIndex === index}
                position="bottom right"
                pinned
                on="click"
                // callback
                onClose={() => {
                  setPopupIndex(-1);
                }}
                // Element
                content={
                  <>
                    <div
                      aria-hidden="true"
                      onClick={() => {
                        handleGotoEncounter(item, "Imaging Order");
                      }}
                      style={{
                        borderBottom: "1px solid rgba(0,0,0,0.15)",
                        cursor: "pointer",
                        padding: "0 0 11px",
                      }}
                    >
                      Imaging Order
                    </div>
                    <div
                      aria-hidden="true"
                      style={{ cursor: "pointer", padding: "9px 0 1px" }}
                      onClick={handleOpenModAuditLog(item)}
                    >
                      Audit Log
                    </div>
                  </>
                }
                trigger={
                  <Icon
                    name="ellipsis vertical"
                    onClick={() => {
                      setPopupIndex(index);
                    }}
                    style={{
                      cursor: "pointer",
                      fontSize: "0.85rem",
                      position: "absolute",
                      right: "-7px",
                      top: "-3px",
                    }}
                  />
                }
              />

              <RenderActionSection
                data={item}
                index={index}
                status={status}
                // CommonInterface
                buttonLoadCheck={props.buttonLoadCheck}
                onClickPacs={handleClickPacs}
                // callback
                onGoToEncounter={handleGotoEncounter}
                onRegister={handleRegister}
                onSetModActionOrder={setModActionOrder}
                onSetModExecute={setModExecute}
                onUnregister={handleUnregister}
              />
            </div>
          ),
          check: (
            <div style={GridCenter}>
              {([ORDER_STATUS.REGISTERED, ORDER_STATUS.REQUESTED] as string[]).includes(status) && (
                <Checkbox
                  id={`CardImagingWorkList-Checkbox-OrderSelected-${index}`}
                  checked={!!checkedItems.some((acc) => acc.id === item.id)}
                  onChange={handleChecked(item)}
                />
              )}
            </div>
          ),
          datetime: <div style={GridCenter}>{item.created}</div>,
          division_name: <div style={GridCenter}>{item.order_division}</div>,
          doctor_name: <div style={GridCenter}>{item.care_provider}</div>,
          order_no: <div style={GridCenter}>{item.order_number}</div>,
          patient: (
            <div style={{ lineHeight: 1.75 }}>
              <div>{item.patient_name}</div>
              <div>HN: {item.patient_hn}</div>
              <div style={{ alignItems: "center", display: "flex" }}>
                <div>DOB: {item.patient_dob}</div>
                {!!icon && (
                  <img
                    alt={icon.alt}
                    src={icon.src}
                    style={{
                      ...icon.style,
                      height: "100%",
                      marginBottom: "1px",
                    }}
                  />
                )}
              </div>
            </div>
          ),
          radiologist: (
            <div style={GridCenter}>
              <RenderRadiologistStatus
                data={item}
                django={props.django}
                // CommonInterface
                doctorDetail={props.doctorDetail}
                status={status}
                buttonLoadCheck={props.buttonLoadCheck}
                // callback
                onGoToEncounter={handleGotoEncounter}
                onSetModActionOrder={setModActionOrder}
              />
            </div>
          ),
          request: (
            <div style={{ height: "100%", position: "relative" }}>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 30px" }}>
                <div>{item.product_name}</div>
                <div>
                  <img
                    alt={item.note ? "green speech bubble" : "grey speech bubble"}
                    aria-hidden="true"
                    src={item.note ? IMAGES.comment_green : IMAGES.comment_grey}
                    onClick={handleOpenModDetail(item)}
                    // style
                    style={{
                      cursor: "pointer",
                      marginLeft: "3px",
                      width: "25px",
                    }}
                  />
                </div>
              </div>

              <ButtonReadyToView
                active={item.ready_to_view}
                checkboxSx={{ "& .MuiSvgIcon-root": { fontSize: "1.25rem" } }}
                hideLabel
                // disabled={!checkReadyToView(item.status, item.payment_status)}
                loading={
                  !!props.buttonLoadCheck?.[
                    `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.ready_to_view}_${item.id}`
                  ]
                }
                // style
                style={{
                  cursor: "auto",
                  float: "right",
                  margin: "-4px -4px 0 0",
                  transform: "scale(0.75)",
                }}
                // callback
                // onClick={handleReadyToView(item)}
              />
            </div>
          ),
          status: (
            <div style={GridCenter}>
              {(
                [
                  ["Item", status, ORDER_STATUS_COLORS],
                  ["Billing", BILLING_STATUS[item.payment_status], BILLING_STATUS_COLORS],
                ] as const
              ).map((acc) => (
                <div key={`status-${acc[0]}`} style={{ display: "flex", marginBottom: "0.5rem" }}>
                  <div style={{ textAlign: "left", width: "44px" }}>{acc[0]}: </div>
                  <Label
                    style={{
                      backgroundColor: (acc[2] as any)[acc[1]],
                      color: "white",
                      fontSize: "0.7rem",
                      marginLeft: "0.5rem",
                      textAlign: "center",
                      width: "75px",
                    }}
                  >
                    {acc[1]}
                  </Label>
                </div>
              ))}
            </div>
          ),
          type: <div style={GridCenter}>{item.group_name}</div>,
        };
      }),
    [
      checkedItems,
      orderList,
      popupIndex,
      props.buttonLoadCheck,
      props.django,
      props.doctorDetail,
      props.masterOptions?.division,
    ]
  );

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;
    const { name } = data;

    props.setProp(`ImagingWorkListSequence.filter.${name}`, value);
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleUpdateStatus: UpdateStatusHandler = (data, buttonAction) => {
    props.runSequence({
      sequence: "ImagingWorkList",
      action: "UPDATE_STATUS",
      actionType: data.action,
      btnAction: buttonAction,
      card: CARD_IMAGING_WORK_LIST,
      index: data.index,
      orderItems: data.orderItems,
      onSuccess: data.onSuccess,
    });
  };

  const handleConfirmActionOrder = (data: ApproveDataType) => {
    if (modActionOrder && ["Approve", "UNEXECUTE", "Unapprove"].includes(modActionOrder.action)) {
      handleUpdateStatus({
        action: modActionOrder.action.toUpperCase() as any,
        orderItems: [
          {
            id: modActionOrder.data.id,
            password: data.password || undefined,
            username: data.username || undefined,
          },
        ],
        onSuccess: () => {
          handleCloseModActionOrder();

          data.onClear?.();
        },
      });
    }
  };

  const handleCloseModActionOrder = () => {
    setModActionOrder(null);
  };

  const handleCloseModExecute = () => {
    setModExecute(null);
  };

  const handleCloseModLog = () => {
    setModAuditLog(null);

    props.setProp("ImagingWorkListSequence.auditLogList", []);
  };

  const handleCloseModXray = () => {
    props.runSequence({
      sequence: "ImagingWorkList",
      action: "MOD_XRAY",
      type: "CLOSE",
    });
  };

  const handleSearch = (page: number, noLoading = false) => {
    setCheckedItems([]);

    props.runSequence({
      sequence: "ImagingWorkList",
      action: "SEARCH",
      activePage: page,
      card: noLoading ? "" : CARD_IMAGING_WORK_LIST,
    });
  };

  const handleCheckedAll = (e: any, data: any) => {
    const list = orderList.filter((item) =>
      ([ORDER_STATUS.REGISTERED, ORDER_STATUS.REQUESTED] as string[]).includes(
        ORDER_STATUS[item.status]
      )
    );

    setCheckedItems(data.checked ? [...list] : []);
  };

  const handleOpenModExecute = () => {
    if (checkedExecuteItems.length > 0) {
      setModExecute({
        orderItemIds: checkedExecuteItems.map((item) => item.id as number),
      });
    }
  };

  const handleClickRegister = () => {
    if (checkedRegisterItems.length > 0) {
      handleRegister({
        index: -1,
        orderItems: checkedRegisterItems.map((item) => ({ id: item.id })),
      });
    }
  };

  const handlePageChange = (e: any, data: any) => {
    handleSearch(data.activePage);
  };

  const handleCloseModalGallery = () => {
    props.setProp("ImagingWorkListSequence.pacsGalleryDetail", null);
  };

  const handleSaveXray = (inputData: any, buttonAction?: string) => {
    const detail = props.modXrayDetail;

    const isChanged =
      detail?.ready_to_view !== inputData?.ready_to_view ||
      detail?.image_url !== inputData?.image_url;

    if (detail && isChanged) {
      const updatedData = {
        id: inputData.id,
        image_id: inputData.image_id,
        image_url: inputData.image_url,
        ready_to_view: !inputData.ready_to_view,
        onSuccess: handleCloseModXray,
      };

      handleReadyToView(updatedData, buttonAction)();
    } else {
      handleCloseModXray();
    }
  };

  // #console.log("CardImagingWorkList props: ", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_IMAGING_WORK_LIST]}
        success={null}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_IMAGING_WORK_LIST}`, null);
        }}
        languageUX={props.languageUX}
      />

      <CardImagingWorkListUX
        filter={props.ImagingWorkListSequence?.filter}
        // data
        orderList={orderItems}
        billingStatusOptions={BILLING_STATUS_OPTIONS}
        // options
        divisionOptions={props.masterOptions?.division}
        encounterTypeOptions={ENCOUNTER_TYPE_OPTIONS}
        imagingTestGroupOptions={props.masterOptions?.imagingTestGroup}
        orderStatusOptions={ORDER_STATUS_OPTIONS}
        // callback
        onChangeDate={handleChangeDate}
        onChangeValue={handleChangeValue}
        onGetTdProps={handleGetTdProps}
        // Element
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            // data
            paramKey={`${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.search}`}
            size="medium"
            style={{ minWidth: "max-content" }}
            title={intl.formatMessage({ id: "ค้นหา" })}
            fluid
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.search}`]
            }
            onClick={() => {
              handleSearch(1);
            }}
          />
        }
        IconExecute={
          <ActionButton
            badgeContent={checkedExecuteItems.length}
            icon="execute"
            onClick={handleOpenModExecute}
          />
        }
        IconRegister={
          <ActionButton
            badgeContent={checkedRegisterItems.length}
            icon="register"
            onClick={handleClickRegister}
            loading={
              !!props.buttonLoadCheck?.[
                `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.register}_${-1}`
              ]
            }
          />
        }
        Pagination={
          <Pagination
            activePage={props.ImagingWorkListSequence?.orderQueue?.activePage || 0}
            nextItem={{ icon: true, content: <Icon name="angle right" /> }}
            prevItem={{ icon: true, content: <Icon name="angle left" /> }}
            size="mini"
            // callback
            onPageChange={handlePageChange}
            ellipsisItem={{
              icon: true,
              content: <Icon name="ellipsis horizontal" />,
            }}
            firstItem={{
              icon: true,
              content: <Icon name="angle double left" />,
            }}
            lastItem={{
              icon: true,
              content: <Icon name="angle double right" />,
            }}
            totalPages={Math.ceil(
              (props.ImagingWorkListSequence?.orderQueue?.total || 0) / WORK_LIST_LIMIT
            )}
          />
        }
        SearchBoxOrderNo={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            id="IWL"
            icon="search"
            limit={20}
            selectedItem={props.ImagingWorkListSequence?.filter?.orderNo || null}
            style={{ width: "100%" }}
            // config
            type="SearchType"
            fluid
            useWithKey
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            setSelectedItem={handleSelectedItem({
              id: PATIENT_SEARCH_ID,
              name: "filter.patient",
            })}
          />
        }
        SearchBoxPatient={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            id="IWL"
            icon="search"
            limit={20}
            selectedItem={props.ImagingWorkListSequence?.filter?.patient || null}
            style={{ width: "100%" }}
            // config
            type="Patient"
            fluid
            useWithKey
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            setSelectedItem={handleSelectedItem({
              id: PATIENT_SEARCH_ID,
              name: "filter.patient",
            })}
          />
        }
        SearchBoxRadiologist={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            id="IWL"
            icon="search"
            limit={20}
            selectedItem={props.ImagingWorkListSequence?.filter?.radiologist || null}
            style={{ width: "100%" }}
            // config
            type="Doctor"
            fluid
            inline
            useWithKey
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            setSelectedItem={handleSelectedItem({
              id: RADIOLOGIST_SEARCH_ID,
              name: "filter.radiologist",
            })}
          />
        }
        // table
        headers={[
          <Checkbox key="checkbox" checked={isCheckAll} onChange={handleCheckedAll} />,
          ..."Order date/Time,Order No.,Patient,Order department,Careprovider,Type,Request,Status,Radiologist,".split(
            ","
          ),
        ]}
        keys={
          "check,datetime,order_no,patient,division_name,doctor_name,type,request,status,radiologist,action"
        }
        languageUX={props.languageUX}
      />

      <ModCancelImagingOrder
        onEvent={props.onEvent}
        setProp={props.setProp}
        action={modActionOrder?.action || ""}
        authUsrPass={modActionOrder?.authUsrPass}
        backgroundColor={modActionOrder?.backgroundColor}
        buttonKey={`${modActionOrder?.action.toUpperCase()}`}
        card={CARD_IMAGING_WORK_LIST}
        hideReason={!!modActionOrder?.hideReason}
        infoOnly={modActionOrder?.infoOnly}
        languageUX={props.languageUX}
        open={!!modActionOrder}
        orderStatus={modActionOrder?.orderStatus || ""}
        title={modActionOrder?.title}
        userFullname={props.django?.user.full_name}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        userTokenize={props.userTokenize}
        // options
        cancelImagingOrderOptions={props.masterOptions?.cancelImagingOrder}
        // callback
        onApprove={handleConfirmActionOrder}
        onClose={handleCloseModActionOrder}
      />

      <ModExecuteRadiologyOrder
        onEvent={props.onEvent}
        setProp={props.setProp}
        // seq
        runSequence={props.runSequence}
        card={CARD_IMAGING_WORK_LIST}
        // data
        open={!!modExecute}
        orderItemIds={modExecute?.orderItemIds}
        userId={props.django?.user.id}
        userName={props.django?.user.full_name}
        buttonLoadCheck={props.buttonLoadCheck}
        // CommonInterface
        searchedItemListWithKey={props.searchedItemListWithKey}
        // callback
        onClose={handleCloseModExecute}
        languageUX={props.languageUX}
      />

      <ModImagingAuditLog
        // data
        auditLogList={props.ImagingWorkListSequence?.auditLogList || []}
        data={modAuditLog || {}}
        open={!!modAuditLog}
        // callback
        onClose={handleCloseModLog}
        languageUX={props.languageUX}
      />

      <ModXrayDetail
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        allowedReadyToView={allowedReadyToView}
        clinicalFindingId={props.clinicalFindingId}
        clinicalFindingIndex={props.clinicalFindingIndex}
        clinicalFindings={props.clinicalFindings}
        data={props.modXrayDetail}
        filterClinicalFindingIds={props.filterClinicalFindingIds}
        open={!!props.modXrayDetail}
        readOnly
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
        masterData={{ clinicaltags: props.clinicaltags, organ: props.organ }}
        // options
        eligibilityOptions={props.masterOptions?.eligibilityType}
        // callback
        onClose={handleCloseModXray}
        onSave={handleSaveXray}
        languageUX={props.languageUX}
      />

      <ModalGallery
        data={props.ImagingWorkListSequence?.pacsGalleryDetail || null}
        open={!!props.ImagingWorkListSequence?.pacsGalleryDetail}
        onClose={handleCloseModalGallery}
        languageUX={props.languageUX}
      />

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                RenderRadiologistStatus;                */

/* ------------------------------------------------------ */
type RenderRadiologistStatusProps = {
  data: ImagingQueueSerializer;
  status: any;
  // callback
  onGoToEncounter: GoToEncounterHandler;
  onSetModActionOrder: (data: ModActionOrderType) => any;
} & Pick<CardImagingWorkListProps, "buttonLoadCheck" | "django" | "doctorDetail">;

const RenderRadiologistStatus = (props: RenderRadiologistStatusProps) => {
  if ([ORDER_STATUS.REGISTERED, ORDER_STATUS.REQUESTED].includes(props.status)) {
    return <div style={{ color: "#D9D9D9" }}>{"Waiting for execute"}</div>;
  } else if (props.data.status === ORDER_STATUS.EXECUTED) {
    return (
      <>
        {props.data.radiologist}
        {props.data.radiologist_id === props.doctorDetail?.id && (
          <ActionButton
            badgeSx={{ justifyContent: "center" }}
            icon="report"
            onClick={() => {
              props.onGoToEncounter(props.data, "Imaging Result");
            }}
          />
        )}
      </>
    );
  } else if (props.data.status === ORDER_STATUS.REPORTED) {
    return (
      <>
        {props.data.radiologist}
        <div
          style={{
            display: "grid",
            gridTemplateColumns:
              props.data.radiologist_id === props.doctorDetail?.id ? "50% 1fr" : "100%",
          }}
        >
          {props.data.radiologist_id === props.doctorDetail?.id && (
            <ActionButton
              badgeSx={{ justifyContent: "center", minWidth: "50px" }}
              icon="edit"
              onClick={() => {
                props.onGoToEncounter(props.data, "Imaging Result");
              }}
            />
          )}
          {props.django?.user.role_types.includes("DOCTOR") && (
            <ActionButton
              badgeSx={{ justifyContent: "center", minWidth: "50px" }}
              icon="approve"
              onClick={() => {
                props.onSetModActionOrder({
                  action: "Approve",
                  authUsrPass: true,
                  backgroundColor: "var(--primary-theme-color)",
                  data: props.data,
                  hideReason: true,
                  orderStatus: "APPROVED",
                  title: "Approve Imaging Order",
                });
              }}
              loading={
                !!props.buttonLoadCheck?.[
                  `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.approve}_${props.data.id}`
                ]
              }
            />
          )}
        </div>
      </>
    );
  } else if (props.data.status === ORDER_STATUS.APPROVED) {
    return (
      <>
        {props.data.radiologist}
        {props.django?.user.role_types.includes("DOCTOR") && (
          <ActionButton
            badgeSx={{ justifyContent: "center" }}
            icon="unapprove"
            onClick={() => {
              props.onSetModActionOrder({
                action: "Unapprove",
                authUsrPass: true,
                data: props.data,
                hideReason: true,
                orderStatus: "reported",
              });
            }}
          />
        )}
      </>
    );
  }

  return null;
};

/* ------------------------------------------------------ */

/*                   RenderActionSection                  */

/* ------------------------------------------------------ */
type RenderActionSectionProps = {
  data: ImagingQueueSerializer;
  index: number;
  status: any;
  //  callback
  onClickPacs: (data: ImagingQueueSerializer, index: number) => any;
  onGoToEncounter: GoToEncounterHandler;
  onRegister: RegisterHandler;
  onSetModActionOrder: (data: ModActionOrderType) => any;
  onSetModExecute: (data: { orderItemIds: number[] }) => any;
  onUnregister: RegisterHandler;
} & Pick<CardImagingWorkListProps, "buttonLoadCheck">;

const RenderActionSection = (props: RenderActionSectionProps) => {
  const isReadyToView = useMemo(
    () => props.data.ready_to_view || (!!CONFIG.PACS_BASE_URL && props.data.accession_id),
    [props.data]
  );

  // Handler
  const handleRegister = () => {
    props.onRegister({
      index: props.index,
      orderItems: [{ id: props.data.id }],
    });
  };

  const handleUnregister = () => {
    props.onUnregister({
      index: props.index,
      orderItems: [{ id: props.data.id }],
    });
  };

  const handleSetModExecute = () => {
    props.onSetModExecute({ orderItemIds: [props.data.id] });
  };

  const handleSetModActionOrder = () => {
    props.onSetModActionOrder({
      action: "UNEXECUTE",
      data: props.data,
      infoOnly: true,
      orderStatus: "REGISTERED",
      title: "Unexecute Radiology Order",
    });
  };

  const handleClickPacs = () => {
    props.onClickPacs(props.data, props.index);
  };

  const handleGoToEncounter = () => {
    props.onGoToEncounter({ ...props.data, readOnly: true }, "Imaging Result");
  };

  if (props.status === ORDER_STATUS.REQUESTED) {
    return (
      <ActionButton
        icon="register"
        onClick={handleRegister}
        loading={
          !!props.buttonLoadCheck?.[
            `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.register}_${props.index}`
          ]
        }
      />
    );
  } else if (props.data.status === ORDER_STATUS.REGISTERED) {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <ActionButton
          icon="unregister"
          onClick={handleUnregister}
          loading={
            !!props.buttonLoadCheck?.[
              `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.unregister}_${props.index}`
            ]
          }
        />
        <ActionButton icon="execute" onClick={handleSetModExecute} />
      </div>
    );
  } else if (
    ([ORDER_STATUS.EXECUTED, ORDER_STATUS.REPORTED, ORDER_STATUS.APPROVED] as string[]).includes(
      props.data.status
    )
  ) {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "56% 1fr" }}>
        <ActionButton
          badgeSx={{ justifyContent: "center", minWidth: "50px" }}
          icon="result"
          onClick={handleGoToEncounter}
        />
        <ActionButton
          badgeSx={{ justifyContent: "center", minWidth: "50px" }}
          disabled={!isReadyToView}
          icon={isReadyToView ? "pacs" : "pacs_grey"}
          onClick={handleClickPacs}
          loading={
            !!props.buttonLoadCheck?.[
              `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.open_pacs}_${props.index}`
            ]
          }
        />
        {props.data.status === ORDER_STATUS.EXECUTED && (
          <ActionButton
            badgeSx={{ justifyContent: "center", minWidth: "50px" }}
            icon="unexecute"
            onClick={handleSetModActionOrder}
          />
        )}
      </div>
    );
  }

  return null;
};

const ForwardedCardImagingWorkList = forwardRef<any, CardImagingWorkListProps>(CardImagingWorkList);

CardImagingWorkList.displayName = "CardImagingWorkList";

export default React.memo(ForwardedCardImagingWorkList);
