import React, { CSSProperties, useState, useMemo } from "react";
import { Button, Checkbox, Input, Modal } from "semantic-ui-react";

import { useIntl } from "react-intl";

// Types
type ModSelectDivisionProps = {
  open: boolean;
  divisionOptions: Record<string, any>[];
  // config
  organizationFilter?: boolean;
  // callback
  onClose: () => any;
  onSelect: (divisions: Record<string, any>[]) => any;
};

// Const
// styles
const styles = {
  header: {
    backgroundColor: "#C6EBF3",
    fontSize: "1.2rem",
    padding: "15px",
  } as CSSProperties,
  title: {
    marginTop: "5px",
    fontWeight: "bold",
    fontSize: "1.2rem",
  } as CSSProperties,
};

const ModSelectDivision = (props: ModSelectDivisionProps) => {
  const intl = useIntl();

  const [search, setSearch] = useState<string>("");

  const [selectedDivision, setSelectedDivision] = useState<
    Record<string, any>[]
  >([]);

  const divisionOptions = useMemo(() => {
    return props.divisionOptions.filter((option) =>
      option.text.toLowerCase().includes(search.toLowerCase())
    );
  }, [props.divisionOptions, search]);

  const handleSearch = (e: any, v: any) => {
    setSearch(v.value);
  };

  const handleCheckDivision = (item: any) => (e: any, v: any) => {
    let checkeds = [...selectedDivision];

    if (v.checked) {
      checkeds.push(item);
    } else {
      checkeds = checkeds.filter((acc) => acc.value !== item.value);
    }

    setSelectedDivision(checkeds);
  };

  const handleClear = () => {
    setSearch("");
    setSelectedDivision([]);
  };

  const handleClose = () => {
    handleClear();

    props.onClose();
  };

  const handleSelect = () => {
    handleClear();

    props.onSelect(selectedDivision);
  };

  return (
    <Modal open={props.open} onClose={handleClose} closeOnDimmerClick={true}>
      <div>
        <div style={styles.header}>
          <div style={styles.title}>
            {props.organizationFilter
              ? intl.formatMessage({ id: "key872" })
              : "เลือกแผนก"}
          </div>
        </div>

        <div style={{ padding: "15px" }}>
          <Input
            icon="search"
            placeholder="search"
            fluid={true}
            style={{ width: "100%" }}
            value={search}
            onChange={handleSearch}
          />
          <div
            style={{
              height: "300px",
              width: "100%",
              overflowY: "scroll",
              padding: "15px",
            }}
          >
            {divisionOptions.map((item: any) => (
              <div key={item.value}>
                <Checkbox
                  label={item.text}
                  checked={
                    !!selectedDivision.find((acc) => acc.value === item.value)
                  }
                  style={{ margin: "10px" }}
                  name={item.value}
                  onChange={handleCheckDivision(item)}
                />
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              style={{ marginRight: "20px" }}
              color="green"
              disabled={!selectedDivision.length}
              onClick={handleSelect}
            >
              บันทึก
            </Button>
            <Button color="red" onClick={handleClose}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ModSelectDivision);
