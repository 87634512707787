import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ModDownloadZipFileUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{backgroundColor: "#FFFFF",width: "100%",height:"100%",padding:"1rem"}}>
        <div
          style={{display: "flex", alignItems: "center"}}>

          <label
            style={{fontWeight: "bold", fontSize: "1.1rem"}}>

            <label>
              {`ดาวน์โหลด zip file ย้อนหลังของเลขที่ใบแจ้งหนี้ ${props.lotNo || "-"} ปีงบประมาณ ${props.fiscalYear || "-"}`}
            </label>
          </label>
          <div
            style={{flex:1}}>

          </div>
          <Icon
            className="red"
            link={true}
            name="close"
            onClick={props.onClose}>
          </Icon>
        </div>
        <div
          className="ui divider">

        </div>
        <div>

          <Table
            data={props.data}
            headers="งวดส่ง,วันเวลาที่ส่ง,ผู้ส่ง,zip file name,สถานะส่งเบิก,,"
            keys="sent_no,datetime,sent_by_name,zip_file_name,status_name,summary,download"
            showPagination={false}
            style={{height: "400px"}}
            widths="^90,^110,^120,^100,^100,90,110">
          </Table>
        </div>
      </div>
    )
}

ModDownloadZipFileUX.displayName = "ModDownloadZipFileUX";
export default React.memo(ModDownloadZipFileUX)

export const screenPropsDefault = {}

/* Date Time : Mon May 27 2024 11:30:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#FFFFF\",width: \"100%\",height:\"100%\",padding:\"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.1rem\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "`ดาวน์โหลด zip file ย้อนหลังของเลขที่ใบแจ้งหนี้ ${props.lotNo || \"-\"} ปีงบประมาณ ${props.fiscalYear || \"-\"}`"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Icon",
      "parent": 58,
      "props": {
        "className": {
          "type": "value",
          "value": "red"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 115,
      "name": "Table",
      "parent": 114,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "value",
          "value": "งวดส่ง,วันเวลาที่ส่ง,ผู้ส่ง,zip file name,สถานะส่งเบิก,,"
        },
        "keys": {
          "type": "value",
          "value": "sent_no,datetime,sent_by_name,zip_file_name,status_name,summary,download"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        },
        "widths": {
          "type": "value",
          "value": "^90,^110,^120,^100,^100,90,110"
        }
      },
      "seq": 115,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 35,
  "isMounted": true,
  "memo": true,
  "name": "ModDownloadZipFileUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
