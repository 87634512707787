import React, { useEffect, useRef, useState } from "react";
// UX
import CardCovidCertificateUX from "./CardCovidCertificateUX";
// Common
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
// Interface
import { State } from "./sequence/DoctorCertificate";
// Utils
import moment from "moment";
import { useIntl } from "react-intl";

// Main Props
type CardCovidCertificateProps = {
  // Function
  setProp: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

// Current Date
const currentDate = new Date().toISOString().split("T")[0];
const christYear = moment(currentDate).format("YYYY");
const buddhistYear = (parseInt(christYear) + 543).toString();
const formattedDate = moment(currentDate).format(
  "DD/MM/YYYY".replace("YYYY", buddhistYear)
);
const defaultDate = moment().format("HH:mm");

// Method Options
const methodOptions = [
  { key: 1, value: "Antigen Test Kit (ATK)", text: "Antigen Test Kit (ATK)" },
  { key: 2, value: "วิธี Real-Time PCR", text: "วิธี Real-Time PCR" },
];

// Main
const CardCovidCertificate = (props: CardCovidCertificateProps) => {
  const intl = useIntl();
  // log Props
  // console.log("CardCovidCertificate Props: ", props.DoctorCertificateSequence);

  // Call Sequence ( Covid Certificate )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "FETCH_CERTIFICATE",
      fetchType: "CardCovidCertificate",
    });
  }, []);

  // set State
  const [newMethodOptions, setNewMethodOptions] = useState(methodOptions);
  const [currentMethod, setCurrentMethod] = useState("");

  // Change Date
  const handleChangeDate = (name: any, date: string) => {
    // console.log("Key: ", name + " Date: ", date);
    props.setProp(`DoctorCertificateSequence.covidReport.${name}`, date);
  };

  // Change Text
  const handleChangeText = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (name) {
      case "comment":
      case "absenceDuration":
        props.setProp(
          `DoctorCertificateSequence.covidReport.${name}`,
          e.target.value
        );
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Tested Result Checkobx
  // const testedResultOption = (value: string) => {
  //   return props.DoctorCertificateSequence?.covidReport?.testedResult === `${value}`
  // }

  // Change Checkbox
  const handleChangeTestedResult = (e: any, value: string) => {
    props.setProp(`DoctorCertificateSequence.covidReport.testedResult`, value);
  };

  // Add new Tested Method
  const handleAddTestedMethod = (e: any, { value }: { value: string }) => {
    setNewMethodOptions((prevOptions: any) => [
      { text: value, value },
      ...prevOptions,
    ]);
  };

  // Change Tested Method
  const handleChangeTestedMethod = (value: string) => {
    props.setProp(`DoctorCertificateSequence.covidReport.testedMethod`, value);
  };

  // get Value from Tested Method Dropdown
  const handleSelectedTestedMethod = (e: any, { value }: { value: string }) => {
    //console.log(value)
    handleChangeTestedMethod(value);
    setCurrentMethod(value);
  };

  // Change Checkbox
  const handleChangeCheckbox = (e: any, name: string, data: any) => {
    if (data.type === "checkbox") {
      var value = data.checked;
      switch (name) {
        case "getAdvised":
        case "shouldAbsence":
          props.setProp(`DoctorCertificateSequence.covidReport.${name}`, value);
          break;
        default:
          console.log("Doesn't Exist!!");
          break;
      }
    }
  };

  // Enable/Disable Absence Duration
  const handleDurationDisable = () => {
    if (props.DoctorCertificateSequence?.covidReport?.shouldAbsence === true) {
      return false;
    } else if (
      props.DoctorCertificateSequence?.covidReport?.shouldAbsence === false
    ) {
      if (
        props.DoctorCertificateSequence?.covidReport?.absenceDuration ||
        (props.DoctorCertificateSequence?.covidReport?.absenceStartDate &&
          props.DoctorCertificateSequence?.covidReport?.absenceEndDate)
      ) {
        props.setProp(
          `DoctorCertificateSequence.covidReport.absenceDuration`,
          ""
        );
        props.setProp(
          `DoctorCertificateSequence.covidReport.absenceStartDate`,
          ""
        );
        props.setProp(
          `DoctorCertificateSequence.covidReport.absenceEndDate`,
          ""
        );
      }
      return true;
    } else {
      return false;
    }
  };

  // Condition Close Button
  // const clearSelectedData = () => {
  //   // Set selectedReport = null
  //   props.setProp(`DoctorCertificateSequence.selectedReport`, null);

  //   // Re-Fetch
  //   props.runSequence({
  //     sequence: "DoctorCertificate",
  //     action: "CLEAR_REPORT",
  //     callReport: "CardCovidCertificate",
  //   });
  // };

  // const closeSelectedCheck = (reportSelected: any) => {
  //   if (reportSelected != null) {
  //     return (
  //       <Button
  //         color="red"
  //         style={{ width: "8em" }}
  //         onClick={clearSelectedData}
  //       >
  //         ปิด
  //       </Button>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Disable when select FiveForm
  const handleDisableForm = (selected: any, reportType?: any) => {
    if (selected == null) {
      return false;
    } else if (selected !== null && reportType === "ใบรับรองผลการตรวจโควิด") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CardCovidCertificateUX
        // Exam Date
        onExamDate={
          props.DoctorCertificateSequence?.covidReport?.examDate ||
          formattedDate
        }
        onHandleChangeDate={handleChangeDate}
        // Min Date
        onMinDate={moment().subtract(1, "days")}
        // TimeComboBox Exam Time
        onExamTime={
          <TimeComboBox
            defaultValue={
              props.DoctorCertificateSequence?.covidReport?.examTime ||
              defaultDate
            }
            // disabled={handleDisableForm(
            //   null,
            //   props.DoctorCertificateSequence?.selectedReport?.data?.reportType
            // )}
            onTextChange={(time: string) => handleChangeDate("examTime", time)}
            style={{ width: "100%" }}
          />
        }
        // Tested Result Checkbox
        onTestedResult={(value: string) =>
          props.DoctorCertificateSequence?.covidReport?.testedResult ===
          `${value}`
        }
        onHandleChangeTestedResult={handleChangeTestedResult}
        // Tested Method Dropdown
        onTestedMethod={newMethodOptions}
        onTestedMethodValue={
          props.DoctorCertificateSequence?.covidReport?.testedMethod ||
          currentMethod
        }
        onHandleChangeMethod={handleSelectedTestedMethod}
        onHandleAddMethod={handleAddTestedMethod}
        // Swab Date
        onSwabDate={
          props.DoctorCertificateSequence?.covidReport?.swabDate || ""
        }
        // Get Advised
        onGetAdvised={
          props.DoctorCertificateSequence?.covidReport?.getAdvised || false
        }
        onHandleChangeCheckbox={handleChangeCheckbox}
        // Comment
        onComment={props.DoctorCertificateSequence?.covidReport?.comment || ""}
        onHandleChangeText={handleChangeText}
        // Absence Checkbox
        onShouldAbsence={
          props.DoctorCertificateSequence?.covidReport?.shouldAbsence || false
        }
        // Absence Duration (days)
        onAbsenceDuration={
          props.DoctorCertificateSequence?.covidReport?.absenceDuration || ""
        }
        onHandleDurationDisable={handleDurationDisable}
        // Absence Start Date
        onAbsenceStartDate={
          props.DoctorCertificateSequence?.covidReport?.absenceStartDate || ""
        }
        // Absence End Date
        onAbsenceEndDate={
          props.DoctorCertificateSequence?.covidReport?.absenceEndDate || ""
        }
        // Absence Min Date
        onAbsenceMinDate={
          props.DoctorCertificateSequence?.covidReport?.examDate
        }
        languageUX={props.languageUX}
        // Close Button When Selected Report
        // onCloseSelected={closeSelectedCheck(
        //   null
        // )}
        // Disable Input
        // onHandleDisableForm={handleDisableForm(
        //   null,
        //   props.DoctorCertificateSequence?.selectedReport?.data?.reportType
        // )}
      />
    </>
  );
};

export default CardCovidCertificate;
