import HeaderMedicineMore, {
  HeaderMedicineMoreProps,
} from "./HeaderMedicineMore";

// Types
type FormAdverseReactionMoreProps = {
  adr_text: string;
} & HeaderMedicineMoreProps;

const FormAdverseReactionMore = (props: FormAdverseReactionMoreProps) => {
  const headerMedicineMore = HeaderMedicineMore(props);

  return {
    ...headerMedicineMore,
    content: [
      {
        table: {
          widths: ["100%"],
          body: [
            [
              {
                text: "Adverse Reaction",
                style: "tableHeader",
                fillColor: "#d9d9d9",
              },
            ],
            [
              {
                text: props.adr_text,
                style: "tableData",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => {
            return 0;
          },
          vLineWidth: () => {
            return 0;
          },
          paddingTop: () => {
            return 0;
          },
          paddingBottom: () => {
            return 0;
          },
        },
      },
    ],
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      tableData: { fontSize: 13 },
    },
  };
};

export default FormAdverseReactionMore;
