import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  FormGroup,
  FormField,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSendClaimResponseFileUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{backgroundColor: "#FFFFF",width: "100%",height:"100%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"transparent"}}>
        <div
          style={{display: "flex", marginBottom: "0.75rem"}}>
          
          <Button
            color={props.activeTab === "EClaim"? "blue":""}
            name="EClaim"
            onClick={props.onChangeTab}
            size="small"
            style={{marginRight: "0.75rem"}}>
            ไฟล์ตอบกลับโปรแกรม E-Claim
          </Button>
          <Button
            color={props.activeTab === "AIPN"? "blue":""}
            name="AIPN"
            onClick={props.onChangeTab}
            size="small"
            style={{marginRight: "0.75rem"}}>
            ไฟล์ตอบกลับโปรแกรม AIPN
          </Button>
        </div>
        <div>
          <div
            style={{display: "flex",height:"100%", fontSize:"1.15rem",fontWeight:"bold",alignItems:"center", padding: "2.5px 0px"}}>
            
            <label>
              บันทึกผลการส่งเบิก
            </label>
          </div>
          <div
            className="ui divider"
            style={{marginTop: "0.5rem"}}>
            
          </div>
        </div>
        <div
          className="ui form small --override-disabled --grey"
          style={{display:props.activeTab === "EClaim"? "":"none"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              className="required">
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                ระบุสิทธิ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                className="inline-label"
                fluid={true}
                onChange={props.onChangeCoveragePayer}
                options={props.eclaimCoverageOptions || []}
                search={true}
                selection={true}
                style={{ width: "100%", fontSize: "1em" }}
                value={props.eclaimDetail?.coverage_payer_sent_claim_group || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                รหัสโรงพยาบาล
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                disabled={true}
                value={props.eclaimDetail?.hospital_code|| ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                เลขที่เอกสาร
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                disabled={true}
                value={props.eclaimDetail?.document_no|| ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                วันที่ออกเอกสาร
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                disabled={true}
                value={props.eclaimDetail?.document_date|| ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                รายการ REP NO
              </label>
            </FormField>
            <FormField
              inline={true}
              width={16}>
              <Input
                disabled={true}
                value={props.eclaimDetail?.reply_no|| ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form small --override-disabled --grey"
          style={{display:props.activeTab === "AIPN"? "":"none"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                สิทธิ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                disabled={true}
                fluid={true}
                value={props.aipnDetail?.coverage_payer_sent_claim_group_name || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                รหัสโรงพยาบาล
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                disabled={true}
                fluid={true}
                value={props.aipnDetail?.hospital_code|| ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                เลขที่ตอบรับ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                disabled={true}
                fluid={true}
                value={props.aipnDetail?.response_no|| ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                วันที่ออกเลขที่ตอบรับ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                disabled={true}
                fluid={true}
                value={props.aipnDetail?.response_at|| ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                งวดส่ง
              </label>
            </FormField>
            <FormField
              inline={true}
              width={16}>
              <Input
                disabled={true}
                fluid={true}
                value={props.aipnDetail?.sent_no|| ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display: "grid", gridTemplateColumns: "auto 1fr"}}>
          
          <div>
            {props.dropzone}
          </div>
          <div>
            
            <Table
              data={props.data}
              getTrProps={props.onGetTrProps}
              headers={props.table?.headers}
              keys={props.table?.keys}
              minRows={8}
              showPagination={false}
              style={{height: "calc(-33rem + 100dvh)"}}
              widths={props.table?.widths}>
            </Table>
          </div>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem"}}>
          {}
          <div>
            {props.buttonSave}
          </div>
        </div>
      </div>
    )
}

CardSendClaimResponseFileUX.displayName = "CardSendClaimResponseFileUX";
export default React.memo(CardSendClaimResponseFileUX)

export const screenPropsDefault = {}

/* Date Time : Thu Nov 14 2024 12:38:23 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#FFFFF\",width: \"100%\",height:\"100%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"transparent\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small --override-disabled --grey"
        },
        "style": {
          "type": "code",
          "value": "{display:props.activeTab === \"EClaim\"? \"\":\"none\"}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"100%\", fontSize:\"1.15rem\",fontWeight:\"bold\",alignItems:\"center\", padding: \"2.5px 0px\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "label",
      "parent": 183,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกผลการส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 371,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 372,
      "name": "Button",
      "parent": 371,
      "props": {
        "children": {
          "type": "value",
          "value": "ไฟล์ตอบกลับโปรแกรม E-Claim"
        },
        "color": {
          "type": "code",
          "value": "props.activeTab === \"EClaim\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "EClaim"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 372,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 373,
      "name": "Button",
      "parent": 371,
      "props": {
        "children": {
          "type": "value",
          "value": "ไฟล์ตอบกลับโปรแกรม AIPN"
        },
        "color": {
          "type": "code",
          "value": "props.activeTab === \"AIPN\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "AIPN"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 373,
      "void": false
    },
    {
      "from": null,
      "id": 374,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.5rem\"}"
        }
      },
      "seq": 374,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 375,
      "name": "FormGroup",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 375,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 376,
      "name": "FormGroup",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 376,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 377,
      "name": "FormGroup",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 377,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 378,
      "name": "FormGroup",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 378,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 379,
      "name": "FormGroup",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 379,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 380,
      "name": "FormField",
      "parent": 375,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        }
      },
      "seq": 380,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 381,
      "name": "FormField",
      "parent": 375,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 381,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 382,
      "name": "FormField",
      "parent": 376,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 382,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 383,
      "name": "FormField",
      "parent": 376,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 383,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 384,
      "name": "FormField",
      "parent": 377,
      "props": {
      },
      "seq": 384,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 385,
      "name": "FormField",
      "parent": 377,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 385,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 386,
      "name": "FormField",
      "parent": 378,
      "props": {
      },
      "seq": 386,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 387,
      "name": "FormField",
      "parent": 378,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 387,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 388,
      "name": "FormField",
      "parent": 379,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 388,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 389,
      "name": "FormField",
      "parent": 379,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 389,
      "void": false
    },
    {
      "from": null,
      "id": 390,
      "name": "label",
      "parent": 388,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการ REP NO"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 390,
      "void": false
    },
    {
      "from": null,
      "id": 391,
      "name": "label",
      "parent": 386,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ออกเอกสาร"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 391,
      "void": false
    },
    {
      "from": null,
      "id": 392,
      "name": "label",
      "parent": 384,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่เอกสาร"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 392,
      "void": false
    },
    {
      "from": null,
      "id": 393,
      "name": "label",
      "parent": 382,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสโรงพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 393,
      "void": false
    },
    {
      "from": null,
      "id": 394,
      "name": "label",
      "parent": 380,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุสิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 394,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 395,
      "name": "Input",
      "parent": 389,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.eclaimDetail?.reply_no|| \"\""
        }
      },
      "seq": 395,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 396,
      "name": "Input",
      "parent": 387,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.eclaimDetail?.document_date|| \"\""
        }
      },
      "seq": 396,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 397,
      "name": "Input",
      "parent": 385,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.eclaimDetail?.document_no|| \"\""
        }
      },
      "seq": 397,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 398,
      "name": "Input",
      "parent": 383,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.eclaimDetail?.hospital_code|| \"\""
        }
      },
      "seq": 398,
      "void": false
    },
    {
      "from": null,
      "id": 400,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"auto 1fr\"}"
        }
      },
      "seq": 405,
      "void": false
    },
    {
      "from": null,
      "id": 401,
      "name": "div",
      "parent": 400,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dropzone"
        }
      },
      "seq": 401,
      "void": false
    },
    {
      "from": null,
      "id": 402,
      "name": "div",
      "parent": 400,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 402,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 403,
      "name": "Table",
      "parent": 402,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "code",
          "value": "props.table?.headers"
        },
        "keys": {
          "type": "code",
          "value": "props.table?.keys"
        },
        "minRows": {
          "type": "code",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(-33rem + 100dvh)\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.table?.widths"
        }
      },
      "seq": 403,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 404,
      "name": "Dropdown",
      "parent": 381,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCoveragePayer"
        },
        "options": {
          "type": "code",
          "value": "props.eclaimCoverageOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", fontSize: \"1em\" }"
        },
        "value": {
          "type": "code",
          "value": "props.eclaimDetail?.coverage_payer_sent_claim_group || \"\""
        }
      },
      "seq": 404,
      "void": false
    },
    {
      "from": null,
      "id": 405,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"1rem\"}"
        }
      },
      "seq": 407,
      "void": false
    },
    {
      "from": null,
      "id": 406,
      "name": "div",
      "parent": 405,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 406,
      "void": false
    },
    {
      "from": null,
      "id": 407,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small --override-disabled --grey"
        },
        "style": {
          "type": "code",
          "value": "{display:props.activeTab === \"AIPN\"? \"\":\"none\"}"
        }
      },
      "seq": 400,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 408,
      "name": "FormGroup",
      "parent": 407,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 408,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 409,
      "name": "FormGroup",
      "parent": 407,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 409,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 410,
      "name": "FormGroup",
      "parent": 407,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 410,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 411,
      "name": "FormGroup",
      "parent": 407,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 411,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 412,
      "name": "FormGroup",
      "parent": 407,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 412,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 413,
      "name": "FormField",
      "parent": 408,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 413,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 414,
      "name": "FormField",
      "parent": 408,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 414,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 415,
      "name": "FormField",
      "parent": 409,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 415,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 416,
      "name": "FormField",
      "parent": 409,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 416,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 417,
      "name": "FormField",
      "parent": 410,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 417,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 418,
      "name": "FormField",
      "parent": 410,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 418,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 419,
      "name": "FormField",
      "parent": 411,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 419,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 420,
      "name": "FormField",
      "parent": 411,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 420,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 421,
      "name": "FormField",
      "parent": 412,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 421,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 422,
      "name": "FormField",
      "parent": 412,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 422,
      "void": false
    },
    {
      "from": null,
      "id": 423,
      "name": "label",
      "parent": 413,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 423,
      "void": false
    },
    {
      "from": null,
      "id": 424,
      "name": "label",
      "parent": 415,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสโรงพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 424,
      "void": false
    },
    {
      "from": null,
      "id": 425,
      "name": "label",
      "parent": 417,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ตอบรับ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 425,
      "void": false
    },
    {
      "from": null,
      "id": 426,
      "name": "label",
      "parent": 419,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ออกเลขที่ตอบรับ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 426,
      "void": false
    },
    {
      "from": null,
      "id": 427,
      "name": "label",
      "parent": 421,
      "props": {
        "children": {
          "type": "value",
          "value": "งวดส่ง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 427,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 428,
      "name": "Input",
      "parent": 414,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.aipnDetail?.coverage_payer_sent_claim_group_name || \"\""
        }
      },
      "seq": 428,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 429,
      "name": "Input",
      "parent": 416,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.aipnDetail?.hospital_code|| \"\""
        }
      },
      "seq": 429,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 430,
      "name": "Input",
      "parent": 418,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.aipnDetail?.response_no|| \"\""
        }
      },
      "seq": 430,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 431,
      "name": "Input",
      "parent": 420,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.aipnDetail?.response_at|| \"\""
        }
      },
      "seq": 431,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 432,
      "name": "Input",
      "parent": 422,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.aipnDetail?.sent_no|| \"\""
        }
      },
      "seq": 432,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": true,
  "memo": true,
  "name": "CardSendClaimResponseFileUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 100
}

*********************************************************************************** */
